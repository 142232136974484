import React from 'react';
import PropTypes from 'prop-types';

export const PetFoodShipTime = ({ isMondayToThursday }) => (
  <div className="d-flex">
    <img alt="Flash" src="https://bottomless.imgix.net/flash.svg" />
    <div className="text-success font-weight-bold ml-2">
      {isMondayToThursday ? 'Order now for delivery tomorrow 3 pm - 6 pm' : 'Order now for delivery Monday 3 pm - 6 pm'}
    </div>
  </div>
);

PetFoodShipTime.propTypes = {
  isMondayToThursday: PropTypes.bool.isRequired,
};
