import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const HomeLandingComponent = ({ me, history }) => {
  if (me) {
    history.push('/profile');
  }

  window.location.reload();
  return null;
};

HomeLandingComponent.propTypes = {
  me: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export const HomeLanding = connect(({ user: { me } }) => ({ me }))(HomeLandingComponent);
