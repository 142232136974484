import { useMemo } from 'react';

export const usePersonalizedAttributes = personalized => {
  const roast = useMemo(
    () =>
      (personalized?.roast || [])
        .map(val => val.replace('any', "I don't know, surprise me!").replace(/^\w/, c => c.toUpperCase()))
        .join(', '),
    [personalized]
  );

  const tag = useMemo(
    () =>
      (personalized?.tag || [])
        .map(val => val.replace('cold_brew', 'Cold brew').replace(/^\w/, c => c.toUpperCase()))
        .join(', '),
    [personalized]
  );

  const origin = useMemo(
    () =>
      (personalized?.origin || [])
        .map(val =>
          val
            .replace('any', "I don't know, surprise me!")
            .replace('single', 'Single Origin')
            .replace(/^\w/, c => c.toUpperCase())
        )
        .join(', '),
    [personalized]
  );

  const singleOrigins = useMemo(() => (personalized?.single_origins || []).map(origin => origin.name).join(', '), [
    personalized,
  ]);

  const vendor = useMemo(() => (personalized?.vendor || []).map(vendor => vendor.name).join(', '), [personalized]);

  const tastingNoteCategory = useMemo(
    () =>
      (personalized?.tasting_note_category || []).map(tasting_note_category => tasting_note_category.name).join(', '),
    [personalized]
  );

  const attributes = useMemo(
    () =>
      [
        { label: 'Tag', value: tag },
        { label: 'Origin', value: origin },
        { label: 'Single Origin', value: singleOrigins },
        { label: 'Vendor', value: vendor },
        { label: 'Tasting Notes', value: tastingNoteCategory },
      ].filter(({ value }) => Boolean(value)),
    [tag, origin, singleOrigins, vendor, tastingNoteCategory]
  );

  return { roast, attributes };
};
