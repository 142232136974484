import React from 'react';
import { SubscriptionFaq } from './SubscriptionFaq';

const FAQ = [
  {
    question: 'When will I get my next order?',
    answer:
      'Your smart canister tracks your usage and reorders for you, ensuring your AG1 arrives at the perfect time. You will receive an SMS notification one day prior to your next order with the option to change or delay your shipment if desired.',
  },
  {
    question: 'Do l have control over future orders?',
    answer: (
      <div>
        Yes! You have full control over your subscription preferences by accessing your membership{' '}
        <a href="https://www.drinkag1.com/members" target="_blank" rel="noreferrer">
          online
        </a>
        .
        <br />
        <br />
        Switch between AG1 Pouch and Travel Packs, adjust your shipment schedule, upgrade to a subscription for two, add
        products as add-ons, and discover member exclusive products to support your health routine.
      </div>
    ),
  },
  {
    question: 'Why does AG1 need to be refrigerated?',
    answer:
      "AG1 is best kept cold, remember to refrigerate your AG1 pouch after opening to maintain the efficacy of the whole food-sourced ingredients and probiotics. AG1 Travel Packs don't require refrigeration.",
  },
  {
    question: 'What happens if I remove the smart attachment from the canister?',
    answer:
      'The smart attachment will wait for at least 24 hours of steady readings before re-ordering. To wash your canister, remove the smart attachment and re-attach once dry.',
  },
  {
    question: 'How long does my smart canister last?',
    answer: (
      <div>
        The battery will last for up to two years and has been tested and approved for use in the refrigerator.
        Don&apos;t worry, if your scale stops working for any reason or you&apos;re ready for a new battery, you&apos;ll
        get a notification with instructions on what to do next.
        <br />
        <br />
        Learn more about AG1 and our approach to{' '}
        <a href="https://drinkag1.com/foundational-nutrition-education" target="_blank" rel="noreferrer">
          Foundational Nutrition
        </a>{' '}
        or check out additional{' '}
        <a href="https://drinkag1.com/about-ag1/faq" target="_blank" rel="noreferrer">
          Frequently Asked Questions
        </a>
        . Need Help?{' '}
        <a href="https://drinkag1.com/contact-us/customer-service" target="_blank" rel="noreferrer">
          Contact Customer Happiness
        </a>
        .
      </div>
    ),
  },
];

export const AG1Faq = () => <SubscriptionFaq faq={FAQ} />;
