import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const PaginationComponent = ({ collection, onPageChange, size, setter, location, history }) => {
  const pagination = generatePages({ page: collection.page, pages: collection.pages });
  const query = qs.parse(location.search);

  if (pagination.length <= 1) {
    return null;
  }

  const handlePageChange = nextPage => async e => {
    e.preventDefault();

    history.push({
      pathname: location.pathname,
      search: qs.stringify({ ...query, page: nextPage }),
    });

    if (!setter) {
      return;
    }

    if (nextPage !== collection.page) {
      setter({ ...collection, docs: [], isLoading: true });
      const { payload } = await onPageChange({ page: nextPage });
      setter({ ...payload, isLoading: false });
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <ul className={`pagination ${size ? `pagination-${size}` : ''}`}>
        {pagination.map(({ page, isCurrent }) => (
          <li key={page} className={`page-item${isCurrent ? ' active' : ''}`}>
            <a className="page-link" href="#" onClick={handlePageChange(page)}>
              {page}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

PaginationComponent.propTypes = {
  size: PropTypes.string,
  collection: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
  }).isRequired,
  onPageChange: PropTypes.func.isRequired,
  setter: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const Pagination = withRouter(PaginationComponent);

const range = count => [...Array(count).keys()];

const generatePages = ({ page = 1, pages }) => {
  const start = Math.max(1, page - 4);
  const count = page <= pages ? Math.min(9, pages - start + 1) : 0;

  return range(count).map(i => ({
    page: start + i,
    isCurrent: start + i === page,
  }));
};
