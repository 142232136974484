import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { UserAvatar } from '../UserAvatar';
import { ProductName } from './components';
import { OrderSources, OrderStatuses } from '../../constants';
import { Image } from '../Image';
import { DateFormat } from '../DateFormat';

const AVAILABLE_STATUSES = {
  Scheduled: 'scheduled',
  Roasting: 'roasting',
  Fulfilling: 'fulfilling',
  InTransit: 'in_transit',
  OutOfDelivery: 'out_for_delivery',
  Delivered: 'delivered',
};

export const OrderProduct = ({ user, order }) => {
  const isOrderOneOff = useMemo(() => order.source === OrderSources.USER_ONE_OFF, [order]);
  const { product } = useMemo(() => order.subproduct_id || (isOrderOneOff && order.product_id) || {}, [
    order,
    isOrderOneOff,
  ]);

  const isOrderWithError = useMemo(
    () =>
      ![OrderStatuses.Cancelled, OrderStatuses.Refunded].includes(order.status) &&
      !order.date_fulfilled &&
      !!order.fulfillmentErrors?.charge?.error,
    [order]
  );

  const status = useMemo(() => {
    if (Object.values(AVAILABLE_STATUSES).includes(order.shipping_status)) {
      return order.shipping_status;
    }

    if (['sent_to_roaster'].includes(order.status) || (order.status === 'fulfilled' && !order.tracking_number)) {
      return AVAILABLE_STATUSES.Fulfilling;
    }

    if (order.status === 'fulfilled' && order.tracking_number) {
      return AVAILABLE_STATUSES.InTransit;
    }

    return AVAILABLE_STATUSES.Scheduled;
  }, [order]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {product && (
            <Image src={product.small_image_src} alt={product.name || 'Product'} width="75" className="mr-2" />
          )}
          {!product && <UserAvatar user={user} width="75" className="mr-2" />}
          <div>
            {product && (
              <div className="small">
                <div className="text-uppercase text-secondary">{product.vendor_name}</div>
                <strong>{product.name}</strong>
                {isOrderOneOff && (
                  <div>
                    <Badge color="primary" pill className="text-capitalize">
                      One-off
                    </Badge>
                  </div>
                )}
              </div>
            )}
            {!product && <ProductName user={user} />}
          </div>
        </div>
        {!order.date_arrived && (
          <>
            {isOrderWithError && (
              <Badge color="danger" pill className="text-capitalize">
                Payment <span className="d-block d-md-inline mt-1">Failure</span>
              </Badge>
            )}
            {!isOrderWithError && (
              <Badge color="dark" pill className="text-capitalize">
                {status.replace(/_/g, ' ')}
              </Badge>
            )}
          </>
        )}
        {order.date_arrived && (
          <div className="text-right">
            <div className="extra-small text-uppercase font-weight-thin text-nowrap pl-1">Delivered on</div>
            <div className="small font-weight-bold mb-1">
              <DateFormat date={order.date_arrived} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

OrderProduct.propTypes = {
  user: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  order: PropTypes.shape({
    source: PropTypes.string,
    product_id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subproduct_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        product: PropTypes.object.isRequired,
      }),
    ]),
    fulfillmentErrors: PropTypes.shape({
      charge: PropTypes.shape({
        error: PropTypes.string,
      }),
    }),
    date_fulfilled: PropTypes.string,
    date_arrived: PropTypes.string,
    status: PropTypes.string,
    tracking_number: PropTypes.string,
    shipping_status: PropTypes.string,
  }).isRequired,
};
