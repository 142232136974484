import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import * as Yup from 'yup';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { Auth } from '../../layouts/Auth/Auth';
import { loginAction } from '../../store/auth';
import { getMeAction } from '../../store';
import { withMetaTags } from '../../components/MetaTags/MetaTags';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid e-mail address')
    .required('This field is required'),
  password: Yup.string().required('This field is required'),
});

export const LoginPageComponent = ({ login, getMe, history, location }) => {
  const isMobile = useMediaQuery({ maxDeviceWidth: 767 });

  const onLogin = async result => {
    await getMe();

    const query = qs.parse(location.search);

    if (result?.redirectUrl) {
      window.location.href = result.redirectUrl;
    } else if (query.path) {
      history.push(query.path);
    } else {
      history.push(`/change-account`);
    }
  };

  return (
    <Auth>
      <Form
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={login}
        onSuccess={onLogin}
      >
        {({ isSubmitting }) => (
          <>
            <Field name="email" type="email" label="E-mail" autocomplete="email" />
            <Field name="password" type="password" label="Password" autocomplete="current-password" />
            <SubmitButton size={null} isSubmitting={isSubmitting} loadingText="Logging in" block>
              Log In
            </SubmitButton>
          </>
        )}
      </Form>
      <div className="small d-flex justify-content-end mt-2 mb-3">
        <Link to="/forgot">Forgot your password?</Link>
      </div>
      <div className="d-flex align-items-center my-4 text-secondary small">
        <span className="border-bottom w-100" style={{ height: '1px' }} />
        <span className="text-secondary mx-2">OR</span>
        <span className="border-bottom w-100" style={{ height: '1px' }} />
      </div>
      <Link
        to={`/send-magic-link${location.search}`}
        className="btn btn-outline-success btn-no-transform btn-block mb-2"
      >
        <i className="fa fa-magic mr-1" aria-hidden="true"></i> Log in with magic link
      </Link>
      <Row className="mt-2">
        <Col md="6" className={!isMobile ? 'pr-0' : ''}>
          <Link to="/auth/facebook" className="btn btn-outline-dark btn-no-transform btn-block mb-2">
            <i className="fa fa-facebook mr-1" aria-hidden="true"></i> Log in with Facebook
          </Link>
        </Col>
        <Col md="6">
          <Link to="/auth/google" className="btn btn-outline-dark btn-no-transform btn-block mb-2">
            <img src="/images/google.png" alt="G" className="mr-1" height="14" /> Log in with Google
          </Link>
        </Col>
      </Row>
      <hr />
      <div className="small text-center">
        <span className="mr-2 text-secondary">Don&apos;t have an account?</span>
        <Link to="/filter_checkout">Sign up</Link>
      </div>
    </Auth>
  );
};

LoginPageComponent.propTypes = {
  login: PropTypes.func.isRequired,
  getMe: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export const LoginPage = withMetaTags({
  title: 'Bottomless.com: Login',
  description: 'Login to Bottomless to track or modify your orders. Select new products or build your rotation here.',
})(
  connect(null, dispatch => ({
    login: data => dispatch(loginAction(data)),
    getMe: () => dispatch(getMeAction()),
  }))(LoginPageComponent)
);
