import React from 'react';
import { Loader } from 'react-feather';

export const StripeLoader = () => (
  <div className="placeholder-loading">
    <div className="d-flex flex-column stripe-loader">
      <div className="d-flex">
        <label className="placeholder-text">Card details</label>
      </div>
      <div className="placeholder-text py-2">
        <Loader size="20" className="spin mr-2" />
        <span>Loading payment provider</span>
      </div>
    </div>
  </div>
);
