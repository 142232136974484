import Cookies from 'universal-cookie';
const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;

export const FiftyOffPostcardLandingRedirect = () => {
  const cookies = new Cookies();
  cookies.set('last_landing_rule', '/50_off_postcard', { domain: cookieDomain });
  cookies.set('landing_page', '/50_off_postcard', { domain: cookieDomain });
  window.location = '/';
  return null;
};
