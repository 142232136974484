import React from 'react';
import { createContext } from 'react';

export const CustomWordingContext = createContext({});
export const CustomWordingProvider = CustomWordingContext.Provider;

export const withCustomWording = (customWording, WrappedComponent) =>
  function WrappedComponentWithCustomWording(props) {
    return (
      <CustomWordingProvider value={customWording}>
        <WrappedComponent {...props} />
      </CustomWordingProvider>
    );
  };
