import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetVendorsTypes = createAction('Vendor', 'Get Vendors');

export const getVendorsAction = () => ({
  [RSAA]: {
    endpoint: 'vendors',
    method: 'GET',
    types: [GetVendorsTypes.REQUEST, GetVendorsTypes.SUCCESS, GetVendorsTypes.FAILURE],
  },
});

export const GetWhitelistVendorsTypes = createAction('Vendor', 'Get Vendors');

export const getWhitelistVendorsAction = category => ({
  [RSAA]: {
    endpoint: `vendors/whitelist${category ? `?category=${category}` : ''}`,
    method: 'GET',
    types: [GetWhitelistVendorsTypes.REQUEST, GetWhitelistVendorsTypes.SUCCESS, GetWhitelistVendorsTypes.FAILURE],
  },
});

export const GetVendorTypes = createAction('Vendor', 'Get Vendor');

export const getVendorAction = slug => ({
  [RSAA]: {
    endpoint: `vendors/${slug}`,
    method: 'GET',
    types: [GetVendorTypes.REQUEST, GetVendorTypes.SUCCESS, GetVendorTypes.FAILURE],
  },
});

export const GetVendorByIdTypes = createAction('Vendor', 'Get Vendor By Id');

export const getVendorByIdAction = id => ({
  [RSAA]: {
    endpoint: `vendors/id/${id}`,
    method: 'GET',
    types: [GetVendorByIdTypes.REQUEST, GetVendorByIdTypes.SUCCESS, GetVendorByIdTypes.FAILURE],
  },
});

export const SubmitVendorTypes = createAction('Vendor', 'Submit Vendor');

export const submitVendorAction = data => ({
  [RSAA]: {
    endpoint: 'vendors',
    method: 'POST',
    body: data,
    types: [SubmitVendorTypes.REQUEST, SubmitVendorTypes.SUCCESS, SubmitVendorTypes.FAILURE],
  },
});

export const SubmitOnboardingTypes = createAction('Vendor', 'Submit onbooarding');

export const submitOnboardingAction = data => ({
  [RSAA]: {
    endpoint: 'vendors/onboarding',
    method: 'POST',
    body: data,
    types: [SubmitOnboardingTypes.REQUEST, SubmitOnboardingTypes.SUCCESS, SubmitOnboardingTypes.FAILURE],
  },
});
