import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Button, Container } from 'reactstrap';
import { Tile } from './Tile';

const ProductTilesComponent = ({ products, history, navigateToShop }) => {
  const tiles = useMemo(() => {
    const productTypes = [];

    return (
      products
        // eslint-disable-next-line
        ?.map(prod => {
          const typeAdded = productTypes.filter(type => type.name === prod.attributes?.type)?.length;

          if (!typeAdded && prod.attributes?.type) {
            const typeInfo = {
              name: prod.attributes?.type,
              image_url: prod.image_src,
            };

            productTypes.push(typeInfo);
            return typeInfo;
          }
        })
        .filter(Boolean)
    );
  }, [products]);

  if (!tiles || !tiles.length) return null;

  return (
    <>
      <hr id="product-types" />
      <section className="products-section">
        <Container>
          <h1 className="text-center mb-5">Shop by Type</h1>
          <Row className="d-flex justify-content-center">
            {tiles.map(tile => (
              <Tile key={tile.name} tile={tile} history={history} />
            ))}
          </Row>
          <Row className="mt-5 justify-content-center">
            <Button
              color="primary"
              size="lg"
              className="shop-all-button"
              onClick={() => navigateToShop({ position: 'vendor-shop-all' })}
            >
              Shop All
            </Button>
          </Row>
        </Container>
      </section>
    </>
  );
};

ProductTilesComponent.propTypes = {
  products: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  navigateToShop: PropTypes.func.isRequired,
};

export const ProductTilesSection = memo(ProductTilesComponent);
