import React from 'react';
import { Link } from 'react-router-dom';

export const ToS = () => {
  return (
    <small className="text-secondary mt-2 mb-4 mb-lg-0 extra-small text-center">
      <span>
        By continuing, you agree to Bottomless&apos;s
        <Link to="/terms-of-service.html" target="_blank" className="mx-1">
          Terms of Service
        </Link>
        and acknowledge the
        <Link to="/privacy.html" target="_blank" className="mx-1">
          Privacy Policy
        </Link>
      </span>
    </small>
  );
};
