import isNil from 'lodash-es/isNil';
import { getDiscountedPrice } from '@bottomless/common/components';

export const calculatePricePerOz = ({ variant, priceRule, isFirstBag }) =>
  Math.round(getDiscountedPrice(priceRule, variant.price * 100, isFirstBag, variant.size) / variant.size) / 100;

export const getProductPrice = ({ pricingRule, variant, isFirstBag = false }) => {
  const productCost = Math.round(Number(variant.price) * 100);

  if (!pricingRule) {
    return productCost;
  }

  if (isFirstBag && !isNil(pricingRule.first_bag_price) && (!variant.size || variant.size < 17)) {
    return pricingRule.first_bag_price;
  }

  if (isFirstBag && pricingRule.first_bag_discount) {
    return Math.round(productCost * (pricingRule.first_bag_discount || 1));
  }

  if (pricingRule.discount_fixed) {
    return Math.max(0, productCost - pricingRule.discount_fixed);
  }

  if (pricingRule.discount_proportion) {
    return Math.round(productCost * pricingRule.discount_proportion);
  }

  return productCost;
};
