export const AddToastType = '[Toasts] Add';
export const RemoveToastType = '[Toasts] Remove';

export const addToastAction = (message, type = 'info') => ({
  type: AddToastType,
  payload: { message, type, isOpen: true },
});

export const removeToastAction = id => ({
  type: RemoveToastType,
  payload: { id },
});
