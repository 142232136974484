import React from 'react';
import { FeatureSection } from '../../FeatureSection/FeatureSection';

export const UseContainer = () => (
  <FeatureSection
    text="The Bottomless scale works with any container! Or you can store the coffee bag on top."
    title="Use your own container"
    image="https://bottomless.imgix.net/assets/own-container.png?auto=compress,format"
    reverse
  />
);
