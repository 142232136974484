import { GetUserVendorTypes, CLEAR_USER_VENDOR } from './user.actions';

const initialState = {
  vendor: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetUserVendorTypes.SUCCESS:
      return {
        ...state,
        vendor: action.payload,
      };

    case CLEAR_USER_VENDOR:
      return {
        ...state,
        vendor: null,
      };

    default:
      return state;
  }
};
