import React from 'react';
import PropTypes from 'prop-types';
import { ProductCategoryManifest } from '../../../components/CategoryManifest';
import { CoffeeCheckoutShipTime, GenericCheckoutShipTime } from '../manifest';

const COMPONENTS = {
  CoffeeCheckoutShipTime: CoffeeCheckoutShipTime,
  GenericCheckoutShipTime: GenericCheckoutShipTime,
};

export const CheckoutShipTime = props => (
  <ProductCategoryManifest
    slugOrId={props.checkout.product?.product?._id}
    manifestKey="checkoutSummaryShipTime"
    components={COMPONENTS}
    fallbackComponent={GenericCheckoutShipTime}
    withPlaceholder
    {...props}
  />
);

CheckoutShipTime.propTypes = {
  checkout: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }),
    }),
    category: PropTypes.object.isRequired,
  }),
};
