import { GetGroceryPricingRuleTypes } from './pricing_rules.actions';

const initialState = {
  data: [],
  groceryPricingRule: null,
  isLoading: false,
};

export const pricingRuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetGroceryPricingRuleTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetGroceryPricingRuleTypes.SUCCESS:
      return {
        ...state,
        groceryPricingRule: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
