import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Link2 } from 'react-feather';
import { Box } from '../../Box';
import { Image } from '../../Image';

export const ScaleArrived = ({ noScaleImage }) => {
  return (
    <Box>
      <Row>
        <Col xs={6} md={noScaleImage ? 6 : 8}>
          <div className="d-flex align-items-center">
            {!noScaleImage && (
              <Image src="scale-sketch-transparent-2.png" alt="Scale" height="75" className="mr-3 image-scale" />
            )}
            <div className="small">
              <div className="font-weight-bold">Scale shipment</div>
              <a
                href="https://support.bottomless.com/en/articles/909568"
                target="_blank"
                rel="noopener noreferrer"
                className="small"
                onClick={e => e.stopPropagation()}
              >
                <Link2 width="12" height="12" className="mr-1" /> Set-Up Guide
              </a>
            </div>
          </div>
        </Col>
        <Col xs={6} md={noScaleImage ? 6 : 4} className="d-flex align-items-center justify-content-end">
          <div className="small text-right text-secondary">
            <div className="font-weight-bold">No data yet</div>
            <div>It will be available soon</div>
          </div>
        </Col>
      </Row>
    </Box>
  );
};

ScaleArrived.propTypes = {
  noScaleImage: PropTypes.bool,
};
