import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { activateScaleAction } from '../../../store';
import { StepsProgress } from '../../../components/StepsProgress';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { useMe } from '../../../hooks/useMe.hook';
import { PinForm } from './components';
import { useQueryString } from '@bottomless/common/hooks';
import { ScaleStatus } from '@bottomless/common/constants';
import { withCustomWording } from '../context/custom-wording-context';
import { customWording } from './customWording';

const ConnectStep1PageComponent = ({ activateScale }) => {
  const me = useMe();
  const {
    params: { chooseAccount, newScale },
  } = useQueryString();
  const history = useHistory();

  const onSuccess = useCallback(() => history.push('/activate/step2'), [history]);

  useEffect(() => {
    const hasLinkedScale = me.base_id && !me.base_id.startsWith('*');

    if (chooseAccount !== undefined) {
      history.push(
        me.accounts.length > 1 ? { pathname: '/change-account', state: { path: '/activate/step1' } } : '/activate/step1'
      );
    } else if (newScale === undefined && hasLinkedScale && me.scale_status === ScaleStatus.Connected) {
      history.push('/activate/connected');
    } else if (newScale === undefined && hasLinkedScale && me.scale_status === ScaleStatus.Disconnected) {
      history.push('/activate/disconnected');
    }
  }, [me, history, chooseAccount, newScale]);

  if (chooseAccount !== undefined) {
    return null;
  }

  return (
    <>
      <StepsProgress steps={3} activeStep={0} className="mb-5" />
      <h1 className="text-center mb-4">Activate your smart scale</h1>
      <PinForm onSubmit={activateScale} onSuccess={onSuccess} />
    </>
  );
};

ConnectStep1PageComponent.propTypes = {
  activateScale: PropTypes.func.isRequired,
};

export const ConnectStep1Page = withCustomWording(
  customWording,
  withMetaTags({
    title: 'Bottomless.com: Activate your smart scale',
  })(
    connect(null, dispatch => ({
      activateScale: data => dispatch(activateScaleAction(data)),
    }))(ConnectStep1PageComponent)
  )
);
