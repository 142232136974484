import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { fromOz } from '@bottomless/common/utils';

export const ProductAvatar = ({
  image,
  icon,
  productId,
  name,
  vendorId,
  vendorName,
  small,
  index,
  grind,
  size,
  variant,
}) => (
  <div
    className={classNames('d-flex mb-1 flex-column align-items-center product-avatar font-weight-bolder', {
      'up-next-avatar-small': small,
    })}
  >
    <div className="product-avatar-image-wrapper">
      {index && <div className="d-none my-product-number-overlay">{index}</div>}
      {icon && (
        <div className="potential-product-icon d-flex align-items-center justify-content-center mb-3">{icon}</div>
      )}
      {image && <img className="img-fluid mb-3 up-next-image" src={image} alt={name} />}
    </div>
    <div className="up-next-product-name text-center">
      <div className="text-secondary up-next-product-name-vendor">
        {vendorId && (
          <Link className="text-secondary" to={`shop?vendor_id=${vendorId}`}>
            {vendorName}
          </Link>
        )}
        {!vendorId && <div className="text-secondary">{vendorName}</div>}
      </div>
      <div className="up-next-product-name">
        {productId && (
          <Link className="text-dark" to={`shop?product_id=${productId}`}>
            {name}
          </Link>
        )}
        {!productId && <div className="text-dark">{name}</div>}
      </div>
      <div className="text-secondary default-product-grind">
        {grind} ({fromOz({ oz: size, unit: variant?.unit }).formatted})
      </div>
    </div>
  </div>
);

ProductAvatar.propTypes = {
  image: PropTypes.string,
  productId: PropTypes.string,
  name: PropTypes.string.isRequired,
  vendorId: PropTypes.string,
  vendorName: PropTypes.string,
  small: PropTypes.bool,
  icon: PropTypes.node,
  index: PropTypes.number,
  grind: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.object,
};
