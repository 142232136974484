import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Heart, Slash } from 'react-feather';
import { Modal, ModalHeader, ModalBody, Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import { DateFormat } from '@bottomless/common/components';
import { getUserAddress } from '../../utils/address';

const LikesDislikesModalComponent = ({ isOpen, toggle, feedback }) => {
  const [reviews] = useMemo(() => {
    const reviews = feedback
      .filter(feedback => feedback.like || feedback.dislike)
      .map(feedback => ({ ...feedback, timestamp: new Date(feedback.timestamp) }))
      .sort((a, b) => b.timestamp - a.timestamp);

    return [reviews];
  }, [feedback]);

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle} className="modal-likes-dislikes">
      <ModalHeader toggle={toggle}>Product Reviews</ModalHeader>
      <ModalBody>
        <Row>
          {reviews?.map(review => (
            <Col key={review._id} xs="6" md="4" className="mb-2">
              <Card>
                <CardBody>
                  <CardTitle>{review.user_id.first_name}</CardTitle>
                  <CardSubtitle>from {getUserAddress({ feedback: review })}</CardSubtitle>
                  <CardText>
                    {review.like && (
                      <p className="text-danger">
                        <Heart size="20" fill="currentColor" /> Liked the coffee
                      </p>
                    )}
                    {review.dislike && (
                      <p className="text-danger">
                        <Slash size="20" /> Disliked the coffee
                      </p>
                    )}
                    <DateFormat date={review.timestamp} />
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </ModalBody>
    </Modal>
  );
};

LikesDislikesModalComponent.propTypes = {
  feedback: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export const LikesDislikesModal = memo(LikesDislikesModalComponent);
