import { connect } from 'react-redux';
import { RSAA } from 'redux-api-middleware';

export const createAction = (domain, name) => ({
  REQUEST: `[REQUEST] ${domain}/${name}`,
  SUCCESS: `[SUCCESS] ${domain}/${name}`,
  FAILURE: `[FAILURE] ${domain}/${name}`,
});

const defaultMapStateToProps = () => ({});

export const connectWithAbort = (mapStateToProps, mapDispatchToProps) => {
  if (!mapDispatchToProps || typeof window.AbortController === 'undefined') {
    return connect(mapStateToProps, mapDispatchToProps);
  }

  let controller = new AbortController();
  let { signal } = controller;

  const newMapDispatchToProps = (dispatch, ownProps) => {
    const newDispatch = (action, args) => {
      if (action[RSAA] && action[RSAA].method.toLowerCase() === 'get') {
        return dispatch(
          { ...action, [RSAA]: { ...action[RSAA], options: { ...(action[RSAA].options || {}), signal } } },
          args
        );
      }

      return dispatch(action, args);
    };

    return mapDispatchToProps(newDispatch, ownProps);
  };

  const newMapStateToProps = (state, ownProps) => {
    const currentProps = (mapStateToProps || defaultMapStateToProps)(state, ownProps);

    const abortRequests = () => {
      if (ownProps.abortRequests) {
        ownProps.abortRequests();
      }

      controller.abort();

      controller = new AbortController();
      // eslint-disable-next-line prefer-destructuring
      signal = controller.signal;
    };

    return { ...currentProps, abortRequests };
  };

  return connect(newMapStateToProps, newMapDispatchToProps);
};

const singletonActions = {};

export const singletonAction = (action, name = 'all') => {
  if (singletonActions[name]) {
    singletonActions[name].abort();
  }

  if (action[RSAA]) {
    const controller = new AbortController();
    singletonActions[name] = controller;
    action[RSAA].options = { ...(action[RSAA].options || {}), signal: controller.signal };
  }

  return action;
};
