import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './PanelSection.scss';

export const PanelSection = ({ title, subtitle, titleAside, children, className }) => (
  <section className={classNames('panel-section', className)}>
    {title && (
      <header className="d-flex justify-content-between align-items-center">
        <div className="title-wrapper d-flex">
          <h5 className="title">{title}</h5>
          <div className="subtitle text-muted small font-italic">{subtitle}</div>
        </div>
        {titleAside}
      </header>
    )}
    <aside>{children}</aside>
  </section>
);

PanelSection.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  titleAside: PropTypes.node,
};
