import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { CheckboxBox } from '@bottomless/common/src/components';
import { BuildCard } from '../BuildCard';
import { useProductsCount } from '../../hooks/use-products-count.hook';

export const RoastComponent = ({ disabled, onChange, name, products, hideOptionWithoutProducts, optionFilter }) => {
  const roastPersonalized = useMemo(() => ({ ...optionFilter, roast: [name] }), [name, optionFilter]);
  const productCount = useProductsCount({
    products,
    personalized: roastPersonalized,
    optional: true,
  });

  if (hideOptionWithoutProducts && !productCount) {
    return <></>;
  }

  return (
    <Col lg="3" md="3" xs="4" className="answer-card-container">
      <CheckboxBox
        name={`roast.${name}`}
        label={<BuildCard name={name} />}
        value={true}
        disabled={disabled}
        onChange={onChange}
      />
    </Col>
  );
};

RoastComponent.propTypes = {
  disabled: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  name: PropTypes.string.isRequired,
  optionFilter: PropTypes.object,
};

export const Roast = React.memo(RoastComponent);
