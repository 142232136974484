import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/browser';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { Loader } from 'react-feather';
import * as Yup from 'yup';
import classnames from 'classnames';
import { Col, Row } from 'reactstrap';
import { Elements } from 'react-stripe-elements';
import { Form, Field, SubmitButton, AddressInput, withPlaceholder } from '@bottomless/common/components';
import { GoogleMapsLoaded } from '../../../components/Maps/GoogleMapsLoader';
import { EVENT_ADDED_CARD, trackEvent } from '../../../utils/tracker';
import { ToS } from '../../../components/ToS/ToS';
import { CardDetails } from './CardDetails';
import { StripeLoaded } from '../../../components/Stripe/StripeLoader';
import { StripeLoader } from './StripeLoader';

const BillingSchema = tokenRequired =>
  Yup.object().shape({
    token: tokenRequired ? Yup.string().required('This field is required') : Yup.string(),
    first_name: Yup.string().required('This field is required'),
    last_name: Yup.string().required('This field is required'),
    verifiedAddress: Yup.object()
      .shape({
        street1: Yup.string().required('This field is required'),
        city: Yup.string().required('This field is required'),
        zip: Yup.string().required('This field is required'),
        state: Yup.string().required('This field is required'),
        street2: Yup.string(),
      })
      .required(),
  });

const EMPTY_VALUES = {
  first_name: '',
  last_name: '',
  verifiedAddress: { state: 'AL', street1: '', street2: '', city: '', zip: '' },
  token: '',
};

const BillingDetailsComponent = ({
  checkout,
  onChange,
  onSetStripe,
  finishCheckout,
  onPaymentSuccess,
  allInOne,
  addToast,
  onRefreshCheckout,
  onChangeAddress,
  theme,
  className,
}) => {
  const form = useRef();
  const [error, setError] = useState(null);
  const [isProcessing, setProcessing] = useState(false);
  const [formSubmitCount, setFormSubmitCount] = useState(0);
  const {
    first_name = EMPTY_VALUES.first_name,
    last_name = EMPTY_VALUES.last_name,
    verifiedAddress = {
      street1: EMPTY_VALUES.verifiedAddress.street1,
      street2: EMPTY_VALUES.verifiedAddress.street2,
      city: EMPTY_VALUES.verifiedAddress.city,
      zip: EMPTY_VALUES.verifiedAddress.zip,
    },
  } = checkout;

  const previousCheckout = useRef(checkout);
  const stripe = useRef();

  const setStripe = useCallback(stripeInstance => (stripe.current = stripeInstance), []);

  useEffect(() => {
    if (
      form.current &&
      checkout._id &&
      !previousCheckout?.current?._id &&
      JSON.stringify(EMPTY_VALUES) === JSON.stringify(form.current.state.values)
    ) {
      form.current.resetForm({
        first_name,
        last_name,
        verifiedAddress: { state: 'AL', ...(verifiedAddress || {}) },
        token: '',
      });

      setShowStripeInput(!checkout.stripe_last_four);
    }

    previousCheckout.current = checkout;
  }, [checkout, previousCheckout, form, first_name, last_name, verifiedAddress]);

  const isEnabled = checkout.email && checkout.product;
  const [isSubmitting, setSubmitting] = useState(false);
  const [showStripeInput, setShowStripeInput] = useState(!checkout.stripe_last_four);
  const chosenPlace = useRef();
  const [isMissingCityReported, setMissingCityReported] = useState(false);

  useEffect(() => {
    let isComponentUnMounted = false;
    if (isMissingCityReported || isComponentUnMounted) {
      return;
    }

    if (formSubmitCount > 0 && chosenPlace.current?.address && !chosenPlace.current.address.locality) {
      Sentry.captureException(new Error('[Checkout] Missing city'), {
        extra: {
          checkoutId: checkout._id,
          ...chosenPlace.current,
        },
      });
      setMissingCityReported(true);
    }

    return () => {
      isComponentUnMounted = true;
    };
  }, [formSubmitCount, chosenPlace, setMissingCityReported, isMissingCityReported, checkout._id]);

  const onPayment = async () => {
    setSubmitting(true);

    try {
      const { payload } = await allInOne({ ...form.current?.state.values });

      if (payload) {
        trackEvent(EVENT_ADDED_CARD, { checkout });
      }

      const { error, payload: finishPayload } = await finishCheckout();

      if (!error) {
        onSetStripe(payload);
        return onPaymentSuccess();
      } else {
        if (finishPayload?.status === 409) {
          addToast('We are sorry, but your promo code is inactive', 'danger');
          await onRefreshCheckout();
          const { token } = await stripe.current.createToken({ name: 'card' });
          form.current.setFieldValue('token', token.id);

          if (onChange) {
            onChange({ token: token.id });
          }
          setSubmitting(false);
        }
      }
    } catch (e) {
      if (get(e, 'details.token', e.message) === '"token" is not allowed to be empty') {
        setError('Invalid card details');
      } else {
        addToast(get(e, 'details.token', e.message), 'danger');
        setError(get(e, 'details.token', e.message));
      }
    }

    setSubmitting(false);
  };

  const handleChange = setFieldValue => async e => {
    setError(null);

    if (e.complete) {
      setProcessing(true);
      try {
        const { token } = await stripe.current.createToken({ name: 'card' });
        setProcessing(false);
        setFieldValue('token', token.id);

        if (onChange) {
          onChange({ token: token.id });
        }
      } catch (e) {
        setProcessing(false);
        setError(e.message);
      }
    }
  };

  const handleSuccess = (_, { resetForm }) => {
    resetForm({ first_name, last_name, verifiedAddress, token: '' });
  };

  const onCardEdit = useCallback(() => setShowStripeInput(true), [setShowStripeInput]);

  const onPlaceChosen = useCallback(
    (placeId, address) => {
      chosenPlace.current = { placeId, address };
    },
    [chosenPlace]
  );

  const buttonStyle = useMemo(
    () =>
      theme?.buttonColor && {
        background: theme.buttonColor,
        borderColor: theme.buttonColor,
        boxShadow: 'none',
        opacity: 1,
      },
    [theme]
  );

  return (
    <div
      className={classnames(
        'h-100',
        {
          'text-secondary': !isEnabled,
        },
        className
      )}
    >
      <Form
        initialValues={{
          first_name,
          last_name,
          verifiedAddress: { state: EMPTY_VALUES.verifiedAddress.state, ...(verifiedAddress || {}) },
          token: '',
        }}
        validationSchema={BillingSchema(showStripeInput)}
        onSuccess={handleSuccess}
        onSubmit={onPayment}
        innerRef={form}
      >
        {({ dirty, values, setFieldValue, submitCount }) => {
          if (submitCount > formSubmitCount) {
            setTimeout(() => setFormSubmitCount(submitCount), 0);
          }

          return (
            <>
              <Row>
                <Col xs="12" sm="6">
                  <Field
                    name="first_name"
                    label="First name"
                    autocomplete="given-name"
                    onBlur={() => onChangeAddress({ values: form.current?.state.values })}
                  />
                </Col>
                <Col xs="12" sm="6">
                  <Field
                    name="last_name"
                    label="Last name"
                    autocomplete="family-name"
                    onBlur={() => onChangeAddress({ values: form.current?.state.values })}
                  />
                </Col>
              </Row>
              <GoogleMapsLoaded lazy>
                <AddressInput {...{ setFieldValue, values, onChangeAddress, form }} onPlaceChosen={onPlaceChosen} />
              </GoogleMapsLoaded>
              {isProcessing && (
                <div className="text-alerts-processing d-flex align-items-center justify-content-end">
                  Processing... <Loader size="13" className="spin ml-2" />
                </div>
              )}
              <div className="stripe-input">
                <StripeLoaded loader={StripeLoader}>
                  <Elements>
                    <CardDetails
                      checkout={checkout}
                      showStripeInput={showStripeInput}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      onCardEdit={onCardEdit}
                      setStripe={setStripe}
                    />
                  </Elements>
                </StripeLoaded>
              </div>

              <Field type="hidden" name="token" />
              {error && <div className="text-sm text-danger">{error}</div>}
              <SubmitButton
                disabled={!stripe || (!dirty && !checkout.stripe_last_four)}
                isSubmitting={isSubmitting}
                color="primary"
                block
                className="mt-3"
                size="md"
                style={buttonStyle}
              >
                Finish and Pay
              </SubmitButton>
              <ToS />
            </>
          );
        }}
      </Form>
    </div>
  );
};

BillingDetailsComponent.propTypes = {
  allInOne: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
  finishCheckout: PropTypes.func.isRequired,
  onSetStripe: PropTypes.func.isRequired,
  checkout: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    product: PropTypes.object,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    verifiedAddress: PropTypes.object,
    email: PropTypes.string,
    stripe_brand: PropTypes.string,
    stripe_last_four: PropTypes.string,
    preventReset: PropTypes.bool,
  }).isRequired,
  stripe: PropTypes.shape({
    createToken: PropTypes.func.isRequired,
    paymentRequest: PropTypes.func.isRequired,
  }),
  onChange: PropTypes.func,
  onRefreshCheckout: PropTypes.func.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    buttonColor: PropTypes.string,
  }),
  className: PropTypes.string,
};

export const BillingDetails = withPlaceholder({ condition: 'checkout', props: { checkout: {} } })(
  BillingDetailsComponent
);
