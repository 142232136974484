import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '../Box';

export const EmptyState = ({ title, description, children }) => (
  <Box className="text-center text-secondary small" secondary>
    {title && <div className="font-weight-bold mb-3">{title}</div>}
    <div className={children ? 'mb-3' : undefined}>{description}</div>
    {children}
  </Box>
);

EmptyState.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
};
