import React from 'react';
import { FeatureSection } from '../../FeatureSection/FeatureSection';

export const UseWithGrinder = () => (
  <FeatureSection
    text="Bottomless will learn your habits using a grinder. Simply enjoy fresh coffee and we will take care of the
              rest!"
    title="Use with a grinder"
    width="617"
    image="https://uploads-ssl.webflow.com/60632cfc862b4707c8632d4e/6067830aa7e78284af2ac6b4_grinder_gif_small%20(1)-poster-00001.jpg?auto=compress,format"
    video={[
      'https://uploads-ssl.webflow.com/606f47e81cb8f6f7bb617980/606f47e81cb8f682706179aa_grinder_gif_small%20(1)-transcode.mp4',
      'https://uploads-ssl.webflow.com/606f47e81cb8f6f7bb617980/606f47e81cb8f682706179aa_grinder_gif_small%20(1)-transcode.webm',
    ]}
  />
);
