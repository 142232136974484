import React from 'react';
import PropTypes from 'prop-types';
import { ProductCategoryManifest } from '../../../components/CategoryManifest';
import { CoffeeCheckoutHeading, GenericCheckoutHeading } from '../manifest';
import { withPlaceholder } from '@bottomless/common/components';

const COMPONENTS = {
  CoffeeCheckoutHeading: CoffeeCheckoutHeading,
  GenericCheckoutHeading: GenericCheckoutHeading,
};

const CheckoutHeadingComponent = props => (
  <ProductCategoryManifest
    slugOrId={props.checkout.product?.product?._id}
    manifestKey="checkoutHeading"
    components={COMPONENTS}
    fallbackComponent={GenericCheckoutHeading}
    withPlaceholder
    {...props}
  />
);

CheckoutHeadingComponent.propTypes = {
  checkout: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }),
    }),
    category: PropTypes.object.isRequired,
  }),
};

export const CheckoutHeading = withPlaceholder({
  condition: 'checkout',
  props: {
    checkout: {},
  },
})(CheckoutHeadingComponent);
