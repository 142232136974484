import React, { useEffect, useMemo } from 'react';
import { useQueryString } from '@bottomless/common/hooks';
import { StepsProgress } from '../../../components/StepsProgress';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { SetupScale } from './components';
import { withCustomWording } from '../context/custom-wording-context';
import { customWording } from './customWording';

const ConnectStep2PageComponent = () => {
  const { params, update } = useQueryString();
  const flow = useMemo(() => params.flow || 'first_step', [params.flow]);
  const timestamp = useMemo(() => params.timestamp || String(Date.now()), [params.timestamp]);

  useEffect(() => {
    if (params.flow !== flow || params.time !== timestamp) {
      update({ flow, timestamp });
    }
  }, [update, flow, timestamp, params]);

  return (
    <>
      <StepsProgress steps={3} activeStep={1} className="mb-5" />
      <h1 className="text-center mb-4">Activate your smart scale</h1>
      <SetupScale flow={flow} timestamp={timestamp} />
    </>
  );
};

export const ConnectStep2Page = withCustomWording(
  customWording,
  withMetaTags({
    title: 'Bottomless.com: Activate your smart scale',
  })(ConnectStep2PageComponent)
);
