import React from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import qs from 'query-string';
import { Loader } from '@bottomless/common/components';
import { getMeAction } from '../store';
import { useVendorLock } from '@bottomless/common/hooks';

const domain = process.env.REACT_APP_COOKIE_DOMAIN;

const setupIdentity = user => {
  if (/@bottomless.com$/.test(user.local.email)) {
    return;
  }

  const { _id, alertSettings, local, onboardingState } = user;

  if (window.hj) {
    window.hj('identify', _id, {
      email: user.local.email,
      username: `${user.first_name} ${user.last_name}`,
      local,
      alertSettings,
      onboardingState,
    });
  }
};

const IsAuthComponent = ({ children, me, isAuth, getMe, history, location }) => {
  const [{ hasLoggedIn }, setCookie, removeCookie] = useCookies(['hasLoggedIn']);
  const vendor = useVendorLock();

  useEffect(() => {
    getMe();
  }, [getMe]);

  if (me) {
    Sentry.configureScope(scope => {
      scope.setUser({ id: me._id, email: me.local.email, username: `${me.first_name} ${me.last_name}` });
    });

    setupIdentity(me);
  }

  if (
    !location.pathname.match(
      /^\/(grocery-order|custom_product|profile|data|pause|close_account|hibernate|free-bag|update-stripe|charges|shop|shop\/lists|shop\/rotations|shop\/other|account|support|subscription\/new|bring-your-own|variant_switch\/[a-f\d]{24})|change-account|orders|pets-pilot-program|haf-pilot-program|activate|setup-scale$/
    )
  ) {
    return children;
  }

  if (!isAuth) {
    return <Loader fullPage />;
  }

  if (!me) {
    removeCookie('hasLoggedIn');
    history.push(`/login?path=${location.pathname}${location.search}`);
    return null;
  }

  if (me.vendor_id && !vendor) {
    return <Loader fullPage />;
  }

  const isScaleActivationProcess =
    location.pathname.match(/\/activate/) ||
    location.pathname.match(/(scales\/assign|assign-scale|shopify)/) ||
    (location.pathname === '/change-account' && location.state?.path && location.state.path.match(/\/activate/));
  const isDataShopify = location.pathname === '/data/shopify';

  if (me.vendor_id && vendor.shopifyPortalUrl && !isScaleActivationProcess && !isDataShopify) {
    window.location.href = vendor.shopifyPortalUrl;
    return null;
  }

  if (me.onboardingState && me.onboardingState.paid === false) {
    if (me.onboardingState.accountCreated !== true) {
      history.push('/create_account');
    } else if (me.onboardingState.productChosen !== true) {
      history.push('/choose_product');
    } else if (me.onboardingState.packageChosen !== true) {
      history.push('/choose_package');
    } else if (me.onboardingState.eagernessChosen !== true) {
      history.push('/choose_eagerness');
    } else if (me.onboardingState.paid !== true) {
      history.push('/onboarding_billing_shipping');
    } else {
      history.push('/onboarding_success');
    }

    return null;
  }

  if (!hasLoggedIn) {
    setCookie('hasLoggedIn', true, { domain, path: '/' });

    const { skipAccountSelection, ...query } = qs.parse(location.search.replace(/%26/g, '&'));
    const stringifiedQuery = qs.stringify(query);
    const fullPath = `${location.pathname}${stringifiedQuery ? `?${stringifiedQuery}` : ''}`;

    const whitelisted = location.pathname.match(/^\/(profile|shop|account|orders|activate)$/);
    const blacklistedSearch = location.search.match(/\?onboarding=true/);

    if (whitelisted && !skipAccountSelection && !blacklistedSearch) {
      history.push({ pathname: '/change-account', state: { path: fullPath } });
    } else if (skipAccountSelection) {
      setTimeout(() => history.push(fullPath), 0);
    }
  }

  return children;
};

IsAuthComponent.propTypes = {
  children: PropTypes.node.isRequired,
  me: PropTypes.object,
  getMe: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    state: PropTypes.object,
  }).isRequired,
  isAuth: PropTypes.bool.isRequired,
};

export const IsAuth = connect(
  ({ user: { me }, auth: { isAuth } }) => ({ me, isAuth }),
  dispatch => ({ getMe: () => dispatch(getMeAction()) })
)(IsAuthComponent);
