import { Field as FormikField } from 'formik';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

export const Checkbox = props => <FormikField {...props} component={CheckboxComponent} />;

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const CheckboxComponent = ({ field, form, label, ...props }) => {
  const invalid = get(form.errors, field.name) && get(form.touched, field.name);
  const value = get(form.values, field.name);
  const isBooleanCheckbox = Boolean(value) === value;

  const checked = isBooleanCheckbox
    ? value
    : field.value === value ||
      (field.value === false && value === 'false') ||
      (field.value === true && value === 'true');

  const onChange = useCallback(
    event => {
      const val = isBooleanCheckbox ? event.target.checked : event.target.checked ? field.value : undefined;
      form.setFieldValue(field.name, val);
      if (props.onChange) {
        props.onChange(event);
      }
    },
    [isBooleanCheckbox, field, form, props]
  );

  return (
    <FormGroup check>
      <Label check className={`${invalid ? 'is-invalid' : ''}`}>
        <Input {...field} {...props} type="checkbox" invalid={invalid} onChange={onChange} checked={checked} />
        {label}
      </Label>
      <FormFeedback className="text-left">{get(form.errors, field.name)}</FormFeedback>
    </FormGroup>
  );
};

CheckboxComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  value: PropTypes.any,
};
