import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { CheckboxBox } from '@bottomless/common/src/components';
import { useProductsCount } from '../../hooks/use-products-count.hook';
import { BuildCard } from '../BuildCard';

const TastingCategoryComponent = ({
  category,
  onChange,
  products,
  hideOptionWithoutProducts,
  optionFilter,
  personalized,
}) => {
  const categoryPersonalizedForOptionFilter = useMemo(
    () => ({ ...optionFilter, tasting_note_category: [category._id] }),
    [optionFilter, category._id]
  );

  const categoryPersonalized = useMemo(() => ({ ...personalized, tasting_note_category: [category._id] }), [
    personalized,
    category._id,
  ]);

  const productCount = useProductsCount({
    products,
    personalized: categoryPersonalizedForOptionFilter,
    optional: true,
  });

  const tastingCategoryCount = useProductsCount({
    products,
    personalized: categoryPersonalized,
  });

  if (hideOptionWithoutProducts && !productCount) {
    return <></>;
  }

  return (
    <Col xs="6" md="3" key={category._id} className="answer-card-container">
      <div className="h-100" style={{ color: tastingCategoryCount === 0 ? 'grey' : 'black' }}>
        <CheckboxBox
          name={`tasting_note_category.${category._id}`}
          className="answer-card"
          label={
            <BuildCard
              name={`${category.name?.toLowerCase()} (${
                tastingCategoryCount || tastingCategoryCount === 0 ? tastingCategoryCount : '-'
              })`}
            />
          }
          onChange={onChange}
        />
      </div>
    </Col>
  );
};

TastingCategoryComponent.propTypes = {
  category: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    amount: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  optionFilter: PropTypes.object,
  personalized: PropTypes.object,
};

export const TastingCategory = React.memo(TastingCategoryComponent);
