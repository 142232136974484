import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetOrdersTypes = createAction('Orders', 'Get all');

export const getOrdersAction = () => ({
  [RSAA]: {
    endpoint: 'orders',
    method: 'GET',
    types: [GetOrdersTypes.REQUEST, GetOrdersTypes.SUCCESS, GetOrdersTypes.FAILURE],
  },
});

export const ProductFeedbackTypes = createAction('Orders', 'Product feedback');

export const productFeedbackAction = (id, data, product_id) => ({
  [RSAA]: {
    endpoint: `orders/${id}/product-feedback`,
    method: 'POST',
    body: data,
    types: [
      { type: ProductFeedbackTypes.REQUEST, payload: { ...data, product_id } },
      ProductFeedbackTypes.SUCCESS,
      ProductFeedbackTypes.FAILURE,
    ],
  },
});

export const ModifySlowLocalOrderTypes = createAction('Slow Local Orders', 'Modify');

export const modifySlowLocalOrderAction = data => ({
  [RSAA]: {
    endpoint: 'orders/slow-local/modify-products',
    method: 'POST',
    body: data,
    types: [ModifySlowLocalOrderTypes.REQUEST, ModifySlowLocalOrderTypes.SUCCESS, ModifySlowLocalOrderTypes.FAILURE],
  },
});

export const GetInTransitionOrdersTypes = createAction('Orders', 'Get in transition');

export const getInTransitionOrdersAction = () => ({
  [RSAA]: {
    endpoint: 'orders/in-transition',
    method: 'GET',
    types: [GetInTransitionOrdersTypes.REQUEST, GetInTransitionOrdersTypes.SUCCESS, GetInTransitionOrdersTypes.FAILURE],
  },
});

export const GetInTransitionCountTypes = createAction('Orders', 'Get in transition count');

export const getInTransitionCountAction = () => ({
  [RSAA]: {
    endpoint: 'orders/in-transition/count',
    method: 'GET',
    types: [GetInTransitionCountTypes.REQUEST, GetInTransitionCountTypes.SUCCESS, GetInTransitionCountTypes.FAILURE],
  },
});

export const GetSlowLocalInTransitionOrdersTypes = createAction('Orders', 'Get slow local in transition');

export const getSlowLocalInTransitionOrdersAction = () => ({
  [RSAA]: {
    endpoint: 'orders/slow-local/in-transition',
    method: 'GET',
    types: [
      GetSlowLocalInTransitionOrdersTypes.REQUEST,
      GetSlowLocalInTransitionOrdersTypes.SUCCESS,
      GetSlowLocalInTransitionOrdersTypes.FAILURE,
    ],
  },
});

export const GetSlowLocalOrderTypes = createAction('Orders', 'Get slow local order');

export const getSlowLocalOrderAction = id => ({
  [RSAA]: {
    endpoint: `orders/slow-local/${id}`,
    method: 'GET',
    types: [GetSlowLocalOrderTypes.REQUEST, GetSlowLocalOrderTypes.SUCCESS, GetSlowLocalOrderTypes.FAILURE],
  },
});

export const GetPrevSlowLocalOrdersTypes = createAction('Orders', 'Get previous slow local orders');

export const getPrevSlowLocalOrdersAction = () => ({
  [RSAA]: {
    endpoint: 'orders/slow-local/get-order-history',
    method: 'GET',
    types: [
      GetPrevSlowLocalOrdersTypes.REQUEST,
      GetPrevSlowLocalOrdersTypes.SUCCESS,
      GetPrevSlowLocalOrdersTypes.FAILURE,
    ],
  },
});

export const GetFutureOrdersTypes = createAction('Orders', 'Get future');

export const getFutureOrdersAction = () => ({
  [RSAA]: {
    endpoint: 'orders/future',
    method: 'GET',
    types: [GetFutureOrdersTypes.REQUEST, GetFutureOrdersTypes.SUCCESS, GetFutureOrdersTypes.FAILURE],
  },
});

export const GetOrderTypes = createAction('Orders', 'Get order');

export const getOrderAction = id => ({
  [RSAA]: {
    endpoint: `orders/${id}`,
    method: 'GET',
    types: [GetOrderTypes.REQUEST, GetOrderTypes.SUCCESS, GetOrderTypes.FAILURE],
  },
});

export const GetOrderStatusTypes = createAction('Orders', 'Get order status');

export const getOrderStatusAction = id => ({
  [RSAA]: {
    endpoint: `orders/${id}/status`,
    method: 'GET',
    types: [GetOrderStatusTypes.REQUEST, GetOrderStatusTypes.SUCCESS, GetOrderStatusTypes.FAILURE],
  },
});

export const GetBottomlessExpectedArrivalTypes = createAction('Orders', 'Get bottomless expected arrival date');

export const getBottomlessExpectedArrivalAction = id => ({
  [RSAA]: {
    endpoint: `orders/bottomless-expected-delivery/${id}`,
    method: 'GET',
    types: [
      GetBottomlessExpectedArrivalTypes.REQUEST,
      GetBottomlessExpectedArrivalTypes.SUCCESS,
      GetBottomlessExpectedArrivalTypes.FAILURE,
    ],
  },
});

export const SetProductTypes = createAction('Orders', 'Set order');

export const setProductAction = (orderId, productId, variantId) => ({
  [RSAA]: {
    endpoint: `orders/${orderId}/set/${productId}/${variantId}`,
    method: 'POST',
    types: [SetProductTypes.REQUEST, SetProductTypes.SUCCESS, SetProductTypes.FAILURE],
  },
});

export const GetSelectionTypes = createAction('Orders', 'Get Selections');

export const getSelectionAction = orderId => ({
  [RSAA]: {
    endpoint: `orders/selection/${orderId}`,
    method: 'GET',
    types: [GetSelectionTypes.REQUEST, GetSelectionTypes.SUCCESS, GetSelectionTypes.FAILURE],
  },
});

export const TriggerOrderAction = createAction('Orders', 'Trigger order');

export const triggerOrderAction = data => ({
  [RSAA]: {
    endpoint: 'orders/future/trigger',
    method: 'POST',
    body: data,
    types: [TriggerOrderAction.REQUEST, TriggerOrderAction.SUCCESS, TriggerOrderAction.FAILURE],
  },
});

export const CancelOrderTypes = createAction('Orders', 'Cancel order');

export const cancelOrderAction = id => ({
  [RSAA]: {
    endpoint: `orders/${id}/cancel`,
    method: 'POST',
    types: [CancelOrderTypes.REQUEST, CancelOrderTypes.SUCCESS, CancelOrderTypes.FAILURE],
  },
});

export const ReportOrderProblemAction = createAction('Orders', 'Report order problem');

export const reportOrderProblemAction = (id, data) => ({
  [RSAA]: {
    endpoint: `orders/${id}/report-problem`,
    method: 'POST',
    body: data,
    types: [ReportOrderProblemAction.REQUEST, ReportOrderProblemAction.SUCCESS, ReportOrderProblemAction.FAILURE],
  },
});

export const SetShipmentProviderTypes = createAction('Orders', 'Set shipment provider');

export const setShipmentProviderAction = (id, data) => ({
  [RSAA]: {
    endpoint: `orders/${id}/shipment-provider`,
    method: 'POST',
    body: data,
    types: [SetShipmentProviderTypes.REQUEST, SetShipmentProviderTypes.SUCCESS, SetShipmentProviderTypes.FAILURE],
  },
});

export const SetSlowLocalPreferencesTypes = createAction('Orders', 'Set Slow Local preferences');

export const setSlowLocalPreferencesAction = data => ({
  [RSAA]: {
    endpoint: 'slow-local-orders/preferences',
    method: 'POST',
    body: data,
    types: [
      SetSlowLocalPreferencesTypes.REQUEST,
      SetSlowLocalPreferencesTypes.SUCCESS,
      SetSlowLocalPreferencesTypes.FAILURE,
    ],
  },
});
