import React from 'react';
import PropTypes from 'prop-types';

export const Battery = ({ batteryLevel, hide, className }) => {
  if (hide || batteryLevel < 0) {
    return null;
  }

  const color = batteryLevel < 0.5 ? 'coral' : 'text-success';
  let icon;
  if (batteryLevel <= 0.2) {
    icon = 'battery-empty';
  } else if (batteryLevel < 0.5) {
    icon = 'battery-quarter';
  } else if (batteryLevel < 0.75) {
    icon = 'battery-half';
  } else if (batteryLevel < 0.9) {
    icon = 'battery-three-quarters';
  } else {
    icon = 'battery-full';
  }

  return (
    <span className={className}>
      <i className={`${color} fa fa-${icon}`} />
    </span>
  );
};

Battery.defaultProps = {
  batteryLevel: 0,
  hide: false,
};

Battery.propTypes = {
  batteryLevel: PropTypes.number,
  hide: PropTypes.bool,
  className: PropTypes.string,
};
