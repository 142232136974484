import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useOnScreen } from '../../hooks/use-on-screen';

const baseUrl = 'https://bottomless.imgix.net';
const defaultSrc = 'https://bottomless.imgix.net/images/no-image.png';

const normalizePath = src => {
  if (!src) {
    src = defaultSrc;
  }

  let path = src.replace(baseUrl, '').replace('http://bottomless.imgix.net', '');
  path = path.indexOf('/') === 0 ? path.substr(1) : path;
  path = path.indexOf('?') > -1 ? path.substring(0, path.indexOf('?')) : path;

  return path;
};

export const Image = ({
  src,
  alt,
  width,
  height,
  maxWidth,
  maxHeight,
  fit,
  withDpr,
  className,
  wrapperClassName,
  trim,
  transition,
  useSrcset,
}) => {
  const query = ['auto=compress,format'];
  const [animate, setAnimate] = useState(false);
  const ref = useRef();
  const onScreen = useOnScreen(ref, '-100px');

  query.push(`fit=${fit ? fit : 'crop'}`);

  if (maxWidth) {
    query.push(`max-w=${maxWidth}`);
  }

  if (maxHeight) {
    query.push(`max-h=${maxHeight}`);
  }

  if (width) {
    query.push(`fit=clip`, `w=${width}`);
  }

  if (height) {
    query.push(`fit=clip`, `h=${height}`);
  }

  if (trim) {
    query.push(`trim=color`);
  }

  if (withDpr) {
    query.push(`dpr=${window.devicePixelRatio || 1}&fit=max`);
  }

  const placeholder = `${baseUrl}/${normalizePath(src)}?${query.join('&')}&fit=max&q=10&blur=10`;

  useEffect(() => {
    if (onScreen && !animate) {
      setAnimate(true);
    }
  }, [animate, onScreen]);

  const srcSet = useMemo(() => {
    if (!useSrcset || (!width && !height)) {
      return undefined;
    }

    let doubleQuery = [...query];

    if (width) {
      doubleQuery = doubleQuery.map(param => (param.startsWith('w=') ? `w=${width * 2}` : param));
    }

    if (height) {
      doubleQuery = doubleQuery.map(param => (param.startsWith('h=') ? `h=${height * 2}` : param));
    }

    return `${baseUrl}/${normalizePath(src)}?${query.join('&')} 1x, ${baseUrl}/${normalizePath(src)}?${doubleQuery.join(
      '&'
    )} 2x`;
  }, [useSrcset, src, width, height, query]);

  return (
    <span ref={ref} className={wrapperClassName}>
      <LazyLoadImage
        alt={alt}
        threshold={400}
        className={`img-fluid ${className} ${transition ? 'animated img-animated ' : ''} ${animate ? transition : ''}`}
        placeholderSrc={placeholder}
        src={`${baseUrl}/${normalizePath(src)}?${query.join('&')}`}
        srcSet={srcSet}
      />
    </span>
  );
};

Image.defaultProps = {
  withDpr: false,
  trim: false,
  className: '',
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fit: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withDpr: PropTypes.bool,
  trim: PropTypes.bool,
  transition: PropTypes.string,
  useSrcset: PropTypes.bool,
};
