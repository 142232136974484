import * as Sentry from '@sentry/browser';
import { RSAA } from 'redux-api-middleware';
import { v4 } from 'uuid';
import { AddAlertType, removeAlertAction } from './alert/alert.actions';
import { ValidationError } from './errors';
import { addToastAction, AddToastType, removeToastAction } from './toast/toast.actions';

export const endpointMiddleware = () => next => action => {
  const callAPI = action[RSAA];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const host = process.env.REACT_APP_BACKEND_URL.replace(/\/$/, '');
  const prefix = (process.env.REACT_APP_BACKEND_PREFIX || 'api').replace(/^\/|\/$/g, '');
  const sufix = callAPI.endpoint.replace(/^\//, '');
  const endpoint = `${host}/${prefix}/${sufix}`.replace(/^\/\//, '/');
  const authToken = localStorage.getItem('bottomless_access_token');
  const types = [
    callAPI.types[0],
    callAPI.types[1],
    {
      type: callAPI.types[2],
      meta: action => {
        if (!action[RSAA]) {
          return undefined;
        }

        return { method: action[RSAA].method || 'GET' };
      },
    },
  ];

  return next({
    [RSAA]: {
      ...callAPI,
      types,
      endpoint,
      credentials: 'include',
      ...(authToken ? { headers: { ...(callAPI.headers || {}), Authorization: authToken } } : {}),
    },
  });
};

export const bodyMiddleware = () => next => action => {
  const callAPI = action[RSAA];

  if (typeof callAPI === 'undefined' || !callAPI.body) {
    return next(action);
  }

  let headers = { ...callAPI.headers, 'Content-Type': 'application/json' };
  let body = JSON.stringify(callAPI.body);

  if (callAPI.headers && callAPI.headers.upload) {
    headers = {};
    ({ body } = callAPI);
  }

  return next({
    [RSAA]: {
      ...callAPI,
      headers,
      body,
    },
  });
};

export const errorMiddleware = ({ dispatch }) => next => action => {
  if (action.payload && action.payload.status === 400) {
    const error = action.payload.response?.message ? action.payload.response.message : '';
    const hideToast = action.payload.response?.hideToast;

    if (error && !hideToast) {
      dispatch(addToastAction(error, 'error'), 'error');
    }
    throw new ValidationError(action.payload.response.message, action.payload.response.details);
  }

  if (
    action.payload &&
    action.payload.status === 401 &&
    action.meta?.method &&
    action.meta.method.toLowerCase() !== 'get'
  ) {
    const error = action.payload.response?.message ? action.payload.response.message : 'You must be logged in.';

    throw new ValidationError(error);
  }

  if (action.payload && action.payload.status === 500) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(new Error(action.payload.response.message));
    }
    // eslint-disable-next-line no-console
    console.error(`Something went wrong: ${action.payload.response.message}`);
    dispatch(addToastAction('Oops, something went wrong. Please try again later or contact support.'), 'error');
    return;
  }

  return next(action);
};

export const toastMiddleware = ({ dispatch }) => next => action => {
  if (action.type === AddToastType) {
    const id = v4();
    setTimeout(() => dispatch(removeToastAction(id)), 3000);
    return next({ ...action, payload: { ...action.payload, id } });
  }

  return next(action);
};

export const alertMiddleware = ({ dispatch }) => next => action => {
  if (action.type === AddAlertType) {
    const id = v4();
    setTimeout(() => dispatch(removeAlertAction(id)), 5000);
    return next({ ...action, payload: { ...action.payload, id } });
  }

  return next(action);
};
