import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const PageViewTracker = () => {
  const history = useHistory();
  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    if (!initiated) {
      setInitiated(true);
    } else if (window.gtag) {
      // eslint-disable-next-line no-unused-vars
      let prevLocation;
      history.listen(nextLocation => {
        window.gtag('event', 'page_view', {
          page_title: document.title,
          page_location: window.location.href,
          page_path: window.location.pathname,
          send_to: process.env.REACT_APP_GA_ID,
        });
        prevLocation = nextLocation;
      });
    }
  }, [initiated, history]);

  return null;
};
