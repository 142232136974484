import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { Review } from './Review';

const DisplayReviewsComponent = ({ currentReviews }) => {
  return (
    <Row>
      {currentReviews?.map(review => (
        <Review key={review._id} feedback={review} />
      ))}
    </Row>
  );
};

DisplayReviewsComponent.propTypes = {
  currentReviews: PropTypes.array,
};

export const DisplayReviews = memo(DisplayReviewsComponent);
