import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';
import { Field as FormikField } from 'formik';
import classNames from 'classnames';
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { CheckCircle, Circle } from 'react-feather';
import './RadioBox.scss';

const RadioBoxComponent = ({
  field,
  form,
  label,
  description,
  size,
  onChange,
  labelClassName,
  labelInnerClassName,
  ...props
}) => {
  const invalid = get(form.errors, field.name) && get(form.touched, field.name);
  const value = get(form.values, field.name);

  const checked =
    field.value === value || (field.value === false && value === 'false') || (field.value === true && value === 'true');

  return (
    <FormGroup check className={classNames('radio-box mb-3', { [`radio-box-${size}`]: size })}>
      <Label check className={classNames('d-flex align-items-center', labelClassName, { 'label-checked': checked })}>
        {checked ? (
          <CheckCircle width="20" height="20" role="img" aria-label="Checked" />
        ) : (
          <Circle width="20" color="rgba(0, 0, 0, 0.125)" height="20" role="img" aria-label="Unchecked" />
        )}
        <Input
          className="d-none"
          {...field}
          {...props}
          type="radio"
          invalid={invalid}
          checked={checked}
          onChange={event => {
            field.onChange(event);

            if (onChange) {
              onChange(event);
            }
          }}
        />
        <div className={classNames('ml-3 radio-box-label', labelInnerClassName)}>
          <div className={`h6 text-uppercase ${description ? 'mb-1' : 'mb-0'}`}>{label}</div>
          {description && <div className="small text-secondary">{description}</div>}
        </div>
      </Label>
      <FormFeedback className="text-left">{get(form.errors, field.name)}</FormFeedback>
    </FormGroup>
  );
};

RadioBoxComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
  labelClassName: PropTypes.string,
  labelInnerClassName: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
};

export const RadioBox = props => <FormikField {...props} component={RadioBoxComponent} />;

RadioBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
};
