import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { Loader } from 'react-feather';
import classNames from 'classnames';
import { UserAvatar } from '@bottomless/common/src/components';
import { ScaleStatus } from './ScaleStatus';
import './Account.scss';

export const Account = ({ account, choosingAccount, onAccountChosen, disabled, useProductName, hideStatus }) => {
  const onClick = useCallback(() => onAccountChosen(account), [onAccountChosen, account]);

  return (
    <div
      role="button"
      className={classNames('d-flex flex-column align-items-center text-center', { 'cursor-not-allowed': disabled })}
      onClick={disabled ? undefined : onClick}
    >
      <Card
        body
        className={classNames('card-account justify-content-center mb-2 position-relative', {
          'card-account-disabled': disabled,
        })}
      >
        {choosingAccount && (
          <div className="account-choosing d-flex align-items-center justify-content-center">
            <Loader size="16" className="spin mr-1" /> Choosing...
          </div>
        )}
        <UserAvatar user={account} />
      </Card>
      <span className={classNames({ 'text-secondary': disabled })}>
        {useProductName ? account.product.product.name : account.name}
      </span>
      {!hideStatus && <ScaleStatus user={account} />}
    </div>
  );
};

Account.propTypes = {
  account: PropTypes.shape({
    name: PropTypes.string.isRequired,
    product: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        small_image_src: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  choosingAccount: PropTypes.bool,
  onAccountChosen: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  useProductName: PropTypes.bool,
  hideStatus: PropTypes.bool,
};
