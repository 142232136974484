import React from 'react';
import PropTypes from 'prop-types';
import { FeatureSection } from '../../FeatureSection/FeatureSection';

export const TryItRisk = ({ actionText, onClick }) => (
  <FeatureSection
    image="https://bottomless.imgix.net/assets/risk-free.png?auto=format,compress"
    text="With other subscriptions you either have too much or too little. With Bottomless you never have to worry about this ever again!"
    title="Try it risk-free cancel anytime"
    reverse
    actionText={actionText}
    onClick={onClick}
  />
);

TryItRisk.propTypes = {
  actionText: PropTypes.string,
  onClick: PropTypes.func,
};
