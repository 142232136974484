import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FilterCheckbox } from './FilterCheckbox';

export const FilterElement = ({ element, onChange, name, i, products, filters, intersect, hideNumber }) => {
  let counter = 0;

  const incr = () => {
    counter++;
  };

  if (element.items && element.items.length > 0) {
    return (
      <div className="mb-2">
        <div className="d-block">{element.name}</div>
        {element.items.map(node => (
          <Fragment key={node._id}>
            <FilterCheckbox
              key={node._id}
              label={node.name}
              filters={filters}
              products={products}
              value={node._id}
              title={node.name}
              name={`${name}[${counter}]`}
              filterName={name}
              onChange={onChange}
              intersect={intersect}
              hideNumber={hideNumber}
            />
            {incr()}
          </Fragment>
        ))}
      </div>
    );
  }

  return (
    <FilterCheckbox
      label={element.name}
      filters={filters}
      products={products}
      value={element._id}
      name={`${name}[${i}]`}
      filterName={name}
      onChange={onChange}
      intersect={intersect}
      hideNumber={hideNumber}
    />
  );
};

FilterElement.propTypes = {
  element: PropTypes.shape({
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  intersect: PropTypes.bool,
  hideNumber: PropTypes.bool,
};
