import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Col, Container, Row } from 'reactstrap';
import { Image } from '@bottomless/common/components';
import './FeatureSection.scss';

export const FeatureSection = ({ title, text, image, video, reverse, actionText, onClick, width }) => {
  return (
    <div
      className={classNames('d-flex landing-section', {
        'landing-section-reverse': !!reverse,
      })}
    >
      <Container>
        <Row className="align-items-center text-lg-left text-center">
          <Col xs="12" lg="6">
            <h1 className="landing-section-title">{title}</h1>
            <p className="landing-section-text">{text}</p>
            {actionText && onClick && (
              <Button color="primary" size="lg" className="landing-section-button mt-3" onClick={onClick}>
                {actionText}
              </Button>
            )}
          </Col>
          <Col xs="12" lg="6">
            {video && (
              <video
                poster={image}
                width={width}
                autoPlay
                loop
                muted
                playsInline
                className="img-fluid landing-section-image landing-section-video"
              >
                {video.map((src, i) => (
                  <source key={i} src={src} width={width} />
                ))}
              </video>
            )}
            {!video && image && <Image src={image} alt={title} width={width} className="feature-image" />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

FeatureSection.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  reverse: PropTypes.bool,
  actionText: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  video: PropTypes.arrayOf(PropTypes.string),
};
