import { useMemo } from 'react';
import isNil from 'lodash-es/isNil';

export default function useTotal(pricingRule, firstProduct, variant, checkout, pricing) {
  const total = useMemo(() => {
    if (checkout && checkout._id) {
      const product = (checkout && checkout.product && checkout.product.product) || {};
      const variant = product.variants && product.variants.find(variant => variant._id === checkout.product.variant);
      return variant && getTotal(variant, product, checkout.pricing_rule, checkout.dynamicPricing);
    } else if (pricingRule && firstProduct && variant) {
      return getTotal(variant, firstProduct, pricingRule, pricing);
    }
  }, [checkout, pricingRule, firstProduct, variant, pricing]);

  return total;
}

const getTotal = (variant, orderProduct, pricingRule, dynamicPricing) => {
  const signup = pricingRule.signup_fee || 0;
  const shipping = getShippingPrice(pricingRule);
  const product = getProductPrice(pricingRule, variant, orderProduct, dynamicPricing);
  const total = signup + product + shipping;

  return { signup, product, shipping, total };
};

// eslint-disable-next-line no-unused-vars
const getProductPrice = (pricingRule, variant, product, dynamicPricing) => {
  const price = dynamicPricing ? dynamicPricing.price : variant.price;
  const productCost = Math.round(Number(price) * 100); // convert to cents

  if (!isNil(pricingRule.first_bag_price) && variant.size < 17) {
    return pricingRule.first_bag_price;
  }

  if (pricingRule.first_bag_discount) {
    return Math.round(productCost * (pricingRule.first_bag_discount || 1));
  }

  if (pricingRule.discount_fixed) {
    return Math.max(0, productCost - pricingRule.discount_fixed);
  }

  if (pricingRule.discount_proportion) {
    return Math.round(productCost * pricingRule.discount_proportion);
  }

  return productCost;
};

const getShippingPrice = pricingRule => {
  if (!isNaN(pricingRule.first_shipping_price)) {
    return pricingRule.first_shipping_price;
  }

  if (pricingRule.free_shipping) {
    return 0;
  }

  if (pricingRule.shipping_price) {
    return pricingRule.shipping_price;
  }

  return 0;
};
