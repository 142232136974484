import React from 'react';
import { Image } from '@bottomless/common/components';

export const Ag1Setup = () => {
  return (
    <div className="text-center mb-4">
      <Image src="/connect-scale/ag1/smart-canister.png" alt="Smart Canister" width="200" useSrcset className="mb-4" />
      <div>When you see the green lights flash, place the canister on a flat surface with your scoop inside.</div>
      <br />
      <div>This will set the smart canister to &quot;zero level&quot; before you add your AG1.</div>
    </div>
  );
};

Ag1Setup.propTypes = {};
