import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box, Image } from '@bottomless/common/components';
import { ScaleFaq } from './ScaleFaq';
import { ScaleLoading } from './ScaleLoading';
import { useCustomWording } from '../../hooks/use-custom-wording';
import { useIsAg1 } from '../../hooks/use-is-ag1';

export const ConnectScale = ({ isConnected, onConnectedFinished }) => {
  const isAg1 = useIsAg1();
  const { cw } = useCustomWording();
  const [isOpen, setOpen] = useState(false);

  const onClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(true);
  }, []);

  return (
    <>
      <Box secondary className="mb-4">
        <h2 className="text-center mb-4">{cw('Connect your scale to Wi-Fi')}</h2>
        <div className="text-center mb-3">
          On your smartphone or computer, go to Wi-Fi settings, then select the network named{' '}
          <strong>&quot;Bottomless_Scale&quot;</strong>.
        </div>
        <div className="text-center">
          <Image src="/connect-scale/wifi-list.jpg" alt="Wi-Fi list" width="250" useSrcset className="mb-4" />
        </div>
        <div className={classNames('text-center', { 'mb-5': !isAg1, 'mb-3': isAg1 })}>
          Wait for a pop-up on your device and follow the displayed instructions to complete the connection.
        </div>
        {isAg1 && (
          <div className="text-center mb-5">
            Your device will automatically reconnect to Wi-Fi once the setup is complete.
          </div>
        )}
        <ScaleLoading className="mb-5" isConnected={isConnected} onConnectedFinished={onConnectedFinished} />
        <div className="text-center mt-3">
          <a href="#" onClick={onClick}>
            Need help? See FAQ
          </a>
        </div>
      </Box>
      {isOpen && <ScaleFaq />}
    </>
  );
};

ConnectScale.propTypes = {
  isConnected: PropTypes.bool,
  onConnectedFinished: PropTypes.func.isRequired,
};
