import { useEffect, useState } from 'react';

export const useOnScreen = (ref, rootMargin = '0px', onlyOnce = false) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    let wasIntersecting = false;
    try {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!onlyOnce || !wasIntersecting) {
            setIntersecting(entry.isIntersecting);
            wasIntersecting = entry.isIntersecting;
          }
        },
        {
          rootMargin,
        }
      );
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return setIntersecting(true);
    }
  }, []);

  return isIntersecting;
};
