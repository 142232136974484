import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import classNames from 'classnames';
import './TimeToFulfill.scss';

const pstTimezone = 'America/Los_Angeles';

export const TimeToFulfill = ({ center = false, className, timerText }) => {
  const formatNumber = useCallback(value => ('0' + value).substr(-2), []);

  const getDiff = useCallback(() => {
    const nowInSeattle = momentTimezone().tz(pstTimezone);

    const day = nowInSeattle.day();
    const increment = day === 5 ? 3 : day === 6 ? 2 : 1;
    const nextWorkingDay = moment(nowInSeattle).add(increment, 'day');
    const midnight = moment(nextWorkingDay).startOf('day');

    return moment.duration(midnight.diff(nowInSeattle));
  }, []);

  const [diff, setDiff] = useState(getDiff());

  useEffect(() => {
    const interval = setInterval(() => setDiff(getDiff()), 200);
    return () => clearInterval(interval);
  }, [getDiff, setDiff]);

  if (diff.asMilliseconds() <= 0 || diff.days()) {
    return null;
  }

  return (
    <div
      className={classNames('d-flex flex-column align-items-center', className, {
        'align-items-lg-center': center,
        'align-items-lg-start': !center,
      })}
    >
      <div className="text-center text-lg-left small">
        {timerText || 'Time to complete so it gets processed tomorrow'}
      </div>
      <strong className="mt-1">
        {formatNumber(diff.hours())}h {formatNumber(diff.minutes())}m {formatNumber(diff.seconds())}s
      </strong>
    </div>
  );
};

TimeToFulfill.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
  timerText: PropTypes.string,
};
