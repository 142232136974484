import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Form, Field, SubmitButton, Loader } from '@bottomless/common/components';
import { Auth } from '../../layouts/Auth/Auth';
import { loginAction } from '../../store/auth';
import { getMeAction, getVendorAction } from '../../store';
import { withMetaTags } from '../../components/MetaTags/MetaTags';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid e-mail address')
    .required('This field is required'),
  password: Yup.string().required('This field is required'),
});

export const VendorLockedLoginPageComponent = ({ login, getMe, history, location, match, getVendor, vendor }) => {
  const [isFetchingVendor, setIsFetchingVendor] = useState(true);

  useEffect(() => {
    (async () => {
      await getVendor(match.params.slug);
      setIsFetchingVendor(false);
    })();
  }, [getVendor, match.params.slug]);

  const onLogin = async () => {
    await getMe();

    const query = qs.parse(location.search);

    if (query.path) {
      history.push(query.path);
    } else {
      history.push(`/change-account`);
    }
  };

  if (!vendor && isFetchingVendor) {
    return <Loader fullPage />;
  }

  return (
    <Auth isVendorLocked>
      <div className="d-flex justify-content-center mb-5">
        <img
          src={vendor?.logo || '/images/Bottomless-logo-blue.png'}
          alt={vendor?.name}
          height={vendor?.logo ? '140' : '30'}
        />
      </div>
      <div className="pb-md-5 pb-3">
        <Form
          initialValues={{ email: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={login}
          onSuccess={onLogin}
        >
          {({ isSubmitting }) => (
            <>
              <Field name="email" type="email" label="E-mail" />
              <Field name="password" type="password" label="Password" />
              <SubmitButton size={null} isSubmitting={isSubmitting} loadingText="Logging in" block>
                Log In
              </SubmitButton>
            </>
          )}
        </Form>
        <div className="small d-flex justify-content-end mt-2 mb-3">
          <Link to="/forgot">Forgot your password?</Link>
        </div>
        <div className="d-flex align-items-center my-4 text-secondary small">
          <span className="border-bottom w-100" style={{ height: '1px' }} />
          <span className="text-black-50 mx-2">OR</span>
          <span className="border-bottom w-100" style={{ height: '1px' }} />
        </div>
        <Link
          to={`/send-magic-link${location.search}`}
          className="btn btn-outline-success btn-no-transform btn-block mb-2"
        >
          <i className="fa fa-magic mr-1" aria-hidden="true"></i> Log in with magic link
        </Link>
        <Row className="mt-2">
          <Col md="6" className="pr-md-0">
            <Link to="/auth/facebook" className="btn btn-outline-dark btn-no-transform btn-block mb-2">
              <i className="fa fa-facebook mr-1" aria-hidden="true"></i> Log in with Facebook
            </Link>
          </Col>
          <Col md="6">
            <Link to="/auth/google" className="btn btn-outline-dark btn-no-transform btn-block mb-2">
              <img src="/images/google.png" alt="G" className="mr-1" height="14" /> Log in with Google
            </Link>
          </Col>
        </Row>
        <hr />
        <div className="small text-center mb-md-5 mb-3">
          <span className="mr-2 text-secondary">Don&apos;t have an account?</span>
          <Link to={vendor?.slug}>Sign up</Link>
        </div>
      </div>
      {vendor?.logo && (
        <div className="d-flex justify-content-center pt-md-5 pt-3">
          <img src="https://bottomless.imgix.net/powered-by-v2.png" alt="Powered by Bottomless" height="40" />
        </div>
      )}
    </Auth>
  );
};

VendorLockedLoginPageComponent.propTypes = {
  login: PropTypes.func.isRequired,
  getMe: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  getVendor: PropTypes.func.isRequired,
  vendor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export const VendorLockedLoginPage = withMetaTags({
  title: 'Bottomless.com: Login',
})(
  connect(
    ({ vendor }, { match }) => ({
      vendor: vendor?.data && vendor?.data.find(v => v.landing_page === `/${match.params.slug}`),
    }),
    dispatch => ({
      login: data => dispatch(loginAction(data)),
      getMe: () => dispatch(getMeAction()),
      getVendor: slug => dispatch(getVendorAction(slug)),
    })
  )(VendorLockedLoginPageComponent)
);
