import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

export const DataHandlerContent = ({ children, data, error }) => {
  const noData = useMemo(() => !data || (Array.isArray(data) && !data.length), [data]);

  if (error || noData) {
    return null;
  }

  return <>{children}</>;
};

DataHandlerContent.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  error: PropTypes.object,
  emptyState: PropTypes.node,
};
