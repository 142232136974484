import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { Clock } from 'react-feather';
import { getNextBusinessDayOrToday, getNextBusinessDay } from '../../../utils/dates';
import { TimeToFulfill } from '../../../components/TimeToFulfill/TimeToFulfill';

const DateOverrideTextComponent = ({ dateOverride, showTimer = false, timerText, shipText, excludeDates }) => {
  const dateOverrideText = useMemo(() => {
    if (dateOverride === '0') {
      if (showTimer) {
        return <TimeToFulfill timerText={timerText} />;
      }
      return `${shipText || 'Roasts and ships'} tomorrow at 4AM`;
    }

    if (dateOverride && dateOverride !== '0') {
      const nextBusinessDayOrToday = getNextBusinessDayOrToday();

      const shipmentDate =
        dateOverride === 'nextBusiness'
          ? getNextBusinessDay(new Date(), excludeDates)
          : new Date(nextBusinessDayOrToday.setDate(nextBusinessDayOrToday.getDate() + 7 * Number(dateOverride)));

      return `${shipText || 'Roasts and ships'} on ${moment(shipmentDate).format('MM/DD/YYYY')}`;
    }
  }, [dateOverride, excludeDates, shipText, showTimer, timerText]);

  if (!dateOverrideText) {
    return null;
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      {!showTimer && <Clock size="20" role="img" aria-label="Clock" />}
      <div
        className={classnames('mb-0 ml-2 placeholder-text', {
          'text-center': !showTimer,
        })}
      >
        {dateOverrideText}
      </div>
    </div>
  );
};

DateOverrideTextComponent.propTypes = {
  dateOverride: PropTypes.string,
  showTimer: PropTypes.bool,
  timerText: PropTypes.string,
  shipText: PropTypes.string,
  excludeDates: PropTypes.array,
};

export const DateOverrideText = memo(DateOverrideTextComponent);
