import React, { memo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const getSchema = me =>
  Yup.object().shape({
    email: !me ? Yup.string().required('Email is required') : Yup.string(),
    description: Yup.string().required('Description is required'),
  });

const RequestProductModal = ({ me, isOpen, toggle, onSubmit }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} unmountOnClose size="md" zIndex="2000">
      <ModalHeader toggle={toggle}>Request Product</ModalHeader>
      <ModalBody>
        <Form
          initialValues={{ email: me?.local?.email || '', description: '' }}
          validationSchema={getSchema(me)}
          onSubmit={data => onSubmit(data)}
        >
          {({ isSubmitting }) => (
            <>
              <Field name="email" type="email" label="Email" />
              <Field
                name="description"
                type="textarea"
                label="Description"
                placeholder="Let us know the name of the product you need and a product link if available"
              />
              <SubmitButton color="dark" isSubmitting={isSubmitting}>
                Submit
              </SubmitButton>
            </>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
};

RequestProductModal.propTypes = {
  me: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(RequestProductModal);
