import React from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { groupBy } from 'lodash-es';
import { Loader } from '@bottomless/common/components';
import { getMeAction } from '../../store';

const HomeLandingRedirectComponent = ({ me, getMe, isLoading, history }) => {
  const [isReady, setIsReady] = useState(false);
  const [, setCookie, removeCookie] = useCookies(['vendorLocked']);

  useEffect(() => {
    if (!me) {
      getMe();
    }
    setIsReady(true);
  }, []);

  if (me?.vendor_id) {
    setCookie('vendorLocked', 1);
  } else {
    removeCookie('vendorLocked');
  }

  // For landing page experiment - remove it after it's finished
  useEffect(() => {
    if (window.landingPageExperimentError) {
      Sentry.captureException(window.landingPageExperimentError);
    }
  }, []);

  if (!isReady || isLoading) {
    return <Loader fullPage />;
  }

  if (me) {
    const vendorGroups = groupBy(me.accounts, 'vendor_id');
    if (Object.keys(vendorGroups).length > 1) {
      history.push('/portal-select');
      return null;
    }
    if (!me.vendor_id) {
      history.push('/profile');
      return null;
    }
  }

  const originalLocationSearch = window.location.search;
  window.location = `/landing/index.html${originalLocationSearch || ''}`;
  return null;
};

HomeLandingRedirectComponent.propTypes = {
  me: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getMe: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export const HomeLandingRedirect = connect(
  ({ user: { me, isLoading } }) => ({ me, isLoading }),
  dispatch => ({
    getMe: () => dispatch(getMeAction()),
  })
)(HomeLandingRedirectComponent);
