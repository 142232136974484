import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { DataHandler } from '@bottomless/common/components';
import { useDispatch, useSelector } from 'react-redux';
import { ManifestContextProvider } from '../../context/manifest.context';
import { getProductAction } from '../../store';

const ProductCategoryManifestComponent = ({
  slugOrId,
  manifestKey = '',
  components = {},
  fallbackComponent,
  withPlaceholder,
  ...props
}) => {
  const dispatch = useDispatch();
  const [fetchedProduct, setFetchedProduct] = useState();
  const getProduct = useCallback(id => dispatch(getProductAction(id, { withHidden: 'true' })), [dispatch]);

  const { product, isLoading } = useSelector(({ product }) => ({
    product: product.data.find(product => product.slug === slugOrId || product._id === slugOrId),
    isLoading: product.isLoading,
  }));

  const manifest = useMemo(() => fetchedProduct?.category?.manifest || product?.category?.manifest, [
    product,
    fetchedProduct,
  ]);

  const Component = useMemo(
    () => components[((manifest || { components: {} }).components || {})[manifestKey]] || fallbackComponent,
    [manifest, manifestKey, components, fallbackComponent]
  );

  const { data, error } = useConditionalDataEffect(!manifest && slugOrId, getProduct, undefined, slugOrId, null);

  useEffect(() => {
    setFetchedProduct(data);
  }, [data, setFetchedProduct]);

  if (!manifest) {
    return (
      <DataHandler
        data={product}
        isLoading={!slugOrId || isLoading}
        error={error}
        withMarginTop={false}
        withPlaceholder={withPlaceholder}
      />
    );
  }

  if (!Component) {
    return null;
  }

  return (
    <ManifestContextProvider value={{ manifest }}>
      <Component {...props} />
    </ManifestContextProvider>
  );
};

ProductCategoryManifestComponent.propTypes = {
  slugOrId: PropTypes.string.isRequired,
  manifestKey: PropTypes.string.isRequired,
  components: PropTypes.object.isRequired,
  fallbackComponent: PropTypes.element.isRequired,
  withPlaceholder: PropTypes.bool,
};

export const ProductCategoryManifest = React.memo(ProductCategoryManifestComponent);
