import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { CheckboxBox } from '@bottomless/common/src/components';
import { useProductsCount } from '../../hooks/use-products-count.hook';
import { BuildCard } from '../BuildCard';

export const Origin = ({ onChange, products, hideOptionWithoutProducts, optionFilter, personalized }) => {
  const blendPersonalized = useMemo(() => ({ ...personalized, origin: ['blend'] }), [personalized]);
  const blendCount = useProductsCount({
    products,
    personalized: blendPersonalized,
  });

  const singlePersonalized = useMemo(() => ({ ...personalized, origin: ['single'] }), [personalized]);
  const singleCount = useProductsCount({
    products,
    personalized: singlePersonalized,
  });

  const blendPersonalizedForOptionFilter = useMemo(() => ({ ...optionFilter, origin: ['blend'] }), [optionFilter]);
  const blendCountForOptionFilter = useProductsCount({
    products,
    personalized: blendPersonalizedForOptionFilter,
    optional: true,
  });

  const singlePersonalizedForOptionFilter = useMemo(() => ({ ...optionFilter, origin: ['single'] }), [optionFilter]);
  const singleCountForOptionFilter = useProductsCount({
    products,
    personalized: singlePersonalizedForOptionFilter,
    optional: true,
  });

  if (hideOptionWithoutProducts && !blendCountForOptionFilter && !singleCountForOptionFilter) {
    return <></>;
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <h3 className="mb-0 mr-2">Origin</h3>
        <span className="text-secondary">(Optional)</span>
      </div>
      <Row className="pb-4 pt-2">
        {!(hideOptionWithoutProducts && !blendCountForOptionFilter) && (
          <Col xs="6" lg="3" className="answer-card-container">
            <CheckboxBox
              name="origin.blend"
              className="answer-card"
              label={<BuildCard name={`blend (${blendCount || blendCount === 0 ? blendCount : '-'})`} />}
              value={true}
              onChange={onChange}
            />
          </Col>
        )}
        {!(hideOptionWithoutProducts && !singleCountForOptionFilter) && (
          <Col xs="6" lg="3" className="answer-card-container">
            <div style={{ color: singleCount === 0 ? 'grey' : 'black' }}>
              <CheckboxBox
                name="origin.single"
                className="answer-card"
                label={<BuildCard name={`single origin (${singleCount || singleCount === 0 ? singleCount : '-'})`} />}
                value={true}
                onChange={onChange}
              />
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

Origin.propTypes = {
  onChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  optionFilter: PropTypes.object,
  personalized: PropTypes.object,
};
