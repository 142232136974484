import React from 'react';
import PropTypes from 'prop-types';
import './Address.scss';

export const Address = ({ address, inline }) => (
  <div className={inline ? 'address-inline' : ''}>
    <div>{address.street1}</div>
    {address.street2 && <div>{address.street2}</div>}
    <div>
      {address.city}, {address.state}, {address.zip}
    </div>
  </div>
);

Address.propTypes = {
  address: PropTypes.shape({
    street1: PropTypes.string.isRequired,
    street2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired,
  inline: PropTypes.bool,
};
