import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addToastAction } from '../store';

export const useToast = (message, type) => {
  const dispatch = useDispatch();

  return useCallback(
    (overrideMessage, overrideType) => dispatch(addToastAction(overrideMessage || message, overrideType || type)),
    [dispatch, message, type]
  );
};
