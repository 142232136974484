import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { RadioBox } from '@bottomless/common/src/components';
import { BuildCard } from '../BuildCard';
import { useProductsCount } from '../../hooks/use-products-count.hook';

export const GrindComponent = ({ onChange, name, products, hideOptionWithoutProducts, optionFilter }) => {
  const grindPersonalized = useMemo(() => ({ ...optionFilter, grind: name }), [name, optionFilter]);
  const productCount = useProductsCount({
    products,
    personalized: grindPersonalized,
    optional: true,
  });

  if (hideOptionWithoutProducts && !productCount) {
    return <></>;
  }

  return (
    <Col lg="3" md="3" xs="4" className="answer-card-container">
      <RadioBox
        name="grind"
        type="radio"
        className="answer-card"
        label={<BuildCard name={name.toLowerCase()} />}
        value={name}
        onChange={onChange}
      />
    </Col>
  );
};

GrindComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  optionFilter: PropTypes.object,
};

export const Grind = React.memo(GrindComponent);
