import PropTypes from 'prop-types';
import React from 'react';
import Flag from 'react-country-flag';
import classnames from 'classnames';
import { VariantAttributes } from '../VariantAttributes/VariantAttributes';

const emoji = product => {
  if (product) {
    if (!product.origin || product.origin.code === 'global') {
      return null;
    } else {
      return <Flag className="ml-2 flag" countryCode={product.origin.code} aria-label={product.origin.code} />;
    }
  } else {
    return null;
  }
};

export const ProductName = ({
  centerText,
  product,
  personalized,
  grind,
  isSubproduct,
  hideVendor,
  quantity,
  productVariant,
}) => {
  let isCustomRotation;
  if (product) {
    if (product.product) {
      isCustomRotation = product.product.personalized_rotation;
    } else {
      isCustomRotation = product.personalized_rotation;
    }
  }

  if (!personalized || !isCustomRotation) {
    return (
      <>
        <div className="font-weight-bold placeholder-text">
          {(product && product.name) || (product && product.product && product.product.name)} {emoji(product)}{' '}
          {quantity && <span className="text-secondary font-weight-normal">(x{quantity})</span>}
        </div>
        {(grind || productVariant) && (
          <div className="text-secondary small text-sm grind placeholder-text">
            <VariantAttributes productVariant={productVariant} grind={grind ? { name: grind } : undefined} />
          </div>
        )}
      </>
    );
  }

  let name = '';

  if (personalized && personalized.roast) {
    name = personalized.roast
      .map(val => val.replace('any', "I don't know, surprise me!").replace(/^\w/, c => c.toUpperCase()))
      .join(', ');
  }

  const tag = ((personalized && personalized.tag) || [])
    .map(val => val.replace('cold_brew', 'Cold brew').replace(/^\w/, c => c.toUpperCase()))
    .join(', ');

  const origin = ((personalized && personalized.origin) || [])
    .map(val =>
      val
        .replace('any', "I don't know, surprise me!")
        .replace('single', 'Single Origin')
        .replace(/^\w/, c => c.toUpperCase())
    )
    .join(', ');

  const singleOrigins = ((personalized && personalized.single_origins) || []).map(origin => origin.name).join(', ');

  const vendor = !isSubproduct
    ? ((personalized && personalized.vendor) || [])
        .map(vendor => vendor.name)
        .slice(0, 3)
        .join(', ')
    : ((personalized && personalized.vendor) || []).map(vendor => vendor.name).join(', ');

  const tasting_note_category = !isSubproduct
    ? ((personalized && personalized.tasting_note_category) || [])
        .map(tasting_note_category => tasting_note_category.name)
        .slice(0, 3)
        .join(', ')
    : ((personalized && personalized.tasting_note_category) || [])
        .map(tasting_note_category => tasting_note_category.name)
        .join(', ');

  return (
    <>
      {personalized?.roast ? (
        <h6 className="mb-0">{name}</h6>
      ) : (
        <div className={classnames('placeholder-text', { 'text-center': centerText })}>{name}</div>
      )}
      <div className={`text-secondary text-sm placeholder-text ${classnames({ 'text-center': centerText })}`}>
        {grind ? <span className="grind">{grind}</span> : ''}
        {tag && (
          <>
            {grind && <br />}
            <span className="small">{tag}</span>
            <br />
          </>
        )}
        {origin && (
          <span className="small">
            {(grind || tag) && ','} {origin}
            {singleOrigins && (
              <>
                {' ('}
                {singleOrigins}
                {')'}
              </>
            )}
          </span>
        )}
      </div>
      <>
        {vendor && !hideVendor && (
          <div className="text-secondary text-uppercase small placeholder-text">
            {vendor}
            {personalized && personalized.vendor.length > 3 ? '...' : ''}
          </div>
        )}
      </>
      <>
        {tasting_note_category && (
          <div className="text-secondary small placeholder-text">
            {tasting_note_category}
            {personalized && personalized.tasting_note_category.length > 3 ? '...' : ''}
          </div>
        )}
      </>
    </>
  );
};

ProductName.defaultProps = {
  isSubproduct: false,
  centerText: false,
};

ProductName.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    personalized_rotation: PropTypes.bool,
    variant: PropTypes.string,
    product: PropTypes.shape({
      name: PropTypes.string,
      personalized_rotation: PropTypes.bool,
    }),
  }),
  grind: PropTypes.string,
  personalized: PropTypes.shape({
    roast: PropTypes.arrayOf(PropTypes.string),
    origin: PropTypes.arrayOf(PropTypes.string),
    tag: PropTypes.arrayOf(PropTypes.string),
    single_origins: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    vendor: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tasting_note_category: PropTypes.array,
  }),
  centerText: PropTypes.bool,
  isSubproduct: PropTypes.bool,
  hideVendor: PropTypes.bool,
  quantity: PropTypes.number,
  productVariant: PropTypes.object,
};
