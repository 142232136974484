import React from 'react';
import PropTypes from 'prop-types';
import { FeatureSection } from '../../../../components/FeatureSection/FeatureSection';

export const TryItRiskFreeSection = ({ vendor }) => (
  <FeatureSection
    image={vendor.custom_styles.images.riskFree}
    text="We offer a 30 day free trial. Cancel your order or cancel/pause your subscription any time."
    title="Try it risk-free cancel anytime"
    reverse
  />
);

TryItRiskFreeSection.propTypes = {
  vendor: PropTypes.object.isRequired,
};
