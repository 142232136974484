import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Image, Loader } from '@bottomless/common/components';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDataEffect, useQueryString } from '@bottomless/common/hooks';
import { getMeAction, sendMagicLinkAction } from '../../../store';
import { VendorBrandLayout } from '../../../layouts/VendorBranded';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { EmailForm, EmailSent } from './components';
import { UserStatuses } from '@bottomless/common/constants';
import { getStaticVendor } from './static-vendors';

const ConnectLoginPageComponent = ({ me, isLoading, getMe, sendMagicLink }) => {
  const { params } = useQueryString();
  const history = useHistory();
  const [isSent, setIsSent] = useState(false);

  useDataEffect(getMe);

  const onSuccess = useCallback(
    data => {
      if (data.redirectUrl) {
        history.push(data.redirectUrl);
      } else {
        setIsSent(true);
      }
    },
    [history]
  );

  const vendor = useMemo(() => getStaticVendor(params.merchant), [params.merchant]);

  useEffect(() => {
    if (me) {
      const validAccounts = me.accounts.filter(account => account.status !== UserStatuses.Offboarding);
      history.push(
        validAccounts.length > 1
          ? { pathname: '/change-account', state: { path: '/activate/step1' } }
          : '/activate/step1'
      );
    }
  }, [me, history]);

  const handleSendMagicLink = useCallback(
    async data => {
      const result = await sendMagicLink({
        ...data,
        action: 'activate',
        ...(params.merchant ? { merchant: params.merchant } : {}),
      });

      if (result.payload?.redirectUrl) {
        await getMe();
      }

      return result;
    },
    [sendMagicLink, getMe, params.merchant]
  );

  if (me) {
    return null;
  }

  if (isLoading) {
    return <Loader fullPage />;
  }

  return (
    <VendorBrandLayout noNavigationBar vendor={vendor} noHeadingLink={!!params.merchant}>
      <h1 className="text-center mb-4">Activate your smart scale</h1>
      {!isSent && <EmailForm onSubmit={handleSendMagicLink} onSuccess={onSuccess} />}
      {isSent && <EmailSent />}
      {!vendor && (
        <div className="text-center">
          <Image src="/powered-by-v2.png" alt="Powered by Bottomless" height="35" useSrcset />
        </div>
      )}
    </VendorBrandLayout>
  );
};

ConnectLoginPageComponent.propTypes = {
  sendMagicLink: PropTypes.func.isRequired,
  me: PropTypes.object,
  getMe: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export const ConnectLoginPage = withMetaTags({
  title: 'Bottomless.com: Activate your smart scale',
})(
  connect(
    ({ user: { me, isLoading } }) => ({ me, isLoading }),
    dispatch => ({
      sendMagicLink: data => dispatch(sendMagicLinkAction(data)),
      getMe: () => dispatch(getMeAction()),
    })
  )(ConnectLoginPageComponent)
);
