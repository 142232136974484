import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import './FrontendPagination.scss';

const FrontendPaginationComponent = ({
  reviews,
  reviewsPerPage = 10,
  paginationDisplayNumberRange = 5,
  nextLabel = '>',
  previousLabel = '<',
  breakLabel = '...',
  setCurrentReviews,
}) => {
  const [pageCount, setPageCount] = useState(0);
  const [reviewOffset, setReviewOffset] = useState(0);

  useEffect(() => {
    const endOffset = reviewOffset + reviewsPerPage;
    setCurrentReviews(reviews.slice(reviewOffset, endOffset));
    setPageCount(Math.ceil(reviews.length / reviewsPerPage));
  }, [reviewOffset, reviews, reviewsPerPage, setCurrentReviews]);

  const handlePageClick = event => {
    const newOffset = (event.selected * reviewsPerPage) % reviews.length;
    setReviewOffset(newOffset);
  };

  if (reviews?.length <= reviewsPerPage) {
    return <></>;
  }

  return (
    <ReactPaginate
      breakLabel={breakLabel}
      nextLabel={nextLabel}
      onPageChange={handlePageClick}
      pageRangeDisplayed={paginationDisplayNumberRange}
      pageCount={pageCount}
      previousLabel={previousLabel}
      renderOnZeroPageCount={null}
      containerClassName="reviews-pagination"
      pageClassName="page-number-container"
      pageLinkClassName="page-number-link"
      previousClassName="previous-label"
      nextClassName="next-label"
    />
  );
};

FrontendPaginationComponent.propTypes = {
  reviews: PropTypes.array.isRequired,
  reviewsPerPage: PropTypes.number,
  paginationDisplayNumberRange: PropTypes.number,
  nextLabel: PropTypes.string,
  previousLabel: PropTypes.string,
  breakLabel: PropTypes.string,
  setCurrentReviews: PropTypes.func.isRequired,
};

export const FrontendPagination = memo(FrontendPaginationComponent);
