import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { MakeBottomless } from '../../Shop/components/MakeBottomless';
import { ProductParameters } from './ProductParameters';

export const BasicProductInfo = ({
  product,
  pathname,
  onVendorClick,
  me,
  pricingRule,
  onChooseProduct,
  onChooseSuccess,
  grinds,
  makeBottomlessText,
  setSelectedGrind,
  setSelectedVariant,
  outOfStock,
  addToCustomList,
  selectedGrind,
  selectedVariant,
  productSelection,
  saveProductSelection,
  onOneOffOrder,
  expressCheckoutButton,
  isFirstBag,
  numberOfRichReviews,
  toggleLikesDislikesModal,
  category,
  userGrind,
  getCheckout,
  onlyMappedVariants,
  checkout,
}) => {
  const location = useLocation();

  const likesProportion = useMemo(() => {
    const likes = product.likes || 0;
    const dislikes = product.dislikes || 0;

    if (!likes && !dislikes) {
      return null;
    }

    return likes + dislikes;
  }, [product.likes, product.dislikes]);

  return (
    <Row className="mb-5 justify-content-center">
      <Col xs="12" md="6" className="d-flex align-items-center justify-content-center">
        <LazyLoad once height={525} offset={525}>
          <img
            className={`img-fluid product-image ${product.custom_rotation ? 'list' : ''}`}
            src={product.image_src || product.small_image_src}
            alt={product.name}
          />
        </LazyLoad>
      </Col>
      <Col xs="12" md="5" className="pt-2">
        <div className="d-flex flex-column align-items-center mb-3">
          <div className="text-secondary text-uppercase">
            {pathname ? (
              <Link
                to={`${pathname}?vendor_id=${product.vendor_id._id}`}
                onClick={onVendorClick}
                className="text-secondary text-decoration-none"
              >
                {product.vendor_name}
              </Link>
            ) : (
              product.vendor_name
            )}
          </div>
          <h1 className="text-capitalize text-center mb-4">{product.name}</h1>

          {product.likes > 0 && (
            <span className="text-nowrap">
              <i className="fa fa-heart text-danger" /> {product.likes}
              {likesProportion !== null && (
                <span className="ml-1">
                  {numberOfRichReviews > 0 ? (
                    <a href="#reviews" className="reviews-link">
                      of {likesProportion} reviews
                    </a>
                  ) : (
                    <p className="reviews-link" onClick={toggleLikesDislikesModal}>
                      of {likesProportion} reviews
                    </p>
                  )}
                </span>
              )}
            </span>
          )}
        </div>
        <ProductParameters product={product} />
        <MakeBottomless
          me={me}
          product={product}
          priceRule={pricingRule}
          isFirstBag={isFirstBag}
          onChooseProduct={onChooseProduct}
          onOneOffOrder={onOneOffOrder}
          onChooseSuccess={onChooseSuccess}
          grinds={grinds}
          hasOneOff={!!me}
          hasCustomLists={!!me}
          selectText={makeBottomlessText}
          outOfStock={outOfStock}
          setSelectedGrind={setSelectedGrind}
          setSelectedVariant={setSelectedVariant}
          isProductPage
          pathname={pathname}
          addToCustomList={addToCustomList}
          selectedGrind={selectedGrind}
          selectedVariant={selectedVariant}
          productSelection={productSelection}
          saveProductSelection={saveProductSelection}
          location={location}
          expressCheckoutButton={!me && !outOfStock && expressCheckoutButton ? expressCheckoutButton : undefined}
          category={category}
          userGrind={userGrind}
          getCheckout={getCheckout}
          onlyMappedVariants={onlyMappedVariants}
          checkout={checkout}
        />
      </Col>
    </Row>
  );
};

BasicProductInfo.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image_src: PropTypes.string.isRequired,
    small_image_src: PropTypes.string,
    vendor_name: PropTypes.string.isRequired,
    vendor_id: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
    likes: PropTypes.number,
    dislikes: PropTypes.number,
    custom_rotation: PropTypes.string,
  }).isRequired,
  pathname: PropTypes.string,
  onVendorClick: PropTypes.func,
  me: PropTypes.object,
  pricingRule: PropTypes.object,
  onChooseProduct: PropTypes.func.isRequired,
  onChooseSuccess: PropTypes.func.isRequired,
  grinds: PropTypes.array,
  makeBottomlessText: PropTypes.string,
  setSelectedGrind: PropTypes.func,
  setSelectedVariant: PropTypes.func.isRequired,
  outOfStock: PropTypes.bool,
  addToCustomList: PropTypes.func,
  selectedGrind: PropTypes.string,
  selectedVariant: PropTypes.string,
  productSelection: PropTypes.object,
  saveProductSelection: PropTypes.func.isRequired,
  onOneOffOrder: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  expressCheckoutButton: PropTypes.node,
  isFirstBag: PropTypes.bool,
  numberOfRichReviews: PropTypes.number,
  toggleLikesDislikesModal: PropTypes.func.isRequired,
  category: PropTypes.string,
  userGrind: PropTypes.string,
  getCheckout: PropTypes.func,
  onlyMappedVariants: PropTypes.bool,
  checkout: PropTypes.object,
};
