import React from 'react';
import PropTypes from 'prop-types';

export const FaqElement = ({ question, answer }) => (
  <div className="mb-5">
    <div className="font-weight-bold mb-3">{question}</div>
    <div>{answer}</div>
  </div>
);

FaqElement.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};
