import { ReviewBrewingMethods } from '@bottomless/common/constants';
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReadMore from 'read-more-react';
import { PhotoModal } from './components';
import './Review.scss';

const BREWING_METHOD_DICTIONARY = {
  [ReviewBrewingMethods.Aeropress]: 'Aeropress',
  [ReviewBrewingMethods.PourOver]: 'Pour Over',
  [ReviewBrewingMethods.SuperautomaticEspressoMachine]: 'Superautomatic Espresso Machine',
  [ReviewBrewingMethods.EspressoMachine]: 'Espresso Machine',
  [ReviewBrewingMethods.FrenchPress]: 'French Press',
  [ReviewBrewingMethods.Moccamaster]: 'Moccamaster',
  [ReviewBrewingMethods.Drip]: 'Drip',
};

export const Review = ({ review, long, withUser }) => {
  const { min, ideal, max } = useMemo(
    () => (long ? { min: 700, ideal: 800, max: 900 } : { min: 160, ideal: 200, max: 250 }),
    [long]
  );

  const capitalize = useCallback(phrase => phrase.toLowerCase().replace(/\b\w/g, c => c.toUpperCase()), []);

  const userAddress = useMemo(
    () =>
      review.user?.verifiedAddress?.city &&
      ` from ${capitalize(review.user?.verifiedAddress?.city)}, ${review.user?.verifiedAddress?.state}`,
    [review, capitalize]
  );

  return (
    <div className="review">
      {withUser && (
        <div className="mb-3">
          <div className="review-heading">Customer</div>
          <div className="review-value">
            {review.user.first_name}
            {userAddress}
          </div>
        </div>
      )}
      <div className="mb-3">
        <div className="review-heading">Brewing method</div>
        <div className="review-value">{BREWING_METHOD_DICTIONARY[review.brewing_method] || review.brewing_method}</div>
      </div>
      <div className="mb-3">
        <div className="review-heading">Review</div>
        <div className="review-value">
          {review.content && <ReadMore text={review.content} min={min} ideal={ideal} max={max} />}
        </div>
      </div>
      {review.photos?.length > 0 && (
        <div className="mb-3">
          <PhotoModal photos={review.photos} />
        </div>
      )}
    </div>
  );
};

Review.propTypes = {
  review: PropTypes.shape({
    content: PropTypes.string,
    brewing_method: PropTypes.string,
    user: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      verifiedAddress: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
      }),
    }),
    photos: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  long: PropTypes.bool,
  withUser: PropTypes.bool,
};
