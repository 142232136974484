import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import classNames from 'classnames';
import { CheckboxBox } from '@bottomless/common/src/components';
import { useProductsCount } from '../../hooks/use-products-count.hook';
import { BuildCard } from '../BuildCard';

const RoasterComponent = ({ onChange, vendor, products, personalized }) => {
  const vendorPersonalized = useMemo(() => ({ ...personalized, vendor: [vendor._id] }), [personalized, vendor]);
  const count = useProductsCount({ products, personalized: vendorPersonalized });

  return (
    <Col xs="6" md="3" lg="3" className="answer-card-container" key={vendor._id}>
      <div className={classNames('h-100', { 'text-secondary': !count })}>
        <CheckboxBox
          name={`vendor.${vendor._id}`}
          className="answer-card"
          label={
            <BuildCard img={vendor.img_url} name={`${vendor.name.replace('Roasters', '').toLowerCase()} (${count})`} />
          }
          onChange={onChange}
        />
      </div>
    </Col>
  );
};

RoasterComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  vendor: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    img_url: PropTypes.string,
  }),
  products: PropTypes.array.isRequired,
  personalized: PropTypes.object.isRequired,
};

export const Roaster = React.memo(RoasterComponent);
