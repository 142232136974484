import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { TastingCategory } from './TastingCategory';
import { AdvanceBuilderHeading } from '../AdvanceBuilderHeading';
import { useProductsCount } from '../../hooks/use-products-count.hook';

export const TastingCategories = ({
  onChange,
  categories,
  products,
  hideOptionWithoutProducts,
  optionFilter,
  personalized,
}) => {
  const categoryPersonalizedForOptionFilter = useMemo(
    () => ({ ...optionFilter, tasting_note_category: (categories || []).map(row => row._id) }),
    [optionFilter, categories]
  );

  const productCount = useProductsCount({
    products,
    personalized: categoryPersonalizedForOptionFilter,
    optional: true,
  });

  if (hideOptionWithoutProducts && !productCount) {
    return <></>;
  }

  return (
    <Row className="mb-5">
      <Col xs="12">
        <AdvanceBuilderHeading title="Tasting Categories" subtitle="Optional" />
        <Row className="pt-2 mb-3 mb-md-0">
          {categories &&
            categories.map(category => (
              <TastingCategory
                key={category._id}
                category={category}
                onChange={onChange}
                products={products}
                hideOptionWithoutProducts={hideOptionWithoutProducts}
                optionFilter={optionFilter}
                personalized={personalized}
              />
            ))}
        </Row>
      </Col>
    </Row>
  );
};

TastingCategories.propTypes = {
  onChange: PropTypes.func.isRequired,
  categories: PropTypes.array,
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  optionFilter: PropTypes.object,
  personalized: PropTypes.object,
};
