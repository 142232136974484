import React from 'react';
import PropTypes from 'prop-types';
import { DateOverrideText } from '../../../FilterCheckout/components/DateOverrideText';

export const GenericCheckoutShipTime = ({ dateOverride, excludeDates }) => {
  return (
    <DateOverrideText
      dateOverride={dateOverride}
      excludeDates={excludeDates}
      timerText="Time to complete for tomorrow fulfilment"
      shipText="Ships"
      showTimer
    />
  );
};

GenericCheckoutShipTime.propTypes = {
  dateOverride: PropTypes.string.isRequired,
  excludeDates: PropTypes.array,
};
