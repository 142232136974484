import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import isArray from 'lodash-es/isArray';
import isString from 'lodash-es/isString';
import isNumber from 'lodash-es/isNumber';
import isBoolean from 'lodash-es/isBoolean';
import isNull from 'lodash-es/isNull';
import isPlainObject from 'lodash-es/isPlainObject';
import isEmpty from 'lodash-es/isEmpty';
import set from 'lodash-es/set';
import get from 'lodash-es/get';
import { Form } from '@bottomless/common/components';
import { useManifest } from '../../../../hooks/useManifest.hook';
import { FilterAccordion } from '../../components/FilterAccordion';

function flattenObject(o, prefix = '', result = {}, keepNull = true) {
  if (isArray(o) || isString(o) || isNumber(o) || isBoolean(o) || (keepNull && isNull(o))) {
    result[prefix] = o;
    return result;
  }

  if (isPlainObject(o)) {
    for (const i in o) {
      const pref = isEmpty(prefix) ? i : prefix + '.' + i;
      flattenObject(o[i], pref, result, keepNull);
    }

    return result;
  }

  return result;
}

const FiltersSchema = Yup.object();

// Add search bar keys here with their value being fields location
// The key will show up in filters and value will be used to fetch the field
// Also, add a method for useProductsFilter if it doesn't already exists.
const specialFilters = {
  vendor_id: 'vendor_id._id',
};

export const GenericFilters = ({ onChange, products, vendorId, changeBatch, filters, onClose, clearFilters }) => {
  const manifest = useManifest();
  const initialValues = useMemo(() => manifest.filters.reduce((all, attr) => set(all, attr.field, []), {}), [manifest]);

  const formRef = useRef(null);
  const [filtersCount, setFiltersCount] = useState(0);

  const [collapse, setCollapse] = useState(
    manifest.filters.reduce(
      (all, attr, i) => ({ ...all, [attr.field]: i < 3 ? true : filterCollapsed(filters, attr.field) }),
      {}
    )
  );

  const sort = useCallback(data => [...data].sort((a, b) => (a.name < b.name ? -1 : a.name > b.name)), []);

  const getUniqueFieldCallback = useCallback(
    getField => sort(Object.values(products.reduce((fields, product) => ({ ...fields, ...getField(product) }), {}))),
    [products, sort]
  );

  const availableFilters = useMemo(
    () =>
      manifest.filters
        .map(filter => ({
          ...filter,
          values: getUniqueFieldCallback(product => {
            const filterField = specialFilters[filter.field] || filter.field;
            const field = get(product, filterField);
            const fieldValue = get(product, filter.fieldValue) || field;

            return Array.isArray(fieldValue)
              ? fieldValue.reduce((values, value) => ({ ...values, [value]: { _id: value, name: value } }), {})
              : { [field]: { _id: field, name: fieldValue } };
          }),
        }))
        .filter(filter => filter.values.length > 0),
    [manifest, getUniqueFieldCallback]
  );

  useEffect(() => {
    setFiltersCount(countFilters(countFilters(filters) - (vendorId ? 1 : 0)));

    const data = Object.entries(filters).reduce((all, [key, value]) => {
      const exists =
        availableFilters
          .find(f => f.field === key)
          ?.values.reduce((fields, v, index) => (value.includes(v._id) ? set(fields, index, v._id) : fields), []) || [];
      return set(all, key, exists);
    }, {});
    formRef.current.resetForm(data);

    Object.entries(filters).forEach(([key, value]) =>
      value.length && !collapse[key] ? setCollapse({ ...collapse, [key]: true }) : undefined
    );
  }, [availableFilters, collapse, filters]);

  const submit = useCallback(
    submitForm => () => {
      setTimeout(submitForm);
    },
    []
  );

  const onCollapse = useCallback(field => () => setCollapse({ ...collapse, [field]: !collapse[field] }), [
    collapse,
    setCollapse,
  ]);
  const onSubmit = useCallback(
    values => {
      const flatten = flattenObject(values);
      const data = Object.entries(flatten).reduce(
        (data, [key, value]) => ({ ...data, [key]: typeof value === 'string' ? value : value.filter(Boolean) }),
        {}
      );
      setFiltersCount(countFilters(data) - (vendorId ? 1 : 0));
      onChange(data);
    },
    [vendorId, onChange, setFiltersCount]
  );

  const handleClean = useCallback(() => clearFilters(flattenObject(initialValues)), [clearFilters, initialValues]);

  return (
    <>
      <Form
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={FiltersSchema}
        onSubmit={onSubmit}
        className="form-filters"
      >
        {({ submitForm }) => (
          <div className="filters">
            <div className="filters-clear">
              {filtersCount > 0 && (
                <Button color="link" onClick={handleClean} size="sm">
                  Clear {filtersCount} filter{filtersCount > 1 && 's'}
                </Button>
              )}
            </div>
            <div className="filters-wrapper">
              {availableFilters.map(filter => (
                <FilterAccordion
                  key={filter.field}
                  data={filter.values}
                  name={filter.field}
                  label={filter.name}
                  onCollapse={onCollapse(filter.field)}
                  collapsed={collapse[filter.field]}
                  onChange={submit(submitForm)}
                  products={products}
                  filters={filters}
                />
              ))}
            </div>
            {changeBatch && (
              <Button color="dark" type="button" onClick={onClose} block className="mt-3">
                Close
              </Button>
            )}
          </div>
        )}
      </Form>
    </>
  );
};

GenericFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  changeBatch: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      roast: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  vendorId: PropTypes.string,
  clearFilters: PropTypes.func.isRequired,
};

GenericFilters.defaultProps = {
  changeBatch: false,
};

const countFilters = filters =>
  Object.values(filters).reduce((a, b) => a + (typeof b === 'string' ? Boolean(b.length) : b.length), 0);

const filterCollapsed = (filters, name) => Boolean(filters[name]?.length);
