import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Image } from '@bottomless/common/components';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { UsingContainer } from '../../ConnectStep2/components/UsingContainer';
import { useCustomWording } from '../../hooks/use-custom-wording';
import { useIsAg1 } from '../../hooks/use-is-ag1';
import { Ag1Setup } from '../../components/Ag1Setup';

export const TareScale = ({ timestamp }) => {
  const { cw } = useCustomWording();
  const isAg1 = useIsAg1();
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(`/activate/step3?flow=tare&timestamp=${timestamp}`);
  }, [history, timestamp]);

  return (
    <Box secondary className="mb-4">
      <h2 className="text-center mb-4">Set &quot;zero level&quot;</h2>
      <div className="text-center">
        <Image
          src={cw('/connect-scale/scale-bottom.png')}
          alt="Scale setup"
          width={isAg1 ? 210 : 300}
          height={isAg1 ? 210 : 198}
          useSrcset
          className="mb-4"
        />
      </div>
      <div className="text-center mb-4">
        {cw(
          'Press the "Setup" button on the bottom of your scale, then place it empty on a flat surface to set the "zero level".'
        )}
      </div>
      {isAg1 && <Ag1Setup />}
      <Button size="lg" color="dark" block onClick={onClick}>
        Next
      </Button>
      {!isAg1 && <UsingContainer />}
    </Box>
  );
};

TareScale.propTypes = {
  timestamp: PropTypes.string.isRequired,
};
