import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Button } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Origin } from '../../../../../components/Origin';
import { getIsMondayToThursday } from '../../../../../utils/dates';
import { InfoTooltip } from '@bottomless/common/components';

export const ProductDescriptionComponent = ({ product, me }) => {
  const [showMap, setShowMap] = useState(!['Blend', 'Rotating'].includes(product.origin.name));
  const isMondayToThursday = getIsMondayToThursday();
  const [copied, setCopied] = useState(false);

  const tastingNotes = useMemo(() => {
    return product?.tasting_notes?.map(note => note.name);
  }, [product]);

  const tags = useMemo(() => {
    return product?.tags.map(tag => tag.name);
  }, [product]);

  const altitude = useMemo(() => {
    if (product.altitude_min === product.altitude_max) {
      return `${product.altitude_min} masl`;
    } else if (product.altitude_min && product.altitude_max) {
      return `Min: ${product.altitude_min} masl, Max: ${product.altitude_max} masl`;
    } else if (product.altitude_min) {
      return `Min: ${product.altitude_min} masl`;
    } else {
      return `Max: ${product.altitude_max} masl`;
    }
  }, [product]);

  return (
    <Row className="mt-5 mb-5">
      <Col xs="12" md="6">
        <Row className="mb-5 other-details">
          <Col xs="12">
            <h6 className="mb-3">Details</h6>
          </Col>
          {tastingNotes.length > 0 && (
            <Col xs="12" className="details-row-first">
              <span className="font-weight-bold">Tasting Notes:</span> <span>{tastingNotes.join(', ')}</span>
            </Col>
          )}
          {tags.length > 0 && (
            <Col xs="12" className="details-row">
              <span className="font-weight-bold">Tags:</span> <span>{tags.join(', ')}</span>
            </Col>
          )}
          {(product.altitude_max || product.altitude_min) && (
            <Col xs="12" className="details-row">
              <span className="font-weight-bold">Altitude:</span> <span>{altitude}</span>
            </Col>
          )}
        </Row>
        <h6 className="mb-3">Description</h6>
        <div
          className="ml-2 description-text-block"
          dangerouslySetInnerHTML={{
            __html: product?.description
              .replace(/<\/?p>/gi, '')
              .replace(/<br\s*\/?>/gi, '<br><br>')
              .replace(/\./g, '.<br><br>'),
          }}
        />
        {showMap && (
          <>
            <h6 className="mt-3"> Coffee Origin </h6>
            <Origin origin={product.origin} setShowMap={setShowMap} />
          </>
        )}
      </Col>
      <Col xs="12" md="6" className="delivery-info">
        {!me && (
          <>
            <h6 className="mb-3">Delivery Information</h6>
            <div className="delivery-text mb-5">
              {!me && (
                <div className="ship-text-wrap">
                  <div className="ship-text rotation d-flex align-items-center font-weight-bold">
                    <img alt="Flash" src="https://bottomless.imgix.net/flash.svg" className="img-fluid" />
                    <div className="ship-description rotation__text ml-2">
                      {isMondayToThursday ? 'Roasts and ships tomorrow at 4AM' : 'Roasts and ships on Monday at 4AM'}
                    </div>
                    <InfoTooltip className="ml-1" size="sm">
                      Get free shipping via USPS, arrives in 3 days.
                    </InfoTooltip>
                  </div>
                </div>
              )}
              You will receive the Bottomless smart scale along with your first bag. This scale tracks your consumption
              and re-orders your next {product.name} bags at the perfect time.
            </div>
          </>
        )}
        <h6 className="mb-3">Share this Product with friends and family</h6>
        <div className="d-flex invite-link-copy">
          <Input
            className="invite-link mr-2 border px-2"
            type="text"
            readOnly
            value={window.location.href}
            aria-label="Share link"
            autocomplete="off"
          />
          <CopyToClipboard text={window.location.href} onCopy={() => setCopied(true)}>
            <Button color="primary" outline>
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          </CopyToClipboard>
        </div>
      </Col>
    </Row>
  );
};

ProductDescriptionComponent.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    tasting_notes: PropTypes.array,
    tags: PropTypes.array,
    altitude_max: PropTypes.number,
    altitude_min: PropTypes.number,
    roast: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    origin: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    process: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }),
  me: PropTypes.object,
};

export const ProductDescription = memo(ProductDescriptionComponent);
