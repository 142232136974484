import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import { UserProductInfo } from '../../components/UserProductInfo';
import { Variants } from '../../components/Variants';
import { Parameters, MakeBottomless } from './components';

export const PetFoodProduct = ({
  product,
  pricingRule,
  isFirstBag,
  onChooseProduct,
  onOneOffOrder,
  onChooseSuccess,
  onClick,
  onFilterChange,
  withDescription,
  featuredTags,
  vendor,
  pathname,
  grinds,
  me,
  hasOneOff,
  hasCustomLists,
  hasMakeBottomless,
  includesForm = true,
  additionalActionButton,
  onFormChange,
  formInitialValues,
  makeBottomlessText,
  outOfStock,
  oneOffText,
  flatOrders,
  setSelectedGrind,
  setSelectedVariant,
  isProductPage,
  addToCustomList,
  selectedGrind,
  selectedVariant,
  productSelection,
  saveProductSelection,
  location,
  isVendorLandingShop,
  userGrind,
  checkout,
}) => {
  const onVendorClick = e => {
    e.stopPropagation();
  };

  return (
    <div className={onClick ? 'card card-body product-card mb-3 cursor-pointer' : ''} onClick={onClick}>
      <Row className="product-card-row align-items-center">
        <Col xs="4" md="3" className="text-center text-md-left d-flex align-items-center justify-content-center p-1">
          <LazyLoad once height={300} offset={250}>
            <img className="product-card-image img-fluid" src={product.small_image_src} alt={product.name} />
          </LazyLoad>
        </Col>
        <Col xs="8" md={includesForm ? 5 : 8}>
          <div className="product-card-vendor text-secondary text-uppercase">
            {pathname && !isVendorLandingShop ? (
              <Link
                className="text-decoration-none"
                to={`${pathname}?category=${product.category?.slug}&vendor_id=${product.vendor_id._id}`}
                onClick={onVendorClick}
              >
                {product.vendor_name}
              </Link>
            ) : (
              product.vendor_name
            )}
          </div>
          <div className="product-card-product mb-3">
            <Link className="text-dark text-decoration-none font-weight-bold" to={`products/${product.slug}`}>
              {product.name}
            </Link>
          </div>
          <div className="d-flex d-md-none">
            <Variants variants={product.variants} pricingRule={pricingRule} isFirstBag={isFirstBag} me={me} />
          </div>
          <Parameters
            product={product}
            withDetails={!onClick}
            onFilterClick={onFilterChange}
            featuredTags={featuredTags}
            isVendorLandingShop={isVendorLandingShop}
            checkout={checkout}
            me={me}
          />
          <div className="user-product-info">
            <UserProductInfo flatOrders={flatOrders} productId={product._id} />
          </div>
          {withDescription && <div dangerouslySetInnerHTML={{ __html: product.description }} />}
        </Col>
        {includesForm && (
          <Col className="d-none d-md-block product-card-select" md="4">
            <MakeBottomless
              me={me}
              product={product}
              priceRule={pricingRule}
              isFirstBag={isFirstBag}
              onChooseProduct={onChooseProduct}
              onOneOffOrder={onOneOffOrder}
              onChooseSuccess={onChooseSuccess}
              vendor={vendor}
              grinds={grinds}
              hasMakeBottomless={hasMakeBottomless}
              hasOneOff={hasOneOff}
              hasCustomLists={hasCustomLists}
              additionalActionButton={additionalActionButton}
              onFormChange={onFormChange}
              initialValues={formInitialValues}
              selectText={makeBottomlessText}
              oneOffText={oneOffText}
              outOfStock={outOfStock}
              setSelectedGrind={setSelectedGrind}
              setSelectedVariant={setSelectedVariant}
              isProductPage={isProductPage}
              pathname={pathname}
              addToCustomList={addToCustomList}
              selectedGrind={selectedGrind}
              selectedVariant={selectedVariant}
              productSelection={productSelection}
              saveProductSelection={saveProductSelection}
              location={location}
              userGrind={userGrind}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

PetFoodProduct.propTypes = {
  withDescription: PropTypes.bool,
  onChooseProduct: PropTypes.func.isRequired,
  onOneOffOrder: PropTypes.func,
  onChooseSuccess: PropTypes.func,
  onFilterChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    small_image_src: PropTypes.string.isRequired,
    vendor_id: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        size: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
      })
    ).isRequired,
    rotating: PropTypes.bool,
    likes: PropTypes.number,
    category: PropTypes.object.isRequired,
  }).isRequired,
  pricingRule: PropTypes.object,
  isFirstBag: PropTypes.bool,
  featuredTags: PropTypes.array,
  vendor: PropTypes.object,
  pathname: PropTypes.string,
  grinds: PropTypes.array,
  me: PropTypes.object,
  hasOneOff: PropTypes.bool,
  hasCustomLists: PropTypes.bool,
  hasMakeBottomless: PropTypes.bool,
  includesForm: PropTypes.bool,
  additionalActionButton: PropTypes.node,
  onFormChange: PropTypes.object,
  formInitialValues: PropTypes.object,
  makeBottomlessText: PropTypes.string,
  oneOffText: PropTypes.string,
  flatOrders: PropTypes.object,
  outOfStock: PropTypes.bool,
  setSelectedGrind: PropTypes.func,
  setSelectedVariant: PropTypes.func,
  isProductPage: PropTypes.bool,
  addToCustomList: PropTypes.func,
  selectedGrind: PropTypes.string,
  selectedVariant: PropTypes.string,
  productSelection: PropTypes.object,
  saveProductSelection: PropTypes.func,
  location: PropTypes.object,
  isVendorLandingShop: PropTypes.bool,
  userGrind: PropTypes.string,
  checkout: PropTypes.object,
};

PetFoodProduct.defaultProps = {
  withDescription: false,
  includesForm: true,
  flatOrders: {},
  isProductPage: false,
  isVendorLandingShop: false,
};
