import * as Sentry from '@sentry/browser';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import React, { useState, useRef, useCallback } from 'react';
import { Loader } from 'react-feather';
import classNames from 'classnames';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { Col, FormFeedback, Row } from 'reactstrap';
import { byName } from 'usps-states';
import { Field } from '../Form/Field';

const AddressAutoComplete = ({
  getInputProps,
  suggestions,
  getSuggestionItemProps,
  loading,
  ref,
  onFocus,
  onBlur,
  fieldPrefix,
  error,
  disabled,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { value, ...props } = getInputProps();

  return (
    <div className="position-relative">
      <Field
        name={`${fieldPrefix}address`}
        label="Address"
        {...props}
        autoComplete="new-address"
        innerRef={ref}
        onFocus={onFocus}
        onBlur={(...args) => {
          onBlur(...args);
          props.onBlur(...args);
        }}
        disabled={disabled}
      />
      {error && <FormFeedback className="d-block text-left one-line-address-error">{error}</FormFeedback>}
      <div className="autocomplete-dropdown">
        {(loading || suggestions.length > 0) && (
          <div className="autocomplete-inner">
            {!suggestions.length && loading && <Loader size="18" className="spin" />}
            {suggestions.map(suggestion => (
              <div
                key={suggestion.id}
                {...getSuggestionItemProps(suggestion, {
                  className: suggestion.active ? 'suggestion-item--active' : 'suggestion-item',
                })}
              >
                <span>{suggestion.description}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

AddressAutoComplete.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  getSuggestionItemProps: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  ref: PropTypes.object.isRequired,
  fieldPrefix: PropTypes.string,
  error: PropTypes.object,
  disabled: PropTypes.bool,
};

export const OneLineAddress = ({ setFieldValue, values, errors, defaultAddress, fieldPrefix = '', disabled }) => {
  const [actualAddress, setActualAddress] = useState(defaultAddress);
  const addressRef = useRef();

  const onAddressSelect = setFieldValue => async (_, placeId) => {
    const [place] = await geocodeByPlaceId(placeId);
    const address = {};

    place.address_components.forEach(address_component => {
      address_component.types.forEach(type => {
        address[type] = address_component.long_name;
      });
    });

    if (!address.locality) {
      Sentry.captureException(new Error('[Checkout] No locality'), { extra: { address } });
    }

    const street = `${address.street_number || ''} ${address.route}`;
    const city = address.locality || address.sublocality;
    const zip = address.postal_code;
    const state = get(byName, `[${address.administrative_area_level_1}].abbr`, '');
    const fullAddress = [street, city, zip, state].join(', ');

    if (addressRef.current) {
      addressRef.current.blur();
    }
    setActualAddress(fullAddress);

    setFieldValue(`${fieldPrefix}address`, fullAddress);
    setFieldValue(`${fieldPrefix}verifiedAddress.street1`, street);
    setFieldValue(`${fieldPrefix}verifiedAddress.city`, city);
    setFieldValue(`${fieldPrefix}verifiedAddress.zip`, zip);
    setFieldValue(`${fieldPrefix}verifiedAddress.state`, state);
  };

  const onFocus = useCallback(() => setFieldValue(`${fieldPrefix}address`, ''), []);
  const onBlur = useCallback(() => setFieldValue(`${fieldPrefix}address`, actualAddress), [actualAddress]);

  return (
    <>
      <Row>
        <Col xs="9">
          {window.google && (
            <PlacesAutocomplete
              searchOptions={{
                types: ['geocode'],
                componentRestrictions: { country: ['us'] },
              }}
              value={(get(values, `${fieldPrefix}verifiedAddress`) || { street1: '' }).street1}
              onChange={address => setFieldValue(`${fieldPrefix}verifiedAddress.street1`, address)}
              onSelect={onAddressSelect(setFieldValue)}
            >
              {props =>
                AddressAutoComplete({
                  ...props,
                  ref: addressRef,
                  onFocus,
                  onBlur,
                  fieldPrefix,
                  disabled,
                  error:
                    errors &&
                    (get(errors, 'verifiedAddress') ||
                      get(errors, 'verifiedAddress.street1') ||
                      get(errors, `${fieldPrefix}verifiedAddress`) ||
                      get(errors, `${fieldPrefix}verifiedAddress.street1`)),
                })
              }
            </PlacesAutocomplete>
          )}
        </Col>
        <Col xs="3">
          <Field name={`${fieldPrefix}verifiedAddress.street2`} type="text" label="Unit" disabled={disabled} />
        </Col>
      </Row>
      <div className={classNames({ 'd-none': !actualAddress })}>
        <Field name={`${fieldPrefix}verifiedAddress.zip`} type="hidden" label="Zip code" />
        <Field name={`${fieldPrefix}verifiedAddress.city`} type="hidden" label="City" />
        <Field name={`${fieldPrefix}verifiedAddress.state`} type="hidden" label="State" />
      </div>
    </>
  );
};

OneLineAddress.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  defaultAddress: PropTypes.string,
  fieldPrefix: PropTypes.string,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};
