import { createHashTag } from '@bottomless/common/src/utils';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { NewVendorTag } from '../../../components/NewVendorTag';

export const Parameters = ({ product, onFilterClick, featuredTags, isVendorLandingShop, checkout, me }) => {
  const filterClick = (type, id) => e => {
    e.preventDefault();
    e.stopPropagation();
    onFilterClick && onFilterClick(type, id);
  };

  const likesProportion = useMemo(() => {
    const likes = product.likes || 0;
    const dislikes = product.dislikes || 0;

    if (!likes && !dislikes) {
      return null;
    }

    return likes + dislikes;
  }, [product.likes, product.dislikes]);

  return (
    <>
      {!product.likes ? null : (
        <div className="text-nowrap small mb-2">
          <i className="fa fa-heart text-danger" /> {product.likes}
          {likesProportion !== null && <span className="ml-1 text-secondary">of {likesProportion} reviews</span>}
        </div>
      )}
      <div className="product-card-parameters">
        {product.attributes?.tags?.map(tag => (
          <>
            <a href={createHashTag(tag)} key={tag} className="small" onClick={filterClick('attributes.tags', tag)}>
              {createHashTag(tag)}
            </a>{' '}
          </>
        ))}
        {product.tags &&
          product.tags.map(tag => (
            <>
              <a
                href={createHashTag(tag.name)}
                key={tag._id}
                className="small"
                onClick={filterClick((featuredTags || []).includes(tag._id) ? 'type' : 'tag', tag._id)}
                disabled={isVendorLandingShop}
              >
                {createHashTag(tag.name)}
              </a>{' '}
            </>
          ))}
        {!product.vendor_id || me?.vendor_id || checkout?.source === 'shopify' ? null : (
          <>
            <NewVendorTag vendor={product.vendor_id} />{' '}
          </>
        )}
      </div>
    </>
  );
};

Parameters.propTypes = {
  product: PropTypes.object.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  withDetails: PropTypes.bool,
  featuredTags: PropTypes.array,
  isVendorLandingShop: PropTypes.bool,
  checkout: PropTypes.object,
  me: PropTypes.object,
};

Parameters.defaultProps = {
  onFilterClick: () => {},
  isVendorLandingShop: false,
};
