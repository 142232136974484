import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Loader } from '@bottomless/common/components';
import { VendorLandingPage } from '../pages/VendorLandingPage/VendorLandingPage';
import { getVendorAction } from '../store/vendor';
import { MonolithFallback } from './MonolithFallback';

const FallbackRouterComponent = ({ match, location, vendor, getVendor }) => {
  const [isFetchingVendor, setIsFetchingVendor] = useState(true);

  useEffect(() => {
    (async () => {
      await getVendor(match.url);
      setIsFetchingVendor(false);
    })();
  }, [getVendor, match.url]);

  if (!vendor) {
    if (isFetchingVendor) {
      return <Loader fullPage />;
    }
    return <MonolithFallback location={location} />;
  }

  return <Route exact component={VendorLandingPage} />;
};

FallbackRouterComponent.propTypes = {
  getVendor: PropTypes.func.isRequired,
  vendor: PropTypes.object,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
};

export const FallbackRouter = connect(
  ({ vendor }, { match }) => ({
    vendor: vendor.data && vendor.data.find(v => v.landing_page === match.url),
  }),
  dispatch => ({
    getVendor: slug => dispatch(getVendorAction(slug)),
  })
)(FallbackRouterComponent);
