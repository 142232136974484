import PropTypes from 'prop-types';
import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { FormFeedback } from 'reactstrap';
import { useInterval } from '@bottomless/common/hooks';

export const GoogleSignupButton = ({ onAuth, getMe, onAuthSuccess, postAuthSuccess, onSignUp, onSignUpSuccess }) => {
  const [errors, setErrors] = useState(null);

  useInterval(
    () => {
      setErrors(null);
    },
    errors ? 5000 : null
  );

  const handler = async data => {
    try {
      const { payload } = await onAuth({ credential: data.tokenId });

      if (payload.state === 'AUTHORIZED_USER') {
        await onAuthSuccess(payload);
        await getMe();
        postAuthSuccess(payload);
      }

      if (payload.state === 'GUEST') {
        const signupResponse = await onSignUp(payload);
        onSignUpSuccess(signupResponse.payload);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setErrors(e.details);
    }
  };

  const onFailure = error => {
    // eslint-disable-next-line no-console
    console.error(error);
  };

  return (
    <div className="d-flex flex-column">
      <div>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          onSuccess={handler}
          onFailure={onFailure}
          theme="dark"
          buttonText="Sign Up With Google"
          className="button-google-signup"
          longtitle={true}
        />
      </div>
      {errors && (
        <div>
          {Object.keys(errors).map(key => (
            <FormFeedback className="d-block" key={key}>
              {errors[key]}
            </FormFeedback>
          ))}
        </div>
      )}
    </div>
  );
};

GoogleSignupButton.propTypes = {
  onSignUpSuccess: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
  onAuth: PropTypes.func.isRequired,
  onAuthSuccess: PropTypes.func.isRequired,
  postAuthSuccess: PropTypes.func.isRequired,
  getMe: PropTypes.func.isRequired,
};
