import { REHYDRATE } from 'redux-persist';

export const staticPersistReducer = ({ staticData, version }, baseReducer) => (state, action) => {
  if (!staticData || action.type !== REHYDRATE || action.payload) {
    return baseReducer(state, action);
  }

  return baseReducer(state, {
    ...action,
    payload: { ...staticData, _persist: { rehydrated: true, version } },
  });
};
