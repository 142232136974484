import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Heart, Slash } from 'react-feather';
import { Col, Card, CardText, CardBody, CardTitle } from 'reactstrap';
import { DateFormat } from '@bottomless/common/components';
import { ReviewBrewingMethods } from '@bottomless/common/src/constants';
import { useToggle } from '@bottomless/common/hooks';
import { DetailedReview } from './DetailedReview';
import { getUserAddress } from '../../utils/address';
import './Review.scss';

const BREWING_METHODS = {
  [ReviewBrewingMethods.Aeropress]: 'Aeropress',
  [ReviewBrewingMethods.PourOver]: 'Pour Over',
  [ReviewBrewingMethods.SuperautomaticEspressoMachine]: 'Superautomatic Espresso Machine',
  [ReviewBrewingMethods.EspressoMachine]: 'Espresso Machine',
  [ReviewBrewingMethods.FrenchPress]: 'French Press',
  [ReviewBrewingMethods.Moccamaster]: 'Moccamaster',
  [ReviewBrewingMethods.Drip]: 'Drip',
};

export const Review = ({ feedback, fullWidth }) => {
  const [isDetailModalOpen, toggleDetailsModal] = useToggle();
  const hasMoreContent = useMemo(() => feedback?.content?.length > 115 || feedback?.photos?.length > 0, [feedback]);
  const isNotRichReview = useMemo(() => !feedback.brewing_method, [feedback.brewing_method]);
  const limit = useMemo(() => (fullWidth ? +Infinity : 115), [fullWidth]);

  const userAddress = useMemo(() => getUserAddress({ feedback }), [feedback]);

  return (
    <Col xs="12" md={fullWidth ? undefined : 4} className="d-flex flex-column review">
      <Card className="card-review">
        <CardBody className="d-flex flex-column">
          <CardTitle>
            <div className="d-flex justify-content-between">
              <span className="review-person">
                {feedback.user_id.first_name} <span className="city">({userAddress})</span>
              </span>
              <span className="text-danger">
                {feedback.like ? (
                  <Heart fill="currentColor" size="20" role="img" aria-label="Heart" />
                ) : (
                  <Slash size="20" role="img" aria-label="Slash" />
                )}
              </span>
            </div>
          </CardTitle>
          <span
            className={`review-body d-flex flex-column justify-content-between ${
              isNotRichReview ? 'justify-content-end' : ''
            }`}
          >
            <CardText>
              {feedback.brewing_method && (
                <span className="content mt-2 mb-1 w-100 brewing-method">
                  <span className="font-weight-bold">Brewing method:</span>{' '}
                  {BREWING_METHODS[feedback.brewing_method] || feedback.brewing_method}
                </span>
              )}
              {feedback.content && (
                <span className="mb-0 review-description">
                  <br />
                  {feedback.content.substring(0, limit)}
                  {feedback.content.length > limit && '...'}
                </span>
              )}
            </CardText>

            <span className="d-flex align-items-center justify-content-between review-more">
              <DateFormat date={feedback.timestamp} />
              {hasMoreContent && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a className="mb-0 read-more-link" onClick={toggleDetailsModal}>
                  Read More
                </a>
              )}
            </span>
          </span>
        </CardBody>
      </Card>
      {isDetailModalOpen && (
        <DetailedReview
          isOpen={isDetailModalOpen}
          toggle={toggleDetailsModal}
          feedback={feedback}
          brewingMethods={BREWING_METHODS}
        />
      )}
    </Col>
  );
};

Review.propTypes = {
  feedback: PropTypes.shape({
    user_id: PropTypes.object.isRequired,
    timestamp: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number]),
    content: PropTypes.string,
    like: PropTypes.bool,
    dislike: PropTypes.bool,
    photos: PropTypes.arrayOf(PropTypes.string),
    brewing_method: PropTypes.string,
  }),
  fullWidth: PropTypes.bool,
};
