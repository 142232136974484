import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { Image } from '@bottomless/common/components';
import { Step } from './Step';

const HowItWorksSectionComponent = ({ hasCustomStyles = false, vendor }) => {
  return (
    <section className={`how-it-works-section ${hasCustomStyles ? 'custom-styles' : ''}`}>
      <Container>
        <h1 className="text-center mb-5">How it Works</h1>
        {!hasCustomStyles ? (
          <div>
            <p className="description text-center pb-lg-4">
              Bottomless is the first subscription by usage which tracks your consumption via our smart scale and
              re-orders for you at the perfect time, everytime{' '}
              <span role="img" aria-label="Fire">
                🔥
              </span>
            </p>
            <Row className="mt-5">
              <Step
                number="Step 1"
                title="Choose your product"
                description="Choose any product to automatically re-stock. You will receive the Bottomless smart scale with your first order."
                icon="shopping-bag"
              />
              <Step
                number="Step 2"
                title="Set up your scale"
                description="Setup is as easy as connecting to wifi. The scale will track your consumption and re-order at the perfect time!"
                icon="wifi"
              />
              <Step
                number="Step 3"
                title="Sit back and relax"
                description="No more manual re-ordering. Experience less wastage and on-time delivery with our automatic usage-based re-ordering system."
                icon="repeat"
              />
            </Row>
          </div>
        ) : (
          <div className="how-it-works-steps">
            <Row className="align-items-center">
              <Col xs="12" md="6" className="pr-lg-5 pr-0">
                <div className="d-flex flex-column align-items-lg-start align-items-center mb-lg-5 mb-4">
                  <p style={{ color: vendor?.custom_styles?.brand_color }} className="mb-0">
                    Step 1
                  </p>
                  <h3>Choose Your Product</h3>
                  <p className="step-description">{`Pick from a catalog of ${vendor.name} products.`}</p>
                </div>
                <div className="d-flex flex-column align-items-lg-start align-items-center mb-lg-5 mb-4">
                  <p style={{ color: vendor?.custom_styles?.brand_color }} className="mb-0">
                    Step 2
                  </p>
                  <h3>Setup Your Scale</h3>
                  <p className="step-description">Setup takes 59 seconds. Battery lasts a year.</p>
                </div>
                <div className="d-flex flex-column align-items-lg-start align-items-center mb-lg-0 mb-4">
                  <p style={{ color: vendor?.custom_styles?.brand_color }} className="mb-0">
                    Step 3
                  </p>
                  <h3>Sit Back and Relax</h3>
                  <p className="step-description">
                    Store your bag on the scale. A new order arrives exactly when you need it.
                  </p>
                </div>
              </Col>
              <Col xs="12" md="6" className="d-flex justify-content-center">
                <Image
                  src={vendor?.custom_styles?.images?.arrowImage}
                  alt="Scale reordering"
                  className="how-it-works-image"
                />
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </section>
  );
};

HowItWorksSectionComponent.propTypes = {
  vendor: PropTypes.object.isRequired,
  hasCustomStyles: PropTypes.bool,
};

export const HowItWorksSection = memo(HowItWorksSectionComponent);
