import React, { memo, useState } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Plus } from 'react-feather';

const FaqItemComponent = ({ question, answer }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="d-flex flex-column justify-content-between w-100 px-3 py-2 faq-item">
      <div className="d-flex justify-content-between align-items-center cursor-pointer" onClick={toggle}>
        <div className="cursor-pointer font-weight-bold text-uppercase py-2 faq-heading">{question}</div>
        <Plus
          size="40"
          color="#0632C2"
          className={classnames('faq-icon', {
            rotated: !isCollapsed,
          })}
          role="img"
          aria-label="Plus"
        />
      </div>
      <Collapse isOpen={!isCollapsed}>
        <div className="mt-2 mr-5">
          <p className="text-secondary pr-lg-4 pr-2">{answer}</p>
        </div>
      </Collapse>
    </div>
  );
};

FaqItemComponent.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export const FaqItem = memo(FaqItemComponent);
