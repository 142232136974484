import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Button } from 'reactstrap';

export const Parameters = ({ product }) => {
  const avgAltitude = ((product.altitude_max || 0) + (product.altitude_min || 0)) / 2;

  const feedbacks = useMemo(() => {
    let totLikes = 0;
    let totDislikes = 0;
    for (const feedback of product.feedback) {
      if (feedback.like && !feedback.dislike) {
        totLikes += 1;
      }
      if (feedback.dislike && !feedback.like) {
        totDislikes += 1;
      }
    }
    return [totLikes, totDislikes];
  }, [product]);

  return (
    <div className="product-card-parameters">
      {!product.rotating && (
        <>
          {product.roast ? (
            <Button
              color="danger"
              size="sm"
              outline
              className={`btn-no-transform mr-3 mb-3 roast--${product.roast.name}`}
            >
              {product.roast.name}
            </Button>
          ) : (
            undefined
          )}
          {product.origin ? (
            <Button color="danger" size="sm" outline className="btn-no-transform mr-3 mb-3">
              <span className="d-flex align-items-center">{product.origin.name}</span>
            </Button>
          ) : (
            undefined
          )}
        </>
      )}
      {product.rotating && product.description && <div dangerouslySetInnerHTML={{ __html: product.description }} />}
      {product.tags &&
        product.tags.map(tag => (
          <Button
            key={tag._id}
            color="secondary"
            size="sm"
            outline
            className={`btn-no-transform mr-3 mb-3 rounded-pill tag--${tag.name}`}
          >
            {tag.name}
          </Button>
        ))}

      <>
        {product.tasting_notes &&
          product.tasting_notes.map(note => (
            <Button
              key={note._id}
              color="secondary"
              size="sm"
              outline
              className="btn-no-transform mr-3 mb-3 rounded-pill"
            >
              {note.name}
            </Button>
          ))}
        {avgAltitude !== 0 && (
          <Button color="secondary" size="sm" outline className="btn-no-transform btn-no-hover mr-3 mb-3 rounded-pill">
            {avgAltitude} masl
          </Button>
        )}
        {product.size && (
          <Button color="secondary" size="sm" outline className="btn-no-transform mr-3 mb-3 rounded-pill">
            {product.size}oz
          </Button>
        )}
        {product.process && (
          <Button color="secondary" size="sm" outline className="btn-no-transform mr-3 mb-3 rounded-pill">
            {product.process.name}
          </Button>
        )}
      </>
      {feedbacks.length !== 2 || feedbacks[0] === 0 ? null : (
        <Button
          color="secondary"
          size="sm"
          outline
          className={`btn-no-transform mr-3 mb-3 rounded-pill tag--likes px-2`}
        >
          <span className="text-nowrap">
            {' '}
            <i className="fa fa-heart coral" /> {feedbacks[0]}
            {feedbacks[0] + feedbacks[1] !== 0 && (
              <span className="ml-1" style={{ color: '#adb5bd' }}>
                of {feedbacks[0] + feedbacks[1]} reviews
              </span>
            )}
          </span>
        </Button>
      )}
    </div>
  );
};

Parameters.propTypes = {
  product: PropTypes.object.isRequired,
};
