import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'react-feather';
import './Box.scss';

const LinkWrapper = props => {
  if (props.to?.pathname && props.to?.target === '_blank') {
    const { to, children, ...rest } = props;
    return (
      <a href={to.pathname} target={to.target} {...rest}>
        {children}
      </a>
    );
  }

  return <Link {...props} />;
};
LinkWrapper.propTypes = { children: PropTypes.node, to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]) };

const FragmentWrapper = ({ children }) => <Fragment>{children}</Fragment>;
FragmentWrapper.propTypes = { children: PropTypes.node };

export const Box = ({
  children,
  to,
  onClick,
  className,
  innerClassName,
  wrapperClassName,
  secondary,
  withShadow,
  size,
  ...props
}) => {
  const Wrapper = useMemo(() => (to ? LinkWrapper : FragmentWrapper), [to]);

  return (
    <Wrapper to={to} className={classNames('d-block box-link', wrapperClassName)}>
      <Card
        body
        color={secondary ? 'light' : 'white'}
        onClick={onClick}
        role={onClick ? 'button' : undefined}
        className={classNames(
          'box flex-row align-items-center justify-content-between',
          { 'box-with-shadow': withShadow },
          { [`box-${size}`]: size },
          className
        )}
        {...props}
      >
        <div className={classNames('flex-grow-1', innerClassName)}>{children}</div>
        {(to || onClick) && (
          <div className="pl-2 pl-md-3 text-secondary box-chevron">
            <ChevronRight strokeWidth="1" width="24" height="24" />
          </div>
        )}
      </Card>
    </Wrapper>
  );
};

Box.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  secondary: PropTypes.bool,
  withShadow: PropTypes.bool,
  size: PropTypes.string,
};
