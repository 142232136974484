import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { useMediaQuery } from 'react-responsive';

export const CoffeeSorting = ({ onChange, sorting }) => {
  const sorts = {
    desc_price: 'High price',
    asc_price: 'Low price',
    alt: 'High altitude',
    alph: 'Alphabetical',
    date_added: 'Recently added',
    likes: 'Likes',
    likes_ratio: '% of Likes',
  };

  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 });

  return (
    <div className="d-flex small align-items-baseline list-sorting">
      <strong className="d-block mr-3">Sort: </strong>
      {isTabletOrMobileDevice ? (
        <>
          <select
            name="filter"
            id="filter-select"
            onChange={e => onChange(e.target.value)}
            style={{ borderWidth: '0px', color: '#989898', textDecoration: 'underline' }}
          >
            {Object.entries(sorts).map(([key, name]) => (
              <option key={key} selected={key === sorting} value={key}>
                {name}
              </option>
            ))}
          </select>
        </>
      ) : (
        <>
          {Object.entries(sorts).map(([key, name]) => (
            <Button
              key={key}
              size="sm"
              color="link"
              onClick={() => onChange(key)}
              className="btn-no-transform list-sorting-button"
              active={sorting === key}
            >
              {name}
            </Button>
          ))}
        </>
      )}
    </div>
  );
};

CoffeeSorting.propTypes = {
  onChange: PropTypes.func.isRequired,
  sorting: PropTypes.string,
};

CoffeeSorting.defaultProps = {
  sorting: 'likes',
};
