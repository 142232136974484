import React, { memo, useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Price } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { RotationProduct } from './RotationProduct';
import { FirstProduct } from './FirstProduct';
import { DateOverrideText } from './DateOverrideText';
import { ProductDetailsModal } from './ProductDetailsModal';

const PersonalizedProductComponent = ({
  product,
  variant,
  firstVariant: originalFirstVariant,
  personalized,
  firstProduct: originalFirstProduct,
  onSignUp,
  buttonText,
  grind,
  pricingRule,
  pricing,
  alternativeProducts,
  hideAdvancedOptions,
  vendor,
  dateOverride,
  error,
  customRotationShopFilter,
  isQuizCompleted,
  expressCheckoutButton,
  selectedProduct,
  selectedVariant,
  setSelectedProduct,
  setSelectedVariant,
  isMobile,
}) => {
  const [detailsProduct, setDetailsProduct] = useState();
  const [areDetailsOpen, toggleDetails, setDetailsOpen] = useToggle();
  const [isLoading, setLoading] = useState(null);

  const isComponentMounted = useRef(false);

  useEffect(() => {
    isComponentMounted.current = true;
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  const onClick = useCallback(
    ({ product, variant }) => () => {
      setSelectedProduct(product);
      setSelectedVariant(variant);
    },
    [setSelectedProduct, setSelectedVariant]
  );

  const onInfoClick = useCallback(
    product => {
      setDetailsProduct(product);
      setDetailsOpen(true);
    },
    [setDetailsProduct, setDetailsOpen]
  );

  const handleSignup = useCallback(async () => {
    if (isComponentMounted.current) {
      setLoading(true);
    }
    await onSignUp({ first_product: { product: selectedProduct?._id, variant: selectedVariant?._id } });
    if (isComponentMounted.current) {
      setLoading(false);
    }
  }, [onSignUp, selectedProduct, selectedVariant]);

  return (
    <>
      <Container className="d-flex flex-sm-row flex-column">
        <Row className="justify-content-between align-items-center">
          <Col xs="12" md="6">
            <div className="d-flex flex-column custom-rotation">
              <h3 className="text-center font-italic">Your Custom Rotation</h3>
              <RotationProduct
                product={product}
                personalized={personalized}
                grind={grind}
                isQuizCompleted={isQuizCompleted}
                vendor={vendor}
                isMobile={isMobile}
              />
              <div className="first-product-price d-flex justify-content-between mb-4">
                <span className="font-weight-bold">
                  <Price
                    value={pricing.price}
                    isFirstBag={true}
                    size={variant.size}
                    pricingRule={pricingRule}
                    discountClass="discounted-price"
                  />
                </span>
                <span className="text-secondary">{variant.size}oz</span>
              </div>
              <DateOverrideText dateOverride={dateOverride} />
            </div>
          </Col>
          <Col xs="12" md="5">
            <Row>
              <Col xs="12">
                {selectedProduct && selectedProduct.variants && (
                  <>
                    <h4 className="text-center font-italic mb-0">Choose your first product</h4>
                    <p className="text-secondary text-sm text-center mb-4">Selected to match your rotation</p>
                    <div className="cursor-pointer mt-3">
                      <FirstProduct
                        product={originalFirstProduct}
                        onClick={onClick({ product: originalFirstProduct, variant: originalFirstVariant })}
                        onInfoClick={onInfoClick}
                        active={originalFirstProduct._id === selectedProduct._id}
                      />
                    </div>
                    {alternativeProducts &&
                      alternativeProducts.map(alternativeProduct => (
                        <div className="cursor-pointer" key={alternativeProduct.product._id}>
                          <FirstProduct
                            product={alternativeProduct.product}
                            onClick={onClick(alternativeProduct)}
                            onInfoClick={() =>
                              onInfoClick({
                                ...alternativeProduct.product,
                                likes: alternativeProduct.likes,
                                dislikes: alternativeProduct.dislikes,
                                feedback: alternativeProduct.feedback,
                              })
                            }
                            active={alternativeProduct.product._id === selectedProduct._id}
                          />
                        </div>
                      ))}
                  </>
                )}
              </Col>
              <Col className="d-flex flex-column mt-3 justify-content-center" xs="12">
                <div className="d-flex justify-content-between other-action-buttons">
                  {!hideAdvancedOptions && <Link to="/filter_checkout/advanced">See Advanced Options</Link>}
                  <Link
                    to={`/public_shop${
                      customRotationShopFilter
                        ? `${customRotationShopFilter}&back=/filter_checkout?finished=true`
                        : '?back=/filter_checkout?finished=true'
                    }`}
                  >
                    Shop Matching Coffee
                  </Link>
                </div>
                <div className={`d-flex ${expressCheckoutButton ? 'flex-column' : ''}`}>
                  <Button
                    color="primary"
                    onClick={handleSignup}
                    disabled={isLoading}
                    block
                    className="d-flex align-items-center justify-content-center mt-3 mb-2 checkout-btn"
                  >
                    {isLoading && <span>Processing...</span>}
                    {!isLoading && <span>{buttonText}</span>}
                  </Button>
                  {expressCheckoutButton}
                </div>
                {error && <div className="text-danger">{error}</div>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={areDetailsOpen} toggle={toggleDetails} size="lg" className="shop-modal">
        <ModalHeader toggle={toggleDetails}>Product Details</ModalHeader>
        <ModalBody>
          {detailsProduct && <ProductDetailsModal product={detailsProduct} isMobileDevice={isMobile} />}
        </ModalBody>
      </Modal>
    </>
  );
};

PersonalizedProductComponent.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    vendor_id: PropTypes.string,
    description: PropTypes.string,
  }),
  firstProduct: PropTypes.object.isRequired,
  personalized: PropTypes.object.isRequired,
  variant: PropTypes.shape({
    size: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
  firstVariant: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
  onSignUp: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  grind: PropTypes.string,
  pricingRule: PropTypes.object.isRequired,
  pricing: PropTypes.shape({
    price: PropTypes.number.isRequired,
  }).isRequired,
  alternativeProducts: PropTypes.array,
  dateOverride: PropTypes.string,
  vendor: PropTypes.object,
  hideAdvancedOptions: PropTypes.bool,
  error: PropTypes.string,
  largeButton: PropTypes.bool,
  customRotationShopFilter: PropTypes.string,
  isQuizCompleted: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  expressCheckoutButton: PropTypes.node,
  selectedProduct: PropTypes.object.isRequired,
  selectedVariant: PropTypes.object.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  setSelectedVariant: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

PersonalizedProductComponent.defaultProps = {
  buttonText: 'Start Coffee Journey',
  hideAdvancedOptions: false,
  filterCheckoutShop: false,
  isQuizCompleted: false,
  isModalOpen: false,
};

export const PersonalizedProduct = memo(PersonalizedProductComponent);
