import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const SendReviewTypes = createAction('Review', 'Send review');

export const sendReviewAction = data => ({
  [RSAA]: {
    endpoint: 'reviews',
    method: 'POST',
    body: data,
    types: [SendReviewTypes.REQUEST, SendReviewTypes.SUCCESS, SendReviewTypes.FAILURE],
  },
});

export const GetProductReviewTypes = createAction('Review', 'Get product review');

export const getProductReviewAction = (productId, userId) => ({
  [RSAA]: {
    endpoint: `reviews/products/${productId}/${userId}`,
    method: 'GET',
    types: [GetProductReviewTypes.REQUEST, GetProductReviewTypes.SUCCESS, GetProductReviewTypes.FAILURE],
  },
});

export const GetReviewTypes = createAction('Review', 'Get review');

export const getReviewAction = id => ({
  [RSAA]: {
    endpoint: `reviews/${id}`,
    method: 'GET',
    types: [GetReviewTypes.REQUEST, GetReviewTypes.SUCCESS, GetReviewTypes.FAILURE],
  },
});

export const UploadReviewPictureTypes = createAction('Review', 'Upload picture');

export const uploadReviewPictureAction = (productId, data) => ({
  [RSAA]: {
    endpoint: `reviews/products/${productId}/upload`,
    method: 'POST',
    headers: { upload: true },
    body: data,
    types: [UploadReviewPictureTypes.REQUEST, UploadReviewPictureTypes.SUCCESS, UploadReviewPictureTypes.FAILURE],
  },
});
