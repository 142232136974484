import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PaymentIcon } from '../../../components/PaymentIcon/PaymentIcon';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Button } from 'reactstrap';

const CardDetailsComponent = ({
  checkout,
  showStripeInput,
  handleChange,
  setFieldValue,
  onCardEdit,
  setStripe,
  stripe,
}) => {
  useEffect(() => {
    if (stripe) {
      setStripe(stripe);
    }
  }, [stripe, setStripe]);

  return (
    <>
      <label htmlFor="checkout-payment">Card Details</label>
      {showStripeInput && <CardElement onChange={handleChange(setFieldValue)} id="checkout-payment" />}
      {!showStripeInput && (
        <div className="d-flex align-items-center justify-content-between mb-2 pl-2 saved-card">
          <div className="d-flex align-items-center justify-content-between">
            <PaymentIcon id={checkout.stripe_brand?.toLowerCase()} />
            <div className="d-flex card-number">
              <span className="asterisks">
                <sub>****</sub>
              </span>
              <span className="asterisks">
                <sub>****</sub>
              </span>
              <span className="asterisks">
                <sub>****</sub>
              </span>
              <span>{checkout.stripe_last_four}</span>
            </div>
          </div>
          <Button color="link" onClick={onCardEdit} className="card-edit-button">
            Edit
          </Button>
        </div>
      )}
    </>
  );
};

CardDetailsComponent.propTypes = {
  checkout: PropTypes.shape({ stripe_brand: PropTypes.string, stripe_last_four: PropTypes.string }),
  showStripeInput: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onCardEdit: PropTypes.func.isRequired,
  stripe: PropTypes.object,
  setStripe: PropTypes.func.isRequired,
};

export const CardDetails = injectStripe(CardDetailsComponent);
