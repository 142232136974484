import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { AdvanceBuilderHeading } from '../AdvanceBuilderHeading';
import { Price } from './Price';

const PRICE_TYPES = ['basic', 'standard', 'premium'];

export const Prices = ({ disabled, onChange, products, hideOptionWithoutProducts, optionFilter }) => (
  <>
    <AdvanceBuilderHeading title="Price" subtitle="Choose one" />
    <Row className="pb-4 pt-2">
      {PRICE_TYPES.map(type => (
        <Price
          key={type}
          disabled={disabled}
          onChange={onChange}
          type={type.toLowerCase()}
          products={products}
          hideOptionWithoutProducts={hideOptionWithoutProducts}
          optionFilter={optionFilter}
        />
      ))}
    </Row>
  </>
);

Prices.propTypes = {
  disabled: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  optionFilter: PropTypes.object,
};
