import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetStartedCustomProductTypes = createAction('Custom product request', 'Get Started');

export const getStartedCustomProductAction = data => ({
  [RSAA]: {
    endpoint: `pilot/request`,
    method: 'POST',
    body: data,
    types: [
      GetStartedCustomProductTypes.REQUEST,
      GetStartedCustomProductTypes.SUCCESS,
      GetStartedCustomProductTypes.FAILURE,
    ],
  },
});

export const GetUserCustomProductRequest = createAction('Custom product request', 'Get User Custom Product Request');

export const getUserCustomProductRequestAction = pilotCategory => ({
  [RSAA]: {
    endpoint: `pilot/request?pilotCategory=${pilotCategory}`,
    method: 'GET',
    types: [
      GetUserCustomProductRequest.REQUEST,
      GetUserCustomProductRequest.SUCCESS,
      GetUserCustomProductRequest.FAILURE,
    ],
  },
});
