import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DateFormat } from '@bottomless/common/components';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { PhotoModal } from '../../../../components/Review';

const DetailedReviewComponent = ({ isOpen, toggle, feedback, brewingMethods }) => {
  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle} className="modal-review-details">
      <ModalHeader toggle={toggle}>Review Details</ModalHeader>
      <ModalBody>
        <p>
          <span className="content-title">Review By:</span> {feedback.user_id.first_name}
        </p>
        <p>
          <span className="content-title">Review Date:</span> <DateFormat date={feedback.timestamp} />
        </p>
        <p>
          <span className="content-title">Brewing Method:</span>{' '}
          {brewingMethods[feedback.brewing_method] || feedback.brewing_method}
        </p>
        <p className="content-text">{feedback.content}</p>
        {feedback.photos.length > 0 && (
          <div>
            <p className="content-title">Photos: </p>
            <div className="review-photos">{feedback.photos.length > 0 && <PhotoModal photos={feedback.photos} />}</div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

DetailedReviewComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  feedback: PropTypes.shape({
    user_id: PropTypes.object.isRequired,
    timestamp: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number]),
    content: PropTypes.string,
    like: PropTypes.bool,
    dislike: PropTypes.bool,
    photos: PropTypes.arrayOf(PropTypes.string),
    brewing_method: PropTypes.string,
  }),
  brewingMethods: PropTypes.object.isRequired,
};

export const DetailedReview = memo(DetailedReviewComponent);
