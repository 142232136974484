import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetOpenShopTypes = createAction('Open shop', 'Get all');

export const getOpenShopAction = () => ({
  [RSAA]: {
    endpoint: 'open-shop',
    method: 'GET',
    types: [GetOpenShopTypes.REQUEST, GetOpenShopTypes.SUCCESS, GetOpenShopTypes.FAILURE],
  },
});
