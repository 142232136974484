import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CheckCircle, Circle } from 'react-feather';
import { Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';
import { Form, SubmitButton, VariantPrice } from '@bottomless/common/components';
import { fromOz } from '@bottomless/common/utils';
import { useToggle } from '@bottomless/common/hooks';
import { UserStatuses } from '@bottomless/common/src/constants';
import { SizeWarningModalBody } from '../../../components/SizeWarningModal';

const MakeBottomlessSchema = Yup.object().shape({
  variant: Yup.string(),
  oneOff: Yup.bool(),
});

const BUTTON_TYPE = {
  SET_DEFAULT: 'set-default',
  ONE_OFF: 'one-off',
};

export const MakeBottomless = ({
  product,
  priceRule,
  isFirstBag,
  onChooseProduct,
  onOneOffOrder,
  onChooseSuccess,
  size,
  me,
  hasOneOff,
  additionalActionButton,
  onFormChange,
  initialValues = {},
  selectText = 'Select',
  oneOffText = 'Buy this one off',
  setDefaultText = 'Select',
  hasMakeBottomless = true,
  outOfStock = false,
  setSelectedVariant,
  isProductPage = false,
  selectedVariant,
  productSelection,
  saveProductSelection,
}) => {
  const [typeSubmitting, setTypeSubmitting] = useState();
  const [sizeWarningModal, toggleSizeWarningModal, setSizeWarningModal] = useToggle(false);
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [{ checkout }] = useCookies(['checkout']);
  const shopPath = useMemo(() => (me ? '/shop?category=byo' : '/public_shop?category=byo'), [me]);

  const userDefaultSize = useMemo(() => {
    if (!me) {
      return;
    }
    if (me.product?.product?.rotating && me?.personalized?.size) {
      return me.personalized.size;
    }
    const variant = me.product?.product?.variants?.find(row => row._id === me.product.variant);
    return variant.size;
  }, [me]);

  useEffect(() => {
    const filtered = product.variants.filter(v => {
      return v.available;
    });
    setFilteredVariants(filtered);
  }, [isFirstBag, priceRule, product.variants]);

  const chooseVariant = (setFieldValue, value) => e => {
    e.preventDefault();
    e.stopPropagation();
    setFieldValue('variant', value);

    if (setSelectedVariant) {
      setSelectedVariant(value);
    }

    if (isProductPage) {
      if (productSelection.product === String(product._id)) {
        productSelection.variant = value;
      } else {
        productSelection.variant = value;
        productSelection.product = String(product._id);
      }
      saveProductSelection(productSelection);
    }
  };

  const isClosedOrOffboarding = useMemo(() => [UserStatuses.Closed, UserStatuses.Offboarding].includes(me?.status), [
    me,
  ]);

  const handleSubmit = useCallback(
    ({ submitForm, setFieldValue, oneOff, type, forceSize, values }) => e => {
      e.stopPropagation();
      setTypeSubmitting(type);

      const variant = product.variants.find(row => row._id === values?.variant);
      const isSameSize = userDefaultSize && values?.variant ? userDefaultSize === variant.size : true;

      if (!oneOff && !isSameSize && !forceSize) {
        setSizeWarningModal(true);
        return;
      }

      setFieldValue('oneOff', oneOff);
      setTimeout(submitForm, 0);
      setSizeWarningModal(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [me]
  );

  const onSubmit = useCallback(
    ({ oneOff, ...data }) => {
      return oneOff ? onOneOffOrder(product, data) : onChooseProduct(product, data);
    },
    [onChooseProduct, onOneOffOrder, product]
  );

  const getDefaultVariant = () => {
    if (me && me.personalized && me.personalized.size) {
      const { size } = me.personalized;
      const userDefaultVariant = product.variants.filter(v => v.size === size);
      if (userDefaultVariant && userDefaultVariant.length) {
        return userDefaultVariant[0]._id;
      }
    }

    const [defaultVariant] =
      product && product.variants
        ? product.variants.filter(v => v.available).sort((a, b) => (a.size > b.size ? 1 : -1))
        : [];
    return defaultVariant && defaultVariant._id;
  };

  const noMatchingCategory = useMemo(
    () => !me?.accounts.find(account => account.product.product.category._id === product.category?._id),
    [me, product]
  );

  const isDifferentVendor = useMemo(
    () => !!me && !me.accounts.find(account => !account.vendor_id || account.vendor_id === product.vendor_id._id),
    [me, product]
  );

  let buttonText = selectText;
  if (outOfStock) {
    buttonText = 'Out of Stock';
  } else if (!me || noMatchingCategory || (!checkout && isDifferentVendor)) {
    buttonText = 'Sign Up';
  }

  const calculatedSetDefaultText = useMemo(() => (noMatchingCategory ? 'Sign Up' : setDefaultText), [
    setDefaultText,
    noMatchingCategory,
  ]);

  return (
    <Form
      initialValues={{
        variant: selectedVariant || getDefaultVariant(),
        oneOff: false,
        ...initialValues,
      }}
      validationSchema={MakeBottomlessSchema}
      onSubmit={onSubmit}
      onSuccess={onChooseSuccess}
      onChange={onFormChange}
    >
      {({ isSubmitting, values, setFieldValue, submitForm }) => (
        <>
          <Modal size="sm" isOpen={sizeWarningModal} className="shop-modal" autoFocus={true}>
            <ModalHeader>You have selected a different size from your account default.</ModalHeader>
            <ModalBody>
              <SizeWarningModalBody product={product} values={values} me={me} />
            </ModalBody>
            <ModalFooter className="flex-column align-items-stretch">
              <Button
                color="primary"
                onClick={handleSubmit({
                  submitForm,
                  setFieldValue,
                  addToList: BUTTON_TYPE.CUSTOM_LIST === typeSubmitting,
                  type: typeSubmitting,
                  forceSize: true,
                  values,
                })}
              >
                Yes, go ahead
              </Button>{' '}
              <Button color="secondary" onClick={toggleSizeWarningModal}>
                No, I changed my mind
              </Button>
            </ModalFooter>
          </Modal>
          <Row className="variants">
            {filteredVariants
              .sort((a, b) => (a.size > b.size ? 1 : -1))
              .map((variant, i) => (
                <Col xs={12} key={i} className="mb-2">
                  <div
                    type="button"
                    size="sm"
                    className={`btn-option d-flex align-items-center justify-content-between py-1 px-2 ${
                      values.variant === variant._id ? 'btn-option-active' : ''
                    }`}
                    onClick={chooseVariant(setFieldValue, variant._id)}
                  >
                    <div>
                      {values.variant === variant._id && (
                        <CheckCircle className="mr-2" size="16" role="img" aria-label="Checked" />
                      )}
                      {values.variant !== variant._id && (
                        <Circle
                          className="mr-2"
                          color="rgba(0, 0, 0, 0.125)"
                          size="16"
                          role="img"
                          aria-label="Unchecked"
                        />
                      )}
                    </div>

                    <div className="d-flex flex-grow-1 justify-content-between">
                      <div>{fromOz({ oz: variant.size, unit: variant.unit }).formatted}</div>
                      <div className="product-price">
                        <VariantPrice
                          variant={variant}
                          user={me}
                          pricingRule={priceRule}
                          isFirstBag={isFirstBag}
                          size={variant.size}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
          {me && !isFirstBag && !checkout && !noMatchingCategory && !isDifferentVendor && (
            <>
              {hasMakeBottomless && (
                <>
                  <SubmitButton
                    color="primary"
                    size={size}
                    block
                    isSubmitting={isSubmitting && typeSubmitting === BUTTON_TYPE.SET_DEFAULT}
                    disabled={isSubmitting}
                    type="button"
                    onClick={handleSubmit({
                      submitForm,
                      setFieldValue,
                      oneOff: false,
                      type: BUTTON_TYPE.SET_DEFAULT,
                      values,
                    })}
                  >
                    {calculatedSetDefaultText}
                  </SubmitButton>
                </>
              )}
            </>
          )}
          {me && !isClosedOrOffboarding && hasOneOff && !checkout && !noMatchingCategory && !isDifferentVendor && (
            <SubmitButton
              color="primary"
              size={size}
              outline
              type="button"
              block
              isSubmitting={isSubmitting && typeSubmitting === BUTTON_TYPE.ONE_OFF}
              disabled={isSubmitting}
              onClick={handleSubmit({ submitForm, setFieldValue, oneOff: true, type: BUTTON_TYPE.ONE_OFF, values })}
            >
              {oneOffText}
            </SubmitButton>
          )}
          {(!me || isFirstBag || checkout || noMatchingCategory || isDifferentVendor) && (
            <SubmitButton
              disabled={outOfStock || !filteredVariants.length}
              color="primary"
              size={size}
              block
              isSubmitting={isSubmitting}
              onClick={e => e.stopPropagation()}
            >
              {buttonText}
            </SubmitButton>
          )}
          {additionalActionButton}
          {isProductPage && outOfStock && (
            <div className="more-coffee-link mt-2 text-center">
              <a href={shopPath}>Visit our shop to explore more products</a>
            </div>
          )}
        </>
      )}
    </Form>
  );
};

MakeBottomless.propTypes = {
  onChooseProduct: PropTypes.func.isRequired,
  onOneOffOrder: PropTypes.func,
  onChooseSuccess: PropTypes.func,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    available_ground: PropTypes.bool,
    variants: PropTypes.array,
    category: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    vendor_id: PropTypes.shape({ _id: PropTypes.string.isRequired }),
  }).isRequired,
  size: PropTypes.string,
  priceRule: PropTypes.object,
  isFirstBag: PropTypes.bool,
  vendor: PropTypes.object,
  me: PropTypes.object,
  hasOneOff: PropTypes.bool,
  hasMakeBottomless: PropTypes.bool,
  additionalActionButton: PropTypes.node,
  onFormChange: PropTypes.func,
  initialValues: PropTypes.object,
  selectText: PropTypes.string,
  oneOffText: PropTypes.string,
  setDefaultText: PropTypes.string,
  outOfStock: PropTypes.bool,
  setSelectedVariant: PropTypes.func,
  isProductPage: PropTypes.bool,
  selectedVariant: PropTypes.string,
  productSelection: PropTypes.shape({
    product: PropTypes.string,
    variant: PropTypes.string,
  }),
  saveProductSelection: PropTypes.func,
  location: PropTypes.object,
};

MakeBottomless.defaultProps = {
  selectedVariant: null,
  productSelection: {},
  saveProductSelection: () => {},
  location: {},
  size: 'sm',
};
