import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Toast, ToastBody } from 'reactstrap';
import './Toasts.scss';

const ToastsComponent = ({ toasts, bottom }) => (
  <div className={`toasts ${bottom ? 'toasts--bottom' : ''}`}>
    {toasts.map(toast => (
      <Toast key={toast.id} isOpen={toast.isOpen} className={`toast-${toast.type}`}>
        <ToastBody>{toast.message}</ToastBody>
      </Toast>
    ))}
  </div>
);

ToastsComponent.defaultProps = {
  bottom: false,
};

ToastsComponent.propTypes = {
  toasts: PropTypes.array.isRequired,
  bottom: PropTypes.bool,
};

export const Toasts = connect(({ toast }) => ({ toasts: toast.data }))(ToastsComponent);
