import React from 'react';
import PropTypes from 'prop-types';
import { Container, Navbar } from 'reactstrap';
import { withPlaceholder } from '@bottomless/common/components';
import classNames from 'classnames';

const ShopifyCheckoutNavbarComponent = ({ sticky, shopifyVendorLogo, isMobile, className }) => (
  <Navbar
    color="white"
    light
    fixed={sticky ? 'top' : undefined}
    expand="md"
    className={classNames('app-nav', className)}
  >
    <Container className="justify-content-center">
      <div className="placeholder-image">
        <img src={shopifyVendorLogo} alt="Vendor logo" height={isMobile ? '90' : '120'} className="vendor-logo" />
      </div>
    </Container>
  </Navbar>
);

ShopifyCheckoutNavbarComponent.propTypes = {
  sticky: PropTypes.bool,
  shopifyVendorLogo: PropTypes.string,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

export const ShopifyCheckoutNavbar = withPlaceholder({
  condition: 'shopifyVendorLogo',
  props: {
    shopifyVendorLogo:
      'https://bottomless.imgix.net/placeholder-pixel.png?auto=compress,format&fit=crop&fit=clip&h=120',
  },
})(ShopifyCheckoutNavbarComponent);
