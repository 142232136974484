import React from 'react';
import PropTypes from 'prop-types';
import { MyProduct } from './MyProduct';
import { CustomList } from './CustomList';

export const CustomRotation = ({
  wishlist,
  userProduct,
  me,
  price,
  size,
  shopFilters,
  productPricingRule,
  moveCustomListItem,
  deleteCustomListItem,
  onSubscriptionSwitch,
  onSubscriptionSwitchSuccess,
  attributes,
  updateCustomListElement,
  variant,
}) => (
  <>
    <CustomList
      wishlist={wishlist}
      productPricingRule={productPricingRule}
      moveCustomListItem={moveCustomListItem}
      deleteCustomListItem={deleteCustomListItem}
      shopFilters={shopFilters}
      attributes={attributes}
      updateCustomListElement={updateCustomListElement}
      me={me}
    />
    <MyProduct
      me={me}
      product={userProduct}
      price={price}
      size={size}
      productPricingRule={productPricingRule}
      wishlist={wishlist}
      onSubscriptionSwitch={onSubscriptionSwitch}
      onSubscriptionSwitchSuccess={onSubscriptionSwitchSuccess}
      variant={variant}
    />
  </>
);

CustomRotation.propTypes = {
  wishlist: PropTypes.array.isRequired,
  userProduct: PropTypes.object.isRequired,
  me: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  shopFilters: PropTypes.object,
  productPricingRule: PropTypes.object.isRequired,
  moveCustomListItem: PropTypes.func.isRequired,
  deleteCustomListItem: PropTypes.func.isRequired,
  onSubscriptionSwitch: PropTypes.func.isRequired,
  onSubscriptionSwitchSuccess: PropTypes.func.isRequired,
  attributes: PropTypes.object,
  updateCustomListElement: PropTypes.func.isRequired,
  variant: PropTypes.object,
};
