import { LogoutTypes, ChangeScaleTypes } from '@bottomless/common/store';
import { GetScaleStatusTypes } from './scale.actions';

const initialState = {
  status: null,
  isLoading: false,
};

export const scaleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetScaleStatusTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetScaleStatusTypes.SUCCESS:
      return {
        ...state,
        status: action.payload,
        isLoading: false,
      };

    case GetScaleStatusTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case LogoutTypes.SUCCESS:
    case ChangeScaleTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
