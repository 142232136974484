export const getTabs = match => {
  const shopUri = match.url.includes('public_shop')
    ? '/public_shop'
    : match.url.includes('get_started_shop')
    ? `/get_started_shop/${match.params.checkoutId}`
    : '/shop';

  return [
    { to: shopUri, name: 'Products' },
    { to: `${shopUri}/lists`, name: 'Lists' },
  ];
};
