import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetPetFoodProductDetailsTypes = createAction('Pet food', 'Get product details');

export const getPetFoodProductDetailsAction = id => ({
  [RSAA]: {
    endpoint: `pet-food/${id}`,
    method: 'GET',
    types: [
      GetPetFoodProductDetailsTypes.REQUEST,
      GetPetFoodProductDetailsTypes.SUCCESS,
      GetPetFoodProductDetailsTypes.FAILURE,
    ],
  },
});

export const PurchasePetFoodTypes = createAction('Pet food', 'Purchase');

export const purchasePetFoodAction = (id, data) => ({
  [RSAA]: {
    endpoint: `pet-food/${id}/purchase`,
    method: 'POST',
    body: data,
    types: [PurchasePetFoodTypes.REQUEST, PurchasePetFoodTypes.SUCCESS, PurchasePetFoodTypes.FAILURE],
  },
});

export const PetFoodLandingEmailTypes = createAction('Pet food', 'Landing email');

export const petFoodLandingEmailAction = data => ({
  [RSAA]: {
    endpoint: `pet-food/landing-email`,
    method: 'POST',
    body: data,
    types: [PetFoodLandingEmailTypes.REQUEST, PetFoodLandingEmailTypes.SUCCESS, PetFoodLandingEmailTypes.FAILURE],
  },
});
