import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'react-feather';
import classNames from 'classnames';

export const DataLoading = ({ children, isLoading, count, loadingText, withMarginTop }) => {
  if (!count && isLoading) {
    return (
      <div className={classNames('d-flex align-items-center justify-content-center', { 'mt-5': withMarginTop })}>
        <Loader size="20" className="spin mr-2" />
        <span>{loadingText}</span>
      </div>
    );
  }

  if (!count) {
    return children;
  }

  return null;
};

DataLoading.propTypes = {
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  children: PropTypes.node,
  withMarginTop: PropTypes.bool,
};

DataLoading.defaultProps = {
  children: '',
  loadingText: 'Loading data',
  withMarginTop: true,
};
