import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Card, CardBody } from 'reactstrap';
import { Image } from '@bottomless/common/components';

const TileComponent = ({ tile, history }) => {
  const navigateToProductPage = () => {
    history.push(`/public_shop?attributes.type=${tile.name}&back=/four-sigmatic&category=byo`);
  };

  return (
    <Col xs="6" sm="4" md="4" lg="4" className="mb-4">
      <Card className="type-card h-100" onClick={navigateToProductPage}>
        <CardBody className="h-100 text-center">
          <Image src={tile.image_url} alt={tile.name} />
          <h5 className="mt-2">{tile.name}</h5>
        </CardBody>
      </Card>
    </Col>
  );
};

TileComponent.propTypes = {
  tile: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export const Tile = memo(TileComponent);
