import { useOnScreen } from '@bottomless/common/src/hooks';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Col, Container } from 'reactstrap';
import './Section.scss';

export const Section = ({ title, text, image, video, reverse, actionText, onClick }) => {
  const ref = useRef();
  const onScreen = useOnScreen(ref, '-100px', true);

  return (
    <div
      className={classNames('landing-section', {
        'landing-section-visible': onScreen,
        'landing-section-reverse': !!reverse,
      })}
    >
      <Container>
        <div className="row landing-section-row" ref={ref}>
          <Col xs="12" lg="6">
            <p className="landing-section-title">{title}</p>
            <p className="landing-section-text">{text}</p>
            {actionText && onClick && (
              <Button color="dark" size="lg" className="landing-section-button" onClick={onClick}>
                {actionText}
              </Button>
            )}
          </Col>
          <Col xs="12" lg="6">
            {video && (
              <video poster={image} autoPlay loop muted playsInline className="img-fluid landing-section-image">
                {video.map((src, i) => (
                  <source key={i} src={src} />
                ))}
              </video>
            )}
            {!video && typeof image === 'string' && (
              <img src={image} alt={title} className="img-fluid landing-section-image" />
            )}
            {!video && typeof image !== 'string' && image}
          </Col>
        </div>
      </Container>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  video: PropTypes.arrayOf(PropTypes.string),
  reverse: PropTypes.bool,
  actionText: PropTypes.string,
  onClick: PropTypes.func,
};
