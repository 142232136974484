import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const CreateGenericTesterTypes = createAction('Generic beta tester', 'Create');

export const createGenericTesterAction = data => ({
  [RSAA]: {
    endpoint: 'generic-beta-testers',
    method: 'POST',
    body: data,
    types: [CreateGenericTesterTypes.REQUEST, CreateGenericTesterTypes.SUCCESS, CreateGenericTesterTypes.FAILURE],
  },
});
