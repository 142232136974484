import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import qs from 'query-string';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { DataLoading, Error } from '@bottomless/common/components';
import { stripTags } from '@bottomless/common/utils';
import { addAlertAction, addToastAction, changeScaleAction } from '@bottomless/common/store';
import { useDataEffect, useOnce, useToggle, useConditionalDataEffect } from '@bottomless/common/hooks';
import { withMetaTags } from '../../../../components/MetaTags/MetaTags';
import { withRichProductMeta } from '../../../../components/MetaTags/RichProductMeta';
import { GetStartedModal } from '../../../../components/GetStartedModal/GetStartedModal';
import { IsLoggedIn } from '../../../../guards/is-logged.guard';
import { Panel } from '../../../../layouts/Panel/Panel';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { Simple } from '../../../../layouts/Simple/Simple';
import { AccountsModal } from '../../../../components/AccountsModal';
import {
  addCustomListElementAction,
  getOrdersAction,
  getPricingRuleAction,
  getProductAction,
  makeBottomlessProductAction,
  saveProductSelectionAction,
  sendReviewAction,
  triggerOrderAction,
  uploadReviewPictureAction,
  setShipmentAction,
  newScaleCheckoutAction,
  getCheckoutAction,
} from '../../../../store';
import { EVENT_ADD_TO_CUSTOM_LIST, EVENT_PRODUCT_VIEW, trackEvent } from '../../../../utils/tracker';
import OneOffOrderModal from '../../../Shop/components/OneOffOrderModal';
import { ApplePayButton } from '../../../ProductCheckout/components';
import { getNextBusinessDay, getNextBusinessDayOrToday } from '../../../../utils/dates';
import { BasicProductInfo, Reviews, LikesDislikesModal } from '../../components';
import { ProductDescription } from './components';
import { CheckoutSource } from '@bottomless/common/constants';

const domain = process.env.REACT_APP_COOKIE_DOMAIN;

const PanelWrapper = deps => (
  <Panel className="panel-layout-product-details">
    <PanelPage {...deps} />
  </Panel>
);

const PetProductDetailsComponent = ({
  product,
  getProduct,
  getPricingRule,
  getStarted,
  history,
  isLoading,
  match,
  me,
  makeBottomlessProduct,
  addToast,
  noEmail,
  isGuest,
  addToCustomList,
  productSelection,
  saveProductSelection,
  hideDetails,
  sendReview,
  uploadReviewPicture,
  addAlert,
  triggerOrder,
  orders,
  getOrders,
  setShipment,
  changeScale,
  newScaleCheckout,
  highlightedReviewId,
  getCheckout,
}) => {
  const location = useLocation();
  const isMobileDevice = useMediaQuery({ maxDeviceWidth: 600 });
  const [{ checkout, phone, gift }, setCookie] = useCookies(['checkout', 'phone', 'gift']);
  const [isModalOpen, setModalOpen] = useState(false);
  const [productData, setProductData] = useState(null);
  const [pricingRule, setPricingRule] = useState(null);
  const [error, setError] = useState(null);
  const [currentStock, setCurrentStock] = useState(null);
  const [showAccountsModal, toggleAccountsModal] = useToggle();
  const [matchedAccounts, setMatchedAccounts] = useState();
  const [checkoutData, setCheckoutData] = useState();

  useOnce(() => trackEvent(EVENT_PRODUCT_VIEW, product), [product]);

  useEffect(() => {
    setCookie('landing_page', '/pets', { domain, path: '/' });
    setCookie('last_landing_rule', 'pets', { domain, path: '/' });
  }, [setCookie]);

  const query = qs.parse(location?.search);
  const isFromVendorLanding = query?.vendor;
  const isLoggedIn = !!me;

  const [isOneOffModalOpen, toggleOneOffModal] = useToggle();
  const [loadingOneOff, setLoadingOneOff] = useState(false);
  const [pendingOneOffOrder, setPendingOneOffOrder] = useState(null);

  const [isLikesDislikesModalOpen, toggleLikesDislikesModal] = useToggle();
  const [isCustomListRequest, setCustomListRequest] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const outOfStock = useMemo(() => product && product.status === 'archived', [product]);
  const numberOfRichReviews = useMemo(() => product?.feedback?.filter(feedback => !!feedback.brewing_method)?.length, [
    product,
  ]);

  const Wrapper = useMemo(() => {
    if (!me) {
      return Simple;
    }

    if (!product) {
      return me ? PanelWrapper : Simple;
    }

    const myVendorId = me.vendor_id?._id || me.vendor_id;
    const productVendorId = product.vendor_id?._id || product.vendor_id;

    return myVendorId && myVendorId !== productVendorId ? props => <Simple {...props} forceNoVendor /> : PanelWrapper;
  }, [me, product]);

  const shopPath = me ? '/shop' : '/public_shop';

  const isDifferentVendor = useMemo(
    () => !!me && !me.accounts.find(account => !account.vendor_id || account.vendor_id === product?.vendor_id._id),
    [me, product]
  );

  const computedPricingRule = useMemo(() => {
    if (checkoutData) {
      return checkoutData.pricing_rule;
    }
    if (me && !isDifferentVendor) {
      return me.pricing_rule;
    }
    return pricingRule;
  }, [me, pricingRule, checkoutData, isDifferentVendor]);

  const onBack = useCallback(() => history.goBack(), [history]);

  const getDefaultVariant = useCallback(() => {
    if (me && me.personalized && me.personalized.size) {
      const { size } = me.personalized;
      const userDefaultVariant =
        product && product.variants && product.variants.filter(v => v.available && v.size === size);
      if (userDefaultVariant && userDefaultVariant.length) {
        return userDefaultVariant[0]._id;
      }
    }

    const [defaultVariant] =
      product && product.variants
        ? product.variants.filter(v => v.available).sort((a, b) => (a.size > b.size ? 1 : -1))
        : [];
    return defaultVariant && defaultVariant._id;
  }, [me, product]);

  const [selectedVariant, setSelectedVariant] = useState(
    productSelection ? productSelection.variant : getDefaultVariant()
  );

  useEffect(() => {
    if (me && isGuest) {
      history.push('/profile');
    }
  }, [me, isGuest, history]);

  useEffect(() => {
    let componentUnmounted = false;
    (async () => {
      const { error } = await getProduct(match.params.slug);
      if (!componentUnmounted) {
        setError(error);
      }
    })();

    return () => {
      componentUnmounted = true;
    };
  }, [getProduct, match]);

  useEffect(() => {
    if (productSelection && productSelection.product === (product && String(product._id))) {
      setSelectedVariant(productSelection.variant);
    } else {
      setSelectedVariant(getDefaultVariant());
    }
  }, [getDefaultVariant, product, productSelection]);

  useDataEffect(getPricingRule, setPricingRule, match.path);
  useDataEffect(getOrders);
  useConditionalDataEffect(!!checkout, getCheckout, setCheckoutData, checkout);

  const lastOrder = useMemo(() => product && orders.find(order => order.subproduct_id?.product?._id === product._id), [
    orders,
    product,
  ]);

  const addProductToCustomList = async (product, { variant, ...data }) => {
    const result = await addToCustomList({ product: product._id, variant, ...data });
    trackEvent(EVENT_ADD_TO_CUSTOM_LIST);
    if (result.payload) {
      result.payload.addToList = true;
    }

    if (result?.payload?.userId && result.payload.userId !== me._id) {
      await changeScale({ account: result.payload.userId });
    }

    return result;
  };

  const onAddToCustomList = async (product, data) => {
    const matched = me.accounts.filter(account => account.product.product.category._id === product?.category?._id);
    setMatchedAccounts(matched);

    if (matched?.length > 1) {
      setProductData({
        product: { product: product._id, variant: data.variant },
        vendorId: product?.vendor_id?._id,
        ...data,
      });
      setCustomListRequest(true);
      toggleAccountsModal();
      return;
    }

    return addProductToCustomList(product, data);
  };

  const onChooseProductGuest = async (product, { variant, ...data }) => {
    setProductData({ product: { product: product._id, variant }, vendorId: product?.vendor_id?._id, ...data });

    if (me && checkout) {
      const matched = me.accounts.filter(account => account.product.product.category._id === product?.category?._id);
      setMatchedAccounts(matched);

      if (matched?.length > 1) {
        setCustomListRequest(false);
        toggleAccountsModal();
        return;
      }
    }

    if (checkout || noEmail) {
      const { payload } = await onSubmit({
        product: { product: product._id, variant },
        ...data,
      });

      return onSuccess(payload);
    }

    toggleModal();
  };

  const makeBottomless = useCallback(
    async (id, data) => {
      if (checkout) {
        const { payload: getStartedPayload } = await getStarted({
          product: { product: product?._id, variant: data.variant },
          ...data,
        });

        if (getStartedPayload.source === CheckoutSource.Shopify) {
          return history.push(
            `/get_started/${getStartedPayload.checkoutId}?source=${getStartedPayload.source}&vendorId=${getStartedPayload.vendorId}`
          );
        }

        return history.push(`/get_started/${getStartedPayload.checkoutId}`);
      }

      const result = await makeBottomlessProduct(id, data);

      if (result?.error && result?.payload?.status === 401) {
        await newScaleCheckout();
        const { payload: getStartedPayload } = await getStarted({
          product: { product: product?._id, variant: data.variant },
          ...data,
        });

        return history.push(`/get_started/${getStartedPayload.checkoutId}`);
      }

      if (result?.payload?.userId && result.payload.userId !== me._id) {
        await changeScale({ account: result.payload.userId });
      }

      return result;
    },
    [makeBottomlessProduct, changeScale, me, history, newScaleCheckout, getStarted, product, checkout]
  );

  const onChooseProductUser = (product, { variant, ...data }) => {
    const matched = me.accounts.filter(account => account.product.product.category._id === product?.category?._id);
    setMatchedAccounts(matched);

    if (matched?.length > 1) {
      setProductData({ product: { product: product._id, variant }, vendorId: product?.vendor_id?._id, ...data });
      setCustomListRequest(false);
      toggleAccountsModal();
      return;
    }

    return makeBottomless(product._id, { variant, ...data });
  };

  const onChooseProduct = me && !checkout ? onChooseProductUser : onChooseProductGuest;

  const onChooseProductSuccess = params => {
    if (!params) {
      return;
    }
    const { addToList } = params;
    addToast('Product has been successfully saved');
    if (addToList) {
      const back = new URLSearchParams(window.location.search).get('back');
      history.push(back || '/shop');
    } else {
      history.push('/profile');
    }
  };

  const onReviewSuccess = useCallback(() => addToast('Review has been successfully saved'), [addToast]);

  const onSubmit = useCallback(
    async (data = {}) => {
      const { email } = data;

      let path;
      if (isLoggedIn) {
        path = undefined;
      } else if (isGuest) {
        path = `${match.path}/buy`;
      } else {
        ({ path } = match);
      }
      const result = await getStarted({
        phone,
        ...productData,
        ...data,
        email,
        guest: Boolean(isGuest),
        path,
      });

      const { event: { dateOverride } = {} } = currentStock || {};

      if (dateOverride === '0') {
        await setShipment(result.payload.checkoutId, { shipment_date: undefined, dateOverride });
      }

      if (dateOverride && dateOverride !== '0') {
        const nextBusinessDayOrToday = getNextBusinessDayOrToday();

        const shipmentDate =
          dateOverride === 'nextBusiness'
            ? getNextBusinessDay()
            : new Date(nextBusinessDayOrToday.setDate(nextBusinessDayOrToday.getDate() + 7 * Number(dateOverride)));

        await setShipment(result.payload.checkoutId, {
          shipment_date: moment(shipmentDate).format('YYYY-MM-DD'),
          dateOverride,
        });
      }

      return result;
    },
    [isLoggedIn, isGuest, getStarted, phone, productData, currentStock, match, setShipment]
  );

  const onSuccess = ({ checkoutId }) => {
    history.push(`/get_started/${checkoutId}`);
  };

  const onOneOffOrder = useCallback(
    async (product, { variant, ...data }) => {
      const { _id, image_src, variants, name, vendor_id } = product || {};
      setPendingOneOffOrder({
        product: { product: _id, variant, imageUrl: image_src, allVariants: variants, name, vendor: vendor_id },
        source: 'user_one_off',
        ...data,
      });
      setTimeout(toggleOneOffModal, 0);
    },
    [setPendingOneOffOrder, toggleOneOffModal]
  );

  const triggerOneOffOrder = useCallback(async () => {
    setLoadingOneOff(true);

    try {
      await triggerOrder(pendingOneOffOrder);
      addAlert('Order has been successfully generated', 'success');
    } catch (error) {
      addAlert('Could not generate order. Please try again later', 'danger');
    }
    setLoadingOneOff(false);
    toggleOneOffModal();
  }, [toggleOneOffModal, triggerOrder, pendingOneOffOrder, addAlert]);

  const renderContent = () => {
    if (error) {
      return <Error />;
    } else if (!product) {
      return <DataLoading count={product ? 1 : 0} isLoading={isLoading} />;
    } else if (product && (me || !product.hidden)) {
      let makeBottomlessText = undefined;
      if (gift) {
        makeBottomlessText = 'Redeem Gift';
      } else if (isGuest) {
        makeBottomlessText = 'Buy';
      }
      return (
        <>
          <div className="page-product-details-v2">
            <BasicProductInfo
              product={product}
              pathname={shopPath}
              me={me}
              pricingRule={computedPricingRule}
              onChooseProduct={onChooseProduct}
              onChooseSuccess={onChooseProductSuccess}
              makeBottomlessText={makeBottomlessText}
              setSelectedVariant={setSelectedVariant}
              outOfStock={outOfStock}
              addToCustomList={onAddToCustomList}
              selectedVariant={selectedVariant}
              productSelection={productSelection}
              saveProductSelection={saveProductSelection}
              hideDetails={hideDetails}
              onOneOffOrder={onOneOffOrder}
              isMobileDevice={isMobileDevice}
              numberOfRichReviews={numberOfRichReviews}
              toggleLikesDislikesModal={toggleLikesDislikesModal}
              category="pet-food"
              expressCheckoutButton={
                <ApplePayButton
                  pricingRule={computedPricingRule}
                  product={product}
                  variant={product.variants.find(v => v._id === selectedVariant)}
                  showSeperator
                />
              }
              isFirstBag={checkout || !me}
              getCheckout={getCheckout}
            />
            <div className="product-more-info">
              <hr />
              <ProductDescription me={me} product={product} />
              {numberOfRichReviews > 0 && (
                <>
                  <hr id="reviews" />
                  <Row className="mt-5 mb-5">
                    <Col xs="12" className="review-column">
                      <Reviews
                        product={product}
                        me={me}
                        onSubmit={sendReview}
                        onSuccess={onReviewSuccess}
                        onPictureUpload={uploadReviewPicture}
                        lastOrder={lastOrder}
                        reviewsPerPage={isMobileDevice ? 3 : 6}
                        paginationDisplayNumberRange={isMobileDevice ? 3 : 5}
                        numberOfRichReviews={numberOfRichReviews}
                        toggleLikesDislikesModal={toggleLikesDislikesModal}
                        highlightedReviewId={highlightedReviewId}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
          {isLikesDislikesModalOpen && (
            <LikesDislikesModal
              isOpen={isLikesDislikesModalOpen}
              toggle={toggleLikesDislikesModal}
              feedback={product.feedback}
            />
          )}
        </>
      );
    }
  };

  return (
    <IsLoggedIn>
      <Wrapper back={onBack} withBackButton>
        <Container className="pt-2 pb-4 page-product-details">{renderContent()}</Container>
        <GetStartedModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          getStarted={onSubmit}
          onSignUp={onSuccess}
          pricingRule={computedPricingRule}
          isFromVendorLanding={!!isFromVendorLanding}
          setCurrentStock={setCurrentStock}
          dateOverride={currentStock?.event?.dateOverride}
        />
        <OneOffOrderModal
          me={me}
          isOneOffModalOpen={isOneOffModalOpen}
          toggleOneOffModal={toggleOneOffModal}
          cancelOneOffOrder={toggleOneOffModal}
          loadingOneOff={loadingOneOff}
          triggerOneOffOrder={triggerOneOffOrder}
          setPendingOneOffOrder={setPendingOneOffOrder}
          pendingOneOffOrder={pendingOneOffOrder}
          pathname={location.pathname}
          pricingRule={computedPricingRule}
          isFirstBag={!me}
        />
        {showAccountsModal && (
          <AccountsModal
            me={me}
            isOpen={showAccountsModal}
            toggle={toggleAccountsModal}
            matchedAccounts={matchedAccounts}
            productData={productData}
            makeBottomlessProduct={makeBottomlessProduct}
            addProductToCustomList={addProductToCustomList}
            isCustomList={isCustomListRequest}
          />
        )}
      </Wrapper>
    </IsLoggedIn>
  );
};

PetProductDetailsComponent.propTypes = {
  getProduct: PropTypes.func.isRequired,
  getStarted: PropTypes.func.isRequired,
  getPricingRule: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  makeBottomlessProduct: PropTypes.func.isRequired,
  me: PropTypes.object,
  product: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string.isRequired,
    }).isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  noEmail: PropTypes.bool,
  isGuest: PropTypes.bool,
  addToCustomList: PropTypes.func.isRequired,
  saveProductSelection: PropTypes.func.isRequired,
  productSelection: PropTypes.object,
  hideDetails: PropTypes.bool,
  sendReview: PropTypes.func.isRequired,
  uploadReviewPicture: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  triggerOrder: PropTypes.func.isRequired,
  highlightedReviewId: PropTypes.string,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      subproduct_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ product: PropTypes.shape({ _id: PropTypes.string }) }),
      ]),
    })
  ),
  getOrders: PropTypes.func.isRequired,
  setShipment: PropTypes.func.isRequired,
  changeScale: PropTypes.func.isRequired,
  newScaleCheckout: PropTypes.func.isRequired,
  getCheckout: PropTypes.func.isRequired,
};

export const PetProductDetails = connect(
  (
    { order, product: { data, isLoading, productSelection }, user: { me } },
    {
      match: {
        params: { slug },
      },
    }
  ) => ({
    product: data.find(product => product.slug === slug || product._id === slug),
    orders: order.data ? order.data : [],
    isLoading,
    me,
    productSelection,
  }),
  dispatch => ({
    getProduct: id => dispatch(getProductAction(id)),
    getPricingRule: path => dispatch(getPricingRuleAction(path)),
    makeBottomlessProduct: (id, data) => dispatch(makeBottomlessProductAction(id, data)),
    addToast: message => dispatch(addToastAction(message)),
    addToCustomList: data => dispatch(addCustomListElementAction(data)),
    saveProductSelection: data => dispatch(saveProductSelectionAction(data)),
    sendReview: data => dispatch(sendReviewAction(data)),
    uploadReviewPicture: (productId, data) => dispatch(uploadReviewPictureAction(productId, data)),
    addAlert: (message, type) => dispatch(addAlertAction(message, type)),
    triggerOrder: data => dispatch(triggerOrderAction(data)),
    getOrders: () => dispatch(getOrdersAction()),
    setShipment: (id, data) => dispatch(setShipmentAction(id, data)),
    changeScale: data => dispatch(changeScaleAction(data)),
    getCheckout: id => dispatch(getCheckoutAction(id)),
    newScaleCheckout: data => dispatch(newScaleCheckoutAction(data)),
  })
)(
  withMetaTags({
    title: ({ product }) => (product ? `Bottomless: ${product.name} - ${product.vendor_name}` : undefined),
    description: ({ product }) =>
      product
        ? `${product.name} from ${product.vendor_name} on Bottomless. Explore 500+ amazing coffees from the best roasters in the country. All orders are roast to order.`
        : undefined,
    image: ({ product }) => (product ? (product.small_image_src || '').replace(/\?.*$/, '') : undefined),
  })(
    withRichProductMeta({
      title: ({ product }) => (product ? product.name : undefined),
      image: ({ product }) => (product ? (product.small_image_src || '').replace(/\?.*$/, '') : undefined),
      vendor: ({ product }) => (product ? product.vendor_name : undefined),
      description: ({ product }) => (product ? stripTags(product.description) : undefined),
      numPosRatings: ({ product }) =>
        product ? product.feedback.filter(fb => fb.like === true && fb.dislike === false).length : undefined,
      price: ({ product }) =>
        product
          ? Math.min(...product.variants.filter(variant => variant.available).map(availVar => availVar.price))
          : undefined,
      numNegRatings: ({ product }) =>
        product ? product.feedback.filter(fb => fb.dislike === true && fb.like === false).length : undefined,
      availability: ({ product }) => !!(product ? product.variants.find(variant => variant.available) : undefined),
    })(PetProductDetailsComponent)
  )
);
