import React from 'react';
import PropTypes from 'prop-types';
import { FeatureSection } from '../../../../components/FeatureSection/FeatureSection';

export const ContainerSection = ({ vendor }) => (
  <FeatureSection
    text={`The Bottomless scale works with any container! Or you can store the ${vendor.name} in its original packaging.`}
    title="Use your own container"
    image={vendor.custom_styles.images.container}
    reverse
  />
);

ContainerSection.propTypes = {
  vendor: PropTypes.object.isRequired,
};
