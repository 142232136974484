import React, { memo } from 'react';
import { Col, Container } from 'reactstrap';
import { Image } from '@bottomless/common/components';

const MeetTheScaleSectionComponent = () => {
  return (
    <section className="scale-section">
      <h1 className="text-center mb-5">Meet Our Scale</h1>
      <Container className="d-flex justify-content-center">
        <Col xs="12" md="6" className="d-flex justify-content-center">
          <Image
            width="400"
            src="https://bottomless.imgix.net/vendor-landing/MeetTheScale.png?auto=format,compress"
            alt="meet-the-scale"
          />
        </Col>
      </Container>
    </section>
  );
};

export const MeetTheScaleSection = memo(MeetTheScaleSectionComponent);
