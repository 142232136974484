import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ScaleStatus as ScaleStatusConst, UserStatuses } from '@bottomless/common/constants';

const STATUS_COLOR = {
  [ScaleStatusConst.Pending]: 'bg-secondary',
  [ScaleStatusConst.Connected]: 'bg-success',
  [ScaleStatusConst.Disconnected]: 'bg-danger',
};

export const ScaleStatus = ({ user }) => {
  const isReplacing = useMemo(() => user.status === UserStatuses.ScaleReplaced && user.lastReplacement, [user]);
  const isOnboarding = useMemo(
    () => [UserStatuses.OnboardingScaleShipped, UserStatuses.OnboardingPaid].includes(user.status),
    [user]
  );
  const isShipment = useMemo(() => isReplacing || isOnboarding, [isReplacing, isOnboarding]);

  const isScaleArrived = useMemo(
    () => user.lastReplacement?.status === 'arrived' || user.onboardingState?.scaleArrived,
    [user]
  );

  const { status, className } = useMemo(() => {
    if (isShipment) {
      return { status: isScaleArrived ? 'Pending Setup' : 'Scale Shipment', className: 'bg-secondary' };
    }

    if (!user.scale_status) {
      return { status: 'Scale shipment', className: 'bg-secondary' };
    }

    return {
      status: user.scale_status === ScaleStatusConst.Pending ? 'Waiting for data' : user.scale_status,
      className: STATUS_COLOR[user.scale_status] || 'bg-warning',
    };
  }, [isShipment, isScaleArrived, user]);

  return (
    <div className="account-scale-status text-capitalize small d-flex align-items-center justify-content-center">
      <span className={classNames('scale-status-dot mr-1', className)} />
      {status}
    </div>
  );
};

ScaleStatus.propTypes = {
  user: PropTypes.shape({
    scale_status: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    lastReplacement: PropTypes.object,
    onboardingState: PropTypes.shape({
      scaleArrived: PropTypes.bool,
    }),
  }).isRequired,
};
