import React from 'react';
import PropTypes from 'prop-types';
import { ProductCategoryManifest } from '../../../../components/CategoryManifest';
import { CoffeeShipTime, GenericShipTime, PetFoodShipTime } from './manifest';

const COMPONENTS = {
  CoffeeShipTime: CoffeeShipTime,
  PetFoodShipTime: PetFoodShipTime,
  GenericShipTime: GenericShipTime,
};

export const ShipTime = props => (
  <ProductCategoryManifest
    slugOrId={props.product?._id}
    manifestKey="vendorShipTime"
    components={COMPONENTS}
    fallbackComponent={GenericShipTime}
    {...props}
  />
);

ShipTime.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
};
