import React from 'react';
import PropTypes from 'prop-types';
import { Price } from '@bottomless/common/components';
import { useMemo } from 'react';

export const Shipping = ({ pricingRule, isFirstOrder, shippingPrice }) => {
  const price = useMemo(() => {
    if (isFirstOrder && !isNaN(pricingRule.first_shipping_price)) {
      if (!pricingRule.first_shipping_price) {
        return 'free';
      }

      return <Price value={pricingRule.first_shipping_price} cents />;
    }

    if (pricingRule.free_shipping) {
      return 'free';
    }

    if (pricingRule.shipping_price) {
      return <Price value={pricingRule.shipping_price} cents />;
    }

    return null;
  }, [pricingRule, isFirstOrder]);

  return (
    (shippingPrice !== null && !isNaN(shippingPrice) && <Price value={shippingPrice} cents />) ||
    price || <span className="text-sm font-weight-normal text-secondary">Choose address to calculate</span>
  );
};

Shipping.propTypes = {
  isFirstOrder: PropTypes.bool,
  pricingRule: PropTypes.shape({
    first_shipping_price: PropTypes.number,
    free_shipping: PropTypes.bool,
    shipping_price: PropTypes.bool,
  }).isRequired,
  getCheckoutShippingPrice: PropTypes.func.isRequired,
  shippingPriceLoading: PropTypes.bool,
  shippingPrice: PropTypes.number,
};

Shipping.defaultProps = {
  isFirstOrder: false,
};
