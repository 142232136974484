import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IsAuth } from '../guards/auth.guard';
import { VendorBrandLayout } from '../layouts/VendorBranded';
import { ConnectStep1Page } from '../pages/Connect/ConnectStep1';
import { ConnectStep2Page } from '../pages/Connect/ConnectStep2';
import { ConnectStep3Page } from '../pages/Connect/ConnectStep3';
import { ConnectSuccessPage } from '../pages/Connect/ConnectSuccess';
import { ConnectConnectedPage } from '../pages/Connect/ConnectConnected';
import { ConnectDisconnectedPage } from '../pages/Connect/ConnectDisconnected';
import { ConnectTarePage } from '../pages/Connect/ConnectTare';
import { ConnectCompletePage } from '../pages/Connect/ConnectComplete/ConnectCompletePage';

export const ScaleConnectRouter = props => (
  <IsAuth {...props}>
    <VendorBrandLayout>
      <Switch>
        <Route path="/activate/step1" exact component={ConnectStep1Page} />
        <Route path="/activate/step2" exact component={ConnectStep2Page} />
        <Route path="/activate/step3" exact component={ConnectStep3Page} />
        <Route path="/activate/success" exact component={ConnectSuccessPage} />
        <Route path="/activate/connected" exact component={ConnectConnectedPage} />
        <Route path="/activate/disconnected" exact component={ConnectDisconnectedPage} />
        <Route path="/activate/tare" exact component={ConnectTarePage} />
        <Route path="/activate/complete/:id" exact component={ConnectCompletePage} />
      </Switch>
    </VendorBrandLayout>
  </IsAuth>
);
