import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ErrorBoundary, Toasts } from '@bottomless/common/components';
import { getManifestAction } from '@bottomless/common/store';
import { NavContextProvider } from '../../context/nav.context';
import { useIsGuest } from '../../hooks/useIsGuest.hook';
import { Menu } from './components';
import './Panel.scss';
import { getInTransitionCountAction } from '../../store';
import { useConditionalDataEffect, useDataEffect } from '@bottomless/common/hooks';

const PanelComponent = ({
  children,
  me,
  getManifest,
  displayNav: rawDisplayNav,
  withRightSideNav,
  className,
  getInTransitionCount,
  ordersCount,
}) => {
  const { search } = useLocation();
  const displayNav = useMemo(() => rawDisplayNav && !search.match('hideNav'), [search, rawDisplayNav]);
  const isMobile = useMediaQuery({ maxDeviceWidth: 767 });

  useDataEffect(getManifest);
  useConditionalDataEffect(displayNav, getInTransitionCount);

  const isAg1User = useMemo(() => me && !!me.local?.email.match(/^ag1(\+[\d]+)?@bottomless.com$/i), [me]);

  const isAdmin = useMemo(() => me && !!me.local?.email.match(/@bottomless.com$/) && !isAg1User, [me, isAg1User]);
  const isGuest = useIsGuest(me);

  return (
    <>
      <div className={classNames({ 'panel-layout': !isMobile, 'panel-layout-mobile': isMobile }, className)}>
        {displayNav && <Menu ordersCount={ordersCount} isGuest={isGuest} isAdmin={isAdmin} />}
        <ErrorBoundary>
          <NavContextProvider value={{ hidden: !displayNav }}>{children}</NavContextProvider>
        </ErrorBoundary>
        {withRightSideNav && !isMobile && <div id="right-side-nav" />}
      </div>
      <Toasts />
    </>
  );
};

PanelComponent.defaultProps = {
  displayNav: true,
  withRightSideNav: false,
};

PanelComponent.propTypes = {
  me: PropTypes.shape({
    status: PropTypes.string.isRequired,
    local: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }),
  }),
  children: PropTypes.node.isRequired,
  getManifest: PropTypes.func.isRequired,
  displayNav: PropTypes.bool,
  className: PropTypes.string,
  withRightSideNav: PropTypes.bool,
  getInTransitionCount: PropTypes.func.isRequired,
  ordersCount: PropTypes.object,
};

export const Panel = connect(
  ({ user: { me }, order }) => ({ me, ordersCount: order.count || {} }),
  dispatch => ({
    getManifest: () => dispatch(getManifestAction()),
    getInTransitionCount: () => dispatch(getInTransitionCountAction()),
  })
)(PanelComponent);
