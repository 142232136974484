import { applyMiddleware, combineReducers, compose, createStore as createReduxStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { bodyMiddleware, endpointMiddleware, errorMiddleware, toastMiddleware, alertMiddleware } from './middlewares';
import { alertReducer } from './alert/alert.reducer';
import { menifestReducer } from './manifest/manifest.reducer';
import { toastReducer } from './toast/toast.reducer';
import { userReducer } from './user/user.reducer';
import { serialize } from './serialize';
import { staticPersistReducer } from './static-persist';

// Bump this variable if you want to clear storage on deploy
const VERSION = 50;

export const createStore = (reducersObject = {}, blacklist = [], config = {}) => {
  const migrate = createMigrate(
    new Array(VERSION + 1).fill(0).reduce((mig, _, i) => ({ ...mig, [i]: () => ({}) }), {})
  );

  const reducers = staticPersistReducer(
    { staticData: config.staticData, version: VERSION },
    persistReducer(
      {
        key: 'bottomless-storage',
        storage,
        blacklist: ['toast', 'alert', 'auth', ...blacklist],
        version: VERSION,
        migrate,
        serialize,
      },
      combineReducers({
        toast: toastReducer,
        alert: alertReducer,
        user: userReducer,
        manifest: menifestReducer,
        ...reducersObject,
      })
    )
  );

  const composeEnhancers = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;

  const middlewares = composeEnhancers(
    applyMiddleware(
      toastMiddleware,
      alertMiddleware,
      endpointMiddleware,
      bodyMiddleware,
      apiMiddleware,
      errorMiddleware
    )
  );

  const store = createReduxStore(reducers, middlewares);
  const persistor = persistStore(store);

  return { store, persistor };
};
