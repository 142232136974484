import React from 'react';
import PropTypes from 'prop-types';
import Flag from 'react-country-flag';
import { Col, Row, Modal, ModalHeader, ModalBody, Container } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import { Parameters } from './components/Parameters';
import './ProductDetailsModal.scss';

const GLOBAL = 'global';

const ProductComponent = ({ product, toggleModal, showModal, children, className }) => {
  const emoji =
    (product.origin?.code || GLOBAL) === GLOBAL ? null : (
      <Flag className="ml-2 origin-flag" countryCode={product.origin.code} />
    );

  return (
    <div className="product-details-modal">
      <Modal isOpen={showModal} toggle={toggleModal} size="lg" className={className}>
        <ModalHeader toggle={toggleModal}></ModalHeader>
        <ModalBody>
          <Container>
            <Row className="product-card-row">
              <Col
                xs="12"
                md={children ? 2 : 3}
                className="text-center text-md-left d-flex align-items-center justify-content-center p-1"
              >
                <LazyLoad once height={300} offset={250}>
                  <img className="product-card-image img-fluid" src={product.small_image_src} alt={product.name} />
                </LazyLoad>
              </Col>
              <Col xs="12" md={children ? 6 : 9} className="d-flex flex-column justify-content-center">
                <b>
                  <div className="product-card-product">{product.vendor_name}</div>
                </b>
                <div className="product-card-product mb-3">
                  {product.name} {emoji}
                </div>

                <Parameters product={product} />
              </Col>

              {children && (
                <Col xs="12" md="4">
                  {children}
                </Col>
              )}

              <div className="m-3">
                <div dangerouslySetInnerHTML={{ __html: product.description }} />
              </div>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
};

ProductComponent.propTypes = {
  product: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

ProductComponent.defaultProps = {
  withDescription: false,
  includesForm: true,
};

export const ProductDetailsModal = ProductComponent;
