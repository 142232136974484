import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Flag from 'react-country-flag';
import { Card, Col } from 'reactstrap';
import classNames from 'classnames';
import { Image } from '@bottomless/common/components';
import { fromOz } from '../../utils';
import { Price } from '../Price';
import { ProductName } from './ProductName';
import './RotationProduct.scss';
import { VariantPrice } from '../VariantPrice';

const emoji = product =>
  !product.origin || product.origin.code === 'global' ? null : (
    <Flag className="ml-2 flag" countryCode={product.origin.code} />
  );

export const RotationProduct = ({
  product,
  variant: variantId,
  rotationPrice,
  onClick,
  pricingRule,
  isFirstBag,
  firstProduct,
  personalized,
  grind,
  dynamicPricing,
  hideRotationImage,
  customList,
  showPricing = true,
  withBorder = true,
  me,
  className,
}) => {
  const productImage =
    product?.status === 'draft'
      ? product?.vendor_id?.product_image_url ?? product?.small_image_src
      : product?.small_image_src;

  const variant = useMemo(() => product.variants.find(productVariant => productVariant._id === variantId) || {}, [
    product.variants,
    variantId,
  ]);
  const price = dynamicPricing
    ? dynamicPricing.price
    : me?.vendor_id
    ? variant.website_pricing || variant.price
    : variant.price;
  const shippingPrice = useMemo(() => {
    if (isFirstBag) {
      return !isNaN(pricingRule?.first_shipping_price)
        ? pricingRule?.first_shipping_price
        : pricingRule?.shipping_price;
    }

    return pricingRule?.shipping_price;
  }, [isFirstBag, pricingRule]);

  const isFreeShipping = useMemo(() => {
    if (isFirstBag) {
      return (
        pricingRule?.first_shipping_price === 0 || (!pricingRule?.first_shipping_price && pricingRule?.free_shipping)
      );
    }

    return pricingRule?.free_shipping;
  }, [isFirstBag, pricingRule]);

  return (
    <Card
      key={product._id}
      body={withBorder}
      className={classNames(
        `d-flex flex-row justify-content-between rotation-product ${onClick ? 'cursor-pointer' : ''}${
          !withBorder ? ' border-0' : 'px-1'
        }`,
        className
      )}
      onClick={onClick}
    >
      <Col lg={showPricing ? '8' : '12'} className="d-flex flex-md-row flex-column p-1">
        <div className="d-flex flex-column mr-lg-3 mr-0">
          {firstProduct && (
            <div className="small text-secondary text-uppercase font-weight-bold mb-2">Your custom rotation</div>
          )}
          {customList && <div className="small text-secondary text-uppercase font-weight-bold mb-2">First product</div>}
          <div className="d-flex align-items-center product-info">
            {!hideRotationImage && (
              <Image wrapperClassName="placeholder-image" alt={product.name} src={productImage} width="60" />
            )}
            <div className="rotation-details ml-3">
              {!firstProduct && <div className="text-secondary vendor placeholder-text">{product.vendor_name}</div>}
              <ProductName product={product} personalized={personalized} grind={grind} />
              <div className="text-secondary small placeholder-text">
                Size: {fromOz({ oz: variant.size, unit: variant.unit }).formatted}
              </div>
            </div>
          </div>
        </div>
        {firstProduct && (
          <div className="d-flex flex-column ml-lg-4 ml-0 mt-3 mt-lg-0">
            <div className="small text-secondary text-uppercase font-weight-bold mb-2">First product</div>
            <div className="d-flex align-items-center product-info">
              <Image
                wrapperClassName="placeholder-image"
                alt={firstProduct.name}
                src={firstProduct.small_image_src}
                width="70"
              />
              <div>
                <div className="vendor text-secondary text-uppercase placeholder-text">{firstProduct.vendor_name}</div>
                <div className="font-weight-bold pr-2 placeholder-text">
                  {firstProduct.name} {emoji(firstProduct)}
                </div>
              </div>
            </div>
          </div>
        )}
      </Col>
      {showPricing && (
        <Col lg="4" className="d-flex flex-column align-items-end text-right justify-content-center pr-1">
          <div className="placeholder-text">{fromOz({ oz: variant.size, unit: variant.unit }).formatted}</div>
          <div>
            {rotationPrice && price && rotationPrice !== price && (
              <span className="text-strikethrough text-secondary mr-1 placeholder-text">
                <VariantPrice
                  user={me}
                  variant={variant}
                  isFirstBag={isFirstBag}
                  pricingRule={pricingRule}
                  hideDiscount
                />
              </span>
            )}
            {rotationPrice && (
              <span className="text-primary placeholder-text">
                <VariantPrice
                  user={me}
                  variant={variant}
                  isFirstBag={isFirstBag}
                  pricingRule={pricingRule}
                  hideDiscount={rotationPrice && price && rotationPrice !== price}
                />
              </span>
            )}
            {!rotationPrice && (
              <span className="text-primary placeholder-text">
                <Price value={price} isFirstBag={isFirstBag} pricingRule={pricingRule} size={variant.size} />
              </span>
            )}
            {pricingRule && (
              <div>
                {isFreeShipping ? (
                  'Free Shipping'
                ) : (
                  <span className="placeholder-text">
                    Shipping: <Price value={shippingPrice} cents />
                  </span>
                )}
              </div>
            )}
          </div>
        </Col>
      )}
    </Card>
  );
};

RotationProduct.propTypes = {
  rotationPrice: PropTypes.number,
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    small_image_src: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
      })
    ).isRequired,
    status: PropTypes.string,
    vendor_id: PropTypes.shape({
      product_image_url: PropTypes.string,
    }),
  }),
  pricingRule: PropTypes.object,
  isFirstBag: PropTypes.bool,
  firstProduct: PropTypes.object,
  personalized: PropTypes.object,
  grind: PropTypes.string,
  dynamicPricing: PropTypes.shape({
    price: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }),
  hideRotationImage: PropTypes.bool,
  customList: PropTypes.shape({
    products: PropTypes.array,
  }),
  showPricing: PropTypes.bool,
  withBorder: PropTypes.bool,
  me: PropTypes.any,
  className: PropTypes.string,
};
