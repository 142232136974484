import { LogoutTypes, ChangeScaleTypes } from '@bottomless/common/store';
import {
  GetMeTypes,
  GetReferralsTypes,
  InviteTypes,
  VerifyAddressTypes,
  TextAlertsTypes,
  PauseAccountTypes,
  OrderingAggresionTypes,
  VerifyStripeTypes,
  GetMyProductTypes,
  OverrideDateTypes,
  MakeBottomlessProductTypes,
  GetCleanDataTypes,
  GetCreditsTypes,
  VerifyPhoneTypes,
  SwitchVariantTypes,
  SetAccountNameTypes,
  UpgradeMembershipTypes,
  SubscribeTypes,
  GetMembershipsTypes,
  ShareDataTypes,
  GetAlertSettingsTypes,
  GetActiveDevicesTypes,
  SetAlertSettingsTypes,
  UPDATE_LAST_CREDIT_CARD_ALERT,
  ReopenAccountTypes,
  GetUserVendorTypes,
  CLEAR_USER_VENDOR,
  GetAccountsTypes,
} from './user.actions';

const initialState = {
  me: null,
  memberships: null,
  referrals: [],
  cleanData: '',
  credits: {},
  product: null,
  isLoading: false,
  alertSettings: null,
  devices: [],
  lastCreditCardAlert: null,
  vendor: null,
  accounts: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetMeTypes.REQUEST:
    case GetReferralsTypes.REQUEST:
    case GetMembershipsTypes.REQUEST:
    case GetMyProductTypes.REQUEST:
    case GetAccountsTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetMeTypes.SUCCESS:
    case UpgradeMembershipTypes.SUCCESS:
    case SubscribeTypes.SUCCESS:
      if (!action.payload.product?.product?.category) {
        delete action.payload.product;
      }

      return {
        ...state,
        me: {
          ...action.payload,
        },
        isLoading: false,
      };

    case VerifyAddressTypes.SUCCESS:
    case TextAlertsTypes.SUCCESS:
    case VerifyPhoneTypes.SUCCESS:
    case PauseAccountTypes.SUCCESS:
    case OrderingAggresionTypes.SUCCESS:
    case VerifyStripeTypes.SUCCESS:
    case SetAccountNameTypes.SUCCESS:
    case ShareDataTypes.SUCCESS:
      if (!action.payload.product?.product?.category) {
        delete action.payload.product;
      }
      return {
        ...state,
        me: {
          ...state.me,
          ...action.payload,
        },
        isLoading: false,
      };
    case OverrideDateTypes.SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          pendingOrder: action.payload,
        },
      };

    case GetReferralsTypes.SUCCESS:
      return {
        ...state,
        referrals: action.payload.data,
        isLoading: false,
      };

    case GetMembershipsTypes.SUCCESS:
      return {
        ...state,
        memberships: action.payload.data,
        isLoading: false,
      };

    case InviteTypes.SUCCESS:
      return {
        ...state,
        referrals: [...state.referrals, action.payload],
        isLoading: false,
      };

    case GetMyProductTypes.SUCCESS:
    case MakeBottomlessProductTypes.SUCCESS:
    case SwitchVariantTypes.SUCCESS:
      return {
        ...state,
        product: action.payload,
        isLoading: false,
      };

    case GetCleanDataTypes.SUCCESS:
      return {
        ...state,
        cleanData: action.payload,
      };

    case GetCreditsTypes.SUCCESS:
      return {
        ...state,
        credits: action.payload,
      };

    case GetAlertSettingsTypes.SUCCESS:
    case SetAlertSettingsTypes.SUCCESS:
      return {
        ...state,
        alertSettings: action.payload,
      };

    case GetActiveDevicesTypes.SUCCESS:
      return {
        ...state,
        devices: action.payload,
      };

    case ReopenAccountTypes.SUCCESS:
      if (!action.payload.product?.product?.category) {
        delete action.payload.product;
      }

      return {
        ...state,
        me: { ...state.me, ...action.payload },
      };

    case GetUserVendorTypes.SUCCESS:
      return {
        ...state,
        vendor: action.payload,
      };

    case GetAccountsTypes.SUCCESS:
      return {
        ...state,
        accounts: action.payload,
      };

    case UPDATE_LAST_CREDIT_CARD_ALERT:
      return {
        ...state,
        lastCreditCardAlert: action.payload,
      };

    case GetMeTypes.FAILURE:
      return {
        ...state,
        me: null,
        isLoading: false,
      };

    case GetMembershipsTypes.FAILURE:
    case GetReferralsTypes.FAILURE:
    case GetMyProductTypes.FAILURE:
    case GetAccountsTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case CLEAR_USER_VENDOR:
      return {
        ...state,
        vendor: null,
      };

    case LogoutTypes.SUCCESS:
      return initialState;

    case ChangeScaleTypes.SUCCESS:
      return {
        ...initialState,
        me: state.me,
        accounts: state.accounts,
      };

    default:
      return state;
  }
};
