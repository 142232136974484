import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Roaster } from './Roaster';
import { AdvanceBuilderHeading } from '../AdvanceBuilderHeading';

export const Roasters = ({ onChange, vendors, products, personalized }) => (
  <Row className="mb-4">
    <Col xs="12">
      <AdvanceBuilderHeading title="Roasters" subtitle="Optional" />
      <Row className="pt-2">
        {vendors &&
          vendors.map(vendor => (
            <Roaster
              key={vendor._id}
              onChange={onChange}
              vendor={vendor}
              personalized={personalized}
              products={products}
            />
          ))}
      </Row>
    </Col>
  </Row>
);

Roasters.propTypes = {
  onChange: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      amount: PropTypes.number,
    })
  ),
  products: PropTypes.array.isRequired,
  personalized: PropTypes.object.isRequired,
};
