import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, isNil } from 'lodash-es';
import './Placeholder.scss';

const Placeholder = ({
  placeholderCondition,
  placeholderProps,
  placeholderComponent: PlaceholderComponent,
  ...props
}) => {
  const isLoaded = useMemo(() => {
    if (typeof placeholderCondition === 'string') {
      return !isNil(get(props, placeholderCondition));
    }

    if (typeof placeholderCondition === 'function') {
      return placeholderCondition.call();
    }

    throw new Error('Condition has to be type string or function');
  }, [props, placeholderCondition]);

  if (isLoaded) {
    return <PlaceholderComponent {...props} />;
  }

  return (
    <PlaceholderComponent
      {...props}
      {...placeholderProps}
      className={classNames(placeholderProps.className, 'placeholder-loading')}
    />
  );
};

Placeholder.propTypes = {
  placeholderCondition: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  placeholderProps: PropTypes.object,
  placeholderComponent: PropTypes.elementType,
};

export const withPlaceholder = ({ condition: placeholderCondition, props: placeholderProps }) => Component =>
  function PlaceholderComponent(props) {
    return (
      <Placeholder
        {...props}
        placeholderCondition={placeholderCondition}
        placeholderProps={placeholderProps}
        placeholderComponent={Component}
      />
    );
  };
