import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import { StripeLoaded, StripeLoader } from '../../../components/Stripe/StripeLoader';
import { PaymentProvider } from '../../../components/ApplePay/ApplePay';
import { ApplePayButtonInner } from './ApplePayButtonInner';

export const ApplePayButton = ({
  pricingRule,
  product,
  variant,
  grind,
  showSeperator = false,
  className,
  showTitle = true,
  pricing,
  firstProduct,
  firstVariant,
  personalized,
  dateOverride,
}) => (
  <StripeLoader>
    <StripeLoaded>
      <Elements>
        <PaymentProvider
          args={[
            pricingRule,
            product,
            firstProduct,
            variant,
            firstVariant,
            pricing,
            personalized,
            grind,
            undefined,
            dateOverride,
          ]}
        >
          <ApplePayButtonInner showSeperator={showSeperator} className={className} showTitle={showTitle} />
        </PaymentProvider>
      </Elements>
    </StripeLoaded>
  </StripeLoader>
);

ApplePayButton.propTypes = {
  pricingRule: PropTypes.object,
  product: PropTypes.object,
  variant: PropTypes.object,
  grind: PropTypes.string,
  showSeperator: PropTypes.bool,
  className: PropTypes.string,
  showTitle: PropTypes.bool,
  pricing: PropTypes.object,
  firstProduct: PropTypes.object,
  firstVariant: PropTypes.object,
  personalized: PropTypes.object,
  dateOverride: PropTypes.string,
};
