import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ManifestContextProvider } from '../../context/manifest.context';

const PlainObjectCategoryManifestComponent = ({
  manifestKey = '',
  components = {},
  category,
  fallbackComponent,
  ...props
}) => {
  const manifest = useMemo(() => category?.manifest, [category]);
  const Component = useMemo(
    () => components[(manifest || { components: {} }).components[manifestKey]] || fallbackComponent,
    [manifest, manifestKey, components, fallbackComponent]
  );

  if (!Component) {
    return null;
  }

  return (
    <ManifestContextProvider value={{ manifest }}>
      <Component {...props} />
    </ManifestContextProvider>
  );
};

PlainObjectCategoryManifestComponent.propTypes = {
  manifestKey: PropTypes.string.isRequired,
  components: PropTypes.object.isRequired,
  category: PropTypes.shape({
    manifest: PropTypes.object.isRequired,
  }).isRequired,
  fallbackComponent: PropTypes.element.isRequired,
};

export const PlainObjectCategoryManifest = React.memo(PlainObjectCategoryManifestComponent);
