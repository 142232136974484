import { useCallback, useMemo } from "react";
import qs from "query-string";
import { useHistory, useLocation } from "react-router-dom";

export const useQueryString = () => {
  const location = useLocation();
  const history = useHistory();

  const params = useMemo(() => qs.parse(location.search), [location.search]);

  const update = useCallback(
    newParams => {
      history.push({
        pathname: location.pathname,
        search: qs.stringify({ ...params, ...newParams })
      });
    },
    [history.push, location.pathname, params]
  );

  return { params, update };
};
