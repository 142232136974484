import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ErrorComponent = ({ errorText }) => {
  return (
    <div className="d-flex flex-column align-items-center text-center" style={{ width: '75%', margin: '80px auto' }}>
      <Link to="/" className="d-flex align-items-center navbar-brand mt-3">
        <img src="/images/superhero-b-256x256.png" alt="B" height="100" width="100" style={{ left: '30px' }} />
      </Link>
      <h2 className="m-5" style={{ letterSpacing: '1px' }}>
        {errorText ? errorText : <p>This URL does not exist, please check your link again.</p>}
      </h2>
    </div>
  );
};

ErrorComponent.propTypes = {
  errorText: PropTypes.string,
};

export const Error = memo(ErrorComponent);
