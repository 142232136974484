import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Container, Button } from 'reactstrap';
import { Slash, Loader } from 'react-feather';
import { Price, Image } from '@bottomless/common/components';
import { useMediaQuery } from 'react-responsive';
import has from 'lodash-es/has';
import get from 'lodash-es/get';
import isString from 'lodash-es/isString';
import Flag from 'react-country-flag';
import { ProductFormActions } from '../ProductForm';
import './Product.scss';

const FlagIcon = product =>
  !product.origin || product.origin.code === 'global' ? null : (
    <Flag className="ml-2 flag" countryCode={product.origin.code} />
  );

export const Product = ({
  values,
  pricingTier,
  pricingRule,
  loading,
  firstProduct,
  firstProductText,
  origins,
  vendors,
  tastingCategories,
  numProduct,
  isFirstBag,
  error,
  errors,
  onClick,
  isSubmitting,
  isUpdatingFirstProduct,
  getPricingTier,
  isRoast,
  clearFilters,
  resetForm,
  submitForm,
  onSelectClick,
  vendorRotation,
  title,
  back,
  me,
}) => {
  const isMobile = useMediaQuery({ maxDeviceWidth: 991 });

  const name = Object.entries(values.roast)
    .filter(val => val[1])
    .map(([val]) => val.replace(/^\w/, c => c.toUpperCase()))
    .join(', ');

  const origin = Object.entries(values.origin)
    .filter(val => val[1])
    .map(([val]) => val.replace('single', 'Single Origin').replace(/^\w/, c => c.toUpperCase()))
    .join(', ');

  const tag = Object.entries(values.tag)
    .filter(val => val[1])
    .map(([val]) => val.replace('cold_brew', 'Cold brew').replace(/^\w/, c => c.toUpperCase()))
    .join(', ');

  const singleOrigins = Object.entries(values.single_origins || {})
    .filter(([, value]) => value)
    .map(([key]) => ((origins && origins.find(origin => origin._id === key)) || {}).name)
    .join(', ');

  const dispVendors = Object.entries(values.vendor || {})
    .filter(([, value]) => value)
    .map(([key]) => ((vendors && vendors.find(vendors => vendors._id === key)) || {}).name)
    .join(', ');

  const categories = Object.entries(values.tasting_note_category || {})
    .filter(([, value]) => value)
    .map(([key]) => ((tastingCategories && tastingCategories.find(cat => cat._id === key)) || {}).name)
    .join(', ');

  const rotationValues = [tag, origin, singleOrigins, categories].filter(Boolean).join(', ');

  return (
    <Container className="mb-md-0 mb-3 mt-2 mt-md-0 builder-product-container" id="builder-product-container">
      <Row className="flex-md-column">
        {title && <h3 className="text-center mb-4">{title}</h3>}
        <Col className="builder-custom-rotation">
          <Card body className="mb-2 justify-content-center">
            <Container className="p-0">
              <div className="d-flex flex-column align-items-center">
                {has(errors, 'personalized.roast') ? (
                  get(errors, 'personalized.roast') &&
                  isString(get(errors, 'personalized.roast')) && (
                    <div>
                      <h3 className="text-danger"> Please select a roast! </h3>
                    </div>
                  )
                ) : (
                  <>
                    <div className="font-weight-bold">{name}</div>
                    <div>
                      {values.size}oz{' '}
                      {pricingTier && (
                        <>
                          <span className="mx-1">-</span>
                          <span>
                            <Price value={pricingTier.price} pricingRule={pricingRule} isFirstBag={isFirstBag} />
                          </span>
                        </>
                      )}
                    </div>
                    <div className="text-secondary text-center mb-1">
                      {values.grind} <br />
                      {rotationValues} <br />
                      {dispVendors && <>{dispVendors}</>}
                    </div>
                    <Button color="link" className="small py-0" onClick={() => clearFilters(resetForm, submitForm)}>
                      Clear
                    </Button>
                  </>
                )}
              </div>
              {isMobile && (
                <>
                  {error ? (
                    <div className="text-danger mt-3 text-center">
                      <h4 className="text-danger">Something went wrong</h4>
                      <div>
                        <Slash size="24" />
                      </div>
                    </div>
                  ) : (
                    <>
                      {(!pricingTier && pricingTier !== null) ||
                        (pricingTier && pricingTier.productAmount === 0 && (
                          <div className="d-flex align-items-center justify-content-center text-danger mt-3 text-center">
                            <h6 className="text-danger mb-0 mr-2">No matching products</h6>
                            <Slash size="18" />
                          </div>
                        ))}
                      {numProduct && numProduct <= 2 && (
                        <div className="text-danger mt-3 text-center">
                          Warning: This rotation only has {numProduct} products!{' '}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </Container>
          </Card>
        </Col>
        {!isMobile && (
          <>
            <Col className="mt-2">
              <Card body className="mb-3 justify-content-center example-product">
                <Container className="p-0">
                  {error ? (
                    <div className="text-danger">
                      <h4>Something went wrong</h4>
                      <div>
                        <Slash size="24" />
                      </div>
                    </div>
                  ) : !name ? (
                    <div>
                      <h4 className="text-danger text-center"> Please select a roast! </h4>
                    </div>
                  ) : (
                    <>
                      {loading && !firstProduct && (
                        <div className="text-center">
                          <Loader size="20" className="spin" />
                        </div>
                      )}
                      {(!pricingTier && pricingTier !== null) ||
                        (pricingTier && pricingTier.productAmount === 0 && (
                          <div className="text-center">
                            <div className="text-danger">
                              <h4>No matching products</h4>
                              <Slash size="24" />
                            </div>
                          </div>
                        ))}
                      {firstProduct && firstProduct.name !== 'Custom rotation' && (
                        <div className="d-flex flex-column justify-content-center align-items-center" onClick={onClick}>
                          <div>
                            <h5 className="text-center">{firstProductText}</h5>
                            <div className="text-secondary text-center text-uppercase mt-3 vendor">
                              {firstProduct.vendor_name}
                            </div>
                            <div className="text-center font-weight-bold">
                              {firstProduct.name} {FlagIcon(firstProduct)}
                            </div>
                          </div>
                          <Image src={firstProduct.small_image_src} width="105" alt={firstProduct.name} />
                        </div>
                      )}
                      {numProduct && numProduct <= 2 && (
                        <div className="text-danger text-center">
                          Warning: This rotation only has {numProduct} products!{' '}
                        </div>
                      )}
                    </>
                  )}
                </Container>
              </Card>
              <ProductFormActions
                isSubmitting={isSubmitting}
                isUpdatingFirstProduct={isUpdatingFirstProduct}
                getPricingTier={getPricingTier}
                values={values}
                isRoast={isRoast}
                vendorRotation={vendorRotation}
                onSelectClick={onSelectClick}
                submitForm={submitForm}
                isMobile={isMobile}
                back={back}
                me={me}
              />
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

Product.propTypes = {
  values: PropTypes.shape({
    roast: PropTypes.object.isRequired,
    size: PropTypes.string.isRequired,
    price_type: PropTypes.string.isRequired,
    grind: PropTypes.string.isRequired,
    tag: PropTypes.object.isRequired,
    origin: PropTypes.object.isRequired,
    single_origins: PropTypes.object.isRequired,
    tasting_note_category: PropTypes.object.isRequired,
    vendor: PropTypes.object.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  pricingTier: PropTypes.shape({
    price: PropTypes.number.isRequired,
    productAmount: PropTypes.number.isRequired,
  }),
  pricingRule: PropTypes.object,
  firstProduct: PropTypes.shape({
    small_image_src: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
  }),
  firstProductText: PropTypes.string.isRequired,
  origins: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  isFirstBag: PropTypes.bool,
  error: PropTypes.bool,
  errors: PropTypes.array,
  onClick: PropTypes.func,
  vendors: PropTypes.array,
  tastingCategories: PropTypes.array,
  numProduct: PropTypes.number,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdatingFirstProduct: PropTypes.bool.isRequired,
  getPricingTier: PropTypes.func.isRequired,
  isRoast: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  vendorRotation: PropTypes.bool,
  title: PropTypes.string,
  back: PropTypes.string,
  me: PropTypes.object,
};
