import React from 'react';
import PropTypes from 'prop-types';

export const TriggerNow = ({ isTriggerAllowed, onTrigger }) => {
  if (!isTriggerAllowed) {
    return null;
  }

  return (
    <>
      <div className="text-center text-sm text-secondary mx-4 trigger-or">or</div>
      <button
        className="btn btn-sm btn-outline-dark flex-column align-items-center justify-content-center d-flex"
        onClick={onTrigger}
      >
        <div>Trigger Now</div>
      </button>
    </>
  );
};

TriggerNow.propTypes = {
  isTriggerAllowed: PropTypes.bool,
  onTrigger: PropTypes.func.isRequired,
};
