import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash-es/isNil';

export const Price = ({
  value: rawValue,
  cents,
  pricingRule,
  isFirstBag,
  size,
  hideDiscount,
  discountClass,
  priceClass,
}) => {
  const value = !cents ? Math.round(Number(rawValue) * 100) : Number(rawValue);

  if (!pricingRule) {
    return formatCents(value);
  }

  const discounted = getDiscountedPrice(pricingRule, value, isFirstBag, size);

  if (discounted >= value) {
    return formatCents(discounted);
  }

  return (
    <>
      {!hideDiscount && <span className={`text-secondary ${discountClass}`}>{formatCents(value)}</span>}
      <span className={priceClass}>{formatCents(discounted)}</span>
    </>
  );
};

export const getDiscountedPrice = (pricingRule, value, isFirstBag = false, size = undefined) => {
  if (!pricingRule) {
    return value;
  }

  if (isFirstBag && !isNil(pricingRule.first_bag_price) && (!size || size < 17)) {
    return pricingRule.first_bag_price;
  }

  if (isFirstBag && pricingRule.first_bag_discount) {
    return Math.round(value * (pricingRule.first_bag_discount || 1));
  }

  if (pricingRule.discount_fixed) {
    return Math.max(0, value - pricingRule.discount_fixed);
  }

  if (!isNil(pricingRule.discount_proportion)) {
    return Math.round(value * pricingRule.discount_proportion);
  }

  return value;
};

const formatCents = cents => `$${(cents / 100).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

Price.propTypes = {
  value: PropTypes.number.isRequired,
  cents: PropTypes.bool,
  isFirstBag: PropTypes.bool,
  pricingRule: PropTypes.oneOfType([
    PropTypes.shape({
      discount_proportion: PropTypes.number,
      first_bag_price: PropTypes.number,
      discount_fixed: PropTypes.number,
    }),
    PropTypes.string,
  ]),
  size: PropTypes.number,
  hideDiscount: PropTypes.bool,
  discountClass: PropTypes.string,
  priceClass: PropTypes.string,
};

Price.defaultProps = {
  cents: false,
  isFirstBag: false,
  value: 0,
  discountClass: 'text-strikethrough mr-1',
  priceClass: '',
  hideDiscount: false,
};
