import React from 'react';
import { Box } from '@bottomless/common/components';
import { Check } from 'react-feather';
import './EmailSent.scss';

export const EmailSent = () => (
  <Box secondary className="mb-4">
    <div className="d-flex justify-content-center mb-4">
      <span className="text-white success-checkmark d-flex align-items-center justify-content-center rounded-circle">
        <Check className="mt-1" />
      </span>
    </div>
    <h2 className="text-center mb-4">We&apos;ve sent you an email</h2>
    <div className="text-center">
      Click on the magic link provided in the email to proceed with your scale activation.
    </div>
  </Box>
);
