import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ShoppingBag, Wifi, Repeat } from 'react-feather';
import classnames from 'classnames';
import { Col, Card } from 'reactstrap';

const renderIcon = icon => {
  switch (icon) {
    case 'shopping-bag':
      return <ShoppingBag color="white" />;
    case 'wifi':
      return <Wifi color="white" />;
    case 'repeat':
      return <Repeat color="white" />;
    default:
      break;
  }
};

const StepComponent = ({ number, title, description, icon, className }) => {
  return (
    <Col xs="12" md="4" className={classnames('d-flex flex-column align-items-center mb-4 mb-lg-0', className)}>
      <Card body className="align-items-center">
        <div className="steps-icon-container mb-3">{renderIcon(icon)}</div>
        <div className="text-primary">{number}</div>
        <h5 className="text-center mb-4">{title}</h5>
        <div className="text-secondary text-center">{description}</div>
      </Card>
    </Col>
  );
};

StepComponent.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export const Step = memo(StepComponent);
