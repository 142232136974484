import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@bottomless/common/components';
import { ProductRoasts, ProductTags } from '@bottomless/common/src/constants/product.constants';
import { useVendorLock } from '../../hooks';

export const UserAvatar = ({ user, ...props }) => {
  const vendor = useVendorLock();
  const isEspresso = useCallback(account => account.personalized?.tag?.includes(ProductTags.Espresso), []);

  const isDark = useCallback(
    account => account.personalized?.roast?.length === 1 && account.personalized?.roast[0] === ProductRoasts.Dark,
    []
  );

  const isLight = useCallback(
    account => account.personalized?.roast?.length === 1 && account.personalized?.roast[0] === ProductRoasts.Light,
    []
  );

  const src = useMemo(() => {
    if (!user.product.product.personalized_rotation) {
      return user.product.product.small_image_src;
    }

    if (vendor && vendor.rotation_img) {
      return vendor.rotation_img;
    }

    if (user.vendor_id?.rotation_img) {
      return user.vendor_id.rotation_img;
    }

    if (isEspresso(user)) {
      return 'landing/espresso-3bags-p-500.png';
    }

    if (isDark(user)) {
      return 'landing/dark-3bags-p-500.png';
    }

    if (isLight(user)) {
      return 'landing/light-3bags-p-500.png';
    }

    return 'landing/medium-3bags-p-500.png';
  }, [user, vendor, isEspresso, isDark, isLight]);

  return <Image src={src} alt={user.name} {...props} />;
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    personalized: PropTypes.object,
    product: PropTypes.shape({
      product: PropTypes.shape({
        personalized_rotation: PropTypes.bool,
        small_image_src: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    vendor_id: PropTypes.shape({
      rotation_img: PropTypes.string,
    }),
  }).isRequired,
};
