import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';
import classNames from 'classnames';
import { Container, Row, Col, Button } from 'reactstrap';
import { useDataEffect } from '@bottomless/common/hooks';
import { DataLoading, Box } from '@bottomless/common/components';
import { CheckoutSource } from '@bottomless/common/constants';
import { useCookies } from 'react-cookie';
import { withMetaTags } from '../../components/MetaTags/MetaTags';
import { Simple } from '../../layouts/Simple/Simple';
import { CoffeeInvite as Invite } from '../../pages/OnboardingReferral/manifest/CoffeeInvite';
import { getCheckoutDetailsAction } from '../../store';
import { clearCustomRotationAction } from '../../store/quiz/quiz.actions';
import { CompleteMessage } from './components/CompleteMessage';
import { HeardAboutUs } from './components/HeardAboutUs';
import { PanelSection } from '../../components/PanelSection';
import './GetStartedCompleted.scss';

const domain = process.env.REACT_APP_COOKIE_DOMAIN;

const GetStartedCompletedComponent = ({ clearCustomRotation, getCheckoutDetails }) => {
  const checkoutId = qs.parse(window.location.search)?.checkout;
  const [checkoutDetails, setCheckoutDetails] = useState({});
  const [{ checkout: checkoutCookie }, setCookie] = useCookies(['checkout']);

  useEffect(() => {
    if (checkoutCookie) {
      setCookie('checkout', '', { path: '/', domain, expires: new Date(1) });
    }
  }, [checkoutCookie, setCookie]);

  const { referralId, howDidYouHear, productId } = checkoutDetails;

  const isShopifyCheckout = useMemo(() => checkoutDetails?.source === CheckoutSource.Shopify, [checkoutDetails]);
  const shopifyVendorLogo = useMemo(() => checkoutDetails?.vendor?.img_url, [checkoutDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchCheckoutDetails = useCallback(() => {
    if (checkoutId) {
      return getCheckoutDetails(checkoutId);
    }
  }, [getCheckoutDetails, checkoutId]);

  const { isFetching } = useDataEffect(fetchCheckoutDetails, setCheckoutDetails);

  useEffect(() => {
    clearCustomRotation();
  }, [clearCustomRotation]);

  const vendorShopifyTheme = useMemo(() => checkoutDetails?.vendor?.shopifyManifest?.theme, [checkoutDetails]);

  const buttonStyle = useMemo(
    () =>
      vendorShopifyTheme?.buttonColor && {
        background: vendorShopifyTheme.buttonColor,
        borderColor: vendorShopifyTheme.buttonColor,
        boxShadow: 'none',
        opacity: 1,
      },
    [vendorShopifyTheme]
  );

  const renderLoader = () => {
    if (isFetching) {
      return (
        <div className="mt-5">
          <DataLoading count={0} isLoading={isFetching} loadingText={'Loading...'}>
            <></>
          </DataLoading>
        </div>
      );
    }
    return '';
  };

  return (
    <Simple
      className={classNames('page-get-started-completed', {
        'shopify-checkout': isShopifyCheckout,
      })}
      isShopifyCheckout={isShopifyCheckout}
      shopifyVendorLogo={shopifyVendorLogo}
      withFooter={isShopifyCheckout}
    >
      <Container className="pt-4">
        <div className="d-flex text-center align-items-center justify-content-center mb-5">
          <h3 className="mb-0 ml-2">Purchase Successful!</h3>
        </div>
        {isShopifyCheckout && (
          <>
            <div className="text-center mb-5">
              We have sent you an email with a confirmation button.
              <br /> Please click on the button and verify your email to complete the sign-up process.
            </div>
            {checkoutDetails?.vendor?.shopifyManifest?.storefrontUrl && (
              <div className="text-center">
                <a href={checkoutDetails.vendor.shopifyManifest.storefrontUrl} title="Go back to the store">
                  <Button color="primary" style={buttonStyle}>
                    Go back to the store
                  </Button>
                </a>
              </div>
            )}
          </>
        )}
        {!isShopifyCheckout && (
          <>
            <Box className="d-flex flex-column text-center success-description">
              <div>
                <strong className="text-primary">Just one more step...</strong>
              </div>
              <p>
                We have sent you an email with a confirmation button. Please click on the button and verify your email
                to complete the sign-up process.
              </p>
              <div>{productId && <CompleteMessage productId={productId} />}</div>
              <p className="mb-0">
                Help us spread the word! We are @bottomless on{' '}
                <a href="https://www.instagram.com/bottomless/" target="_blank" rel="noopener noreferrer">
                  Instagram
                </a>{' '}
                and{' '}
                <a href="https://www.twitter.com/bottomless/" target="_blank" rel="noopener noreferrer">
                  Twitter
                </a>
                !
              </p>
            </Box>
            {renderLoader()}
            {referralId && checkoutId && (
              <Row className="mt-5">
                {checkoutId && (
                  <Col xs="12" md="4">
                    <HeardAboutUs checkout_id={checkoutId} howDidYouHear={howDidYouHear} />
                  </Col>
                )}
                {referralId && (
                  <Col xs="12" md="8">
                    <PanelSection title="Use your referral for free refills">
                      <Box>
                        <Invite
                          referralId={referralId}
                          withDescription={false}
                          shareButtonsClassname="justify-content-md-center"
                        />
                      </Box>
                    </PanelSection>
                  </Col>
                )}
              </Row>
            )}
          </>
        )}
      </Container>
    </Simple>
  );
};

GetStartedCompletedComponent.propTypes = {
  clearCustomRotation: PropTypes.func.isRequired,
  getCheckoutDetails: PropTypes.func.isRequired,
};

export const GetStartedCompletedPage = withMetaTags({
  title: 'Bottomless.com: Verify your e-mail',
})(
  connect(
    ({ user: { me } }) => ({ me }),
    dispatch => ({
      clearCustomRotation: () => dispatch(clearCustomRotationAction()),
      getCheckoutDetails: data => dispatch(getCheckoutDetailsAction(data)),
    })
  )(GetStartedCompletedComponent)
);
