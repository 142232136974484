import React from 'react';
import { SlugCategoryManifest } from '../../../components/CategoryManifest';
import { GenericTabs } from '../manifest';

const COMPONENTS = {
  CoffeeShopTabs: GenericTabs, // Removed Coffee tabs for now
  GenericShopTabs: GenericTabs,
};

export const Tabs = props => (
  <SlugCategoryManifest
    manifestKey="shopTabs"
    components={COMPONENTS}
    noLoading
    fallbackComponent={GenericTabs}
    {...props}
  />
);
