import { LogoutTypes, ChangeScaleTypes } from '@bottomless/common/store';
import { GetOpenShopTypes } from './open-shop.actions';

const initialState = {
  data: [],
  isLoading: false,
};

export const openShopReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetOpenShopTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetOpenShopTypes.SUCCESS:
      return {
        data: action.payload,
        isLoading: false,
      };

    case GetOpenShopTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case LogoutTypes.SUCCESS:
    case ChangeScaleTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
