import { useCallback, useEffect, useRef } from 'react';

export const usePreventTouchMove = () => {
  const ref = useRef();

  const preventDefault = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const currentElement = ref.current;
    currentElement.addEventListener('touchmove', preventDefault, { passive: false });

    return () => currentElement.removeEventListener('touchmove', preventDefault);
  }, [ref, preventDefault]);

  return ref;
};
