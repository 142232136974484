import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader, X } from 'react-feather';
import { Button, Input, Label } from 'reactstrap';
import * as Yup from 'yup';
import { Field, Form, Image, Radio, SubmitButton } from '@bottomless/common/components';
import { ReviewBrewingMethods } from '@bottomless/common/constants';
import './ReviewForm.scss';

const BREWING_METHOD_OPTIONS = {
  '': '--select--',
  [ReviewBrewingMethods.Aeropress]: 'Aeropress',
  [ReviewBrewingMethods.PourOver]: 'Pour Over',
  [ReviewBrewingMethods.SuperautomaticEspressoMachine]: 'Superautomatic Espresso Machine',
  [ReviewBrewingMethods.EspressoMachine]: 'Espresso Machine',
  [ReviewBrewingMethods.FrenchPress]: 'French Press',
  [ReviewBrewingMethods.Moccamaster]: 'Moccamaster',
  [ReviewBrewingMethods.Drip]: 'Drip',
  Other: 'Other',
};

const Schema = Yup.object().shape({
  like: Yup.bool().required('This field is required'),
  product_id: Yup.string().required('This field is required'),
  order_id: Yup.string(),
  brewing_method: Yup.string().required('This field is required'),
  brewing_method_other: Yup.string().when('brewing_method', {
    is: BREWING_METHOD_OPTIONS.Other,
    then: Yup.string().required('This field is required'),
  }),
  content: Yup.string(),
  photos: Yup.array()
    .max(10)
    .of(Yup.string().required('This field is required')),
});

export const ReviewForm = ({ product, order, review, onSubmit, onSuccess, onPictureUpload }) => {
  const form = useRef();
  const uploadField = useRef();
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    form.current.setFieldValue('product_id', product._id);
  }, [form, product]);

  useEffect(() => {
    form.current.setFieldValue('order_id', order?._id);
  }, [form, order]);

  const handleSubmit = useCallback(
    ({ like, brewing_method, brewing_method_other, ...data }) =>
      onSubmit({
        ...data,
        like: like === 'true' || like === true,
        dislike: like !== 'true' && like !== true,
        brewing_method: brewing_method === BREWING_METHOD_OPTIONS.Other ? brewing_method_other : brewing_method,
      }),
    [onSubmit]
  );

  const onImageChange = useCallback(
    async e => {
      const [file] = e.target.files;

      if (!file) {
        return;
      }

      try {
        setError(null);
        setLoading(true);

        const data = new FormData();
        data.append('file', file);

        const response = await onPictureUpload(product._id, data);

        if (!response || response.error) {
          setError(response?.payload || new Error("Couldn't upload the file"));
        } else {
          form.current.setFieldValue('photos', [...form.current.state.values.photos, response.payload.photo]);
        }

        uploadField.current.value = '';
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    },
    [form, uploadField, product, onPictureUpload, setError, setLoading]
  );

  const onImageDelete = useCallback(
    index => () => {
      const { photos } = form.current.state.values;
      form.current.setFieldValue('photos', [...photos.slice(0, index), ...photos.slice(index + 1)]);
    },
    [form]
  );

  return (
    <div className="form-review">
      <Form
        innerRef={form}
        initialValues={{
          product_id: product._id,
          order_id: order?._id,
          brewing_method: '',
          brewing_method_other: '',
          photos: [],
          ...(review || {}),
          like: review?.like || !review?.dislike,
          dislike: review?.dislike && !review?.like,
        }}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
      >
        {({ isSubmitting, values }) => (
          <>
            <div className="d-flex mb-2">
              <Radio name="like" type="radio" value={true} label="Like" />
              <span className="ml-3">
                <Radio name="like" type="radio" value={false} label="Dislike" />
              </span>
            </div>
            <Field name="brewing_method" label="Brewing Method" type="select" options={BREWING_METHOD_OPTIONS} />
            {values.brewing_method === BREWING_METHOD_OPTIONS.Other && (
              <Field name="brewing_method_other" label="Specific Brewing Method" type="text" />
            )}
            <Field name="content" label="Write your review" type="textarea" />
            <div className="pictures d-flex align-items-center">
              {values.photos.map((photo, i) => (
                <div key={i} className="mx-3 mb-3 picture-wrapper">
                  <Image alt="Review Photo" src={photo} width={70} height={70} />
                  <Button
                    type="button"
                    color="link"
                    className="d-flex align-items-center justify-content-center"
                    onClick={onImageDelete(i)}
                  >
                    <X width="16" height="16" color="#212529" />
                  </Button>
                </div>
              ))}
            </div>
            {error && <div className="text-danger text-sm mb-3">{error.message}</div>}
            <div className="d-flex justify-content-between">
              {values.photos.length < 10 && (
                <Label
                  disabled={isLoading}
                  className="btn btn btn-outline-dark d-flex align-items-center justify-content-center"
                >
                  <Input
                    className="d-none"
                    disabled={isLoading}
                    color="success"
                    type="file"
                    name="file"
                    accept="image/png, image/jpeg"
                    onChange={onImageChange}
                    innerRef={uploadField}
                  />
                  Add photo
                </Label>
              )}
              {values.photos.length >= 10 && <span />}
              <SubmitButton disabled={isLoading} color="primary" isSubmitting={isSubmitting}>
                Save
              </SubmitButton>
            </div>
            {isLoading && (
              <div className="d-flex align-items-center text-sm mt-3">
                Uploading... <Loader size="13" className="spin ml-2" />
              </div>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

ReviewForm.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }),
  review: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onPictureUpload: PropTypes.func.isRequired,
};
