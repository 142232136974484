import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'react-feather';
import { Button } from 'reactstrap';

export const PetFoodChangeProduct = ({ checkout, goToShop }) => {
  const onChange = useCallback(() => {
    if (checkout.vendor_id) {
      goToShop(checkout._id, 'pet-food');
    } else {
      window.location = '/public_shop?category=pet-food';
    }
  }, [checkout, goToShop]);

  return (
    <Button color="link" size="sm" onClick={onChange} className="placeholder-text">
      <Edit width="18" height="18" role="img" aria-label="Edit" />
    </Button>
  );
};

PetFoodChangeProduct.propTypes = {
  checkout: PropTypes.object,
  goToShop: PropTypes.func.isRequired,
};
