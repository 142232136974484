import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import { browserMatches } from '../../utils';

const DefaultAction = () => (
  <Button
    onClick={() => {
      window.localStorage.clear();
      window.location.reload();
    }}
    size="sm"
    outline
    color="danger"
  >
    Refresh and try again
  </Button>
);

class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
  }

  componentDidMount() {
    this.setState(this.defaultState());
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState(this.defaultState());
    }
  }

  componentDidCatch(error, errorInfo) {
    const isChunkLoadError = error.name === 'ChunkLoadError';

    if (isChunkLoadError) {
      throw error;
    }

    this.setState({ error });

    Sentry.withScope(scope => {
      scope.setExtra('errorInfo', errorInfo);
      Sentry.captureException(error);
    });

    if ('hj' in window) {
      window.hj('tagRecording', ['Error']);
    }

    if (!browserMatches()) {
      window.location.replace(`/update-browser.html`);
    }
  }

  defaultState() {
    return { error: null };
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <div className="ErrorComponent my-3">
            <div>
              <Alert color="danger">
                <h4 className="alert-heading mb-3">We&apos;re sorry — something&apos;s gone wrong.</h4>
                <p>
                  Our team has been notified, but click{' '}
                  <a
                    href="#"
                    onClick={() =>
                      Sentry.showReportDialog({
                        eventId: Sentry.lastEventId(),
                      })
                    }
                    className="alert-link"
                  >
                    here fill out a report.
                  </a>
                </p>
                <hr className="my-3" />
                <div className="d-flex justify-content-end">{this.props.action}</div>
              </Alert>
            </div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

ErrorBoundaryComponent.defaultProps = {
  action: <DefaultAction />,
};

ErrorBoundaryComponent.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.node,
  location: PropTypes.object.isRequired,
};

export const ErrorBoundary = withRouter(ErrorBoundaryComponent);
