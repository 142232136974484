import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import * as Sentry from '@sentry/browser';

const KEY = 'AIzaSyD-WmVRn4haurYhiY1O400BbnmhN02NjfU';
const LIBRARIES = ['places'];

export const Origin = ({ origin, setShowMap }) => {
  const [marker, setMarker] = useState(null);
  const [loading, setLoading] = useState(true);

  const onLoad = useCallback(function callback(map) {
    try {
      var request = {
        query: origin.name,
        fields: ['name', 'geometry', 'types'],
      };
      if (window.google) {
        const service = new window.google.maps.places.PlacesService(map);

        service.findPlaceFromQuery(request, function(results, status) {
          if (window.google && status === window.google.maps.places.PlacesServiceStatus.OK) {
            const countries = results.filter(place => place.types.includes('country'));

            if (countries.length) {
              setMarker(countries[0]);
            } else if (results.length) {
              setMarker(results[0]);
            }
          }
          setLoading(false);
        });
      }
    } catch (error) {
      Sentry.captureException(new Error(`Could not load google maps service`));
      setShowMap(false);
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const center = marker ? marker.geometry.location : { lat: 6, lng: -83 };

  if (!loading && !marker) {
    Sentry.captureException(new Error(`Could not show map for Origin: ${origin.name}, ${origin.code}`));
    setShowMap(false);
    return null;
  }

  return (
    <LoadScript googleMapsApiKey={KEY} libraries={LIBRARIES}>
      <GoogleMap
        onLoad={onLoad}
        mapContainerStyle={{ minHeight: 200 }}
        center={center}
        zoom={2}
        options={{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          panControl: false,
          rotateControl: false,
          fullscreenControl: false,
          scrollWheel: false,
          draggable: false,
          keyboardShortcuts: false,
          disableDoubleClickZoom: true,
          styles: [
            {
              featureType: 'administrative',
              elementType: 'geometry.fill',
              stylers: [{ visibility: 'off' }],
            },
          ],
        }}
      >
        {marker && <Marker position={marker.geometry.location} />}
      </GoogleMap>
    </LoadScript>
  );
};

Origin.propTypes = {
  origin: PropTypes.object.isRequired,
  setShowMap: PropTypes.func.isRequired,
};
