import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid e-mail address')
    .required('This field is required'),
  referral_id: Yup.string(),
});

export const EnterEmail = ({ checkout, getStarted, onSuccess, referralId: referral_id }) => {
  const hasCheckout = Boolean(checkout?.email);

  const [isOpen, setOpen] = useState(!hasCheckout);
  const [isFinished, setFinished] = useState(hasCheckout);

  const onSubmitSuccess = data => {
    setOpen(false);
    setFinished(true);
    onSuccess(data);
  };

  useEffect(() => {
    if (checkout?.email) {
      setFinished(true);
      setOpen(false);
    }
  }, [checkout]);

  return (
    <div className="mb-3">
      <Collapse isOpen={isOpen && !isFinished}>
        <Row>
          <Col xs="12" md="6">
            <Form
              initialValues={{ email: '', referral_id }}
              validationSchema={SignupSchema}
              onSubmit={getStarted}
              onSuccess={onSubmitSuccess}
            >
              {({ isSubmitting }) => (
                <>
                  <Field name="email" type="email" label="E-mail" />
                  <Field name="referral_id" type="hidden" />
                  <SubmitButton size="sm" color="dark" loadingText="Saving..." isSubmitting={isSubmitting}>
                    Save
                  </SubmitButton>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

EnterEmail.defaultProps = {
  referralId: '',
};

EnterEmail.propTypes = {
  getStarted: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  referralId: PropTypes.string,
  checkout: PropTypes.object,
};
