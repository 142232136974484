import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'reactstrap';
import * as Yup from 'yup';
import * as moment from 'moment-timezone';
import classNames from 'classnames';
import { Form, DateDropdown, withPlaceholder } from '@bottomless/common/components';
import { utcDate } from '@bottomless/common/src/utils';
import { CheckoutShipTime } from './CheckoutShipTime';
import { pstTimezone } from '../../../utils/dates';

const Schema = Yup.object().shape({
  shipment_date: Yup.date().required('This field is required'),
});

const ScheduleShipmentComponent = ({ checkout, setShipmentDate, onSuccess, className }) => {
  const [open, setIsCalendarOpen] = useState(false);

  const dateOverride = useMemo(() => {
    if (checkout.dateOverride?.overwritten) {
      return null;
    }

    return checkout.dateOverride?.answer || 'nextBusiness';
  }, [checkout]);

  const shipmentDate = checkout.shipment_date ? new Date(checkout.shipment_date) : null;

  return (
    <Card body className={classNames('schedule-shipment-card mb-2 mb-lg-3', className)}>
      <Form
        initialValues={{ shipment_date: shipmentDate }}
        validationSchema={Schema}
        onSubmit={setShipmentDate}
        onSuccess={() => {
          onSuccess();
          setIsCalendarOpen(false);
        }}
        inline
      >
        {({ submitForm }) => (
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center w-100">
            <>
              {dateOverride ? (
                <CheckoutShipTime
                  checkout={checkout}
                  dateOverride={dateOverride}
                  excludeDates={checkout.exclude_fulfilment_dates}
                />
              ) : (
                <span className="mr-2 text-center text-lg-left">
                  Processing and Shipment Date:{' '}
                  <strong>{moment.tz(checkout.shipment_date, 'YYYY-MM-DD', pstTimezone).format('MM/DD/YYYY')}</strong>
                </span>
              )}
              <div className="placeholder-text">
                <Button
                  color="link"
                  size="sm"
                  className="d-flex align-items-center button-date"
                  onClick={() => setIsCalendarOpen(true)}
                >
                  <span className="mr-2 small">Pick Specific Date</span>
                  <DateDropdown
                    onChange={() => setTimeout(submitForm, 0)}
                    name="shipment_date"
                    min={new Date(Date.now() + 86400000)}
                    max={new Date(Date.now() + 56 * 86400000)}
                    excludeDates={
                      checkout.exclude_fulfilment_dates ? checkout.exclude_fulfilment_dates.map(utcDate) : []
                    }
                    excludeWeekends
                    opened={open}
                    popperPlacement="bottom-end"
                    resetOpenState={() => setIsCalendarOpen(false)}
                    onCalendarClose={() => setIsCalendarOpen(false)}
                  />
                </Button>
              </div>
            </>
          </div>
        )}
      </Form>
    </Card>
  );
};

ScheduleShipmentComponent.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  setShipmentDate: PropTypes.func.isRequired,
  checkout: PropTypes.shape({
    shipment_date: PropTypes.any,
    exclude_fulfilment_dates: PropTypes.array,
    dateOverride: PropTypes.shape({
      overwritten: PropTypes.bool,
      answer: PropTypes.string,
    }),
  }).isRequired,
  className: PropTypes.string,
};

export const ScheduleShipment = withPlaceholder({
  condition: 'checkout',
  props: {
    checkout: {},
  },
})(ScheduleShipmentComponent);
