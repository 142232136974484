import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Landing } from '../../../components/Landing/Landing';

export const ProductMarketingComponent = ({ product, onClickStartCoffeeJourney }) => {
  return (
    <>
      <hr id="product-marketing" />
      <div className="mt-5">
        <h1 className="stroked-heading text-center mb-2">Why Go Bottomless?</h1>
        <Landing actionText={`Sign up for ${product.name}`} onClick={onClickStartCoffeeJourney} isProductSpecific />
      </div>
    </>
  );
};

ProductMarketingComponent.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  onClickStartCoffeeJourney: PropTypes.func.isRequired,
};

export const ProductMarketing = memo(ProductMarketingComponent);
