/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import has from 'lodash-es/has';
import get from 'lodash-es/get';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { Elements } from 'react-stripe-elements';
import qs from 'query-string';
import * as Sentry from '@sentry/browser';
import { Box, Error } from '@bottomless/common/components';
import { useDataEffect, useOnce, useQueryString } from '@bottomless/common/hooks';
import { addToastAction, ChangeScaleTypes, singletonAction } from '@bottomless/common/store';
import { GoogleMapsLoader } from '../../components/Maps/GoogleMapsLoader';
import { withMetaTags } from '../../components/MetaTags/MetaTags';
import { StripeLoader } from '../../components/Stripe/StripeLoader';
import { Simple } from '../../layouts/Simple/Simple';
import { clearUserVendorAction, getMeAction } from '../../store';
import { getStartedCompleteAction } from '../../store/auth';
import {
  finishCheckoutAction,
  getCheckoutAction,
  setCheckoutAddressAction,
  setEmailAction,
  allInOneCheckoutAction,
  setStripeAction,
  setShipmentAction,
  getCheckoutShippingPriceAction,
  getCheckoutAfterDataAction,
} from '../../store/checkout';
import { EVENT_ADDED_ADDRESS, EVENT_CHECKOUT_COMPLETE, syncCheckout, trackEvent } from '../../utils/tracker';
import { BillingDetails } from './components/BillingDetails';
import { EnterEmail } from './components/EnterEmail';
import { PaymentDetails } from './components/PaymentDetails';
import { PickProduct } from './components/PickProduct';
import { ScheduleShipment } from './components/ScheduleShipment';
import { ExpressCheckout } from './components/ExpressCheckout';
import { StripeLoaded } from '../../components/Stripe/StripeLoader';
import { CheckoutHeading } from './components/CheckoutHeading';
import './GetStarted.scss';
import classNames from 'classnames';
import { useCookies } from 'react-cookie';
import { CheckoutSource } from '@bottomless/common/constants';
import { clearCustomRotationAction } from '../../store/quiz';

const domain = process.env.REACT_APP_COOKIE_DOMAIN;

const handleAsyncUpdate = (fn, setter, onSuccess = () => {}) => async data => {
  const response = await fn(data);
  setter(response.payload);

  if (!response.error && onSuccess) {
    onSuccess(response.payload);
  }

  return response;
};

const GetStartedPageComponent = ({
  setAddress,
  setShipment,
  setStripe,
  getCheckout,
  finishCheckout,
  addToast,
  match,
  history,
  finishRegistration,
  cleanAccountData,
  getMe,
  setEmail,
  allInOne,
  hideRotationImage,
  getCheckoutShippingPrice,
  clearCustomRotation,
  getCheckoutAfterData,
  vendor: foundVendor,
  clearUserVendor,
}) => {
  const { params } = useQueryString();
  const [checkout, setCheckout] = useState();
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(true);
  const [initialCheckout, setInitialCheckout] = useState();
  const [isOpen, setOpen] = useState(true);
  const [shippingPrice, setShippingPrice] = useState();
  const [shippingPriceLoading, setShippingPriceLoading] = useState(true);
  const [checkoutFinished, setCheckoutFinished] = useState(false);
  const [{ checkout: checkoutCookie }, setCookie, removeCookie] = useCookies(['checkout']);

  useDataEffect(getCheckoutShippingPrice, ({ shippingPrice }) => {
    setShippingPrice(shippingPrice);
    setShippingPriceLoading(false);
  });

  useEffect(() => {
    clearUserVendor();
  }, [clearUserVendor]);

  const isShopifyCheckout = useMemo(
    () => foundVendor || checkout?.source === CheckoutSource.Shopify || params.source === CheckoutSource.Shopify,
    [checkout, foundVendor, params]
  );

  const rawVendor = useMemo(
    () =>
      !checkout?.product?.product.rotating ? checkout?.product?.product?.vendor_id : checkout?.first_product?.vendor_id,
    [checkout]
  );

  const vendor = rawVendor || foundVendor;

  const shopifyVendorLogo = useMemo(() => vendor?.img_url, [vendor]);

  const vendorShopifyTheme = useMemo(() => {
    if (foundVendor?.shopifyManifest?.theme) {
      return foundVendor.shopifyManifest.theme;
    }

    return !checkout?.product?.product.rotating
      ? checkout?.product?.product.vendor_id?.shopifyManifest?.theme
      : checkout?.first_product?.vendor_id?.shopifyManifest?.theme;
  }, [foundVendor, checkout]);

  const { error: fetchCheckoutError } = useDataEffect(
    getCheckout,
    checkout => {
      setInitialCheckout(checkout);
      setCheckout(checkout);
      setIsCheckoutLoading(false);
    },
    match.params.checkoutId,
    null,
    [match.params]
  );

  useEffect(() => {
    if (checkout && !checkoutFinished && (!checkoutCookie || checkoutCookie !== checkout._id)) {
      const dateIn3Months = new Date();
      dateIn3Months.setMonth(dateIn3Months.getMonth() + 3);
      setCookie('checkout', checkout._id, { path: '/', domain, expires: dateIn3Months });
    }

    if (checkoutFinished) {
      removeCookie('checkout', { path: '/', domain });
    }
  }, [checkoutCookie, checkout, setCookie, removeCookie, checkoutFinished]);

  useOnce(() => {
    syncCheckout(checkout);
  }, [checkout]);

  useEffect(() => {
    if (checkout && !checkout.product) {
      if (checkout.vendor_id) {
        history.push(`/get_started_shop/${checkout._id}`);
      } else if (checkout.category?.slug === 'coffee') {
        history.push(`/filter_checkout/${checkout._id}`);
      } else {
        history.push(`/public_shop${checkout.category?.slug ? `?category=${checkout.category?.slug}` : ''}`);
      }
    }
  }, [checkout, history]);

  useEffect(() => {
    try {
      if (checkout && checkout._id && typeof _refersion !== 'undefined') {
        _refersion(() => _rfsn._addCart(checkout._id));
      }
    } catch (error) {
      Sentry.captureException(new Error(`Failed to add Refersion cart on checkout. Error: ${error}`));
      return null;
    }
  }, [checkout]);

  const onSetAddress = handleAsyncUpdate(setAddress, setCheckout, checkout => {
    trackEvent(EVENT_ADDED_ADDRESS, { checkout });
  });

  const onChangeAddress = async ({ values }) => {
    const { verifiedAddress } = values || {};
    const { street1, street2, city, state, zip } = verifiedAddress || {};

    if ((street1 && street2 && city && state && zip) || (street1 && city && state && zip)) {
      try {
        setIsCheckoutLoading(true);
        const { payload, error } = await setAddress(values, { hideToast: true });
        setCheckout({ ...checkout, verifiedAddress: payload.verifiedAddress, taxRate: payload.taxRate });

        if (!error) {
          setShippingPrice(payload.shippingPrice);
          setIsCheckoutLoading(false);
        }
      } catch (e) {
        setShippingPrice(null);
        setIsCheckoutLoading(false);
      }
    }
  };

  const onSetShipment = handleAsyncUpdate(
    data =>
      setShipment({
        ...data,
        shipment_date: has(data, 'shipment_date') ? moment(data.shipment_date).format('YYYY-MM-DD') : null,
      }),
    setCheckout
  );

  const onSetStripe = data => {
    setCheckout(data);
  };

  const onRefreshCheckout = () => {
    setIsCheckoutLoading(true);
    handleAsyncUpdate(
      () => getCheckout(checkout?._id),
      checkout => {
        setCheckout(checkout);
        setIsCheckoutLoading(false);
      }
    )();
  };

  const onSetEmail = handleAsyncUpdate(setEmail, setCheckout);
  const onSetEmailSuccess = () => addToast('Email adddress has been saved');

  const onSetShipmentSuccess = () => addToast('Shipment date has been changed');

  const onPaymentSuccess = async () => {
    const { payload } = await finishRegistration();

    if (!payload.primary) {
      cleanAccountData();
      await getMe();
    }

    trackEvent(EVENT_CHECKOUT_COMPLETE, { checkout });

    if (isShopifyCheckout && vendor?.shopifyManifest?.storefrontUrl) {
      setCheckoutFinished(true);
      clearCustomRotation();

      if (checkoutCookie) {
        removeCookie('checkout', { path: '/', domain });
      }

      const baseUrl = `${vendor.shopifyManifest.storefrontUrl.replace(/\/$/, '')}/pages/subscription-by-usage`;

      if (payload.redirectURL) {
        window.location.replace(payload.redirectURL);
      } else if (vendor.shopifyManifest?.useFacebookPixel) {
        try {
          const { payload, error } = await getCheckoutAfterData(checkout._id);

          if (error) {
            throw new Error(error);
          }

          window.location.replace(
            `${baseUrl}#get_started_complete?${qs.stringify({
              success: true,
              ...payload,
            })}`
          );
        } catch (e) {
          window.location.replace(`${baseUrl}#get_started_complete`);
        }
      } else {
        window.location.replace(`${baseUrl}#get_started_complete`);
      }

      return;
    }

    history.push(payload.primary ? `/get_started_complete?checkout=${checkout._id}` : '/profile');
  };

  const goToPersonalized = () => {
    history.push(`/filter_checkout?finished=true`);
  };

  const goToShop = (id, category) => {
    history.push(`/get_started_shop/${id}${category ? `?category=${category}` : ''}`);
  };

  const goToQuiz = () => {
    history.push('/quiz');
  };

  const goToRotation = id => {
    history.push(`/filter_checkout/advanced?new_scale=true&checkoutId=${id}`);
  };

  const goToCustomListShop = () => {
    history.push('/custom-list-shop');
  };

  const goToLandingPage = () => {
    const categorySlug = checkout.product?.product?.category?.slug;

    window.location = `/${categorySlug}`;
  };

  const onSetStripeToken = async data => {
    try {
      const { payload } = await setStripe(data);

      if (payload) {
        onSetStripe(payload);
      }
    } catch (e) {
      addToast(get(e, 'details.token', e.message));
    }
  };

  if (fetchCheckoutError) {
    return (
      <Simple className="page-get-started" withNavbar={false}>
        <Error />
      </Simple>
    );
  }

  return (
    <StripeLoader>
      <GoogleMapsLoader>
        <Simple
          className={classNames('page-get-started', {
            'shopify-checkout': isShopifyCheckout,
          })}
          withCheckoutButton={false}
          isShopifyCheckout={isShopifyCheckout}
          shopifyVendorLogo={shopifyVendorLogo}
          withFooter={isShopifyCheckout}
        >
          <Container className="d-flex flex-column">
            {!isShopifyCheckout && (
              <h3 className="mb-4 mb-lg-5 text-center">
                <CheckoutHeading checkout={checkout} />
              </h3>
            )}
            <Row className="mt-lg-3 mt-0">
              <Col lg="6" className="d-flex flex-column">
                <h5>Order Summary</h5>
                <ScheduleShipment
                  checkout={checkout}
                  setShipmentDate={onSetShipment}
                  onSuccess={onSetShipmentSuccess}
                />
                <PickProduct
                  checkout={checkout}
                  goToPersonalized={goToPersonalized}
                  goToRotation={goToRotation}
                  goToShop={goToShop}
                  goToQuiz={goToQuiz}
                  goToCustomListShop={goToCustomListShop}
                  hideRotationImage={hideRotationImage && checkout.first_product}
                  goToLandingPage={goToLandingPage}
                />
                <PaymentDetails
                  isCheckoutLoading={isCheckoutLoading}
                  checkout={checkout}
                  showCardDetails={false}
                  shippingPrice={shippingPrice}
                  shippingPriceLoading={shippingPriceLoading}
                />
              </Col>

              <Col lg="6" className="mt-4 mt-lg-0">
                <h5>Shipping Details</h5>
                {initialCheckout && !initialCheckout.email && (
                  <EnterEmail getStarted={onSetEmail} checkout={checkout} onSuccess={onSetEmailSuccess} />
                )}
                {(!checkout || checkout.email) && (
                  <Box className="shipping-details">
                    {checkout && (
                      <div className="text-center">
                        <StripeLoaded loader={React.Fragment}>
                          <Elements>
                            <ExpressCheckout
                              onChange={onSetStripeToken}
                              checkout={checkout}
                              editing={true}
                              finishCheckout={finishCheckout}
                              onPaymentSuccess={onPaymentSuccess}
                              setAddress={onSetAddress}
                              setOpen={setOpen}
                              addToast={addToast}
                            />
                          </Elements>
                        </StripeLoaded>
                      </div>
                    )}
                    <>
                      {isOpen && (
                        <BillingDetails
                          checkout={checkout}
                          allInOne={allInOne}
                          addToast={addToast}
                          onSetStripe={onSetStripe}
                          finishCheckout={finishCheckout}
                          onPaymentSuccess={onPaymentSuccess}
                          onRefreshCheckout={onRefreshCheckout}
                          onChangeAddress={onChangeAddress}
                          theme={isShopifyCheckout ? vendorShopifyTheme : undefined}
                        />
                      )}
                    </>
                  </Box>
                )}
              </Col>
            </Row>
          </Container>
        </Simple>
      </GoogleMapsLoader>
    </StripeLoader>
  );
};

GetStartedPageComponent.propTypes = {
  getCheckout: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired,
  setShipment: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setStripe: PropTypes.func.isRequired,
  finishCheckout: PropTypes.func.isRequired,
  finishRegistration: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      checkoutId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  cleanAccountData: PropTypes.func.isRequired,
  getMe: PropTypes.func.isRequired,
  allInOne: PropTypes.func.isRequired,
  hideRotationImage: PropTypes.bool,
  getCheckoutShippingPrice: PropTypes.func.isRequired,
  clearCustomRotation: PropTypes.func,
  getCheckoutAfterData: PropTypes.func,
  vendor: PropTypes.shape({
    img_url: PropTypes.string.isRequired,
    shopifyManifest: PropTypes.shape({
      theme: PropTypes.object,
    }),
  }),
  clearUserVendor: PropTypes.func,
};

GetStartedPageComponent.defaultProps = {
  hideRotationImage: false,
};

export const GetStartedPage = connect(
  ({ vendor }, { location: { search } }) => {
    const { vendorId } = qs.parse(search);

    const foundVendor =
      vendorId && (vendor?.shopify?.find(v => v._id === vendorId) || vendor?.data?.find(v => v._id === vendorId));

    return { vendor: foundVendor };
  },
  (dispatch, { match: { params } }) => ({
    getCheckout: id => dispatch(getCheckoutAction(id)),
    setAddress: (data, paramsData) =>
      dispatch(
        singletonAction(setCheckoutAddressAction(params.checkoutId, data, paramsData), 'get-started-set-address')
      ),
    setEmail: data => dispatch(setEmailAction(params.checkoutId, data)),
    setStripe: data => dispatch(setStripeAction(params.checkoutId, data)),
    setShipment: data => dispatch(setShipmentAction(params.checkoutId, data)),
    allInOne: data => dispatch(allInOneCheckoutAction(params.checkoutId, data)),
    finishCheckout: data => dispatch(finishCheckoutAction(params.checkoutId, data)),
    getCheckoutShippingPrice: () => dispatch(getCheckoutShippingPriceAction(params.checkoutId)),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    finishRegistration: () => dispatch(getStartedCompleteAction(params)),
    cleanAccountData: () => dispatch({ type: ChangeScaleTypes.SUCCESS }),
    getMe: () => dispatch(getMeAction()),
    clearCustomRotation: () => dispatch(clearCustomRotationAction()),
    getCheckoutAfterData: id => dispatch(getCheckoutAfterDataAction(id)),
    clearUserVendor: () => dispatch(clearUserVendorAction()),
  })
)(
  withMetaTags({
    title: 'Bottomless.com: Get started',
  })(GetStartedPageComponent)
);

export default GetStartedPage;
