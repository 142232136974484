import { createHashTag } from '@bottomless/common/src/utils';
import PropTypes from 'prop-types';
import React from 'react';

export const NewVendorTag = ({ vendor }) => {
  // guard for component nor breaking in case server still not updated
  if (!vendor.created_at) {
    return null;
  }

  const now = new Date();
  const pastDate = new Date();
  pastDate.setDate(now.getDate() - 90);
  const vendorAddDate = new Date(vendor.created_at);

  if (vendorAddDate < pastDate) {
    return null;
  }

  return <span className="small text-secondary mr-1 non-link-tag">{createHashTag('New Vendor')}</span>;
};

NewVendorTag.propTypes = {
  vendor: PropTypes.object,
};
