import { useMemo } from 'react';

export const useProductsSort = (products, sorting, filters) => {
  return useMemo(() => sort(products, sorting, filters), [products, sorting, filters]);
};

const sort = (products, sorting, filters) => {
  const filterVariants = product =>
    (product.variants.filter(variant => filters?.size.includes(String(variant.size)))[0] || { price: 0 }).price;

  switch (sorting) {
    case 'search':
      return [...products].sort((a, b) => a.sortPriority - b.sortPriority);
    case 'desc_price':
      if (filters.size?.length > 0) {
        return [...products].sort((a, b) => filterVariants(b) - filterVariants(a));
      }
      return [...products].sort((a, b) => b.variants[0].price - a.variants[0].price);
    case 'asc_price':
      if (filters.size?.length > 0) {
        return [...products].sort((a, b) => filterVariants(a) - filterVariants(b));
      }
      return [...products].sort((a, b) => a.variants[0].price - b.variants[0].price);
    case 'alt':
      return [...products].sort((a, b) => avgAltitude(b) - avgAltitude(a));
    case 'alph':
      return [...products].sort((a, b) => (a.name < b.name ? -1 : a.name > b.name));
    case 'date_added':
      return [...products].sort((a, b) => new Date(b.date_added).getTime() - new Date(a.date_added).getTime());
    case 'likes':
      return [...products].sort((a, b) => b.likes - a.likes);
    case 'likes_ratio':
      return [...products].sort((a, b) => {
        const aValues = likesRatio(a);
        const bValues = likesRatio(b);

        return aValues.ratio === bValues.ratio ? bValues.sum - aValues.sum : bValues.ratio - aValues.ratio;
      });

    default:
      return products;
  }
};

const avgAltitude = product => ((product.altitude_max || 0) + (product.altitude_min || 0)) / 2;

const likesRatio = product => {
  const likes = product.likes || 0;
  const dislikes = product.dislikes || 0;
  const sum = likes + dislikes;

  if (!likes && !dislikes) {
    return { sum: 0, ratio: 0 };
  }

  const rounded = Math.round((likes / (sum + 1)) * 100);

  return rounded > 0 ? { sum, ratio: rounded } : { sum, ratio: -1 };
};
