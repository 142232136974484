import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Box, Field, Form, SubmitButton } from '@bottomless/common/components';

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid e-mail address')
    .required('This field is required'),
});

export const EmailForm = ({ onSubmit, onSuccess }) => (
  <Box secondary className="mb-4">
    <h2 className="text-center mb-4">Enter your email address</h2>
    <div className="mb-3 text-center">Please use the same email address associated with your subscription.</div>
    <Form
      initialValues={{ email: '', path: '/activate/step1?chooseAccount' }}
      validationSchema={Schema}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <>
          <Field name="email" type="email" placeholder="E-mail Address" />
          <SubmitButton isSubmitting={isSubmitting} loadingText="Processing..." color="dark" size="lg" block>
            Next
          </SubmitButton>
        </>
      )}
    </Form>
  </Box>
);

EmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
