export class ValidationError extends Error {
  constructor(message, details = {}) {
    super(message);

    this.details = details;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }
  }
}

export class UnathorizedError extends Error {
  constructor() {
    super('Unathorized');

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnathorizedError);
    }
  }
}
