import React from 'react';
import { UserCategoryManifest } from '../../../components/CategoryManifest';
import { CoffeeMenu, GenericMenu } from '../manifest';

const COMPONENTS = {
  CoffeeMenu,
  GenericMenu,
};

export const Menu = props => (
  <UserCategoryManifest manifestKey="menu" components={COMPONENTS} fallbackComponent={GenericMenu} {...props} />
);
