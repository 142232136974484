import { useMemo } from 'react';
import PropTypes from 'prop-types';

export const PhoneNumberFormat = ({ phone }) => {
  const formatted = useMemo(() => {
    const cleaned = String(phone).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (!match) {
      return phone;
    }

    const prefixCode = match[1] ? '+1 ' : '';
    return [prefixCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }, [phone]);

  return formatted;
};

PhoneNumberFormat.propTypes = {
  phone: PropTypes.string,
};
