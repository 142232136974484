import React from 'react';
import PropTypes from 'prop-types';
import { injectStripe } from 'react-stripe-elements';
import usePaymentRequest from './usePaymentRequest';

const PaymentContext = React.createContext([]);

Provider.propTypes = {
  children: PropTypes.node,
  args: PropTypes.array,
  stripe: PropTypes.object,
};

function Provider({ children, args, stripe }) {
  const [request, canMakePayment, loading, errors] = usePaymentRequest(stripe, ...args);

  return (
    <PaymentContext.Provider value={[request, canMakePayment, loading, errors]}>{children}</PaymentContext.Provider>
  );
}

export const PaymentProvider = injectStripe(Provider);

export function usePaymentProvider() {
  return React.useContext(PaymentContext);
}
