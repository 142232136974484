import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { utcDate } from '@bottomless/common/utils';
import { addToastAction } from '@bottomless/common/store';
import { UserStatuses } from '@bottomless/common/constants';
import {
  getInTransitionOrdersAction,
  getMyProductAction,
  triggerOrderAction,
  sources,
  getMeAction,
  verifyAddressAction,
  moveCustomListItemAction,
  deleteCustomListItemAction,
  updateCustomListAction,
  makeBottomlessProductAction,
  getPersonalizedProductAction,
  getProductAttributesAction,
  updateCustomListElementAction,
  getCustomListAction,
} from '../../store';

import './UpNext.scss';
import { EVENT_REMOVE_FROM_CUSTOM_LIST, EVENT_REORDER_CUSTOM_LIST, trackEvent } from '../../utils/tracker';
import { CustomRotation, TriggerNow } from './components';
import { NextOrderDate } from './components/NextOrderDate';
import { ShareCoffeeJourney } from '../ShareCoffeeJourney/ShareCoffeeJourney';
import { TriggerNowModal } from './components/TriggerNowModal';

const ONE_OFF_ENABLED = false;

const UpNextComponent = ({
  getMyProduct,
  addToast,
  product: myProduct,
  me,
  getInTransitionOrders,
  triggerOrder,
  inTransition,
  pastOrders,
  wishlist,
  moveCustomListItem,
  deleteCustomListItem,
  updateCustomList,
  makeBottomless,
  getPersonalizedProduct,
  personalizedProduct,
  getAttributes,
  attributes,
  updateCustomListElement,
  getMe,
  getCustomList,
}) => {
  const store = useStore();
  const [isOpen, setOpen] = useState(false);
  const [isSubscriptionSwitch, toggleSubscriptionSwitch, setSubscriptionSwitch] = useToggle(false);

  const hasOneOffOrder = useMemo(
    () => inTransition.filter(({ source }) => source === sources.USER_ONE_OFF).length > 0,
    [inTransition]
  );

  useEffect(() => {
    getPersonalizedProduct();
  }, [getPersonalizedProduct]);

  useEffect(() => {
    getMyProduct();
  }, [getMyProduct]);

  useEffect(() => {
    getAttributes();
  }, [getAttributes]);

  const isClosedOrOffboarding = useMemo(() => [UserStatuses.Closed, UserStatuses.Offboarding].includes(me.status), [
    me,
  ]);

  const isTriggerAllowed = useMemo(
    () =>
      !isClosedOrOffboarding &&
      (!myProduct?.pendingOrder || (myProduct?.pendingOrder && !hasOneOffOrder && ONE_OFF_ENABLED)),
    [myProduct, hasOneOffOrder, isClosedOrOffboarding]
  );

  const handleCustomListUpdate = useCallback(
    data => {
      const products = store
        .getState()
        .product.wishlist.map(({ product, variant, grind }) => ({ product: product._id, variant, grind: grind._id }));

      return updateCustomList({
        products: products,
        timestamp: Date.now(),
        indexChange: data,
      });
    },
    [updateCustomList, store]
  );

  const onMoveCustomListItem = useCallback(
    async (...data) => {
      moveCustomListItem(...data);
      const result = await handleCustomListUpdate(data);
      trackEvent(EVENT_REORDER_CUSTOM_LIST);
      return result;
    },
    [moveCustomListItem, handleCustomListUpdate]
  );

  const onDeleteCustomListItem = useCallback(
    async (...data) => {
      deleteCustomListItem(...data);
      const result = await handleCustomListUpdate();
      trackEvent(EVENT_REMOVE_FROM_CUSTOM_LIST);
      return result;
    },
    [deleteCustomListItem, handleCustomListUpdate]
  );

  if (!myProduct || !wishlist) {
    return null;
  }

  const { product, pendingOrder, variant: variantId, vendor, dynamicPricing, shopFilters } = myProduct;
  const variant = product && product.variants && product.variants.find(({ _id }) => _id === variantId);

  const toggle = () => setOpen(!isOpen);

  const productPricingRule = me.pricing_rule;

  const onOverrideDateSuccess = async ({ override_fulfillment_date }, { resetForm }) => {
    addToast('Order date have been successfully saved');
    resetForm({ override_fulfillment_date: utcDate(override_fulfillment_date) });
    await getInTransitionOrders();
  };

  const onSubscriptionSwitch = async () => {
    if (product.personalized_rotation) {
      const { payload: latestMe } = await getMe();
      if (latestMe.last_specific_product?.product) {
        return makeBottomless(latestMe.last_specific_product.product, {
          variant: latestMe.last_specific_product.variant,
          grind: latestMe.grind._id,
        });
      }

      setSubscriptionSwitch(true);
      return false;
    }

    if (me.personalized?.size) {
      return makeBottomless(personalizedProduct._id, {
        variant: personalizedProduct.variants.find(variant => variant.size === Number(me.personalized.size))._id,
        personalized: me.personalized
          ? {
              ...me.personalized,
              grind: me.grind._id,
              vendor: me.personalized?.vendor.map(vendor => vendor._id),
              tasting_note_category: me.personalized?.tasting_note_category.map(category => category._id),
              single_origins: me.personalized?.single_origins.map(origin => origin._id),
            }
          : undefined,
        grind: me.grind._id,
      });
    }

    setSubscriptionSwitch(true);
    return false;
  };

  const onSubscriptionSwitchSuccess = () => addToast('Your default product has been successfully changed', 'success');

  const onTriggerOrderSuccess = async () => {
    addToast('Order has been created');
    await getMyProduct();
    await getCustomList();
    getInTransitionOrders();
  };

  const price = dynamicPricing ? dynamicPricing.price : variant ? variant.price : null;

  return (
    <>
      <Row>
        <Col xs="12" className="mb-4">
          <div className="d-flex align-items-center justify-content-between custom-rotation-heading">
            <div className="d-flex justify-content-between justify-content-md-start w-100 mb-3 mb-md-2">
              <h2 className="mb-0">My Custom Rotation</h2>
              {pastOrders?.length > 0 && <ShareCoffeeJourney me={me} />}
            </div>
            <div className="d-flex align-items-center">
              <NextOrderDate
                me={me}
                pendingOrder={pendingOrder}
                hasOneOffOrder={hasOneOffOrder}
                pastOrders={pastOrders}
                vendor={vendor}
              />
              <TriggerNow onTrigger={toggle} isTriggerAllowed={isTriggerAllowed} />
            </div>
          </div>
          {product && (
            <CustomRotation
              wishlist={wishlist}
              userProduct={product}
              me={me}
              price={price}
              size={variant?.size}
              shopFilters={shopFilters}
              productPricingRule={productPricingRule}
              moveCustomListItem={onMoveCustomListItem}
              deleteCustomListItem={onDeleteCustomListItem}
              onSubscriptionSwitch={onSubscriptionSwitch}
              onSubscriptionSwitchSuccess={onSubscriptionSwitchSuccess}
              attributes={attributes}
              updateCustomListElement={updateCustomListElement}
              variant={variant}
            />
          )}
        </Col>
      </Row>
      {isOpen && (
        <TriggerNowModal
          vendor={vendor}
          toggle={toggle}
          pendingOrder={pendingOrder}
          hasOneOffOrder={hasOneOffOrder}
          triggerOrder={triggerOrder}
          onOverrideDateSuccess={onOverrideDateSuccess}
          setOpen={setOpen}
          onTriggerOrderSuccess={onTriggerOrderSuccess}
        />
      )}
      {isSubscriptionSwitch && (
        <Modal isOpen={isSubscriptionSwitch} toggle={toggleSubscriptionSwitch} className="text-center">
          <ModalHeader toggle={toggleSubscriptionSwitch}>
            <span className="d-none d-sm-block">Change your default product</span>
          </ModalHeader>
          <ModalBody>
            <p className="mb-0">
              You&apos;ve never had a {product.personalized_rotation ? 'Default product' : 'Personalized product'}{' '}
              before.
              <br />
              Go to the <strong>{product.personalized_rotation ? 'Shop' : 'Personalized Product Builder'}</strong> and
              choose your new default product
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="transparent" outline className="mr-3" size="sm" onClick={toggleSubscriptionSwitch}>
              Cancel
            </Button>
            <Link to={product.personalized_rotation ? '/shop' : '/custom_product'} className="btn btn-success btn-sm">
              Continue
            </Link>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

UpNextComponent.propTypes = {
  getMyProduct: PropTypes.func.isRequired,
  getInTransitionOrders: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  me: PropTypes.object.isRequired,
  product: PropTypes.shape({
    _id: PropTypes.string,
    small_image_src: PropTypes.string,
    image_src: PropTypes.string,
    name: PropTypes.string,
    vendor_name: PropTypes.string,
    vendor_id: PropTypes.string,
    personalized_rotation: PropTypes.bool,
    variants: PropTypes.array,
    dynamicPricing: PropTypes.shape({
      price: PropTypes.number.isRequired,
    }),
  }),
  triggerOrder: PropTypes.func.isRequired,
  inTransition: PropTypes.array,
  userLoading: PropTypes.bool.isRequired,
  pastOrders: PropTypes.array,
  wishlist: PropTypes.array.isRequired,
  getMe: PropTypes.func.isRequired,
  verifyAddress: PropTypes.func.isRequired,
  moveCustomListItem: PropTypes.func.isRequired,
  deleteCustomListItem: PropTypes.func.isRequired,
  updateCustomList: PropTypes.func.isRequired,
  makeBottomless: PropTypes.func.isRequired,
  getPersonalizedProduct: PropTypes.func.isRequired,
  personalizedProduct: PropTypes.object,
  getAttributes: PropTypes.func.isRequired,
  attributes: PropTypes.object,
  updateCustomListElement: PropTypes.func.isRequired,
  getCustomList: PropTypes.func.isRequired,
};

UpNextComponent.defaultProps = {
  inTransition: [],
  pastOrders: [],
};

export const UpNext = connect(
  ({
    user: { product, me, isLoading: userLoading },
    order: { data: pastOrders, inTransition },
    product: { wishlist, personalized, attributes },
  }) => ({
    product,
    me,
    userLoading,
    pastOrders,
    wishlist: wishlist || [],
    inTransition: inTransition || [],
    personalizedProduct: personalized,
    attributes,
  }),
  dispatch => ({
    getMyProduct: id => dispatch(getMyProductAction(id)),
    getInTransitionOrders: () => dispatch(getInTransitionOrdersAction()),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
    triggerOrder: data => dispatch(triggerOrderAction(data)),
    getMe: () => dispatch(getMeAction()),
    verifyAddress: data => dispatch(verifyAddressAction(data)),
    moveCustomListItem: (sourceIndex, destinationIndex) =>
      dispatch(moveCustomListItemAction(sourceIndex, destinationIndex)),
    deleteCustomListItem: index => dispatch(deleteCustomListItemAction(index)),
    updateCustomList: data => dispatch(updateCustomListAction(data)),
    makeBottomless: async (id, data) => {
      const result = await dispatch(makeBottomlessProductAction(id, data));
      await Promise.all([dispatch(getMeAction()), dispatch(getMyProductAction())]);
      return result;
    },
    getPersonalizedProduct: () => dispatch(getPersonalizedProductAction()),
    getAttributes: () => dispatch(getProductAttributesAction()),
    updateCustomListElement: (id, data) => dispatch(updateCustomListElementAction(id, data)),
    getCustomList: () => dispatch(getCustomListAction()),
  })
)(UpNextComponent);
