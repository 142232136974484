import get from 'lodash-es/get';
import set from 'lodash-es/set';
import cloneDeep from 'lodash-es/cloneDeep';

const sha256 = async message => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
  return hashHex;
};

const hashIt = async (payload, paths) => {
  for (const path of paths) {
    const value = get(payload, path);
    if (value) set(payload, path, await sha256(value));
  }
};

const twitter = (event, payload, manifest, retries = 1) => {
  if (!window.twq) {
    if (retries > 4) {
      // eslint-disable-next-line no-console
      console.info(`Twitter is not enabled`);
      return;
    }
    setTimeout(() => {
      twitter(event, payload, manifest, retries + 1);
    }, 50 * retries);
    return;
  }

  const { twq } = window;

  const product = get(payload, 'checkout.product.product');
  const productDetails = product
    ? {
        content_ids: [product._id],
        contents_name: product.name,
        content_type: 'Product',
        currency: 'USD',
        num_items: 1,
        value: getValue(product, manifest),
      }
    : {};

  if (event === EVENT_CHECKOUT_CREATED) {
    twq('track', 'CHECKOUT_CREATED', {
      ...productDetails,
    });
  }

  if (event === EVENT_ADDED_ADDRESS) {
    twq('track', 'InitiateCheckout', {
      ...productDetails,
    });
  }

  if (event === EVENT_CHOSE_PRODUCT) {
    twq('track', 'AddToCart', {
      ...productDetails,
    });
  }

  if (event === EVENT_ADDED_CARD) {
    twq('track', 'AddPaymentInfo', {
      ...productDetails,
    });
  }

  if (event === EVENT_CHECKOUT_COMPLETE) {
    twq('track', 'Purchase', {
      ...productDetails,
    });
  }
};

const reddit = (event, payload, manifest, retries = 1) => {
  if (!window.rdt) {
    if (retries > 4) {
      // eslint-disable-next-line no-console
      console.info(`RedditPixel is not enabled`);
      return;
    }
    setTimeout(() => {
      reddit(event, payload, manifest, retries + 1);
    }, 50 * retries);
    return;
  }

  const { rdt } = window;

  if (event === EVENT_PAGE_VISIT) {
    rdt('track', 'PageVisit');
  }

  if (event === EVENT_VIEW_CONTENT) {
    rdt('track', 'ViewContent');
  }

  if (event === EVENT_ADDED_ADDRESS) {
    rdt('track', 'Lead');
  }

  if (event === EVENT_CHOSE_PRODUCT) {
    rdt('track', 'AddToCart');
  }

  if (event === EVENT_CHECKOUT_COMPLETE) {
    rdt('track', 'Purchase');
  }
};

const facebook = (event, payload, manifest, retries = 1) => {
  const payloadCopy = cloneDeep(payload);

  if (!window.fbq) {
    if (retries > 4) {
      // eslint-disable-next-line no-console
      console.info(`FacebookPixel is not enabled`);
      return;
    }
    setTimeout(() => {
      facebook(event, payloadCopy, manifest, retries + 1);
    }, 50 * retries);
    return;
  }

  const { fbq } = window;

  if (
    [
      EVENT_CHECKOUT_CREATED,
      EVENT_ADDED_ADDRESS,
      EVENT_CHOSE_PRODUCT,
      EVENT_ADDED_CARD,
      EVENT_CHECKOUT_COMPLETE,
    ].includes(event)
  ) {
    const paths = [
      'checkout.email',
      'checkout.first_name',
      'checkout.last_name',
      'checkout.verifiedAddress.street1',
      'checkout.verifiedAddress.street2',
      'checkout.verifiedAddress.city',
      'checkout.verifiedAddress.zip',
      'checkout.verifiedAddress.state',
    ];

    hashIt(payloadCopy, paths);

    const product = get(payloadCopy, 'checkout.product.product');
    const personalized = get(payloadCopy, 'checkout.personalized');

    const productDetails = product
      ? {
          content_ids: [product._id],
          contents_name: product.name,
          content_type: 'Product',
          currency: 'USD',
          num_items: 1,
          value: getValue(personalized),
        }
      : {};

    if (event === EVENT_CHECKOUT_CREATED) {
      fbq('trackCustom', 'CHECKOUT_CREATED', {
        ...productDetails,
      });
    }

    if (event === EVENT_ADDED_ADDRESS) {
      fbq('track', 'InitiateCheckout', {
        ...productDetails,
      });
    }

    if (event === EVENT_CHOSE_PRODUCT) {
      fbq('track', 'AddToCart', {
        ...productDetails,
      });
    }

    if (event === EVENT_ADDED_CARD) {
      fbq('track', 'AddPaymentInfo', {
        ...productDetails,
      });
    }

    if (event === EVENT_CHECKOUT_COMPLETE) {
      fbq('track', 'Purchase', {
        ...productDetails,
      });
    }
  }

  fbq('trackCustom', event, payloadCopy);
};

const getValue = personalized => {
  if (personalized) {
    if (personalized.price_type === 'basic') {
      return 60;
    }

    if (personalized.price_type === 'premium') {
      return 80;
    }
  }

  return 70;
};

const google = (event, payload) => {
  if (!window.gtag) {
    // eslint-disable-next-line no-console
    console.info('GoogleAnalytics is not enabled');
    return;
  }

  const { gtag } = window;

  const product = get(payload, 'checkout.product.product');
  const personalized = get(payload, 'checkout.personalized');

  const productDetails = product
    ? {
        transaction_id: get(payload, 'checkout._id'),
        value: getValue(personalized),
        currency: 'USD',
        items: [
          {
            product,
          },
        ],
      }
    : {};

  if (event === EVENT_CHECKOUT_CREATED) {
    gtag('event', 'begin_checkout');
  }

  if (event === EVENT_ADDED_ADDRESS) {
    gtag('event', EVENT_ADDED_ADDRESS);
  }

  if (event === EVENT_CHOSE_PRODUCT) {
    gtag('event', 'add_to_cart', {
      ...productDetails,
    });
  }

  if (event === EVENT_ADDED_CARD) {
    gtag('event', 'add_payment_info', {
      ...productDetails,
    });
  }

  if (event === EVENT_CHECKOUT_COMPLETE) {
    gtag('event', 'purchase', {
      ...productDetails,
    });
  }

  if (event === EVENT_REFERRAL_CLICK) {
    gtag('event', EVENT_REFERRAL_CLICK, payload);
  }

  if (event === EVENT_APPLE_PAY) {
    gtag('event', EVENT_APPLE_PAY, payload);
  }

  if (event === EVENT_LIST_SIGNUP) {
    gtag('event', EVENT_LIST_SIGNUP, payload);
  }

  if (event === EVENT_GIFT_BOUGHT) {
    gtag('event', EVENT_GIFT_BOUGHT, payload);
  }

  if (event === EVENT_PRODUCT_VIEW) {
    const variant = payload.variants.find(variant => variant.available);

    gtag('event', 'view_item', {
      items: [
        {
          item_id: payload._id,
          item_name: payload.name,
          currency: 'USD',
          item_brand: payload.vendor_name,
          item_category: payload.category?.name,
          item_variant: variant._id,
          price: variant?.price,
          quantity: 1,
        },
      ],
    });
  }

  if (event === EVENT_CTA_CLICK) {
    gtag('event', 'cta_click', { event_label: payload.position });
  }

  if (event === EVENT_SUBMIT_GROCERY_EMAIL) {
    gtag('event', 'generate_lead', {
      event_category: 'grocery',
      event_label: 'email',
      event_value: payload,
    });
  }

  if (event === EVENT_LOCAL_CATALOG_QR) {
    gtag('event', 'local_catalog', {
      event_category: 'grocery',
      event_label: payload.userId,
    });
  }
};

const hotjar = event => {
  if (!('hj' in window)) {
    // eslint-disable-next-line no-console
    console.info('Hotjar tracking is not enabled');
    return;
  }

  switch (event) {
    case EVENT_ADD_TO_CUSTOM_LIST:
      window.hj('tagRecording', [EVENT_ADD_TO_CUSTOM_LIST, 'CUSTOM_LIST']);
      break;
    case EVENT_REMOVE_FROM_CUSTOM_LIST:
      window.hj('tagRecording', [EVENT_REMOVE_FROM_CUSTOM_LIST, 'CUSTOM_LIST']);
      break;
    case EVENT_REORDER_CUSTOM_LIST:
      window.hj('tagRecording', [EVENT_REORDER_CUSTOM_LIST, 'CUSTOM_LIST']);
      break;
    default:
      break;
  }
};

const drip = (event, payload, manifest) => {
  if (!window._dcq) {
    // eslint-disable-next-line no-console
    console.info('Drip tracking is not enabled');
    return;
  }

  switch (event) {
    case EVENT_CTA_CLICK:
      window._dcq.push(['track', event]);
      break;
    case EVENT_PRODUCT_VIEW:
      // eslint-disable-next-line no-case-declarations
      const variant = payload.variants.find(variant => variant.available);

      window._dcq.push([
        'track',
        'Viewed a Product',
        {
          product_id: payload._id,
          product_variant_id: variant?._id,
          name: payload.name,
          categories: payload.category?.name,
          price: variant?.price,
          currency: 'USD',
          product_url: window.location.href,
          image_url: payload.image_src,
          brand: payload.vendor_name,
          source: 'customer-app',
        },
      ]);
      break;
    case EVENT_CHECKOUT_CREATED:
      window._dcq.push(['track', 'begin_checkout']);
      break;
    case EVENT_CHECKOUT_COMPLETE:
      // eslint-disable-next-line no-case-declarations
      const product = get(payload, 'checkout.product.product');

      window._dcq.push(['track', 'Purchase', { value: getValue(product, manifest) }]);
      break;
    default:
      break;
  }
};

export const EVENT_CHECKOUT_CREATED = 'EVENT_CHECKOUT_CREATED';
export const EVENT_CHOSE_PRODUCT = 'EVENT_CHOSE_PRODUCT';
export const EVENT_ADDED_ADDRESS = 'EVENT_ADDED_ADDRESS';
export const EVENT_ADDED_CARD = 'EVENT_ADDED_CARD';
export const EVENT_CHECKOUT_COMPLETE = 'EVENT_CHECKOUT_COMPLETE';
export const EVENT_VIEW_CONTENT = 'EVENT_VIEW_CONTENT';
export const EVENT_PAGE_VISIT = 'EVENT_PAGE_VISIT';
export const EVENT_REFERRAL_CLICK = 'EVENT_REFERRAL_CLICK';
export const EVENT_APPLE_PAY = 'EVENT_APPLE_PAY';
export const EVENT_LIST_SIGNUP = 'EVENT_LIST_SIGNUP';
export const EVENT_FILTER_CHECKOUT_STEP = 'FILTER_CHECKOUT_STEP';
export const EVENT_GIFT_BOUGHT = 'gift_bought';
export const EVENT_ADD_TO_CUSTOM_LIST = 'EVENT_ADD_TO_CUSTOM_LIST';
export const EVENT_REMOVE_FROM_CUSTOM_LIST = 'EVENT_REMOVE_FROM_CUSTOM_LIST';
export const EVENT_REORDER_CUSTOM_LIST = 'EVENT_REORDER_CUSTOM_LIST';
export const EVENT_PRODUCT_VIEW = 'EVENT_PRODUCT_VIEW';
export const EVENT_CTA_CLICK = 'EVENT_CTA_CLICK';
export const EVENT_SUBMIT_GROCERY_EMAIL = 'EVENT_SUBMIT_GROCERY_EMAIL';
export const EVENT_LOCAL_CATALOG_QR = 'EVENT_LOCAL_CATALOG_QR';

export const pageView = () => {
  reddit(EVENT_PAGE_VISIT);
};

export const syncCheckout = (checkout, manifest) => {
  if (!checkout) {
    return;
  }

  switch (checkout.status) {
    case 'created':
      trackEvent(EVENT_CHECKOUT_CREATED);
      break;
    case 'added_address':
      trackEvent(EVENT_ADDED_ADDRESS, { checkout }, manifest);
      break;
    case 'chose_product':
      trackEvent(EVENT_CHOSE_PRODUCT, { checkout }, manifest);
      break;
    case 'stripe_customer_created':
      trackEvent(EVENT_ADDED_CARD, { checkout }, manifest);
      break;
    default:
      break;
  }
};

const call = fn => {
  try {
    fn();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

/**
 * @param event
 * @param payload
 * @param manifest
 */
export const trackEvent = (event, payload, manifest = {}) => {
  call(() => facebook(event, payload, manifest));
  call(() => google(event, payload, manifest));
  call(() => reddit(event, payload, manifest));
  call(() => twitter(event, payload, manifest));
  call(() => hotjar(event, payload, manifest));
  call(() => drip(event, payload, manifest));
};
