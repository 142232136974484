/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

export const useDataEffect = (fn, setter = () => {}, arg = undefined, errorValue = {}, deps = []) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    (async () => {
      try {
        setFetching(true);
        const { payload, error } = await fn(arg);

        if (error) {
          setError(payload);
          setData(errorValue);
          setter(errorValue, payload);
        } else {
          setData(payload);
          setter(payload);
        }
      } catch (e) {
        setError(e);
        setData(errorValue);
        setter(errorValue);
      }
      setFetching(false);
    })();
  }, [fn, ...deps]);

  return { data, error, isFetching };
};

export const useConditionalDataEffect = (condition, fn, setter = () => {}, arg = undefined, errorValue = {}) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    if (!condition || isFetching) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        const { payload, error } = await fn(arg);

        if (error) {
          setError(payload);
          setData(errorValue);
          setter(errorValue);
        } else {
          setData(payload);
          setter(payload);
        }
      } catch (e) {
        setError(e);
        setData(errorValue);
        setter(errorValue);
      }
      setFetching(false);
    })();
  }, [fn, condition]);

  return { data, error, isFetching };
};
