import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { Row, Col, Card, Container } from 'reactstrap';
import classNames from 'classnames';
import { Image } from '@bottomless/common/components';
import { QuizHeader } from './QuizHeader';
import { QuestionText } from './QuestionText';

const answers = [
  {
    name: '12oz',
    value: 'size:12',
    icon: '/assets/coffee-bag-size.png',
    smallImage: true,
    explanation: 'It takes me at least a week to use  a 12oz bag',
  },
  {
    name: '32oz',
    value: 'size:32',
    icon: '/assets/coffee-bag-size.png',
    explanation: 'It takes me less than a week to use a 12oz bag',
  },
];

export const Question5 = ({ index, total, onAnswer, answers: quizAnswers, isMobile }) => {
  const [{ gift }] = useCookies(['gift']);

  const filteredAnswers = useMemo(() => (gift ? answers.filter(answer => answer.value === 'size:12') : answers), [
    gift,
  ]);

  return (
    <>
      <QuizHeader index={index - 1} total={total - 1} quizAnswers={quizAnswers} />
      <QuestionText
        index={index - 1}
        total={total - 1}
        title="Bag Size"
        subtitle="How fast do you go through coffee?"
      />
      <Container className="mt-5">
        <Row className="justify-content-center">
          {filteredAnswers.map((answer, i) => (
            <Col key={i} xs="6" sm="6" lg="3" className="mb-3">
              <Card
                body
                tag="button"
                className="d-flex flex-md-row flex-column align-items-center justify-content-center w-100 answer"
                onClick={() => onAnswer(answer)}
              >
                <Image src={answer.icon} width={answer.smallImage ? '55' : '70'} alt={`Answer - ${answer.name}`} />
                <p
                  className={classNames('mb-0', {
                    'ml-4': !isMobile,
                    'mt-2': isMobile,
                  })}
                >
                  {answer.name}
                </p>
              </Card>
              <p className="text-center font-italic mt-2 text-secondary">{answer.explanation}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

Question5.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onAnswer: PropTypes.func.isRequired,
  answers: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
};

Question5.sizeRelated = true;
