import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import moment from 'moment';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { DataLoading } from '@bottomless/common/components';
import { stripTags } from '@bottomless/common/utils';
import { useOnce, useQueryString, useToggle, useDataEffect, useConditionalDataEffect } from '@bottomless/common/hooks';
import { withMetaTags } from '../../../../components/MetaTags/MetaTags';
import { withRichProductMeta } from '../../../../components/MetaTags/RichProductMeta';
import { addAlertAction, addToastAction, changeScaleAction } from '@bottomless/common/store';
import { GetStartedModal } from '../../../../components/GetStartedModal/GetStartedModal';
import { IsLoggedIn } from '../../../../guards/is-logged.guard';
import { Panel } from '../../../../layouts/Panel/Panel';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { Simple } from '../../../../layouts/Simple/Simple';
import { ApplePayButton } from '../../../ProductCheckout/components';
import { AccountsModal } from '../../../../components/AccountsModal';
import {
  getCheckoutAction,
  getPricingRuleAction,
  makeBottomlessProductAction,
  newScaleCheckoutAction,
  saveProductSelectionAction,
  setShipmentAction,
  triggerOrderAction,
} from '../../../../store';
import { getNextBusinessDay, getNextBusinessDayOrToday } from '../../../../utils/dates';
import { EVENT_PRODUCT_VIEW, trackEvent } from '../../../../utils/tracker';
import { BasicProductInfo, ProductDescription } from './components';
import './GenericProductDetails.scss';
import OneOffOrderModal from '../../../Shop/components/OneOffOrderModal';
import { CheckoutSource } from '@bottomless/common/constants';

const PanelWrapper = deps => (
  <Panel className="panel-layout-product-details">
    <PanelPage {...deps} />
  </Panel>
);

const GenericProductDetailsComponent = ({
  product,
  getPricingRule,
  getStarted,
  history,
  isLoading,
  match,
  me,
  makeBottomlessProduct,
  addToast,
  noEmail,
  isGuest,
  productSelection,
  saveProductSelection,
  hideDetails,
  setShipment,
  changeScale,
  newScaleCheckout,
  getCheckout,
  triggerOrder,
  addAlert,
}) => {
  const location = useLocation();
  const {
    params: { back },
  } = useQueryString();
  const [{ checkout, phone }] = useCookies(['checkout', 'phone']);
  const [isModalOpen, setModalOpen] = useState(false);
  const [productData, setProductData] = useState(null);
  const [pricingRule, setPricingRule] = useState(null);
  const [currentStock, setCurrentStock] = useState(null);
  const variant = useMemo(() => product?.variants && product.variants[0], [product]);
  const [showAccountsModal, toggleAccountsModal] = useToggle();
  const [matchedAccounts, setMatchedAccounts] = useState();
  const [checkoutData, setCheckoutData] = useState();

  const [isOneOffModalOpen, toggleOneOffModal] = useToggle();
  const [pendingOneOffOrder, setPendingOneOffOrder] = useState(null);
  const [loadingOneOff, setLoadingOneOff] = useState(false);

  const query = qs.parse(location?.search);
  const isFromVendorLanding = query?.vendor;

  useOnce(() => trackEvent(EVENT_PRODUCT_VIEW, product), [product]);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const outOfStock = useMemo(() => product && product.status === 'archived', [product]);

  const Wrapper = useMemo(() => {
    if (!me) {
      return Simple;
    }

    if (!product) {
      return me ? PanelWrapper : Simple;
    }

    const myVendorId = me.vendor_id?._id || me.vendor_id;
    const productVendorId = product.vendor_id?._id || product.vendor_id;

    return myVendorId && myVendorId !== productVendorId ? props => <Simple {...props} forceNoVendor /> : PanelWrapper;
  }, [me, product]);

  useEffect(() => {
    if (me && isGuest) {
      history.push('/profile');
    }
  }, [me, isGuest, history]);

  useDataEffect(getPricingRule, setPricingRule, match.path);
  useConditionalDataEffect(!!checkout, getCheckout, setCheckoutData, checkout);

  const onOneOffOrder = useCallback(
    async (product, { variant, ...data }) => {
      const { _id, image_src, variants, name, vendor_id } = product || {};
      setPendingOneOffOrder({
        product: { product: _id, variant, imageUrl: image_src, allVariants: variants, name, vendor: vendor_id },
        source: 'user_one_off',
        ...data,
      });
      setTimeout(toggleOneOffModal, 0);
    },
    [setPendingOneOffOrder, toggleOneOffModal]
  );

  const triggerOneOffOrder = useCallback(async () => {
    setLoadingOneOff(true);

    try {
      await triggerOrder(pendingOneOffOrder);
      addAlert('Order has been successfully generated', 'success');
    } catch (error) {
      addAlert('Could not generate order. Please try again later', 'danger');
    }
    setLoadingOneOff(false);
    toggleOneOffModal();
  }, [toggleOneOffModal, triggerOrder, pendingOneOffOrder, addAlert]);

  const onChooseProductGuest = async (product, { variant, ...data }) => {
    setProductData({ product: { product: product._id, variant }, vendorId: product?.vendor_id?._id, ...data });

    if (me && checkout) {
      const matched = me.accounts.filter(account => account.product.product.category._id === product?.category?._id);
      setMatchedAccounts(matched);

      if (matched?.length > 1) {
        toggleAccountsModal();
        return;
      }
    }

    if (checkout || noEmail) {
      const { payload } = await onSubmit({ product: { product: product._id, variant }, ...data });
      return onSuccess(payload);
    }

    toggleModal();
  };

  const makeBottomless = useCallback(
    async (id, data) => {
      if (checkout) {
        const { payload: getStartedPayload } = await getStarted({
          product: { product: product?._id, variant: data.variant },
          ...data,
        });

        if (getStartedPayload.source === CheckoutSource.Shopify) {
          return history.push(
            `/get_started/${getStartedPayload.checkoutId}?source=${getStartedPayload.source}&vendorId=${getStartedPayload.vendorId}`
          );
        }

        return history.push(`/get_started/${getStartedPayload.checkoutId}`);
      }

      const result = await makeBottomlessProduct(id, data);

      if (result?.error && result?.payload?.status === 401) {
        await newScaleCheckout();
        const { payload: getStartedPayload } = await getStarted({
          product: { product: product?._id, variant: data.variant },
          ...data,
        });

        return history.push(`/get_started/${getStartedPayload.checkoutId}`);
      }

      if (result?.payload?.userId && result.payload.userId !== me._id) {
        await changeScale({ account: result.payload.userId });
      }

      return result;
    },
    [makeBottomlessProduct, changeScale, me, history, newScaleCheckout, product, getStarted, checkout]
  );

  const onChooseProductUser = (product, { variant, ...data }) => {
    const matched = me.accounts.filter(account => account.product.product.category._id === product?.category?._id);
    setMatchedAccounts(matched);

    if (matched?.length > 1) {
      setProductData({ product: { product: product._id, variant }, vendorId: product?.vendor_id?._id, ...data });
      toggleAccountsModal();
      return;
    }

    return makeBottomless(product._id, { variant, ...data });
  };

  const onChooseProduct = me ? onChooseProductUser : onChooseProductGuest;

  const onChooseProductSuccess = params => {
    if (!params) {
      return;
    }
    const { addToList } = params;
    addToast('Product has been successfully saved');
    if (addToList) {
      const back = new URLSearchParams(window.location.search).get('back');
      history.push(back || '/shop');
    } else {
      history.push('/profile');
    }
  };

  const onSubmit = useCallback(
    async (...data) => {
      const email = data && data[0].email;
      const result = await getStarted({
        phone,
        ...productData,
        ...data[0],
        email,
        guest: Boolean(isGuest),
        path: isGuest ? match.path : undefined,
      });

      const { event: { dateOverride } = {} } = currentStock || {};

      if (dateOverride === '0') {
        await setShipment(result.payload.checkoutId, { shipment_date: undefined, dateOverride });
      }

      if (dateOverride && dateOverride !== '0') {
        const nextBusinessDayOrToday = getNextBusinessDayOrToday();

        const shipmentDate =
          dateOverride === 'nextBusiness'
            ? getNextBusinessDay()
            : new Date(nextBusinessDayOrToday.setDate(nextBusinessDayOrToday.getDate() + 7 * Number(dateOverride)));

        await setShipment(result.payload.checkoutId, {
          shipment_date: moment(shipmentDate).format('YYYY-MM-DD'),
          dateOverride,
        });
      }

      return result;
    },
    [getStarted, phone, productData, isGuest, match.path, currentStock, setShipment]
  );

  const onSuccess = ({ checkoutId }) => {
    history.push(`/get_started/${checkoutId}`);
  };

  const shopPath = me ? '/shop' : '/public_shop';

  const isDifferentVendor = useMemo(
    () => !!me && !me.accounts.find(account => !account.vendor_id || account.vendor_id === product?.vendor_id._id),
    [me, product]
  );

  const computedPricingRule = useMemo(() => {
    if (checkoutData) {
      return checkoutData.pricing_rule;
    }
    if (me && !isDifferentVendor) {
      return me.pricing_rule;
    }

    return pricingRule;
  }, [me, pricingRule, checkoutData, isDifferentVendor]);

  const onBack = useCallback(() => (isFromVendorLanding ? history.goBack() : history.push(back || shopPath)), [
    history,
    isFromVendorLanding,
    shopPath,
    back,
  ]);

  const renderContent = () => {
    if (!product) {
      return <DataLoading count={product ? 1 : 0} isLoading={isLoading} />;
    } else if (product && (me || !product.hidden || checkoutData?.source === 'shopify')) {
      return (
        <>
          <div className="page-generic-product-details">
            <BasicProductInfo
              product={product}
              pathname={shopPath}
              me={me}
              pricingRule={computedPricingRule}
              onChooseProduct={onChooseProduct}
              onChooseSuccess={onChooseProductSuccess}
              makeBottomlessText="Buy Product"
              outOfStock={outOfStock}
              productSelection={productSelection}
              saveProductSelection={saveProductSelection}
              hideDetails={hideDetails}
              expressCheckoutButton={
                <ApplePayButton pricingRule={computedPricingRule} product={product} variant={variant} showSeperator />
              }
              isFirstBag={checkout || !me}
              getCheckout={getCheckout}
              onOneOffOrder={onOneOffOrder}
            />
            <div className="product-more-info">
              <hr />
              <ProductDescription me={me} product={product} />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <IsLoggedIn>
      <Wrapper back={onBack} withBackButton>
        <Container className="pt-2 pb-4 page-product-details">{renderContent()}</Container>
        <GetStartedModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          getStarted={onSubmit}
          onSignUp={onSuccess}
          pricingRule={computedPricingRule}
          headingText="Start your subscription by usage"
          isFromVendorLanding={!!isFromVendorLanding}
          setCurrentStock={setCurrentStock}
          dateOverride={currentStock?.event?.dateOverride}
        />
        <OneOffOrderModal
          me={me}
          isOneOffModalOpen={isOneOffModalOpen}
          toggleOneOffModal={toggleOneOffModal}
          cancelOneOffOrder={toggleOneOffModal}
          loadingOneOff={loadingOneOff}
          triggerOneOffOrder={triggerOneOffOrder}
          setPendingOneOffOrder={setPendingOneOffOrder}
          pendingOneOffOrder={pendingOneOffOrder}
          pathname={location.pathname}
          pricingRule={computedPricingRule}
          isFirstBag={!me}
        />
        {showAccountsModal && (
          <AccountsModal
            me={me}
            isOpen={showAccountsModal}
            toggle={toggleAccountsModal}
            matchedAccounts={matchedAccounts}
            productData={productData}
            makeBottomlessProduct={makeBottomlessProduct}
          />
        )}
      </Wrapper>
    </IsLoggedIn>
  );
};

GenericProductDetailsComponent.propTypes = {
  getStarted: PropTypes.func.isRequired,
  getPricingRule: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  makeBottomlessProduct: PropTypes.func.isRequired,
  me: PropTypes.object,
  product: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string.isRequired,
    }).isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  noEmail: PropTypes.bool,
  isGuest: PropTypes.bool,
  saveProductSelection: PropTypes.func.isRequired,
  productSelection: PropTypes.object,
  hideDetails: PropTypes.bool,
  addAlert: PropTypes.func.isRequired,
  pricingRule: PropTypes.object,
  setShipment: PropTypes.func.isRequired,
  changeScale: PropTypes.func.isRequired,
  newScaleCheckout: PropTypes.func.isRequired,
  getCheckout: PropTypes.func.isRequired,
  triggerOrder: PropTypes.func.isRequired,
};

export const GenericProductDetails = connect(
  (
    { order, product: { data, isLoading, productSelection }, user: { me, pricingRule } },
    {
      match: {
        params: { slug },
      },
    }
  ) => ({
    product: data.find(product => product.slug === slug || product._id === slug),
    orders: order.data ? order.data : [],
    isLoading,
    me,
    productSelection,
    pricingRule,
  }),
  dispatch => ({
    getPricingRule: path => dispatch(getPricingRuleAction(path)),
    makeBottomlessProduct: (id, data) => dispatch(makeBottomlessProductAction(id, data)),
    addToast: message => dispatch(addToastAction(message)),
    saveProductSelection: data => dispatch(saveProductSelectionAction(data)),
    addAlert: (message, type) => dispatch(addAlertAction(message, type)),
    setShipment: (id, data) => dispatch(setShipmentAction(id, data)),
    changeScale: data => dispatch(changeScaleAction(data)),
    getCheckout: id => dispatch(getCheckoutAction(id)),
    newScaleCheckout: data => dispatch(newScaleCheckoutAction(data)),
    triggerOrder: data => dispatch(triggerOrderAction(data)),
  })
)(
  withMetaTags({
    title: ({ product }) => (product ? `Bottomless: ${product.name} - ${product.vendor_name}` : undefined),
    description: ({ product }) =>
      product
        ? `${product.name} from ${product.vendor_name} on Bottomless. Pick a ${product.vendor_name} product to start your subscription and re-order at the perfect time, everytime!`
        : undefined,
    image: ({ product }) => (product ? (product.small_image_src || '').replace(/\?.*$/, '') : undefined),
  })(
    withRichProductMeta({
      title: ({ product }) => (product ? product.name : undefined),
      image: ({ product }) => (product ? (product.small_image_src || '').replace(/\?.*$/, '') : undefined),
      vendor: ({ product }) => (product ? product.vendor_name : undefined),
      description: ({ product }) => (product ? stripTags(product.description) : undefined),
      price: ({ product }) =>
        product
          ? Math.min(...product.variants.filter(variant => variant.available).map(availVar => availVar.price))
          : undefined,
      availability: ({ product }) => !!(product ? product.variants.find(variant => variant.available) : undefined),
    })(GenericProductDetailsComponent)
  )
);
