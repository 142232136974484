import React from 'react';
import PropTypes from 'prop-types';

export const GrindWarningModalBody = ({ values, me, grinds }) => {
  const selectedGrind = grinds?.find(grind => grind._id === values.grind);

  return (
    <div>
      <div className="mt-2">
        <div className="d-flex mt-3 justify-space-between">
          <div className="font-weight-bold">Default:</div>
          <div className="text-right">{me.grind?.name}</div>
        </div>
        <div className="d-flex mt-3 justify-space-between">
          <div className="font-weight-bold">Selected:</div>
          <div className="text-right">{selectedGrind.name}</div>
        </div>
      </div>
      <div className="mt-4">Do you wish to proceed with {selectedGrind.name}?</div>
    </div>
  );
};

GrindWarningModalBody.propTypes = {
  values: PropTypes.shape({
    grind: PropTypes.string,
  }).isRequired,
  grinds: PropTypes.array,
  me: PropTypes.object,
};
