import 'chartjs-plugin-annotation';
import PropTypes from 'prop-types';
import React from 'react';
import { Line } from 'react-chartjs-2';

export const MiniGraph = ({ stats, options, borderColor = '#0632C2', backgroundColor = '#E4F5FF' }) => {
  const data = stats
    .filter(record => record.adj_weight > -10 && record.adj_weight < 960) // 60 pounds limit
    .map(record => ({
      x: new Date(record.timestamp),
      y: record.adj_weight.toFixed(1),
    }));

  const defaults = {
    animation: { duration: 0 },
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      xAxes: [{ type: 'time', time: { unit: 'day' }, ticks: { display: false }, gridLines: { display: false } }],
      yAxes: [{ ticks: { display: false, suggestedMax: 12, suggestedMin: 0 }, gridLines: { display: false } }],
    },
    tooltips: { enabled: false },
    elements: { line: { tension: 0 } }, // Bezier Curve parameter,
    layout: { padding: { bottom: -10, left: -10 } },
  };

  return (
    <div className="mini-graph">
      <Line
        data={{
          datasets: [{ data, pointRadius: 0, borderColor, backgroundColor }],
        }}
        options={{ ...defaults, ...options }}
        height={60}
      />
    </div>
  );
};

MiniGraph.defaultProps = {
  datasets: [],
  options: {},
};

MiniGraph.propTypes = {
  stats: PropTypes.array.isRequired,
  datasets: PropTypes.array,
  options: PropTypes.object,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};
