import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const cards = {
  visa: 'cc-visa',
  mastercard: 'cc-mastercard',
  diners_club: 'cc-diners-club',
  discover: 'cc-discover',
  jcb: 'cc-jcb',
};

const defaultIcon = 'credit-card';

export const PaymentIcon = ({ id }) => {
  const icon = useMemo(() => cards[id] || defaultIcon, [id]);

  return <i className={`fa fa-${icon}`} aria-hidden="true"></i>;
};

PaymentIcon.propTypes = {
  id: PropTypes.string.isRequired,
};
