import React, { memo } from 'react';
import { Row, Col, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { Image } from '@bottomless/common/components';

const FeaturesSectionComponent = ({ vendor, hasCustomStyles }) => {
  return (
    <section className="features-section">
      <h1 className="text-center mb-5 pb-4">A New Subscription Experience</h1>
      <Container>
        <Row>
          <Col xs="12" sm="12" lg="6" className="d-flex justify-content-center mb-lg-0 mb-5">
            <Image
              src={
                vendor?.modal_image
                  ? `${vendor.modal_image}?auto=compress,format`
                  : 'https://bottomless.imgix.net/vendor-landing/bottomless-scale.png?auto=compress,format'
              }
              alt="bottomless scale"
            />
          </Col>
          <Col xs="12" sm="12" lg="6" className="d-flex flex-column justify-content-around">
            <div className="d-flex flex-column align-items-center align-items-lg-start">
              <div
                className="feature-icon"
                style={hasCustomStyles ? { backgroundColor: vendor?.custom_styles?.brand_color } : {}}
              >
                <Image
                  src="https://bottomless.imgix.net/vendor-landing/restock.png?auto=compress,format"
                  width="32"
                  alt="restock"
                />
              </div>
              <div>
                <h4 className="text-center text-lg-left">Automatic Restocking at the Perfect Time</h4>
                <p className="description text-secondary text-center text-lg-left">
                  We track your consumption via the scale and reorder at the perfect time unlike traditional
                  subscriptions that order at regular intervals.
                </p>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center align-items-lg-start">
              <div
                className="feature-icon"
                style={hasCustomStyles ? { backgroundColor: vendor?.custom_styles?.brand_color } : {}}
              >
                <Image
                  src="https://bottomless.imgix.net/vendor-landing/free-shipping.png?auto=compress,format"
                  width="32"
                  alt="restock"
                />
              </div>
              <div>
                <h4 className="text-center text-lg-left">Free Shipping</h4>
                <p className="description text-secondary text-center text-lg-left">
                  There are no delivery charges. There are no markups.
                </p>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center align-items-lg-start">
              <div
                className="feature-icon"
                style={hasCustomStyles ? { backgroundColor: vendor?.custom_styles?.brand_color } : {}}
              >
                <Image
                  src="https://bottomless.imgix.net/vendor-landing/best-price.png?auto=compress,format"
                  width="32"
                  alt="restock"
                />
              </div>
              <div>
                <h4 className="text-center text-lg-left">You Have Control</h4>
                <p className="description text-secondary text-center text-lg-left">
                  You&apos;ll be alerted at least 12 hours before any order is charged or shipped. You can update the
                  product linked to your account or cancel at any time.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

FeaturesSectionComponent.propTypes = {
  vendor: PropTypes.object.isRequired,
  hasCustomStyles: PropTypes.bool.isRequired,
};

export const FeaturesSection = memo(FeaturesSectionComponent);
