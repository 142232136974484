import { usePersonalizedAttributes, useToggle } from '@bottomless/common/src/hooks';
import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Tooltip } from 'reactstrap';

export const PersonalizedProduct = ({ index, personalized, grind, size }) => {
  const [isOpen, toggle] = useToggle();
  const isMobile = useMediaQuery({ maxDeviceWidth: 767 });
  const { roast, attributes } = usePersonalizedAttributes(personalized);

  return (
    <div className="d-flex mb-1 flex-column align-items-center product-avatar font-weight-bolder">
      <div className="product-avatar-image-wrapper">
        <div className="d-none my-product-number-overlay">{index}</div>
        <div className="my-product-image d-flex align-items-center justify-content-center text-lg">
          <span aria-label="Sparkle" role="img">
            ✨
          </span>
        </div>
      </div>
      <div className="up-next-product-name text-center">
        <div className="up-next-product-name">
          <div className="text-dark d-flex align-items-center">
            {roast}
            {attributes.length > 0 && (
              <>
                <span id="personalized-info" className="information-badge ml-2 font-weight-normal">
                  i
                </span>
                <Tooltip
                  placement={isMobile ? 'top' : 'right'}
                  target="personalized-info"
                  isOpen={isOpen}
                  toggle={toggle}
                >
                  {attributes.map(({ label, value }) => (
                    <div key={label}>
                      {label}: {value}
                    </div>
                  ))}
                </Tooltip>
              </>
            )}
          </div>
        </div>
        <div className="text-secondary up-next-product-name-vendor">
          <div className="text-secondary">
            {size}oz, {grind}
          </div>
        </div>
      </div>
    </div>
  );
};

PersonalizedProduct.propTypes = {
  personalized: PropTypes.object.isRequired,
  grind: PropTypes.string,
  size: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
