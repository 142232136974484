import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, ModalFooter } from 'reactstrap';
import { Loader } from 'react-feather';
import { Address, DateFormat, Form, DateDropdown, Image, VariantPrice } from '@bottomless/common/components';
import { utcDate } from '@bottomless/common/utils';
import { useIsGuest } from '../../../hooks/useIsGuest.hook';
import { getNextFulfillmentDate } from '../../../utils/dates';
import '../Shop.scss';

const ONE_DAY_IN_MILLISECONDS = 86400000;

const overrideDateSchema = Yup.object().shape({
  override_fulfillment_date: Yup.date().required('This field is required'),
});

const OneOffOrderModal = ({
  me,
  isOneOffModalOpen,
  toggleOneOffModal,
  cancelOneOffOrder,
  loadingOneOff,
  triggerOneOffOrder,
  setPendingOneOffOrder,
  pendingOneOffOrder,
  pricingRule,
  isFirstBag,
  grinds,
}) => {
  const isGuest = useIsGuest(me);
  const [overrideDate, setOverrideDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { product: { allVariants, imageUrl, name, variant: variantId, vendor } = {} } = pendingOneOffOrder || {};
  const variantDetails = allVariants && allVariants.filter(v => variantId === v._id).shift();
  const { name: vendorName, exclude_fulfilment_dates } = vendor || {};
  const { override_fulfillment_date: overrideFulfillmentDate } = overrideDate || {};

  const grind = useMemo(() => (grinds || []).find(grind => grind._id === pendingOneOffOrder?.grind), [
    grinds,
    pendingOneOffOrder,
  ]);

  const excludedDatesForFulfillment = exclude_fulfilment_dates
    ? exclude_fulfilment_dates.map(date => utcDate(date))
    : [];

  const today = new Date();
  const tomorrow = today.setDate(today.getDate() + 1);
  const nextAvailableRoastDate = getNextFulfillmentDate({
    startDate: tomorrow,
    excludedDates: excludedDatesForFulfillment,
  });

  const onClickNextFulfillmentDate = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const onDateChange = data => {
    setOverrideDate(data);

    const orderWithNewFulfillmentDate = {
      ...pendingOneOffOrder,
      override_fulfillment_date: moment(data.override_fulfillment_date).format('YYYY-MM-DD'),
    };

    setPendingOneOffOrder(orderWithNewFulfillmentDate);
  };

  return (
    <Modal
      isOpen={isOneOffModalOpen}
      toggle={toggleOneOffModal}
      onClosed={() => setOverrideDate()}
      unmountOnClose
      className="one-off-order-modal"
      size="lg"
      zIndex="2000"
    >
      <ModalHeader toggle={toggleOneOffModal}>{isGuest ? 'Create order' : 'One-off order'}</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="12" md="6" className="mb-4 mb-md-0">
            <div className="d-flex align-items-center">
              <div className="img-container text-right mr-3">
                <Image className="product-card-image img-fluid" src={imageUrl} alt={name || 'Product'} width="150" />
              </div>
              <div className="product-details-container">
                <div className="product-card-vendor text-secondary small text-uppercase">{vendorName}</div>
                <div className="product-card-product font-weight-bold">{name}</div>
                {variantDetails && (
                  <>
                    <div>
                      {variantDetails.size}
                      {variantDetails.unit ? ` ${variantDetails.unit}s` : 'oz'} -{' '}
                      <span className="product-card-price font-weight-semi-bold">
                        <VariantPrice
                          user={me}
                          variant={variantDetails}
                          pricingRule={pricingRule}
                          isFirstBag={isFirstBag}
                          size={variantDetails.size}
                        />
                      </span>
                    </div>
                    {grind && <div className="small text-secondary">{grind.name}</div>}
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs="12" md="6" className="d-flex flex-column align-items-md-end justify-content-center">
            <div className="d-flex flex-column align-items-md-end">
              <div className="font-weight-semi-bold">Next Available Processing Date:</div>
              <div>
                <DateFormat date={nextAvailableRoastDate} />
              </div>
            </div>
            <div className="d-inline-flex pt-1 cursor-pointer">
              <div className="text-secondary" onClick={onClickNextFulfillmentDate}>
                Schedule your order
              </div>
              <Form
                initialValues={{ override_fulfillment_date: new Date(nextAvailableRoastDate) }}
                validationSchema={overrideDateSchema}
                onSubmit={onDateChange}
                inline
              >
                {({ submitForm }) => (
                  <span className="ml-2">
                    <DateDropdown
                      onChange={() => setTimeout(submitForm, 0)}
                      name="override_fulfillment_date"
                      min={new Date(nextAvailableRoastDate)}
                      max={new Date(Date.now() + 56 * ONE_DAY_IN_MILLISECONDS)}
                      excludeDates={excludedDatesForFulfillment}
                      excludeWeekends={true}
                      opened={isCalendarOpen}
                      resetOpenState={() => setIsCalendarOpen(false)}
                      onCalendarClose={() => setIsCalendarOpen(false)}
                      popperPlacement="bottom-end"
                    />
                  </span>
                )}
              </Form>
              <div>
                &nbsp;
                {overrideFulfillmentDate ? ': ' : ''}
                {overrideFulfillmentDate ? <DateFormat date={overrideFulfillmentDate} /> : ''}
              </div>
            </div>
          </Col>
        </Row>

        {!isGuest && me?.grind && (
          <p className="py-3">
            One-off orders should not be used with your scale. <br />
            Use this for backup coffee, gifts, decaf, or anything other than your normal Bottomless beans.
          </p>
        )}
        {!me?.grind && (
          <p className="py-3">
            One-off orders should not be used with your scale. <br />
            Use this for backup orders, gifts, or anything other than your normal Bottomless subscription.
          </p>
        )}
        {me && (
          <div className="py-3">
            <div className="font-weight-bold">Shipping address:</div>
            <div>
              {me.first_name} {me.last_name}
            </div>
            <Address address={me.verifiedAddress} />
          </div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          color="transparent"
          outline
          className="mr-3"
          size="sm"
          onClick={cancelOneOffOrder}
          disabled={loadingOneOff}
        >
          Cancel
        </Button>
        <Button color="primary" size="sm" onClick={triggerOneOffOrder} disabled={loadingOneOff}>
          {loadingOneOff ? (
            <>
              Saving... <Loader size="13" className="spin ml-2" />
            </>
          ) : (
            'Confirm'
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

OneOffOrderModal.propTypes = {
  me: PropTypes.object,
  isOneOffModalOpen: PropTypes.bool.isRequired,
  toggleOneOffModal: PropTypes.func.isRequired,
  cancelOneOffOrder: PropTypes.func.isRequired,
  loadingOneOff: PropTypes.bool.isRequired,
  triggerOneOffOrder: PropTypes.func.isRequired,
  pendingOneOffOrder: PropTypes.shape({
    grind: PropTypes.string,
    source: PropTypes.string.isRequired,
    product: PropTypes.shape({
      allVariants: PropTypes.array.isRequired,
      imageUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      product: PropTypes.string.isRequired,
      variant: PropTypes.string.isRequired,
      vendor: PropTypes.object.isRequired,
    }).isRequired,
  }),
  pricingRule: PropTypes.object,
  isFirstBag: PropTypes.bool,
  setPendingOneOffOrder: PropTypes.func.isRequired,
  grinds: PropTypes.array.isRequired,
};

OneOffOrderModal.defaultProps = {
  isFirstBag: false,
  pendingOneOffOrder: {},
};

export default memo(OneOffOrderModal);
