import PropTypes from 'prop-types';

export const MonolithFallback = ({ location }) => {
  window.location.replace(`${process.env.REACT_APP_BACKEND_URL}${location.pathname}${location.search}`);
  return null;
};

MonolithFallback.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
