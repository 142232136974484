import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { FilterElement } from './FilterElement';

export const FilterAccordion = ({
  name,
  data,
  onCollapse,
  label,
  collapsed,
  onChange,
  products,
  filters,
  intersect,
  hideNumber,
}) => {
  const [shouldRender, setShouldRender] = useState(collapsed);
  const [isOpen, setIsOpen] = useState(collapsed);

  useEffect(() => {
    if (collapsed) {
      setShouldRender(true);
    }

    setIsOpen(collapsed);
  }, [collapsed]);

  const onExited = useCallback(() => setShouldRender(false), [setShouldRender]);

  return (
    <div className="filter mb-2">
      <h6 className={`filter-label cursor-pointer mb-1${collapsed ? ' collapsed' : ''}`} onClick={onCollapse}>
        {label}
      </h6>
      <Collapse isOpen={isOpen} onExited={onExited}>
        {shouldRender &&
          data
            .filter(value => (value?._id ? value : null))
            .filter(Boolean)
            .map((element, i) => (
              <FilterElement
                key={element._id}
                i={i}
                onChange={onChange}
                name={name}
                element={element}
                products={products}
                filters={filters}
                intersect={intersect}
                hideNumber={hideNumber}
              />
            ))}
      </Collapse>
    </div>
  );
};

FilterAccordion.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    })
  ).isRequired,
  onCollapse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  intersect: PropTypes.bool,
  hideNumber: PropTypes.bool,
};
