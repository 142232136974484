import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './ExternalSignup.scss';
import { Loader } from 'react-feather';
import { FacebookSignupButton } from './FacebookSignupButton';
import { OneTapSignup } from './OneTapSignup';

export const ExternalSignup = ({ getMe, onAuth, getStarted, onSignUp, onAuthSuccess, postAuthSuccess }) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <div className="signup__external">
      <div className="signup__external--providers">
        {isLoading && (
          <div className="signup__external--loader">
            <div>
              <span>Loading...</span>
              <Loader size="13" className="spin ml-2" />
            </div>
          </div>
        )}
        <div className="mb-4">
          <OneTapSignup
            getMe={getMe}
            onAuth={payload => {
              setLoading(true);
              return onAuth({ provider: 'google', payload });
            }}
            onAuthSuccess={onAuthSuccess}
            postAuthSuccess={payload => {
              setLoading(false);
              return postAuthSuccess(payload);
            }}
            onSignUp={data => {
              setLoading(true);
              return getStarted({ email: data.email });
            }}
            onSignUpSuccess={payload => {
              setLoading(false);
              return onSignUp(payload);
            }}
          />
        </div>

        <div className="mb-4">
          <FacebookSignupButton
            getMe={getMe}
            onAuth={payload => {
              setLoading(true);
              return onAuth({ provider: 'facebook', payload });
            }}
            onAuthSuccess={onAuthSuccess}
            postAuthSuccess={payload => {
              setLoading(false);
              return postAuthSuccess(payload);
            }}
            onSignUp={data => {
              setLoading(true);
              return getStarted({ email: data.email });
            }}
            onSignUpSuccess={payload => {
              setLoading(false);
              return onSignUp(payload);
            }}
          />
        </div>
      </div>

      <div className="signup__external--footer">
        <span>OR</span>
      </div>
    </div>
  );
};

ExternalSignup.propTypes = {
  getStarted: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
  getMe: PropTypes.func.isRequired,
  onAuth: PropTypes.func.isRequired,
  onAuthSuccess: PropTypes.func.isRequired,
  postAuthSuccess: PropTypes.func.isRequired,
};
