import { useCallback, useContext } from 'react';
import { useMe } from '../../../hooks/useMe.hook';
import { useMemo } from 'react';
import { CustomWordingContext } from '../context/custom-wording-context';

export const useCustomWording = () => {
  const user = useMe();
  const vendorId = useMemo(() => user?.vendor_id?._id, [user]);

  const customWording = useContext(CustomWordingContext);

  const cw = useCallback(text => (customWording[vendorId] || {})[text] || text, [customWording, vendorId]);

  return { cw };
};
