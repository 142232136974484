import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'reactstrap';
import { Image } from '@bottomless/common/components';
import './Footer.scss';

export const CobrandedFooter = () => (
  <section className="footer co-branded">
    <Container className="w-100">
      <Row className="d-flex justify-content-center">
        <Image
          src="https://bottomless.imgix.net/shopify-widget/Vector2.png"
          loading="lazy"
          alt="vendor"
          className="logo-image"
          height="28"
          useSrcset
        />
      </Row>
    </Container>
  </section>
);

CobrandedFooter.defaultProps = {
  withLogos: false,
};

CobrandedFooter.propTypes = {
  withLogos: PropTypes.bool,
};
