import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import has from 'lodash-es/has';
import moment from 'moment';
import { Field, Form, SubmitButton, Tooltip } from '@bottomless/common/components';
import { OrderSources } from '@bottomless/common/constants';
import { useToggle } from '@bottomless/common/hooks';
import { datesService, utcDate } from '@bottomless/common/utils';
import { CheckSquare, Square } from 'react-feather';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { getNextFulfillmentDate } from '../../../utils/dates';

const ONE_OFF_ENABLED = false;

const STEP_CONFIRMATION = 'step_confirmation';
const STEP_DATE = 'step_date';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const OverrideDateSchema = Yup.object().shape({
  override_fulfillment_date: Yup.date().required('This field is required'),
});

export const TriggerNowModal = ({
  toggle,
  triggerOrder,
  vendor,
  pendingOrder,
  hasOneOffOrder,
  onOverrideDateSuccess,
  setOpen,
  onTriggerOrderSuccess,
}) => {
  const [step, setStep] = useState(null);
  const [data, setData] = useState(null);
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [isOneOffOrder, toggleOnOffOrder, setOneOffOrder] = useToggle();

  useEffect(() => {
    setOneOffOrder(Boolean(pendingOrder));
  }, [pendingOrder, setOneOffOrder]);

  const onReset = useCallback(() => {
    setStep(STEP_DATE);
    setData(null);
    setOpen(false);
    setOneOffOrder(false);
  }, [setStep, setData, setOpen, setOneOffOrder]);

  const triggerOrderHandler = useCallback(
    async data => {
      await triggerOrder({
        ...data,
        override_fulfillment_date: has(data, 'override_fulfillment_date')
          ? moment(data.override_fulfillment_date).format('YYYY-MM-DD')
          : null,
      });

      onTriggerOrderSuccess();
      onReset();
    },
    [triggerOrder, onTriggerOrderSuccess, onReset]
  );

  const onConfirm = () => {
    setConfirmDisabled(true);
    return triggerOrderHandler({ ...data, source: OrderSources.USER_ONE_OFF });
  };

  const startDate = useMemo(() => {
    const today = new Date();
    const isBefore4AMLocalTime = today.getHours() > 0 && today.getHours() < 4;
    let tomorrow = new Date();
    tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);

    if (isBefore4AMLocalTime) {
      return today;
    }

    return tomorrow;
  }, []);

  const excludedDatesForFulfillment = useMemo(() => {
    const { exclude_fulfilment_dates } = vendor || {};
    return exclude_fulfilment_dates ? exclude_fulfilment_dates.map(date => utcDate(date)) : [];
  }, [vendor]);

  const nextAvailableRoastDate = useMemo(
    () =>
      getNextFulfillmentDate({
        startDate,
        excludedDates: excludedDatesForFulfillment,
      }),
    [startDate, excludedDatesForFulfillment]
  );

  const nextAvailableRoastDateLabel = useMemo(() => moment(nextAvailableRoastDate).format('MM/DD/YYYY'), [
    nextAvailableRoastDate,
  ]);

  const nextAvailableRoastDay = useMemo(() => daysOfWeek[new Date(nextAvailableRoastDate).getDay()], [
    nextAvailableRoastDate,
  ]);

  const fullFillmentDate = useMemo(
    () =>
      pendingOrder && pendingOrder.override_fulfillment_date
        ? utcDate(pendingOrder.override_fulfillment_date)
        : nextAvailableRoastDateLabel,
    [pendingOrder, nextAvailableRoastDateLabel]
  );

  const minDate = useMemo(() => {
    let min = datesService.getTodayInSeattle();

    if (!vendor.exclude_fulfilment_dates) {
      return min;
    }

    const excludedDates = vendor.exclude_fulfilment_dates.map(date => utcDate(date).getTime());

    while (excludedDates.includes(min.setHours(0, 0, 0, 0))) {
      min = new Date(min.getTime() + 25 * 3600 * 1000);
    }

    return min;
  }, [vendor]);

  const onTriggerOrder = useCallback(
    ({ override_fulfillment_date, ...formData }) => {
      const data =
        override_fulfillment_date === nextAvailableRoastDateLabel
          ? formData
          : { ...formData, override_fulfillment_date };

      if (isOneOffOrder) {
        setData(data);
        return setStep(STEP_CONFIRMATION);
      }
      return triggerOrderHandler(data);
    },
    [nextAvailableRoastDateLabel, isOneOffOrder, setData, setStep, triggerOrderHandler]
  );

  return (
    <Modal isOpen={true} toggle={toggle} className="text-center up-next-modal">
      {step === STEP_CONFIRMATION && (
        <>
          <ModalHeader toggle={onReset}>
            <span className="d-none d-sm-block">Confirm your one-off order</span>
          </ModalHeader>
          <ModalBody>
            <p className="mb-0">
              One-off orders should not be used with your scale. <br />
              Use this for backup coffee, gifts, decaf, or anything other than your normal Bottomless beans.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="transparent" outline className="mr-3" size="sm" onClick={onReset}>
              Cancel
            </Button>
            <Button color="success" size="sm" onClick={onConfirm} disabled={confirmDisabled}>
              {confirmDisabled ? (
                <>
                  <Spinner size="sm">Triggering...</Spinner>
                  <span> Triggering </span>
                </>
              ) : (
                'Confirm'
              )}
            </Button>
          </ModalFooter>
        </>
      )}
      {step !== STEP_CONFIRMATION && (
        <>
          <ModalHeader toggle={toggle}>
            <span className="d-none d-sm-block">Schedule your order</span>
          </ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column flex-sm-row justify-content-around align-items-center up-next-modal-content pb-1">
              <h2 className="d-block d-sm-none">Schedule your order</h2>
              <div className="d-flex flex-column align-items-center">
                <span className="mb-3">
                  <strong>Note:</strong> The next available roast date for your order is {nextAvailableRoastDay}
                </span>
                <Form
                  initialValues={{ override_fulfillment_date: fullFillmentDate }}
                  validationSchema={OverrideDateSchema}
                  onSubmit={onTriggerOrder}
                  onSuccess={onOverrideDateSuccess}
                  inline
                >
                  {({ isSubmitting }) => (
                    <>
                      <Field
                        type="date"
                        label="Date"
                        name="override_fulfillment_date"
                        min={minDate}
                        max={new Date(Date.now() + 56 * 86400000)}
                        excludeWeekends={true}
                        excludeDates={
                          vendor.exclude_fulfilment_dates
                            ? vendor.exclude_fulfilment_dates.map(date => utcDate(date))
                            : []
                        }
                      />
                      <SubmitButton isSubmitting={isSubmitting} color="dark">
                        Trigger
                      </SubmitButton>
                    </>
                  )}
                </Form>
              </div>
            </div>
            {pendingOrder && hasOneOffOrder && (
              <>
                <Alert color="warning" className="mt-5 mb-0">
                  <p className="mb-0">
                    It seems like you already have an order either scheduled or on the way that hasn&apos;t arrived.
                  </p>
                </Alert>
              </>
            )}
          </ModalBody>
          {ONE_OFF_ENABLED && !hasOneOffOrder && (
            <ModalFooter className="justify-content-center">
              <Tooltip
                content={`
                        One-off orders should not be used with your scale.
                        Use this for backup coffee, gifts, decaf, or anything other than your normal Bottomless beans.`}
                id={'button'}
              >
                <Button
                  onClick={() => toggleOnOffOrder()}
                  color="transparent"
                  disabled={pendingOrder}
                  className="btn-outline d-flex justify-content-center text-medium text-lowercase"
                >
                  <span>{isOneOffOrder ? <CheckSquare size="17" /> : <Square size="17" />}</span>
                  <span className="ml-2"> one-off order</span>
                </Button>
              </Tooltip>
            </ModalFooter>
          )}
        </>
      )}
    </Modal>
  );
};

TriggerNowModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  triggerOrder: PropTypes.func.isRequired,
  vendor: PropTypes.shape({
    exclude_fulfilment_dates: PropTypes.array,
  }),
  pendingOrder: PropTypes.shape({
    override_fulfillment_date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
  hasOneOffOrder: PropTypes.bool,
  onOverrideDateSuccess: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  onTriggerOrderSuccess: PropTypes.func.isRequired,
};
