import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { MakeBottomless } from '../../../../Shop/components/MakeBottomless';

export const BasicProductInfo = ({
  product,
  pathname,
  me,
  pricingRule,
  onChooseProduct,
  onChooseSuccess,
  makeBottomlessText,
  outOfStock,
  productSelection,
  saveProductSelection,
  isFirstBag,
  getCheckout,
}) => {
  const location = useLocation();

  return (
    <Row className="mb-5 mt-5">
      <Col xs="12" md="6" className="product-image-col d-flex align-items-center justify-content-center">
        <LazyLoad once height={525} offset={525}>
          <img className="img-fluid product-image" src={`${product.image_src}&w=300`} alt={product.name} />
        </LazyLoad>
      </Col>
      <Col xs="12" md="6" className="pt-2 product-details-col">
        <div className="d-flex flex-column align-items-center mb-5">
          <div className="text-uppercase text-secondary">{product.vendor_name}</div>
          <h1 className="text-center">{product.name}</h1>
        </div>
        <MakeBottomless
          me={me}
          product={product}
          priceRule={pricingRule}
          isFirstBag={isFirstBag}
          onChooseProduct={onChooseProduct}
          onChooseSuccess={onChooseSuccess}
          hasOneOff={!!me}
          hasCustomLists={!!me}
          selectText={makeBottomlessText}
          outOfStock={outOfStock}
          isProductPage
          pathname={pathname}
          productSelection={productSelection}
          saveProductSelection={saveProductSelection}
          location={location}
          getCheckout={getCheckout}
        />
      </Col>
    </Row>
  );
};

BasicProductInfo.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image_src: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    vendor_id: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
    likes: PropTypes.number,
    dislikes: PropTypes.number,
  }).isRequired,
  pathname: PropTypes.string,
  me: PropTypes.object,
  pricingRule: PropTypes.object,
  onChooseProduct: PropTypes.func.isRequired,
  onChooseSuccess: PropTypes.func.isRequired,
  makeBottomlessText: PropTypes.string,
  outOfStock: PropTypes.bool,
  productSelection: PropTypes.object,
  saveProductSelection: PropTypes.func.isRequired,
  isFirstBag: PropTypes.bool,
  getCheckout: PropTypes.func,
};
