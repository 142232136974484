import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Col, Input, Row, Button } from 'reactstrap';
import QRCode from 'react-qr-code';

export const CoffeeInvite = ({
  me,
  referralId,
  withDescription,
  descriptionText,
  withQrCode,
  withTitle,
  shareButtonsClassname,
}) => {
  const referral = referralId || (me && me.referral_id);
  const inviteLink = `${window.location.origin}/referral/${referral}`;
  const textTemplate = `sms:?&body=Hey! I thought you might like Bottomless. Your second bag is free with my referral! ${inviteLink} Bottomless is a coffee subscription that uses a smart scale to automatically reorder you coffee at the right time. ♾️☕`;
  const [copied, setCopied] = useState(false);
  const linkText = 'Get your second bag for free when signing up for Bottomless!';
  const emailBody = `Your friend${
    me?.first_name ? ` ${me.first_name} ` : ' '
  }sent you a free bag of coffee on bottomless.com!\n\nBottomless is a coffee subscription by usage. We send you a WiFi-enabled scale to figure out the perfect time to send you freshly roasted coffee... so you never run out.  Just leave your bag on the scale, and take coffee off your to-do list.\n\nClaim your free bag here: ${inviteLink}
Please let us know if you have any questions!\n\n- Bottomless Team`;

  const appLinks = {
    whatsapp: `https://api.whatsapp.com/send?phone=&text=${encodeURI(linkText + ' ')}${encodeURI(inviteLink)}`,
    twitter: `https://twitter.com/share?url=${inviteLink}&text=${encodeURI(linkText + ' ')}&via=bottomless`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(inviteLink)}`,
    'facebook-messenger': `fb-messenger://share/?link=${encodeURI(inviteLink)}&app_id=2117364121911429`,
    reddit: `https://reddit.com/submit?url=${encodeURI(inviteLink)}&title=${encodeURI(linkText)}`,
    envelope: `mailto:?subject=${linkText}&body=${encodeURIComponent(emailBody)}`,
  };

  const shareData = {
    title: 'Bottomless - The First Coffee Subscription by Usage',
    text: linkText,
    url: inviteLink,
  };

  const shareClicked = async () => {
    await navigator.share(shareData);
  };

  const showShare = navigator.share;

  return (
    <section>
      {withDescription && <p className="mb-3 small">{descriptionText}</p>}
      <Row>
        <Col xs="12" sm="12" lg="12" md="12" xl="12">
          <a href={textTemplate} className="btn btn-block btn-no-transform btn-primary mb-3 d-block d-sm-none">
            Text Your Friends
          </a>
          {withTitle && <h3 className="text-center">Share your invite link</h3>}
          <div className={classNames('invite-fields', { 'invite-fields-center': !withQrCode })}>
            <div>
              <div className="invite-link-copy d-flex mb-3">
                <Input className="mr-2" type="text" disabled value={inviteLink} />
                <CopyToClipboard text={inviteLink} onCopy={() => setCopied(true)}>
                  <Button color="primary" outline>
                    {copied ? 'Copied!' : 'Copy'}
                  </Button>
                </CopyToClipboard>
              </div>
              {showShare ? (
                <div className="mb-3">
                  <Button color="primary" outline onClick={shareClicked}>
                    Share
                  </Button>
                </div>
              ) : (
                <div
                  className={classNames(
                    'share-buttons d-flex align-items-center justify-content-center justify-content-md-start',
                    shareButtonsClassname
                  )}
                >
                  {Object.entries(appLinks).map(([name, link]) => (
                    <a
                      key={name}
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classNames('d-flex align-items-center justify-content-center mr-2', name)}
                    >
                      <i className={`fa fa-${name}`} />
                    </a>
                  ))}
                </div>
              )}
            </div>
            {withQrCode && (
              <>
                <div className="d-flex align-items-center my-4 text-secondary small">
                  <span className="border-bottom w-100" style={{ height: '1px' }} />
                  <span className="text-black-50 mx-2">OR</span>
                  <span className="border-bottom w-100" style={{ height: '1px' }} />
                </div>
                <div className="text-center">
                  <QRCode value={inviteLink} size={100} />
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </section>
  );
};

CoffeeInvite.propTypes = {
  referralId: PropTypes.string,
  withDescription: PropTypes.bool,
  withQrCode: PropTypes.bool,
  inviteButtonText: PropTypes.string,
  descriptionText: PropTypes.string,
  me: PropTypes.shape({
    referral_id: PropTypes.string,
    first_name: PropTypes.string,
  }),
  withTitle: PropTypes.bool,
  shareButtonsClassname: PropTypes.string,
};

CoffeeInvite.defaultProps = {
  me: {
    referral_id: '',
  },
  withDescription: true,
  withQrCode: true,
  inviteButtonText: 'Send Invite',
  descriptionText:
    'Invite people you know to join Bottomless - for every person who signs up, you will get a free bag credit added to your account. Your friend will also get a free bag.',
  withTitle: true,
};
