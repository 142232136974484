import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FeatureSection } from '../../FeatureSection/FeatureSection';

export const BringSmart = ({ isProductSpecific = false }) => {
  const text = useMemo(() => {
    if (isProductSpecific) {
      return 'Bottomless provides you a smart, automatic re-ordering mechanism via the scale that tracks your consumption. The scale re-orders for you at the perfect time.';
    } else {
      return 'As you rate different coffees Bottomless will learn your preference and recommend new products to try. It is like having a personal coffee curator!';
    }
  }, [isProductSpecific]);

  return (
    <FeatureSection
      image="https://uploads-ssl.webflow.com/60632cfc862b4707c8632d4e/6066fe9a553682d6242f13fd_personalized_resized (1)-poster-00001.jpg?auto=compress,format"
      video={[
        'https://uploads-ssl.webflow.com/606f47e81cb8f6f7bb617980/606f47e81cb8f674af6179a3_personalized_resized%20(1)-transcode.mp4',
        'https://uploads-ssl.webflow.com/606f47e81cb8f6f7bb617980/606f47e81cb8f674af6179a3_personalized_resized%20(1)-transcode.webm',
      ]}
      text={text}
      title="Bring smart to coffee"
    />
  );
};

BringSmart.propTypes = {
  isProductSpecific: PropTypes.bool,
};
