import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Field as FormikField } from 'formik';
import { CheckSquare, Square } from 'react-feather';
import get from 'lodash-es/get';
import './CheckboxBox.scss';

const CheckboxBoxComponent = ({ field, form, label, description, ...props }) => {
  const invalid = get(form.errors, field.name) && get(form.touched, field.name);
  const value = get(form.values, field.name);
  const isBooleanCheckbox = Boolean(value) === value;

  const checked = isBooleanCheckbox
    ? value
    : field.value === value ||
      (field.value === false && value === 'false') ||
      (field.value === true && value === 'true');

  const onChange = useCallback(
    event => {
      const val = isBooleanCheckbox ? event.target.checked : event.target.checked ? field.value : undefined;
      form.setFieldValue(field.name, val);
      if (props.onChange) {
        props.onChange(event);
      }
    },
    [isBooleanCheckbox, field, form, props]
  );

  return (
    <FormGroup check className="check-box mb-3">
      <Label check className={classNames('d-flex align-items-center', { 'label-checked': checked })}>
        {checked ? (
          <CheckSquare width="20" height="20" role="img" aria-label="Checked" />
        ) : (
          <Square color="rgba(0, 0, 0, 0.125)" width="20" height="20" role="img" aria-label="Unchecked" />
        )}
        <Input {...field} {...props} type="checkbox" invalid={invalid} onChange={onChange} checked={checked} />
        <div className="ml-3 checkbox-label">
          <h6 className="text-uppercase mb-0">{label}</h6>
          {description && <div className="small text-secondary">{description}</div>}
        </div>
      </Label>
      <FormFeedback className="text-left">{get(form.errors, field.name)}</FormFeedback>
    </FormGroup>
  );
};

CheckboxBoxComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export const CheckboxBox = props => <FormikField {...props} component={CheckboxBoxComponent} />;

CheckboxBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
