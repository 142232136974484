import { memo } from 'react';

const ReferralRedirectComponent = () => {
  const { location } = window || {};
  const paths = location.pathname.split('/');

  // The static referral page uses a dynamic path param. To enable to API call and
  // fetching information about referrals for a static page, the URL is changed
  // from: e.g.: /referral/test to /referral?referralId="test"
  if (process.env.NODE_ENV === 'development') {
    const referralId = paths && paths.length && paths[2];
    const newReferralUrl = `${location.origin}/referral?referralId=${referralId}`;

    window.location.replace(newReferralUrl);
  } else {
    window.location.reload();
  }

  return null;
};

export const ReferralRedirect = memo(ReferralRedirectComponent);
