import React from 'react';
import PropTypes from 'prop-types';
import { UserCategoryManifest } from '../../../components/CategoryManifest';
import { CoffeeInvite } from '../manifest/CoffeeInvite';
import { PetFoodInvite } from '../manifest/PetFoodInvite';

const COMPONENTS = {
  CoffeeUserReferral: CoffeeInvite,
  PetFoodUserReferral: PetFoodInvite,
};

const InviteComponent = ({ me, ...props }) => {
  return <UserCategoryManifest manifestKey="userReferral" components={COMPONENTS} noLoading me={me} {...props} />;
};

InviteComponent.propTypes = {
  me: PropTypes.object,
};

export const Invite = InviteComponent;
