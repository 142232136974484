import React from 'react';
import PropTypes from 'prop-types';
import { HowItWorks } from './components/HowItWorks';
import { BringSmart } from './sections/BringSmart';
import { HandpickedCoffees } from './sections/HandpickedCoffees';
import { TryItRisk } from './sections/TryItRisk';
import { TryVariety } from './sections/TryVariety';
import { UseContainer } from './sections/UseContainer';
import { UseWithGrinder } from './sections/UseWithGrinder';
import './Landing.scss';

export const Landing = ({ actionText, onClick, showHowItWorks = false, isProductSpecific = false }) => (
  <>
    {showHowItWorks && <HowItWorks actionText={actionText} onClick={onClick} isProductSpecific={isProductSpecific} />}
    <BringSmart isProductSpecific={isProductSpecific} />
    <TryItRisk actionText={actionText} onClick={onClick} />
    <TryVariety isProductSpecific={isProductSpecific} />
    {!isProductSpecific && <HandpickedCoffees actionText={actionText} onClick={onClick} />}
    <UseContainer />
    <UseWithGrinder />
  </>
);

Landing.propTypes = {
  actionText: PropTypes.string,
  onClick: PropTypes.func,
  showHowItWorks: PropTypes.bool,
  isProductSpecific: PropTypes.bool,
};
