import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const PurchaseGiftAction = createAction('Gift', 'Purchase gift');

export const purchaseGiftAction = data => ({
  [RSAA]: {
    endpoint: 'gifts/purchase',
    method: 'POST',
    body: data,
    types: [PurchaseGiftAction.REQUEST, PurchaseGiftAction.SUCCESS, PurchaseGiftAction.FAILURE],
  },
});

export const GetGiftsAction = createAction('Gift', 'Get gifts');

export const getGiftsAction = () => ({
  [RSAA]: {
    endpoint: 'gifts',
    method: 'GET',
    types: [GetGiftsAction.REQUEST, GetGiftsAction.SUCCESS, GetGiftsAction.FAILURE],
  },
});

export const GetGiftPurchaseAction = createAction('Gift', 'Get gift purchase');

export const getGiftPurchaseAction = code => ({
  [RSAA]: {
    endpoint: `gifts/purchase/${code}`,
    method: 'GET',
    types: [GetGiftPurchaseAction.REQUEST, GetGiftPurchaseAction.SUCCESS, GetGiftPurchaseAction.FAILURE],
  },
});

export const RedeemGiftAction = createAction('Gift', 'Redeem gift');

export const redeemGiftAction = code => ({
  [RSAA]: {
    endpoint: `gifts/redeem/${code}`,
    method: 'PATCH',
    types: [RedeemGiftAction.REQUEST, RedeemGiftAction.SUCCESS, RedeemGiftAction.FAILURE],
  },
});

export const HowYouHeardAboutGiftsTypes = createAction('Gift', 'How You Heard About Gifts');

export const howYouHeardAboutGiftsAction = (id, data) => ({
  [RSAA]: {
    endpoint: `gifts/heard-about/${id}`,
    method: 'PATCH',
    body: data,
    types: [HowYouHeardAboutGiftsTypes.REQUEST, HowYouHeardAboutGiftsTypes.SUCCESS, HowYouHeardAboutGiftsTypes.FAILURE],
  },
});
