import Helmet from 'react-helmet';
import React from 'react';

export const withRichProductMeta = ({
  title = 'Bottomless.com',
  image = 'https://www.bottomless.com/images/infinity-black.png',
  vendor = 'Bottomless',
  description = 'Bottomless is a subscription by usage where instead of getting shipments on a set schedule, you get shipments sent based on your usage.',
  numPosRatings = 0,
  numNegRatings = 0,
  availability = false,
  price = 0,
}) => Component =>
  function RichMetaTagsWrapper(props) {
    const titleValue = typeof title === 'function' ? title(props) : title;
    const imageValue = typeof image === 'function' ? image(props) : image;
    const descriptionValue = typeof description === 'function' ? description(props) : description;
    const vendorValue = typeof vendor === 'function' ? vendor(props) : vendor;
    const numPosRatingsValue = typeof numPosRatings === 'function' ? numPosRatings(props) : vendor;
    const numNegRatingsValue = typeof numNegRatings === 'function' ? numNegRatings(props) : vendor;
    const totRatingsValue = numPosRatingsValue + numNegRatingsValue;
    const availabilityValue = typeof availability === 'function' ? availability(props) : availability;
    const priceValue = typeof price === 'function' ? price(props) : price;

    return (
      <>
        <Helmet>
          {titleValue && (
            <script type="application/ld+json">
              {JSON.stringify({
                '@context': 'https://schema.org/',
                '@type': 'Product',
                name: titleValue,
                image: imageValue,
                company: vendorValue,
                description: descriptionValue,
                keywords: `Bottomless, ${titleValue}, ${vendorValue}`,
                availability: availabilityValue ? 'In stock' : 'Out of stock',
                ...(totRatingsValue > 0 && {
                  aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingValue:
                      totRatingsValue === 0 ? totRatingsValue : Math.round((numPosRatingsValue / totRatingsValue) * 5),
                    ratingCount: totRatingsValue,
                  },
                }),
                offers: {
                  '@type': 'Offer',
                  price: priceValue,
                  priceCurrency: 'USD',
                },
              })}
            </script>
          )}
        </Helmet>
        <Component {...props} />
      </>
    );
  };
