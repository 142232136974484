import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Field } from '@bottomless/common/components';

const SearchSchema = Yup.object().shape({
  text: Yup.string(),
});

export const Search = ({ onChange, text }) => {
  const formRef = useRef(null);
  const [search, setSearch] = useState(null);
  useEffect(() => {
    formRef.current.setFieldValue('text', text || '');
  }, [text, setSearch]);

  const submit = submitForm => () => setTimeout(submitForm);

  const onSearchChange = submitForm => () => {
    if (search) {
      clearTimeout(search);
    }
    setSearch(setTimeout(submit(submitForm), 300));
  };

  const onClear = (setFieldValue, submitForm) => () => {
    setFieldValue('text', '');
    submit(submitForm)();
  };

  return (
    <Form
      initialValues={{ text: '' }}
      validationSchema={SearchSchema}
      onSubmit={onChange}
      innerRef={formRef}
      className="form-search bg-white"
    >
      {({ submitForm, values, setFieldValue }) => (
        <div className="pb-2 mb-1 shop-search">
          <Field
            name="text"
            placeholder="Search"
            onChange={onSearchChange(submitForm)}
            aria-label="Search"
            autocomplete="off"
          />
          {values.text.length > 0 && (
            <i
              onClick={onClear(setFieldValue, submitForm)}
              role="button"
              className="shop-search-clear p-2 fa fa-times"
            ></i>
          )}
        </div>
      )}
    </Form>
  );
};

Search.propTypes = {
  text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};
