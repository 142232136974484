import React from 'react';
import PropTypes from 'prop-types';
import { FeatureSection } from '../../../../components/FeatureSection/FeatureSection';

export const BringSmartSection = ({ vendor }) => {
  return (
    <FeatureSection
      text={`Other subscriptions leave you running out or with too many shipments. Bottomless ships based on your usage. Just ${vendor?.name} arriving at the perfect time.`}
      title={`Simply ${vendor.name}, always in stock`}
      image={vendor.custom_styles.images.bringSmart}
    />
  );
};

BringSmartSection.propTypes = {
  vendor: PropTypes.object.isRequired,
};
