import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDataEffect } from '@bottomless/common/src/hooks';
import { DataLoading } from '@bottomless/common/components';
import { IsAuth } from '../../guards/auth.guard';
import { Panel } from '../../layouts/Panel/Panel';
import { PanelPage } from '../../layouts/PanelPage/PanelPage';
import { addCustomListElementAction } from '../../store';
import { getMeAction, makeBottomlessProductAction } from '../../store/user';
import { ShopPage } from './Shop';
import { QueueBar } from './components/QueueBar';

const domain = process.env.REACT_APP_COOKIE_DOMAIN;

const Wrapper = props => <PanelPage {...props} footer={<QueueBar />} />;

const MainShopPageComponent = props => {
  const { data: me, isFetching } = useDataEffect(props.getMe, undefined, undefined, null);
  const history = useHistory();
  const { search } = useLocation();
  const [{ checkout: checkoutCookie }, , removeCookie] = useCookies(['checkout']);

  useEffect(() => {
    if ((props.me || me) && checkoutCookie) {
      removeCookie('checkout', { domain, path: '/' });
    }
  }, [checkoutCookie, me, props.me, removeCookie]);

  if (!props.me && (me === undefined || isFetching)) {
    return <DataLoading count={0} isLoading={isFetching} />;
  }

  if (!props.me && !me) {
    history.push(`/public_shop${search}`);
    return null;
  }

  const vendor = props.me?.vendor_id ? { ...props.me?.vendor_id, id: props.me.vendor_id._id } : undefined;

  return (
    <IsAuth {...props}>
      <Panel className="panel-layout shop-layout">
        <ShopPage {...props} wrapper={Wrapper} vendor={vendor} isFirstBag={!props.me ? true : false} />
      </Panel>
    </IsAuth>
  );
};

MainShopPageComponent.propTypes = {
  makeBottomless: PropTypes.func.isRequired,
  me: PropTypes.shape({
    vendor_id: PropTypes.string,
  }).isRequired,
  getMe: PropTypes.func.isRequired,
};

export const MainShopPage = connect(
  ({ user: { me } }) => ({ pricingRule: (me || {}).pricing_rule, me }),
  (dispatch, { history, location }) => ({
    getMe: () => dispatch(getMeAction()),
    addToCustomList: data => dispatch(addCustomListElementAction(data)),
    makeBottomless: async (id, data) => {
      await dispatch(makeBottomlessProductAction(id, data));
      await dispatch(getMeAction());

      const query = qs.parse(location.search);
      history.push(query.back ? query.back : '/profile');
    },
  })
)(MainShopPageComponent);
