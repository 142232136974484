import { LogoutTypes } from '@bottomless/common/store';
import { GetShipmentProvidersTypes } from './shipment.actions';

const initialState = {
  providers: [],
  isLoading: false,
};

export const shipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetShipmentProvidersTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetShipmentProvidersTypes.SUCCESS:
      return {
        ...state,
        providers: action.payload,
        isLoading: false,
      };

    case GetShipmentProvidersTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case LogoutTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
