import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useScript, useToggle } from '@bottomless/common/hooks';
import { GoogleSignupButton } from './GooogleSignupButton';

export const OneTapSignup = ({ getMe, onAuth, onSignUp, onSignUpSuccess, onAuthSuccess, postAuthSuccess }) => {
  const [loaded, error] = useScript(`https://accounts.google.com/gsi/client`);
  const [fallback, toggleFallback] = useToggle(false);

  const handler = async googleLoginResponse => {
    try {
      if (googleLoginResponse.credential) {
        const { payload } = await onAuth({ credential: googleLoginResponse.credential });

        if (payload.state === 'AUTHORIZED_USER') {
          await onAuthSuccess(payload);
          await getMe();
          postAuthSuccess(payload);
        }

        if (payload.state === 'GUEST') {
          const signupResponse = await onSignUp(payload);
          onSignUpSuccess(signupResponse.payload);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const notifications = notification => {
    if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
      toggleFallback();
    }
  };

  useEffect(() => {
    try {
      if (!loaded && error) {
        toggleFallback();
      }

      if (loaded && !error) {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          context: 'signup',
          prompt_parent_id: 'g_id_wrapper',
          cancel_on_tap_outside: false,
          callback: handler,
        });

        window.google.accounts.id.prompt(notifications);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      toggleFallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, error]);

  return (
    <div id="g_id_wrapper">
      <>
        {fallback && (
          <GoogleSignupButton
            getMe={getMe}
            onAuth={onAuth}
            onAuthSuccess={onAuthSuccess}
            postAuthSuccess={postAuthSuccess}
            onSignUp={onSignUp}
            onSignUpSuccess={onSignUpSuccess}
          />
        )}
      </>
    </div>
  );
};

OneTapSignup.propTypes = {
  getMe: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
  onSignUpSuccess: PropTypes.func.isRequired,
  onAuth: PropTypes.func.isRequired,
  onAuthSuccess: PropTypes.func.isRequired,
  postAuthSuccess: PropTypes.func.isRequired,
};
