import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from 'reactstrap';
import { capitalize } from 'lodash-es';
import { ProductName, Image } from '@bottomless/common/components';
import { fromOz } from '@bottomless/common/src/utils';

export const RotationProduct = ({ product, personalized, grind, isQuizCompleted, vendor, isMobile }) => {
  const history = useHistory();

  const handleEditClick = useCallback(
    e => {
      e.stopPropagation();
      if (vendor) {
        history.push(vendor.slug);
      } else {
        history.push('/filter_checkout');
      }
    },
    [history, vendor]
  );

  return (
    <Card key={product._id} body outline className="d-flex flex-row justify-space-between w-100 rotation-product">
      <div className="d-flex justify-content-between p-1 w-100">
        <div className="d-flex align-items-center">
          <Image src={product.small_image_src} width="60" alt={product.name} className={isMobile ? 'mr-2' : 'mr-4'} />
          <div>
            {!isQuizCompleted && <div className="text-secondary">{product.vendor_name}</div>}
            <ProductName product={product} personalized={personalized} grind={grind} />
            {personalized && (
              <>
                <p className="text-secondary small mb-0">Size: {fromOz({ oz: personalized.size }).formatted}</p>
                <p className="text-secondary small mb-0">{capitalize(personalized.price_type)} Tier</p>
              </>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center text-secondary">
          <Button color="primary" className="btn-sm" outline onClick={handleEditClick}>
            Edit
          </Button>
        </div>
      </div>
    </Card>
  );
};

RotationProduct.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    small_image_src: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
      })
    ).isRequired,
  }),
  personalized: PropTypes.object,
  grind: PropTypes.string,
  isQuizCompleted: PropTypes.bool,
  vendor: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
};
