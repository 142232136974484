import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Loader } from 'react-feather';

export const SubmitButton = ({
  isSubmitting,
  disabled,
  children,
  loadingText,
  withLoader,
  outline,
  size,
  ...props
}) => {
  const [isLoading, setLoading] = useState(isSubmitting);
  useEffect(() => {
    if (isSubmitting !== isLoading) {
      const timeout = setTimeout(() => setLoading(isSubmitting), isSubmitting ? 300 : 0);
      return () => clearTimeout(timeout);
    }
  }, [isSubmitting, isLoading]);

  return (
    <Button disabled={isSubmitting || disabled} type="submit" color="primary" size={size} outline={outline} {...props}>
      {isLoading ? (
        <span className="d-flex align-items-center justify-content-center">
          {loadingText} {withLoader ? <Loader size="13" className="spin ml-2" /> : ''}
        </span>
      ) : (
        children
      )}
    </Button>
  );
};

SubmitButton.propTypes = {
  loadingText: PropTypes.string,
  children: PropTypes.node.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  withLoader: PropTypes.bool,
  outline: PropTypes.bool,
  size: PropTypes.string,
};

SubmitButton.defaultProps = {
  loadingText: 'Saving',
  disabled: false,
  withLoader: true,
  outline: false,
  size: 'sm',
};
