import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { AdvanceBuilderHeading } from '../AdvanceBuilderHeading';
import { Grind } from './Grind';

const GRINDS = [
  { name: 'Whole Beans' },
  { name: 'Extra Coarse' },
  { name: 'Coarse' },
  { name: 'Medium' },
  { name: 'Fine' },
];

export const Grinds = ({ onChange, products, hideOptionWithoutProducts, optionFilter }) => (
  <>
    <AdvanceBuilderHeading title="Grind" subtitle="Choose one" />
    <Row className="pb-4 pt-2">
      {GRINDS.map(({ name }) => (
        <Grind
          key={name}
          onChange={onChange}
          products={products}
          hideOptionWithoutProducts={hideOptionWithoutProducts}
          name={name}
          optionFilter={optionFilter}
        />
      ))}
    </Row>
  </>
);

Grinds.propTypes = {
  onChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  optionFilter: PropTypes.object,
};
