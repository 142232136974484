import PropTypes from "prop-types";

export const Default = ({ children }) =>
  children === undefined || children === null || children === ""
    ? "---"
    : children;

Default.propTypes = {
  children: PropTypes.node
};
