import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { SingleOrigin } from './SingleOrigin';
import { AdvanceBuilderHeading } from '../AdvanceBuilderHeading';

export const SingleOrigins = ({
  onChange,
  allOrigins,
  products,
  hideOptionWithoutProducts,
  optionFilter,
  personalized,
}) => {
  const singleOrigins = useMemo(() => allOrigins.filter(({ name }) => name !== 'Blend'), [allOrigins]);

  return (
    <>
      <AdvanceBuilderHeading title="Specific Origin" />
      <Row className="pb-4 pt-2">
        <Col xs="12" className="mb-3">
          <Row>
            {singleOrigins.map(origin => (
              <SingleOrigin
                key={origin._id}
                origin={origin}
                onChange={onChange}
                products={products}
                hideOptionWithoutProducts={hideOptionWithoutProducts}
                optionFilter={optionFilter}
                personalized={personalized}
              />
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
};

SingleOrigins.propTypes = {
  onChange: PropTypes.func.isRequired,
  allOrigins: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  optionFilter: PropTypes.object,
  personalized: PropTypes.object,
};
