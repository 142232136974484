import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

export const NewSubscription = ({ onClick }) => (
  <div role="button" className="d-flex flex-column align-items-center text-center" onClick={onClick}>
    <Card body className="card-account justify-content-center mb-2">
      <span className="text-secondary add-subscription">+</span>
    </Card>
    Add Subscription
  </div>
);

NewSubscription.propTypes = {
  onClick: PropTypes.func.isRequired,
};
