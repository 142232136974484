import React from 'react';
import PropTypes from 'prop-types';
import { getTrackingLink } from '../../utils';

export const Tracking = ({ number, label, shippingService, trackingUrl }) => {
  return (
    <>
      {label && <>{label}: </>}
      <a
        href={getTrackingLink(shippingService, number, trackingUrl)}
        target="_blank"
        rel="noopener noreferrer"
        className="text-primary"
      >
        {number}
      </a>
    </>
  );
};

Tracking.propTypes = {
  number: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  shippingService: PropTypes.string,
  trackingUrl: PropTypes.string,
};

Tracking.defaultProps = {
  label: 'Tracking link',
};
