import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ProductCategoryManifest } from '../../components/CategoryManifest';
import { getStartedAction } from '../../store';
import {
  CoffeeProductDetails,
  PetProductDetails,
  AthleticGreensProductDetails,
  GenericProductDetails,
} from './manifest';
import './ProductDetails.scss';

const COMPONENTS = {
  CoffeeProductDetails: CoffeeProductDetails,
  PetFoodProductDetails: PetProductDetails,
  AthleticGreensProductDetails: AthleticGreensProductDetails,
  GenericProductDetails: GenericProductDetails,
};

const ProductDetailsPageComponent = props => (
  <ProductCategoryManifest
    slugOrId={props.match.params.slug}
    manifestKey="productDetails"
    components={COMPONENTS}
    fallbackComponent={GenericProductDetails}
    {...props}
  />
);

ProductDetailsPageComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const ProductDetailsPage = connect(null, dispatch => ({
  getStarted: data => dispatch(getStartedAction(data)),
}))(ProductDetailsPageComponent);
