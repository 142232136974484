import moment from 'moment-timezone';
import PropTypes from 'prop-types';

export const DateFormat = ({
  date: raw,
  withTime,
  correctTimezone,
  fromZeroed,
  toSeattle,
  toTimezone,
  format: rawFormat,
}) => {
  const format = rawFormat ? rawFormat : `MM/DD/YYYY${withTime ? ' h:mm a' : ''}`;

  if (toTimezone) {
    return moment
      .utc(raw)
      .tz(toTimezone)
      .format(format);
  }

  if (toSeattle) {
    return moment
      .utc(raw)
      .tz('America/Los_Angeles')
      .format(format);
  }

  return moment(raw)
    .utc(!(correctTimezone || fromZeroed))
    .format(format);
};

DateFormat.propTypes = {
  withTime: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number]),
  correctTimezone: PropTypes.bool,
  fromZeroed: PropTypes.bool,
  toSeattle: PropTypes.bool,
  toTimezone: PropTypes.bool,
  format: PropTypes.string,
};

DateFormat.defaultProps = {
  withTime: false,
  correctTimezone: false,
  fromZeroed: false,
  toSeattle: false,
  toTimezone: false,
};
