import React, { useEffect, useMemo } from 'react';
import { StepsProgress } from '../../../components/StepsProgress';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { TareScale } from './components';
import { useQueryString } from '@bottomless/common/hooks';
import { withCustomWording } from '../context/custom-wording-context';
import { customWording } from './customWording';

const ConnectTarePageComponent = () => {
  const { params, update } = useQueryString();
  const timestamp = useMemo(() => params.timestamp || String(Date.now()), [params.timestamp]);

  useEffect(() => {
    if (params.time !== timestamp) {
      update({ timestamp });
    }
  }, [update, timestamp, params]);

  return (
    <>
      <StepsProgress steps={3} activeStep={1} className="mb-5" />
      <h1 className="text-center mb-4">Activate your smart scale</h1>
      <TareScale timestamp={params.timestamp} />
    </>
  );
};

export const ConnectTarePage = withCustomWording(
  customWording,
  withMetaTags({
    title: 'Bottomless.com: Activate your smart scale',
  })(ConnectTarePageComponent)
);
