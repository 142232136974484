import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { VariantPrice } from '@bottomless/common/components';

export const Variants = ({ variants, pricingRule, isFirstBag, me, checkout }) => (
  <Row className="product-card-variants mb-3">
    {variants
      .filter(v => v.available)
      .sort((a, b) => (a.size > b.size ? 1 : -1))
      .map((variant, i) => (
        <Col xs="12" key={i}>
          <div className="product-card-size small">
            {variant.size}oz -{' '}
            <span className="font-weight-bold">
              <VariantPrice
                user={me}
                variant={variant}
                pricingRule={pricingRule}
                isFirstBag={isFirstBag}
                size={variant.size}
                checkout={checkout}
              />
            </span>
          </div>
        </Col>
      ))}
  </Row>
);

Variants.propTypes = {
  variants: PropTypes.array.isRequired,
  pricingRule: PropTypes.object,
  isFirstBag: PropTypes.bool,
  me: PropTypes.any,
  checkout: PropTypes.object,
};
