import React from 'react';
import { FaqElement } from './FaqElement';
import { VENDOR_IDS } from '../../../../constants/vendor';
import { useCustomWording } from '../../hooks/use-custom-wording';
import { withCustomWording } from '../../context/custom-wording-context';

export const customWording = {
  [VENDOR_IDS.AG1]: {
    'What happens after I sync the scale?': 'What happens after I sync the smart canister?',
    'You should receive a confirmation email or text. Otherwise, please try restarting your router and syncing the scale again. Ensure there are no typos in your email address.':
      'You should receive a confirmation email or text. Otherwise, please try restarting your router and syncing the smart canister again. Ensure there are no typos in your email address.',
    "How do I reset my scale's zero level?": "How do I reset my smart canister's zero level?",
    'Remove the item from the scale (or leave your empty container on it), connect to the “Bottomless_Scale” Wi-Fi, and select “Set Zero Level”.': `Place the canister on a flat surface with your scoop inside. Connect to the “Bottomless_Scale“ Wi-Fi, and select “Set Zero Level“. This will set smart canister to “zero level“ before you add your AG1.`,
  },
};

const FAQ = [
  {
    question: `Why didn't I get a pop-up after connecting to the "Bottomless_Scale" network?`,
    answer: 'Please try navigating to: 192.168.0.1 or 192.168.1.1 (Android) or captive.apple.com (iOS)',
  },
  {
    question: "Why isn't my Wi-Fi network appearing?",
    answer: 'Please exit the pop-up and try syncing again.',
  },
  {
    question: 'How do I change the Wi-Fi?',
    answer: 'Connect to the “Bottomless_Scale” Wi-Fi and select “Sync to New Wi-Fi”.',
  },
  {
    question: "How do I reset my scale's zero level?",
    answer:
      'Remove the item from the scale (or leave your empty container on it), connect to the “Bottomless_Scale” Wi-Fi, and select “Set Zero Level”.',
  },
];

const ScaleFaqComponent = () => {
  const { cw } = useCustomWording();

  return (
    <div className="text-center mt-5">
      <h2 className="text-center mb-5">FAQ & Troubleshooting</h2>
      {FAQ.map(({ question, answer }) => (
        <FaqElement key={question} question={cw(question)} answer={cw(answer)} />
      ))}
      <div className="mb-5">
        <div className="font-weight-bold mb-3">Need more help?</div>
        <div>
          Contact{' '}
          <strong>
            <a href="mailto:support@bottomless.com" title="E-mail support" className="text-dark text-decoration-none">
              support@bottomless.com
            </a>
          </strong>{' '}
          or text{' '}
          <strong>
            <a href="sms:+12062600782" title="Text support" className="text-nowrap text-dark text-decoration-none">
              (206) 260-0782
            </a>
          </strong>
        </div>
      </div>
    </div>
  );
};

export const ScaleFaq = withCustomWording(customWording, ScaleFaqComponent);
