export const OrderSources = {
  USER_ONE_OFF: 'user_one_off',
  USER: 'user',
  DUMB_SUBSCRIPTION: 'dumb_subscription',
  OPERATIONS: 'operations',
  REPLACED: 'replaced',
  LOST: 'lost',
};

export const OrderStatuses = {
  Initiated: 'initiated',
  Cancelled: 'cancelled',
  Offboarded: 'offboarded',
  Replaced: 'replaced',
  Paid: 'paid',
  SubproductGenerated: 'subproduct_generated',
  WithoutShipment: 'without_shipment',
  Shipment: 'shipment',
  BatchSent: 'batch_sent',
  Fulfilled: 'fulfilled',
  SentToRoaster: 'sent_to_roaster',
  Refunded: 'refunded',
};

export const OrderShippingStatuses = {
  Delivered: 'delivered',
};

export const UnfulfilledOrderStatuses = [
  OrderStatuses.Initiated,
  OrderStatuses.SubproductGenerated,
  OrderStatuses.Paid,
];
