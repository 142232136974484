import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DataLoading } from '@bottomless/common/components';
import { Categories } from '@bottomless/common/constants';
import { useDataEffect } from '@bottomless/common/hooks';
import {
  getPersonalizedProductAction,
  getProductOriginsAction,
  setPersonalizedAction,
  getDynamicPricesAction,
  getSingleProductAction,
  getTastingNoteCategoriesAction,
  getProductsAction,
} from '../../store';
import { addToastAction, getVendorManifestAction } from '@bottomless/common/store';
import { getWhitelistVendorsAction } from '../../store/vendor';
import { ProductForm } from './components';

const FilterCheckoutEditComponent = ({
  getOrigins,
  personalizedProduct,
  getPersonalizedProduct,
  vendorId,
  vendorManifest,
  getVendorManifest,
  isLoading,
  personalized: personalizedProp,
  pricingRule,
  getDynamicPrices,
  getVendors,
  getTastingNotes,
  onSubmit,
  onSuccess,
  getProduct,
  firstProductText,
  advancedOpened,
  addToast,
  me,
  getProducts,
  products,
  back,
}) => {
  const [origins, setOrigins] = useState();
  const [allVendors, setAllVendors] = useState(null);
  const [vendors, setVendors] = useState(null);
  const [tastingNotes, setTastingNotes] = useState(null);

  const personalized = personalizedProp && {
    ...personalizedProp,
    ...(vendorManifest?.hasGeneralPricing ? { price_type: 'general', vendor: [vendorId] } : {}),
  };

  useDataEffect(getOrigins, setOrigins);
  useDataEffect(getVendors, ({ data }) => setAllVendors(data), me?.product?.product?.category?.slug || 'coffee');
  useDataEffect(getTastingNotes, setTastingNotes);

  useEffect(() => {
    if (vendorId) {
      getVendorManifest({ vendorId });
    }
  }, [vendorId, getVendorManifest]);

  useEffect(() => {
    if (allVendors) {
      const cleanedVendors = Object.entries(
        allVendors.reduce(
          (all, { name, _id }) => ({
            ...all,
            [_id]: name,
          }),
          {}
        )
      ).map(([_id, name]) => ({ _id, name }));

      setVendors(cleanedVendors);
    }
  }, [allVendors]);

  useEffect(() => {
    getPersonalizedProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProducts({ category: Categories.Coffee });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DataLoading
        count={personalizedProduct && personalized && pricingRule && origins && vendors ? 1 : 0}
        isLoading={isLoading}
      />
      {personalized && pricingRule && personalizedProduct && origins && vendors && tastingNotes && (
        <ProductForm
          back={back}
          addToast={addToast}
          product={personalizedProduct}
          onSubmit={onSubmit}
          onSuccess={onSuccess}
          personalized={personalized}
          origins={origins}
          vendors={vendors}
          getDynamicPrices={getDynamicPrices}
          pricingRule={pricingRule}
          getProduct={getProduct}
          firstProductText={firstProductText}
          advancedOpened={advancedOpened}
          tastingCategories={tastingNotes}
          me={me}
          products={products}
          hasGeneralPricing={vendorManifest?.hasGeneralPricing}
          vendorId={vendorId}
        />
      )}
    </>
  );
};

FilterCheckoutEditComponent.propTypes = {
  getOrigins: PropTypes.func.isRequired,
  getPersonalizedProduct: PropTypes.func.isRequired,
  setPersonalizedAction: PropTypes.func.isRequired,
  personalizedProduct: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  getDynamicPrices: PropTypes.func.isRequired,
  getVendorRotations: PropTypes.func,
  getVendors: PropTypes.func.isRequired,
  getTastingNotes: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  personalized: PropTypes.object.isRequired,
  pricingRule: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  firstProductText: PropTypes.string.isRequired,
  advancedOpened: PropTypes.bool,
  me: PropTypes.object,
  getProducts: PropTypes.func.isRequired,
  products: PropTypes.array,
  back: PropTypes.string,
  vendorId: PropTypes.string,
  vendorManifest: PropTypes.shape({
    hasGeneralPricing: PropTypes.bool,
  }),
  getVendorManifest: PropTypes.func,
};

export const FilterCheckoutEdit = connect(
  ({ product: { personalized, isLoading }, user: { me }, product, manifest: { vendorManifest } }) => ({
    personalizedProduct: personalized,
    products: product.data.filter(product => product.category?.slug === Categories.Coffee),
    isLoading,
    me,
    vendorManifest,
  }),
  dispatch => ({
    getOrigins: () => dispatch(getProductOriginsAction(true)),
    getPersonalizedProduct: () => dispatch(getPersonalizedProductAction()),
    setPersonalizedAction: (id, data) => dispatch(setPersonalizedAction(id, data)),
    addToast: message => dispatch(addToastAction(message)),
    getVendors: data => dispatch(getWhitelistVendorsAction(data)),
    getDynamicPrices: data => dispatch(getDynamicPricesAction(data)),
    getProduct: id => dispatch(getSingleProductAction(id)),
    getTastingNotes: () => dispatch(getTastingNoteCategoriesAction()),
    getProducts: ({ category }) => dispatch(getProductsAction({ category })),
    getVendorManifest: params => dispatch(getVendorManifestAction(params)),
  })
)(FilterCheckoutEditComponent);
