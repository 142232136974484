export const ProductRoasts = {
  Light: 'light',
  Dark: 'dark',
};

export const ProductTags = {
  Espresso: 'espresso',
};

export const ProductStatuses = {
  Active: 'active',
  Archived: 'archived',
};
