import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Box } from '@bottomless/common/components';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import './ConnectDisconnected.scss';
import { X } from 'react-feather';

const ConnectDisconnectedComponent = () => (
  <div className="page-connect-connected">
    <h1 className="text-center mb-4">Activate your smart scale</h1>
    <Box secondary className="mb-4">
      <h2 className="text-center mb-5">Your scale is active and linked successfully.</h2>
      <div className="d-flex justify-content-center mb-3">
        <span className="text-white success-checkmark bg-danger d-flex align-items-center justify-content-center rounded-circle">
          <X className="mt-1" />
        </span>
      </div>
      <div className="text-center">
        <div className="text-uppercase small text-secondary">Scale status</div>
        <div className="mb-5 font-weight-bold scale-status-text">Disconnected</div>
      </div>
      <div className="text-center">
        If you encounter some difficulties with the scale, check our troubleshooting guide{' '}
        <a href="https://support.bottomless.com/en/articles/909568" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </div>
    </Box>
    <Row className="mb-4">
      <Col>
        <Link to="/activate/step2?flow=new_wifi" className="btn btn-lg btn-dark btn-block">
          Sync to new Wi-Fi
        </Link>
      </Col>
    </Row>
    <div className="text-center">
      <Link to="/activate/step1?newScale">I want to connect another scale</Link>
    </div>
  </div>
);

export const ConnectDisconnectedPage = withMetaTags({
  title: 'Bottomless.com: Activate your smart scale',
})(ConnectDisconnectedComponent);
