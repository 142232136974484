import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card } from 'reactstrap';

const answers = [
  {
    name: "I'm almost out",
    value: 'dateOverride:0',
    event: { dateOverride: '0' },
  },
  {
    name: '1 week',
    value: 'dateOverride:nextBusiness',
    event: { dateOverride: 'nextBusiness' },
  },
  {
    name: '2 weeks',
    value: 'dateOverride:1',
    event: { dateOverride: '1' },
  },
  {
    name: '3 weeks',
    value: 'dateOverride:2',
    event: { dateOverride: '2' },
  },
  {
    name: '4+ weeks',
    value: 'dateOverride:3',
    event: { dateOverride: '3' },
  },
];

export const CurrentStock = ({ onAnswer }) => (
  <Container className="mt-4 current-stock-question">
    <h2>What is your current stock?</h2>
    <Row>
      {answers.map((answer, i) => (
        <Col key={i} xs="12">
          <Card
            body
            tag="button"
            className="d-flex flex-row align-items-center question-text mb-3"
            onClick={() => onAnswer(answer)}
          >
            <div className={`answer-name ${answer.name}`}>{answer.name}</div>
          </Card>
        </Col>
      ))}
    </Row>
  </Container>
);

CurrentStock.propTypes = {
  onAnswer: PropTypes.func.isRequired,
};
