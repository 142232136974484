import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetCategoryType = createAction('Categories', 'Get category');

export const getCategoryAction = slug => ({
  [RSAA]: {
    endpoint: `categories/${slug}`,
    method: 'GET',
    types: [GetCategoryType.REQUEST, GetCategoryType.SUCCESS, GetCategoryType.FAILURE],
  },
});
