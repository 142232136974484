import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Heart } from 'react-feather';
import { Col, Card, CardBody, CardTitle, CardImg, Button, CardFooter } from 'reactstrap';
import { VariantPrice } from '@bottomless/common/components';

const ProductComponent = ({ product, history, pricingRule, isFirstBag, onChooseProduct, hasCustomStyles, me }) => {
  const likesProportion = useMemo(() => {
    const likes = product.likes || 0;
    const dislikes = product.dislikes || 0;

    if (!likes && !dislikes) {
      return null;
    }

    return likes + dislikes;
  }, [product.likes, product.dislikes]);

  const navigateToProductPage = () => {
    history.push(`/products/${product.slug}?vendor=true`);
  };

  return (
    <Col xs="12" sm="6" md="6" lg="4" className="mb-3">
      <div className="h-100" onClick={navigateToProductPage}>
        <Card className="h-100">
          <div className="d-flex align-items-center h-100">
            <CardImg top width="50" className="product-image" src={product.small_image_src} alt={product.name} />
            <CardBody className="d-flex flex-column h-100 justify-content-between pl-0">
              <CardTitle>
                <h6 className="text-capitalize">{product.name}</h6>
              </CardTitle>
              <div>
                {product.variants
                  .filter(v => v.available)
                  .sort((a, b) => (a.size > b.size ? 1 : -1))
                  .map((variant, i) => (
                    <Col xs="12" key={i} className="pl-0">
                      <span className="text-secondary">{variant.size}oz</span> -{' '}
                      <span className="font-weight-bold">
                        <VariantPrice
                          user={me}
                          variant={variant}
                          pricingRule={pricingRule}
                          isFirstBag={isFirstBag}
                          size={variant.size}
                        />
                      </span>
                    </Col>
                  ))}
              </div>
              <div>
                {(product.roast || product.origin) && (
                  <div className="d-flex flex-column mt-3 text-secondary">
                    {product.roast && <span>{product.roast.name} Roast</span>}
                    {product.origin && <span>{product.origin.name}</span>}
                  </div>
                )}
                {product.attributes?.tags && (
                  <div className="d-flex justify-content-center mb-3">
                    <span>{product.attributes.tags.join(', ')}</span>
                  </div>
                )}
              </div>
              {hasCustomStyles && (
                <Button
                  color="primary"
                  outline
                  size="sm"
                  onClick={e => onChooseProduct(e, product, { variant: product.variants[0]._id })}
                  className="mt-3"
                >
                  Buy Product
                </Button>
              )}
            </CardBody>
          </div>
          {!product.likes ? null : (
            <CardFooter className="d-flex justify-content-center">
              <span className="likes px-2 py-1">
                <span className="text-nowrap">
                  <Heart size="18" fill="#ec2d2d" color="#ec2d2d" className="mr-1" />
                  {product.likes}
                  {likesProportion !== null && (
                    <span className="ml-1 text-secondary">of {likesProportion} reviews</span>
                  )}
                </span>
              </span>
            </CardFooter>
          )}
        </Card>
      </div>
    </Col>
  );
};

ProductComponent.propTypes = {
  product: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pricingRule: PropTypes.object,
  isFirstBag: PropTypes.bool.isRequired,
  onChooseProduct: PropTypes.func.isRequired,
  hasCustomStyles: PropTypes.bool.isRequired,
  me: PropTypes.any,
};

export const Product = memo(ProductComponent);
