import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@bottomless/common/components';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import './ConnectConnected.scss';
import { Check } from 'react-feather';

const ConnectConnectedComponent = () => (
  <div className="page-connect-connected">
    <h1 className="text-center mb-4">Activate your smart scale</h1>
    <Box secondary className="mb-4">
      <h2 className="text-center mb-5">Your scale is active and linked successfully.</h2>
      <div className="d-flex justify-content-center mb-3">
        <span className="text-white success-checkmark d-flex align-items-center justify-content-center rounded-circle">
          <Check className="mt-1" />
        </span>
      </div>
      <div className="text-center">
        <div className="text-uppercase small text-secondary">Scale status</div>
        <div className="mb-5 font-weight-bold scale-status-text">Connected</div>
      </div>
      <div className="text-center">It might take a few hours before you see any updates in your profile.</div>
    </Box>

    <Link to="/activate/step2?flow=new_wifi" className="btn btn-lg btn-dark btn-block mb-4">
      Sync to new Wi-Fi
    </Link>
    <Link to="/activate/tare" className="btn btn-lg btn-outline-dark btn-block mb-4">
      Set &quot;zero level&quot;
    </Link>

    <div className="text-center">
      <Link to="/activate/step1?newScale">I want to connect another scale</Link>
    </div>
  </div>
);

export const ConnectConnectedPage = withMetaTags({
  title: 'Bottomless.com: Activate your smart scale',
})(ConnectConnectedComponent);
