import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { Container } from 'reactstrap';
import './PricingBanner.scss';

export const PricingBanner = ({ pricingRule }) => {
  const [{ promocode }] = useCookies(['promocode']);

  const isDiscount = useMemo(
    () =>
      pricingRule &&
      (pricingRule.first_bag_price ||
        pricingRule.first_bag_discount ||
        pricingRule.discount_fixed ||
        pricingRule.discount_proportion),
    [pricingRule]
  );

  if (!promocode && !pricingRule?.banner && !pricingRule?.description) {
    return null;
  }

  return (
    <div className="pricing-banner py-2 small text-center">
      <Container>
        <div className="pricing-banner-heading">
          {promocode && (
            <>
              <span className="font-weight-bold">CODE: {promocode}</span>
              <span className="mx-2">-</span>
            </>
          )}
          {pricingRule?.banner || pricingRule?.description}
          {isDiscount && <div className="discount-text">(discount automatically applied at checkout)</div>}
        </div>
      </Container>
    </div>
  );
};

PricingBanner.propTypes = {
  pricingRule: PropTypes.shape({
    banner: PropTypes.string,
    description: PropTypes.string,
    first_bag_price: PropTypes.number,
    first_bag_discount: PropTypes.number,
    discount_fixed: PropTypes.number,
    discount_proportion: PropTypes.number,
  }),
};
