import React from 'react';
import PropTypes from 'prop-types';
import { FeatureSection } from '../../../../components/FeatureSection/FeatureSection';

export const BestPriceSection = ({ vendor }) => (
  <FeatureSection
    text="We guarantee the best price in the market. There are no hidden markups."
    title="Best price guaranteed, no markups"
    image={vendor.custom_styles.images.bestPrice}
  />
);

BestPriceSection.propTypes = {
  vendor: PropTypes.object.isRequired,
};
