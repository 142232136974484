import React from 'react';
import PropTypes from 'prop-types';

export const CoffeeShipTime = ({ isMondayToThursday }) => (
  <div className="d-flex justify-content-center justify-content-lg-start">
    <img alt="Flash" src="https://bottomless.imgix.net/flash.svg" />
    <div className="text-success font-weight-bold ml-2">
      {isMondayToThursday ? 'Roasts and ships tomorrow at 4AM' : 'Roasts and ships on Monday at 4AM'}
    </div>
  </div>
);

CoffeeShipTime.propTypes = {
  isMondayToThursday: PropTypes.bool.isRequired,
};
