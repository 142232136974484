import React from 'react';
import { SlugCategoryManifest } from '../../../components/CategoryManifest';
import { CoffeeRequestProduct, GenericRequestProduct } from '../manifest';

const COMPONENTS = {
  CoffeeRequestProduct: CoffeeRequestProduct,
  GenericRequestProduct: GenericRequestProduct,
};

export const RequestProductForm = props => (
  <SlugCategoryManifest
    manifestKey="shopRequestProduct"
    components={COMPONENTS}
    noLoading
    fallbackComponent={GenericRequestProduct}
    {...props}
  />
);
