import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'reactstrap';
import classNames from 'classnames';
import { RotationProduct, withPlaceholder } from '@bottomless/common/components';
import { ChangeProduct } from './ChangeProduct';
import { CustomListProducts } from './CustomListProducts';

const PickProductComponent = ({
  checkout,
  goToRotation,
  goToPersonalized,
  goToShop,
  goToQuiz,
  goToCustomListShop,
  hideRotationImage,
  goToLandingPage,
  className,
}) => {
  if (!checkout.product) {
    return null;
  }

  const personalized = checkout.product?.product?.personalized_rotation && checkout.personalized;

  return (
    <>
      {!checkout.custom_list && (
        <Card body className={classNames('d-flex flex-row align-items-center justify-content-between', className)}>
          <RotationProduct
            product={checkout.product.product}
            variant={checkout.product.variant}
            isFirstBag={true}
            pricingRule={checkout.pricing_rule}
            firstProduct={checkout.first_product}
            personalized={personalized}
            grind={checkout.grind?.name}
            dynamicPricing={checkout.dynamicPricing}
            hideRotationImage={hideRotationImage && checkout.first_product}
            showPricing={false}
            withBorder={false}
          />
          <ChangeProduct
            checkout={checkout}
            goToRotation={goToRotation}
            goToPersonalized={goToPersonalized}
            goToShop={goToShop}
            goToQuiz={goToQuiz}
            goToCustomListShop={goToCustomListShop}
            goToLandingPage={goToLandingPage}
          />
        </Card>
      )}
      {checkout.custom_list && (
        <>
          <RotationProduct
            product={checkout.custom_list.products[0].product}
            variant={checkout.custom_list.products[0].variant}
            isFirstBag={true}
            pricingRule={checkout.pricing_rule}
            personalized={personalized}
            grind={checkout.grind?.name}
            customList={checkout.custom_list}
            showPricing={false}
            className={className}
          />
          {checkout.custom_list.products.length > 1 && (
            <CustomListProducts customList={checkout.custom_list} className={className} />
          )}
        </>
      )}
    </>
  );
};

PickProductComponent.propTypes = {
  goToPersonalized: PropTypes.func.isRequired,
  goToRotation: PropTypes.func.isRequired,
  goToShop: PropTypes.func.isRequired,
  goToQuiz: PropTypes.func.isRequired,
  checkout: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    product: PropTypes.object,
    product_grind: PropTypes.string,
    pricing_rule: PropTypes.object.isRequired,
    first_product: PropTypes.object,
    personalized: PropTypes.object,
    vendor_id: PropTypes.string,
    quiz_answers: PropTypes.string,
    grind: PropTypes.object,
    dynamicPricing: PropTypes.object,
    custom_list: PropTypes.object,
  }).isRequired,
  hideRotationImage: PropTypes.bool,
  goToCustomListShop: PropTypes.func.isRequired,
  goToLandingPage: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export const PickProduct = withPlaceholder({
  condition: 'checkout',
  props: {
    checkout: {
      product: {
        product: {
          name: 'Placeholder',
          vendor_name: 'Placeholder',
          variants: [{ _id: 'placeholder', size: 12 }],
        },
        variant: 'placeholder',
      },
      category: { name: 'coffee' },
      grind: { name: 'Whole Beans' },
    },
  },
})(PickProductComponent);
