export const serialize = data => {
  if (!data || typeof data !== 'object') {
    return JSON.stringify(data);
  }

  const limitedData = Object.entries(data).reduce(
    (all, [key, value]) => ({
      ...all,
      [key]: Array.isArray(value) ? value.slice(0, 50) : value,
    }),
    {}
  );

  return JSON.stringify(limitedData);
};
