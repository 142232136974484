import { createAction } from '@bottomless/common/src/store';
import { RSAA } from 'redux-api-middleware';

export const GetReferralHistoryTypes = createAction('Referral', 'Get history');

export const getReferralHistoryAction = ref => ({
  [RSAA]: {
    endpoint: `referral/${ref}/history`,
    method: 'GET',
    types: [GetReferralHistoryTypes.REQUEST, GetReferralHistoryTypes.SUCCESS, GetReferralHistoryTypes.FAILURE],
  },
});
