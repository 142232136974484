import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetMeTypes = createAction('Users', 'Get me');

export const getMeAction = () => ({
  [RSAA]: {
    endpoint: 'users/me',
    method: 'GET',
    types: [GetMeTypes.REQUEST, GetMeTypes.SUCCESS, GetMeTypes.FAILURE],
  },
});

export const GetAccountsTypes = createAction('Users', 'Get accounts');

export const getAccountsAction = () => ({
  [RSAA]: {
    endpoint: 'users/accounts',
    method: 'GET',
    types: [GetAccountsTypes.REQUEST, GetAccountsTypes.SUCCESS, GetAccountsTypes.FAILURE],
  },
});

export const GetReferralsTypes = createAction('Users', 'Get referrals');

export const getReferralsAction = () => ({
  [RSAA]: {
    endpoint: 'users/referrals',
    method: 'GET',
    types: [GetReferralsTypes.REQUEST, GetReferralsTypes.SUCCESS, GetReferralsTypes.FAILURE],
  },
});

export const InviteTypes = createAction('Users', 'Invite');

export const inviteAction = data => ({
  [RSAA]: {
    endpoint: 'users/invite',
    method: 'POST',
    body: data,
    types: [InviteTypes.REQUEST, InviteTypes.SUCCESS, InviteTypes.FAILURE],
  },
});

export const VerifyAddressTypes = createAction('Users', 'Verify address');

export const verifyAddressAction = data => ({
  [RSAA]: {
    endpoint: 'users/verify-address',
    method: 'POST',
    body: data,
    types: [VerifyAddressTypes.REQUEST, VerifyAddressTypes.SUCCESS, VerifyAddressTypes.FAILURE],
  },
});

export const TextAlertsTypes = createAction('Users', 'Text alerts');

export const textAlertsAction = data => ({
  [RSAA]: {
    endpoint: 'users/text-alerts',
    method: 'POST',
    body: data,
    types: [TextAlertsTypes.REQUEST, TextAlertsTypes.SUCCESS, TextAlertsTypes.FAILURE],
  },
});

export const VerifyPhoneTypes = createAction('Users', 'Verify phone');

export const verifyPhoneAction = data => ({
  [RSAA]: {
    endpoint: 'users/verify-phone',
    method: 'POST',
    body: data,
    types: [VerifyPhoneTypes.REQUEST, VerifyPhoneTypes.SUCCESS, VerifyPhoneTypes.FAILURE],
  },
});

export const SetupPhoneTypes = createAction('Users', 'Setup phone');

export const setupPhoneAction = data => ({
  [RSAA]: {
    endpoint: 'users/setup-phone',
    method: 'POST',
    body: data,
    types: [SetupPhoneTypes.REQUEST, SetupPhoneTypes.SUCCESS, SetupPhoneTypes.FAILURE],
  },
});

export const ResendVerificationCodeTypes = createAction('Users', 'Resend Verification Code');

export const resendVerificationCodeAction = data => ({
  [RSAA]: {
    endpoint: 'users/resend-confirmation-code',
    method: 'POST',
    body: data,
    types: [
      ResendVerificationCodeTypes.REQUEST,
      ResendVerificationCodeTypes.SUCCESS,
      ResendVerificationCodeTypes.FAILURE,
    ],
  },
});

export const PauseAccountTypes = createAction('Users', 'Pause account');

export const pauseAccountAction = data => ({
  [RSAA]: {
    endpoint: 'users/pause-account',
    method: 'POST',
    body: data,
    types: [PauseAccountTypes.REQUEST, PauseAccountTypes.SUCCESS, PauseAccountTypes.FAILURE],
  },
});

export const OrderingAggresionTypes = createAction('Users', 'Ordering aggression');

export const orderingAggresionAction = data => ({
  [RSAA]: {
    endpoint: 'users/ordering-aggression',
    method: 'POST',
    body: data,
    types: [OrderingAggresionTypes.REQUEST, OrderingAggresionTypes.SUCCESS, OrderingAggresionTypes.FAILURE],
  },
});

export const VerifyStripeTypes = createAction('Users', 'Verify stripe');

export const verifyStripeAction = data => ({
  [RSAA]: {
    endpoint: 'users/verify-stripe',
    method: 'POST',
    body: data,
    types: [VerifyStripeTypes.REQUEST, VerifyStripeTypes.SUCCESS, VerifyStripeTypes.FAILURE],
  },
});

export const GetMyProductTypes = createAction('Users', 'Get my product');

export const getMyProductAction = () => ({
  [RSAA]: {
    endpoint: `products/my`,
    method: 'GET',
    types: [GetMyProductTypes.REQUEST, GetMyProductTypes.SUCCESS, GetMyProductTypes.FAILURE],
  },
});

export const MakeBottomlessProductTypes = createAction('Users', 'Make bottomless product');

export const makeBottomlessProductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `products/${id}/make-bottomless`,
    method: 'post',
    body: data,
    types: [MakeBottomlessProductTypes.REQUEST, MakeBottomlessProductTypes.SUCCESS, MakeBottomlessProductTypes.FAILURE],
  },
});

export const SwitchVariantTypes = createAction('Users', 'Switch variant');

export const switchVariantAction = id => ({
  [RSAA]: {
    endpoint: `products/switch-variant/${id}`,
    method: 'post',
    types: [SwitchVariantTypes.REQUEST, SwitchVariantTypes.SUCCESS, SwitchVariantTypes.FAILURE],
  },
});

export const OverrideDateTypes = createAction('Orders', 'Override date');

export const overrideDateAction = (id, data) => ({
  [RSAA]: {
    endpoint: `orders/override-date${id ? `/${id}` : ''}`,
    method: 'POST',
    body: data,
    types: [OverrideDateTypes.REQUEST, OverrideDateTypes.SUCCESS, OverrideDateTypes.FAILURE],
  },
});

export const GetCleanDataTypes = createAction('Users', 'Get clean data');

export const getCleanedDataAction = () => ({
  [RSAA]: {
    endpoint: 'users/clean-data',
    method: 'GET',
    types: [GetCleanDataTypes.REQUEST, GetCleanDataTypes.SUCCESS, GetCleanDataTypes.FAILURE],
  },
});

export const GetUserStatsTypes = createAction('Users', 'Get user stats');

export const getUserStatsAction = () => ({
  [RSAA]: {
    endpoint: `users/stats`,
    method: 'GET',
    types: [GetUserStatsTypes.REQUEST, GetCleanDataTypes.SUCCESS, GetUserStatsTypes.FAILURE],
  },
});

export const GetUserCleanDataTypes = createAction('Users', 'Get user clean data');

export const getUserCleanedDataAction = id => ({
  [RSAA]: {
    endpoint: `users/clean-data/${id}`,
    method: 'GET',
    types: [GetUserCleanDataTypes.REQUEST, GetUserCleanDataTypes.SUCCESS, GetUserCleanDataTypes.FAILURE],
  },
});

export const GetUserOrdersDataTypes = createAction('Users', 'Get user orders data');

export const getUserOrdersDataAction = id => ({
  [RSAA]: {
    endpoint: `users/orders-data/${id}`,
    method: 'GET',
    types: [GetUserOrdersDataTypes.REQUEST, GetUserOrdersDataTypes.SUCCESS, GetUserOrdersDataTypes.FAILURE],
  },
});

export const SetDumbPeriodTypes = createAction('Users', 'Set dumb period');

export const setDumbPeriodAction = data => ({
  [RSAA]: {
    endpoint: `users/dumb-period`,
    method: 'POST',
    body: data,
    types: [SetDumbPeriodTypes.REQUEST, SetDumbPeriodTypes.SUCCESS, SetDumbPeriodTypes.FAILURE],
  },
});

export const SetAccountNameTypes = createAction('Users', 'Set account name');

export const setAccountNameAction = data => ({
  [RSAA]: {
    endpoint: `users/name`,
    method: 'POST',
    body: data,
    types: [SetAccountNameTypes.REQUEST, SetAccountNameTypes.SUCCESS, SetAccountNameTypes.FAILURE],
  },
});

export const GetCreditsTypes = createAction('Users', 'Get credits');

export const getCreditsAction = () => ({
  [RSAA]: {
    endpoint: 'credits',
    method: 'GET',
    types: [GetCreditsTypes.REQUEST, GetCreditsTypes.SUCCESS, GetCreditsTypes.FAILURE],
  },
});

export const CloseAccountTypes = createAction('Users', 'Close account');

export const closeAccountAction = data => ({
  [RSAA]: {
    endpoint: `users/close-account`,
    method: 'POST',
    body: data,
    types: [CloseAccountTypes.REQUEST, CloseAccountTypes.SUCCESS, CloseAccountTypes.FAILURE],
  },
});

export const GetByRefTypes = createAction('Users', 'Get user by ref');

export const getByRefAction = ref => ({
  [RSAA]: {
    endpoint: `referral/${ref}`,
    method: 'GET',
    types: [GetByRefTypes.REQUEST, GetByRefTypes.SUCCESS, GetByRefTypes.FAILURE],
  },
});

export const GetInfluencerTypes = createAction('Users', 'Get influencer');

export const getInfluencerAction = id => ({
  [RSAA]: {
    endpoint: `influencer/${id}`,
    method: 'GET',
    types: [GetInfluencerTypes.REQUEST, GetInfluencerTypes.SUCCESS, GetInfluencerTypes.FAILURE],
  },
});

export const UpgradeMembershipTypes = createAction('Users', 'Upgrade membership');

export const upgradeMembershipAction = data => ({
  [RSAA]: {
    endpoint: `users/membership`,
    method: 'POST',
    body: data,
    types: [UpgradeMembershipTypes.REQUEST, UpgradeMembershipTypes.SUCCESS, UpgradeMembershipTypes.FAILURE],
  },
});

export const SubscribeTypes = createAction('Users', 'Subscribe to a membership');

export const subscribeAction = data => ({
  [RSAA]: {
    endpoint: `users/subscribe`,
    method: 'POST',
    body: data,
    types: [SubscribeTypes.REQUEST, SubscribeTypes.SUCCESS, SubscribeTypes.FAILURE],
  },
});

export const GetMembershipsTypes = createAction('Users', 'Get memberships');

export const getMembershipsAction = () => ({
  [RSAA]: {
    endpoint: `users/memberships`,
    method: 'GET',
    types: [GetMembershipsTypes.REQUEST, GetMembershipsTypes.SUCCESS, GetMembershipsTypes.FAILURE],
  },
});

export const ShareDataTypes = createAction('Users', 'Share data');

export const shareDataAction = data => ({
  [RSAA]: {
    endpoint: `users/share-data`,
    method: 'POST',
    body: data,
    types: [ShareDataTypes.REQUEST, ShareDataTypes.SUCCESS, ShareDataTypes.FAILURE],
  },
});

export const GetActiveDevicesTypes = createAction('Users', 'Get active devices');

export const getActiveDevicesAction = () => ({
  [RSAA]: {
    endpoint: `users/active-devices`,
    method: 'GET',
    types: [GetActiveDevicesTypes.REQUEST, GetActiveDevicesTypes.SUCCESS, GetActiveDevicesTypes.FAILURE],
  },
});

export const GetAlertSettingsTypes = createAction('Users', 'Get alert settings');

export const getAlertSettingsAction = () => ({
  [RSAA]: {
    endpoint: `users/alert-settings`,
    method: 'GET',
    types: [GetAlertSettingsTypes.REQUEST, GetAlertSettingsTypes.SUCCESS, GetAlertSettingsTypes.FAILURE],
  },
});

export const SetAlertSettingsTypes = createAction('Users', 'Set alert settings');

export const setAlertSettingsAction = data => ({
  [RSAA]: {
    endpoint: `users/alert-settings`,
    method: 'POST',
    body: data,
    types: [SetAlertSettingsTypes.REQUEST, SetAlertSettingsTypes.SUCCESS, SetAlertSettingsTypes.FAILURE],
  },
});

export const UPDATE_LAST_CREDIT_CARD_ALERT = 'Users/Update last credit card';

export const updateLastCreditCardAlert = () => ({
  type: UPDATE_LAST_CREDIT_CARD_ALERT,
  payload: new Date(),
});

export const RequestScaleAssistanceTypes = createAction('Users', 'Request scale assistance');

export const requestScaleAssistanceAction = data => ({
  [RSAA]: {
    endpoint: `users/scale-assistance`,
    method: 'POST',
    body: data,
    types: [
      RequestScaleAssistanceTypes.REQUEST,
      RequestScaleAssistanceTypes.SUCCESS,
      RequestScaleAssistanceTypes.FAILURE,
    ],
  },
});

export const ReopenAccountTypes = createAction('Users', 'Reopen account');

export const reopenAccountAction = () => ({
  [RSAA]: {
    endpoint: `users/reopen`,
    method: 'POST',
    types: [ReopenAccountTypes.REQUEST, ReopenAccountTypes.SUCCESS, ReopenAccountTypes.FAILURE],
  },
});

export const GetScaleShipmentTypes = createAction('Users', 'Get onboarding scale shipment');

export const getScaleShipmentAction = () => ({
  [RSAA]: {
    endpoint: 'users/scale-shipment',
    method: 'GET',
    types: [GetScaleShipmentTypes.REQUEST, GetScaleShipmentTypes.SUCCESS, GetScaleShipmentTypes.FAILURE],
  },
});

export const GetChargesTypes = createAction('Users', 'Get charges');

export const getChargesAction = () => ({
  [RSAA]: {
    endpoint: 'users/charges',
    method: 'GET',
    types: [GetChargesTypes.REQUEST, GetChargesTypes.SUCCESS, GetChargesTypes.FAILURE],
  },
});

export const setSlowLocalDeliveryInstructionsTypes = createAction('Slow Local Orders', 'Delivery Instructions');

export const setSlowLocalDeliveryInstructionsAction = data => ({
  [RSAA]: {
    endpoint: 'users/slow-local-delivery-instructions',
    method: 'POST',
    body: data,
    types: [
      setSlowLocalDeliveryInstructionsTypes.REQUEST,
      setSlowLocalDeliveryInstructionsTypes.SUCCESS,
      setSlowLocalDeliveryInstructionsTypes.FAILURE,
    ],
  },
});

export const GetUserVendorTypes = createAction('Users', 'Get User Vendor');

export const getUserVendorAction = id => ({
  [RSAA]: {
    endpoint: `vendors/id/${id}`,
    method: 'GET',
    types: [GetUserVendorTypes.REQUEST, GetUserVendorTypes.SUCCESS, GetUserVendorTypes.FAILURE],
  },
});

export const CLEAR_USER_VENDOR = 'Users/Clear vendor';

export const clearUserVendorAction = () => ({
  type: CLEAR_USER_VENDOR,
});
