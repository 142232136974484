import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

export const Close = ({ path }) => (
  <Link to={{ pathname: path || '/profile' }}>
    <Button color="primary" outline size="sm">
      Close
    </Button>
  </Link>
);

Close.propTypes = {
  path: PropTypes.string,
};
