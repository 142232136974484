import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@bottomless/common/components';
import { Link, useHistory } from 'react-router-dom';
import './Queue.scss';
import { Button } from 'reactstrap';

const AVATARS_AMOUNT = 5;

export const Queue = ({ queue, children, editable = true, onEditClick, isQueueEmpty }) => {
  const history = useHistory();
  const avatarElements = useMemo(() => queue.slice(0, AVATARS_AMOUNT).reverse(), [queue]);
  const restElementsAmount = useMemo(() => queue.length - avatarElements.length, [queue, avatarElements]);

  const onClickQueue = useCallback(() => {
    history.push('/shop?editQueue=true');
  }, [history]);

  return (
    <div className="d-flex flex-column justify-content-between flex-grow-1">
      <div className="mb-3 d-flex align-items-center">
        <div className="font-weight-bold">Queue</div>
        {children}
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center cursor-pointer" onClick={onEditClick || onClickQueue}>
          <div className="d-flex flex-row-reverse queue-avatars">
            {avatarElements.map(element => (
              <div key={element._id} className="p-2 bg-white border rounded-circle queue-avatar">
                <Image src={element.product.small_image_src} alt={element.product.name} height="45" />
              </div>
            ))}
          </div>
          {restElementsAmount > 0 && <div className="text-secondary queue-more-items">+{restElementsAmount}</div>}
          {isQueueEmpty && !avatarElements.length && (
            <div className="small text-secondary">
              There are no items in your queue, your next order will be your default product.
            </div>
          )}
        </div>
        {editable && (
          <>
            {onEditClick && (
              <Button size="sm" color="primary" outline onClick={onEditClick}>
                Edit
              </Button>
            )}
            {!onEditClick && (
              <Link to={isQueueEmpty ? '/shop' : '/shop?editQueue=true'} className="btn btn-sm btn-outline-primary">
                Edit
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
};

Queue.propTypes = {
  queue: PropTypes.array.isRequired,
  me: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  editable: PropTypes.bool,
  onEditClick: PropTypes.func,
  isQueueEmpty: PropTypes.bool,
};
