import { Checkbox } from '@bottomless/common/src/components/Form';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useProductsCount } from '../hooks/use-products-count.hook';

export const FilterCheckbox = ({
  name,
  label,
  value,
  title,
  onChange,
  products,
  filters,
  filterName,
  intersect,
  hideNumber,
}) => {
  const amount = useProductsCount(products, {
    ...filters,
    [filterName]: [...(intersect ? (filters[filterName] || []).filter(filter => filter !== value) : []), value],
  });

  return (
    <Checkbox
      type="checkbox"
      name={name}
      label={
        <span className={classNames('font-weight-normal', { 'filter-disabled': !amount })}>
          {label} {!hideNumber && <span className="text-secondary">({amount})</span>}
        </span>
      }
      value={value}
      onChange={onChange}
      title={title}
    />
  );
};

FilterCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  title: PropTypes.string,
  intersect: PropTypes.bool,
  hideNumber: PropTypes.bool,
};
