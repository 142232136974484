import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { UserAvatar } from '../UserAvatar';
import { ProductName } from './components';
import { VariantPrice } from '../VariantPrice';
import { VariantAttributes } from '../VariantAttributes/VariantAttributes';
import { Price } from '../Price';

export const UserProduct = ({ user, withSizeAndGrind = true, avatarProps = {}, withPrice }) => {
  const product = useMemo(() => user.product.product, [user]);
  const variant = useMemo(() => product.variants.find(variant => variant._id === user.product.variant), [
    user,
    product,
  ]);

  return (
    <div className="d-flex align-items-center">
      <UserAvatar user={user} height="75" className="mr-2" {...avatarProps} />
      <div>
        <ProductName user={user} />
        {withSizeAndGrind && (
          <div className="extra-small text-secondary">
            <VariantAttributes productVariant={user.product} grind={user?.grind} />
          </div>
        )}
        {withPrice && (
          <div className="extra-small text-secondary">
            {user.dynamicPricing?.price ? (
              <Price value={user.dynamicPricing.price} pricingRule={user.pricing_rule} hideDiscount />
            ) : (
              <VariantPrice user={user} variant={variant} pricingRule={user.pricing_rule} hideDiscount />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

UserProduct.propTypes = {
  user: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.shape({
        variants: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string.isRequired })).isRequired,
      }).isRequired,
      variant: PropTypes.string.isRequired,
    }).isRequired,
    grind: PropTypes.shape({
      name: PropTypes.string,
    }),
    pricing_rule: PropTypes.object,
    dynamicPricing: PropTypes.shape({
      price: PropTypes.string,
    }),
  }).isRequired,
  withSizeAndGrind: PropTypes.bool,
  avatarProps: PropTypes.object,
  withPrice: PropTypes.bool,
};
