import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createHashTag } from '@bottomless/common/utils';
import { NewVendorTag } from '../../../components/NewVendorTag';
import classNames from 'classnames';

const reorderProductTags = tags => {
  if (!tags || !tags.length) {
    return null;
  }

  const reorderedTags = [];
  let originalTags = tags;

  // eslint-disable-next-line
  tags.map(tag => {
    if (tag.name === 'Decaf') {
      originalTags = originalTags.filter(tag => tag.name !== 'Decaf');
      reorderedTags.push(tag);
    }

    if (tag.name === 'Espresso') {
      originalTags = originalTags.filter(tag => tag.name !== 'Espresso');
      reorderedTags.push(tag);
    }
  });

  originalTags.map(tag => reorderedTags.push(tag));
  return reorderedTags;
};

export const Parameters = ({
  product,
  withDetails,
  onFilterClick,
  featuredTags,
  isVendorLandingShop,
  checkout,
  me,
}) => {
  const avgAltitude = ((product.altitude_max || 0) + (product.altitude_min || 0)) / 2;
  const [productTags, setProductTags] = useState();

  const filterClick = (type, id) => e => {
    e.preventDefault();
    e.stopPropagation();
    onFilterClick && onFilterClick(type, id);
  };

  const likesProportion = useMemo(() => {
    const likes = product.likes || 0;
    const dislikes = product.dislikes || 0;

    if (!likes && !dislikes) {
      return null;
    }

    return likes + dislikes;
  }, [product.likes, product.dislikes]);

  useEffect(() => {
    setProductTags(reorderProductTags(product?.tags));
  }, [product]);

  return (
    <>
      {!product.likes ? null : (
        <div className="text-nowrap small mb-2">
          <i className="fa fa-heart text-danger" /> {product.likes}
          {likesProportion !== null && <span className="ml-1 text-secondary">of {likesProportion} reviews</span>}
        </div>
      )}
      <div className="product-card-parameters">
        {!product.rotating && (
          <>
            {product.roast && (
              <>
                <a
                  href={createHashTag(product.roast.name)}
                  className="small font-weight-bold"
                  disabled={isVendorLandingShop}
                  role="button"
                  onClick={filterClick('roast', product.roast._id)}
                >
                  {createHashTag(`${product.roast.name.replace(/roast/i, '')} Roast`)}
                </a>{' '}
              </>
            )}
            {product.origin && (
              <>
                <a
                  href={createHashTag(product.origin.name)}
                  className="small font-weight-bold"
                  disabled={isVendorLandingShop}
                  onClick={filterClick('origin', product.origin._id)}
                >
                  {createHashTag(product.origin.name)}
                </a>{' '}
              </>
            )}
          </>
        )}
        {product.rotating && product.description && <div dangerouslySetInnerHTML={{ __html: product.description }} />}
        {productTags?.map(tag => (
          <>
            <a
              key={tag._id}
              href={createHashTag(tag.name)}
              className={classNames('small', {
                'text-primary font-weight-bold': tag.name === 'Decaf' || tag.name === 'Espresso',
              })}
              disabled={isVendorLandingShop}
              onClick={filterClick((featuredTags || []).includes(tag._id) ? 'type' : 'tag', tag._id)}
            >
              {createHashTag(tag.name)}
            </a>{' '}
          </>
        ))}
        {!product.vendor_id || me?.vendor_id || checkout?.source === 'shopify' ? null : (
          <>
            <NewVendorTag vendor={product.vendor_id} />{' '}
          </>
        )}
        {withDetails && (
          <>
            {product.tasting_notes &&
              product.tasting_notes.map(note => (
                <>
                  <a
                    key={note._id}
                    href={createHashTag(note.name)}
                    className="small"
                    onClick={filterClick('tasting_notes', note._id)}
                    disabled={isVendorLandingShop}
                  >
                    {createHashTag(note.name)}
                  </a>{' '}
                </>
              ))}
            {avgAltitude !== 0 && (
              <>
                <span className="small text-secondary non-link-tag">{createHashTag(`${avgAltitude} masl`)}</span>{' '}
              </>
            )}
            {product.size && (
              <>
                <a
                  href={createHashTag(`${product.size}oz`)}
                  className="small"
                  onClick={filterClick('size', product.size)}
                  disabled={isVendorLandingShop}
                >
                  {createHashTag(`${product.size}oz`)}
                </a>{' '}
              </>
            )}
            {product.process && (
              <>
                <a
                  href={createHashTag(product.process.name)}
                  className="small"
                  onClick={filterClick('process', product.process._id)}
                  disabled={isVendorLandingShop}
                >
                  {createHashTag(product.process.name)}
                </a>{' '}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

Parameters.propTypes = {
  product: PropTypes.object.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  withDetails: PropTypes.bool,
  featuredTags: PropTypes.array,
  isVendorLandingShop: PropTypes.bool,
  checkout: PropTypes.object,
  me: PropTypes.object,
};

Parameters.defaultProps = {
  withDetails: false,
  onFilterClick: () => {},
  isVendorLandingShop: false,
};
