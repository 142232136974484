import { AddAlertType, RemoveAlertType } from './alert.actions';

const initialState = {
  data: [],
};

export const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case AddAlertType:
      return {
        ...state,
        data: [...state.data, action.payload],
      };

    case RemoveAlertType:
      return {
        ...state,
        data: state.data.filter(({ id }) => id !== action.payload.id),
      };

    default:
      return state;
  }
};
