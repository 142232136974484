import React, { useCallback, useMemo, useState } from 'react';
import { useToggle } from '@bottomless/common/hooks';
import { Image } from '@bottomless/common/components';
import PropTypes from 'prop-types';
import {
  Button,
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

export const PhotoModal = ({ photos, imageSize = 70 }) => {
  const [isOpen, toggle] = useToggle();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const items = useMemo(() => photos.map(src => ({ src, altText: 'Review Photo' })), [photos]);

  const toggleAndSetActiveIndex = useCallback(
    index => () => {
      setActiveIndex(index);
      toggle();
    },
    [toggle, setActiveIndex]
  );

  const next = useCallback(() => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }, [items, activeIndex, animating, setActiveIndex]);

  const previous = useCallback(() => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }, [items, activeIndex, animating, setActiveIndex]);

  const goToIndex = useCallback(
    newIndex => {
      if (animating) return;
      setActiveIndex(newIndex);
    },
    [animating, setActiveIndex]
  );

  return (
    <>
      <div className="pictures">
        {items.map((item, i) => (
          <Button key={i} color="text" onClick={toggleAndSetActiveIndex(i)} className="mr-3 mb-3 p-0">
            <Image alt={item.altText} src={item.src} width={imageSize} height={imageSize} />
          </Button>
        ))}
      </div>
      <Modal size="xl" isOpen={isOpen} toggle={toggle} className="modal-review-photos">
        <ModalHeader toggle={toggle}>Customer&apos;s Photos</ModalHeader>
        <ModalBody>
          <Carousel interval={false} activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {items.map(item => (
              <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.src}>
                <img src={item.src} alt={item.altText} />
              </CarouselItem>
            ))}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </Carousel>
        </ModalBody>
      </Modal>
    </>
  );
};

PhotoModal.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageSize: PropTypes.number,
};
