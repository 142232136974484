import React, { useMemo, useCallback, useState, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';
import { CheckCircle, Circle } from 'react-feather';
import classNames from 'classnames';
import { Form, Field, SubmitButton, VariantPrice, Box } from '@bottomless/common/components';
import { UserStatuses } from '@bottomless/common/src/constants';
import { useToggle } from '@bottomless/common/hooks';
import { fromOz, getCleanNumericalValue } from '@bottomless/common/utils';
import { getProductPrice } from '../../../utils/price';
import { SizeWarningModalBody } from './SizeWarningModal';
import { GrindWarningModalBody } from './GrindWarningModal';

import './MakeBottomless.scss';

const MakeBottomlessSchema = Yup.object().shape({
  grind: Yup.string(),
  variant: Yup.string(),
  oneOff: Yup.bool(),
  addToList: Yup.bool(),
});

const BUTTON_TYPE = {
  SET_DEFAULT: 'set-default',
  CUSTOM_LIST: 'custom-list',
  ONE_OFF: 'one-off',
  SELECT: 'select',
};

const DEFAULT_CEILING_AMOUNT = 12 * 1.5;

export const MakeBottomless = ({
  product,
  priceRule,
  isFirstBag,
  onChooseProduct,
  onOneOffOrder,
  onChooseSuccess,
  size,
  grinds,
  me,
  hasOneOff,
  hasCustomLists,
  additionalActionButton,
  onFormChange,
  initialValues = {},
  selectText = 'Select',
  oneOffText = 'Buy this one off',
  setDefaultText = 'Set this as default',
  hasMakeBottomless = true,
  outOfStock = false,
  setSelectedGrind,
  setSelectedVariant,
  isProductPage = false,
  addToCustomList,
  selectedGrind,
  selectedVariant,
  productSelection,
  saveProductSelection,
  location,
  userGrind,
  expressCheckoutButton,
  category,
  onlyMappedVariants,
  checkout: checkoutData,
}) => {
  const [typeSubmitting, setTypeSubmitting] = useState();
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [warningModal, toggleWarningModal, setWarningModal] = useToggle(false);
  const [sizeWarningModal, toggleSizeWarningModal, setSizeWarningModal] = useToggle(false);
  const [selectedVariantId, setSelectedVariantId] = useState();
  const [{ gift, checkout }] = useCookies(['gift', 'checkout']);
  const queryParse = qs.parse(location.search);
  const grindFromSearch = queryParse && queryParse.grind;
  const shopPath = useMemo(
    () =>
      me ? `/shop${category ? `?category=${category}` : ''}` : `/public_shop${category ? `?category=${category}` : ''}`,
    [category, me]
  );

  const userDefaultSize = useMemo(() => {
    if (!me) {
      return;
    }
    if (me.product?.product?.rotating && me?.personalized?.size) {
      return me.personalized.size;
    }
    const variant = me.product?.product?.variants?.find(row => row._id === me.product.variant);
    return variant.size;
  }, [me]);

  useEffect(() => {
    const filtered = product.variants.filter(v => {
      if (gift) {
        const variantPrice = getProductPrice({ pricingRule: priceRule, variant: v, isFirstBag });
        return v.available && variantPrice / 100 <= DEFAULT_CEILING_AMOUNT;
      }
      return v.available;
    });
    setFilteredVariants(filtered);
  }, [gift, isFirstBag, priceRule, product.variants]);

  const chooseVariant = (setFieldValue, setFieldTouched, value) => e => {
    e.preventDefault();
    e.stopPropagation();
    setFieldValue('variant', value);
    setFieldTouched('variant');
    setSelectedVariantId(value);

    if (setSelectedVariant) {
      setSelectedVariant(value);
    }

    if (isProductPage) {
      if (productSelection.product === String(product._id)) {
        productSelection.variant = value;
      } else {
        productSelection.variant = value;
        productSelection.grind = null;
        productSelection.product = String(product._id);
      }
      saveProductSelection(productSelection);
    }
  };

  const isClosedOrOffboarding = useMemo(() => [UserStatuses.Closed, UserStatuses.Offboarding].includes(me?.status), [
    me,
  ]);

  const handleSubmit = useCallback(
    ({ submitForm, setFieldValue, oneOff, addToList, type, forceSubmit, forceSize, values }) => e => {
      e.stopPropagation();
      setTypeSubmitting(type);

      const isSameGrind = userGrind && values?.grind ? values.grind === userGrind : true;
      const variant = product.variants.find(row => row._id === values?.variant);
      const isSameSize = userDefaultSize && values?.variant ? userDefaultSize === variant.size : true;

      if (!oneOff && !isSameSize && !(forceSize || forceSubmit)) {
        setSizeWarningModal(true);
        setWarningModal(false);
        return;
      }
      if (!oneOff && !isSameGrind && !forceSubmit) {
        setWarningModal(true);
        setSizeWarningModal(false);
        return;
      }
      setFieldValue('oneOff', oneOff);
      setFieldValue('addToList', addToList);
      setTimeout(submitForm, 0);
      setWarningModal(false);
      setSizeWarningModal(false);
    },
    [setWarningModal, userGrind, product, userDefaultSize, setSizeWarningModal]
  );

  const onSubmit = useCallback(
    ({ oneOff, addToList, ...data }) => {
      if (addToList) {
        return addToCustomList(product, data);
      }

      return oneOff ? onOneOffOrder(product, data) : onChooseProduct(product, data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const defaultGrind = () => {
    if (grindFromSearch) {
      return grindFromSearch;
    }

    if (product.available_ground) {
      if (me && me.grind) {
        return me.grind._id;
      }
    }

    if (userGrind && product.available_ground) {
      return userGrind;
    }

    return grinds?.find(grind => grind.default)._id;
  };

  const getDefaultVariant = () => {
    if (me && me.personalized && me.personalized.size) {
      const { size } = me.personalized;
      const userDefaultVariant = product.variants.filter(v => v.available && v.size === size);
      if (userDefaultVariant && userDefaultVariant.length) {
        return userDefaultVariant[0]._id;
      }
    }

    const [defaultVariant] =
      product && product.variants
        ? product.variants.filter(v => v.available).sort((a, b) => (a.size > b.size ? 1 : -1))
        : [];
    return defaultVariant && defaultVariant._id;
  };

  const noMatchingCategory = useMemo(
    () => me && !me?.accounts.find(account => account.product.product.category._id === product.category?._id),
    [me, product]
  );

  const isDifferentVendor = useMemo(
    () => !!me && !me.accounts.find(account => !account.vendor_id || account.vendor_id === product.vendor_id._id),
    [me, product]
  );

  let buttonText = selectText;
  if (outOfStock) {
    buttonText = 'Out of Stock';
  } else if (gift) {
    buttonText = 'Redeem Gift';
  } else if (!me || noMatchingCategory || (!checkout && isDifferentVendor)) {
    buttonText = 'Sign Up';
  }

  const calculatedSetDefaultText = useMemo(() => (noMatchingCategory ? 'Sign Up' : setDefaultText), [
    setDefaultText,
    noMatchingCategory,
  ]);

  const SignupWrapper = isProductPage ? Box : Fragment;

  const form = useRef();

  const grindOptions = useMemo(() => {
    const selectedFormVariantId = selectedVariantId || form.current?.state.values.variant;
    const selectedFormVariant = selectedFormVariantId && product.variants.find(v => v._id === selectedFormVariantId);
    const mappedGrind = Object.keys(selectedFormVariant?.original_id_mapping || {});

    const selectedGrinds = product.available_ground
      ? (grinds || []).filter(grind => !onlyMappedVariants || grind.default || mappedGrind.includes(grind._id))
      : (grinds || []).filter(grind => grind.default);

    return selectedGrinds.reduce(
      (all, grind, i) => ({ ...all, [grind._id]: !i ? grind.name : `Ground: ${grind.name}` }),
      {}
    );
  }, [product.available_ground, product.variants, grinds, form, selectedVariantId, onlyMappedVariants]);

  useEffect(() => {
    const currentGrindOption = form.current?.state.values.grind;
    const grindOptionsIds = Object.keys(grindOptions);

    if (Object.keys(form.current?.state.touched || {}).length && !grindOptionsIds.includes(currentGrindOption)) {
      form.current.setFieldValue('grind', grindOptionsIds[0]);
    }
  }, [form, selectedVariantId, grindOptions]);

  const hasGrindOptions = useMemo(() => grindOptions && !!Object.keys(grindOptions)?.length, [grindOptions]);

  return (
    <Form
      innerRef={form}
      initialValues={{
        grind: selectedGrind || defaultGrind(),
        variant: selectedVariant || getDefaultVariant(),
        oneOff: false,
        addToList: false,
        ...initialValues,
      }}
      validationSchema={MakeBottomlessSchema}
      onSubmit={onSubmit}
      onSuccess={onChooseSuccess}
      onChange={onFormChange}
    >
      {({ isSubmitting, values, setFieldValue, setFieldTouched, submitForm }) => (
        <>
          <Modal size="sm" isOpen={warningModal} className="shop-modal" autoFocus={true}>
            <ModalHeader>You have selected a different grind from your account default.</ModalHeader>
            <ModalBody>
              <GrindWarningModalBody values={values} me={me} grinds={grinds} />
            </ModalBody>
            <ModalFooter className="flex-column align-items-stretch">
              <Button
                color="primary"
                onClick={handleSubmit({
                  submitForm,
                  setFieldValue,
                  addToList: BUTTON_TYPE.CUSTOM_LIST === typeSubmitting,
                  type: typeSubmitting,
                  forceSubmit: true,
                  values,
                })}
              >
                Yes, go ahead
              </Button>{' '}
              <Button color="secondary" onClick={toggleWarningModal}>
                No, I changed my mind
              </Button>
            </ModalFooter>
          </Modal>
          <Modal size="sm" isOpen={sizeWarningModal} className="shop-modal" autoFocus={true}>
            <ModalHeader>You have selected a different size from your account default.</ModalHeader>
            <ModalBody>
              <SizeWarningModalBody product={product} values={values} me={me} />
            </ModalBody>
            <ModalFooter className="flex-column align-items-stretch">
              <Button
                color="primary"
                onClick={handleSubmit({
                  submitForm,
                  setFieldValue,
                  addToList: BUTTON_TYPE.CUSTOM_LIST === typeSubmitting,
                  type: typeSubmitting,
                  forceSize: true,
                  values,
                })}
              >
                Yes, go ahead
              </Button>{' '}
              <Button color="secondary" onClick={toggleSizeWarningModal}>
                No, I changed my mind
              </Button>
            </ModalFooter>
          </Modal>
          {outOfStock && <Row className="justify-content-center text-danger mb-3">Out of Stock</Row>}
          <Row className={classNames('variants', { 'variants-single': filteredVariants.length === 1 })}>
            {filteredVariants
              .sort((a, b) => (a.size > b.size ? 1 : -1))
              .map((variant, i) => (
                <Col xs={12} key={i} className="mb-2">
                  <div
                    role="button"
                    size="sm"
                    className={`btn-option d-flex align-items-center justify-content-between py-1 px-2 ${
                      values.variant === variant._id ? 'btn-option-active' : ''
                    }`}
                    onClick={chooseVariant(setFieldValue, setFieldTouched, variant._id)}
                  >
                    <div>
                      {values.variant === variant._id && (
                        <CheckCircle className="mr-2" size="16" role="img" aria-label="Checked" />
                      )}
                      {values.variant !== variant._id && (
                        <Circle
                          className="mr-2"
                          color="rgba(0, 0, 0, 0.125)"
                          size="16"
                          role="img"
                          aria-label="Unchecked"
                        />
                      )}
                    </div>

                    <div className="d-flex flex-grow-1 justify-content-between">
                      <div>{fromOz({ oz: variant.size, unit: variant.unit }).formatted}</div>
                      <div className="product-price">
                        <VariantPrice
                          variant={variant}
                          user={me}
                          pricingRule={priceRule}
                          isFirstBag={isFirstBag}
                          size={variant.size}
                          checkout={checkoutData}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
          {hasGrindOptions ? (
            <Field
              bsSize="sm"
              type="select"
              name="grind"
              idNounce={product._id}
              disabled={!product.available_ground || outOfStock}
              onClick={e => e.stopPropagation()}
              aria-label={`${product.name}'s grind`}
              autocomplete="off"
              onChange={
                setSelectedGrind
                  ? e => {
                      setSelectedGrind(e.target.value);
                      if (isProductPage) {
                        if (productSelection?.product === String(product._id)) {
                          productSelection.grind = e.target.value;
                        } else {
                          productSelection.variant = selectedVariant;
                          productSelection.grind = e.target.value;
                          productSelection.product = String(product._id);
                        }
                        saveProductSelection(productSelection);
                      }
                    }
                  : undefined
              }
              options={grindOptions}
            />
          ) : (
            undefined
          )}
          {me && !isFirstBag && !checkout && !noMatchingCategory && !isDifferentVendor && (
            <>
              {hasMakeBottomless && (
                <SubmitButton
                  color="primary"
                  size={size}
                  block
                  isSubmitting={isSubmitting && typeSubmitting === BUTTON_TYPE.SET_DEFAULT}
                  disabled={outOfStock || isSubmitting}
                  type="button"
                  onClick={handleSubmit({
                    submitForm,
                    setFieldValue,
                    oneOff: false,
                    type: BUTTON_TYPE.SET_DEFAULT,
                    values,
                  })}
                >
                  {calculatedSetDefaultText}
                </SubmitButton>
              )}
            </>
          )}
          <div className="secondary-actions">
            {me && hasCustomLists && addToCustomList && !checkout && !noMatchingCategory && !isDifferentVendor && (
              <div>
                <SubmitButton
                  color="primary"
                  outline
                  size={size}
                  block
                  isSubmitting={isSubmitting && typeSubmitting === BUTTON_TYPE.CUSTOM_LIST}
                  disabled={outOfStock || isSubmitting}
                  type="button"
                  className="mt-2"
                  onClick={handleSubmit({
                    submitForm,
                    setFieldValue,
                    addToList: true,
                    type: BUTTON_TYPE.CUSTOM_LIST,
                    values,
                  })}
                >
                  Add to queue
                </SubmitButton>
              </div>
            )}
            {me && !isClosedOrOffboarding && hasOneOff && !checkout && !noMatchingCategory && !isDifferentVendor && (
              <div>
                <SubmitButton
                  color="primary"
                  size={size}
                  outline
                  type="button"
                  className="mt-2"
                  block
                  isSubmitting={isSubmitting && typeSubmitting === BUTTON_TYPE.ONE_OFF}
                  disabled={outOfStock || isSubmitting}
                  onClick={handleSubmit({
                    submitForm,
                    setFieldValue,
                    oneOff: true,
                    type: BUTTON_TYPE.ONE_OFF,
                    values,
                  })}
                >
                  {oneOffText}
                </SubmitButton>
              </div>
            )}
          </div>

          {(!me || isFirstBag || checkout || noMatchingCategory || isDifferentVendor) && (
            <SignupWrapper withShadow>
              <div>
                {isProductPage && (
                  <>
                    <h4 className="text-center mb-4">
                      <a href="#product-marketing" className="tagline text-decoration-none text-dark">
                        Subscribe by Usage
                      </a>
                    </h4>
                    <Row xs="12" className="pricing-box text-center mb-4">
                      <Col xs="4">
                        <div className="price">
                          <span>${getCleanNumericalValue(priceRule?.monthly_fee, 0) / 100}</span>
                          <span className="extra-small">/mo</span>
                        </div>
                        <span className="price-subtitle text-secondary small">Membership</span>
                      </Col>
                      <Col xs="4">
                        <div className="price">Free</div>
                        <span className="price-subtitle text-secondary small">Smart Scale</span>
                      </Col>
                      <Col>
                        <div className="price">
                          {Math.round((1 - getCleanNumericalValue(priceRule?.discount_proportion, 1)) * 100)}% off
                        </div>
                        <span className="price-subtitle text-secondary small">
                          {priceRule?.free_shipping ? '+ Free Shipping' : 'on every order'}
                        </span>
                      </Col>
                    </Row>
                  </>
                )}
                <SubmitButton
                  disabled={outOfStock || !filteredVariants.length}
                  color="primary"
                  type="button"
                  block
                  isSubmitting={isSubmitting && typeSubmitting === BUTTON_TYPE.SELECT}
                  onClick={handleSubmit({
                    submitForm,
                    setFieldValue,
                    type: BUTTON_TYPE.SELECT,
                  })}
                >
                  {buttonText}
                </SubmitButton>
                {expressCheckoutButton && (
                  <div className="d-flex flex-column align-items-center text-center">{expressCheckoutButton}</div>
                )}
                {isProductPage && <p className="small mt-3 text-secondary text-center">{priceRule?.description}</p>}
              </div>
            </SignupWrapper>
          )}
          {additionalActionButton}
          {isProductPage && outOfStock && (
            <div className="more-coffee-link mt-3 text-center">
              <a href={shopPath}>Visit our shop to explore more products</a>
            </div>
          )}
        </>
      )}
    </Form>
  );
};

MakeBottomless.propTypes = {
  onChooseProduct: PropTypes.func.isRequired,
  onOneOffOrder: PropTypes.func,
  onChooseSuccess: PropTypes.func,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    available_ground: PropTypes.bool,
    variants: PropTypes.array,
    custom_rotation: PropTypes.object,
    category: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
    vendor_id: PropTypes.shape({ _id: PropTypes.string.isRequired }),
  }).isRequired,
  size: PropTypes.string,
  priceRule: PropTypes.object,
  isFirstBag: PropTypes.bool,
  vendor: PropTypes.object,
  grinds: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
  me: PropTypes.object,
  hasOneOff: PropTypes.bool,
  hasCustomLists: PropTypes.bool,
  hasMakeBottomless: PropTypes.bool,
  additionalActionButton: PropTypes.node,
  onFormChange: PropTypes.func,
  initialValues: PropTypes.object,
  selectText: PropTypes.string,
  oneOffText: PropTypes.string,
  setDefaultText: PropTypes.string,
  outOfStock: PropTypes.bool,
  setSelectedGrind: PropTypes.func,
  setSelectedVariant: PropTypes.func,
  isProductPage: PropTypes.bool,
  addToCustomList: PropTypes.func,
  selectedGrind: PropTypes.string,
  selectedVariant: PropTypes.string,
  productSelection: PropTypes.shape({
    product: PropTypes.string,
    variant: PropTypes.string,
    grind: PropTypes.string,
  }),
  saveProductSelection: PropTypes.func,
  location: PropTypes.object,
  userGrind: PropTypes.string,
  expressCheckoutButton: PropTypes.node,
  category: PropTypes.string,
  onlyMappedVariants: PropTypes.bool,
  checkout: PropTypes.object,
};

MakeBottomless.defaultProps = {
  selectedGrind: null,
  selectedVariant: null,
  productSelection: {},
  saveProductSelection: () => {},
  location: {},
  size: 'sm',
};
