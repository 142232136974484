import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { UserStatuses } from '../../constants';
import { ScaleArrived, ScaleConnected, ScaleShipment } from './components';

export const ScaleStatus = ({ me, status, stats, link = '/data', borderColor, backgroundColor, noScaleImage }) => {
  const isReplacing = useMemo(() => me.status === UserStatuses.ScaleReplaced && status.lastReplacement, [me, status]);
  const isOnboarding = useMemo(
    () => [UserStatuses.OnboardingScaleShipped, UserStatuses.OnboardingPaid].includes(me.status),
    [me]
  );
  const isShipment = useMemo(() => isReplacing || isOnboarding, [isReplacing, isOnboarding]);

  return (
    <>
      {isShipment && (
        <ScaleShipment
          me={me}
          replacement={isReplacing ? status.lastReplacement : undefined}
          noScaleImage={noScaleImage}
        />
      )}
      {!isShipment && (
        <>
          {status.scale_status && (
            <ScaleConnected
              status={status}
              stats={stats}
              link={link}
              borderColor={borderColor}
              backgroundColor={backgroundColor}
              noScaleImage={noScaleImage}
            />
          )}
          {!status.scale_status && <ScaleArrived status={status} noScaleImage={noScaleImage} />}
        </>
      )}
    </>
  );
};

ScaleStatus.propTypes = {
  me: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    lastReplacement: PropTypes.string,
    scale_status: PropTypes.string,
  }).isRequired,
  stats: PropTypes.array.isRequired,
  link: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  noScaleImage: PropTypes.bool,
};
