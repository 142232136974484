import { UserStatuses } from '@bottomless/common/constants';

export const isUserEligibleForAutomaticOrdering = user => {
  if (user?.dumb_period) {
    return true;
  }

  return ![UserStatuses.OnboardingScaleShipped, UserStatuses.OnboardingPaid].includes(user?.status);
};

export const isUserVendorLocked = user => {
  return !!user?.vendor_id;
};
