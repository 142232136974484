import { Image } from '@bottomless/common/components';
import React, { useCallback, useState } from 'react';
import { useCustomWording } from '../../hooks/use-custom-wording';

export const UsingContainer = () => {
  const { cw } = useCustomWording();
  const [isOpen, setOpen] = useState(false);

  const onClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(true);
  }, []);

  return (
    <div className="mt-3">
      <div className="text-center">
        <a href="#" onClick={onClick}>
          Using your own container?
        </a>
      </div>
      {isOpen && (
        <div className="text-center mt-3">
          <Image src={cw('/connect-scale/empty-container.png')} alt="Bottomless Scale" width="200" useSrcset />
          <div className="mt-3">
            Place the <strong>empty</strong> container on the scale after pressing the &quot;Setup&quot; button.
          </div>
        </div>
      )}
    </div>
  );
};
