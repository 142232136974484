import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Toasts } from '@bottomless/common/components';
import './Auth.scss';

export const Auth = ({ children, isVendorLocked = false }) => (
  <>
    <div className="skip-to-main">
      <a href="#main-content" className="text-dark">
        Skip to main content
      </a>
    </div>
    <div
      className={classNames('container-auth d-flex align-items-stretch', {
        'with-white-background': isVendorLocked,
      })}
    >
      <Row
        className={classNames('w-100 m-0', {
          'justify-content-center': isVendorLocked,
        })}
      >
        {!isVendorLocked && <Col className="auth-image d-none d-md-flex" xs="6" md="5" />}
        <Col className="d-flex align-items-stretch justify-content-center flex-column" xs="12" md="7">
          {!isVendorLocked && (
            <h1 className="auth-logo mb-4 d-flex justify-content-center" role="navigation">
              <Link to="/" className="d-flex align-items-center" role="heading" aria-level="1">
                <img src="/images/Bottomless-logo-blue.png" alt="Bottomless" height="32" />
              </Link>
            </h1>
          )}
          <main className="auth-content" id="main-content">
            {children}
          </main>
        </Col>
      </Row>
      <Toasts />
    </div>
  </>
);

Auth.propTypes = {
  children: PropTypes.node.isRequired,
  isVendorLocked: PropTypes.bool.isRequired,
};
