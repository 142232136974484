import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetProductsTypes = createAction('Products', 'Get all');

export const getProductsAction = ({ vendorId: vendor, type, category = '', withHidden } = { vendorId: '' }) => {
  const params = { vendor, type, ...(category && { category }), ...(withHidden && { withHidden: true }) };

  return {
    [RSAA]: {
      endpoint: `products${params ? `?${qs.stringify(params)}` : ''}`,
      method: 'GET',
      types: [GetProductsTypes.REQUEST, GetProductsTypes.SUCCESS, GetProductsTypes.FAILURE],
    },
  };
};

export const GetGroceryProductsTypes = createAction('Grocery Products', 'Get all');

export const getGroceryProductsAction = () => {
  return {
    [RSAA]: {
      endpoint: 'grocery-products',
      method: 'GET',
      types: [GetGroceryProductsTypes.REQUEST, GetGroceryProductsTypes.SUCCESS, GetGroceryProductsTypes.FAILURE],
    },
  };
};

export const GetProductTypes = createAction('Products', 'Get');

export const getProductAction = (id, params = {}) => ({
  [RSAA]: {
    endpoint: `products/${id}${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetProductTypes.REQUEST, GetProductTypes.SUCCESS, GetProductTypes.FAILURE],
  },
});

export const GetGroceryProductTypes = createAction('Grocery Products', 'Get');

export const getGroceryProductAction = id => ({
  [RSAA]: {
    endpoint: `grocery-products/${id}`,
    method: 'GET',
    types: [GetGroceryProductTypes.REQUEST, GetGroceryProductTypes.SUCCESS, GetGroceryProductTypes.FAILURE],
  },
});

export const GetSingleProductTypes = createAction('Products', 'Get single');

export const getSingleProductAction = id => ({
  [RSAA]: {
    endpoint: `products/${id}`,
    method: 'GET',
    types: [GetSingleProductTypes.REQUEST, GetSingleProductTypes.SUCCESS, GetSingleProductTypes.FAILURE],
  },
});

export const GetPopularProductsTypes = createAction('Products', 'Get popular');

export const getPopularProductsAction = () => ({
  [RSAA]: {
    endpoint: 'products/popular',
    method: 'GET',
    types: [GetPopularProductsTypes.REQUEST, GetPopularProductsTypes.SUCCESS, GetPopularProductsTypes.FAILURE],
  },
});

export const GetFeaturedProductsTypes = createAction('Products', 'Get featured');

export const getFeaturedProductsAction = () => ({
  [RSAA]: {
    endpoint: 'products/featured',
    method: 'GET',
    types: [GetFeaturedProductsTypes.REQUEST, GetFeaturedProductsTypes.SUCCESS, GetFeaturedProductsTypes.FAILURE],
  },
});

export const GetProductAttributesTypes = createAction('Products', 'Get attributes');

export const getProductAttributesAction = checkoutId => ({
  [RSAA]: {
    endpoint: `products/attributes${checkoutId ? `?checkout_id=${checkoutId}` : ''}`,
    method: 'GET',
    types: [GetProductAttributesTypes.REQUEST, GetProductAttributesTypes.SUCCESS, GetProductAttributesTypes.FAILURE],
  },
});

export const GetProductOrigins = createAction('Products', 'Get origins');

export const getProductOriginsAction = withoutRotating => ({
  [RSAA]: {
    endpoint: `products/origins${withoutRotating ? '?withoutRotating=true' : ''}`,
    method: 'GET',
    types: [GetProductOrigins.REQUEST, GetProductOrigins.SUCCESS, GetProductOrigins.FAILURE],
  },
});

export const GetTastingNoteCategoriesTypes = createAction('Products', 'Get tasting note categories All');

export const getTastingNoteCategoriesAction = () => ({
  [RSAA]: {
    endpoint: `tasting-notes/get-categories`,
    method: 'GET',
    types: [
      GetTastingNoteCategoriesTypes.REQUEST,
      GetTastingNoteCategoriesTypes.SUCCESS,
      GetTastingNoteCategoriesTypes.FAILURE,
    ],
  },
});

export const GetProductVendors = createAction('Products', 'Get vendors');

export const getProductVendorsAction = () => ({
  [RSAA]: {
    endpoint: 'products/vendors',
    method: 'GET',
    types: [GetProductVendors.REQUEST, GetProductVendors.SUCCESS, GetProductVendors.FAILURE],
  },
});

export const GetPersonalizedProduct = createAction('Products', 'Get personalized');

export const getPersonalizedProductAction = () => ({
  [RSAA]: {
    endpoint: 'products/personalized',
    method: 'GET',
    types: [GetPersonalizedProduct.REQUEST, GetPersonalizedProduct.SUCCESS, GetPersonalizedProduct.FAILURE],
  },
});

export const GetDynamicPrices = createAction('Products', 'Get dynamic prices');

export const getDynamicPricesAction = data => ({
  [RSAA]: {
    endpoint: 'products/dynamic-pricing',
    method: 'POST',
    body: data,
    types: [GetDynamicPrices.REQUEST, GetDynamicPrices.SUCCESS, GetDynamicPrices.FAILURE],
  },
});

export const GetSimpleDynamicPrices = createAction('Products', 'Get simple dynamic prices');

export const getSimpleDynamicPricesAction = () => ({
  [RSAA]: {
    endpoint: 'products/dynamic-pricing/simple',
    method: 'GET',
    types: [GetSimpleDynamicPrices.REQUEST, GetSimpleDynamicPrices.SUCCESS, GetSimpleDynamicPrices.FAILURE],
  },
});

export const GetVendorRotationsPrices = createAction('Products', 'Get vendor rotations');

export const getVendorRotationsAction = () => ({
  [RSAA]: {
    endpoint: 'products/vendor-rotations',
    method: 'GET',
    types: [GetVendorRotationsPrices.REQUEST, GetVendorRotationsPrices.SUCCESS, GetVendorRotationsPrices.FAILURE],
  },
});

export const GetCustomListTypes = createAction('Products', 'Get custom lists');

export const getCustomListAction = () => ({
  [RSAA]: {
    endpoint: 'custom-lists',
    method: 'GET',
    types: [GetCustomListTypes.REQUEST, GetCustomListTypes.SUCCESS, GetCustomListTypes.FAILURE],
  },
});

export const AddCustomListElementTypes = createAction('Products', 'Add custom lists element');

export const addCustomListElementAction = data => ({
  [RSAA]: {
    endpoint: 'custom-lists/element',
    method: 'POST',
    body: data,
    types: [AddCustomListElementTypes.REQUEST, AddCustomListElementTypes.SUCCESS, AddCustomListElementTypes.FAILURE],
  },
});

export const UpdateCustomListTypes = createAction('Products', 'Update custom list');

export const updateCustomListAction = data => ({
  [RSAA]: {
    endpoint: 'custom-lists',
    method: 'PATCH',
    body: data,
    types: [UpdateCustomListTypes.REQUEST, UpdateCustomListTypes.SUCCESS, UpdateCustomListTypes.FAILURE],
  },
});

export const UpdateCustomListElementTypes = createAction('Products', 'Update custom list element');

export const updateCustomListElementAction = (id, data) => ({
  [RSAA]: {
    endpoint: `custom-lists/element/${id}`,
    method: 'PATCH',
    body: data,
    types: [
      UpdateCustomListElementTypes.REQUEST,
      UpdateCustomListElementTypes.SUCCESS,
      UpdateCustomListElementTypes.FAILURE,
    ],
  },
});

export const MOVE_CUSTOM_LIST_ITEM = 'Products/Custom list move item';

export const moveCustomListItemAction = (sourceIndex, destinationIndex) => ({
  type: MOVE_CUSTOM_LIST_ITEM,
  payload: { sourceIndex, destinationIndex },
});

export const DELETE_CUSTOM_LIST_ITEM = 'Products/Custom list delete item';

export const deleteCustomListItemAction = index => ({
  type: DELETE_CUSTOM_LIST_ITEM,
  payload: { index },
});

export const SAVE_PRODUCT_SELECTION = 'Products/Product selection';

export const saveProductSelectionAction = productSelection => ({
  type: SAVE_PRODUCT_SELECTION,
  payload: productSelection,
});

export const ReorderProductTypes = createAction('Grocery Products', 'Re-order');

export const reorderGroceryProductAction = (id, userId) => ({
  [RSAA]: {
    endpoint: `grocery-products/${id}/re-order`,
    method: 'POST',
    body: { userId },
    types: [ReorderProductTypes.REQUEST, ReorderProductTypes.SUCCESS, ReorderProductTypes.FAILURE],
  },
});

export const SensorizeGroceryProductTypes = createAction('Grocery Products', 'Sensorize');

export const sensorizeGroceryProductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `grocery-products/${id}/sensorize`,
    method: 'POST',
    body: data,
    types: [
      SensorizeGroceryProductTypes.REQUEST,
      SensorizeGroceryProductTypes.SUCCESS,
      SensorizeGroceryProductTypes.FAILURE,
    ],
  },
});

export const GetFourSigmaticProductsTypes = createAction('Products', 'Get Four Sigmatic Products');

export const getFourSigmaticProductsAction = () => {
  return {
    [RSAA]: {
      endpoint: 'products/four-sigmatic',
      method: 'GET',
      types: [
        GetFourSigmaticProductsTypes.REQUEST,
        GetFourSigmaticProductsTypes.SUCCESS,
        GetFourSigmaticProductsTypes.FAILURE,
      ],
    },
  };
};

export const SubmitProductRequestTypes = createAction('Products', 'Submit Request');

export const submitProductRequestAction = data => ({
  [RSAA]: {
    endpoint: `products/request`,
    method: 'POST',
    body: data,
    types: [SubmitProductRequestTypes.REQUEST, SubmitProductRequestTypes.SUCCESS, SubmitProductRequestTypes.FAILURE],
  },
});
