import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ScaleConnection } from '../../../../components/ScaleConnection';
import { useIsAg1 } from '../../hooks/use-is-ag1';
import { Ag1ScaleImage } from '../../components/Ag1ScaleImage';
import { useCustomWording } from '../../hooks/use-custom-wording';

const ANIMATION_SPEED = 700;

export const ScaleLoading = ({ className, isConnected, onConnectedFinished }) => {
  const isAg1 = useIsAg1();
  const { cw } = useCustomWording();
  const [lines, setLines] = useState([true, false, false, false]);
  const loadingInterval = useRef();

  useEffect(() => {
    loadingInterval.current = setInterval(() => {
      setLines(lines => {
        const nextIndex = (lines.findIndex(Boolean) + 1) % lines.length;

        return lines.map((_, i) => i === nextIndex);
      });
    }, ANIMATION_SPEED);

    return () => clearInterval(loadingInterval.current);
  }, []);

  useEffect(() => {
    if (isConnected && loadingInterval.current && lines[0]) {
      clearInterval(loadingInterval.current);

      for (let i = 0; i < 3; i++) {
        setTimeout(
          () => setLines(lines => lines.map((line, j) => (j === i + 1 ? true : line))),
          (i + 1) * ANIMATION_SPEED
        );
        setTimeout(() => onConnectedFinished(), 4 * ANIMATION_SPEED);
      }
      loadingInterval.current = null;
    }
  }, [isConnected, onConnectedFinished, lines]);

  return (
    <div className={className}>
      <div className="text-center mb-4">
        <strong>{cw('Waiting for scale connection...')}</strong>
      </div>
      <div className="text-center">
        <ScaleConnection lines={lines} ScaleImageComponent={isAg1 ? Ag1ScaleImage : undefined} />
      </div>
    </div>
  );
};

ScaleLoading.propTypes = {
  className: PropTypes.string.isRequired,
  isConnected: PropTypes.bool,
  onConnectedFinished: PropTypes.func.isRequired,
};
