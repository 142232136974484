import { GetPetFoodProductDetailsTypes } from './pet-food.actions';

const initialState = {
  product: null,
  isLoading: false,
};

export const petFoodReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetPetFoodProductDetailsTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetPetFoodProductDetailsTypes.SUCCESS:
      return {
        ...state,
        product: action.payload,
        isLoading: false,
      };

    case GetPetFoodProductDetailsTypes.FAILURE:
      return {
        ...state,
        product: null,
        isLoading: false,
      };

    default:
      return state;
  }
};
