import * as Sentry from '@sentry/browser';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import React from 'react';
import { useCallback } from 'react';
import { Loader } from 'react-feather';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { Col, Row } from 'reactstrap';
import { byName, data as statesData } from 'usps-states';
import { Field } from '../Form/Field';

const AddressAutoComplete = ({ getInputProps, suggestions, getSuggestionItemProps, loading, label }) => (
  <div className="position-relative">
    <Field name="verifiedAddress.street1" label={label} {...getInputProps()} autoComplete="address-line1" />
    {loading && <Loader size="18" className="spin adress-loader" />}
    <div className="autocomplete-dropdown">
      {(loading || suggestions.length > 0) && (
        <div className="autocomplete-inner">
          {suggestions.map(suggestion => (
            <div
              {...getSuggestionItemProps(suggestion, {
                className: suggestion.active ? 'suggestion-item--active' : 'suggestion-item',
              })}
              key={suggestion.placeId}
            >
              <span>{suggestion.description}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
);

AddressAutoComplete.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  getSuggestionItemProps: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

const states = statesData.reduce((states, { abbr }) => ({ ...states, [abbr]: abbr }), {});

export const AddressInput = ({ setFieldValue, values, oneLine, onChangeAddress, onPlaceChosen, form }) => {
  const onAddressSelect = setFieldValue => async (_, placeId) => {
    if (placeId) {
      const [place] = await geocodeByPlaceId(placeId);
      const address = {};

      place.address_components.forEach(address_component => {
        address_component.types.forEach(type => {
          address[type] = address_component.long_name;
        });
      });

      if (!address.locality) {
        Sentry.captureException(new Error('[Checkout] No locality'), { extra: { address } });
      }

      setFieldValue('verifiedAddress.street1', `${address.street_number || ''} ${address.route}`);
      setFieldValue('verifiedAddress.street2', address.subpremise || '');
      setFieldValue('verifiedAddress.city', address.locality || address.sublocality);
      setFieldValue('verifiedAddress.zip', address.postal_code);
      setFieldValue('verifiedAddress.state', get(byName, `[${address.administrative_area_level_1}].abbr`, ''));

      if (onChangeAddress && form) {
        onChangeAddress({ values: form.current?.state.values });
      }

      if (onPlaceChosen) {
        onPlaceChosen(placeId, address);
      }
    }
  };

  const fieldType = oneLine ? 'hidden' : 'text';
  const selectType = oneLine ? 'hidden' : 'select';
  const addressLabel = oneLine ? 'Address' : 'Address 1';

  const onBlur = useCallback(() => {
    if (onChangeAddress && form) {
      setTimeout(() => onChangeAddress({ values: form.current?.state.values }), 0);
    }
  }, [onChangeAddress, form]);

  return (
    <>
      {window.google && (
        <PlacesAutocomplete
          searchOptions={{
            types: ['address'],
            componentRestrictions: { country: ['us'] },
          }}
          value={(values.verifiedAddress || { street: '' }).street1 || ''}
          onChange={address => setFieldValue('verifiedAddress.street1', address)}
          onSelect={onAddressSelect(setFieldValue)}
        >
          {props => AddressAutoComplete({ ...props, label: addressLabel })}
        </PlacesAutocomplete>
      )}
      {!window.google && <Field name="verifiedAddress.street1" type={fieldType} label="Address 1" onBlur={onBlur} />}

      <Field
        name="verifiedAddress.street2"
        type={fieldType}
        label={
          <span>
            Address 2 <span className="text-secondary small font-weight-normal">(Optional)</span>
          </span>
        }
        placeholder="Add apt or suite number"
        autocomplete="address-line2"
        onBlur={onBlur}
      />
      <Row>
        <Col xs="12" sm="4">
          <Field
            name="verifiedAddress.zip"
            type={fieldType}
            label="Zip code"
            onBlur={onBlur}
            autocomplete="postal-code"
          />
        </Col>
        <Col xs="12" sm="4">
          <Field
            name="verifiedAddress.city"
            type={fieldType}
            label="City"
            onBlur={onBlur}
            autocomplete="address-level2"
          />
        </Col>
        <Col xs="12" sm="4">
          <Field
            name="verifiedAddress.state"
            type={selectType}
            label="State"
            options={states}
            onChange={onBlur}
            autocomplete="address-level1"
          />
        </Col>
      </Row>
    </>
  );
};

AddressInput.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  oneLine: PropTypes.bool,
  onChangeAddress: PropTypes.func,
  onPlaceChosen: PropTypes.func,
  form: PropTypes.object,
};

AddressInput.defaultProps = {
  oneLine: false,
};
