export const UserStatuses = {
  Active: 'active',
  OnboardingScaleShipped: 'onboarding_scale_shipped',
  Closed: 'closed',
  Offboarding: 'offboarding',
  OnboardingPaid: 'onboarding_paid',
  ScaleReplaced: 'scale_replaced',
  Guest: 'guest',
};

export const SlowLocalProductPreferences = {
  BottomlessChoice: 'bottomless_choice',
  Remove: 'remove',
  ContactMe: 'contact_me',
};

export const CloseAccountType = {
  Hibernation: 'hibernation',
};

export const SubscriptionType = {
  Dynamic: 'dynamic',
  Fixed: 'fixed',
  Prepaid: 'prepaid',
};

export const DumbType = {
  Days: 'days',
  FirstThursday: 'first-thursday',
  SameDayMonthly: 'same-day-monthly',
  FirstWednesday: 'first-wednesday',
};
