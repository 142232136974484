import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import omit from 'lodash-es/omit';
import PropTypes from 'prop-types';
import qs from 'query-string';
import classNames from 'classnames';
import { useCookies } from 'react-cookie';
import { useMediaQuery } from 'react-responsive';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';
import { addAlertAction, addToastAction, changeScaleAction } from '@bottomless/common/store';
import { useToggle } from '@bottomless/common/src/hooks/use-toggle';
import { withMetaTags } from '../../components/MetaTags/MetaTags';
import { cookieOptions } from '../../constants/cookies';
import { useIsGuest } from '../../hooks/useIsGuest.hook';
import { AccountsModal } from '../../components/AccountsModal';
import {
  getProductsAction,
  triggerOrderAction,
  getProductAttributesAction,
  getOrdersAction,
  getStartedGiftAction,
  getStartedCompleteAction,
  finishCheckoutAction,
  submitProductRequestAction,
  getStartedAction,
  newScaleCheckoutAction,
} from '../../store';
import { EVENT_ADD_TO_CUSTOM_LIST, trackEvent } from '../../utils/tracker';
import { Filters } from './components/Filters';
import { Product } from './components/Product';
import { Search } from './components/Search';
import { Sorting } from './components/Sorting';
import OneOffOrderModal from './components/OneOffOrderModal';
import { Tabs } from './components/Tabs';
import { useProductsFilter } from './hooks/use-products-filter.hook';
import { useProductsSort } from './hooks/use-products-sort.hook';
import { RequestProductForm } from './components/RequestProductForm';
import { ChangeScale } from '../../components/ChangeScale/ChangeScale';
import './Shop.scss';
import { Link } from 'react-router-dom';
import { CheckoutSource } from '@bottomless/common/src/constants';

const getCategoryHomePage = categorySlug => {
  switch (categorySlug) {
    case 'pet-food':
      return '/pets';
    case 'coffee':
      return '/coffee';
    default:
      return '/';
  }
};

const ShopPageComponent = ({
  products,
  getProducts,
  isLoading,
  makeBottomless,
  triggerOrder,
  addAlert,
  location,
  history,
  featuredTags,
  wrapper: Wrapper,
  vendor,
  pricingRule,
  isFirstBag,
  getProductAttributes,
  match,
  me,
  withBanner,
  hideFilters,
  hideSearch,
  hideSorting,
  shouldLazyLoad,
  orders,
  getOrders,
  addToCustomList,
  addToast,
  getStartedGift,
  finishRegistration,
  finishCheckout,
  makeBottomlessText,
  isVendorLandingShop,
  attributes,
  category,
  categorySlug,
  submitProductRequest,
  changeScale,
  getStarted,
  newScaleCheckout,
  userGrind,
  customList,
  checkout,
}) => {
  const tab = match.params.type || 'products';
  const [{ phone, gift }, setCookie] = useCookies(['phone', 'gift']);
  const query = useMemo(() => qs.parse(location.search), [location.search]);

  const listRef = useRef(null);
  const [areFiltersOpen, setFiltersOpen] = useState();
  const [isOneOffModalOpen, setOneOffModalOpen] = useState(false);
  const [filters, setFilters] = useState(filtersFromQuery(query));
  const [sorting, setSorting] = useState(query.sort);
  const [productsCount, setProductsCount] = useState(products.length);
  const [pendingOneOffOrder, setPendingOneOffOrder] = useState(null);
  const [loadingOneOff, setLoadingOneOff] = useState(false);
  const [isProductRequestModalOpen, toggleProductRequestModalOpen, setProductRequestModalOpen] = useToggle(null);
  const hasCustomList = useMemo(() => !!customList?.length, [customList]);

  const [showAccountsModal, toggleAccountsModal, setAccountsModal] = useToggle();
  const [matchedAccounts, setMatchedAccounts] = useState();
  const [isCustomListRequest, setCustomListRequest] = useState(false);
  const [productData, setProductData] = useState(null);

  const filteredProducts = useProductsFilter(products, filters);
  const sortedProducts = useProductsSort(filteredProducts, sorting, filters);

  useEffect(() => {
    getOrders();
  }, [getOrders, me?._id]);

  useEffect(() => {
    const { productId } = location.state || {};
    const element = productId && document.getElementById(`shop-product-${productId}`);

    if (element) {
      setTimeout(() => {
        element.scrollIntoView();
      }, 0);
    }
  }, [location]);

  const flatOrders = useMemo(() => {
    const prodOrders = {};
    const userTriggered = ['user', 'user_one_off'];

    if (orders && orders.length > 0) {
      for (const order of orders) {
        if (order.date_fulfilled && order.status !== 'cancelled') {
          const curProduct = order?.subproduct_id?.product;
          if (
            (curProduct && !prodOrders[curProduct?._id]) ||
            (prodOrders[curProduct?._id] &&
              new Date(prodOrders[curProduct?._id].date_fulfilled).getTime() <
                new Date(order?.date_fulfilled).getTime())
          ) {
            let productFeedback;
            if (order?.product_feedback) {
              if (order?.product_feedback.like && !order?.product_feedback.dislike) {
                productFeedback = 'like';
              } else if (!order?.product_feedback.like && order?.product_feedback.dislike) {
                productFeedback = 'dislike';
              }
            }

            prodOrders[curProduct._id] = {
              date_ordered: order.date_fulfilled,
              fulfilled_by: userTriggered.includes(order?.source) ? 'user' : 'scale',
              productFeedback,
            };
          }
        }
      }
    }
    return prodOrders;
  }, [orders]);

  const LazyLoadComponent = useMemo(() => (shouldLazyLoad ? LazyLoad : ({ children }) => children), [shouldLazyLoad]);

  const isMobile = useMediaQuery({ maxDeviceWidth: 767 });
  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 });
  const isGuest = useIsGuest(me);

  const filtersOverride = {
    ...(vendor ? { vendor_id: [vendor.id] } : {}),
  };

  useEffect(() => {
    getProductAttributes(checkout?._id);
  }, [getProductAttributes, checkout, me?._id]);

  useEffect(() => {
    setFilters({ ...filtersFromQuery(query), ...filtersOverride });
    if (sorting !== 'search' || query.sort) {
      setSorting(query.sort || (filters.text ? 'search' : undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, vendor]);

  const openDetails = useCallback(
    product => {
      history.push(location.pathname + location.search, { productId: product._id });

      const pathname = `/products/${product.slug || product._id}`;
      const search = qs.stringify({
        ...(isVendorLandingShop ? { vendor: true } : {}),
        back: location.pathname + location.search,
      });

      if (isVendorLandingShop) {
        window.location = `${pathname}?vendor=true`;
      } else {
        history.push({ pathname, search });
      }
    },
    [history, isVendorLandingShop, location]
  );

  useEffect(() => {
    if (query.product_id) {
      const product = products.find(product => product._id === query.product_id);
      history.push(`/products/${product?.slug || product?._id || query.product_id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, query]);

  useEffect(() => {
    getProducts({
      vendorId: vendor ? vendor.id : '',
      type: match.params.type,
      category,
      withHidden: !!checkout?.vendor_id,
    });
  }, [getProducts, match.params.type, vendor, category, me?._id, checkout?.vendor_id]);

  useLayoutEffect(() => {
    if (listRef.current) {
      setProductsCount(listRef.current.querySelectorAll('.product-col:not(.d-none)').length);
      forceCheck();
    } else {
      setProductsCount(0);
    }
  }, [filters, products]);

  const toggleFilters = () => setFiltersOpen(!areFiltersOpen);

  const toggleOneOffModal = () => setOneOffModalOpen(!isOneOffModalOpen);

  const onFiltersChange = useCallback(
    (newFilters, extraFilters = {}) => {
      const vendor_id = vendor ? [] : newFilters.vendor_id || [];

      const params = Object.entries({ ...newFilters, vendor_id })
        .map(([key, value]) => [key, value.length ? value.join(',') : undefined])
        .reduce((par, [key, value]) => ({ ...par, [key]: value }), {});

      history.push({
        pathname: location.pathname,
        search: qs.stringify({ ...query, ...params, ...extraFilters }).replace('%2C', ','),
      });
    },
    [vendor, history, location.pathname, query]
  );

  const onSearchChange = useCallback(
    newFilters => {
      if (newFilters.text && !sorting) {
        setSorting('search');
      } else if (!newFilters.text && sorting === 'search') {
        setSorting(undefined);
      }

      setFilters({ ...filters, ...newFilters });
      history.push({
        pathname: location.pathname,
        search: qs.stringify({ ...query, text: newFilters.text }).replace('%2C', ','),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sorting, filters, history]
  );

  const onSortingChange = sortValue => {
    const sort = sortValue === sorting ? undefined : sortValue;
    history.push({
      pathname: location.pathname,
      search: qs.stringify({ ...query, sort }),
    });
  };

  const onChooseProductSuccess = useCallback(() => addAlert('Product has been successfully saved', 'success'), [
    addAlert,
  ]);

  const onPaymentSuccess = useCallback(async () => {
    const {
      payload: { token },
    } = await finishRegistration();

    history.push(token ? `/setup_password/${token}` : '/setup_text');
  }, [finishRegistration, history]);

  const onChooseProduct = useCallback(
    async (product, data) => {
      if (gift) {
        try {
          const { error: giftError, payload: giftPayload } = await getStartedGift({
            code: gift,
            product: { product: product._id, variant: data.variant },
            phone,
            grind: data.grind,
          });

          if (giftError) {
            return addAlert(`Something went wrong: ${giftPayload?.response?.message}`, 'danger');
          }

          const { error, payload } = await finishCheckout(giftPayload.checkoutId);

          if (error) {
            return addAlert(`Something went wrong: ${payload?.response?.message}`, 'danger');
          }

          return await onPaymentSuccess();
        } catch (e) {
          addAlert(`Something went wrong: ${e.message}`, 'danger');
        }
      }

      const matched =
        me?.accounts.filter(account => account.product.product.category._id === product?.category?._id) || [];
      setMatchedAccounts(matched);

      if (matched?.length > 1) {
        setProductData({
          product: { product: product._id, variant: data.variant },
          vendorId: product?.vendor_id?._id,
          ...data,
        });
        setCustomListRequest(false);
        toggleAccountsModal();
        return;
      }

      const result = await makeBottomless(product._id, data);
      onChooseProductSuccess();

      return result;
    },
    [
      addAlert,
      finishCheckout,
      getStartedGift,
      gift,
      makeBottomless,
      onChooseProductSuccess,
      onPaymentSuccess,
      phone,
      me,
      toggleAccountsModal,
    ]
  );

  const onOneOffOrder = useCallback(async (product, { variant, ...data }) => {
    const { _id, image_src, variants, name, vendor_id } = product || {};
    setPendingOneOffOrder({
      product: { product: _id, variant, imageUrl: image_src, allVariants: variants, name, vendor: vendor_id },
      source: 'user_one_off',
      ...data,
    });
    setTimeout(() => {
      setOneOffModalOpen(true);
    }, 0);
  }, []);

  const triggerOneOffOrder = useCallback(async () => {
    setLoadingOneOff(true);

    try {
      const { payload, error } = await triggerOrder(pendingOneOffOrder);

      if (payload?.user_id && payload.user_id !== me._id) {
        await changeScale({ account: payload.user_id });
        return history.push('/profile');
      }

      addAlert('Order has been successfully generated', 'success');

      if (error && payload.status === 401) {
        await newScaleCheckout();
        setCookie('last_landing_rule', `products/:slug/buy`, cookieOptions);
        const { payload: getStartedPayload } = await getStarted({
          product: { product: pendingOneOffOrder.product.product, variant: pendingOneOffOrder.product.variant },
          guest: true,
          path: `products/:slug/buy`,
          shouldUpdateCheckout: true,
        });

        return history.push(`/get_started/${getStartedPayload.checkoutId}`);
      }
    } catch (error) {
      addAlert('Could not generate order. Please try again later', 'danger');
    }
    setLoadingOneOff(false);
    setOneOffModalOpen(false);
  }, [pendingOneOffOrder, triggerOrder, addAlert, changeScale, me, history, newScaleCheckout, getStarted, setCookie]);

  const cancelOneOffOrder = useCallback(() => {
    setOneOffModalOpen(false);
    setPendingOneOffOrder(null);
  }, [setPendingOneOffOrder]);

  const onFiltersBatchChange = filters => {
    onFiltersChange(filters);
  };

  const onExternalFilterChange = (type, value, extraFilters = {}) => {
    const currentValues = filters[type] || [];

    if (!currentValues.includes(value)) {
      onFiltersChange({ ...filters, [type]: [...currentValues, value] }, extraFilters);
    }
  };

  const addProductToCustomList = addToCustomList
    ? async (product, { variant, ...data }) => {
        const result = await addToCustomList({ product: product._id, variant, ...data });
        addToast('Product has been successfully added', 'success');
        setAccountsModal(false);
        trackEvent(EVENT_ADD_TO_CUSTOM_LIST);
        return result;
      }
    : undefined;

  const onAddToCustomList = addToCustomList
    ? async (product, data) => {
        const matched =
          me?.accounts.filter(account => account.product.product.category._id === product?.category?._id) || [];
        setMatchedAccounts(matched);

        if (matched?.length > 1) {
          setProductData({
            product: { product: product._id, variant: data.variant },
            vendorId: product?.vendor_id?._id,
            ...data,
          });
          setCustomListRequest(true);
          toggleAccountsModal();
          return;
        }

        return addProductToCustomList(product, data);
      }
    : undefined;

  const filtersCount =
    Object.values(filters).reduce((a, b) => a + (typeof b === 'string' ? Boolean(b.length) : b.length), 0) -
    (vendor ? 1 : 0);

  const clearFilters = newFilters => {
    onSearchChange({ text: '' });
    onFiltersChange({ text: '', ...newFilters });
  };

  const onCleanClick = () => {
    clearFilters(
      Object.keys(query || {}).reduce((all, key) => ({ ...all, [key]: '', category: [query.category] }), [])
    );
  };

  const handleProductRequestSubmit = useCallback(
    async data => {
      try {
        await submitProductRequest(data);
        addAlert('Product request submitted!', 'success');
      } catch (error) {
        addAlert('Could not submit request. Please try again later', 'danger');
      }
      setProductRequestModalOpen(false);
    },
    [setProductRequestModalOpen, submitProductRequest, addAlert]
  );

  const Heading = (
    <div className="d-flex align-items-center overflow-auto">
      <Tabs isGuest={isGuest} slug={categorySlug} />
      {!isTabletOrMobileDevice ? (
        <>
          <Button className="d-md-none flex-grow-1" color="link" onClick={toggleFilters}>
            Filters{filtersCount ? ` (${filtersCount})` : ''}
          </Button>
        </>
      ) : (
        <></>
      )}
      <div className="pl-2" />
      <ChangeScale path="/shop" />
    </div>
  );

  const enabledFilters = useMemo(
    () =>
      omit(filters, ['text', 'utm_source', 'utm_medium', 'utm_content', 'utm_campaign', '__s', 'editQueue', 'error']),
    [filters]
  );

  const shouldHideBuyOneOff = useMemo(() => query?.back && query.back.includes('status'), [query.back]);
  const hasOneOff = useMemo(() => tab === 'products' && !shouldHideBuyOneOff, [shouldHideBuyOneOff, tab]);

  const hasCustomLists = useMemo(() => !isGuest && tab === 'products', [isGuest, tab]);
  const oneOffText = useMemo(() => (isGuest ? 'Buy' : undefined), [isGuest]);

  const appliedFilters = useMemo(
    () =>
      Object.entries(enabledFilters).filter(
        ([key, value]) => !(vendor?.id && key === 'vendor_id' && value.length === 1 && value[0] === vendor.id)
      ).length,
    [enabledFilters, vendor]
  );

  return (
    <Wrapper
      title="Shop"
      back={me ? '' : query.back || getCategoryHomePage(categorySlug)}
      heading={Heading}
      pricingRule={pricingRule}
      withBanner={withBanner}
    >
      <Row>
        {!hideFilters && (
          <Col
            xs="2"
            className={classNames('d-none d-md-block shop-filters-column', {
              'with-custom-list': hasCustomList,
            })}
          >
            {attributes && products && (
              <Filters
                products={products}
                onChange={onFiltersChange}
                filters={enabledFilters}
                vendorId={vendor?.id}
                featuredTags={featuredTags}
                showRoasterFilter={!vendor}
                attributes={attributes}
                clearFilters={clearFilters}
                slug={categorySlug}
              />
            )}
          </Col>
        )}

        <Col
          xs="12"
          md={!hideFilters ? '10' : '12'}
          className={classNames({
            'with-panel': me,
          })}
        >
          {attributes && products && flatOrders && (
            <>
              {!hideSearch && <Search onChange={onSearchChange} text={filters.text} />}
              {!hideSorting && (
                <Row className="align-items-center mb-3">
                  <Col>
                    <Sorting onChange={onSortingChange} sorting={sorting} slug={categorySlug} />
                  </Col>
                  {isTabletOrMobileDevice && (
                    <Col className="text-right">
                      <>
                        <Button className="filter-btn" color="link" size="sm" onClick={toggleFilters}>
                          <strong style={{ color: '#989898', fontSize: '14px', textDecoration: 'underline' }}>
                            Filters{filtersCount ? ` (${filtersCount})` : ''}
                          </strong>
                        </Button>
                      </>
                    </Col>
                  )}
                </Row>
              )}

              <div
                ref={listRef}
                id="shop-products-container"
                className={sortedProducts?.length ? 'shop-products-container' : ''}
              >
                <Row>
                  {sortedProducts.map(product => {
                    return (
                      <Col xs="12" key={product._id} className="product-col" id={`shop-product-${product._id}`}>
                        <LazyLoadComponent
                          once
                          height={100}
                          offset={350}
                          scrollContainer={isMobile ? '.panel-wrapper' : '#panel-content'}
                        >
                          <Product
                            me={me}
                            pathname={location.pathname}
                            pricingRule={pricingRule}
                            isFirstBag={isFirstBag}
                            onClick={() => openDetails(product)}
                            product={product}
                            onChooseProduct={onChooseProduct}
                            onOneOffOrder={onOneOffOrder}
                            onFilterChange={onExternalFilterChange}
                            featuredTags={featuredTags}
                            vendor={vendor}
                            grinds={attributes.grinds}
                            hasOneOff={hasOneOff}
                            hasCustomLists={hasCustomLists}
                            hasMakeBottomless={!isGuest}
                            oneOffText={oneOffText}
                            flatOrders={flatOrders}
                            addToCustomList={onAddToCustomList}
                            location={location}
                            makeBottomlessText={makeBottomlessText}
                            isVendorLandingShop={isVendorLandingShop}
                            slug={categorySlug}
                            userGrind={userGrind || me?.grind?._id || checkout?.grind?._id}
                            onlyMappedVariants={!!me?.vendor_id || !!checkout?.vendor_id}
                            checkout={checkout}
                          />
                        </LazyLoadComponent>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </>
          )}
          {!hideFilters && (
            <DataLoading count={productsCount} isLoading={isLoading}>
              <div className="d-flex align-items-center">
                <span>No matching products</span>
                {appliedFilters > 0 && (
                  <Button color="link" onClick={onCleanClick}>
                    Clear filters
                  </Button>
                )}
              </div>
              {checkout && !appliedFilters > 0 && (
                <div>
                  <Link
                    className="mt-2 d-inline-block"
                    to={`/get_started/${checkout._id}${
                      checkout.source === CheckoutSource.Shopify ? '?source=shopify' : ''
                    }`}
                  >
                    <Button className="px-0" color="link">
                      Back to checkout
                    </Button>
                  </Link>
                </div>
              )}
              <RequestProductForm
                productsCount={productsCount}
                isLoading={isLoading}
                onCleanClick={onCleanClick}
                isProductRequestModalOpen={isProductRequestModalOpen}
                toggleProductRequestModalOpen={toggleProductRequestModalOpen}
                handleProductRequestSubmit={handleProductRequestSubmit}
                slug={categorySlug}
              />
            </DataLoading>
          )}
        </Col>
      </Row>
      <Modal unmountOnClose={false} isOpen={areFiltersOpen} toggle={toggleFilters} size="lg" className="filters-modal">
        <ModalHeader toggle={toggleFilters} />
        <ModalBody>
          {attributes && products && (
            <Filters
              products={products}
              onChange={onFiltersBatchChange}
              vendorId={vendor?.id}
              filters={filters}
              changeBatch={true}
              onClose={toggleFilters}
              featuredTags={featuredTags}
              attributes={attributes}
              clearFilters={clearFilters}
              slug={categorySlug}
            />
          )}
        </ModalBody>
      </Modal>
      {attributes && (
        <OneOffOrderModal
          me={me}
          isOneOffModalOpen={isOneOffModalOpen}
          toggleOneOffModal={toggleOneOffModal}
          cancelOneOffOrder={cancelOneOffOrder}
          loadingOneOff={loadingOneOff}
          triggerOneOffOrder={triggerOneOffOrder}
          setPendingOneOffOrder={setPendingOneOffOrder}
          pendingOneOffOrder={pendingOneOffOrder}
          pathname={location.pathname}
          pricingRule={pricingRule}
          isFirstBag={isFirstBag}
          grinds={attributes.grinds}
        />
      )}
      {showAccountsModal && (
        <AccountsModal
          me={me}
          isOpen={showAccountsModal}
          toggle={toggleAccountsModal}
          matchedAccounts={matchedAccounts}
          productData={productData}
          makeBottomlessProduct={makeBottomless}
          addProductToCustomList={addProductToCustomList}
          isCustomList={isCustomListRequest}
        />
      )}
    </Wrapper>
  );
};

const filtersFromQuery = query =>
  Object.keys(query)
    .filter(
      key =>
        ![
          'sort',
          'back',
          'category',
          'utm_source',
          'utm_content',
          'utm_campaign',
          'utm_medium',
          '__s',
          'editQueue',
          'skipAccountSelection',
        ].includes(key)
    )
    .reduce((all, key) => ({ ...all, [key]: queryFilter(query, key) }), {});

const queryFilter = (query, field) => {
  if (!query[field]) {
    return [];
  }
  if (Array.isArray(query[field])) {
    return query[field];
  }

  return query[field].split(',');
};

ShopPageComponent.propTypes = {
  me: PropTypes.object,
  products: PropTypes.array.isRequired,
  getProducts: PropTypes.func.isRequired,
  triggerOrder: PropTypes.func.isRequired,
  makeBottomless: PropTypes.func.isRequired,
  getProductAttributes: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    state: PropTypes.object,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  featuredTags: PropTypes.array.isRequired,
  wrapper: PropTypes.func,
  vendor: PropTypes.object,
  pricingRule: PropTypes.object,
  isFirstBag: PropTypes.bool,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
  }).isRequired,
  isPublic: PropTypes.bool,
  withBanner: PropTypes.bool,
  hideFilters: PropTypes.bool,
  hideSorting: PropTypes.bool,
  hideSearch: PropTypes.bool,
  shouldLazyLoad: PropTypes.bool,
  orders: PropTypes.array.isRequired,
  getOrders: PropTypes.func.isRequired,
  addToCustomList: PropTypes.func,
  addToast: PropTypes.func.isRequired,
  getStartedGift: PropTypes.func.isRequired,
  finishRegistration: PropTypes.func.isRequired,
  finishCheckout: PropTypes.func.isRequired,
  makeBottomlessText: PropTypes.string,
  isVendorLandingShop: PropTypes.bool,
  attributes: PropTypes.shape({
    grinds: PropTypes.array.isRequired,
  }),
  category: PropTypes.string,
  categorySlug: PropTypes.string,
  submitProductRequest: PropTypes.func.isRequired,
  changeScale: PropTypes.func.isRequired,
  getStarted: PropTypes.func.isRequired,
  newScaleCheckout: PropTypes.func.isRequired,
  userGrind: PropTypes.string,
  customList: PropTypes.array,
  checkout: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    source: PropTypes.string,
    vendor_id: PropTypes.string,
    grind: PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  }),
};

ShopPageComponent.defaultProps = {
  isFirstBag: false,
  hideFilters: false,
  hideSearch: false,
  hideSorting: false,
  shouldLazyLoad: true,
  isVendorLandingShop: false,
};

export const ShopPage = withMetaTags({
  title: ({ vendor }) => (vendor?.name ? `Bottomless.com: ${vendor.name}` : 'Bottomless.com: Shop'),
  description: ({ vendor }) => (vendor ? vendor.description : undefined),
  image: ({ vendor }) => (vendor ? (vendor.img_url || '').replace(/\?.*$/, '') : undefined),
})(
  connect(
    ({ product, manifest: { featuredTags }, user: { me }, order }, { category: categorySlug }) => {
      const slug = categorySlug || me?.product?.product?.category?.slug;

      return {
        products: product.data.filter(product => !slug || product.category.slug === slug),
        isLoading: product.isLoading,
        featuredTags: featuredTags || [],
        me,
        orders: order && order.data ? order.data : [],
        attributes: product.attributes,
        categorySlug: slug,
        customList: product?.wishlist,
      };
    },
    dispatch => ({
      getOrders: () => dispatch(getOrdersAction()),
      addAlert: (message, type) => dispatch(addAlertAction(message, type)),
      getProducts: ({ vendorId, type, category, withHidden }) =>
        dispatch(getProductsAction({ vendorId, type, category, withHidden })),
      triggerOrder: data => dispatch(triggerOrderAction(data)),
      getProductAttributes: checkoutId => dispatch(getProductAttributesAction(checkoutId)),
      addToast: (message, type) => dispatch(addToastAction(message, type)),
      getStartedGift: data => dispatch(getStartedGiftAction(data)),
      finishRegistration: () => dispatch(getStartedCompleteAction()),
      finishCheckout: (id, data) => dispatch(finishCheckoutAction(id, data)),
      submitProductRequest: data => dispatch(submitProductRequestAction(data)),
      changeScale: data => dispatch(changeScaleAction(data)),
      getStarted: data => dispatch(getStartedAction(data)),
      newScaleCheckout: () => dispatch(newScaleCheckoutAction()),
    })
  )(ShopPageComponent)
);
