import qs from 'query-string';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Edit } from 'react-feather';

export const CoffeeChangeProduct = ({
  checkout,
  goToRotation,
  goToCustomListShop,
  goToShop,
  goToPersonalized,
  goToQuiz,
}) => {
  const onChange = () => {
    if (qs.parse(window.location.search).new_scale) {
      goToRotation(checkout._id);
    } else if (checkout.custom_list) {
      goToCustomListShop();
    } else if (checkout.vendor_id) {
      goToShop(checkout._id);
    } else if (checkout.product.product.personalized_rotation) {
      goToPersonalized(checkout._id);
    } else if (checkout.quiz_answers) {
      goToQuiz(checkout._id);
    } else {
      goToShop(checkout._id);
    }
  };

  return (
    <Button color="link" size="sm" onClick={onChange} className="placeholder-text">
      <Edit width="18" height="18" role="img" aria-label="Edit" />
    </Button>
  );
};

CoffeeChangeProduct.propTypes = {
  checkout: PropTypes.object,
  goToRotation: PropTypes.func.isRequired,
  goToCustomListShop: PropTypes.func.isRequired,
  goToShop: PropTypes.func.isRequired,
  goToPersonalized: PropTypes.func.isRequired,
  goToQuiz: PropTypes.func.isRequired,
};
