import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserAvatar } from '@bottomless/common/src/components';
import './ChangeScale.scss';

const ChangeScaleComponent = ({ me, path }) => {
  if (!me) {
    return null;
  }

  return (
    <div className="d-flex w-100 justify-content-end align-items-center change-scale">
      <Link
        to={{ pathname: '/change-account', state: { path, showClose: true } }}
        className="change-scale-image border rounded p-2"
      >
        <UserAvatar user={me} height="26" />
      </Link>
    </div>
  );
};

ChangeScaleComponent.propTypes = {
  me: PropTypes.object,
  path: PropTypes.string,
  isCloseButton: PropTypes.bool,
};

ChangeScaleComponent.defaultProps = {
  path: null,
  isCloseButton: false,
};

export const ChangeScale = connect(({ user }) => ({ me: user.me }))(ChangeScaleComponent);
