import { GetManifestTypes, GetVendorManifestTypes } from './manifest.actions';
import { LogoutTypes, ChangeScaleTypes } from '../auth';

const initialState = {
  featuredTags: [],
  premium: [],
  basic: [],
  isLoading: false,
  vendorManifest: {
    isLoading: false,
    vendorId: null,
    hasGeneralPricing: false,
  },
};

export const menifestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetVendorManifestTypes.REQUEST:
      return {
        ...state,
        vendorManifest: {
          ...state.vendorManifest,
          isLoading: true,
        },
      };

    case GetVendorManifestTypes.SUCCESS: {
      const { payload } = action;
      const hasGeneralPricing = !!payload?.dynamic_pricing?.find(it => it.type === 'general');

      return {
        ...state,
        vendorManifest: {
          ...state.vendorManifest,
          ...payload,
          hasGeneralPricing,
          isLoading: false,
        },
      };
    }

    case GetVendorManifestTypes.FAILURE:
      return {
        ...state,
        vendorManifest: {
          ...state.vendorManifest,
          isLoading: false,
        },
      };

    case GetManifestTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetManifestTypes.SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        featuredTags: payload.featured_tags,
        premium: payload.premium,
        basic: payload.basic,
        isLoading: false,
      };
    }

    case GetManifestTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case LogoutTypes.SUCCESS:
    case ChangeScaleTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
