import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Row } from 'reactstrap';
import { MapPin } from 'react-feather';
import { useMediaQuery } from 'react-responsive';
import { useToggle } from '@bottomless/common/hooks';
import { Image } from '@bottomless/common/components';
import { ShipTime } from './ShipTime';

const capitalize = phrase => {
  return phrase?.toLowerCase().replace(/\b\w/g, c => c.toUpperCase());
};

const isMondayToThursday = () => {
  const today = new Date();
  const day = today.getDay();
  return day !== 0 && day !== 5 && day !== 6;
};

const MainBannerSectionComponent = ({ vendor, navigateToShop, products, hasCustomStyles, onClickGetStarted }) => {
  const isTodayInMondayToThursday = isMondayToThursday();
  const [descClosed, toggleDesc] = useToggle(true);
  const isMobile = useMediaQuery({ maxDeviceWidth: 479 });

  const mainImage = useMemo(() => {
    let image;
    if (vendor?.custom_styles?.images?.banner) {
      image = vendor.custom_styles.images.banner;
    } else if (vendor?.img_url) {
      image = vendor.img_url;
    } else {
      image = 'https://bottomless.imgix.net/vendor-landing/default-logo-1.png';
    }

    return image;
  }, [vendor]);

  const description = useMemo(() => {
    const desc = vendor?.custom_styles?.slogan || vendor?.description;

    if (desc?.length > 200) return `${desc.slice(0, 200)}...`;
    return desc;
  }, [vendor]);

  return (
    <section className="main-banner-section">
      <Container>
        <Row className="justify-content-center">
          <div className="d-flex flex-lg-row flex-column align-items-center">
            <Image width={isMobile ? '300' : '400'} src={mainImage} alt="vendor logo" />
            {!hasCustomStyles ? (
              <div className=" container d-flex flex-column justify-content-around h-100 ml-lg-5 align-items-lg-start align-items-center">
                <div className="text-center text-lg-left">
                  <h2 className="mb-0 mt-4 mt-lg-0 text-capitalize">{vendor.name}</h2>
                  <div>
                    <MapPin size="16" color="#0632C2" className="mr-1" />
                    {capitalize(vendor.verifiedAddress?.city)}, {vendor.verifiedAddress?.state}
                  </div>
                </div>
                <div className="text-center text-lg-left mt-lg-4">
                  <h5 className="my-4 my-lg-0 pr-lg-3 text-primary">Subscribe by Usage via Bottomless</h5>
                  {products?.length > 0 && (
                    <ShipTime
                      isMondayToThursday={isTodayInMondayToThursday}
                      product={(products && products[0]) || null}
                    />
                  )}
                </div>
                <div className="text-secondary text-center text-lg-left pr-lg-5 my-4 my-lg-3 description">
                  {vendor.description}
                </div>
                <Button
                  color="primary"
                  size="lg"
                  onClick={() => navigateToShop({ position: 'vendor-visit-shop' })}
                  className="mt-4 mt-lg-0"
                >
                  Visit Shop
                </Button>
              </div>
            ) : (
              <div className="container d-flex flex-column justify-content-around h-100 ml-lg-5 align-items-lg-start align-items-center">
                <div>
                  <h2
                    className="text-center text-lg-left mt-4 mt-lg-0 mb-0 vendor-heading"
                    style={{ color: vendor.custom_styles.brand_color }}
                  >
                    {vendor.name}
                  </h2>
                  <h4 className="mb-4 mb-lg-0 text-center text-lg-left">On a Subscription by Usage</h4>
                </div>
                {(vendor?.custom_styles?.slogan || vendor?.description)?.length <= 200 && (
                  <div className="pr-lg-5 my-4 my-lg-2 text-center text-lg-left slogan">{description}</div>
                )}
                {descClosed && (vendor?.custom_styles?.slogan || vendor?.description)?.length > 200 && (
                  <>
                    <div className="pr-lg-5 my-4 my-lg-2 text-center text-lg-left slogan">{description}</div>
                    <p onClick={toggleDesc} className="text-secondary cursor-pointer">
                      Read More
                    </p>
                  </>
                )}
                {!descClosed && (vendor?.custom_styles?.slogan || vendor?.description)?.length > 200 && (
                  <>
                    <div className="pr-lg-5 my-4 my-lg-2 text-center text-lg-left slogan">
                      {vendor.custom_styles.slogan || vendor.description}
                    </div>
                    <p onClick={toggleDesc} className="text-secondary cursor-pointer">
                      Read Less
                    </p>
                  </>
                )}
                {products?.length > 0 && (
                  <ShipTime
                    isMondayToThursday={isTodayInMondayToThursday}
                    product={(products && products[0]) || null}
                  />
                )}
                <Button
                  color={vendor?.custom_styles ? 'dark' : 'primary'}
                  size="lg"
                  href="#product-types"
                  onClick={() => onClickGetStarted()}
                  className="mt-4 mt-lg-3"
                >
                  Get Started
                </Button>
              </div>
            )}
          </div>
        </Row>
      </Container>
    </section>
  );
};

MainBannerSectionComponent.propTypes = {
  vendor: PropTypes.object.isRequired,
  navigateToShop: PropTypes.func.isRequired,
  products: PropTypes.array,
  hasCustomStyles: PropTypes.bool.isRequired,
  onClickGetStarted: PropTypes.func.isRequired,
};

export const MainBannerSection = memo(MainBannerSectionComponent);
