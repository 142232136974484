import { LogoutTypes, ChangeScaleTypes } from '@bottomless/common/store';
import { GetStatRecordsTypes } from './stats.actions';

const initialState = {
  data: [],
  lastTare: null,
  isLoading: false,
};

export const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetStatRecordsTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetStatRecordsTypes.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        lastTare: action.payload.lastTare,
        isLoading: false,
      };

    case GetStatRecordsTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case LogoutTypes.SUCCESS:
    case ChangeScaleTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
