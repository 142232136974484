import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FaqElement } from './FaqElement';

export const SubscriptionFaq = ({ faq }) => {
  const [isOpen, setOpen] = useState(false);

  const onClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(true);
  }, []);

  return (
    <>
      <div className="text-center">
        <a href="#" onClick={onClick}>
          More information about your subscription
        </a>
      </div>
      {isOpen && (
        <div className="text-center">
          <h1 className="text-center mb-5 mt-5">About your subscription</h1>
          {faq.map(({ question, answer }) => (
            <FaqElement key={question} question={question} answer={answer} />
          ))}
        </div>
      )}
    </>
  );
};

SubscriptionFaq.propTypes = {
  faq: PropTypes.arrayOf(PropTypes.object).isRequired,
};
