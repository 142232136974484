import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useConditionalDataEffect, useDataEffect, useQueryString } from '@bottomless/common/hooks';
import { addToastAction, changeScaleAction } from '@bottomless/common/store';
import { GetStartedModal } from '../../components/GetStartedModal/GetStartedModal';
import { PanelPage } from '../../layouts/PanelPage/PanelPage';
import {
  getCheckoutAction,
  getMeAction,
  getPricingRuleAction,
  getStartedAction,
  makeBottomlessProductAction,
  newScaleCheckoutAction,
  setProductAction,
} from '../../store';
import { ShopPage } from './Shop';
import { Navbar } from '../../components/Navbar/Navbar';

const Wrapper = props => <PanelPage back="/" className="simple-panel" {...props} />;

const PublicShopPageComponent = ({
  getStarted,
  getPricingRule,
  noEmail,
  me,
  getMe,
  makeBottomlessProduct,
  changeScale,
  newScaleCheckout,
  getCheckout,
  ...props
}) => {
  const {
    params: { category },
  } = useQueryString();
  const [isModalOpen, setModalOpen] = useState(false);
  const [productData, setProductData] = useState(null);
  const [pricingRule, setPricingRule] = useState(null);
  const toggleModal = () => setModalOpen(!isModalOpen);
  const [{ checkout, phone }] = useCookies(['checkout', 'phone']);

  useDataEffect(getPricingRule, setPricingRule, props.match.path);
  useDataEffect(getMe);

  const { data: fetchedCheckout } = useConditionalDataEffect(checkout, getCheckout, undefined, checkout, null, [
    checkout,
  ]);

  const userGrind = useMemo(() => fetchedCheckout?.grind?._id || me?.grind?._id, [fetchedCheckout, me]);

  const googleRedirect = useMemo(() => {
    if (!productData) {
      return '/auth/google';
    }

    return `/auth/google?product=${productData.product.product}&variant=${productData.product.variant}&grind=${productData.grind}`;
  }, [productData]);

  const makeBottomless = async (product, data) => {
    if (checkout || (!me && noEmail)) {
      const { payload } = await getStarted({
        product: { product, variant: data.variant },
        ...data,
      });

      props.history.push(`/get_started/${payload.checkoutId}`);
    } else if (!me) {
      setProductData({ product: { product, variant: data.variant }, ...data });
      toggleModal();
    } else {
      const { payload, error } = await makeBottomlessProduct(product, data);

      if (error && payload.status === 401) {
        await newScaleCheckout();
        const { payload: getStartedPayload } = await getStarted({
          product: { product, variant: data.variant },
          ...data,
        });

        return props.history.push(`/get_started/${getStartedPayload.checkoutId}`);
      }

      if (payload?.userId && payload.userId !== me._id) {
        await changeScale({ account: payload.userId });
      }

      return props.history.push('/profile');
    }
  };

  const onSubmit = data => {
    return getStarted({ phone, ...data, ...productData });
  };

  const onSuccess = ({ checkoutId }) => {
    props.history.push(`/get_started/${checkoutId}`);
  };

  const computedPricingRule = useMemo(() => (!me ? pricingRule : me.pricing_rule), [me, pricingRule]);

  return (
    <>
      <div className="skip-to-main">
        <a href="#main-content" className="text-dark">
          Skip to main content
        </a>
      </div>
      <main className="panel-layout panel-layout-with-banner public-shop-layout" id="main-content">
        <Navbar small />
        <ShopPage
          {...props}
          makeBottomless={makeBottomless}
          pricingRule={computedPricingRule}
          wrapper={Wrapper}
          isFirstBag={checkout || !me}
          withBanner
          category={category || 'coffee'}
          userGrind={userGrind}
        />
        <GetStartedModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          getStarted={onSubmit}
          onSignUp={onSuccess}
          pricingRule={computedPricingRule}
          googleRedirect={googleRedirect}
        />
      </main>
    </>
  );
};

PublicShopPageComponent.propTypes = {
  getStarted: PropTypes.func.isRequired,
  getPricingRule: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  noEmail: PropTypes.bool,
  withTime: PropTypes.bool,
  getMe: PropTypes.func.isRequired,
  makeBottomlessProduct: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  me: PropTypes.shape({
    _id: PropTypes.string,
    pricing_rule: PropTypes.object,
    grind: PropTypes.shape({ _id: PropTypes.string }),
  }),
  changeScale: PropTypes.func.isRequired,
  newScaleCheckout: PropTypes.func.isRequired,
  getCheckout: PropTypes.func.isRequired,
};

export const PublicShopPage = connect(
  ({ user: { me } }) => ({ pricingRule: (me || {}).pricing_rule, me }),
  dispatch => ({
    getStarted: data => dispatch(getStartedAction(data)),
    getPricingRule: path => dispatch(getPricingRuleAction(path)),
    addToast: data => dispatch(addToastAction(data)),
    makeBottomlessProduct: (id, data) => dispatch(makeBottomlessProductAction(id, data)),
    getMe: () => dispatch(getMeAction()),
    changeScale: data => dispatch(changeScaleAction(data)),
    newScaleCheckout: () => dispatch(newScaleCheckoutAction()),
    setProduct: () => dispatch(setProductAction()),
    getCheckout: id => dispatch(getCheckoutAction(id)),
  })
)(PublicShopPageComponent);
