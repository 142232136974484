import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useToggle } from '../../../hooks';
import { Tooltip } from 'reactstrap';
import './InfoTooltip.scss';

export const InfoTooltip = ({ children, className, size = 'lg' }) => {
  const [isOpen, toggle] = useToggle();
  const ref = useRef();
  const target = useCallback(() => ref.current, [ref]);

  return (
    <>
      <span
        role="button"
        ref={ref}
        className={classNames('d-inline-block information-badge', `information-badge-${size}`, className)}
      >
        i
      </span>
      <Tooltip placement="top" target={target} isOpen={isOpen} toggle={toggle}>
        {children}
      </Tooltip>
    </>
  );
};

InfoTooltip.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
