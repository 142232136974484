import { useState } from 'react';
import { useCallback } from 'react';

const getErrorMessage = e => {
  if (e.details) {
    return Object.values(e.details).join(', ');
  }

  return e.message || e;
};

export const useSafeCallback = (fn, deps) => {
  const [error, setError] = useState(null);

  const callback = useCallback(
    (...data) => {
      setError(null);

      try {
        const result = fn(...data);

        if (result?.catch) {
          result.catch(e => setError(getErrorMessage(e)));
        }

        return result;
      } catch (e) {
        setError(getErrorMessage(e));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fn, setError, ...deps]
  );

  return [callback, error];
};
