import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export const ChangeListener = ({ values, onChange }) => {
  useEffect(() => {
    if (onChange) {
      onChange(values);
    }
  }, [values, onChange]);

  return null;
};

ChangeListener.propTypes = {
  values: PropTypes.any,
  onChange: PropTypes.func,
};
