import { useOnScreen } from '@bottomless/common/src/hooks';
import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container } from 'reactstrap';
import classNames from 'classnames';
import './HowItWorks.scss';
import { Step } from './Step';

const HowItWorksComponent = ({ actionText, onClick, isProductSpecific = false }) => {
  const ref = useRef();
  const onScreen = useOnScreen(ref, '-150px', true);
  const [actualOnScreen, setActualOnScreen] = useState(1);

  const actualImage = useMemo(() => ['step1-min_1', 'step2-min', 'step3-min'][actualOnScreen - 1], [actualOnScreen]);

  return (
    <div className={classNames('landing-how-it-works', { 'landing-how-it-works-visible': onScreen })}>
      <Container>
        <h2 className="text-center">How it works</h2>
        <p className="heading-aside text-center">
          With your first purchase, receive a Smart Bottomless Scale for free. It tracks your coffee usage and re-orders
          your next batch at the perfect time, every time!
        </p>
        <div className="row steps">
          <Col xs="12" lg="6">
            <img
              src={`https://bottomless.imgix.net/landing/${actualImage}.jpg?auto=compress,format`}
              loading="lazy"
              sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 93vw, 622px"
              alt="How it works"
              className="img-fluid how-it-works-image"
              ref={ref}
            />
          </Col>
          <Col xs="12" lg="6">
            <Step
              number={1}
              title={
                isProductSpecific ? (
                  'Choose Coffee'
                ) : (
                  <>
                    Build a Rotation
                    <br />
                    /Choose Coffee
                  </>
                )
              }
              description="With over 500 coffees from local roasters you will never run out of options!"
              actionText={actionText}
              onClick={onClick}
              isOnScreen={setActualOnScreen}
            />
            <Step
              number={2}
              title="Setup your Bottomless Scale"
              description="Setup is as easy as connecting to WiFi and your scale will last 9 months on a single charge!"
              isOnScreen={setActualOnScreen}
            />
            <Step
              number={3}
              title="Enjoy fresh coffee while never running out"
              description="An order is automatically triggered at the perfect time to ensure you always have freshly roasted
                  coffee delivered on time!"
              isOnScreen={setActualOnScreen}
            />
          </Col>
        </div>
      </Container>
    </div>
  );
};

HowItWorksComponent.propTypes = {
  actionText: PropTypes.string,
  onClick: PropTypes.func,
  isProductSpecific: PropTypes.bool,
};

export const HowItWorks = HowItWorksComponent;
