import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import RequestProductModal from '../../components/RequestProductModal';

export const GenericRequestProduct = ({
  isProductRequestModalOpen,
  toggleProductRequestModalOpen,
  handleProductRequestSubmit,
  me,
}) => {
  return (
    <div className="support-contact-wrapper">
      {isProductRequestModalOpen && (
        <RequestProductModal
          me={me}
          isOpen={isProductRequestModalOpen}
          toggle={toggleProductRequestModalOpen}
          onSubmit={handleProductRequestSubmit}
        />
      )}
      <div className="support-contact">
        <div>Can&apos;t find what you&apos;re looking for? </div>
        <Button color="success" onClick={toggleProductRequestModalOpen}>
          Request a product
        </Button>
        <div>OR</div>
        <p>
          Email:{' '}
          <b>
            <a href="mailto:support@bottomless.com">support@bottomless.com</a>
          </b>{' '}
          <br />
          Text:{' '}
          <b>
            <a href="sms:(206)(260-0782)">(206) 260-0782</a>
          </b>
        </p>
      </div>
    </div>
  );
};

GenericRequestProduct.propTypes = {
  isProductRequestModalOpen: PropTypes.bool,
  toggleProductRequestModalOpen: PropTypes.func,
  handleProductRequestSubmit: PropTypes.func,
  me: PropTypes.object,
};
