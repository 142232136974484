import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { DataLoading } from '@bottomless/common/components';
import { stripTags } from '@bottomless/common/utils';
import { useOnce, useDataEffect, useConditionalDataEffect } from '@bottomless/common/hooks';
import { EVENT_PRODUCT_VIEW, trackEvent } from '../../../../utils/tracker';
import { withMetaTags } from '../../../../components/MetaTags/MetaTags';
import { withRichProductMeta } from '../../../../components/MetaTags/RichProductMeta';
import { addAlertAction, addToastAction, changeScaleAction } from '@bottomless/common/store';
import { GetStartedModal } from '../../../../components/GetStartedModal/GetStartedModal';
import { IsLoggedIn } from '../../../../guards/is-logged.guard';
import { Panel } from '../../../../layouts/Panel/Panel';
import { PanelPage } from '../../../../layouts/PanelPage/PanelPage';
import { Simple } from '../../../../layouts/Simple/Simple';
import {
  getCheckoutAction,
  getPricingRuleAction,
  makeBottomlessProductAction,
  newScaleCheckoutAction,
  saveProductSelectionAction,
} from '../../../../store';
import { PricingBanner } from '../../../../components/PricingBanner/PricingBanner';
import { BasicProductInfo, ProductDescription } from './components';
import './AthleticGreensProductDetails.scss';
import { useQueryString } from '@bottomless/common/hooks';
import { CheckoutSource } from '@bottomless/common/constants';

const PanelWrapper = deps => (
  <Panel className="panel-layout-product-details">
    <PanelPage {...deps} />
  </Panel>
);

const AthleticGreensProductDetailsComponent = ({
  product,
  getPricingRule,
  getStarted,
  history,
  isLoading,
  match,
  me,
  makeBottomlessProduct,
  addToast,
  noEmail,
  isGuest,
  productSelection,
  saveProductSelection,
  hideDetails,
  changeScale,
  newScaleCheckout,
  getCheckout,
}) => {
  const {
    params: { back },
  } = useQueryString();
  const [{ checkout, phone }] = useCookies(['checkout', 'phone']);
  const [isModalOpen, setModalOpen] = useState(false);
  const [productData, setProductData] = useState(null);
  const [pricingRule, setPricingRule] = useState(pricingRule);
  const [checkoutData, setCheckoutData] = useState();

  useOnce(() => trackEvent(EVENT_PRODUCT_VIEW, product), [product]);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const outOfStock = useMemo(() => product && product.status === 'archived', [product]);

  const Wrapper = useMemo(() => {
    if (!me) {
      return Simple;
    }

    if (!product) {
      return me ? PanelWrapper : Simple;
    }

    const myVendorId = me.vendor_id?._id || me.vendor_id;
    const productVendorId = product.vendor_id?._id || product.vendor_id;

    return myVendorId && myVendorId !== productVendorId ? props => <Simple {...props} forceNoVendor /> : PanelWrapper;
  }, [me, product]);

  useEffect(() => {
    if (me && isGuest) {
      history.push('/profile');
    }
  }, [me, isGuest, history]);

  useDataEffect(getPricingRule, setPricingRule, match.path);

  useConditionalDataEffect(!!checkout, getCheckout, setCheckoutData, checkout);

  const onChooseProductGuest = async (product, { variant, ...data }) => {
    if (checkout || noEmail) {
      const { payload } = await onSubmit({ product: { product: product._id, variant }, ...data });
      return onSuccess(payload);
    }

    setProductData({ product: { product: product._id, variant }, ...data });
    toggleModal();
  };

  const makeBottomless = useCallback(
    async (id, data) => {
      if (checkout) {
        const { payload: getStartedPayload } = await getStarted({
          product: { product: product?._id, variant: data.variant },
          ...data,
        });

        if (getStartedPayload.source === CheckoutSource.Shopify) {
          return history.push(
            `/get_started/${getStartedPayload.checkoutId}?source=${getStartedPayload.source}&vendorId=${getStartedPayload.vendorId}`
          );
        }

        return history.push(`/get_started/${getStartedPayload.checkoutId}`);
      }

      const result = await makeBottomlessProduct(id, data);

      if (result?.error && result?.payload?.status === 401) {
        await newScaleCheckout();
        const { payload: getStartedPayload } = await getStarted({
          product: { product, variant: data.variant },
          ...data,
        });

        return history.push(`/get_started/${getStartedPayload.checkoutId}`);
      }

      if (result?.payload?.userId && result.payload.userId !== me._id) {
        await changeScale({ account: result.payload.userId });
      }

      return result;
    },
    [makeBottomlessProduct, changeScale, me, newScaleCheckout, history, getStarted, product, checkout]
  );

  const onChooseProductUser = (product, { variant, ...data }) => makeBottomless(product._id, { variant, ...data });

  const onChooseProduct = me ? onChooseProductUser : onChooseProductGuest;

  const onChooseProductSuccess = () => {
    addToast('Product has been successfully saved');
    history.push('/profile');
  };

  const onSubmit = data =>
    getStarted({ phone, ...productData, ...data, guest: Boolean(isGuest), path: isGuest ? match.path : undefined });

  const onSuccess = ({ checkoutId }) => {
    history.push(`/get_started/${checkoutId}`);
  };

  const pathName = me ? '/shop' : '/public_shop';

  const isDifferentVendor = useMemo(
    () => !!me && !me.accounts.find(account => !account.vendor_id || account.vendor_id === product?.vendor_id._id),
    [me, product]
  );

  const computedPricingRule = useMemo(() => {
    if (checkoutData) {
      return checkoutData.pricing_rule;
    }
    if (me && !isDifferentVendor) {
      return me.pricing_rule;
    }
    return pricingRule;
  }, [me, pricingRule, checkoutData, isDifferentVendor]);

  const onBack = useCallback(() => (back ? history.push(back) : history.goBack()), [history, back]);

  const renderContent = () => {
    if (!product) {
      return <DataLoading count={product ? 1 : 0} isLoading={isLoading} />;
    } else if (product && (me || !product.hidden)) {
      return (
        <>
          <div className="page-ag-product-details">
            <BasicProductInfo
              product={product}
              pathname={pathName}
              me={me}
              pricingRule={computedPricingRule}
              onChooseProduct={onChooseProduct}
              onChooseSuccess={onChooseProductSuccess}
              makeBottomlessText="Buy Product"
              outOfStock={outOfStock}
              productSelection={productSelection}
              saveProductSelection={saveProductSelection}
              hideDetails={hideDetails}
              isFirstBag={checkout || !me}
              getCheckout={getCheckout}
            />
            <div className="product-more-info">
              <hr />
              <ProductDescription me={me} product={product} />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <IsLoggedIn>
      <Wrapper
        withFooter={false}
        banner={<PricingBanner pricingRule={computedPricingRule} />}
        back={onBack}
        withBackButton
      >
        <Container className="pt-2 pb-4 page-product-details">{renderContent()}</Container>
        <GetStartedModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          getStarted={onSubmit}
          onSignUp={onSuccess}
          pricingRule={computedPricingRule}
          headingText="Start your subscription by usage"
        />
      </Wrapper>
    </IsLoggedIn>
  );
};

AthleticGreensProductDetailsComponent.propTypes = {
  getStarted: PropTypes.func.isRequired,
  getPricingRule: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  makeBottomlessProduct: PropTypes.func.isRequired,
  me: PropTypes.object,
  product: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string.isRequired,
    }).isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  noEmail: PropTypes.bool,
  isGuest: PropTypes.bool,
  saveProductSelection: PropTypes.func.isRequired,
  productSelection: PropTypes.object,
  hideDetails: PropTypes.bool,
  addAlert: PropTypes.func.isRequired,
  pricingRule: PropTypes.object,
  changeScale: PropTypes.func.isRequired,
  newScaleCheckout: PropTypes.func.isRequired,
  getCheckout: PropTypes.func.isRequired,
};

export const AthleticGreensProductDetails = connect(
  (
    { order, product: { data, isLoading, productSelection }, user: { me, pricingRule } },
    {
      match: {
        params: { slug },
      },
    }
  ) => ({
    product: data.find(product => product.slug === slug || product._id === slug),
    orders: order.data ? order.data : [],
    isLoading,
    me,
    productSelection,
    pricingRule,
  }),
  dispatch => ({
    getPricingRule: path => dispatch(getPricingRuleAction(path)),
    makeBottomlessProduct: (id, data) => dispatch(makeBottomlessProductAction(id, data)),
    addToast: message => dispatch(addToastAction(message)),
    saveProductSelection: data => dispatch(saveProductSelectionAction(data)),
    addAlert: (message, type) => dispatch(addAlertAction(message, type)),
    changeScale: data => dispatch(changeScaleAction(data)),
    newScaleCheckout: () => dispatch(newScaleCheckoutAction()),
    getCheckout: id => dispatch(getCheckoutAction(id)),
  })
)(
  withMetaTags({
    title: ({ product }) => (product ? `Bottomless: ${product.name} - ${product.vendor_name}` : undefined),
    description: ({ product }) =>
      product
        ? `${product.name} from ${product.vendor_name} on Bottomless. Pick an Athletics Green product to start your subscription and re-order at the perfect time, everytime!`
        : undefined,
    image: ({ product }) => (product ? (product.small_image_src || '').replace(/\?.*$/, '') : undefined),
  })(
    withRichProductMeta({
      title: ({ product }) => (product ? product.name : undefined),
      image: ({ product }) => (product ? (product.small_image_src || '').replace(/\?.*$/, '') : undefined),
      vendor: ({ product }) => (product ? product.vendor_name : undefined),
      description: ({ product }) => (product ? stripTags(product.description) : undefined),
      price: ({ product }) =>
        product
          ? Math.min(...product.variants.filter(variant => variant.available).map(availVar => availVar.price))
          : undefined,
      availability: ({ product }) => !!(product ? product.variants.find(variant => variant.available) : undefined),
    })(AthleticGreensProductDetailsComponent)
  )
);
