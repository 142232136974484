import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { AdvanceBuilderHeading } from '../AdvanceBuilderHeading';
import { Roast } from './Roast';

const ROASTS = [{ name: 'light' }, { name: 'medium' }, { name: 'dark' }];

export const Roasts = ({
  disabled,
  onChange,
  products,
  personalized,
  values,
  hideOptionWithoutProducts,
  optionFilter,
}) => (
  <>
    <AdvanceBuilderHeading title="Roast" subtitle="Choose multiple" />
    <Row className="pb-4 pt-2">
      {ROASTS.map(({ name }) => (
        <Roast
          key={name}
          disabled={disabled}
          onChange={onChange}
          name={name}
          products={products}
          personalized={personalized}
          values={values}
          hideOptionWithoutProducts={hideOptionWithoutProducts}
          optionFilter={optionFilter}
        />
      ))}
    </Row>
  </>
);

Roasts.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  personalized: PropTypes.isRequired,
  values: PropTypes.object,
  optionFilter: PropTypes.object,
  hideOptionWithoutProducts: PropTypes.bool,
};
