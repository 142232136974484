import { RSAA } from 'redux-api-middleware';
import { createAction } from '../util';

export const LogoutTypes = createAction('Auth', 'Logout');

export const logoutAction = () => ({
  [RSAA]: {
    endpoint: 'auth/logout',
    method: 'POST',
    body: {},
    types: [LogoutTypes.REQUEST, LogoutTypes.SUCCESS, LogoutTypes.FAILURE],
  },
});

export const ChangeScaleTypes = createAction('Auth', 'Change scale');

export const changeScaleAction = data => ({
  [RSAA]: {
    endpoint: 'auth/change-scale',
    method: 'POST',
    body: data,
    types: [ChangeScaleTypes.REQUEST, ChangeScaleTypes.SUCCESS, ChangeScaleTypes.FAILURE],
  },
});
