import React, { useRef, useState, useCallback, useMemo, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import has from 'lodash-es/has';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { useMediaQuery } from 'react-responsive';
import { Form } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { Grinds } from '../Grinds/Grinds';
import { LowProductCountModal } from '../LowProductCardModal';
import { Product } from '../Product';
import { toPersonalized } from '../../../FilterCheckout/util/get-personalized';
import { Roasts } from '../Roasts/Roasts';
import { Prices } from '../Prices/Prices';
import { Sizes } from '../Sizes/Sizes';
import { Tags } from '../Tags';
import { ProductDetailsModal } from '../../../../components/ProductDetailsModal/ProductDetailsModal';
import { ProductFormActions } from './ProductFormActions';
import { isUserVendorLocked } from '../../../../utils/user';
import { AdvancedOptions } from '../AdvancedOptions';

import './ProductForm.scss';

const SearchSchema = Yup.object().shape({
  roast: Yup.object().required('This field is required'),
  size: Yup.number().required('This field is required'),
  price_type: Yup.string().required('This field is required'),
  grind: Yup.string().required('This field is required'),
  tag: Yup.object().required('This field is required'),
  origin: Yup.object().required('This field is required'),
  vendor: Yup.object(),
  single_origins: Yup.object().required('This field is required'),
  tasting_note_category: Yup.object(),
});

const priceTypes = ['basic', 'standard', 'premium'];
const roast = { dark: false, medium: false, light: false };
const origin = { blend: false, single: false };
const tag = { organic: false, decaf: false, cold_brew: false, espresso: false };

export const ProductForm = ({
  onSubmit,
  onSuccess,
  product,
  personalized,
  origins,
  vendors,
  tastingCategories,
  getDynamicPrices,
  pricingRule,
  getProduct,
  firstProductText,
  advancedOpened,
  addToast,
  me,
  products,
  back,
  hasGeneralPricing,
  vendorId,
}) => {
  const isMobile = useMediaQuery({ maxDeviceWidth: 991 });

  const { getPromise, setPricePromise } = useMemo(() => {
    let pricePromise = null;

    return {
      getPromise: () => pricePromise,
      setPricePromise: promise => (pricePromise = promise),
    };
  }, []);
  const hideOptionWithoutProducts = useMemo(() => isUserVendorLocked(me), [me]);
  const optionFilter = useMemo(() => (isUserVendorLocked(me) ? { vendor: [me.vendor_id._id] } : {}), [me]);

  const [priceLoading, setPriceLoading] = useState(false);
  const [pricingTiers, setPricingTiers] = useState(null);
  const [currentPersonalized, setCurrentPersonalized] = useState(personalized);
  const [vendorRotation] = useState(null);
  const [isSubmit, setSubmit] = useState(false);
  const [firstProduct, setFirstProduct] = useState(null);
  const [numProduct, setNumProducts] = useState(null);
  const [isUpdatingFirstProduct, setisUpdatingFirstProduct] = useState(false);
  const [error, setError] = useState(false);
  const [isModalOpen, toggleModal, setModalOpen] = useToggle(false);
  const [showProductModal, toggleShowProductModal] = useToggle(false);
  const [productContainerHeight, setProductContainerHeight] = useState('130px');

  const myRef = createRef();

  const singleOrigins = getOrigins(origins);
  const categories = getCategories(tastingCategories);
  const allVendors = getVendors(vendors);
  const formRef = useRef();

  useEffect(() => {
    const element = document.getElementById('builder-product-container');
    const height = element?.clientHeight;
    setProductContainerHeight(height);
  }, [currentPersonalized, numProduct]);

  const paddedPersonalized = useMemo(() => {
    const tempPers = personalized;
    if (tempPers?.roast && tempPers.roast.length && tempPers.roast.includes('espresso')) {
      if (tempPers.roast.length === 1) {
        tempPers.roast = Object.keys(roast);
      }
      tempPers.tag.push('espresso');
    }
    if (me && me.vendor_id && tempPers?.vendor) {
      tempPers.vendor.push({ _id: me.vendor_id._id || me.vendor_id });
    }
    return tempPers;
  }, [personalized, me]);

  const getPricingTier = useCallback(
    pricingType => {
      return pricingTiers && Array.isArray(pricingTiers) && pricingTiers.find(({ type }) => type === pricingType);
    },
    [pricingTiers]
  );

  const sizes = useMemo(() => [...new Set(product?.variants.map(variant => Number(variant.size)) || [])], [product]);

  const submit = useCallback(submitForm => () => setTimeout(submitForm), []);

  const clearFilters = useCallback(
    (resetForm, submitForm) => {
      const personalizedDetails = {
        ...(me?.vendor_id && {
          vendor: [{ _id: me.vendor_id._id }],
        }),

        ...(hasGeneralPricing && {
          vendor: [{ _id: vendorId }],
          price_type: 'general',
        }),
      };

      const initialValues = getInitialValues(
        { roast, sizes, priceTypes, tag, origin, singleOrigins, vendor: allVendors, categories },
        personalizedDetails,
        me
      );

      resetForm(initialValues);
      submit(submitForm)();
    },
    [hasGeneralPricing, vendorId, allVendors, categories, me, singleOrigins, sizes, submit]
  );

  const updateCurrentPersonalized = useCallback(values => setCurrentPersonalized(toPersonalized(values)), [
    setCurrentPersonalized,
  ]);

  const sendDynamicPrices = async values => {
    setFirstProduct(null);
    setPriceLoading(true);
    setNumProducts(null);

    const personalized = toPersonalized(values);
    const promise = getDynamicPrices({ personalized, singlePrice: hasGeneralPricing });

    setPricePromise(promise);

    const setResults = (results = null) => {
      setTimeout(async () => {
        if (getPromise() === promise && results !== null) {
          setError(false);
          setPricingTiers(results);
          setPriceLoading(false);
          const pricingTier = results && results.find(({ type }) => type === personalized.price_type);

          const id = pricingTier && pricingTier.firstProduct && pricingTier.firstProduct._id;

          if (id) {
            const { payload: product } = await getProduct(id);
            setFirstProduct(product);
            setNumProducts(pricingTier.productAmount);
          }
        } else if (results === null) {
          setFirstProduct(null);
          setNumProducts(0);
          setError(true);
          addToast('Something went wrong, make another selection!');
        }
      }, 0);
    };

    const data = await promise;

    if (!Array.isArray(data.payload) || [404, 400, 500, 502].includes(data.payload.status)) {
      setResults();
    } else {
      setResults(data.payload);
    }
  };

  const priceDisabled = useCallback(type => !getPricingTier(type), [getPricingTier]);

  useEffect(() => {
    (async () => {
      try {
        const initialValues = getInitialValues(
          {
            roast,
            sizes,
            priceTypes,
            tag,
            origin,
            singleOrigins,
            vendor: allVendors,
            categories,
          },
          paddedPersonalized,
          me
        );
        await sendDynamicPrices(initialValues);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  const updateFirstProduct = useCallback(
    e => {
      (async () => {
        setisUpdatingFirstProduct(true);
        setFirstProduct(null);
        setNumProducts(null);
        const pricingTier = getPricingTier(e.target.value);

        const id = pricingTier && pricingTier.firstProduct && pricingTier.firstProduct._id;

        if (id) {
          const { payload: product } = await getProduct(id);
          setFirstProduct(product);
          setNumProducts(pricingTier.productAmount);
        }
        setisUpdatingFirstProduct(false);
        // eslint-disable-next-line
      })().catch(console.error);
    },
    [getPricingTier, getProduct]
  );

  const fillForEspresso = submitForm => {
    const curForm = formRef.current;
    if (!curForm.state.values['tag']['espresso']) {
      let roastSelected = false;

      for (const r in roast) {
        if (curForm.state.values['roast'][r]) {
          roastSelected = true;
          break;
        }
      }
      if (!roastSelected) {
        for (const r in roast) {
          curForm.setFieldValue(`roast.${r}`, true);
        }
      }
    }
    submit(submitForm)();
  };

  const onCheckboxChange = (setFieldValue, values, submitForm, fieldName) => event => {
    if (event.target.checked) {
      const name = event.target.name.replace(new RegExp(`${fieldName}\\.`), '');
      setFieldValue(fieldName, { ...values[fieldName], [name]: true });
    }

    submit(submitForm)();
  };

  const onOriginChange = (setFieldValue, values, submitForm) => event => {
    if (event.target.name === 'origin.single' && !event.target.checked) {
      setFieldValue('single_origins', singleOrigins);
    }

    onCheckboxChange(setFieldValue, values, 'origin')(event);
    submit(submitForm)();
  };

  const onSelectClick = (submitForm, confiming) => e => {
    e.preventDefault();
    e.stopPropagation();

    if (numProduct !== null && numProduct <= 5 && !confiming) {
      toggleModal();
    } else {
      setSubmit(true);
      setTimeout(submitForm);
    }
  };

  const handleSubmit = data => {
    setSubmit(false);

    const selectedProduct = vendorRotation
      ? {
          product: vendorRotation._id,
          variant: vendorRotation.variants.find(v => v.available && Number(data.size) === Number(v.size))._id,
        }
      : {
          product: product._id,
          variant: product.variants.find(variant => variant.size === Number(data.size))._id,
        };

    const personalized = vendorRotation
      ? undefined
      : {
          ...data,
          roast: getObjectFieldValues(data.roast),
          size: Number(data.size),
          price_type: data.price_type,
          origin: getObjectFieldValues(data.origin),
          single_origins: getObjectFieldValues(data.single_origins),
          tag: getObjectFieldValues(data.tag),
          vendor: getObjectFieldValues(data.vendor),
          tasting_note_category: getObjectFieldValues(data.tasting_note_category),
        };

    const pricingTier = personalized && getPricingTier(personalized.price_type);

    const firstProduct = pricingTier &&
      pricingTier.firstProduct && {
        product: pricingTier.firstProduct._id,
        variant: pricingTier.firstProduct.variants[0],
      };

    return onSubmit({
      personalized,
      product: selectedProduct,
      first_product: firstProduct,
      ...(vendorRotation ? { grind: data.grind } : {}),
    });
  };

  const initialValues = getInitialValues(
    {
      roast,
      sizes,
      priceTypes,
      tag,
      origin,
      singleOrigins,
      vendor: getOrigins(vendors),
      categories: getOrigins(tastingCategories),
    },
    paddedPersonalized,
    me
  );

  return (
    <div className="advance-builder-product-form">
      <Form
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={SearchSchema}
        onSubmit={isSubmit ? handleSubmit : sendDynamicPrices}
        onSuccess={isSubmit ? onSuccess : undefined}
        onChange={updateCurrentPersonalized}
      >
        {({ isSubmitting, setFieldValue, values, submitForm, errors, resetForm }) => (
          <Row className="flex-lg-row-reverse">
            {!isMobile ? (
              <Col lg="4">
                <Product
                  values={values}
                  pricingTier={getPricingTier(values.price_type)}
                  loading={priceLoading}
                  pricingRule={pricingRule}
                  vendors={vendors}
                  firstProduct={isRoast(values.roast) ? product : firstProduct}
                  firstProductText={firstProductText}
                  origins={origins}
                  tastingCategories={!isRoast(values.roast) ? tastingCategories : undefined}
                  numProduct={!isRoast(values.roast) ? numProduct : undefined}
                  isFirstBag={!me}
                  error={error}
                  errors={isRoast(values.roast) ? errors : undefined}
                  onClick={toggleShowProductModal}
                  isSubmitting={isSubmitting}
                  isUpdatingFirstProduct={isUpdatingFirstProduct}
                  getPricingTier={getPricingTier}
                  isRoast={isRoast}
                  vendorRotation={vendorRotation}
                  onSelectClick={onSelectClick}
                  clearFilters={clearFilters}
                  resetForm={resetForm}
                  submitForm={submitForm}
                  title="Your Rotation"
                  back={back}
                  me={me}
                />
              </Col>
            ) : (
              <Product
                values={values}
                pricingTier={getPricingTier(values.price_type)}
                loading={priceLoading}
                pricingRule={pricingRule}
                vendors={vendors}
                firstProduct={isRoast(values.roast) ? product : firstProduct}
                firstProductText={firstProductText}
                origins={origins}
                tastingCategories={!isRoast(values.roast) ? tastingCategories : undefined}
                numProduct={!isRoast(values.roast) ? numProduct : undefined}
                isFirstBag={!me}
                error={error}
                errors={isRoast(values.roast) ? errors : undefined}
                onClick={toggleShowProductModal}
                clearFilters={clearFilters}
                resetForm={resetForm}
                back={back}
              />
            )}
            <Col
              xs="12"
              lg="8"
              className="filter-checkout-form-choices"
              style={{
                paddingTop: isMobile ? (me ? `${productContainerHeight}px` : `${productContainerHeight - 70}px`) : '',
              }}
            >
              <Roasts
                disabled={!!vendorRotation}
                onChange={onCheckboxChange(setFieldValue, values, submitForm, 'roast')}
                products={products}
                hideOptionWithoutProducts={hideOptionWithoutProducts}
                optionFilter={optionFilter}
              />
              <Sizes
                sizes={sizes}
                disabled={!!vendorRotation}
                onChange={submit(submitForm)}
                products={products}
                hideOptionWithoutProducts={hideOptionWithoutProducts}
                optionFilter={optionFilter}
              />
              {!hasGeneralPricing && (
                <Prices
                  disabled={priceDisabled}
                  onChange={updateFirstProduct}
                  products={products}
                  hideOptionWithoutProducts={hideOptionWithoutProducts}
                  optionFilter={optionFilter}
                />
              )}
              <Grinds
                onChange={submit(submitForm)}
                products={products}
                hideOptionWithoutProducts={hideOptionWithoutProducts}
                optionFilter={optionFilter}
              />
              <Tags
                onChange={submit(submitForm)}
                onEspressoChange={() => {
                  fillForEspresso(submitForm);
                  onCheckboxChange(setFieldValue, values, submitForm, 'tag');
                }}
                disabled={!!vendorRotation}
                isVendorLockedUser={me && !!me.vendor_id}
                products={products}
                hideOptionWithoutProducts={hideOptionWithoutProducts}
                optionFilter={optionFilter}
              />
              <AdvancedOptions
                me={me}
                advancedOpened={advancedOpened}
                personalized={personalized}
                products={products}
                currentPersonalized={currentPersonalized}
                hideOptionWithoutProducts={hideOptionWithoutProducts}
                values={values}
                origins={origins}
                vendors={vendors}
                tastingCategories={tastingCategories}
                onOriginChange={onOriginChange}
                setFieldValue={setFieldValue}
                submitForm={submitForm}
                submit={submit}
                pageRef={myRef}
                optionFilter={optionFilter}
              />
            </Col>
            {isMobile && (
              <ProductFormActions
                isSubmitting={isSubmitting}
                isUpdatingFirstProduct={isUpdatingFirstProduct}
                getPricingTier={getPricingTier}
                values={values}
                isRoast={isRoast}
                vendorRotation={vendorRotation}
                onSelectClick={onSelectClick}
                clearFilters={clearFilters}
                resetForm={resetForm}
                submitForm={submitForm}
                back={back}
                me={me}
              />
            )}
            <LowProductCountModal
              numProduct={numProduct}
              toggleModal={toggleModal}
              isModalOpen={isModalOpen}
              confirmed={e => {
                setModalOpen(false);
                return onSelectClick(submitForm, true)(e);
              }}
            />
            {firstProduct && showProductModal && (
              <ProductDetailsModal
                product={firstProduct}
                showModal={showProductModal}
                toggleModal={toggleShowProductModal}
              />
            )}
          </Row>
        )}
      </Form>
    </div>
  );
};

const getOrigins = origins => (origins || []).reduce((all, origin) => ({ ...all, [origin._id]: false }), {});

const getCategories = tastingCategories =>
  (tastingCategories || []).reduce((all, cat) => ({ ...all, [cat._id]: false }), {});

const getVendors = vendors => (vendors || []).reduce((all, vendor) => ({ ...all, [vendor._id]: false }), {});

const getObjectFieldValues = data =>
  Object.entries(data)
    .filter(element => element[1])
    .map(([value]) => value);

const isRoast = roast => Object.keys(roast).every(k => !roast[k]);

const getInitialValues = (
  { roast, sizes, priceTypes, tag, origin, singleOrigins, vendor, categories },
  personalized,
  me
) => {
  const grind = personalized?.grind?.replace(/\s-.*/, '') || me?.grind?.name?.replace(/\s-.*/, '') || 'Whole Beans';

  return {
    roast:
      personalized.roast && personalized.roast.length !== 0
        ? applyPersonalized(roast, 'roast', personalized)
        : { ...roast },
    size: has(personalized, 'size') ? String(personalized.size) : sizes[0],
    price_type: has(personalized, 'price_type') ? personalized.price_type : priceTypes[1],
    grind: grind === 'Whole Bean' ? 'Whole Beans' : grind,
    tag: applyPersonalized(tag, 'tag', personalized),
    origin: applyPersonalized(origin, 'origin', personalized),
    single_origins: applyPersonalized(singleOrigins, 'single_origins', {
      single_origins: (personalized.single_origins || []).map(({ _id }) => _id),
    }),
    tasting_note_category: has(personalized, 'tasting_note_category')
      ? applyPersonalized(categories, 'tasting_note_category', {
          tasting_note_category: (personalized.tasting_note_category || []).map(cat => cat._id),
        })
      : categories,
    vendor: has(personalized, 'vendor')
      ? applyPersonalizedVendor(vendor, {
          vendor: personalized.vendor.map(vendor => vendor._id || vendor).filter(v => v && v !== 'undefined'),
        })
      : vendor,
  };
};

const applyPersonalized = (data, field, personalized) =>
  Object.entries(data).reduce(
    (all, [key, value]) => ({
      ...all,
      [key]: personalized && personalized[field] ? personalized[field].includes(key) : value,
    }),
    {}
  );

const applyPersonalizedVendor = (data, personalized) => {
  const result = applyPersonalized(data, 'vendor', personalized);

  for (const vendor of personalized.vendor || []) {
    result[vendor] = true;
  }

  return result;
};

ProductForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  origins: PropTypes.array.isRequired,
  vendors: PropTypes.array,
  personalized: PropTypes.object,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        size: PropTypes.number.isRequered,
        price: PropTypes.number.isRequered,
      })
    ).isRequired,
  }).isRequired,
  getDynamicPrices: PropTypes.func.isRequired,
  pricingRule: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  getProduct: PropTypes.func.isRequired,
  firstProductText: PropTypes.string.isRequired,
  advancedOpened: PropTypes.bool,
  me: PropTypes.shape({
    pricing_rule: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    vendor_id: PropTypes.string,
  }),
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addToast: PropTypes.func.isRequired,
  tastingCategories: PropTypes.array,
  back: PropTypes.string,
  hasGeneralPricing: PropTypes.bool,
  vendorId: PropTypes.string,
};

ProductForm.defaultProps = {
  vendors: [],
  tasting_note_category: [],
};
