import has from 'lodash-es/has';
import { LogoutTypes, ChangeScaleTypes } from '@bottomless/common/store';
import { SendReviewTypes } from '../review';
import { OverrideDateTypes } from '../user';
import { OverrideSlowLocalDateTypes } from '../slow-local';
import {
  CancelOrderTypes,
  GetFutureOrdersTypes,
  GetInTransitionOrdersTypes,
  GetOrdersTypes,
  GetOrderTypes,
  GetSelectionTypes,
  ProductFeedbackTypes,
  TriggerOrderAction,
  GetBottomlessExpectedArrivalTypes,
  ReportOrderProblemAction,
  GetSlowLocalInTransitionOrdersTypes,
  ModifySlowLocalOrderTypes,
  SetSlowLocalPreferencesTypes,
  GetInTransitionCountTypes,
} from './order.actions';

const initialState = {
  data: [],
  inTransition: [],
  slowLocalInTransition: [],
  future: [],
  isLoading: false,
  selected: null,
  bottomlessExpectedDate: null,
  selections: [],
  count: {},
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetOrdersTypes.REQUEST:
    case GetOrderTypes.REQUEST:
    case TriggerOrderAction.REQUEST:
    case GetSlowLocalInTransitionOrdersTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetSelectionTypes.REQUEST:
      return {
        ...state,
        selectionLoading: true,
      };

    case GetInTransitionOrdersTypes.REQUEST:
      return {
        ...state,
        isInTransitionLoading: true,
      };

    case ProductFeedbackTypes.REQUEST:
    case ProductFeedbackTypes.SUCCESS:
      return {
        ...state,
        data: state.data.map(order =>
          has(order, 'subproduct_id.product._id') && order.subproduct_id.product._id === action.payload.product_id
            ? { ...order, product_feedback: action.payload }
            : order
        ),
      };

    case TriggerOrderAction.SUCCESS:
    case GetOrderTypes.SUCCESS:
      return {
        ...state,
        selected: action.payload,
        isLoading: false,
      };

    case GetBottomlessExpectedArrivalTypes.SUCCESS:
      return {
        ...state,
        bottomlessExpectedDate: action.payload,
        isLoading: false,
      };

    case GetOrdersTypes.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };

    case ModifySlowLocalOrderTypes.SUCCESS:
      return {
        ...state,
        slowLocalInTransition: action.payload.data,
      };

    case GetSelectionTypes.SUCCESS:
      return {
        ...state,
        selections: action.payload,
        selectionLoading: false,
      };

    case GetInTransitionOrdersTypes.SUCCESS:
      return {
        ...state,
        isInTransitionLoading: false,
        inTransition: action.payload.data,
      };

    case GetSlowLocalInTransitionOrdersTypes.SUCCESS:
      return {
        ...state,
        slowLocalInTransition: action.payload.data,
      };

    case OverrideSlowLocalDateTypes.SUCCESS:
      return {
        ...state,
        slowLocalInTransition: action.payload.data,
      };

    case SetSlowLocalPreferencesTypes.SUCCESS: {
      const { order: orderId, product: productId, preference } = action.payload;

      return {
        ...state,
        slowLocalInTransition: state.slowLocalInTransition.map(order =>
          order._id === orderId
            ? {
                ...order,
                products: order.products.map(product =>
                  product.product._id === productId ? { ...product, preference: preference } : product
                ),
              }
            : order
        ),
      };
    }

    case OverrideDateTypes.SUCCESS:
      return {
        ...state,
        ...(state.selected && state.selected._id === action.payload._id ? { selected: action.payload } : {}),
        inTransition: (state.inTransition || []).map(order =>
          order._id === action.payload._id
            ? { ...action.payload, override_fulfillment_date: action.payload.override_fulfillment_date }
            : order
        ),
      };

    case ReportOrderProblemAction.SUCCESS:
      return {
        ...state,
        data: state.data.map(order =>
          order._id === action.payload._id ? { ...order, reportProblem: action.payload.reportProblem } : order
        ),
      };

    case SendReviewTypes.SUCCESS:
      return {
        ...state,
        data: state.data.map(order =>
          order.subproduct_id?.product._id === action.payload.product_id
            ? { ...order, product_feedback: action.payload }
            : order
        ),
      };

    case GetFutureOrdersTypes.SUCCESS:
      return {
        ...state,
        future: action.payload.data,
      };

    case TriggerOrderAction.FAILURE:
    case GetOrdersTypes.FAILURE:
    case GetOrderTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case GetSelectionTypes.FAILURE:
      return {
        ...state,
        selectionLoading: false,
      };

    case GetInTransitionOrdersTypes.FAILURE:
      return {
        ...state,
        isInTransitionLoading: false,
      };

    case CancelOrderTypes.SUCCESS: {
      return {
        ...state,
        inTransition: (state.inTransition || []).filter(({ _id }) => _id !== action.payload._id),
      };
    }

    case GetInTransitionCountTypes.SUCCESS: {
      return {
        ...state,
        count: action.payload,
      };
    }

    case LogoutTypes.SUCCESS:
    case ChangeScaleTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
