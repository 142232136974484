import React, { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col, Container } from 'reactstrap';
import { Heart } from 'react-feather';
import classnames from 'classnames';
import LazyLoad from 'react-lazyload';
import { useToggle } from '@bottomless/common/hooks';
import { Default } from '@bottomless/common/components';
import { Review } from '../../ProductDetails/components/Reviews/Review';
import './ProductDetailsModal.scss';

const MAX_REVIEWS = 3;

const TABS = {
  Description: 'Description',
  Details: 'Details',
  Reviews: 'Reviews',
};

const ProductDetailsModalComponent = ({ product }) => {
  const [activeTab, setActiveTab] = useState(TABS.Description);
  const [open, toggleOpen] = useToggle();

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const likesProportion = useMemo(() => {
    const likes = product.likes || 0;
    const dislikes = product.dislikes || 0;

    if (!likes && !dislikes) {
      return null;
    }

    return likes + dislikes;
  }, [product.likes, product.dislikes]);

  const [firstReviews, restReviews] = useMemo(() => {
    const reviews = product.feedback
      .filter(feedback => feedback.like || feedback.dislike || feedback.content)
      .map(feedback => ({ ...feedback, timestamp: new Date(feedback.timestamp) }))
      .sort((a, b) => {
        if (a.content || b.content) {
          return Number(!!b.content) - Number(!!a.content);
        }

        return b.timestamp - a.timestamp;
      });

    return [reviews.slice(0, MAX_REVIEWS), reviews.slice(MAX_REVIEWS)];
  }, [product]);

  return (
    <div className="first-product-details-modal">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === TABS.Description })}
            onClick={() => {
              toggleTab(TABS.Description);
            }}
          >
            Info
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === TABS.Details })}
            onClick={() => {
              toggleTab(TABS.Details);
            }}
          >
            Details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === TABS.Reviews })}
            onClick={() => {
              toggleTab(TABS.Reviews);
            }}
          >
            Reviews
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={TABS.Description}>
          <Container>
            <Row className="mb-3 mt-3 justify-content-center">
              <Col xs="12" md="6" className="d-flex align-items-center justify-content-md-end justify-content-center">
                <LazyLoad once height={600} offset={600}>
                  <img
                    className="img-fluid product-image"
                    src={product.image_src || product.small_image_src}
                    alt={product.name}
                  />
                </LazyLoad>
              </Col>
              <Col xs="12" md="6" className="d-flex flex-column justify-content-center pt-2">
                <div className="d-flex flex-column align-items-center mb-3">
                  <Link to={`/public_shop?vendor_id=${product.vendor_id._id}`} className="text-dark text-uppercase">
                    {product.vendor_name}
                  </Link>
                  <h2 className="text-center">{product.name}</h2>
                  {product.likes > 0 && (
                    <span className="text-nowrap">
                      <Heart size="20" fill="#ec2d2d" color="#ec2d2d" className="mr-1" /> {product.likes}
                      {likesProportion !== null && (
                        <span className="ml-1" style={{ color: '#adb5bd' }}>
                          of {likesProportion} reviews
                        </span>
                      )}
                    </span>
                  )}
                </div>
                <div className="product-parameters d-flex align-items-center mt-3 mb-3 w-100">
                  <div className="parameter d-flex flex-column align-items-center">
                    <div className="parameter-label">Origin</div>
                    <div className="parameter-value text-center">{product.origin?.name}</div>
                  </div>
                  <div className="parameter d-flex flex-column align-items-center">
                    <div className="parameter-label">Roast</div>
                    <div className="parameter-value text-center">{product.roast?.name}</div>
                  </div>
                  <div className="parameter d-flex flex-column align-items-center">
                    <div className="parameter-label">Process</div>
                    <div className="parameter-value text-center">
                      <Default>{product.process?.name}</Default>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="product-description mb-4 text-center">
              <div dangerouslySetInnerHTML={{ __html: product.description }} />
            </Row>
          </Container>
        </TabPane>
        <TabPane tabId={TABS.Details}>
          <div className="px-4 py-5">
            <Row className="justify-content-center">
              {product.tasting_notes.length > 0 && (
                <Col xs="12" md={product.altitude_max || product.altitude_min ? '4' : '6'} className="text-center mt-3">
                  <h5>Tasting Notes</h5>
                  {product.tasting_notes.map(note => (
                    <div key={note._id} className="mb-1">
                      {note.name}
                    </div>
                  ))}
                </Col>
              )}
              {product.tags.length > 0 && (
                <Col xs="12" md={product.altitude_max || product.altitude_min ? '4' : '6'} className="text-center mt-3">
                  <h5>Tags</h5>
                  {product.tags.map(tag => (
                    <div key={tag._id} className="mb-1">
                      {tag.name}
                    </div>
                  ))}
                </Col>
              )}
              {(product.altitude_max || product.altitude_min) && (
                <Col xs="12" md="4" className="text-center mt-3">
                  <h5>Altitude</h5>
                  {product.altitude_min === product.altitude_max && (
                    <div className="mb-1">{product.altitude_min} masl</div>
                  )}
                  {product.altitude_min !== product.altitude_max && (
                    <>
                      {product.altitude_min && <div className="mb-1">Min: {product.altitude_min} masl</div>}
                      {product.altitude_max && <div className="mb-1">Max: {product.altitude_max} masl</div>}
                    </>
                  )}
                </Col>
              )}
            </Row>
          </div>
        </TabPane>
        <TabPane tabId={TABS.Reviews}>
          {!firstReviews?.length ? (
            <div className="text-secondary w-100 mt-2 text-center">No reviews yet, you can be first!</div>
          ) : (
            <div className="d-flex flex-column">
              <div className="d-flex flex-wrap">
                {firstReviews.map(feedback => (
                  <Review key={feedback._id} feedback={feedback} />
                ))}
                {open && restReviews.map(feedback => <Review key={feedback._id} feedback={feedback} />)}
              </div>
              {restReviews.length > 0 && (
                <Button color="" type="button" onClick={toggleOpen}>
                  {open ? 'Show less' : `Show ${restReviews.length} more`}
                </Button>
              )}
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

ProductDetailsModalComponent.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    vendor_id: PropTypes.object,
    description: PropTypes.string,
    likes: PropTypes.number,
    dislikes: PropTypes.number,
    image_src: PropTypes.string,
    small_image_src: PropTypes.string,
    origin: PropTypes.shape({
      name: PropTypes.string,
    }),
    roast: PropTypes.shape({
      name: PropTypes.string,
    }),
    process: PropTypes.shape({
      name: PropTypes.string,
    }),
    tasting_notes: PropTypes.array,
    tags: PropTypes.array,
    altitude_min: PropTypes.number,
    altitude_max: PropTypes.number,
    feedback: PropTypes.array,
  }),
  isMobileDevice: PropTypes.bool,
};

export const ProductDetailsModal = memo(ProductDetailsModalComponent);
