import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const IsAdminAuthComponent = ({ children, me, history, location }) => {
  if (!me) {
    history.push(`/login?path=${location.pathname}`);
    return null;
  }

  return children;
};

IsAdminAuthComponent.propTypes = {
  children: PropTypes.node.isRequired,
  me: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const IsAdminAuth = connect(({ user: { me } }) => ({ me }))(IsAdminAuthComponent);
