import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ArrowLeft, Search, ShoppingCart } from 'react-feather';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { Container, Badge } from 'reactstrap';
import { Alerts } from '../../components/Alerts/Alerts';
import { PricingBanner } from '../../components/PricingBanner/PricingBanner';
import { Banner } from '../../components/Banner/Banner';
import { usePreventTouchMove } from '../../hooks/preventTouchMove.hook';
import { ScrollToTop } from '../../components/ScrollToTop/ScrollToTop';
import './PanelPage.scss';

export const PanelPage = ({
  children,
  title,
  className,
  heading,
  footer,
  tabs,
  pricingRule,
  withBanner,
  withGenericBanner,
  bannerText,
  back,
  numProducts,
  cartLink,
  searchLink,
  withScroll,
  shouldCenterAlignTitle,
  hideOverflow,
  stretch,
}) => {
  const ref = usePreventTouchMove();
  const Wrapper = useMemo(() => (withScroll ? ScrollToTop : Fragment), [withScroll]);
  const backProps = useMemo(() => {
    if (!back) {
      return undefined;
    }

    if (back === '/pets') {
      return { onClick: () => (window.location.href = back) };
    }

    if (typeof back === 'string') {
      return { to: back };
    }

    return {
      onClick: e => {
        e.preventDefault();
        back();
      },
    };
  }, [back]);

  return (
    <Wrapper>
      <div
        className={classNames('panel-wrapper', className, {
          'with-banner': withBanner || withGenericBanner,
          'with-back-button': !!back,
          'hide-overflow': hideOverflow,
        })}
      >
        <div className="header-wrapper">
          <div
            className={`container d-flex align-items-center header-bar ${
              shouldCenterAlignTitle ? 'justify-content-center' : 'justify-content-between'
            }`}
            ref={ref}
          >
            {(title || tabs || back) && (
              <div className="d-flex align-items-center position-relative flex-grow-1">
                {(title || back) && (
                  <div className="panel-title d-flex align-items-center">
                    {back && (
                      <Link
                        {...backProps}
                        className="d-flex align-items-center justify-content-center heading-back mr-2"
                      >
                        <ArrowLeft size="22" color="rgb(35, 35, 35)" strokeWidth="3" aria-label="Back" role="img" />
                      </Link>
                    )}
                    {title && <h2 className="mb-2 mt-2 mr-1 text-nowrap panel-title">{title}</h2>}
                  </div>
                )}
                {tabs &&
                  tabs.map(({ to, name }) => (
                    <NavLink to={to} key={to} exact className="ml-2 btn btn-sm" activeClassName="btn-outline-secondary">
                      {name}
                    </NavLink>
                  ))}
              </div>
            )}
            {heading}
            <div className="d-flex">
              {searchLink && (
                <NavLink to={searchLink} key={searchLink} exact className="d-flex align-items-center">
                  <Search size="26" />
                </NavLink>
              )}
              {cartLink && (numProducts || numProducts === 0) && (
                <span className="grocery-cart ml-2">
                  <NavLink to={cartLink} key={cartLink} exact>
                    <ShoppingCart className="mr-md-2" size="20" color="white" />
                    <Badge color="light">{numProducts}</Badge>
                  </NavLink>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={classNames('panel-content', { 'panel-content--stretch': stretch })} id="panel-content">
          {withBanner && <PricingBanner pricingRule={pricingRule} />}
          {withGenericBanner && <Banner bannerText={bannerText} />}
          <Container className="pt-4 pb-5">
            <Alerts />
            <div>{children}</div>
          </Container>
        </div>
        {footer}
      </div>
    </Wrapper>
  );
};

PanelPage.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tabs: PropTypes.array,
  pricingRule: PropTypes.object,
  withBanner: PropTypes.bool,
  bannerText: PropTypes.string,
  withGenericBanner: PropTypes.bool,
  back: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  numProducts: PropTypes.number,
  cartLink: PropTypes.string,
  withScroll: PropTypes.bool,
  searchLink: PropTypes.string,
  shouldCenterAlignTitle: PropTypes.bool,
  hideOverflow: PropTypes.bool,
  stretch: PropTypes.bool,
};

PanelPage.defaultProps = {
  shouldCenterAlignTitle: false,
  hideOverflow: false,
  stretch: false,
};
