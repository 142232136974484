import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addToastAction, ChangeScaleTypes } from '@bottomless/common/store';
import { ValidationError } from '@bottomless/common/src/store/errors';
import useTotal from './useTotal';
import { EVENT_APPLE_PAY, EVENT_CHECKOUT_COMPLETE, trackEvent } from '../../utils/tracker';
import {
  finishCheckoutAction,
  getMeAction,
  getStartedAction,
  getStartedCompleteAction,
  setCheckoutAddressAction,
  setShipmentAction,
  setStripeAction,
} from '../../store';
import { useHistory } from 'react-router-dom';
import { getNextBusinessDay, getNextBusinessDayOrToday } from '../../utils/dates';

export default function usePaymentRequest(
  stripe,
  pricingRule,
  product,
  firstProduct,
  variant,
  firstVariant,
  pricing,
  personalized,
  grind,
  checkout,
  dateOverride
) {
  const total = useTotal(pricingRule, product, variant, checkout, pricing);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const dispatch = useDispatch();
  const manifest = useSelector(({ manifest }) => manifest);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const existingPaymentRequest = useRef();

  const getStartedParams = useRef({
    product: { product: product?._id, variant: variant?._id },
    personalized,
    ...(firstProduct ? { first_product: { product: firstProduct._id, variant: firstVariant._id } } : {}),
    ...(grind ? { grind } : {}),
  });

  useEffect(() => {
    getStartedParams.current = {
      product: { product: product?._id, variant: variant?._id },
      personalized,
      ...(firstProduct ? { first_product: { product: firstProduct._id, variant: firstVariant._id } } : {}),
      ...(grind ? { grind } : {}),
    };
  }, [product, variant, firstProduct, firstVariant, personalized, grind]);

  const paymentRequest = useMemo(() => {
    if (existingPaymentRequest.current && total) {
      existingPaymentRequest.current.update({
        total: { label: 'Total', amount: total.total },
        displayItems: [
          { label: 'Cost', amount: total.product },
          { label: 'Shipping', amount: total.shipping },
          { label: 'Signup fee', amount: total.signup },
        ],
      });

      return existingPaymentRequest.current;
    }

    return (existingPaymentRequest.current =
      stripe && total
        ? stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: { label: 'Total', amount: total.total },
            displayItems: [
              { label: 'Cost', amount: total.product },
              { label: 'Shipping', amount: total.shipping },
              { label: 'Signup fee', amount: total.signup },
            ],
            requestPayerName: true,
            requestPayerEmail: true,
            requestShipping: true,
          })
        : null);
  }, [stripe, total, existingPaymentRequest]);

  useEffect(() => {
    if (!paymentRequest) return;

    paymentRequest
      .canMakePayment()
      .then(result => {
        setCanMakePayment(!!result);
        setLoading(false);
      })
      .catch(() => {
        setCanMakePayment(false);
        setLoading(false);
      });

    paymentRequest.on('token', async ({ complete, token, shippingAddress, payerName, payerEmail }) => {
      const [firstName, lastName] = payerName.match(/^(\S+)\s(.*)/).slice(1);
      setErrors([]);

      try {
        const {
          payload: { checkoutId },
        } = await dispatch(
          getStartedAction({
            email: payerEmail,
            ...getStartedParams.current,
          })
        );

        if (dateOverride === '0') {
          await dispatch(setShipmentAction(checkoutId, { shipment_date: undefined, dateOverride }));
        }

        if (dateOverride && dateOverride !== '0') {
          const nextBusinessDayOrToday = getNextBusinessDayOrToday();

          const shipmentDate =
            dateOverride === 'nextBusiness'
              ? getNextBusinessDay()
              : new Date(nextBusinessDayOrToday.setDate(nextBusinessDayOrToday.getDate() + 7 * Number(dateOverride)));

          await dispatch(
            setShipmentAction(checkoutId, { shipment_date: moment(shipmentDate).format('YYYY-MM-DD'), dateOverride })
          );
        }

        await dispatch(
          setCheckoutAddressAction(checkoutId, {
            first_name: firstName,
            last_name: lastName,
            verifiedAddress: {
              street1: shippingAddress.addressLine[0],
              city: shippingAddress.city,
              zip: shippingAddress.postalCode,
              state: shippingAddress.region,
              street2: shippingAddress.addressLine[1],
            },
          })
        );

        const { payload: checkout } = await dispatch(setStripeAction(checkoutId, { token: token.id }));

        await dispatch(finishCheckoutAction(checkout._id));

        const { payload } = await dispatch(getStartedCompleteAction());

        if (!payload.primary) {
          dispatch({ type: ChangeScaleTypes.SUCCESS });
          await dispatch(getMeAction());
        }

        trackEvent(EVENT_CHECKOUT_COMPLETE, { checkout }, manifest);
        trackEvent(EVENT_APPLE_PAY, { id: checkout._id });

        complete('success');

        history.push(payload.primary ? `/get_started_complete?checkout=${checkout._id}` : '/profile');
      } catch (err) {
        complete('fail');

        if (err instanceof ValidationError) {
          setErrors(Object.values(err.details));
        }

        dispatch(addToastAction('There was an error with the payment information', 'error'));
      }
    });

    paymentRequest.on('shippingaddresschange', ev => {
      if (ev.shippingAddress.country !== 'US') ev.updateWith({ status: 'invalid_shipping_address' });
      else {
        ev.updateWith({
          status: 'success',
          shippingOptions: pricingRule.free_shipping
            ? [{ id: 'free-shipping', label: 'Free Shipping', amount: 0 }]
            : [
                {
                  id: 'simple-shipping',
                  label: `$${pricingRule.shipping_price / 100} shipping`,
                  amount: pricingRule.shipping_price,
                },
              ],
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequest, setErrors, getStartedParams]);

  return [paymentRequest, canMakePayment, loading, errors];
}
