import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Info, CheckCircle, Circle } from 'react-feather';
import { Button, Card } from 'reactstrap';
import { ProductName, Image } from '@bottomless/common/components';

const FirstProductComponent = ({ product, onClick, onInfoClick, personalized, grind, active }) => {
  const handleInfoClick = useCallback(
    e => {
      if (!onInfoClick) {
        return undefined;
      }

      e.stopPropagation();
      return onInfoClick(product);
    },
    [onInfoClick, product]
  );

  return (
    <Card
      key={product._id}
      body
      outline
      color={active ? 'primary' : undefined}
      className="rotation-product first-product-details cursor-pointer"
      onClick={onClick}
    >
      <div className="d-flex p-2 justify-content-between">
        <div className="d-flex align-items-center">
          {active ? (
            <CheckCircle size="18" className="ml-1" color="#0632c2" role="img" aria-label="Checked" />
          ) : (
            <Circle size="18" className="ml-1" color="#00000020" role="img" aria-label="Not checked" />
          )}
          <Image src={product.small_image_src} width="55" alt={product.name} className="mr-4" />
          <div>
            <div className="text-secondary text-uppercase text-sm vendor-name">{product.vendor_name}</div>
            <ProductName product={product} personalized={personalized} grind={grind} />
          </div>
        </div>
        <div className="d-flex align-items-center text-secondary info-icon">
          {onInfoClick ? (
            <Button color="link" onClick={handleInfoClick} aria-label="Read more">
              <Info size="16" role="img" aria-label="More info" />
            </Button>
          ) : (
            undefined
          )}
        </div>
      </div>
    </Card>
  );
};

FirstProductComponent.propTypes = {
  rotationPrice: PropTypes.number,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    small_image_src: PropTypes.string.isRequired,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
      })
    ).isRequired,
  }),
  pricingRule: PropTypes.object,
  isFirstBag: PropTypes.bool,
  personalized: PropTypes.object,
  grind: PropTypes.string,
  dynamicPricing: PropTypes.shape({
    price: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }),
  onInfoClick: PropTypes.func,
  active: PropTypes.bool,
};

export const FirstProduct = memo(FirstProductComponent);
