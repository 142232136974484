import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as qs from 'query-string';
import { saveLandingPageAction, saveLastLandingRuleAction } from './store/auth';
import { useEffect } from 'react';
import { pageView } from './utils/tracker';

const ReferralCookieComponent = ({ me, children, location, saveLandingPage, saveLastLandingRule, variant }) => {
  const [{ landing_page: landingPage, utm_parameters: utmParameters }, setCookie] = useCookies([
    'landing_page',
    'utm_parameters',
  ]);

  useEffect(() => {
    pageView(location);

    const query = qs.parse(location.search);
    const { utm_campaign, utm_content, utm_medium, utm_source } = query || {};
    const domain = process.env.REACT_APP_COOKIE_DOMAIN;

    const dateIn3Months = new Date();
    dateIn3Months.setMonth(dateIn3Months.getMonth() + 3);

    if (!utmParameters) {
      const utmParams = {};
      if (utm_campaign) utmParams.campaign = utm_campaign;
      if (utm_content) utmParams.content = utm_content;
      if (utm_medium) utmParams.medium = utm_medium;
      if (utm_source) utmParams.source = utm_source;

      const hasUtmProperties = Object.keys(utmParams) && Object.keys(utmParams).length > 0;

      if (hasUtmProperties) {
        setCookie('utm_parameters', utmParams, { path: '/', domain, expires: dateIn3Months });
      }
    }
  }, [location, setCookie, utmParameters]);

  if (!me && !landingPage) {
    saveLandingPage({ token: `${location.pathname}${location.search}` });
  }

  useEffect(() => {
    if (!me) {
      saveLastLandingRule(variant);
    }
  }, [me, saveLastLandingRule, variant]);

  return children;
};

ReferralCookieComponent.propTypes = {
  me: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  saveLandingPage: PropTypes.func.isRequired,
  saveLastLandingRule: PropTypes.func.isRequired,
  variant: PropTypes.number,
};

export const ReferralCookie = withRouter(
  connect(
    ({ user: { me } }) => ({ me }),
    dispatch => ({
      saveLandingPage: data => dispatch(saveLandingPageAction(data)),
      saveLastLandingRule: variant => dispatch(saveLastLandingRuleAction(variant)),
    })
  )(ReferralCookieComponent)
);
