import { Image } from '@bottomless/common/components';
import React, { useCallback, useState } from 'react';
import { useCustomWording } from '../../hooks/use-custom-wording';

export const ScalePin = () => {
  const { cw } = useCustomWording();
  const [isOpen, setOpen] = useState(false);

  const onClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(true);
  }, []);

  return (
    <div className="mt-3">
      <div className="text-center">
        <a href="#" onClick={onClick}>
          Can&apos;t find the pin?
        </a>
      </div>
      {isOpen && (
        <div className="mt-3">
          <div className="mb-3 text-center">{cw('Look for the sticker on the bottom of your re-ordering scale:')}</div>
          <Image src={cw('/connect-scale/scale-pin-location.png')} alt="Scale PIN location" width="325" useSrcset />
        </div>
      )}
    </div>
  );
};
