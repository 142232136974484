import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'react-feather';
import { UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import { v4 } from 'uuid';
import { Form } from '@bottomless/common/components';

const SubscriptionSchema = Yup.object().shape({
  personalized_rotation: Yup.bool(),
});

export const SubscriptionSwitch = ({ product, onSubmit, onSuccess, mobile }) => {
  const switchId = useMemo(() => `subscription-switch${v4(mobile && '-mobile')}`, [mobile]);
  const form = useRef();
  const tooltipRef = useRef();

  useEffect(() => {
    form.current.setFieldValue('personalized_rotation', product.personalized_rotation);
  }, [product, form]);

  const onChange = useCallback(
    ({ setFieldValue, submitForm }) => () => {
      setFieldValue('personalized_rotation', !form.current.state.values.personalized_rotation);
      setTimeout(submitForm, 0);
    },
    [form]
  );

  const handleSubmit = useCallback(
    async (...data) => {
      try {
        const result = await onSubmit(...data);

        if (result.error || result === false) {
          form.current.setFieldValue('personalized_rotation', !form.current.state.values.personalized_rotation);
        }
      } catch (e) {
        form.current.setFieldValue('personalized_rotation', !form.current.state.values.personalized_rotation);
        throw e;
      }
    },
    [onSubmit, form]
  );

  return (
    <div className="text-center subscription-switch">
      <Form
        innerRef={form}
        initialValues={{ personalized_rotation: product.personalized_rotation }}
        validationSchema={SubscriptionSchema}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
      >
        {({ isSubmitting, values, setFieldValue, submitForm }) => {
          if (isSubmitting) {
            return (
              <>
                Saving... <Loader size="13" className="spin ml-2" />
              </>
            );
          }

          return (
            <div className="text-sm text-secondary d-flex justify-content-center align-items-center">
              <div
                className={classNames('d-flex align-items-center', {
                  'font-weight-bolder': values.personalized_rotation,
                })}
              >
                <span>Personalized</span>
                <>
                  <span ref={tooltipRef} className="information-badge ml-1 font-weight-normal">
                    i
                  </span>
                  <UncontrolledTooltip placement="top" target={tooltipRef}>
                    Tell us what your preferences are and we will choose a coffee for you
                  </UncontrolledTooltip>
                </>
              </div>
              <div className="custom-control custom-switch ml-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={switchId}
                  value={!values.personalized_rotation}
                  checked={!values.personalized_rotation}
                  onChange={onChange({ setFieldValue, submitForm })}
                />
                <label className="custom-control-label" htmlFor={switchId} />
              </div>
              <div className={classNames({ 'font-weight-bolder': !values.personalized_rotation })}>Default</div>
            </div>
          );
        }}
      </Form>
    </div>
  );
};

SubscriptionSwitch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  product: PropTypes.shape({
    personalized_rotation: PropTypes.bool,
  }).isRequired,
  mobile: PropTypes.bool,
};
