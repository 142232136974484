import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Field, SubmitButton } from '@bottomless/common/components';
import { ExternalSignup } from './ExternalSignUp';
import { EVENT_CHECKOUT_CREATED, trackEvent } from '../../utils/tracker';

const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid e-mail address')
    .required('This field is required'),
});

export const SignUpForm = ({
  getStarted,
  onSignUp,
  getMe,
  onAuth,
  onAuthSuccess,
  postAuthSuccess,
  withExternal,
  withGoogle,
  googleRedirect,
  buttonText = 'Get started',
  buttonColor,
}) => {
  const handleGetStarted = useCallback(
    (...data) => {
      trackEvent(EVENT_CHECKOUT_CREATED);
      return getStarted(...data);
    },
    [getStarted]
  );

  return (
    <>
      {withExternal && (
        <div className="mb-4">
          <ExternalSignup
            onSignUp={onSignUp}
            getStarted={handleGetStarted}
            getMe={getMe}
            onAuth={onAuth}
            onAuthSuccess={onAuthSuccess}
            postAuthSuccess={postAuthSuccess}
          />
        </div>
      )}
      <Form
        initialValues={{ email: '' }}
        validationSchema={SignUpSchema}
        onSubmit={handleGetStarted}
        onSuccess={onSignUp}
      >
        {({ isSubmitting }) => (
          <>
            <Field name="email" type="email" label="E-mail" />
            <SubmitButton color={buttonColor || 'primary'} isSubmitting={isSubmitting} loadingText="Signing up" block>
              {buttonText} <i className="fa fa-long-arrow-right ml-1" />
            </SubmitButton>
          </>
        )}
      </Form>
      {withGoogle && (
        <Link to={googleRedirect} className="btn btn-outline-dark btn-no-transform btn-block mt-3 mb-2">
          <img src="/images/google.png" alt="G" className="mr-1" height="14" /> Log in with Google
        </Link>
      )}
    </>
  );
};

SignUpForm.propTypes = {
  getStarted: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
  getMe: PropTypes.func,
  onAuth: PropTypes.func,
  onAuthSuccess: PropTypes.func,
  postAuthSuccess: PropTypes.func,
  withExternal: PropTypes.bool,
  withGoogle: PropTypes.bool,
  googleRedirect: PropTypes.string,
  buttonText: PropTypes.string,
  isMobile: PropTypes.bool,
  buttonColor: PropTypes.string,
};

SignUpForm.defaultProps = {
  withExternal: false,
  googleRedirect: '/auth/google',
  isMobile: false,
};
