import React from 'react';
import PropTypes from 'prop-types';
import { DateOverrideText } from '../../../FilterCheckout/components/DateOverrideText';

export const CoffeeCheckoutShipTime = ({ dateOverride, excludeDates }) => {
  return (
    <DateOverrideText
      dateOverride={dateOverride}
      excludeDates={excludeDates}
      timerText="Time to complete for tomorrow roast and ship"
      shipText="Roasts and Ships"
      showTimer
    />
  );
};

CoffeeCheckoutShipTime.propTypes = {
  dateOverride: PropTypes.string.isRequired,
  excludeDates: PropTypes.array,
};
