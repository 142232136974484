import React from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'react-feather';
import { Link } from 'react-router-dom';
import { Price } from '@bottomless/common/src/components/Price';
import { PersonalizedProduct } from './PersonalizedProduct';
import { ProductAvatar } from './ProductAvatar';
import { SubscriptionSwitch } from './SubscriptionSwitch';

export const MyProduct = ({
  product,
  me,
  price,
  size,
  productPricingRule,
  wishlist,
  onSubscriptionSwitch,
  onSubscriptionSwitchSuccess,
  variant,
}) => (
  <div className="my-product">
    <div className="d-flex align-items-center justify-content-between px-2">
      <div className="text-sm mr-3 my-product-icon">
        <span aria-label="Redo" role="img">
          🔁
        </span>
      </div>
      <div className="mr-3 my-product-number">{wishlist.length + 1}</div>
      <div className="d-flex justify-content-between align-items-center my-product-product">
        <div className="d-flex my-product-personalized">
          {product.personalized_rotation && (
            <PersonalizedProduct
              index={wishlist.length + 1}
              personalized={me.personalized}
              grind={me.grind.name}
              size={size}
            />
          )}
          {!product.personalized_rotation && (
            <ProductAvatar
              name={product.name}
              vendorName={product.vendor_name}
              vendorId={product.vendor_id}
              productId={product._id}
              image={product.small_image_src}
              index={wishlist.length + 1}
              grind={me.grind.name}
              size={size}
              variant={variant}
            />
          )}
          <div className="d-flex align-items-center my-product-secondary">
            <SubscriptionSwitch
              product={product}
              onSubmit={onSubscriptionSwitch}
              onSuccess={onSubscriptionSwitchSuccess}
            />
            <Price value={price} pricingRule={productPricingRule} />
          </div>
        </div>
        <div className="text-right d-flex align-items-center">
          <Link
            to={product.personalized_rotation && !me.vendor_id ? '/custom_product' : '/shop'}
            className="ml-4 btn btn-transparent text-info"
          >
            <Edit size="16" />
          </Link>
        </div>
      </div>
    </div>
    <div className="d-none align-items-center my-product-secondary-mobile">
      <SubscriptionSwitch
        product={product}
        onSubmit={onSubscriptionSwitch}
        onSuccess={onSubscriptionSwitchSuccess}
        mobile
      />
      <div>
        <Price value={price} pricingRule={productPricingRule} />
      </div>
    </div>
  </div>
);

MyProduct.propTypes = {
  price: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  productPricingRule: PropTypes.object,
  wishlist: PropTypes.array.isRequired,
  me: PropTypes.shape({
    personalized: PropTypes.object,
    vendor_id: PropTypes.string,
    grind: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ]),
  }).isRequired,
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    vendor_name: PropTypes.string.isRequired,
    vendor_id: PropTypes.string.isRequired,
    small_image_src: PropTypes.string.isRequired,
    personalized_rotation: PropTypes.bool,
  }),
  onSubscriptionSwitch: PropTypes.func.isRequired,
  onSubscriptionSwitchSuccess: PropTypes.func.isRequired,
  variant: PropTypes.object,
};
