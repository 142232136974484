import { VENDOR_IDS } from '../../../constants/vendor';

export const customWording = {
  [VENDOR_IDS.AG1]: {
    '/connect-scale/scale-pin-location.png': '/connect-scale/ag1/scale-pin-location.png',
    'Look for the sticker on the bottom of your re-ordering scale:':
      'Look for the sticker on the bottom of your smart attachment:',
    "You'll find the 4-character scale pin at the bottom of your re-ordering scale:":
      "You'll find the 4-character scale pin at the bottom of your smart attachment:",
  },
};
