import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const capitalize = phrase => {
  if (!phrase) return null;
  return phrase.toLowerCase().replace(/\b\w/g, c => c.toUpperCase());
};

const QuizHeaderComponent = ({ index, total, quizAnswers }) => {
  const history = useHistory();

  const formatAnswer = question => {
    if (!question || !quizAnswers) {
      return '';
    }

    // eslint-disable-next-line
    return Object.keys(quizAnswers).map(answer => {
      const [key, value] = quizAnswers[answer]?.split(':') || [];

      if (question === key) {
        if (key === 'size') return `${value} oz`;

        if (key === 'price_type' || key === 'grind') {
          const [val] = value?.split(';') || [];

          return capitalize(val);
        }
        return capitalize(value);
      }
    });
  };

  const onClickProgress = index => {
    if (index === 1) {
      history.push('/filter_checkout');
    } else {
      history.push(`/filter_checkout?question=${index}`);
    }
  };

  return (
    <div className="d-flex justify-content-between align-items-center quiz-header">
      <div
        className={`question-progress-step ${index === 1 ? 'active' : ''} ${index > 1 ? 'past cursor-pointer' : ''}`}
      >
        <div className="number font-weight-bold" onClick={() => onClickProgress(1)}>
          01. Roast
        </div>
        <div className="progress-bar mb-2"></div>
        <p className="chosen-answer font-weight-bold text-dark" onClick={() => onClickProgress(1)}>
          {formatAnswer('roast')}
        </p>
      </div>
      <div
        className={`question-progress-step ${index === 2 ? 'active' : ''} ${index < 2 ? 'inactive' : ''} ${
          index > 2 ? 'past' : ''
        }`}
      >
        <div className="number font-weight-bold" onClick={index < 2 ? undefined : () => onClickProgress(2)}>
          02. Grind
        </div>
        <div className="progress-bar mb-2"></div>
        <p
          className="chosen-answer font-weight-bold text-dark"
          onClick={index < 2 ? undefined : () => onClickProgress(2)}
        >
          {formatAnswer('grind')}
        </p>
      </div>
      <div
        className={`question-progress-step ${index === 3 ? 'active' : ''} ${index < 3 ? 'inactive' : ''} ${
          index > 3 ? 'past' : ''
        }`}
      >
        <div className="number font-weight-bold" onClick={index < 3 ? undefined : () => onClickProgress(4)}>
          03. Size
        </div>
        <div className="progress-bar mb-2"></div>
        <p
          className="chosen-answer font-weight-bold text-dark"
          onClick={index < 3 ? undefined : () => onClickProgress(4)}
        >
          {formatAnswer('size')}
        </p>
      </div>
      <div
        className={`question-progress-step ${index === 4 ? 'active' : ''} ${index < 4 ? 'inactive' : ''} ${
          index > 4 ? 'past' : ''
        }`}
      >
        <div className="number font-weight-bold" onClick={index < 4 ? undefined : () => onClickProgress(5)}>
          04. Price
        </div>
        <div className="progress-bar mb-2"></div>
        <p
          className="chosen-answer font-weight-bold text-dark"
          onClick={index < 4 ? undefined : () => onClickProgress(5)}
        >
          {formatAnswer('price_type')}
        </p>
      </div>
      {total > 4 && (
        <div
          className={`question-progress-step ${index === 5 ? 'active' : ''} ${index < 5 ? 'inactive' : ''} ${
            index > 5 ? 'past' : ''
          }`}
        >
          <div className="number font-weight-bold" onClick={index < 5 ? undefined : () => onClickProgress(6)}>
            05. Stock
          </div>
          <div className="progress-bar mb-2"></div>
        </div>
      )}
    </div>
  );
};

QuizHeaderComponent.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  quizAnswers: PropTypes.object,
};

export const QuizHeader = memo(QuizHeaderComponent);
