export const fromOz = ({ oz, unit: variantUnit }) => {
  let formatted;
  if (variantUnit) {
    formatted = `${oz} ${variantUnit}`;
    return { oz, unit: variantUnit, formatted };
  }

  const isLb = oz >= 16;
  const value = isLb ? oz / 16 : oz;
  const unit = isLb ? 'lb' : 'oz';

  if (isLb) {
    const lbValue = Number((oz % 4 === 0 ? oz / 16 : Math.floor(oz / 16)).toPrecision(5));
    const ozValue = Number((oz % 4 === 0 ? 0 : oz % 16).toPrecision(5));

    const lbStr = Number.isInteger(lbValue)
      ? lbValue
      : lbValue.toFixed(2)[lbValue.toFixed(2).length - 1] === '0'
      ? lbValue.toFixed(1)
      : lbValue.toFixed(2);
    const ozStr = Number.isInteger(ozValue)
      ? ozValue
      : ozValue.toFixed(2)[ozValue.toFixed(2).length - 1] === '0'
      ? ozValue.toFixed(1)
      : ozValue.toFixed(2);

    const lbSection = `${lbStr} lb`;
    const ozSection = ozValue ? ` ${ozStr} oz` : '';

    formatted = `${lbSection}${ozSection}`;
  } else {
    formatted = `${value} ${unit}`;
  }

  return { oz, value, unit, formatted };
};

export const convertOzToLbAndOz = ounces => {
  const pounds = Math.floor(ounces / 16);

  const remainingOunces = ounces % 16;

  const result = [];

  if (pounds > 0) {
    result.push(pounds + ' lb');
  }

  if (remainingOunces > 0) {
    result.push(remainingOunces + ' oz');
  }

  return result.join(' ');
};
