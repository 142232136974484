import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { usePersonalizedAttributes } from '../../../hooks';
import { InfoTooltip } from '../../InfoTooltip';

export const ProductName = ({ user }) => {
  const isRotation = useMemo(() => !!user.product.product.personalized_rotation, [user]);
  const { roast, attributes } = usePersonalizedAttributes(user.personalized);

  if (!isRotation) {
    return (
      <div className="small product-name">
        <div className="text-uppercase text-secondary product-name-vendor">{user.product.product.vendor_name}</div>
        <strong>{user.product.product.name}</strong>
      </div>
    );
  }

  return (
    <div className="small d-flex align-items-center">
      <strong>{roast}</strong>
      {attributes.length > 0 && (
        <InfoTooltip size="sm" className="ml-1">
          {attributes.map(({ label, value }) => (
            <div className="small" key={label}>
              {label}: {value}
            </div>
          ))}
        </InfoTooltip>
      )}
    </div>
  );
};

ProductName.propTypes = {
  user: PropTypes.shape({
    personalized: PropTypes.object,
    product: PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
        vendor_name: PropTypes.string.isRequired,
        personalized_rotation: PropTypes.bool,
      }).isRequired,
      variant: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
