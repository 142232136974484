import React from 'react';
import { SlugCategoryManifest } from '../../../components/CategoryManifest';
import { CoffeeProduct, PetFoodProduct, GenericProduct } from '../manifest';

const COMPONENTS = {
  CoffeeShopProduct: CoffeeProduct,
  PetFoodShopProduct: PetFoodProduct,
  GenericShopProduct: GenericProduct,
};

export const Product = props => (
  <SlugCategoryManifest
    manifestKey="shopProduct"
    components={COMPONENTS}
    noLoading
    onlySubscribe
    fallbackComponent={GenericProduct}
    {...props}
  />
);
