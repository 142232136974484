import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

export const LowProductCountModal = ({ numProduct, toggleModal, isModalOpen, confirmed }) => {
  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} className="shop-modal get-started-modal">
      <ModalHeader toggle={toggleModal}>Warning</ModalHeader>
      <ModalBody>
        The custom rotation you have built only contains {numProduct} {numProduct === 1 ? 'product!' : 'products!'}
        <div style={{ textAlign: 'center' }}>
          <Button className="mt-3 btn-dark" onClick={confirmed}>
            I am fine with this
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

LowProductCountModal.propTypes = {
  numProduct: PropTypes.number,
  toggleModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  confirmed: PropTypes.func.isRequired,
};
