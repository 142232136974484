import React from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Collapse } from 'reactstrap';
import { useToggle } from '@bottomless/common/hooks';
import { Roasters } from './Roasters';
import { Origin, SingleOrigins } from './Origin';
import { TastingCategories } from './TastingCategories';

const getDefaultAdvanceOpened = personalized =>
  Boolean(
    personalized &&
      ((personalized.grind && personalized.grind !== 'Whole Beans') ||
        personalized.tag.length ||
        personalized.origin.length ||
        personalized.single_origins.length ||
        (personalized.vendor && personalized.vendor.length) ||
        (personalized.tasting_note_category && personalized.tasting_note_category.length))
  );

export const AdvancedOptions = ({
  me,
  advancedOpened,
  personalized,
  products,
  currentPersonalized,
  hideOptionWithoutProducts,
  values,
  origins,
  vendors,
  tastingCategories,
  onOriginChange,
  setFieldValue,
  submitForm,
  submit,
  pageRef,
  optionFilter,
}) => {
  const [areAdvancedOptionsOpen, toggleAdvancedOptions] = useToggle(
    advancedOpened || getDefaultAdvanceOpened(personalized)
  );

  const onAdvancedClick = e => {
    e.preventDefault();
    toggleAdvancedOptions();

    if (pageRef.current) {
      pageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  return (
    <div>
      <div className="mt-2 mb-5 pb-5 pb-md-0 text-center advanced-options-link">
        <a color="link" href="#" onClick={onAdvancedClick} className="text-primary cursor-pointer">
          ADVANCED OPTIONS{' '}
          {areAdvancedOptionsOpen ? (
            <ChevronUp size="16" role="img" aria-label="Chevron up" />
          ) : (
            <ChevronDown size="16" role="img" aria-label="Chevron down" />
          )}
        </a>
      </div>

      <Collapse isOpen={areAdvancedOptionsOpen} className="pt-3">
        <Origin
          onChange={onOriginChange(setFieldValue, values, submitForm)}
          products={products}
          hideOptionWithoutProducts={hideOptionWithoutProducts}
          optionFilter={optionFilter}
          personalized={currentPersonalized}
        />
        <div className={values.origin.single ? 'd-block' : 'd-none'}>
          <SingleOrigins
            onChange={submit(submitForm)}
            allOrigins={origins}
            products={products}
            hideOptionWithoutProducts={hideOptionWithoutProducts}
            optionFilter={optionFilter}
            personalized={currentPersonalized}
          />
        </div>
        {!me || (me && !me.vendor_id) ? (
          <Roasters
            onChange={submit(submitForm)}
            vendors={vendors}
            products={products}
            personalized={currentPersonalized}
          />
        ) : (
          ''
        )}
        <TastingCategories
          onChange={submit(submitForm)}
          categories={tastingCategories}
          products={products}
          hideOptionWithoutProducts={hideOptionWithoutProducts}
          optionFilter={optionFilter}
          personalized={currentPersonalized}
        />
      </Collapse>
    </div>
  );
};

AdvancedOptions.propTypes = {
  me: PropTypes.shape({
    vendor_id: PropTypes.string,
  }),
  advancedOpened: PropTypes.bool,
  personalized: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  currentPersonalized: PropTypes.object.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  values: PropTypes.object,
  origins: PropTypes.array,
  vendors: PropTypes.array,
  tastingCategories: PropTypes.array,
  onOriginChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  pageRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  optionFilter: PropTypes.object,
};
