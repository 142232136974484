import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import { DataLoading } from '@bottomless/common/components';
import { useScript } from '@bottomless/common/hooks';
import { GoogleLoaderContext, GoogleLoaderProvider } from '../../context/google-loader.context';

export const GoogleMapsLoader = ({ children }) => {
  const [loaded, error] = useScript(
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyD-WmVRn4haurYhiY1O400BbnmhN02NjfU&libraries=places'
  );

  if (error) {
    Sentry.captureException(error);
  }

  return <GoogleLoaderProvider value={{ loaded, error }}>{children}</GoogleLoaderProvider>;
};

GoogleMapsLoader.propTypes = {
  children: PropTypes.node.isRequired,
};

export const GoogleMapsLoaded = ({ children, loader: Loader, lazy }) => {
  const { loaded, error } = useContext(GoogleLoaderContext);

  if (lazy || (loaded && !error)) {
    return <Fragment key={loaded}>{children}</Fragment>;
  }

  if (Loader) {
    return <Loader />;
  }

  return <DataLoading count={0} isLoading={true} loadingText="Loading google maps" />;
};

GoogleMapsLoaded.propTypes = {
  children: PropTypes.node.isRequired,
  loader: PropTypes.elementType,
  lazy: PropTypes.elementType,
};
