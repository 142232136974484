import React, { useCallback } from 'react';
import { Edit } from 'react-feather';
import { Button } from 'reactstrap';

export const AthleticGreensChangeProduct = () => {
  const onChange = useCallback(() => (window.location = '/athletic-greens'), []);

  return (
    <Button color="link" size="sm" onClick={onChange} className="placeholder-text">
      <Edit width="18" height="18" />
    </Button>
  );
};
