import qs from 'query-string';
import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const CheckoutProductTypes = createAction('Checkout', 'Select product');

export const checkoutProductAction = (id, data) => ({
  [RSAA]: {
    endpoint: `checkouts/${id}/product`,
    method: 'POST',
    body: data,
    types: [CheckoutProductTypes.REQUEST, CheckoutProductTypes.SUCCESS, CheckoutProductTypes.FAILURE],
  },
});

export const CheckoutHowYouHeardAbouUsTypes = createAction('Checkout', 'How You Heard About Us');

export const checkoutHowYouHeardAboutUsAction = (id, data) => ({
  [RSAA]: {
    endpoint: `checkouts/heard_about_us/${id}`,
    method: 'POST',
    body: data,
    types: [
      CheckoutHowYouHeardAbouUsTypes.REQUEST,
      CheckoutHowYouHeardAbouUsTypes.SUCCESS,
      CheckoutHowYouHeardAbouUsTypes.FAILURE,
    ],
  },
});

export const GetCheckoutTypes = createAction('Checkout', 'Get');

export const getCheckoutAction = id => ({
  [RSAA]: {
    endpoint: `checkouts/${id}`,
    method: 'GET',
    types: [GetCheckoutTypes.REQUEST, GetCheckoutTypes.SUCCESS, GetCheckoutTypes.FAILURE],
  },
});

export const SetCheckoutAddressTypes = createAction('Checkout', 'Set address');

export const setCheckoutAddressAction = (id, data, params) => ({
  [RSAA]: {
    endpoint: `checkouts/${id}/address${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'POST',
    body: data,
    types: [SetCheckoutAddressTypes.REQUEST, SetCheckoutAddressTypes.SUCCESS, SetCheckoutAddressTypes.FAILURE],
  },
});

export const CalculateCheckoutShippingTypes = createAction('Checkout', 'Calculate shipping');

export const calculateCheckoutShippingAction = (id, data) => ({
  [RSAA]: {
    endpoint: `checkouts/${id}/calculate-shipping`,
    method: 'POST',
    body: data,
    types: [
      CalculateCheckoutShippingTypes.REQUEST,
      CalculateCheckoutShippingTypes.SUCCESS,
      CalculateCheckoutShippingTypes.FAILURE,
    ],
  },
});

export const SetEmailTypes = createAction('Checkout', 'Set email');

export const setEmailAction = (id, data) => ({
  [RSAA]: {
    endpoint: `checkouts/${id}/email`,
    method: 'POST',
    body: {
      slug: window.location.pathname,
      query: window.location.search,
      ...data,
    },
    types: [SetEmailTypes.REQUEST, SetEmailTypes.SUCCESS, SetEmailTypes.FAILURE],
  },
});

export const FinishCheckoutTypes = createAction('Checkout', 'Finish');

export const finishCheckoutAction = (id, data) => ({
  [RSAA]: {
    endpoint: `checkouts/finish`,
    method: 'POST',
    body: { checkout_id: id, ...data },
    types: [FinishCheckoutTypes.REQUEST, FinishCheckoutTypes.SUCCESS, FinishCheckoutTypes.FAILURE],
  },
});

export const SetShipmentTypes = createAction('Checkout', 'Set shipment');

export const setShipmentAction = (id, data) => ({
  [RSAA]: {
    endpoint: `checkouts/${id}/shipment`,
    method: 'POST',
    body: data,
    types: [SetShipmentTypes.REQUEST, SetShipmentTypes.SUCCESS, SetShipmentTypes.FAILURE],
  },
});

export const SetPersonalizedTypes = createAction('Checkout', 'Set personalized');

export const setPersonalizedAction = (id, data) => ({
  [RSAA]: {
    endpoint: `checkouts/${id}/personalized`,
    method: 'POST',
    body: data,
    types: [SetPersonalizedTypes.REQUEST, SetPersonalizedTypes.SUCCESS, SetPersonalizedTypes.FAILURE],
  },
});

export const SetStripeTypes = createAction('Checkout', 'Set stripe');

export const setStripeAction = (id, data) => ({
  [RSAA]: {
    endpoint: `checkouts/${id}/stripe`,
    method: 'POST',
    body: data,
    types: [SetStripeTypes.REQUEST, SetStripeTypes.SUCCESS, SetStripeTypes.FAILURE],
  },
});

export const NewScaleCheckoutTypes = createAction('Checkout', 'New scale');

export const newScaleCheckoutAction = data => ({
  [RSAA]: {
    endpoint: 'checkouts/new-scale',
    method: 'POST',
    body: data,
    types: [NewScaleCheckoutTypes.REQUEST, NewScaleCheckoutTypes.SUCCESS, NewScaleCheckoutTypes.FAILURE],
  },
});

export const AllInOneCheckoutType = createAction('Checkout', 'All in one');

export const allInOneCheckoutAction = (id, data) => ({
  [RSAA]: {
    endpoint: `checkouts/${id}/all-in-one`,
    method: 'POST',
    body: data,
    types: [AllInOneCheckoutType.REQUEST, AllInOneCheckoutType.SUCCESS, AllInOneCheckoutType.FAILURE],
  },
});

export const GetCheckoutDetailsTypes = createAction('Checkout', 'Get checkout details');

export const getCheckoutDetailsAction = id => ({
  [RSAA]: {
    endpoint: `checkouts/${id}/details`,
    method: 'GET',
    types: [GetCheckoutDetailsTypes.REQUEST, GetCheckoutDetailsTypes.SUCCESS, GetCheckoutDetailsTypes.FAILURE],
  },
});

export const GetCheckoutShippingPriceTypes = createAction('Checkout', 'Get checkout shipping price');

export const getCheckoutShippingPriceAction = id => ({
  [RSAA]: {
    endpoint: `checkouts/${id}/shipping-price`,
    method: 'GET',
    types: [
      GetCheckoutShippingPriceTypes.REQUEST,
      GetCheckoutShippingPriceTypes.SUCCESS,
      GetCheckoutShippingPriceTypes.FAILURE,
    ],
  },
});

export const GetCheckoutAfterDataTypes = createAction('Checkout', 'Get checkout after data');

export const getCheckoutAfterDataAction = id => ({
  [RSAA]: {
    endpoint: `checkouts/${id}/after-data`,
    method: 'GET',
    types: [GetCheckoutAfterDataTypes.REQUEST, GetCheckoutAfterDataTypes.SUCCESS, GetCheckoutAfterDataTypes.FAILURE],
  },
});

export const GetShopFiltersAction = createAction('Checkout', 'Get shop filters');

export const getShopFiltersAction = data => ({
  [RSAA]: {
    endpoint: 'checkouts/shop-filters',
    method: 'POST',
    body: { personalized: data },
    types: [GetShopFiltersAction.REQUEST, GetShopFiltersAction.SUCCESS, GetShopFiltersAction.FAILURE],
  },
});
