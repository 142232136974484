import React, { createContext, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getUserVendorAction, clearUserVendorAction } from '../store';

export const VendorLockContext = createContext({ vendor: null });

export const VendorLockProvider = ({ children, getVendor = getUserVendorAction }) => {
  const previousVendorId = useRef();

  const dispatch = useDispatch();

  const me = useSelector(({ user }) => user.me);
  const vendor = useSelector(({ user }) => user.vendor);
  const isAuthenticated = useSelector(({ auth }) => auth.isAuth);

  useEffect(() => {
    if (!me || !isAuthenticated) {
      previousVendorId.current = undefined;
      dispatch(clearUserVendorAction());
      return;
    }

    if (!me.vendor_id) {
      previousVendorId.current = undefined;
      dispatch(clearUserVendorAction());
      return;
    }

    if (previousVendorId.current !== me.vendor_id) {
      previousVendorId.current = me.vendor_id;
      const vendorId = typeof me.vendor_id === 'string' ? me.vendor_id : me.vendor_id._id;
      dispatch(getVendor(vendorId));
    }
  }, [me, dispatch, vendor, getVendor, isAuthenticated]);

  return <VendorLockContext.Provider value={vendor}>{children}</VendorLockContext.Provider>;
};

VendorLockProvider.propTypes = {
  children: PropTypes.node,
  getVendor: PropTypes.func,
};
