import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import classNames from 'classnames';
import { ChangeListener } from './ChangeListener';
import { useState } from 'react';

export const Form = ({
  children,
  initialValues,
  validationSchema,
  onSubmit,
  onSuccess,
  inline,
  innerRef,
  validateOnChange,
  validateOnBlur,
  isInitialValid,
  onChange,
  className,
}) => {
  const [generalError, setGeneralError] = useState();

  return (
    <Formik
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      ref={innerRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      isInitialValid={isInitialValid}
      onSubmit={async (values, actions) => {
        try {
          setGeneralError(null);
          const res = await onSubmit(values);
          actions.setSubmitting(false);
          if (onSuccess) {
            onSuccess(res?.payload, actions);
          }
        } catch (e) {
          if (Object.keys(e.details || {}).length) {
            actions.setErrors(e.details);
          } else {
            setGeneralError(e.message);
          }

          actions.setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting, ...props }) => (
        <form onSubmit={handleSubmit} noValidate className={classNames(className, { 'form-inline': inline })}>
          {/* eslint-disable-next-line react/prop-types */}
          {onChange && <ChangeListener onChange={onChange} values={props.values} />}
          {children({ isSubmitting, ...props })}
          {generalError && <div className="mt-2 small text-danger">{generalError}</div>}
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  initialValues: PropTypes.object,
  validationSchema: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  inline: PropTypes.bool,
  innerRef: PropTypes.object,
  validateOnChange: PropTypes.bool,
  validateOnBlur: PropTypes.bool,
  isInitialValid: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Form.defaultProps = {
  isInitialValid: false,
};
