import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Container } from 'reactstrap';
import classNames from 'classnames';
import { Image } from '@bottomless/common/components';
import { QuizHeader } from './QuizHeader';
import { QuestionText } from './QuestionText';

const answers = [
  {
    name: 'Light',
    value: 'roast:light',
    explanation: 'Bright, Fruity, Acidic',
    icon: '/assets/quiz_1_light.png?w=50',
    event: {
      roast: 'light',
    },
  },
  {
    name: 'Medium',
    value: 'roast:medium',
    explanation: 'Balanced',
    icon: '/assets/quiz_2_medium.png',
    event: {
      roast: 'Medium',
    },
  },
  {
    name: 'Dark',
    value: 'roast:dark',
    explanation: 'Chocolatey, Nutty',
    icon: '/assets/quiz_1_dark.png',
    event: {
      roast: 'dark',
    },
  },
  {
    name: 'Espresso',
    value: 'roast:espresso',
    explanation: 'Made for espresso machines',
    icon: '/assets/quiz_1_espresso.png',
    event: {
      roast: 'espresso',
    },
  },
];

export const Question1 = ({ index, total, onAnswer, isMobile }) => {
  return (
    <>
      <QuizHeader index={index} total={total - 1} />
      <QuestionText
        index={index}
        total={total - 1}
        title="Roast Level"
        subtitle="What roast level do you typically enjoy?"
      />
      <Container className="mt-5">
        <Row>
          {answers.map((answer, i) => (
            <Col
              key={i}
              xs="6"
              sm="6"
              lg="3"
              className={!isMobile && (i === 0 ? 'pl-0' : i === answers.length - 1 ? 'pr-0' : '')}
            >
              <Card
                body
                tag="button"
                className="d-flex flex-md-row flex-column align-items-center justify-content-center w-100 answer"
                onClick={() => onAnswer(answer)}
              >
                <Image src={answer.icon} width="50" alt={`Answer - ${answer.name}`} />
                <p
                  className={classNames('mb-0', {
                    'ml-4': !isMobile,
                    'mt-2': isMobile,
                  })}
                >
                  {answer.name}
                </p>
              </Card>
              <p className="text-center font-italic mt-2 text-secondary">{answer.explanation}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

Question1.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onAnswer: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
