import { GetVendorsTypes, GetVendorTypes, GetWhitelistVendorsTypes, GetVendorByIdTypes } from './vendor.actions';

const initialState = {
  data: [],
  shopify: [],
  isLoading: false,
};

export const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetVendorTypes.REQUEST:
    case GetVendorsTypes.REQUEST:
    case GetVendorByIdTypes.REQUEST:
    case GetWhitelistVendorsTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetVendorsTypes.SUCCESS:
    case GetWhitelistVendorsTypes.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case GetVendorTypes.SUCCESS: {
      const index = state.data.findIndex(v => v._id === action.payload._id);

      return {
        data:
          index !== -1
            ? [...state.data.slice(0, index), action.payload, ...state.data.slice(index + 1)]
            : [...state.data, action.payload],
        isLoading: false,
      };
    }
    case GetVendorTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
