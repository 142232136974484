import { Field as FormikField } from 'formik';
import get from 'lodash-es/get';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';

export const DateDropdown = props => <FormikField {...props} component={FieldComponent} />;

DateDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  min: PropTypes.instanceOf(Date),
  excludeDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  excludeWeekends: PropTypes.bool,
  opened: PropTypes.bool,
};

DateDropdown.defaultProps = {
  excludeWeekends: false,
};

const isWeekday = date => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

const CalendarIcon = ({ onClick }) => <i className="fa fa-calendar" onClick={onClick} />;
CalendarIcon.propTypes = { onClick: PropTypes.func };

const FieldComponent = ({
  field,
  form,
  onChange,
  min,
  max,
  excludeWeekends,
  excludeDates,
  opened,
  onCalendarClose,
  popperPlacement,
  resetOpenState,
  excludeDay,
  iconComponent: IconComponent = CalendarIcon,
  ...props
}) => {
  const ref = useRef();
  const invalid = get(form.errors, field.name) && get(form.touched, field.name);

  useEffect(() => {
    if (opened) {
      if (ref.current) {
        return ref.current.setOpen(true);
      }
      if (resetOpenState) {
        return resetOpenState();
      }
    } else if (ref.current) {
      return ref.current.setOpen(false);
    }
  }, [opened, ref, resetOpenState]);

  const onClick = e => {
    e.preventDefault();
    ref.current?.setOpen(true);
  };

  const handleChange = (value, e) => {
    e.preventDefault();
    form.setFieldValue(field.name, value);

    if (onChange) {
      onChange(value);
    }
  };

  const filterDate = date => {
    const day = date.getDay();

    if (excludeWeekends && excludeDay) {
      return isWeekday(date) && day !== excludeDay;
    }

    if (excludeWeekends) {
      return isWeekday(date);
    }

    if (excludeDay) {
      return day !== excludeDay;
    }

    return undefined;
  };

  return (
    <FormGroup onClick={e => e.preventDefault()}>
      <Label className="regular triangle-right">
        <IconComponent onClick={onClick} />
        <DatePicker
          ref={ref}
          minDate={min}
          maxDate={max}
          excludeDates={excludeDates}
          onChange={handleChange}
          filterDate={filterDate}
          popperPlacement={popperPlacement || 'top-end'}
          selected={field.value}
          onCalendarClose={onCalendarClose}
          customInput={
            <DateInput
              {...field}
              {...props}
              invalid={invalid}
              type="text"
              date={field.value}
              className="d-none"
              autocomplete="off"
            />
          }
        />
      </Label>
      <FormFeedback className="text-left">{get(form.errors, field.name)}</FormFeedback>
    </FormGroup>
  );
};

FieldComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  options: PropTypes.object,
  onChange: PropTypes.func,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  excludeWeekends: PropTypes.bool,
  excludeDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  opened: PropTypes.bool,
  resetOpenState: PropTypes.func,
  onCalendarClose: PropTypes.func,
  popperPlacement: PropTypes.string,
  excludeDay: PropTypes.number,
  iconComponent: PropTypes.elementType,
};

FieldComponent.defaultProps = {
  opened: false,
  resetOpenState: () => {},
};

const DateInput = forwardRef(({ date, ...props }, ref) => (
  <Input innerRef={ref} {...props} value={date ? moment(date).format('MM/DD/YYYY') : ''} />
));

DateInput.displayName = 'DateInput';

DateInput.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
};
