import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const excludedMessages = [/less\sthan\s[\d]+h\sago/, /Cannot\sread\sproperty\s.*\sof/];

const errorsMap = {
  'Missing stripe error': 'No payment method on file.',
};

export const ChargeFailedReason = ({ reason }) => {
  const shouldUseGeenericMessage = useMemo(() => {
    for (const regex of excludedMessages) {
      if (reason.match(regex)) {
        return true;
      }
    }

    return false;
  }, [reason]);

  if (shouldUseGeenericMessage) {
    return 'The payment could not be processed.';
  }

  if (reason.match()) return <>{errorsMap[reason] || reason}</>;
};

ChargeFailedReason.propTypes = {
  reason: PropTypes.string.isRequired,
};
