import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Weight } from '../Weight';

export const VariantAttributes = ({ productVariant, grind, hideSize }) => {
  const product = useMemo(() => productVariant?.product, [productVariant]);
  const variant = useMemo(() => product?.variants.find(variant => variant._id === productVariant.variant), [
    productVariant,
    product,
  ]);

  if (variant?.options?.length) {
    return (
      <>
        {variant.options
          .filter(row => row.name !== 'Title')
          .map(row => row.value)
          .filter(row => !!row)
          .join(', ')}
      </>
    );
  }

  if (!hideSize && variant?.size) {
    return (
      <>
        <Weight value={variant.size} unit={variant.unit} />
        {grind?.name && `, ${grind.name}`}
      </>
    );
  }

  if (grind?.name) {
    return <>{grind.name}</>;
  }

  return <></>;
};

VariantAttributes.propTypes = {
  productVariant: PropTypes.shape({
    product: PropTypes.shape({
      variants: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string.isRequired })).isRequired,
    }).isRequired,
    variant: PropTypes.string.isRequired,
  }).isRequired,
  grind: PropTypes.shape({
    name: PropTypes.string,
  }),
  hideSize: PropTypes.bool,
};
