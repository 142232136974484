import PropTypes from "prop-types";
import React from "react";
import { Tooltip as TooltipStrap } from "reactstrap";
import { useToggle } from "../../hooks/use-toggle";

export const Tooltip = ({ id, content, placement, children }) => {
  const [isOpen, toggle] = useToggle();

  return (
    <div>
      <div id={"Tooltip-" + id}>{children}</div>
      <TooltipStrap
        placement={placement}
        isOpen={isOpen}
        target={"Tooltip-" + id}
        toggle={toggle}
      >
        {content}
      </TooltipStrap>
    </div>
  );
};

Tooltip.defaultProps = {
  placement: "top"
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  placement: PropTypes.string,
  children: PropTypes.node.isRequired
};
