import { GetMeTypes } from '../user';

const initialState = {
  isAuth: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetMeTypes.SUCCESS:
    case GetMeTypes.FAILURE:
      return {
        ...state,
        isAuth: true,
      };

    default:
      return state;
  }
};
