export const AddAlertType = '[Alert] Add';
export const RemoveAlertType = '[Alert] Remove';

export const addAlertAction = (message, type = 'info') => ({
  type: AddAlertType,
  payload: { message, type, isOpen: true },
});

export const removeAlertAction = id => ({
  type: RemoveAlertType,
  payload: { id },
});
