import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Flag from 'react-country-flag';
import { Col } from 'reactstrap';
import { CheckboxBox } from '@bottomless/common/src/components';
import { useProductsCount } from '../../hooks/use-products-count.hook';
import { BuildCard } from '../BuildCard';

export const SingleOriginComponent = ({
  onChange,
  origin,
  products,
  hideOptionWithoutProducts,
  optionFilter,
  personalized,
}) => {
  const originPersonalizedForOptionFilter = useMemo(
    () => ({ ...optionFilter, single_origins: [origin._id], origin: ['single'] }),
    [optionFilter, origin._id]
  );

  const originPersonalized = useMemo(() => ({ ...personalized, single_origins: [origin._id], origin: ['single'] }), [
    origin._id,
    personalized,
  ]);

  const productCount = useProductsCount({
    products,
    personalized: originPersonalizedForOptionFilter,
    optional: true,
  });

  const originCount = useProductsCount({
    products,
    personalized: originPersonalized,
  });

  if (hideOptionWithoutProducts && !productCount) {
    return <></>;
  }

  return (
    <Col xs="4" md="3" lg="3" className="answer-card-container">
      <div className="h-100" style={{ color: originCount === 0 ? 'grey' : 'black' }}>
        <CheckboxBox
          name={`single_origins.${origin._id}`}
          className="answer-card"
          label={
            <BuildCard
              emoji={<Flag countryCode={origin.code} className="origin-flag" />}
              name={`${origin.name.toLowerCase()} (${originCount || originCount === 0 ? originCount : '-'})`}
            />
          }
          onChange={onChange}
        />
      </div>
    </Col>
  );
};

SingleOriginComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  origin: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string,
  }),
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  optionFilter: PropTypes.object,
  personalized: PropTypes.object,
};

export const SingleOrigin = React.memo(SingleOriginComponent);
