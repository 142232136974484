import { Field as FormikField } from 'formik';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { FormFeedback, Input, Label } from 'reactstrap';
import { useMemo } from 'react';
import { v4 } from 'uuid';

export const Switch = props => <FormikField {...props} component={SwitchComponent} />;

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const SwitchComponent = ({ field, form, label, ...props }) => {
  const id = useMemo(() => `switch-${v4()}`, []);
  const invalid = get(form.errors, field.name) && get(form.touched, field.name);
  const value = get(form.values, field.name);
  const isBooleanCheckbox = Boolean(value) === value;

  const checked = isBooleanCheckbox
    ? value
    : field.value === value ||
      (field.value === false && value === 'false') ||
      (field.value === true && value === 'true');

  const onChange = useCallback(
    event => {
      const val = isBooleanCheckbox ? event.target.checked : event.target.checked ? field.value : undefined;
      form.setFieldValue(field.name, val);
      if (props.onChange) {
        props.onChange(event);
      }
    },
    [isBooleanCheckbox, field, form, props]
  );

  return (
    <div className="custom-control custom-switch mb-3">
      <Input
        {...field}
        {...props}
        className="custom-control-input"
        id={id}
        type="checkbox"
        invalid={invalid}
        onChange={onChange}
        checked={checked}
      />
      <Label for={id} className={classNames('custom-control-label', { 'is-invalid': invalid })}>
        {label}
      </Label>
      <FormFeedback className="text-left">{get(form.errors, field.name)}</FormFeedback>
    </div>
  );
};

SwitchComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  value: PropTypes.any,
};
