import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@bottomless/common/components';
import classNames from 'classnames';
import './ScaleConnection.scss';

const DefaultScaleImageComponent = () => (
  <Image src="/connect-scale/bottomless-scale-u.png" alt="Bottomless Scale" width="157" useSrcset />
);

export const ScaleConnection = ({ lines, className, ScaleImageComponent = DefaultScaleImageComponent }) => (
  <div className={classNames('text-center', className)}>
    <svg
      width="71"
      height="70"
      viewBox="0 0 71 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="image-scale-connection"
    >
      <path
        d="M4.86719 26.5334C13.33 19.0737 24.2238 14.9578 35.5051 14.9578C46.7864 14.9578 57.6802 19.0737 66.143 26.5334"
        stroke={lines[3] ? '#28A745' : '#DEE2E6'}
        strokeWidth="5.79167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2305 36.8135C20.9543 32.046 28.1679 29.4353 35.6171 29.4353C43.0664 29.4353 50.28 32.046 56.0038 36.8135"
        stroke={lines[2] ? '#28A745' : '#DEE2E6'}
        strokeWidth="5.79167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.4531 47.1224C28.393 45.0337 31.9099 43.9116 35.5161 43.9116C39.1224 43.9116 42.6393 45.0337 45.5792 47.1224"
        stroke={lines[1] ? '#28A745' : '#DEE2E6'}
        strokeWidth="5.79167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5 58.3875H35.529"
        stroke={lines[0] ? '#28A745' : '#DEE2E6'}
        strokeWidth="5.79167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <div>
      <ScaleImageComponent />
    </div>
  </div>
);

ScaleConnection.propTypes = {
  lines: PropTypes.arrayOf(PropTypes.bool),
  className: PropTypes.string,
  ScaleImageComponent: PropTypes.elementType,
};
