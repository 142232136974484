import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useConditionalDataEffect } from '@bottomless/common/hooks';
import { DataHandler } from '@bottomless/common/components';
import { useDispatch, useSelector } from 'react-redux';
import { ManifestContextProvider } from '../../context/manifest.context';
import { getCategoryAction } from '../../store';

const SlugCategoryManifestComponent = ({
  manifestKey = '',
  components = {},
  noLoading,
  onlySubscribe,
  slug,
  fallbackComponent,
  ...props
}) => {
  const dispatch = useDispatch();
  const getCategory = useCallback(slug => dispatch(getCategoryAction(slug)), [dispatch]);

  const { category, isLoading } = useSelector(({ category }) => ({
    category: category.data.find(c => c.slug === slug),
    isLoading: category.isLoading,
  }));

  const manifest = useMemo(() => category?.manifest, [category]);
  const Component = useMemo(
    () => components[(manifest || { components: {} }).components[manifestKey]] || fallbackComponent,
    [manifest, manifestKey, components, fallbackComponent]
  );

  const { error } = useConditionalDataEffect(
    !onlySubscribe && !manifest && !isLoading,
    getCategory,
    undefined,
    slug,
    null
  );

  if (!manifest && noLoading) {
    return null;
  }

  if (!manifest) {
    return <DataHandler data={category} isLoading={isLoading} error={error} />;
  }

  if (!Component) {
    return null;
  }

  return (
    <ManifestContextProvider value={{ manifest }}>
      <Component {...props} />
    </ManifestContextProvider>
  );
};

SlugCategoryManifestComponent.propTypes = {
  manifestKey: PropTypes.string.isRequired,
  defaultKey: PropTypes.string,
  components: PropTypes.object.isRequired,
  noLoading: PropTypes.bool,
  onlySubscribe: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  fallbackComponent: PropTypes.element.isRequired,
};

export const SlugCategoryManifest = React.memo(SlugCategoryManifestComponent);
