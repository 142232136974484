import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Database, List, Settings, ShoppingCart, Home } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { useVendorLock } from '@bottomless/common/hooks';

export const GenericMenu = ({ isAdmin, ordersCount }) => {
  const vendor = useVendorLock();

  return (
    <div className="panel-nav">
      <div className="nav-section d-flex flex-md-column">
        <NavLink to="/" className={classNames('panel-nav-logo mb-3', { 'flex-column vendor-lock': vendor })}>
          {!vendor && <img src="/images/Bottomless-logo-blue.png" alt="Bottomless" width="140" />}
          {vendor && (
            <>
              <img src={vendor.logo} alt={vendor.name} className="vendor-logo mb-2" />
              <img src="https://bottomless.imgix.net/powered-by-v2.png" alt="Powered by" width="140" />
            </>
          )}
        </NavLink>
        <NavLink to="/profile">
          <Home size="24" />
          <span>Home</span>
        </NavLink>
        <NavLink to="/orders" className="position-relative">
          <List size="24" />
          <span>Orders</span>
          {ordersCount?.sum > 0 && (
            <Badge color="primary" pill className="position-absolute">
              {ordersCount.sum}
            </Badge>
          )}
        </NavLink>
        <NavLink to="/shop">
          <ShoppingCart size="24" />
          <span>Shop</span>
        </NavLink>
        <NavLink to="/account">
          <Settings size="24" />
          <span>Settings</span>
        </NavLink>
      </div>
      {isAdmin && (
        <div className="nav-section d-none d-sm-flex flex-md-column">
          <NavLink to="/admin" className="d-none d-md-flex">
            <Database size="24" />
            <span>Admin</span>
          </NavLink>
        </div>
      )}
    </div>
  );
};

GenericMenu.propTypes = {
  isGuest: PropTypes.bool,
  isAdmin: PropTypes.bool,
  ordersCount: PropTypes.shape({
    sum: PropTypes.number,
  }),
};
