import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Box, Field, Form } from '@bottomless/common/components';
import './PinForm.scss';
import { Loader } from 'react-feather';
import { ScalePin } from './ScalePin';
import { useCustomWording } from '../../hooks/use-custom-wording';

const Schema = Yup.object().shape({
  pin1: Yup.string()
    .min(1)
    .max(1)
    .required('This field is required'),
  pin2: Yup.string()
    .min(1)
    .max(1)
    .required('This field is required'),
  pin3: Yup.string()
    .min(1)
    .max(1)
    .required('This field is required'),
  pin4: Yup.string()
    .min(1)
    .max(1)
    .required('This field is required'),
});

export const PinForm = ({ onSubmit, onSuccess }) => {
  const { cw } = useCustomWording();
  const form = useRef();
  const pin1 = useRef();
  const pin2 = useRef();
  const pin3 = useRef();
  const pin4 = useRef();

  const submitForm = useCallback(() => {
    pin1.current.blur();
    pin4.current.blur();
    setTimeout(form.current.submitForm);
  }, [pin1, pin4, form]);

  const onChange = useCallback(
    refToFocus => e => {
      if (e.target.value) {
        refToFocus.current.focus();
      }
    },
    []
  );

  useEffect(() => {
    if (pin1.current) {
      pin1.current.focus();
    }
  }, [pin1]);

  const onKeyDown = useCallback(
    reftoBackspace => e => {
      const isBackspace = [8, 46].includes(e.keyCode || e.charCode);

      if (isBackspace) {
        reftoBackspace.current.focus();
      }
    },
    []
  );

  const onFocus = useCallback(e => {
    e.target.select();
  }, []);

  const handleSubmit = useCallback(
    async ({ pin1, pin2, pin3, pin4, ...data }) => {
      try {
        return await onSubmit({ ...data, pin: [pin1, pin2, pin3, pin4].join('') });
      } catch (e) {
        for (const field of [1, 2, 3, 4]) {
          form.current.setFieldValue(`pin${field}`, '', false);
          form.current.setFieldTouched(`pin${field}`, false, false);
        }

        throw e;
      }
    },
    [onSubmit, form]
  );

  const onPaste = useCallback(
    e => {
      const pinValues = ((e.clipboardData || window.clipboardData).getData('text') || '').split('').slice(0, 4);

      pinValues.forEach((value, index) => {
        form.current.setFieldValue(`pin${index + 1}`, value);
      });

      submitForm();
    },
    [form, submitForm]
  );

  return (
    <Box secondary className="mb-4">
      <h2 className="text-center mb-4">Enter your scale pin</h2>
      <div className="mb-3 text-center">
        {cw("You'll find the 4-character scale pin at the bottom of your re-ordering scale:")}
      </div>
      <Form
        initialValues={{ pin1: '', pin2: '', pin3: '', pin4: '' }}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        innerRef={form}
        className="form-pin"
      >
        {({ isSubmitting }) => (
          <>
            <div className="d-flex justify-content-between form-pin-inputs">
              <Field
                disabled={isSubmitting}
                name="pin1"
                maxLength="1"
                innerRef={pin1}
                onChange={onChange(pin2)}
                onPaste={onPaste}
                onFocus={onFocus}
              />
              <Field
                disabled={isSubmitting}
                name="pin2"
                maxLength="1"
                innerRef={pin2}
                onChange={onChange(pin3, pin1)}
                onKeyDown={onKeyDown(pin1)}
                onFocus={onFocus}
              />
              <Field
                disabled={isSubmitting}
                name="pin3"
                maxLength="1"
                innerRef={pin3}
                onChange={onChange(pin4, pin2)}
                onKeyDown={onKeyDown(pin2)}
                onFocus={onFocus}
              />
              <Field
                disabled={isSubmitting}
                name="pin4"
                maxLength="1"
                innerRef={pin4}
                onChange={submitForm}
                onKeyDown={onKeyDown(pin3)}
                onFocus={onFocus}
              />
            </div>
            <Field type="hidden" name="pin" />
            {isSubmitting && (
              <div className="d-flex align-items-center justify-content-center mt-3">
                <Loader size="20" className="spin mr-2" />
                <span>Processing</span>
              </div>
            )}
          </>
        )}
      </Form>
      <ScalePin />
    </Box>
  );
};

PinForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
