import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { SignUpForm } from '../SignUp/SignUpForm';
import { TimeToFulfill } from '../TimeToFulfill/TimeToFulfill';
import { PricingBanner } from '../PricingBanner/PricingBanner';
import { CurrentStock } from './CurrentStock';
import './GetStartedModal.scss';

export const GetStartedModal = ({
  isOpen,
  toggle,
  onSignUp,
  getStarted,
  pricingRule,
  dateOverride,
  withGoogle,
  googleRedirect,
  headingText = 'Start coffee journey',
  customTimer,
  isFromVendorLanding = false,
  setCurrentStock,
  subtitleText,
  buttonText,
  className,
  buttonColor,
}) => {
  const [showCurrentStock, setShowCurrentStock] = useState(isFromVendorLanding);

  const onAnswer = answer => {
    if (setCurrentStock) {
      setCurrentStock(answer);
    }

    setShowCurrentStock(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={classNames('get-started-modal', className)}>
      <ModalHeader toggle={toggle}>{headingText}</ModalHeader>
      <ModalBody>
        {subtitleText ? <div className="mb-3">{subtitleText}</div> : undefined}
        {showCurrentStock ? (
          <CurrentStock onAnswer={onAnswer} />
        ) : (
          <>
            <SignUpForm
              withExternal={false}
              onSignUp={onSignUp}
              getStarted={getStarted}
              withGoogle={withGoogle}
              googleRedirect={googleRedirect}
              buttonText={buttonText}
              buttonColor={buttonColor}
            />
            {customTimer
              ? customTimer
              : (!dateOverride || dateOverride === '0') && <TimeToFulfill center className="my-4" />}
            {pricingRule ? (
              <div className="mt-3">
                <PricingBanner pricingRule={pricingRule} />
              </div>
            ) : (
              false
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

GetStartedModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSignUp: PropTypes.func.isRequired,
  getStarted: PropTypes.func.isRequired,
  pricingRule: PropTypes.object,
  dateOverride: PropTypes.string,
  withGoogle: PropTypes.bool,
  googleRedirect: PropTypes.string,
  headingText: PropTypes.string,
  isFromVendorLanding: PropTypes.bool,
  setCurrentStock: PropTypes.func,
  customTimer: PropTypes.any,
  subtitleText: PropTypes.string,
  buttonText: PropTypes.string,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
  buttonColor: PropTypes.string,
};
