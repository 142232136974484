import React, { useEffect, useMemo, useState } from 'react';
import get from 'lodash-es/get';
import pick from 'lodash-es/pick';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { useCookies } from 'react-cookie';
import { ChevronLeft } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Elements } from 'react-stripe-elements';
import { useMediaQuery } from 'react-responsive';
import { DataLoading, Loader } from '@bottomless/common/components';
import { useConditionalDataEffect, useOnce } from '@bottomless/common/hooks';
import { PaymentProvider } from '../../../components/ApplePay/ApplePay';
import { trackEvent, EVENT_PRODUCT_VIEW } from '../../../utils/tracker';
import { getPersonalized } from '../util/get-personalized';
import { StripeLoaded, StripeLoader } from '../../../components/Stripe/StripeLoader';
import { GetStartedModal } from '../../../components/GetStartedModal/GetStartedModal';
import { ApplePayButton } from '../../ProductCheckout/components';
import { PersonalizedProduct } from './PersonalizedProduct';

const getPersonalizedString = personalized =>
  JSON.stringify(
    Object.entries({
      ...personalized,
      grind:
        personalized.grind === 'Whole Beans' || personalized.grind === 'Whole Bean' ? personalized.grind : 'Ground',
    })
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => a.key.localeCompare(b.key))
      .reduce((all, { key, value }) => ({ ...all, [key]: value }), {})
  );

const SummaryComponent = ({
  getStarted,
  getStartedGift,
  onSignUp,
  answers,
  sendAnswers,
  me,
  personalizedProduct,
  makeBottomless,
  onMakeBottomlessSuccess,
  grinds,
  pricingRule,
  quizFirstProduct,
  dateOverride,
  getVendorById,
  finishCheckout,
  onPaymentSuccess,
  getShopFilters,
  checkout,
}) => {
  const [{ phone, gift }] = useCookies(['phone', 'gift']);
  const isMobile = useMediaQuery({ maxDeviceWidth: 991 });
  const [match, setMatch] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [giftError, setGiftError] = useState(null);
  const [variant, setVariant] = useState(null);
  const [vendor, setVendor] = useState(undefined);
  const [isModalOpen, setModalOpen] = useState(false);
  const [overrideData, setOverrideData] = useState({});
  const [shopFilter, setShopFilter] = useState();

  const firstProduct = match && match.product;
  const firstVariant = match && match.variant;
  const [selectedProduct, setSelectedProduct] = useState(firstProduct);
  const [selectedVariant, setSelectedVariant] = useState(firstVariant);

  useEffect(() => {
    setSelectedProduct(firstProduct);
    setSelectedVariant(firstVariant);
  }, [firstProduct, firstVariant]);

  const { specificGrind, ...personalized } = useMemo(() => getPersonalized(answers), [answers]);
  const location = useLocation();
  const toggleModal = () => setModalOpen(!isModalOpen);
  const queryParse = qs.parse(location.search);

  useOnce(() => trackEvent(EVENT_PRODUCT_VIEW, personalizedProduct), [personalizedProduct]);

  useEffect(() => {
    let componentUnmounted = false;

    (async () => {
      const { payload } = await sendAnswers({
        answers: getPersonalizedString(personalized),
        firstProduct: quizFirstProduct,
        vendor: queryParse.vendor,
      });

      if (!payload.message && personalizedProduct) {
        const variant = personalizedProduct.variants.find(v => v.size === personalized.size);
        if (!componentUnmounted) {
          setVariant(variant);
          setMatch(payload);
          setError(undefined);
        }

        trackEvent('QUIZ_COMPLETED', {
          bottomless_plan: pick(personalizedProduct, ['name', 'description', '_id']),
          suggested_product: pick(get(payload, 'product'), ['name', 'vendor_name', '_id']),
        });
      } else {
        if (!componentUnmounted) {
          setError(payload.message);
        }
      }
    })();

    return () => {
      componentUnmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalizedProduct]);

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    let componentUnmounted = false;
    (async () => {
      if (queryParse.vendor && !vendor) {
        const { payload, error } = await getVendorById(queryParse.vendor);
        if (!error && !componentUnmounted) {
          setVendor(payload);
        }
      }
    })();

    return () => {
      componentUnmounted = true;
    };
  }, [queryParse, getVendorById, vendor]);

  useConditionalDataEffect(personalized && !shopFilter, getShopFilters, setShopFilter, personalized);

  const buttonText = useMemo(() => {
    if (gift) {
      return 'Redeem Gift';
    }

    if (me) {
      return 'Choose product';
    }

    return 'Go to Checkout';
  }, [gift, me]);

  const handleSignup = (data, override) =>
    getStarted({
      ...data,
      product: { product: personalizedProduct._id, variant: variant._id },
      phone,
      first_product: { product: firstProduct._id, variant: firstVariant._id },
      personalized,
      ...(specificGrind ? { grind: specificGrind } : {}),
      ...overrideData,
      ...override,
    });

  const signUpHandler = async (overrideData = {}) => {
    if (gift) {
      try {
        const { error: giftError, payload: giftPayload } = await getStartedGift({
          code: gift,
          product: { product: personalizedProduct._id, variant: variant._id },
          phone,
          first_product: { product: firstProduct._id, variant: firstVariant._id },
          personalized,
          ...(specificGrind ? { grind: specificGrind } : {}),
          ...overrideData,
        });

        if (giftError) {
          return setGiftError(`Something went wrong: ${giftPayload?.response?.message}`);
        }

        const { error, payload } = await finishCheckout(giftPayload.checkoutId);

        if (error) {
          return setGiftError(`Something went wrong: ${payload?.response?.message}`);
        }

        return await onPaymentSuccess();
      } catch (e) {
        setGiftError(`Something went wrong: ${e.message}`);
      }
    }

    if (checkout) {
      const { payload } = await handleSignup({}, overrideData);
      return onSignUp(payload);
    }

    if (!me) {
      setOverrideData(overrideData);
      return toggleModal();
    }

    await makeBottomless(personalizedProduct._id, {
      variant: variant._id,
      personalized,
      ...(specificGrind ? { grind: specificGrind } : {}),
    });

    if (onMakeBottomlessSuccess) {
      return onMakeBottomlessSuccess();
    }
  };

  const customRotationShopFilter = useMemo(() => {
    if (!shopFilter) {
      return '';
    }

    const params = Object.entries(shopFilter)
      .filter(([, value]) => value || value?.length)
      .map(([key, value]) => [
        key,
        value.length ? value.map(val => (typeof val === 'object' ? val._id : val)).join(',') : value,
      ])
      .reduce((par, [key, value]) => ({ ...par, [key]: value }), {});

    return `?${qs.stringify(params).replace(/%2C/g, ',')}`;
  }, [shopFilter]);

  const grind = ((grinds || []).find(grind => grind._id === specificGrind) || { name: 'Whole Beans' }).name;

  return (
    <div className="d-flex justify-content-center page-quiz-summary pb-5">
      <DataLoading count={!isLoading && (match || error) ? 1 : 0} isLoading={false}>
        <Loader fullPage />
      </DataLoading>
      {match && match.pricing && !error && !isLoading && (
        <StripeLoader>
          <StripeLoaded>
            <Elements>
              <PaymentProvider
                args={[
                  pricingRule,
                  personalizedProduct,
                  firstProduct,
                  variant,
                  firstVariant,
                  match.pricing,
                  personalized,
                  grind,
                  checkout,
                ]}
              >
                <PersonalizedProduct
                  product={personalizedProduct}
                  variant={variant}
                  onSignUp={signUpHandler}
                  personalized={personalized}
                  vendor={vendor}
                  firstVariant={firstVariant}
                  firstProduct={firstProduct}
                  selectedProduct={selectedProduct}
                  selectedVariant={selectedVariant}
                  setSelectedProduct={setSelectedProduct}
                  setSelectedVariant={setSelectedVariant}
                  buttonText={buttonText}
                  grind={grind}
                  pricingRule={vendor ? { ...pricingRule, first_bag_multiplier: 1 } : pricingRule}
                  pricing={match.pricing}
                  checkout={checkout}
                  alternativeProducts={match.alternativeProducts}
                  dateOverride={dateOverride}
                  hideAdvancedOptions={Boolean(queryParse.vendor || gift)}
                  error={giftError}
                  customRotationShopFilter={customRotationShopFilter}
                  isQuizCompleted={!!answers}
                  isMobile={isMobile}
                  expressCheckoutButton={
                    !me && (
                      <ApplePayButton
                        pricingRule={pricingRule}
                        product={personalizedProduct}
                        variant={variant}
                        firstProduct={selectedProduct}
                        firstVariant={selectedVariant}
                        pricing={match.pricing}
                        grind={specificGrind}
                        personalized={personalized}
                        showSeperator
                        showTitle={false}
                        dateOverride={dateOverride}
                        className="d-flex flex-column align-items-center"
                      />
                    )
                  }
                />
                <GetStartedModal
                  isOpen={isModalOpen}
                  toggle={toggleModal}
                  onSignUp={onSignUp}
                  getStarted={handleSignup}
                  pricingRule={pricingRule}
                  dateOverride={dateOverride}
                  isMobile={isMobile}
                />
              </PaymentProvider>
            </Elements>
          </StripeLoaded>
        </StripeLoader>
      )}
      {error && !isLoading && (
        <Container className="pt-5">
          <div>
            <Link to={vendor ? vendor.slug : '/filter_checkout'} className="d-flex align-items-center">
              <ChevronLeft size="14" />
              Edit
            </Link>
          </div>
          <h1 className="text-center">{error}</h1>
        </Container>
      )}
    </div>
  );
};

SummaryComponent.propTypes = {
  answers: PropTypes.object.isRequired,
  me: PropTypes.object,
  personalizedProduct: PropTypes.object.isRequired,
  onSignUp: PropTypes.func.isRequired,
  getStarted: PropTypes.func.isRequired,
  getStartedGift: PropTypes.func.isRequired,
  finishCheckout: PropTypes.func.isRequired,
  sendAnswers: PropTypes.func.isRequired,
  makeBottomless: PropTypes.func.isRequired,
  onMakeBottomlessSuccess: PropTypes.func,
  grinds: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  pricingRule: PropTypes.object.isRequired,
  quizFirstProduct: PropTypes.shape({
    product: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
  }),
  dateOverride: PropTypes.string,
  getVendorById: PropTypes.func.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
  getShopFilters: PropTypes.func,
  checkout: PropTypes.string,
};

export const Summary = SummaryComponent;
