import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Container } from 'reactstrap';
import classNames from 'classnames';
import { Image } from '@bottomless/common/components';
import { QuizHeader } from './QuizHeader';
import { QuestionText } from './QuestionText';

export const WHOLE_BEANS_ANSWER = 'grind:Whole Bean;specificGrind:null';

const answers = [
  {
    name: 'Whole Beans',
    value: WHOLE_BEANS_ANSWER,
    explanation: 'Retains flavor longer',
    icon: '/assets/dark.png',
    className: 'quiz-image-padding-q2',
    event: {
      grind: 'Whole Beans',
    },
  },
  {
    name: 'Ground',
    value: 'grind:Ground',
    explanation: 'Quick to make',
    icon: '/assets/ground.png',
    className: 'quiz-image-padding-q2',
    event: {
      grind: 'Ground',
    },
  },
];

export const Question2 = ({ index, total, onAnswer, answers: quizAnswers, isMobile }) => {
  return (
    <>
      <QuizHeader index={index} total={total - 1} quizAnswers={quizAnswers} />
      <QuestionText index={index} total={total - 1} title="Grind Options" subtitle="Which grind works best for you?" />
      <Container className="mt-5">
        <Row className="justify-content-center">
          {answers.map((answer, i) => (
            <Col key={i} xs="6" sm="6" lg="3">
              <Card
                body
                tag="button"
                className="d-flex flex-md-row flex-column align-items-center justify-content-center w-100 answer"
                onClick={() => onAnswer(answer)}
              >
                <div className={answer.className}>
                  <Image src={answer.icon} width="50" alt={`Answer - ${answer.name}`} />
                </div>
                <div
                  className={classNames('mb-0', {
                    'ml-4': !isMobile,
                    'mt-2': isMobile,
                  })}
                >
                  {answer.name}
                </div>
              </Card>
              <p className="text-center font-italic mt-2 text-secondary">{answer.explanation}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

Question2.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onAnswer: PropTypes.func.isRequired,
  answers: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
};
