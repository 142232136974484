import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetShipmentProvidersTypes = createAction('Shipments', 'Get providers');

export const getShipmentProvidersAction = () => ({
  [RSAA]: {
    endpoint: 'shipments/providers',
    method: 'GET',
    types: [GetShipmentProvidersTypes.REQUEST, GetShipmentProvidersTypes.SUCCESS, GetShipmentProvidersTypes.FAILURE],
  },
});
