import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card } from 'reactstrap';
import isNil from 'lodash-es/isNil';
import { getDiscountedPrice, Price, withPlaceholder } from '@bottomless/common/components';
import { Shipping } from '../../../components/Membership/Shipping';
import { PaymentIcon } from '../../../components/PaymentIcon/PaymentIcon';

const PaymentDetailsComponent = ({ checkout, showCardDetails, shippingPrice, shippingPriceLoading, className }) => {
  const isEnabled = Boolean(checkout.product);

  const product = useMemo(
    () => (checkout.custom_list ? checkout.custom_list.products[0].product : checkout.product?.product),
    [checkout]
  );

  const variant = useMemo(() => {
    const variantId = checkout.custom_list ? checkout.custom_list.products[0].variant : checkout.product?.variant;

    return product?.variants.find(variant => variant._id === variantId);
  }, [checkout, product]);

  const price = useMemo(
    () =>
      checkout.dynamicPricing
        ? checkout.dynamicPricing.price
        : checkout.vendor_id
        ? variant?.website_pricing
        : variant?.price,
    [checkout, variant]
  );

  const productPricingRule = useMemo(() => checkout.pricing_rule, [checkout]);

  const tax = useMemo(
    () =>
      checkout.taxRate !== null && !isNaN(checkout.taxRate)
        ? checkout.taxRate * (getDiscountedPrice(productPricingRule, price * 100, true, variant?.size) / 100)
        : undefined,
    [productPricingRule, price, checkout, variant]
  );

  const total = useMemo(
    () =>
      variant &&
      getTotal(
        variant,
        product,
        checkout.pricing_rule,
        checkout.dynamicPricing,
        !shippingPriceLoading && shippingPrice,
        tax,
        checkout
      ),
    [checkout, product, variant, shippingPrice, shippingPriceLoading, tax]
  );

  return (
    <div
      className={classnames({
        'text-secondary': !isEnabled,
      })}
    >
      {variant && (
        <>
          <Card body className={classnames('mt-2 mt-lg-3 mb-2', className)}>
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="payment-details">
                  <div className="placeholder-text">
                    <span className="mr-2">Cost:</span>
                    <span className="order-info">
                      <Price value={price} isFirstBag={true} pricingRule={productPricingRule} size={variant.size} />
                    </span>
                  </div>
                  <div className="d-flex align-items-center placeholder-text">
                    <span className="mr-2">Shipping:</span>
                    <span className="text-capitalize order-info">
                      <Shipping
                        pricingRule={checkout.pricing_rule}
                        size={variant.size}
                        isFirstOrder
                        shippingPrice={shippingPrice}
                        shippingPriceLoading={shippingPriceLoading}
                      />
                    </span>
                  </div>
                  <div className="placeholder-text">
                    <span className="mr-2">Signup Fee:</span>
                    <span className="order-info">
                      <Price value={total.signup} cents />
                    </span>
                  </div>
                  {!isNaN(checkout.pricing_rule.free_trial_length) && checkout.pricing_rule.free_trial_length > 0 && (
                    <div className="placeholder-text">
                      <span className="mr-2">Membership:</span>
                      <span className="order-info">{checkout.pricing_rule.free_trial_length} days free</span>
                    </div>
                  )}
                  {checkout.promo_code?.code && (
                    <div className="text-success order-info placeholder-text">
                      <span className="mr-2">Promo Code:</span> {checkout.promo_code.code}
                    </div>
                  )}
                </div>
                <div className="flex-grow-1 text-right">
                  {showCardDetails && (
                    <p className="text-sm">
                      {checkout.stripe_brand && (
                        <PaymentIcon id={checkout.stripe_brand.toLowerCase()} style={{ width: 24 }} />
                      )}
                      <span className="ml-2">{checkout.stripe_last_four}</span>
                    </p>
                  )}
                  <div className="d-flex flex-column flex-lg-row justify-content-lg-end ml-4 placeholder-text">
                    <span className="mr-0 mr-lg-2">Total:</span>
                    <span className="order-info">
                      <Price value={total.total} cents />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div className={className}>
            <div className="small text-secondary mt-2 placeholder-text">{checkout.pricing_rule?.description}</div>
          </div>
        </>
      )}
    </div>
  );
};

const getTotal = (variant, orderProduct, pricingRule, dynamicPricing, shippingPrice, tax, checkout) => {
  const signup = pricingRule.signup_fee || 0;
  const shipping = shippingPrice || getShippingPrice(pricingRule, variant, dynamicPricing, !!checkout.vendor_id);
  const product = getProductPrice(pricingRule, variant, orderProduct, dynamicPricing, checkout);
  const taxInCents = tax ? Math.round(tax * 100) : 0;
  const total = signup + product + shipping + taxInCents;

  return { signup, product, shipping, total };
};

const getProductPaymentPrice = (variant, dynamicPricing, isVendorLocked) => {
  const price = dynamicPricing ? dynamicPricing.price : isVendorLocked ? variant.website_pricing : variant.price;
  const productPriceInCents = Math.round(Number(price) * 100); // convert to cents

  return productPriceInCents;
};

// eslint-disable-next-line no-unused-vars
const getProductPrice = (pricingRule, variant, product, dynamicPricing, checkout) => {
  const productCost = getProductPaymentPrice(variant, dynamicPricing, !!checkout.vendor_id);

  if (!isNil(pricingRule.first_bag_price) && variant.size < 17) {
    return pricingRule.first_bag_price;
  }

  if (pricingRule.first_bag_discount) {
    return Math.round(productCost * (pricingRule.first_bag_discount || 1));
  }

  if (pricingRule.discount_fixed) {
    return Math.max(0, productCost - pricingRule.discount_fixed);
  }

  if (pricingRule.discount_proportion) {
    return Math.round(productCost * pricingRule.discount_proportion);
  }

  return productCost;
};

const getShippingPrice = (pricingRule, variant, dynamicPricing, isVendorLocked) => {
  if (!isNaN(pricingRule.first_shipping_price)) {
    return pricingRule.first_shipping_price;
  }

  const variantPaymentPrice = getProductPaymentPrice(variant, dynamicPricing, isVendorLocked);
  if (
    isVendorLocked &&
    !isNaN(pricingRule.free_shipping_threshold) &&
    variantPaymentPrice >= pricingRule.free_shipping_threshold
  ) {
    return 0;
  }

  if (pricingRule.free_shipping) {
    return 0;
  }

  if (pricingRule.shipping_price) {
    return pricingRule.shipping_price;
  }

  return 0;
};

PaymentDetailsComponent.propTypes = {
  isCheckoutLoading: PropTypes.bool.isRequired,
  checkout: PropTypes.shape({
    email: PropTypes.string,
    verifiedAddress: PropTypes.object,
    product: PropTypes.object,
    stripe_last_four: PropTypes.string,
    stripe_brand: PropTypes.string,
    vendor_id: PropTypes.any,
    pricing_rule: PropTypes.shape({
      free_trial_length: PropTypes.number.isRequired,
      monthly_fee: PropTypes.number.isRequired,
      batch_size: PropTypes.number.isRequired,
      description: PropTypes.string,
      signup_fee: PropTypes.number,
    }).isRequired,
    dynamicPricing: PropTypes.shape({
      price: PropTypes.number.isRequired,
    }),
    promo_code: PropTypes.shape({
      code: PropTypes.string,
    }),
    custom_list: PropTypes.object,
    first_product: PropTypes.shape({
      variants: PropTypes.arrayOf(PropTypes.shape({ size: Price.number })),
    }),
    taxRate: PropTypes.number.isRequired,
  }).isRequired,
  showCardDetails: PropTypes.bool,
  shippingPrice: PropTypes.number,
  shippingPriceLoading: PropTypes.bool,
  className: PropTypes.string,
};

PaymentDetailsComponent.defaultProps = {
  showCardDetails: true,
};

export const PaymentDetails = withPlaceholder({
  condition: 'checkout',
  props: {
    checkout: {
      product: {
        product: {
          variants: [{ _id: 'placeholder', price: 15 }],
        },
        variant: 'placeholder',
      },
      pricing_rule: { free_shipping: true, description: 'Placeholder' },
    },
    showCardDetails: false,
  },
})(PaymentDetailsComponent);
