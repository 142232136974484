import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useOnScreen } from '@bottomless/common/src/hooks';

export const Step = ({ number, title, description, actionText, onClick, isOnScreen }) => {
  const ref = useRef();
  const onScreen = useOnScreen(ref, '-150px');

  useEffect(() => {
    if (onScreen) {
      isOnScreen(number);
    }
  }, [isOnScreen, number, onScreen]);

  return (
    <div className="step d-flex" ref={ref}>
      <div className="step-number">{number}</div>
      <div className="step-info">
        <h3 className="step-title">{title}</h3>
        <div className="step-description">{description}</div>
        {actionText && onClick && (
          <Button color="dark" size="lg" className="landing-section-button" onClick={() => onClick()}>
            {actionText}
          </Button>
        )}
      </div>
    </div>
  );
};

Step.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  onClick: PropTypes.func,
  isOnScreen: PropTypes.func,
};
