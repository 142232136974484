import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Container } from 'reactstrap';
import { useOnScreen } from '@bottomless/common/src/hooks';

export const HandpickedCoffees = ({ actionText, onClick }) => {
  const ref = useRef();
  const onScreen = useOnScreen(ref, '-100px', true);

  return (
    <div className={classNames('landing-section', { 'landing-section-visible': onScreen })}>
      <Container>
        <div className="landing-section-bg d-flex flex-column align-items-center" ref={ref}>
          <h2 className={`text-center w-50 ${actionText && onClick ? '' : 'mb-0'}`}>
            500+ Handpicked <br />
            COFFEES from THE BEST Local Roasters IN THE COUNTRY
          </h2>
          {actionText && onClick && (
            <Button onClick={onClick} color="dark" size="lg" className="mt-5">
              {actionText}
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
};

HandpickedCoffees.propTypes = {
  actionText: PropTypes.string,
  onClick: PropTypes.func,
};
