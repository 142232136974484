import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Edit } from 'react-feather';

export const GenericChangeProduct = ({ checkout, goToShop, goToLandingPage }) => {
  const onClick = useCallback(() => {
    if (checkout.vendor_id) {
      goToShop(checkout._id, checkout.product?.product?.category?.slug);
    } else {
      goToLandingPage();
    }
  }, [checkout, goToShop, goToLandingPage]);

  return (
    <Button color="link" size="sm" onClick={onClick} className="placeholder-text">
      <Edit width="18" height="18" role="img" aria-label="Edit" />
    </Button>
  );
};

GenericChangeProduct.propTypes = {
  checkout: PropTypes.object,
  goToLandingPage: PropTypes.func.isRequired,
  goToShop: PropTypes.func.isRequired,
};
