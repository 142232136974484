import { useCallback, useMemo } from 'react';

const ROAST_MAP = {
  dark: '5bcece4bae8c5c6b0faae13d',
  'light-medium': '5bcece25f325036b0f66bb0b',
  'medium-dark': '5bcece43ae8c5c6b0faae13c',
  french: '5bcece52ae8c5c6b0faae13e',
  italian: '5bcece5bae8c5c6b0faae13f',
  medium: '5bcece3cae8c5c6b0faae13b',
  light: '5bcece1ef325036b0f66bb0a',
};

const ORIGIN_MAP = {
  blend: '5bced756ae8c5c6b0faae145',
};

const TAG_MAP = {
  organic: '5bcecde8f325036b0f66bb07',
  decaf: '5bcecdeef325036b0f66bb08',
  cold_brew: '5de6e41428476846505cf46e',
  espresso: '5bcecdf6f325036b0f66bb09',
};

export const useProductsCount = ({ products, personalized, optional }) => {
  const padPersonalizedRoast = ({ personalized }) => {
    const roasts = personalized.roast;

    // Pad roast
    const seen_roasts = {
      light: false,
      'light-medium': false,
      medium: false,
      'medium-dark': false,
      dark: false,
      french: false,
      italian: false,
    };

    for (const roast of personalized?.roast || []) {
      seen_roasts[roast] = true;
    }

    if (seen_roasts['light'] && !seen_roasts['light-medium']) {
      roasts.push('light-medium');
      seen_roasts['light-medium'] = true;
    }

    if (seen_roasts['medium']) {
      if (!seen_roasts['dark'] && !seen_roasts['light-medium']) {
        roasts.push('light-medium');
        seen_roasts['light-medium'] = true;
      }
      if (!seen_roasts['light'] && !seen_roasts['medium-dark']) {
        roasts.push('medium-dark');
        seen_roasts['medium-dark'] = true;
      }
    }

    if (seen_roasts['dark']) {
      if (!seen_roasts['french']) {
        roasts.push('french');
        seen_roasts['french'] = true;
      }
      if (!seen_roasts['italian']) {
        roasts.push('italian');
        seen_roasts['italian'] = true;
      }
    }

    if (seen_roasts['italian']) {
      if (!seen_roasts['french']) {
        roasts.push('french');
        seen_roasts['french'] = true;
      }
      if (!seen_roasts['dark']) {
        roasts.push('dark');
        seen_roasts['dark'] = true;
      }
    }

    if (seen_roasts['french']) {
      if (!seen_roasts['italian']) {
        roasts.push('italian');
        seen_roasts['italian'] = true;
      }
      if (!seen_roasts['dark']) {
        roasts.push('dark');
        seen_roasts['dark'] = true;
      }
    }

    return roasts;
  };

  const paddedPersonalized = useMemo(() => {
    personalized.roast = padPersonalizedRoast({ personalized });
    return personalized;
  }, [personalized]);

  const matchesRoast = useRoastMatch({ personalized: paddedPersonalized, optional });
  const matchesOrigin = useOriginMatch({ personalized, optional });
  const matchesVendor = useVendorMatch({ personalized, optional });
  const matchesTastingNotes = useTastingNotesMatch({ personalized, optional });
  const matchesSize = useSizeMatch({ personalized, optional });
  const matchesTags = useTagsMatch({ personalized, optional });
  const matchesGrind = useGrindMatch({ personalized, optional });
  const matchesPrice = usePricingMatch({ personalized, optional });

  return useMemo(
    () =>
      products.filter(
        product =>
          matchesRoast(product) &&
          matchesSize(product) &&
          matchesPrice(product) &&
          matchesGrind(product) &&
          matchesOrigin(product) &&
          matchesVendor(product) &&
          matchesTastingNotes(product) &&
          matchesTags(product)
      ).length,
    [
      products,
      matchesRoast,
      matchesSize,
      matchesPrice,
      matchesGrind,
      matchesOrigin,
      matchesVendor,
      matchesTastingNotes,
      matchesTags,
    ]
  );
};

const useRoastMatch = ({ personalized, optional }) => {
  const matchAll = useCallback(() => true, []);
  const mappedRoasts = useMemo(() => personalized?.roast?.map(r => ROAST_MAP[r]).filter(Boolean), [personalized.roast]);
  const matchRoasts = useCallback(product => mappedRoasts?.includes(product.roast?._id), [mappedRoasts]);
  return !personalized?.roast?.length && optional ? matchAll : matchRoasts;
};

const useOriginMatch = ({ personalized, optional }) => {
  const hasSingleOrigin = useMemo(() => personalized.origin?.includes('single'), [personalized.origin]);
  const hasBlendOrigin = useMemo(() => personalized.origin?.includes('blend'), [personalized.origin]);

  const matchAll = useCallback(() => true, []);
  const matchAllSingle = useCallback(product => product.origin?._id !== ORIGIN_MAP.blend, []);
  const matchBlend = useCallback(product => product.origin?._id === ORIGIN_MAP.blend, []);
  const matchGivenSingle = useCallback(
    product =>
      [...personalized.single_origins, ...(hasBlendOrigin ? [ORIGIN_MAP.blend] : [])].includes(product.origin?._id),
    [personalized.single_origins, hasBlendOrigin]
  );

  if (optional && !personalized.origin?.length) {
    return matchAll;
  }

  if (hasSingleOrigin) {
    if (personalized.single_origins?.length) {
      return matchGivenSingle;
    }

    return hasBlendOrigin ? matchAll : matchAllSingle;
  }

  return hasBlendOrigin ? matchBlend : matchAll;
};

const useVendorMatch = ({ personalized }) => {
  const matchAll = useCallback(() => true, []);
  const matchGivenVendors = useCallback(product => personalized.vendor.includes(product.vendor_id._id), [
    personalized.vendor,
  ]);

  return !personalized.vendor?.length ? matchAll : matchGivenVendors;
};

const useTastingNotesMatch = ({ personalized }) => {
  const matchAll = useCallback(() => true, []);
  const matchGivenTastingNotes = useCallback(
    product =>
      !!product.tasting_notes.find(tastingNote =>
        personalized.tasting_note_category.includes(tastingNote.category_id._id)
      ),
    [personalized.tasting_note_category]
  );

  return !personalized.tasting_note_category?.length ? matchAll : matchGivenTastingNotes;
};

const useSizeMatch = ({ personalized, optional }) => {
  const matchAll = useCallback(() => true, []);
  const matchSize = useCallback(
    product => !!product.variants.find(variant => variant.available && variant.size === personalized.size),
    [personalized.size]
  );

  return !personalized.size && optional ? matchAll : matchSize;
};

const useGrindMatch = ({ personalized, optional }) => {
  const isGround = useMemo(() => personalized.grind && String(personalized.grind) !== 'Whole Beans', [
    personalized.grind,
  ]);

  const matchAll = useCallback(() => true, []);
  const matchGivenGrind = useCallback(product => !!product.available_ground, []);

  if (!personalized.grind && optional) {
    return matchAll;
  }

  return isGround ? matchGivenGrind : matchAll;
};

const useTagsMatch = ({ personalized, optional }) => {
  const isEspresso = useMemo(() => personalized.roast?.includes('espresso'), [personalized.roast]);
  const isDecaf = useMemo(() => personalized.tag?.includes('decaf'), [personalized.tag]);
  const isColdBrew = useMemo(() => personalized.tag?.includes('cold_brew'), [personalized.tag]);

  const mappedTags = useMemo(
    () => [...(personalized.tag || []), ...(isEspresso ? ['espresso'] : [])].map(r => TAG_MAP[r]).filter(Boolean),
    [personalized.tag, isEspresso]
  );

  const matchAll = useCallback(() => true, []);

  const matchAllRegular = useCallback(
    product => !product.tags.find(tag => tag._id === TAG_MAP.decaf || tag._id === TAG_MAP.cold_brew),
    []
  );
  const matchGivenTags = useCallback(
    product => {
      const matchesDecaf = !isDecaf ? !product.tags.find(tag => tag._id === TAG_MAP.decaf) : true;
      const matchesColdBrew = !isColdBrew ? !product.tags.find(tag => tag._id === TAG_MAP.cold_brew) : true;

      return (
        matchesDecaf &&
        matchesColdBrew &&
        mappedTags.reduce((res, tagId) => res && !!product.tags.find(tag => tag._id === tagId), true)
      );
    },
    [mappedTags, isDecaf, isColdBrew]
  );

  if (!personalized.tag && optional) {
    return matchAll;
  }

  return !personalized.tag?.length ? matchAllRegular : matchGivenTags;
};

const usePricingMatch = ({ personalized, optional }) => {
  const matchAll = useCallback(() => true, []);
  const matchPrice = useCallback(product => product.rotation_price && product.rotation_price[personalized.price_type], [
    personalized.price_type,
  ]);

  return !personalized.price_type && optional ? matchAll : matchPrice;
};
