import React from 'react';
import { SlugCategoryManifest } from '../../../components/CategoryManifest';
import { CoffeeSorting, GenericSorting } from '../manifest';

const COMPONENTS = {
  CoffeeShopSorting: CoffeeSorting,
  GenericShopSorting: GenericSorting,
};

export const Sorting = props => (
  <SlugCategoryManifest
    manifestKey="shopSorting"
    components={COMPONENTS}
    noLoading
    onlySubscribe
    fallbackComponent={GenericSorting}
    {...props}
  />
);
