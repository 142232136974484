import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { UncontrolledAlert } from 'reactstrap';

const AlertsComponent = ({ alerts }) => (
  <>
    {alerts.map(alert => (
      <UncontrolledAlert key={alert.id} color={alert.type}>
        {alert.title && <h4 className="alert-heading">{alert.title}</h4>}
        <div>{alert.message}</div>
      </UncontrolledAlert>
    ))}
  </>
);

AlertsComponent.propTypes = {
  alerts: PropTypes.array.isRequired,
};

export const Alerts = connect(({ alert }) => ({ alerts: alert.data }))(AlertsComponent);
