import { ProductName } from '@bottomless/common/src/components/Product';
import { useToggle } from '@bottomless/common/src/hooks';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Card, Collapse } from 'reactstrap';

const MAX_PRODUCTS_AMOUNT = 3;

export const CustomListProducts = ({ customList, className }) => {
  const [showMore, toggleMore] = useToggle();
  const [products, restOfProducts] = useMemo(
    () => [customList.products.slice(1, MAX_PRODUCTS_AMOUNT), customList.products.slice(MAX_PRODUCTS_AMOUNT)],
    [customList]
  );

  return (
    <>
      <div className="mt-3 text-sm text-secondary">
        You will be charged for the next product only after you run out of the first one
      </div>
      <Card body className={className('mb-3 d-flex flex-row justify-content-between rotation-product p-1', className)}>
        <div className="d-flex rotation-product-wrapper justify-content-between p-1">
          <div className="d-flex flex-column">
            <div className="text-sm text-secondary text-uppercase font-weight-bold mb-2">Next products</div>
            {products.map((product, i) => (
              <div
                key={product._id}
                className={classNames('d-flex align-items-center', { 'mb-3': i < products.length - 1 })}
              >
                <img
                  className="rotation-product-image img-fluid mr-4"
                  src={product.product.small_image_src}
                  alt={product.product.name}
                />
                <div>
                  <div className="rotation-product-vendor text-secondary">{product.product.vendor_name}</div>
                  <ProductName product={product} grind={product.grind.name} />
                </div>
              </div>
            ))}
            <Collapse isOpen={showMore} className="mt-3">
              {restOfProducts.map((product, i) => (
                <div
                  key={product._id}
                  className={classNames('d-flex align-items-center', { 'mb-3': i < restOfProducts.length - 1 })}
                >
                  <img
                    className="rotation-product-image img-fluid mr-4"
                    src={product.product.small_image_src}
                    alt={product.product.name}
                  />
                  <div>
                    <div className="rotation-product-vendor text-secondary">{product.product.vendor_name}</div>
                    <ProductName product={product} grind={product.grind.name} />
                  </div>
                </div>
              ))}
            </Collapse>
            {restOfProducts.length > 0 && (
              <Button color="link" onClick={toggleMore} className="mt-3">
                {showMore ? `Hide last ${restOfProducts.length}` : `Show ${restOfProducts.length} more`}
              </Button>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

CustomListProducts.propTypes = {
  customList: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        product: PropTypes.shape({
          name: PropTypes.string.isRequired,
          vendor_name: PropTypes.string.isRequired,
          small_image_src: PropTypes.string.isRequired,
        }).isRequired,
        grind: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      })
    ),
  }),
  className: PropTypes.string,
};
