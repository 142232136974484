import React from 'react';
import PropTypes from 'prop-types';
import { ProductCategoryManifest } from '../../../components/CategoryManifest';
import { CoffeeCompleteMessage, GenericCompleteMessage } from '../manifest';

const COMPONENTS = {
  CoffeeCheckoutCompleteMessage: CoffeeCompleteMessage,
  GenericCheckoutCompleteMessage: GenericCompleteMessage,
};

export const CompleteMessage = props => (
  <ProductCategoryManifest
    slugOrId={props.productId}
    manifestKey="checkoutCompleteMessage"
    components={COMPONENTS}
    fallbackComponent={GenericCompleteMessage}
    {...props}
  />
);

CompleteMessage.propTypes = {
  productId: PropTypes.string.isRequired,
};
