import { VENDOR_IDS } from '../../../constants/vendor';

export const customWording = {
  [VENDOR_IDS.AG1]: {
    'Waiting for scale connection...': 'Waiting for connection...',
    'Connect your scale to Wi-Fi': 'Connect your smart canister to Wi-Fi',
    'Remove the item from the scale (or leave your empty container on it), connect to the “Bottomless_Scale” Wi-Fi, and select “Set Zero Level”.':
      'Empty the canister, connect to the “Bottomless_Scale” Wi-Fi, and select “Set Zero Level”.',
  },
};
