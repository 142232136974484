import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { RadioBox } from '@bottomless/common/src/components';
import { BuildCard } from '../BuildCard';
import { useProductsCount } from '../../hooks/use-products-count.hook';

export const PriceComponent = ({ disabled, onChange, type, products, hideOptionWithoutProducts, optionFilter }) => {
  const pricePersonalized = useMemo(() => ({ ...optionFilter, price_type: type }), [optionFilter, type]);
  const productCount = useProductsCount({
    products,
    personalized: pricePersonalized,
    optional: true,
  });

  if (hideOptionWithoutProducts && !productCount) {
    return <></>;
  }

  return (
    <Col lg="3" md="3" xs="4" className="answer-card-container">
      <RadioBox
        name="price_type"
        type="radio"
        className="answer-card"
        disabled={disabled(type)}
        label={<BuildCard name={type} />}
        value={type}
        onChange={onChange}
      />
    </Col>
  );
};

PriceComponent.propTypes = {
  disabled: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  optionFilter: PropTypes.object,
};

export const Price = React.memo(PriceComponent);
