import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Container } from 'reactstrap';
import classNames from 'classnames';
import { Image } from '@bottomless/common/components';
import { QuizHeader } from './QuizHeader';
import { QuestionText } from './QuestionText';

const answers = [
  {
    name: "I'm almost out",
    value: 'dateOverride:0',
    event: { dateOverride: '0' },
  },
  {
    name: '1 week',
    value: 'dateOverride:nextBusiness',
    event: { dateOverride: 'nextBusiness' },
  },
  {
    name: '2 weeks',
    value: 'dateOverride:1',
    event: { dateOverride: '1' },
  },
  {
    name: '3 weeks',
    value: 'dateOverride:2',
    event: { dateOverride: '2' },
  },
  {
    name: '4+ weeks',
    value: 'dateOverride:3',
    event: { dateOverride: '3' },
  },
];

export const Question6 = ({ index, total, onAnswer, answers: quizAnswers, isMobile }) => {
  return (
    <>
      <QuizHeader index={index - 1} total={total - 1} quizAnswers={quizAnswers} />
      <QuestionText
        index={index - 1}
        total={total - 1}
        title="Current stock"
        subtitle="Approximately how many weeks before you run out of coffee?"
      />
      <div className="answers">
        <Container className="mt-5">
          <Row className="justify-content-center">
            {answers.map((answer, i) => (
              <Col
                key={answer.value}
                xs="6"
                sm="6"
                lg="3"
                className={!isMobile && (i === 0 ? 'pl-0' : i === answers.length - 1 ? 'pr-0' : '')}
              >
                <Card
                  body
                  tag="button"
                  className="d-flex flex-md-row flex-column align-items-center justify-content-center mb-3 w-100 answer"
                  onClick={() => onAnswer(answer)}
                >
                  <Image src="/assets/q6-img.png" width="30" alt={`Answer - ${answer.name}`} />
                  <p
                    className={classNames('mb-0', {
                      'ml-4': !isMobile,
                      'mt-2': isMobile,
                    })}
                  >
                    {answer.name}
                  </p>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

Question6.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onAnswer: PropTypes.func.isRequired,
  answers: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
};

Question6.scheduleRelated = true;
