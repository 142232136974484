export const getPersonalized = answers => {
  const objectFields = ['roast', 'tag', 'origin'];
  const numberFields = ['size'];

  return {
    origin: [],
    single_origins: [],
    tag: [],
    ...Object.entries(answers)
      .reverse()
      .filter(([, answer]) => answer !== '')
      .reduce((all, [, answer]) => {
        const single = answer.split(';').map(el => el.split(':'));

        return single.reduce((a, [key, value]) => {
          const isDeleted = 'null' === value;

          if (isDeleted) {
            return { ...a, [key]: undefined };
          }

          if (objectFields.includes(key)) {
            return { ...a, [key]: [...(a[key] || []), value] };
          }

          if (numberFields.includes(key)) {
            return { ...a, [key]: Number(value) };
          }

          return { ...a, [key]: value };
        }, all);
      }, {}),
  };
};

export const toPersonalized = values => {
  const toArray = values =>
    Object.entries(values)
      .filter(([, value]) => value)
      .reduce((all, [key]) => [...all, key], []);

  return {
    ...values,
    size: Number(values.size),
    roast: toArray(values.roast),
    tag: toArray(values.tag),
    origin: toArray(values.origin),
    vendor: toArray(values.vendor),
    single_origins: toArray(values.single_origins),
    tasting_note_category: toArray(values.tasting_note_category),
  };
};
