import moment from 'moment-timezone';

const PST_TIMEZONE = 'America/Los_Angeles';

export const datesService = {
  getTodayInSeattle() {
    return moment()
      .tz(PST_TIMEZONE)
      .startOf('day')
      .toDate();
  },
  getLocalDateInUTC(date) {
    return moment(date)
      .utc(true)
      .toDate();
  },
  formatDateInSeattle(date, format = 'MM/DD/YYYY') {
    return moment.tz(date, PST_TIMEZONE).format(format);
  },
  getDateOnly(date) {
    return moment(date)
      .utcOffset(0, true)
      .format();
  },
};
