import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useDataEffect, useToggle, useConditionalDataEffect } from '@bottomless/common/hooks';
import { addAlertAction } from '@bottomless/common/store';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { getPersonalized } from '../FilterCheckout/util/get-personalized';
import { getPricingRuleAction, getStartedAction, makeBottomlessProductAction, getCheckoutAction } from '../../store';
import { saveCustomRotationPersonalizedAction } from '../../store/quiz';
import { FilterCheckoutEdit } from './FilterCheckoutEdit';
import { GetStartedModal } from '../../components/GetStartedModal/GetStartedModal';
import { PanelPage } from '../../layouts/PanelPage/PanelPage';
import { Simple } from '../../layouts/Simple/Simple';
import './PublicFilterCheckout.scss';

const PublicFilterCheckoutEditPageComponent = ({
  answers,
  getPricingRule,
  history,
  savePersonalized,
  getStarted,
  me,
  addAlert,
  makeBottomless,
  quizPersonalized,
  getCheckout,
}) => {
  const [{ checkout }] = useCookies(['checkout']);
  const personalized = useMemo(() => getPersonalized(answers), [answers]);
  const [pricingRule, setPricingRule] = useState(null);
  const [isModalOpen, toggleModal] = useToggle(false);
  const [chosenProduct, setChosenProduct] = useState(null);
  const [{ phone }] = useCookies(['phone']);

  useDataEffect(getPricingRule, setPricingRule);

  const [checkoutData, setCheckout] = useState(null);

  const getCheckoutById = useCallback(() => getCheckout(checkout), [checkout, getCheckout]);

  useConditionalDataEffect(checkout, getCheckoutById, setCheckout);

  const vendorId = checkoutData?.vendor_id;

  const onSubmit = useCallback(async ({ personalized, product, first_product, grind }) => {
    if (checkout) {
      return handleSignup({ personalized, product, first_product, grind });
    }

    if (me) {
      return makeBottomless(product.product, { variant: product.variant, personalized, grind });
    }

    setChosenProduct({ product, first_product, grind });
    await savePersonalized(personalized, first_product);

    return toggleModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = useCallback(({ checkoutId }) => {
    const { back } = qs.parse(window.location.search);

    if (checkoutId) {
      history.push(`/get_started/${checkoutId}`);
    } else if (me) {
      addAlert('Product has been successfully saved', 'success');
      history.push(back ? back : '/profile');
    } else {
      history.push('/filter_checkout?finished=true&nopopup=true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignup = data => getStarted({ personalized: quizPersonalized, phone, ...data, ...chosenProduct });

  const computedPricingRule = useMemo(() => (!me ? pricingRule : me.pricing_rule), [me, pricingRule]);

  return (
    <>
      <Simple className="page-quiz">
        <PanelPage
          className="page-user-filter-checkout page-public-filter-checkout"
          pricingRule={computedPricingRule}
          withBanner={false}
        >
          <FilterCheckoutEdit
            personalized={personalized}
            pricingRule={computedPricingRule}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            firstProductText={me ? 'Example product' : 'First product'}
            advancedOpened
            back="/filter_checkout?finished=true"
            vendorId={vendorId}
          />
        </PanelPage>
      </Simple>
      <GetStartedModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        onSignUp={onSuccess}
        getStarted={handleSignup}
        pricingRule={computedPricingRule}
      />
    </>
  );
};

PublicFilterCheckoutEditPageComponent.propTypes = {
  me: PropTypes.shape({
    personalized: PropTypes.object,
    pricing_rule: PropTypes.object.isRequired,
  }),
  answers: PropTypes.object,
  quizPersonalized: PropTypes.object,
  getPricingRule: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  savePersonalized: PropTypes.func.isRequired,
  getStarted: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  makeBottomless: PropTypes.func.isRequired,
  getCheckout: PropTypes.func,
};

export const PublicFilterCheckoutEditPage = connect(
  ({ quiz, user: { me } }) => ({ answers: quiz.customRotation, me, quizPersonalized: quiz.personalized }),
  dispatch => ({
    getPricingRule: () => dispatch(getPricingRuleAction()),
    savePersonalized: (personalized, firstProduct) =>
      dispatch(saveCustomRotationPersonalizedAction(personalized, firstProduct)),
    getCheckout: id => dispatch(getCheckoutAction(id)),
    getStarted: data => dispatch(getStartedAction(data)),
    addAlert: (message, type) => dispatch(addAlertAction(message, type)),
    makeBottomless: (id, data) => dispatch(makeBottomlessProductAction(id, data)),
  })
)(PublicFilterCheckoutEditPageComponent);
