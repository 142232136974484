const moment = require('moment-timezone');

export const pstTimezone = 'America/Los_Angeles';

const compareDateWithoutTime = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const getNextBusinessDay = (from = new Date(), excludeDates = []) => {
  const date = new Date(from);

  excludeDates = excludeDates.map(row => moment.tz(row, 'YYYY-MM-DD', pstTimezone).toDate());
  do {
    date.setDate(date.getDate() + 1);
  } while (!(date.getDay() % 6) || excludeDates.find(row => compareDateWithoutTime(row, date)));

  return date;
};

export const getNextBusinessDayOrToday = (from = new Date()) => {
  const date = new Date(from);

  while (!(date.getDay() % 6)) {
    date.setDate(date.getDate() + 1);
  }

  return date;
};

const getNowIn = (timezone = pstTimezone) =>
  new Date(
    moment()
      .tz(timezone)
      .format('YYYY-MM-DD HH:mm:ss')
  );

export const getNowInPst = () => getNowIn(pstTimezone);

const getDateAtMidnight = date => {
  return new Date(date).setHours(0, 0, 0, 0);
};

export const getNextFulfillmentDate = ({ startDate, excludedDates }) => {
  const startDateZero = getDateAtMidnight(startDate);
  const excludedDatesZero = excludedDates.map(getDateAtMidnight);

  const ONE_DAY_IN_MILLISECONDS = 86400000;

  const findDate = compareDate => {
    if (!excludedDatesZero.includes(compareDate)) {
      return compareDate;
    } else {
      return findDate(new Date(compareDate + ONE_DAY_IN_MILLISECONDS).getTime());
    }
  };
  return findDate(startDateZero);
};

export const getIsMondayToThursday = () => {
  const today = new Date();
  const day = today.getDay();
  return day !== 0 && day !== 5 && day !== 6;
};
