import React from 'react';
import PropTypes from 'prop-types';
import { Price } from '../Price';

export const VariantPrice = ({ user, variant, checkout, ...props }) => {
  const useWebsitePricing = !!(user?.vendor_id || checkout?.vendor_id);
  return <Price {...props} value={useWebsitePricing ? variant.website_pricing || variant.price : variant.price} />;
};

VariantPrice.propTypes = {
  user: PropTypes.shape({
    vendor_id: PropTypes.string,
  }),
  variant: PropTypes.shape({
    website_pricing: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    price: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  }),
  checkout: PropTypes.object,
};
