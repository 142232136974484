import { LogoutTypes, ChangeScaleTypes } from '@bottomless/common/store';
import { GetRotationTypes } from './rotation.actions';

const initialState = {
  rotation: null,
  isLoading: false,
};

export const rotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetRotationTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetRotationTypes.SUCCESS:
      return {
        ...state,
        rotation: action.payload,
        isLoading: false,
      };

    case GetRotationTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case LogoutTypes.SUCCESS:
    case ChangeScaleTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
