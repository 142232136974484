/* global hj */
import * as Sentry from '@sentry/browser';
import React, { Component, Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Loader } from '@bottomless/common/components';
import { browserMatches } from '@bottomless/common/utils';
import { LogoutTypes } from '@bottomless/common/store';
import { VendorLockProvider } from '@bottomless/common/src/context/vendor-lock.context';
import { FilterCheckoutPage } from './pages/FilterCheckout/FilterCheckout';
import { GetStartedCompletedPage } from './pages/GetStartedCompleted/GetStartedCompleted';
import { HomeLandingRedirect } from './pages/Home/HomeLandingRedirect';
import { LoginPage } from './pages/Login/Login';
import { VendorLockedLoginPage } from './pages/VendorLockedLogin/VendorLockedLogin';
import { FlyLandingRedirect } from './pages/Landing/Redirect/FlyLandingRedirect';
import { PartnersRedirect } from './pages/Landing/Redirect/PartnersRedirect';
import { ForwardThinkingLandingRedirect } from './pages/Landing/Redirect/ForwardThinkingLandingRedirect';
import { DeelLandingRedirect } from './pages/Landing/Redirect/DeelLandingRedirect';
import { LukeAndJennaLandingRedirect } from './pages/Landing/Redirect/LukeAndJennaLandingRedirect';
import { TwitterLandingRedirect } from './pages/Landing/Redirect/TwitterLandingRedirect';
import { SixtyOffLandingRedirect } from './pages/Landing/Redirect/60LandingRedirect';
import { FiftyOffPostcardLandingRedirect } from './pages/Landing/Redirect/50PostcardLandingRedirect';
import { FiftyOffStickerLandingRedirect } from './pages/Landing/Redirect/50StickerLandingRedirect';
import { FortyOffLandingRedirect } from './pages/Landing/Redirect/40LandingRedirect';
import { FiftyOffLandingRedirect } from './pages/Landing/Redirect/50LandingRedirect';
import { SeventyOffLandingRedirect } from './pages/Landing/Redirect/70LandingRedirect';
import { CoffeeRedirect } from './pages/Landing/Redirect/CoffeeRedirect';
import { PetsRedirect } from './pages/Landing/Redirect/PetsRedirect';
import { MainShopPage } from './pages/Shop/MainShop';
import { ReferralClipboard } from './pages/ToClipboard/Referral/Referral';
import { ProductDetailsPage } from './pages/ProductDetails/ProductDetails';
import { PublicShopPage } from './pages/Shop/PublicShop';
import { ReferralCookie } from './ReferralCookie';
import { FallbackRouter } from './router/FallbackRouter';
import { AdminRoutes } from './router/Admin';
import { VendorRoutes } from './router/VendorPortal';
import { createStore } from './store';
import { PublicFilterCheckoutEditPage } from './pages/FilterCheckoutEdit/PublicFilterCheckoutEdit';
import { HomeLanding } from './pages/Home/HomeLanding';
import { ReferralRedirect } from './components/ReferralRedirect';
import { PageViewTracker } from './PageViewTracker';
import { GetStartedPage } from './pages/GetStarted/GetStarted';
import { ConnectLoginPage } from './pages/Connect/ConnectLogin';
import { ScaleConnectRouter } from './router/ScaleConnectRouter';

const PanelRoutes = React.lazy(() => import(/* webpackChunkName: 'panel' */ './router/Panel'));
const ShareHeatmap = React.lazy(() =>
  /* webpackChunkName: 'heatmap-page' */ import('./pages/ShareHeatmap/ShareHeatmap')
);
const OrderStatusPage = React.lazy(() =>
  /* webpackChunkName: 'order-status-page' */ import('./pages/OrderStatus/OrderStatusPage')
);
const GiftsPage = React.lazy(() => /* webpackChunkName: 'gifts-page' */ import('./pages/Gifts/Gifts'));
const SlowLocalDeliveryPage = React.lazy(() =>
  /* webpackChunkName: 'slow-local-delivery-page' */ import('./pages/SlowLocalDelivery/SlowLocalDeliveryPage')
);
const History = React.lazy(() => /* webpackChunkName: 'history' */ import('./pages/History/History'));
const ReviewPage = React.lazy(() => /* webpackChunkName: 'review-page' */ import('./pages/Review/ReviewPage'));
const BusinessPage = React.lazy(() => /* webpackChunkName: 'business-page' */ import('./pages/Business/BusinessPage'));

const NotFoundPage = React.lazy(() => import('./pages/Landing/NotFoundPage'));
const OrderRatePage = React.lazy(() => import('./pages/OrderRate/OrderRate'));
const ForgotPage = React.lazy(() => import('./pages/Forgot/Forgot'));
const MagicLoginPage = React.lazy(() => import('./pages/MagicLogin'));
const MagicLinkPage = React.lazy(() => import('./pages/MagicLink/MagicLink'));
const SetupPasswordPage = React.lazy(() => import('./pages/SetupPassword/SetupPassword'));
const SetupCreditCardPage = React.lazy(() => import('./pages/SetupCreditCard/SetupCreditCard'));
const SetupAddressPage = React.lazy(() => import('./pages/SetupAddress/SetupAddress'));
const SetupOrderTimingPage = React.lazy(() => import('./pages/SetupOrderTiming/SetupOrderTiming'));
const SetupOnboardingTextPage = React.lazy(() => import('./pages/SetupText/SetupOnboardingText'));
const SetupAlertsPage = React.lazy(() => import('./pages/SetupText/SetupAlerts'));
const ProductCheckoutPage = React.lazy(() => import('./pages/ProductCheckout/ProductCheckout'));
const GetStartedShopPage = React.lazy(() => import('./pages/Shop/GetStartedShop'));
const GetStartedInitiatePage = React.lazy(() => import('./pages/GetStartedInitiate/GetStartedInitiate'));
const OnboardingReferralPage = React.lazy(() => import('./pages/OnboardingReferral/OnboardingReferral'));
const RotationPage = React.lazy(() => import('./pages/Rotation/Rotation'));
const LogoutPage = React.lazy(() => import('./pages/Logout/Logout'));
const InfluencerPage = React.lazy(() => import('./pages/Influencer/Influencer'));
const OpenShopPage = React.lazy(() => import('./pages/OpenShop/OpenShop'));
const GroceryShopPage = React.lazy(() => import('./pages/GroceryShop/GroceryShop'));
const GrocerySearchPage = React.lazy(() => import('./pages/GroceryShop/GrocerySearch'));
const GroceryCategoryPage = React.lazy(() => import('./pages/GroceryShop/GroceryCategoryPage'));
const RedeemGiftPage = React.lazy(() => import('./pages/RedeemGift/RedeemGift'));
const UpdatePasswordPage = React.lazy(() => import('./pages/UpdatePassword/UpdatePassword'));
const Instructions = React.lazy(() => import('./components/Instructions'));
const SlowLocalReOrderPage = React.lazy(() => import('./pages/SlowLocalReOrder/SlowLocalReOrderPage'));
const SocialReviewsPage = React.lazy(() => import('./pages/SocialReviews/SocialReviews'));
const LinkExpiredPage = React.lazy(() => import('./pages/LinkExpired'));
const NewMagicLinkSentPage = React.lazy(() => import('./pages/NewMagicLinkSent'));
const ChangeAccountPage = React.lazy(() => import('./pages/ChangeAccount'));
const SimilarProductsPage = React.lazy(() => import('./pages/SimilarProducts/SimilarProducts'));
const ShopifyAssignScalePage = React.lazy(() => import('./pages/ShopifyAssignScale'));
const ShopifyProductPage = React.lazy(() => import('./pages/ShopifyProduct'));
const ShopifyAddressPage = React.lazy(() => import('./pages/ShopifyAddress'));
const ShopifyCreditCardPage = React.lazy(() => import('./pages/ShopifyCreditCard'));
const DataShopify = React.lazy(() => import('./pages/Data/DataShopify'));
const ShopifyCustomerScaleAssignPage = React.lazy(() => import('./pages/ShopifyCustomerScaleAssign'));

export class App extends Component {
  constructor(props) {
    super(props);

    const { store, persistor } = createStore();
    this.store = store;
    this.persistor = persistor;
  }

  componentDidCatch(error, errorInfo) {
    const errorAlreadyExists = window.location.href.match(/error/);
    const isChunkLoadError = error.name === 'ChunkLoadError';
    const isFirstChunkError = isChunkLoadError && !errorAlreadyExists;

    if (process.env.NODE_ENV === 'production' && !isFirstChunkError) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);

        if ('hj' in window && hj.globals) scope.setExtra('hotjar_user_id', hj.globals.get('userId'));

        Sentry.captureException(error);
      });

      if ('hj' in window) {
        window.hj('tagRecording', ['Error']);
      }
    }

    if (!errorAlreadyExists) {
      if (!isChunkLoadError) {
        this.store.dispatch({ type: LogoutTypes.SUCCESS });
      }

      const sufix = window.location.href.match(/\?/) ? '&error' : '?error';
      window.location.replace(`${window.location.href}${sufix}`);
    } else if (!browserMatches()) {
      window.location.replace(`/update-browser.html`);
    }
  }

  render() {
    const { store, persistor } = this;

    return (
      <CookiesProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <VendorLockProvider>
              <Suspense fallback={<Loader fullPage />}>
                <Router>
                  <PageViewTracker />
                  <ReferralCookie>
                    <Switch>
                      <Redirect from="/landing.html" to="/" />
                      <Redirect from="/home.html" to="/" />
                      <Route path="/404-not-found" exact component={NotFoundPage} />
                      <Route path="/" exact component={HomeLandingRedirect} />
                      <Redirect path="/hello.html" exact to="/" />
                      <Redirect from="/main/index.html" exact to="/" />
                      <Redirect from="/landing-new/index.html" exact to="/" />
                      <Route path="/landing/index.html" exact component={HomeLanding} />
                      <Route path="/partners" exact component={PartnersRedirect} />
                      <Route path="/partners/index.html" exact component={PartnersRedirect} />
                      <Route path="/referral/:ref" exact component={ReferralRedirect} />
                      <Route path="/rate/:id" exact component={OrderRatePage} />
                      <Route path="/vendor/dashboard" component={VendorRoutes} />
                      <Route path="/fly" exact component={FlyLandingRedirect} />
                      <Route path="/deel" exact component={DeelLandingRedirect} />
                      <Route path="/twitter" exact component={TwitterLandingRedirect} />
                      <Route path="/sixtyOff" exact component={SixtyOffLandingRedirect} />
                      <Route path="/seventyOff" exact component={SeventyOffLandingRedirect} />
                      <Route path="/40_off" exact component={FortyOffLandingRedirect} />
                      <Route path="/50_off" exact component={FiftyOffLandingRedirect} />
                      <Route path="/50_off_postcard" exact component={FiftyOffPostcardLandingRedirect} />
                      <Route path="/50_off_sticker" exact component={FiftyOffStickerLandingRedirect} />
                      <Route path="/forwardthinking" exact component={ForwardThinkingLandingRedirect} />
                      <Route path="/lukeandjenna" exact component={LukeAndJennaLandingRedirect} />
                      <Route path="/coffee" exact component={CoffeeRedirect} />
                      <Route path="/coffee/index.html" exact component={CoffeeRedirect} />
                      <Route path="/pets" exact component={PetsRedirect} />
                      <Route path="/pets/index.html" exact component={PetsRedirect} />
                      <Route path="/gifts" exact component={GiftsPage} />
                      <Route path="/business" exact component={BusinessPage} />
                      <Route path="/login/:slug" exact component={VendorLockedLoginPage} />
                      <Route path="/login" exact component={LoginPage} />
                      <Route path="/forgot" exact component={ForgotPage} />
                      <Route from="/magic-login" exact component={MagicLoginPage} />
                      <Route path="/send-magic-link" exact component={MagicLinkPage} />
                      <Route path="/setup_password/:token" exact component={SetupPasswordPage} />
                      <Route path="/setup-credit-card" exact component={SetupCreditCardPage} />
                      <Route path="/setup-address" exact component={SetupAddressPage} />
                      <Route path="/setup_order_timing" exact component={SetupOrderTimingPage} />
                      <Route path="/setup_text" exact component={SetupOnboardingTextPage} />
                      <Route path="/similar-products" exact component={SimilarProductsPage} />
                      <Route path="/setup_alerts" exact component={SetupAlertsPage} />
                      <Route path="/filter_checkout" exact component={FilterCheckoutPage} />
                      <Route path="/filter_checkout/advanced" exact component={PublicFilterCheckoutEditPage} />
                      <Route path="/filter_checkout/:id" exact component={FilterCheckoutPage} />
                      <Route path="/list/:slug" exact component={RotationPage} />
                      <Route path="/share/:id" exact component={ShareHeatmap} />
                      <Redirect from="/heatmap/:id" exact to="/share/:id" />
                      <Redirect path="/checkout/:checkoutId" exact to="/get_started/:checkoutId" />
                      <Route path="/get_started/:checkoutId" exact component={GetStartedPage} />
                      <Route path="/get_started/:checkoutId/product" exact component={ProductCheckoutPage} />
                      <Route path="/get_started_shop/:checkoutId/:type?" exact component={GetStartedShopPage} />
                      <Route path="/get_started_complete" exact component={GetStartedCompletedPage} />
                      <Route path="/get-started-initiate" exact component={GetStartedInitiatePage} />
                      <Redirect from="/offboarded" exact to="/profile" />
                      <Route path="/onboarding_referral" exact component={OnboardingReferralPage} />
                      <Route path="/logout" exact component={LogoutPage} />
                      <Route path="/influencer/:id" exact component={InfluencerPage} />
                      <Route path="/admin" component={AdminRoutes} />
                      <Route path="/open_shop" exact component={OpenShopPage} />
                      <Route path="/grocery" exact component={GroceryShopPage} />
                      <Route path="/grocery-search" exact component={GrocerySearchPage} />
                      <Route path="/grocery/:category" exact component={GroceryCategoryPage} />
                      <Route path="/public_shop/:type?" exact component={PublicShopPage} />
                      <Redirect from="/custom-list-shop" exact to="/public_shop" />
                      <Route path="/products/:slug" exact component={ProductDetailsPage} />
                      <Route path="/redeem-gift/:code" exact component={RedeemGiftPage} />
                      <Route path="/reset-password/:token" exact component={UpdatePasswordPage} />
                      <Route path="/to_clipboard/referral/:id" exact component={ReferralClipboard} />
                      <Route
                        path="/order/:id/select"
                        exact
                        render={props => <OrderStatusPage {...props} isSelection={true} />}
                      />
                      <Route path="/order/:id/status/:productId/:variantId" exact component={OrderStatusPage} />
                      <Route path="/order/:id/status" exact component={OrderStatusPage} />
                      <Route path="/reviews/:id" exact component={ReviewPage} />
                      <Route from="/instructions" exact component={Instructions} />
                      <Route path="/shop/:type?" exact component={MainShopPage} />
                      <Route path="/slow-local-delivery/:id" exact component={SlowLocalDeliveryPage} />
                      <Route path="/grocery-order/product/:id/re-order" exact component={SlowLocalReOrderPage} />
                      <Route path="/social-reviews" exact component={SocialReviewsPage} />
                      <Route path="/history/:referralId" exact component={History} />
                      <Route path="/change-account" exact component={ChangeAccountPage} />
                      <Route path="/portal-select" exact component={ChangeAccountPage} />
                      <Route path="/shopify/assign-scale/:id" exact component={ShopifyAssignScalePage} />
                      <Route path="/shopify/product" exact component={ShopifyProductPage} />
                      <Route path="/shopify/address" exact component={ShopifyAddressPage} />
                      <Route path="/shopify/credit-card" exact component={ShopifyCreditCardPage} />
                      <Route path="/data/shopify" exact component={DataShopify} />
                      <Route path="/activate" exact component={ConnectLoginPage} />
                      <Route path="/activate" component={ScaleConnectRouter} />
                      <Redirect from="/setup-scale" exact to="/activate/success" />
                      <Route path="/shopify/scales/assign/:id" exact component={ShopifyCustomerScaleAssignPage} />
                      <Route
                        path="/(grocery-order|grocery-order/[a-f\d]{24}|profile|pause|close_account|hibernate|custom_product|data|free-bag|account|variant_switch|update-stripe|charges|subscription/new|bring-your-own|variant_switch/[a-f\d]{24}|scales/assign/[a-f\d]{24}|orders|pets-pilot-program|haf-pilot-program)"
                        exact
                        component={PanelRoutes}
                      />
                      <Redirect from="/products/:id/buy" exact to="/products/:id" />
                      <Redirect from="/products/:slug/buy" exact to="/products/:slug" />
                      <Redirect from="/athletic-greens" exact to="/404-not-found?url=%2Fathletic-greens" />
                      <Route path="/link-expired" exact component={LinkExpiredPage} />
                      <Route path="/new-link" exact component={NewMagicLinkSentPage} />
                      <Route path="/:slug" component={FallbackRouter} />
                    </Switch>
                  </ReferralCookie>
                </Router>
              </Suspense>
            </VendorLockProvider>
          </PersistGate>
        </Provider>
      </CookiesProvider>
    );
  }
}
