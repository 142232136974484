import React from 'react';
import PropTypes from 'prop-types';

export const BuildCard = ({ name, textClassName, emoji, className }) => (
  <div
    className={`d-flex flex-row align-items-center justify-content-lg-start justify-content-center ${className || ''}${
      emoji ? ' flex-column' : ''
    }`}
  >
    {emoji && <span>{emoji}</span>}
    <div className={`answer-card-text ${textClassName || ''}${emoji ? ' mt-2 text-center' : ''}`}>{name}</div>
  </div>
);

BuildCard.propTypes = {
  name: PropTypes.string.isRequired,
  textClassName: PropTypes.string,
  emoji: PropTypes.node,
  className: PropTypes.string,
};
