import { useEffect, useState } from "react";

export const useOnce = (effect, deps = []) => {
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (done || deps.filter(Boolean).length !== deps.length) {
      return;
    }

    setDone(true);
    effect();
  }, deps);
};
