import { useMemo } from 'react';
import { useMe } from '../../../hooks/useMe.hook';
import { VENDOR_IDS } from '../../../constants/vendor';

export const useIsAg1 = () => {
  const me = useMe();

  const isAg1 = useMemo(() => me?.vendor_id?._id === VENDOR_IDS.AG1, [me]);

  return isAg1;
};
