import React, { useMemo } from 'react';
import isNil from 'lodash-es/isNil';
import PropTypes from 'prop-types';
import { Row, Col, Card, Container } from 'reactstrap';
import { DataLoading, Price } from '@bottomless/common/components';
import { useSelector } from 'react-redux';
import { QuizHeader } from './QuizHeader';
import { QuestionText } from './QuestionText';

const answerLabels = [
  {
    name: 'Basic',
    event: {
      price_point: 'basic',
    },
  },
  {
    name: 'Standard',
    event: {
      price_point: 'standard',
    },
  },
  {
    name: 'Premium',
    event: {
      price_point: 'Premium',
    },
  },
];

const selectMe = ({ user }) => user.me;

export const Question3 = ({
  index,
  total,
  onAnswer,
  answers: quizAnswers,
  personalized,
  simpleDynamicPricing,
  pricingRule,
}) => {
  const sizeAnswer = useMemo(() => Object.values(quizAnswers).find(answer => answer.includes('size')), [quizAnswers]);
  const size = useMemo(
    () =>
      sizeAnswer
        ? Number(
            sizeAnswer
              .match(/size:(\d+)/)
              ?.slice(1)
              .shift() || 12
          )
        : 12,
    [sizeAnswer]
  );
  const me = useSelector(selectMe);
  const isLoggedIn = !!me;

  const answers = useMemo(() => {
    if (!simpleDynamicPricing || !personalized.roast) {
      return [];
    }

    return simpleDynamicPricing
      .filter(({ size: pricingSize, roast }) => roast === personalized.roast[0] && Number(pricingSize) === Number(size))
      .map(pricing => ({ ...pricing, value: `price_type:${pricing.type};size:${size}` }));
  }, [personalized, simpleDynamicPricing, size]);

  const isFirstBag = useMemo(
    () =>
      (pricingRule?.first_bag_discount || pricingRule?.first_bag_multiplier || !isNil(pricingRule?.first_bag_price)) &&
      !isLoggedIn,
    [pricingRule, isLoggedIn]
  );

  return (
    <>
      <QuizHeader index={index - 1} total={total - 1} quizAnswers={quizAnswers} />
      <QuestionText
        index={index - 1}
        total={total - 1}
        title="Pricing Tier"
        subtitle="Pick the pricing tier you'd like to pay for each bag"
      />
      <Container className="mt-5">
        <Row className="justify-content-center">
          <div className="text-center w-100">
            <DataLoading count={answers.length} isLoading={!answers.length} loadingText="Loading prices" />
          </div>

          {answers.map((answer, i) => (
            <Col key={i} xs="6" sm="6" lg="3">
              <Card
                body
                tag="button"
                className="d-flex flex-column align-items-center justify-content-center w-100 mb-4 answer"
                onClick={() => onAnswer(answer)}
              >
                <p className="mb-0">{answerLabels[i % 3].name}</p>
                <div>
                  <Price
                    value={answer.price}
                    pricingRule={pricingRule}
                    isFirstBag={isFirstBag}
                    size={Number(answer.size)}
                    discountClass="discounted-price"
                  />
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

Question3.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onAnswer: PropTypes.func.isRequired,
  pricingRule: PropTypes.object,
  answers: PropTypes.object.isRequired,
  only32: PropTypes.bool,
  personalized: PropTypes.object.isRequired,
  simpleDynamicPricing: PropTypes.arrayOf(
    PropTypes.shape({
      roast: PropTypes.string.isRequired,
      size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      price: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    })
  ),
};

Question3.pricingRelated = true;
