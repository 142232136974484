import React from 'react';
import PropTypes from 'prop-types';
import { Container, Alert } from 'reactstrap';

export const Banner = ({ bannerText }) => {
  return (
    <Container className="my-3">
      <Alert color="info">
        <div className="text-center">
          <div>{bannerText}</div>
        </div>
      </Alert>
    </Container>
  );
};

Banner.propTypes = {
  bannerText: PropTypes.string,
};
