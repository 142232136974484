import React, { useState, useMemo, useEffect, useRef } from 'react';
import isNil from 'lodash-es/isNil';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Loader } from 'react-feather';
import { Form, Field } from '@bottomless/common/components';
import { EVENT_APPLE_PAY, trackEvent } from '../../../utils/tracker';
import { injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { calculateCheckoutShippingAction } from '../../../store';
import { singletonAction } from '@bottomless/common/store';

const BillingSchema = Yup.object().shape({
  token: Yup.string().required('This field is required'),
});

const ExpressCheckoutComponent = ({
  stripe,
  checkout,
  onChange,
  finishCheckout,
  onPaymentSuccess,
  setAddress,
  setOpen,
  addToast,
  calculateCheckoutShipping,
}) => {
  const form = useRef();
  const [canMakePayment, setCanMakePayment] = useState(false);
  const [error, setError] = useState(null);
  const [isProcessing, setProcessing] = useState(false);

  const product = checkout.product && checkout.product.product;

  const variant = product && product.variants.find(variant => variant._id === checkout.product.variant);
  const total = variant && getTotal(variant, product, checkout.pricing_rule, checkout.dynamicPricing, checkout);

  const paymentRequest = useMemo(
    () =>
      stripe && total
        ? stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: { label: 'Total', amount: total.total },
            requestShipping: true,
            shippingOptions: [
              {
                id: 'basic',
                label: 'Shipped from roaster via USPS',
                amount: total.shipping || 0,
              },
            ],
            displayItems: [
              { label: 'Cost', amount: total.product },
              { label: 'Shipping', amount: total.shipping || 0 },
              { label: 'Signup fee', amount: total.signup },
            ],
          })
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stripe]
  );

  useEffect(() => {
    try {
      if (paymentRequest) {
        paymentRequest.on('shippingaddresschange', async event => {
          if (!isNil(total.shipping)) {
            return event.updateWith({ status: 'success' });
          }

          try {
            const { payload, error } = await calculateCheckoutShipping(checkout._id, {
              verifiedAddress: {
                street1: event.shippingAddress?.addressLine[0] || 'Unknown',
                city: event.shippingAddress?.city,
                zip: event.shippingAddress?.postalCode,
                state: event.shippingAddress?.region,
              },
            });

            if (error || isNil(payload.shippingPrice)) {
              throw new Error("Couldn't determine the shipping price");
            }

            const newTotal = total.recalculate(payload.shippingPrice);

            event.updateWith({
              status: 'success',
              total: { label: 'Total', amount: newTotal },
              shippingOptions: [
                {
                  id: 'basic',
                  label: 'Shipped from roaster via USPS',
                  amount: payload.shippingPrice,
                },
              ],
              displayItems: [
                { label: 'Cost', amount: total.product },
                { label: 'Shipping', amount: payload.shippingPrice },
                { label: 'Signup fee', amount: total.signup },
              ],
            });
          } catch (e) {
            event.updateWith({ status: 'invalid_shipping_address' });
          }
        });

        paymentRequest.on('token', ({ complete, token, shippingAddress }) => {
          setProcessing(true);
          setOpen(false);
          form.current.setFieldValue('token', token.id);
          trackEvent(EVENT_APPLE_PAY, { id: checkout._id });
          (async () => {
            try {
              const first_name = shippingAddress?.recipient
                ?.split(' ')
                .slice(0, -1)
                .join(' ');
              const last_name = shippingAddress?.recipient
                ?.split(' ')
                .slice(-1)
                .join(' ');

              const verifiedAddress = {
                street1: shippingAddress?.addressLine[0],
                street2: shippingAddress?.addressLine[1],
                city: shippingAddress?.city,
                zip: shippingAddress?.postalCode,
                state: shippingAddress?.region,
              };
              await setAddress({ first_name, last_name, verifiedAddress });
              await onChange({ token: token.id });
              const { error } = await finishCheckout();

              if (!error) {
                complete('success');
                return onPaymentSuccess();
              }

              complete('fail');
            } catch (e) {
              complete('fail');
              addToast(e.message, 'danger');

              setError(e.details ? Object.values(e.details).join(' ') : e.message);
              Sentry.captureException(new Error(`Payment failed on Express Checkout. Error - ${e.message}`), {
                extra: { error: e },
              });
            }
            setOpen(true);
            setProcessing(false);
          })();
        });

        (async () => {
          try {
            const result = await paymentRequest.canMakePayment();
            setCanMakePayment(!!result);
          } catch (e) {
            setCanMakePayment(false);
          }
        })();
      }
    } catch (e) {
      setCanMakePayment(false);
      Sentry.captureException(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequest, setCanMakePayment]);

  return (
    <section className="text-alerts">
      <Form innerRef={form} initialValues={{ token: '' }} validationSchema={BillingSchema} onSubmit={() => {}}>
        {() => (
          <>
            {canMakePayment && (
              <div className="d-flex flex-column align-items-center">
                {!isProcessing && (
                  <div className="mb-2 express-line">
                    <div className="express-line-content mb-2">Express Checkout</div>
                    <div className="express-buttons d-flex flex-column align-items-center justify-content-center">
                      <PaymentRequestButtonElement paymentRequest={paymentRequest} className="PaymentRequestButton" />
                      {error && <div className="text-sm text-danger text-center mt-1">{error}</div>}
                      <div className="text-center small text-secondary mt-2">
                        * The address you choose will be your shipping address
                      </div>
                    </div>
                  </div>
                )}
                {isProcessing && (
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    Processing... <Loader size="13" className="spin ml-2" />
                  </div>
                )}
                {!isProcessing && (
                  <div className="mt-3 mb-3 or-line-seperator">
                    <span className="or-line-content small text-secondary">OR</span>
                  </div>
                )}
              </div>
            )}
            <Field type="hidden" name="token" />
          </>
        )}
      </Form>
    </section>
  );
};

const getTotal = (variant, orderProduct, pricingRule, dynamicPricing, checkout) => {
  const signup = pricingRule.signup_fee || 0;
  const shipping = getShippingPrice(pricingRule, variant, dynamicPricing, !!checkout.vendor_id);
  const product = getProductPrice(pricingRule, variant, orderProduct, dynamicPricing, !!checkout.vendor_id);
  const total = signup + product + (shipping || 0);

  const recalculate = shippingPrice => signup + product + shippingPrice;

  return { signup, product, shipping, total, recalculate };
};

const getProductPaymentPrice = (variant, dynamicPricing, isVendorLocked) => {
  const price = dynamicPricing ? dynamicPricing.price : isVendorLocked ? variant.website_pricing : variant.price;
  const productPriceInCents = Math.round(Number(price) * 100); // convert to cents

  return productPriceInCents;
};

const getProductPrice = (pricingRule, variant, product, dynamicPricing, useWebsitePrice) => {
  const productCost = getProductPaymentPrice(variant, dynamicPricing, useWebsitePrice);

  if (!isNil(pricingRule.first_bag_price) && variant.size < 17) {
    return pricingRule.first_bag_price;
  }

  if (pricingRule.first_bag_discount) {
    return Math.round(productCost * (pricingRule.first_bag_discount || 1));
  }

  if (pricingRule.discount_fixed) {
    return Math.max(0, productCost - pricingRule.discount_fixed);
  }

  if (pricingRule.discount_proportion) {
    return Math.round(productCost * pricingRule.discount_proportion);
  }

  return productCost;
};

const getShippingPrice = (pricingRule, variant, dynamicPricing, isVendorLocked) => {
  if (!isNaN(pricingRule.first_shipping_price)) {
    return pricingRule.first_shipping_price;
  }

  const variantPaymentPrice = getProductPrice(pricingRule, variant, null, dynamicPricing, isVendorLocked);
  if (
    isVendorLocked &&
    !isNaN(pricingRule.free_shipping_threshold) &&
    variantPaymentPrice >= pricingRule.free_shipping_threshold
  ) {
    return 0;
  }

  if (pricingRule.free_shipping) {
    return 0;
  }

  if (pricingRule.shipping_price) {
    return pricingRule.shipping_price;
  }

  return null;
};

ExpressCheckoutComponent.propTypes = {
  checkout: PropTypes.object.isRequired,
  stripe: PropTypes.shape({
    createToken: PropTypes.func.isRequired,
    paymentRequest: PropTypes.func.isRequired,
  }),
  onChange: PropTypes.func,
  total: PropTypes.shape({
    total: PropTypes.number.isRequired,
    product: PropTypes.number.isRequired,
    shipping: PropTypes.number.isRequired,
    signup: PropTypes.number.isRequired,
  }),
  finishCheckout: PropTypes.func.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  calculateCheckoutShipping: PropTypes.func.isRequired,
};

// export const ExpressCheckout = injectStripe(ExpressCheckoutComponent);

export const ExpressCheckout = connect(null, dispatch => ({
  calculateCheckoutShipping: (checkoutId, data) =>
    dispatch(singletonAction(calculateCheckoutShippingAction(checkoutId, data), 'express-checkout')),
}))(injectStripe(ExpressCheckoutComponent));
