import React, { Suspense, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as qs from 'query-string';
import { connect, useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDataEffect, useToggle } from '@bottomless/common/src/hooks';
import { DataLoading, Image, Loader } from '@bottomless/common/components';
import { CustomList } from '../../../components/UpNext';
import {
  deleteCustomListItemAction,
  getCustomListAction,
  moveCustomListItemAction,
  updateCustomListAction,
} from '../../../store';
import { EVENT_REMOVE_FROM_CUSTOM_LIST, EVENT_REORDER_CUSTOM_LIST, trackEvent } from '../../../utils/tracker';
import './QueueBar.scss';

const AVATARS_AMOUNT = 5;

const QueueBarComponent = ({
  me,
  customList = [],
  getCustomList,
  updateCustomList,
  moveCustomListItem,
  deleteCustomListItem,
}) => {
  if (!Array.isArray(customList)) {
    customList = [];
  }
  const location = useLocation();
  const query = qs.parse(location.search);
  const store = useStore();
  const [isCustomOpen, toggleCustom] = useToggle(query.editQueue);

  const { isFetching } = useDataEffect(getCustomList);

  const avatarElements = useMemo(() => customList.slice(0, AVATARS_AMOUNT).reverse(), [customList]);
  const restElementsAmount = useMemo(() => customList.length - avatarElements.length, [customList, avatarElements]);

  const handleCustomListUpdate = useCallback(
    data => {
      const products = store
        .getState()
        .product.wishlist.map(({ product, variant, grind }) => ({ product: product._id, variant, grind: grind?._id }));

      return updateCustomList({
        products: products,
        timestamp: Date.now(),
        indexChange: data,
      });
    },
    [updateCustomList, store]
  );

  const onMoveCustomListItem = useCallback(
    async (...data) => {
      moveCustomListItem(...data);
      const result = await handleCustomListUpdate(data);
      trackEvent(EVENT_REORDER_CUSTOM_LIST);
      return result;
    },
    [moveCustomListItem, handleCustomListUpdate]
  );

  const onDeleteCustomListItem = useCallback(
    async (...data) => {
      deleteCustomListItem(...data);
      const result = await handleCustomListUpdate();
      trackEvent(EVENT_REMOVE_FROM_CUSTOM_LIST);
      return result;
    },
    [deleteCustomListItem, handleCustomListUpdate]
  );

  return (
    <>
      {customList?.length > 0 && (
        <div className="py-2 queue-bar bg-white">
          <Container className="d-flex justify-content-between align-items-center">
            <>
              <div>
                <div className="small font-weight-bold mb-1 ml-1">Queue</div>
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-row-reverse queue-avatars">
                    {avatarElements.map(element => (
                      <div key={element._id} className="p-1 bg-white border rounded-circle queue-avatar">
                        <Image src={element.product.small_image_src} alt={element.product.name} height="30" />
                      </div>
                    ))}
                  </div>
                  {restElementsAmount > 0 && (
                    <div className="text-secondary queue-more-items">+{restElementsAmount}</div>
                  )}
                </div>
              </div>
              <div>
                {customList && (
                  <Button color="primary" size="sm" outline onClick={toggleCustom}>
                    Edit
                  </Button>
                )}
              </div>
            </>
          </Container>
        </div>
      )}
      <Modal isOpen={isCustomOpen} toggle={toggleCustom} size="lg" className="custom-list-modal">
        <ModalHeader toggle={toggleCustom}>Edit Your Queue</ModalHeader>
        <ModalBody className="d-flex flex-column justify-content-between">
          <DataLoading count={customList.length} isLoading={isFetching} loadingText="Loading queue" />
          <Suspense fallback={<Loader />}>
            <CustomList
              withAddNew={false}
              wishlist={customList}
              deleteCustomListItem={onDeleteCustomListItem}
              moveCustomListItem={onMoveCustomListItem}
              productPricingRule={me.pricing_rule}
              me={me}
            />
            <Button onClick={toggleCustom} color="dark" className="d-block d-md-none mt-2">
              Close
            </Button>
          </Suspense>
        </ModalBody>
      </Modal>
    </>
  );
};

QueueBarComponent.propTypes = {
  customList: PropTypes.array.isRequired,
  getCustomList: PropTypes.func.isRequired,
  me: PropTypes.shape({ pricing_rule: PropTypes.object }).isRequired,
  updateCustomList: PropTypes.func.isRequired,
  moveCustomListItem: PropTypes.func.isRequired,
  deleteCustomListItem: PropTypes.func.isRequired,
};

export const QueueBar = connect(
  ({ user, product: { wishlist, data } }) => ({
    me: user.me,
    customList: wishlist || [],
    products: data,
  }),
  dispatch => ({
    getCustomList: () => dispatch(getCustomListAction()),
    updateCustomList: data => dispatch(updateCustomListAction(data)),
    moveCustomListItem: (sourceIndex, destinationIndex) =>
      dispatch(moveCustomListItemAction(sourceIndex, destinationIndex)),
    deleteCustomListItem: index => dispatch(deleteCustomListItemAction(index)),
  })
)(QueueBarComponent);
