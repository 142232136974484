import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetStartedTypes = createAction('Auth', 'Get started');

export const getStartedAction = data => ({
  [RSAA]: {
    endpoint: 'auth/get-started',
    method: 'POST',
    body: {
      slug: window.location.pathname,
      query: window.location.search,
      ...data,
    },
    types: [GetStartedTypes.REQUEST, GetStartedTypes.SUCCESS, GetStartedTypes.FAILURE],
  },
});

export const GetStartedGiftTypes = createAction('Auth', 'Get started gift');

export const getStartedGiftAction = data => ({
  [RSAA]: {
    endpoint: 'auth/get-started-gift',
    method: 'POST',
    body: data,
    types: [GetStartedGiftTypes.REQUEST, GetStartedGiftTypes.SUCCESS, GetStartedGiftTypes.FAILURE],
  },
});

export const GetStartedCompleteTypes = createAction('Auth', 'Get started complete');

export const getStartedCompleteAction = () => ({
  [RSAA]: {
    endpoint: 'auth/get-started-complete',
    method: 'POST',
    body: {},
    types: [GetStartedCompleteTypes.REQUEST, GetStartedCompleteTypes.SUCCESS, GetStartedCompleteTypes.FAILURE],
  },
});

export const LoginTypes = createAction('Auth', 'Login');

export const loginAction = data => ({
  [RSAA]: {
    endpoint: 'auth/login',
    method: 'POST',
    body: data,
    types: [LoginTypes.REQUEST, LoginTypes.SUCCESS, LoginTypes.FAILURE],
  },
});

export const SaveLandingPageTypes = createAction('Auth', 'Landing page');

export const saveLandingPageAction = data => ({
  [RSAA]: {
    endpoint: 'auth/landing-page',
    method: 'POST',
    body: { ...data },
    types: [SaveLandingPageTypes.REQUEST, SaveLandingPageTypes.SUCCESS, SaveLandingPageTypes.FAILURE],
  },
});

export const SaveLastLandingRuleTypes = createAction('Auth', 'Last landing rule');

export const saveLastLandingRuleAction = variant => ({
  [RSAA]: {
    endpoint: 'auth/last-landing-rule',
    method: 'POST',
    types: [SaveLastLandingRuleTypes.REQUEST, SaveLastLandingRuleTypes.SUCCESS, SaveLastLandingRuleTypes.FAILURE],
    ...(variant !== undefined ? { body: { variant } } : {}),
  },
});

export const SetupPasswordActionTypes = createAction('Auth', 'Setup password');

export const setupPasswordAction = (token, data) => ({
  [RSAA]: {
    endpoint: `auth/setup-password/${token}`,
    method: 'POST',
    body: data,
    types: [SetupPasswordActionTypes.REQUEST, SetupPasswordActionTypes.SUCCESS, SetupPasswordActionTypes.FAILURE],
  },
});

export const ResetPasswordActionTypes = createAction('Auth', 'Reset password');

export const resetPasswordAction = (token, data) => ({
  [RSAA]: {
    endpoint: `auth/reset-password/${token}`,
    method: 'POST',
    body: data,
    types: [ResetPasswordActionTypes.REQUEST, ResetPasswordActionTypes.SUCCESS, ResetPasswordActionTypes.FAILURE],
  },
});

export const ForgotPasswordTypes = createAction('Auth', 'Forgot password');

export const forgotPasswordAction = data => ({
  [RSAA]: {
    endpoint: 'auth/forgot-password',
    method: 'POST',
    body: data,
    types: [ForgotPasswordTypes.REQUEST, ForgotPasswordTypes.SUCCESS, ForgotPasswordTypes.FAILURE],
  },
});

export const SendMagicLinkTypes = createAction('Auth', 'Send magic link');

export const sendMagicLinkAction = data => ({
  [RSAA]: {
    endpoint: 'auth/magic-link',
    method: 'POST',
    body: data,
    types: [SendMagicLinkTypes.REQUEST, SendMagicLinkTypes.SUCCESS, SendMagicLinkTypes.FAILURE],
  },
});

export const GetPortalRedirectTypes = createAction('Auth', 'Get portal redirect');

export const getPortalRedirectAction = () => ({
  [RSAA]: {
    endpoint: 'auth/portal-redirect',
    method: 'GET',
    types: [GetPortalRedirectTypes.REQUEST, GetPortalRedirectTypes.SUCCESS, GetPortalRedirectTypes.FAILURE],
  },
});

export const CreateRecommendationTypes = createAction('Auth', 'Creacte recommendation');

export const createRecommendationAction = data => ({
  [RSAA]: {
    endpoint: 'auth/personalized-recommendation',
    method: 'POST',
    body: data,
    types: [CreateRecommendationTypes.REQUEST, CreateRecommendationTypes.SUCCESS, CreateRecommendationTypes.FAILURE],
  },
});

export const SaveReferralTypes = createAction('Auth', 'Change scale');

export const saveReferralAction = data => ({
  [RSAA]: {
    endpoint: 'auth/save-referral',
    method: 'POST',
    body: data,
    types: [SaveReferralTypes.REQUEST, SaveReferralTypes.SUCCESS, SaveReferralTypes.FAILURE],
  },
});

export const SSOAuthTypes = createAction('Auth', 'SSOAuth');

export const ssoAuthAction = data => ({
  [RSAA]: {
    endpoint: 'auth/sso',
    method: 'POST',
    body: data,
    types: [SSOAuthTypes.REQUEST, SSOAuthTypes.SUCCESS, SSOAuthTypes.FAILURE],
  },
});
