import React, { memo, useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@bottomless/common/components';
import {
  getStartedAction,
  getProductsAction,
  getPricingRuleAction,
  getFourSigmaticProductsAction,
  setShipmentAction,
} from '../../store';
import { withMetaTags } from '../../components/MetaTags/MetaTags';
import { EVENT_CTA_CLICK, trackEvent } from '../../utils/tracker';
import { Simple } from '../../layouts/Simple/Simple';
import {
  MainBannerSection,
  ProductsSection,
  FeaturesSection,
  HowItWorksSection,
  FaqSection,
  ProductTilesSection,
  MeetTheScaleSection,
  BringSmartSection,
  TryItRiskFreeSection,
  BestPriceSection,
  ContainerSection,
} from './components';
import './VendorLandingPage.scss';

const domain = process.env.REACT_APP_COOKIE_DOMAIN;

const VendorLandingPageComponent = ({
  vendor,
  getProducts,
  products,
  history,
  me,
  getPricingRule,
  getFourSigmaticProducts,
  getStarted,
  setShipment,
}) => {
  const location = useLocation();
  const [, setCookie] = useCookies();
  const [pricingRule, setPricingRule] = useState(null);
  const hasCustomStyles = useMemo(() => (vendor?.custom_styles ? !!Object.keys(vendor.custom_styles).length : false), [
    vendor,
  ]);
  const hasBringSmart = useMemo(() => !!vendor?.custom_styles?.images?.bringSmart, [vendor]);
  const hasRiskFree = useMemo(() => !!vendor?.custom_styles?.images?.riskFree, [vendor]);
  const hasBestPrice = useMemo(() => !!vendor?.custom_styles?.images?.bestPrice, [vendor]);
  const hasContainer = useMemo(() => !!vendor?.custom_styles?.images?.container, [vendor]);

  useEffect(() => {
    if (vendor) {
      if (vendor.landing_page === '/four-sigmatic') {
        getFourSigmaticProducts();
      } else {
        getProducts({ vendorId: vendor._id });
      }
    }
  }, [getFourSigmaticProducts, getProducts, vendor]);

  useEffect(() => {
    if (!me) {
      (async () => {
        let { payload: pricingRule } = await getPricingRule();

        if (vendor.landing_page === '/westbean-coffee-roasters') {
          setCookie('last_landing_rule', 'westbean-coffee-roasters', { domain });
          pricingRule = (await getPricingRule()).payload;
        } else if (vendor.landing_page === '/four-sigmatic') {
          setCookie('last_landing_rule', 'four-sigmatic', { domain });
          pricingRule = (await getPricingRule()).payload;
        } else if (pricingRule.default) {
          setCookie('landing_page', 'vendor_landing', { domain });
          setCookie('last_landing_rule', 'vendor_landing', { domain });
          pricingRule = (await getPricingRule()).payload;
        }

        setPricingRule(pricingRule);
      })();
    } else {
      setPricingRule(me.pricing_rule);
    }
  }, [getPricingRule, me, setCookie, vendor]);

  const categorySlug = products && products[0] && products[0].category.slug;

  const navigateToShop = useCallback(
    ({ position }) => {
      trackEvent(EVENT_CTA_CLICK, { position });
      history.push(
        `public_shop?vendor_id=${vendor._id}${categorySlug ? `&category=${categorySlug}` : ''}&back=${
          location.pathname
        }`
      );
    },
    [categorySlug, history, location.pathname, vendor]
  );

  const onClickGetStarted = () => {
    trackEvent(EVENT_CTA_CLICK, { position: 'vendor-get-started' });
  };

  return (
    <ErrorBoundary>
      <Simple withFooter className="page-vendor-landing" categorySlug={categorySlug}>
        <MainBannerSection
          vendor={vendor}
          navigateToShop={navigateToShop}
          products={products}
          hasCustomStyles={hasCustomStyles}
          onClickGetStarted={onClickGetStarted}
        />
        <hr className="horizontal-line vendor-details" />
        <HowItWorksSection hasCustomStyles={hasCustomStyles} vendor={vendor} />
        {!hasCustomStyles && (
          <>
            <hr className="horizontal-line" />
            <MeetTheScaleSection />
          </>
        )}
        <ProductTilesSection products={products} history={history} navigateToShop={navigateToShop} />
        <hr className="horizontal-line" />
        <FeaturesSection hasCustomStyles={hasCustomStyles} vendor={vendor} />
        {hasBringSmart && (
          <>
            <hr className="horizontal-line" />
            <BringSmartSection vendor={vendor} />
            <hr className="horizontal-line" />
          </>
        )}
        {hasRiskFree && (
          <>
            <TryItRiskFreeSection vendor={vendor} />
          </>
        )}
        <hr className="horizontal-line" id="products" />
        <ProductsSection
          products={products}
          history={history}
          navigateToShop={navigateToShop}
          pricingRule={pricingRule}
          isFirstBag={!me ? true : false}
          getStarted={getStarted}
          setShipment={setShipment}
          hasCustomStyles={hasCustomStyles}
          me={me}
        />
        <hr className="horizontal-line" />
        {hasBestPrice && (
          <>
            <BestPriceSection vendor={vendor} />
            <hr className="horizontal-line" />
          </>
        )}
        {hasContainer && (
          <>
            <ContainerSection vendor={vendor} />
            <hr className="horizontal-line" />
          </>
        )}
        <FaqSection onClickGetStarted={onClickGetStarted} />
      </Simple>
    </ErrorBoundary>
  );
};

VendorLandingPageComponent.propTypes = {
  vendor: PropTypes.object.isRequired,
  getProducts: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  me: PropTypes.object,
  getPricingRule: PropTypes.func.isRequired,
  getFourSigmaticProducts: PropTypes.func.isRequired,
  getStarted: PropTypes.func.isRequired,
  setShipment: PropTypes.func.isRequired,
};

export const VendorLandingPage = connect(
  ({ vendor, user: { me }, product }, { match }) => {
    const vendorDetails = vendor.data.find(v => v.landing_page === match.url);

    return {
      vendor: vendorDetails,
      me,
      products: product?.data?.filter(prod => String(prod.vendor_id?._id) === String(vendorDetails?._id)),
    };
  },
  dispatch => ({
    getStarted: data => dispatch(getStartedAction(data)),
    getProducts: data => dispatch(getProductsAction(data)),
    getPricingRule: () => dispatch(getPricingRuleAction()),
    getFourSigmaticProducts: () => dispatch(getFourSigmaticProductsAction()),
    setShipment: (id, data) => dispatch(setShipmentAction(id, data)),
  })
)(
  withMetaTags({
    title: ({ vendor }) => (vendor ? `Bottomless.com: ${vendor.name}` : undefined),
    description: ({ vendor }) => (vendor ? vendor.description : undefined),
    image: ({ vendor }) => (vendor ? (vendor.img_url || '').replace(/\?.*$/, '') : undefined),
  })(memo(VendorLandingPageComponent))
);
