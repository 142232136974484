import React, { memo } from 'react';
import PropTypes from 'prop-types';

const AdvanceBuilderHeadingComponent = ({ title, subtitle }) => {
  return (
    <div className="d-flex align-items-center">
      <h3 className="mb-0 mr-2">{title}</h3>
      {subtitle && <span className="text-secondary">({subtitle})</span>}
    </div>
  );
};

AdvanceBuilderHeadingComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export const AdvanceBuilderHeading = memo(AdvanceBuilderHeadingComponent);
