import React, { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Button } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getIsMondayToThursday } from '../../../../../utils/dates';
import { InfoTooltip, Image } from '@bottomless/common/components';

export const ProductDescriptionComponent = ({ product, me }) => {
  const isMondayToThursday = getIsMondayToThursday();
  const [copied, setCopied] = useState(false);

  const ingredientsText = useMemo(() => {
    const { ingredients } = product?.attributes || {};
    if (Array.isArray(ingredients)) {
      return ingredients.join(', ');
    }

    return ingredients;
  }, [product]);

  return (
    <Row className="mt-5 mb-5">
      <Col xs="12" md="6">
        {product?.description && (
          <>
            <h6 className="mb-3">Description</h6>
            <div className="mb-5" dangerouslySetInnerHTML={{ __html: product.description }} />
          </>
        )}
        {product?.attributes?.nutrition_info_img?.trim() && (
          <>
            <h6 className="mb-3">Nutrition</h6>
            <div className="mb-5">
              <Image src={product.attributes.nutrition_info_img} alt="nutrition info" height="600" />
            </div>
          </>
        )}
        {product?.attributes?.ingredients && (
          <>
            <h6 className="mb-3">Ingredients</h6>
            <div className="mb-5">{ingredientsText}</div>
          </>
        )}
      </Col>
      <Col xs="12" md="6" className="delivery-info">
        {!me && (
          <>
            <h6 className="mb-3">Delivery Information</h6>
            <div className="delivery-text mb-5">
              <div className="ship-text-wrap mb-3">
                <div className="ship-text rotation d-flex align-items-center font-weight-bold">
                  <div className="ship-description rotation__text">
                    {isMondayToThursday
                      ? 'Order now for delivery tomorrow 3 pm - 6 pm'
                      : 'Order now for delivery Monday 3 pm - 6 pm'}
                  </div>
                  <InfoTooltip className="ml-1" size="sm">
                    Get free shipping via USPS, arrives in 3 days.
                  </InfoTooltip>
                </div>
              </div>
              You will receive the Bottomless smart scale along with your first bag. This scale tracks your consumption
              and re-orders your next {product.name} bags at the perfect time.
            </div>
          </>
        )}
        <h6 className="mb-3">Share this Product with friends and family</h6>
        <div className="d-flex invite-link-copy">
          <Input
            className="invite-link mr-2 border px-2"
            type="text"
            readOnly
            value={window.location.href}
            aria-label="Share link"
            autocomplete="off"
          />
          <CopyToClipboard text={window.location.href} onCopy={() => setCopied(true)}>
            <Button color="primary" outline>
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          </CopyToClipboard>
        </div>
      </Col>
    </Row>
  );
};

ProductDescriptionComponent.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    attributes: PropTypes.object,
  }),
  me: PropTypes.object,
};

export const ProductDescription = memo(ProductDescriptionComponent);
