import { RSAA } from 'redux-api-middleware';
import { createAction } from '../util';

export const GetUserVendorTypes = createAction('Users', 'Get User Vendor');

export const getUserVendorAction = id => ({
  [RSAA]: {
    endpoint: `vendors/id/${id}`,
    method: 'GET',
    types: [GetUserVendorTypes.REQUEST, GetUserVendorTypes.SUCCESS, GetUserVendorTypes.FAILURE],
  },
});

export const CLEAR_USER_VENDOR = 'Users/Clear vendor';

export const clearUserVendorAction = () => ({
  type: CLEAR_USER_VENDOR,
});
