import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

export const ErrorAction = () => {
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push('/filter_checkout');
    window.location.reload();
  }, [history]);

  return (
    <Button onClick={onClick} size="sm" color="success">
      Try again
    </Button>
  );
};
