import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { Form } from '@bottomless/common/components';
import { FilterAccordion } from '../../components/FilterAccordion';
import { fromOz } from '@bottomless/common/utils';

const FiltersSchema = Yup.object().shape({
  roast: Yup.array(),
  size: Yup.array(),
  origin: Yup.array(),
  roaster: Yup.array(),
  tag: Yup.array(),
  type: Yup.array(),
  process: Yup.array(),
  tasting_notes: Yup.array(),
});

export const CoffeeFilters = ({
  onChange,
  products,
  vendorId,
  changeBatch,
  filters,
  onClose,
  featuredTags,
  showRoasterFilter,
  attributes,
  clearFilters,
}) => {
  const initialValues = useMemo(
    () => ({
      roast: [],
      size: [],
      origin: [],
      state: [],
      vendor_id: [],
      tag: [],
      type: [],
      process: [],
      tasting_notes: [],
    }),
    []
  );
  const formRef = useRef(null);
  const [filtersCount, setFiltersCount] = useState(0);

  const [collapse, setCollapse] = useState({
    roast: true,
    size: true,
    origin: filterCollapsed(filters, 'origin'),
    state: filterCollapsed(filters, 'state'),
    vendor_id: filterCollapsed(filters, 'vendor_id'),
    tag: filterCollapsed(filters, 'tag'),
    type: true,
    process: filterCollapsed(filters, 'process'),
    tasting_notes: filterCollapsed(filters, 'tasting_notes'),
  });

  const getUniqueFieldCallback = useCallback(getField => getUniqueField(products, getField), [products]);

  const roasts = getUniqueFieldCallback(product => ({ [product.roast?._id]: product.roast }));
  const sizes = getUniqueFieldCallback(product =>
    product.variants.reduce(
      (variants, variant) => ({
        ...variants,
        [variant.size]: { _id: String(variant.size), name: fromOz({ oz: variant.size }).formatted },
      }),
      {}
    )
  );
  const origins = getUniqueFieldCallback(product => ({ [product.origin?._id]: product.origin }));
  const roasters = getUniqueFieldCallback(product => ({
    ...(!product.vendor_id
      ? {}
      : { [product.vendor_id._id]: { _id: product.vendor_id._id, name: product.vendor_id.name } }),
  }));
  const state = getUniqueFieldCallback(product => ({
    ...(!product?.vendor_id?.verifiedAddress
      ? {}
      : {
          [product.vendor_id.verifiedAddress.state]: {
            _id: product.vendor_id.verifiedAddress.state,
            name: product.vendor_id.verifiedAddress.state,
          },
        }),
  }));
  const tags = getUniqueFieldCallback(product =>
    (product.tags || [])
      .filter(tag => !featuredTags.includes(tag._id))
      .reduce((tags, tag) => ({ ...tags, [tag?._id]: tag }), {})
  );
  const types = getUniqueFieldCallback(product =>
    (product.tags || [])
      .filter(tag => featuredTags.includes(tag._id))
      .reduce((tags, tag) => ({ ...tags, [tag._id]: tag }), {})
  );
  const processes = getUniqueFieldCallback(product =>
    product.process ? { [product.process._id]: product.process } : {}
  );

  const tasting_notes = useMemo(() => {
    const flatNotes = (attributes.tastingNoteCategories || []).flat().map(i => i._id);

    const enabledNotes = getUniqueFieldCallback(product => {
      return (product.tasting_notes || [])
        .filter(tastingNote => flatNotes.includes(tastingNote.category_id._id))
        .reduce((tags, tag) => ({ ...tags, [tag?.category_id?._id]: tag?.category_id }), {});
    });

    return enabledNotes;
  }, [attributes.tastingNoteCategories, getUniqueFieldCallback]);

  const filtersByType = useMemo(
    () => ({
      state: state,
      roast: roasts,
      size: sizes,
      origin: origins,
      vendor_id: roasters,
      tag: tags,
      type: types,
      process: processes,
      tasting_notes,
    }),
    [state, roasts, sizes, origins, roasters, tags, types, processes, tasting_notes]
  );

  const getProperFilter = useCallback(type => filtersByType[type] || [], [filtersByType]);

  useEffect(() => {
    if (vendorId) {
      const vendorIndex = roasters.findIndex(roaster => roaster._id === vendorId);
      initialValues.vendor_id[vendorIndex] = vendorId;
    }
  }, [initialValues.vendor_id, roasters, vendorId]);

  const mapped = useMemo(
    () =>
      Object.entries(filters)
        .map(([key, value]) => {
          if (typeof value === 'string') {
            return [key, value];
          }

          const map = [];
          value.forEach(id => {
            const filter = getProperFilter(key);

            const index = filter.findIndex(element => element._id === id);

            map[index] = id;
          });

          return [key, map];
        })
        .reduce((all, [key, value]) => ({ ...all, [key]: value }), {}),
    [filters, getProperFilter]
  );

  useEffect(() => {
    setFiltersCount(countFilters(filters) - (vendorId ? 1 : 0));
    formRef.current.resetForm(mapped);
    Object.entries(filters).forEach(([key, value]) =>
      value.length && !collapse[key] ? setCollapse({ ...collapse, [key]: true }) : undefined
    );
  }, [vendorId, collapse, filters, mapped]);

  const submit = submitForm => () => {
    setTimeout(submitForm);
  };

  const onCollapse = field => () => setCollapse({ ...collapse, [field]: !collapse[field] });

  const showFilter = filter => Array.isArray(filter) && filter.length > 1;
  const onSubmit = values => {
    const data = Object.entries(values).reduce(
      (data, [key, value]) => ({ ...data, [key]: typeof value === 'string' ? value : value.filter(Boolean) }),
      {}
    );
    setFiltersCount(countFilters(data) - (vendorId ? 1 : 0));
    onChange(data);
  };

  const handleClean = useCallback(() => clearFilters(initialValues), [clearFilters, initialValues]);

  return (
    <>
      <Form
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={FiltersSchema}
        onSubmit={onSubmit}
        className="form-filters"
      >
        {({ submitForm }) => (
          <div className="filters">
            <div className="filters-clear mb-3 mb-xl-0">
              {filtersCount > 0 && (
                <Button color="link" onClick={handleClean} size="sm" className="p-0 text-left">
                  Clear {filtersCount} filter{filtersCount > 1 && 's'}
                </Button>
              )}
            </div>
            <div className="filters-wrapper">
              {showFilter(roasts) && (
                <FilterAccordion
                  data={roasts}
                  name="roast"
                  label="Roast"
                  onCollapse={onCollapse('roast')}
                  collapsed={collapse.roast}
                  onChange={submit(submitForm)}
                  products={products}
                  filters={filters}
                />
              )}

              {showFilter(types) && (
                <FilterAccordion
                  data={types}
                  name="type"
                  label="Type"
                  onCollapse={onCollapse('type')}
                  collapsed={collapse.type}
                  onChange={submit(submitForm)}
                  products={products}
                  filters={filters}
                  intersect
                  hideNumber
                />
              )}

              {showFilter(sizes) && (
                <FilterAccordion
                  data={sizes}
                  name="size"
                  label="Size"
                  onCollapse={onCollapse('size')}
                  collapsed={collapse.size}
                  onChange={submit(submitForm)}
                  products={products}
                  filters={filters}
                />
              )}

              {showFilter(origins) && (
                <FilterAccordion
                  data={origins}
                  name="origin"
                  label="Origin"
                  onCollapse={onCollapse('origin')}
                  collapsed={collapse.origin}
                  onChange={submit(submitForm)}
                  products={products}
                  filters={filters}
                />
              )}

              {showRoasterFilter && showFilter(state) && (
                <FilterAccordion
                  data={state}
                  name="state"
                  label="States"
                  onCollapse={onCollapse('state')}
                  collapsed={collapse.state}
                  onChange={submit(submitForm)}
                  products={products}
                  filters={filters}
                />
              )}

              {showRoasterFilter && showFilter(roasters) && (
                <FilterAccordion
                  data={roasters}
                  name="vendor_id"
                  label="Roaster"
                  onCollapse={onCollapse('vendor_id')}
                  collapsed={collapse.vendor_id}
                  onChange={submit(submitForm)}
                  products={products}
                  filters={filters}
                />
              )}

              {showFilter(tags) && (
                <FilterAccordion
                  data={tags}
                  name="tag"
                  label="Tag"
                  onCollapse={onCollapse('tag')}
                  collapsed={collapse.tag}
                  onChange={submit(submitForm)}
                  products={products}
                  filters={filters}
                  intersect
                  hideNumber
                />
              )}

              {showFilter(processes) && (
                <FilterAccordion
                  data={processes}
                  name="process"
                  label="Process"
                  onCollapse={onCollapse('process')}
                  collapsed={collapse.process}
                  onChange={submit(submitForm)}
                  products={products}
                  filters={filters}
                />
              )}

              {showFilter(tasting_notes) && (
                <FilterAccordion
                  data={tasting_notes}
                  name="tasting_notes"
                  label="Tasting notes"
                  onCollapse={onCollapse('tasting_notes')}
                  collapsed={collapse.tasting_notes}
                  onChange={submit(submitForm)}
                  products={products}
                  filters={filters}
                />
              )}
            </div>
            {changeBatch && (
              <Button color="dark" type="button" onClick={onClose} block className="mt-3">
                Close
              </Button>
            )}
          </div>
        )}
      </Form>
    </>
  );
};

const sort = data => {
  if (data.length > 0 && data[0].order) {
    return [...data].sort((a, b) => a.order - b.order);
  }

  if (data.length > 0 && parseInt(data[0]._id) && !data[0]._id?.match(/^[0-9a-fA-F]{24}$/)) {
    return [...data].sort((a, b) => Number(a._id) - Number(b._id));
  }

  return [...data].sort((a, b) => (a.name < b.name ? -1 : a.name > b.name));
};

const getUniqueField = (products, getField) =>
  sort(Object.values(products.reduce((fields, product) => ({ ...fields, ...getField(product) }), {})));

CoffeeFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  changeBatch: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      roast: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  vendorId: PropTypes.string,
  featuredTags: PropTypes.array.isRequired,
  showRoasterFilter: PropTypes.bool.isRequired,
  attributes: PropTypes.object,
  clearFilters: PropTypes.func.isRequired,
};

CoffeeFilters.defaultProps = {
  changeBatch: false,
  showRoasterFilter: true,
  attributes: {},
};

const countFilters = filters =>
  Object.values(filters).reduce((a, b) => a + (typeof b === 'string' ? Boolean(b.length) : b.length), 0);

const filterCollapsed = (filters, name) => Boolean((filters[name] || []).length);
