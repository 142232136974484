import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { AdvanceBuilderHeading } from '../AdvanceBuilderHeading';
import { Size } from './Size';

export const Sizes = ({ disabled, sizes, onChange, products, hideOptionWithoutProducts, optionFilter }) => (
  <>
    <AdvanceBuilderHeading title="Size" subtitle="Choose one" />
    <Row className="pb-4 pt-2">
      {sizes.map(size => (
        <Size
          key={size}
          disabled={disabled}
          onChange={onChange}
          size={size}
          products={products}
          hideOptionWithoutProducts={hideOptionWithoutProducts}
          optionFilter={optionFilter}
        />
      ))}
    </Row>
  </>
);

Sizes.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.number).isRequired,
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  optionFilter: PropTypes.object,
};
