export const getTrackingLink = (shippingService, tracking_number, tracking_url) => {
  switch (shippingService?.toLowerCase()) {
    case 'fedex':
      return `https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=${tracking_number}`;
    case 'usps':
      return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${tracking_number}`;
    default:
      return tracking_url || '';
  }
};
