import { RSAA } from 'redux-api-middleware';
import { createAction } from '../util';
import qs from 'qs';

export const GetManifestTypes = createAction('Manifest', 'Get');
export const GetVendorManifestTypes = createAction('Vendor Manifest', 'Get');

export const getManifestAction = () => ({
  [RSAA]: {
    endpoint: `manifest`,
    method: 'GET',
    types: [GetManifestTypes.REQUEST, GetManifestTypes.SUCCESS, GetManifestTypes.FAILURE],
  },
});

export const getVendorManifestAction = params => ({
  [RSAA]: {
    endpoint: `manifest${params ? `?${qs.stringify(params)}` : ''}`,
    method: 'GET',
    types: [GetVendorManifestTypes.REQUEST, GetVendorManifestTypes.SUCCESS, GetVendorManifestTypes.FAILURE],
  },
});
