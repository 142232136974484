import React from 'react';
import PropTypes from 'prop-types';

export const GenericShipTime = ({ isMondayToThursday }) => (
  <div className="d-flex">
    <img alt="Flash" src="https://bottomless.imgix.net/flash.svg" />
    <div className="text-success font-weight-bold ml-2">
      {isMondayToThursday ? 'Ships tomorrow at 4AM' : 'Ships on Monday at 4AM'}
    </div>
  </div>
);

GenericShipTime.propTypes = {
  isMondayToThursday: PropTypes.bool.isRequired,
};
