import React from 'react';
import { SlugCategoryManifest } from '../../../components/CategoryManifest';
import { CoffeeFilters, GenericFilters } from '../manifest';

const COMPONENTS = {
  CoffeeShopFilters: CoffeeFilters,
  GenericShopFilters: GenericFilters,
};

export const Filters = props => (
  <SlugCategoryManifest
    manifestKey="shopFilters"
    components={COMPONENTS}
    noLoading
    fallbackComponent={GenericFilters}
    {...props}
  />
);
