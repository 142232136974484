import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Container } from 'reactstrap';
import classNames from 'classnames';
import { Image } from '@bottomless/common/components';
import { QuizHeader } from './QuizHeader';
import { QuestionText } from './QuestionText';

const answers = [
  {
    name: 'Extra Coarse',
    value: 'specificGrind:5eaa7fc066c17ce02128fa3b',
    otherAnswers: [{ key: 'grind', value: 'grind:Extra Coarse' }],
    icon: 'https://bottomless.imgix.net/grinds/ExtraCoarse-2.png',
    className: 'quiz-image-padding-q2',
    event: {
      specific_grind: 'Extra Coarse, Cold Brew',
    },
    explanation: 'Cold Brew',
  },
  {
    name: 'Coarse',
    value: 'specificGrind:5eaa7fc066c17ce02128fa3c',
    otherAnswers: [{ key: 'grind', value: 'grind:Coarse' }],
    icon: 'https://bottomless.imgix.net/grinds/Coarse.png',
    className: 'quiz-image-padding-q2',
    event: {
      specific_grind: 'Coarse, French Press',
    },
    explanation: 'French Press',
  },
  {
    name: 'Medium',
    value: 'specificGrind:5eaa7fc066c17ce02128fa3d',
    otherAnswers: [{ key: 'grind', value: 'grind:Medium' }],
    icon: 'https://bottomless.imgix.net/grinds/Medium.png',
    className: 'quiz-image-padding-q2',
    event: {
      specific_grind: 'Medium, Drip/Pour Over',
    },
    explanation: 'Drip / Pour Over',
  },
  {
    name: 'Fine',
    value: 'specificGrind:5eaa7fc066c17ce02128fa3e',
    otherAnswers: [{ key: 'grind', value: 'grind:Fine' }],
    icon: 'https://bottomless.imgix.net/grinds/Fine.png',
    className: 'quiz-image-padding-q2',
    event: {
      specific_grind: 'Fine, Espresso',
    },
    explanation: 'Espresso',
  },
];

export const Question4 = ({ index, total, onAnswer, answers: quizAnswers, isMobile }) => {
  return (
    <>
      <QuizHeader index={index - 1} total={total - 1} quizAnswers={quizAnswers} />
      <QuestionText index={index - 1} total={total - 1} title="Grind Type" subtitle="Which grind do you enjoy?" />
      <div className="answers">
        <Container className="mt-5">
          <Row className="justify-content-center">
            {answers.map((answer, i) => (
              <Col
                key={i}
                xs="6"
                sm="6"
                lg="3"
                className={!isMobile && (i === 0 ? 'pl-0' : i === answers.length - 1 ? 'pr-0' : '')}
              >
                <Card
                  body
                  tag="button"
                  className="d-flex flex-md-row flex-column align-items-center justify-content-center w-100 answer"
                  onClick={() => onAnswer(answer)}
                >
                  <Image src={answer.icon} width="50" alt={`Answer - ${answer.name}`} />
                  <p
                    className={classNames('mb-0', {
                      'ml-4': !isMobile,
                      'mt-2': isMobile,
                    })}
                  >
                    {answer.name}
                  </p>
                </Card>
                <p className="text-center font-italic mt-2 text-secondary">{answer.explanation}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

Question4.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onAnswer: PropTypes.func.isRequired,
  answers: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
};
