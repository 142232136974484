import React from 'react';
import PropTypes from 'prop-types';
import { fromOz } from '@bottomless/common/utils';

export const SizeWarningModalBody = ({ product, values, me }) => {
  const variant = product.variants.find(row => row._id === values?.variant);
  const defaultVariant = me.product?.product?.variants?.find(row => row._id === me.product.variant);

  return (
    <div>
      <div className="mt-2">
        <div className="d-flex mt-3 justify-space-between">
          <div className="font-weight-bold">Default:</div>
          <div className="text-right">{fromOz({ oz: defaultVariant?.size, unit: defaultVariant.unit }).formatted}</div>
        </div>
        <div className="d-flex mt-3 justify-space-between">
          <div className="font-weight-bold">Selected:</div>
          <div className="text-right">{fromOz({ oz: variant?.size, unit: variant.unit }).formatted}</div>
        </div>
      </div>
      <div className="mt-4">
        Do you wish to proceed with {fromOz({ oz: variant?.size, unit: variant.unit }).formatted}?
      </div>
    </div>
  );
};

SizeWarningModalBody.propTypes = {
  product: PropTypes.shape({
    variants: PropTypes.array,
  }).isRequired,
  values: PropTypes.shape({
    variant: PropTypes.string,
  }).isRequired,
  me: PropTypes.object,
};
