import React from 'react';
import { getCleanNumericalValue } from '@bottomless/common/utils';
import { Price } from '@bottomless/common/components';

export const generateFaqs = ({ product, productPrice, productSize, pricingRule, checkout }) => {
  const shippingDiscount = Math.round((1 - getCleanNumericalValue(pricingRule?.discount_proportion, 1)) * 100);

  return [
    {
      question: 'What is a subscription by usage?',
      answer:
        'A subscription by usage is one that does not ship products on a fixed schedule but one that tracks your usage and re-orders at the perfect time. With other subscriptions you either have too much or too little. But with Bottomless you always have the perfect amount!',
    },
    {
      question: 'Why should I switch to Bottomless?',
      answer:
        'Bottomless is the perfect automatic usage-based re-stocking solution. Joining Bottomless is joining a smart future. There are no delivery fees and no markups. Our re-ordering mechanism will reduce waste and you will never have to worry about running out again.',
    },
    {
      question: 'How does it work?',
      answer:
        'After you receive the scale with your first order, sync it with your WiFi network once. Then leave your product/bag on it. The scale then learns from your consumption and re-orders for you at the right time.',
    },
    {
      question: 'Do I have control of ordering?',
      answer:
        'Yes! You will get an alert 8-12 hours before orders are placed so you have a chance to change your product, postpone or cancel. You can also customize how "eager" our algorithms are to order for you. We adapt to your needs using real-time data on your consumption.',
    },
    {
      question: 'How much does it cost?',
      answer: (
        <>
          A {productSize}oz bag of {product?.name} would cost <Price value={productPrice} />. Membership charges are{' '}
          <Price value={pricingRule?.monthly_fee || 799} cents />
          /month - {pricingRule?.free_shipping ? 'includes shipping, ' : ''}
          {shippingDiscount ? `scale and ${shippingDiscount}% OFF on every bag` : 'and scale'}. No limits. No minimums.
          {pricingRule?.free_trial_length ? `We offer a ${pricingRule.free_trial_length} day free trial.` : ''}
        </>
      ),
      hideForVendorLocked: true,
    },
    {
      question: 'Can I use a container with the scale?',
      answer: 'Yes! The scale can easily be "zeroed" with almost any home container or jar.',
    },
    {
      question: 'Can I pause/cancel my subscription when needed?',
      answer: `Of course!. Bottomless is risk-free. You can pause or cancel anytime.${
        pricingRule?.free_trial_length ? ` We offer a ${pricingRule.free_trial_length} day free trial.` : ''
      }`,
    },
  ].filter(row => !row.hideForVendorLocked || checkout?.vendor_id !== product?.vendor_id?._id);
};
