import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { StepsProgress } from '../../../components/StepsProgress';
import { ConnectScale } from './components';
import { setupScaleUAction } from '../../../store';
import { withMetaTags } from '../../../components/MetaTags/MetaTags';
import { connect } from 'react-redux';
import { useQueryString } from '@bottomless/common/hooks';
import { withCustomWording } from '../context/custom-wording-context';
import { customWording } from './customWording';

const ConnectStep3PageComponent = ({ setupScale }) => {
  const history = useHistory();

  const { params, update } = useQueryString();
  const flow = useMemo(() => params.flow || 'first_step', [params.flow]);
  const timestamp = useMemo(() => params.timestamp || String(Date.now()), [params.timestamp]);

  useEffect(() => {
    if (params.flow !== flow || params.time !== timestamp) {
      update({ flow, timestamp });
    }
  }, [update, flow, timestamp, params]);

  const [isConnected, setConnected] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let intervalId;

    const fetchData = async () => {
      try {
        const { payload, error } = await setupScale({ flow, timestamp: Number(timestamp) });
        if (error && payload.status === 410) {
          history.push('/activate');
        }

        if (payload.scale_status === 'complete') {
          setConnected(true);
          clearInterval(intervalId);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };

    fetchData();

    intervalId = setInterval(() => {
      fetchData();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [setupScale, history, flow, timestamp]);

  const onConnectedFinished = useCallback(() => {
    history.push(`/activate/success?flow=${flow}`);
  }, [history, flow]);

  return (
    <>
      <StepsProgress steps={3} activeStep={2} className="mb-5" />
      <h1 className="text-center mb-4">Activate your smart scale</h1>
      <ConnectScale onConnectedFinished={onConnectedFinished} isConnected={isConnected} />
    </>
  );
};

ConnectStep3PageComponent.propTypes = {
  setupScale: PropTypes.func.isRequired,
};

export const ConnectStep3Page = withCustomWording(
  customWording,
  withMetaTags({
    title: 'Bottomless.com: Activate your smart scale',
  })(
    connect(null, dispatch => ({
      setupScale: data => dispatch(setupScaleUAction(data)),
    }))(ConnectStep3PageComponent)
  )
);
