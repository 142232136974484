import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col } from 'reactstrap';
import { SimplePage } from '../../../layouts/SimplePage/SimplePage';
import { Invite } from '../../OnboardingReferral/components/Invite';
import './Referral.scss';

export const ReferralClipboard = ({ match }) => {
  return (
    <SimplePage withFooter={false} stretch className="to-clipboard-referral">
      <Container className="d-flex justify-content-center text-center">
        <Col xs="12">
          <h3 className="mb-5 text-center"> Get a free bag when you refer!</h3>
          <Invite withTitle={false} withQrCode={false} withDescription={false} referralId={match.params.id} />
        </Col>
      </Container>
    </SimplePage>
  );
};

ReferralClipboard.propTypes = {
  match: PropTypes.object.isRequired,
};
