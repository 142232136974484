import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Loader.scss';

export const Loader = ({ fullPage }) => (
  <div className={classNames('loader', { 'loader-full-page': fullPage })}>
    <div className="d-flex flex-column align-items-center justify-content-center">
      <img
        src="https://bottomless.imgix.net/landing/superhero-B-loader.gif"
        alt="B"
        className="img-fluid"
        height="80"
        width="80"
      />
    </div>
  </div>
);

Loader.propTypes = {
  fullPage: PropTypes.bool,
};
