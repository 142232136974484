import { DateFormat } from '@bottomless/common/src/components/DateFormat';
import { utcDate } from '@bottomless/common/src/utils';
import React from 'react';
import PropTypes from 'prop-types';
import { getNextFulfillmentDate, getNowInPst } from '../../../utils/dates';

const SEVEN_DAYS_IN_MILLISECONDS = 7 * 24 * 3600 * 1000;

const ONE_OFF_ENABLED = false;

const SCALE_STATUSES = {
  DISCONNECTED: 'disconnected',
};

export const NextOrderDate = ({ me, pendingOrder, hasOneOffOrder, pastOrders, vendor }) => {
  const { paused, dumb_period, scale_status, scale_last_connected } = me || {};

  const isScaleDisconnected =
    scale_last_connected &&
    new Date(scale_last_connected) <= new Date(new Date().getTime() - SEVEN_DAYS_IN_MILLISECONDS) &&
    scale_status === SCALE_STATUSES.DISCONNECTED;

  // Next order date for smart subscriptions
  if (!dumb_period && !isScaleDisconnected && (!pendingOrder || (pendingOrder && !hasOneOffOrder && ONE_OFF_ENABLED))) {
    return (
      <>
        <div className="text-center font-weight-bolder mr-2">Order Timing</div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="text-xl mr-2">
            <span aria-label="Robot" role="img">
              🤖
            </span>
          </div>
          <div className="text-sm text-center">Determined algorithmically</div>
        </div>
      </>
    );
  }

  // Next order date for Fixed Frequency Subscription
  if (!paused && !pendingOrder && isScaleDisconnected && dumb_period) {
    const date = utcDate(new Date());
    date.setDate(date.getDate() - dumb_period);

    let lastFulfilledDate;
    if (pastOrders && pastOrders[0]) {
      lastFulfilledDate = pastOrders[0].override_fulfillment_date
        ? utcDate(pastOrders[0].override_fulfillment_date)
        : utcDate(pastOrders[0].date_fulfilled);
    }

    let nextOrderDate;
    if (lastFulfilledDate && lastFulfilledDate > date) {
      nextOrderDate = lastFulfilledDate.setDate(lastFulfilledDate.getDate() + dumb_period);
    } else {
      const nowInPST = getNowInPst();
      const tomorrow = nowInPST.setDate(nowInPST.getDate() + 1);
      const dayAfterTomorrow = nowInPST.setDate(nowInPST.getDate() + 2);

      const isBefore1PMPST = nowInPST.getHours() < 13 && nowInPST.getHours() > 0;

      const { exclude_fulfilment_dates } = vendor || {};
      const excludedDates = exclude_fulfilment_dates ? exclude_fulfilment_dates.map(date => utcDate(date)) : [];

      // Next order date after taking vendor fulfillment into consideration
      if (isBefore1PMPST) {
        // Order will be generated today
        nextOrderDate = getNextFulfillmentDate({ startDate: tomorrow, excludedDates });
      } else {
        // Order will be generated tomorrow
        nextOrderDate = getNextFulfillmentDate({ startDate: dayAfterTomorrow, excludedDates });
      }
    }

    if (nextOrderDate) {
      return (
        <p className="mt-3">
          <strong>Next Order Date:</strong> <DateFormat date={nextOrderDate} />
        </p>
      );
    }
  }

  return null;
};

NextOrderDate.propTypes = {
  me: PropTypes.object.isRequired,
  pendingOrder: PropTypes.object,
  hasOneOffOrder: PropTypes.bool,
  pastOrders: PropTypes.array,
  vendor: PropTypes.object,
};
