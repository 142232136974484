import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Button, Container } from 'reactstrap';
import { GetStartedModal } from '../../../../components/GetStartedModal/GetStartedModal';
import { getNextBusinessDay, getNextBusinessDayOrToday } from '../../../../utils/dates';
import { Product } from './Product';

const ProductsSectionComponent = ({
  products,
  history,
  navigateToShop,
  pricingRule,
  isFirstBag,
  getStarted,
  setShipment,
  hasCustomStyles,
  me,
}) => {
  const [shopProducts, setShopProducts] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [productData, setProductData] = useState(null);
  const [currentStock, setCurrentStock] = useState(null);

  const toggleModal = () => setModalOpen(!isModalOpen);

  useEffect(() => {
    if (products?.length > 6) {
      setShopProducts(products.slice(0, 6));
    } else {
      setShopProducts(products);
    }
  }, [products]);

  const onSubmit = useCallback(
    async (...data) => {
      const email = data && data[0].email;
      const result = await getStarted({
        ...productData,
        ...data,
        email,
      });

      const { event: { dateOverride } = {} } = currentStock || {};

      if (dateOverride === '0') {
        await setShipment(result.payload.checkoutId, { shipment_date: undefined, dateOverride });
      }

      if (dateOverride && dateOverride !== '0') {
        const nextBusinessDayOrToday = getNextBusinessDayOrToday();

        const shipmentDate =
          dateOverride === 'nextBusiness'
            ? getNextBusinessDay()
            : new Date(nextBusinessDayOrToday.setDate(nextBusinessDayOrToday.getDate() + 7 * Number(dateOverride)));

        await setShipment(result.payload.checkoutId, {
          shipment_date: moment(shipmentDate).format('YYYY-MM-DD'),
          dateOverride,
        });
      }

      return result;
    },
    [getStarted, productData, currentStock, setShipment]
  );

  const onSuccess = ({ checkoutId }) => {
    history.push(`/get_started/${checkoutId}`);
  };

  const onChooseProduct = async (e, product, { variant, ...data }) => {
    e.stopPropagation();
    setProductData({ product: { product: product._id, variant }, ...data });
    toggleModal();
  };

  if (!shopProducts) return null;

  return (
    <section className="products-section">
      <Container>
        <h1 className="text-center mb-5">Popular Products</h1>
        <Row>
          {shopProducts.map(product => (
            <Product
              key={product._id}
              product={product}
              history={history}
              pricingRule={pricingRule}
              isFirstBag={isFirstBag}
              onChooseProduct={onChooseProduct}
              hasCustomStyles={hasCustomStyles}
              me={me}
            />
          ))}
        </Row>
        {products?.length >= 6 ? (
          <Row className="mt-5 justify-content-center">
            <Button color="primary" size="lg" onClick={() => navigateToShop({ position: 'vendor-view-more-products' })}>
              View more products
            </Button>
          </Row>
        ) : (
          undefined
        )}
      </Container>
      <GetStartedModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        getStarted={onSubmit}
        onSignUp={onSuccess}
        pricingRule={pricingRule}
        headingText="Start your subscription by usage"
        isFromVendorLanding
        setCurrentStock={setCurrentStock}
        dateOverride={currentStock?.event?.dateOverride}
        className="vendor-landing"
      />
    </section>
  );
};

ProductsSectionComponent.propTypes = {
  products: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  navigateToShop: PropTypes.func.isRequired,
  pricingRule: PropTypes.object,
  isFirstBag: PropTypes.bool.isRequired,
  getStarted: PropTypes.func.isRequired,
  setShipment: PropTypes.func.isRequired,
  hasCustomStyles: PropTypes.bool.isRequired,
  me: PropTypes.any,
};

export const ProductsSection = memo(ProductsSectionComponent);
