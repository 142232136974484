import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetRotationTypes = createAction('Rotations', 'Get');

export const getRotationAction = id => ({
  [RSAA]: {
    endpoint: `rotations/${id}`,
    method: 'GET',
    types: [GetRotationTypes.REQUEST, GetRotationTypes.SUCCESS, GetRotationTypes.FAILURE],
  },
});
