import React from 'react';
import PropTypes from 'prop-types';
import { useCustomWording } from '../../hooks/use-custom-wording';

export const FaqElement = ({ question, answer }) => {
  const { cw } = useCustomWording();

  return (
    <div className="mb-5">
      <div className="font-weight-bold mb-3">{question}</div>
      <div>{cw(answer)}</div>
    </div>
  );
};

FaqElement.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};
