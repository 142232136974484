import React from 'react';
import PropTypes from 'prop-types';
import { PaymentRequestButtonElement } from 'react-stripe-elements';
import { usePaymentProvider } from '../../../components/ApplePay/ApplePay';
import './ApplePayButtonInner.scss';

export const ApplePayButtonInner = ({ showSeperator = false, className, showTitle = true }) => {
  const [request, canMakePayment, loading, errors] = usePaymentProvider();

  if (loading || !canMakePayment) return null;

  return (
    <div className={`product-apple-pay ${className ? className : ''}`}>
      {showSeperator && (
        <div className="express-checkout">
          <div className="mb-3 or-line-seperator">
            <span className="or-line-content small text-secondary">OR</span>
          </div>
          {showTitle && <div className="express-checkout-title mb-2">Express Checkout</div>}
        </div>
      )}
      <PaymentRequestButtonElement paymentRequest={request} className="PaymentRequestButton" />
      {errors?.length > 0 && (
        <div className="mt-2">
          {errors.map((error, i) => (
            <div key={i} className="text-sm text-danger">
              {error}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ApplePayButtonInner.propTypes = {
  showSeperator: PropTypes.bool,
  className: PropTypes.string,
  showTitle: PropTypes.bool,
};
