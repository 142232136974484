import React from 'react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

export const Weight = ({ value: rawValue, toFixed, unit = 'oz' }) => {
  const value = useMemo(() => (toFixed ? parseFloat(rawValue).toFixed(toFixed) : rawValue), [toFixed, rawValue]);

  return (
    <>
      {value}
      {unit}
    </>
  );
};

Weight.propTypes = {
  value: PropTypes.number.isRequired,
  toFixed: PropTypes.number,
  unit: PropTypes.string,
};
