import React from 'react';
import PropTypes from 'prop-types';
import { FeatureSection } from '../../FeatureSection/FeatureSection';

export const TryVariety = ({ isProductSpecific }) => (
  <FeatureSection
    text="Bottomless orders are always roast to order from local roasters. Cutting out the middleman and passing the
              savings to you!"
    title={isProductSpecific ? 'Try coffee with no markups' : 'Try a variety of coffee with no markups'}
    image="https://bottomless.imgix.net/assets/no-markups-1.jpeg?auto=compress,format"
  />
);

TryVariety.propTypes = {
  isProductSpecific: PropTypes.bool,
};
