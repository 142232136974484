import React from 'react';

export const Video = () => {
  return (
    <div className="video-wrapper mt-5 mt-sm-0">
      <video
        poster="https://bottomless.imgix.net/miscellaneous/video/reordering000.png?w=900&auto=compress,format"
        autoPlay
        loop
        muted
        playsInline
      >
        <source
          src="https://bottomless-products.s3-us-west-1.amazonaws.com/miscellaneous/video/reordering-1920.webm"
          type="video/webm"
        />
        <source
          src="https://bottomless-products.s3-us-west-1.amazonaws.com/miscellaneous/video/reordering-1920.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  );
};
