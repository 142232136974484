import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';
import { Field as FormikField } from 'formik';
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';

export const Radio = props => <FormikField {...props} component={RadioComponent} />;

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
};

const RadioComponent = ({ field, form, type, label, onChange, ...props }) => {
  const invalid = get(form.errors, field.name) && get(form.touched, field.name);
  const value = get(form.values, field.name);

  const checked =
    field.value === value || (field.value === false && value === 'false') || (field.value === true && value === 'true');

  return (
    <FormGroup check>
      <Label check>
        <Input
          {...field}
          {...props}
          type={type}
          invalid={invalid}
          checked={checked}
          onChange={event => {
            field.onChange(event);

            if (onChange) {
              onChange(event);
            }
          }}
        />
        {label}
      </Label>
      <FormFeedback className="text-left">{get(form.errors, field.name)}</FormFeedback>
    </FormGroup>
  );
};

RadioComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]).isRequired,
  onChange: PropTypes.func,
};
