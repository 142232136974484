import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Link, Link2 } from 'react-feather';
import { Box } from '../../Box';
import { getTrackingLink } from '../../../utils/trackingLink';
import { Image } from '../../Image';

export const ScaleShipment = ({ me, replacement, noScaleImage }) => {
  const link = useMemo(() => {
    if (replacement?.tracking_number) {
      return getTrackingLink(replacement.service, replacement.tracking_number);
    }

    if (me.onboardingState?.scale_tracking_number) {
      return getTrackingLink(me.onboardingState.scale_shipping_service, me.onboardingState.scale_tracking_number);
    }

    return null;
  }, [me, replacement]);

  const isScaleArrived = useMemo(() => replacement?.status === 'arrived' || me.onboardingState?.scaleArrived, [
    me,
    replacement,
  ]);

  const status = useMemo(() => {
    if (isScaleArrived) {
      return 'Pending';
    }
    if (replacement?.tracking_number || me.onboardingState?.scale_tracking_number) {
      return 'In Progress';
    }

    return 'Preparing';
  }, [me, replacement, isScaleArrived]);

  return (
    <Box>
      <Row>
        <Col xs={isScaleArrived ? 7 : 6} md={noScaleImage ? 6 : 8} className="d-flex">
          <div className="d-flex align-items-center">
            {!noScaleImage && (
              <Image src="scale-sketch-transparent-2.png" alt="Scale" height="75" className="mr-3 image-scale" />
            )}
            <div className="small">
              <div className="font-weight-bold">{isScaleArrived ? 'Pending Setup' : 'Scale Shipment'}</div>
              {!isScaleArrived && <div className="text-secondary">{status}</div>}
              {isScaleArrived && (
                <a
                  href="https://support.bottomless.com/en/articles/909568"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="small"
                  onClick={e => e.stopPropagation()}
                >
                  <Link2 width="12" height="12" className="mr-1" /> Set-Up Guide
                </a>
              )}
            </div>
          </div>
        </Col>
        <Col
          xs={isScaleArrived ? 5 : 6}
          md={noScaleImage ? 6 : 4}
          className="d-flex align-items-center justify-content-end"
        >
          {link && (
            <a href={link} target="_blank" rel="noopener noreferrer" className="small">
              <Link width="12" height="12" /> Tracking link
            </a>
          )}
          {!link && (
            <div className="small text-right text-secondary">
              <div className="font-weight-bold">No tracking yet</div>
              <div>It will be available soon</div>
            </div>
          )}
        </Col>
      </Row>
    </Box>
  );
};

ScaleShipment.propTypes = {
  me: PropTypes.shape({
    onboardingState: PropTypes.object.isRequired,
  }).isRequired,
  replacement: PropTypes.shape({
    tracking_number: PropTypes.string,
    service: PropTypes.string,
    status: PropTypes.string,
  }),
  noScaleImage: PropTypes.bool,
};
