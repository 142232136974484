import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const GetSlowLocalOrderForDeliveryTypes = createAction('Slow Local', 'Get order');

export const getSlowLocalOrderForDeliveryAction = id => ({
  [RSAA]: {
    endpoint: `slow-local-orders/${id}`,
    method: 'GET',
    types: [
      GetSlowLocalOrderForDeliveryTypes.REQUEST,
      GetSlowLocalOrderForDeliveryTypes.SUCCESS,
      GetSlowLocalOrderForDeliveryTypes.FAILURE,
    ],
  },
});

export const SlowLocalOrderOnTheWayTypes = createAction('Slow Local', 'On the way');

export const slowLocalOrderOnTheWayAction = (id, data) => ({
  [RSAA]: {
    endpoint: `slow-local-orders/${id}/on-the-way`,
    body: data,
    method: 'PATCH',
    types: [
      SlowLocalOrderOnTheWayTypes.REQUEST,
      SlowLocalOrderOnTheWayTypes.SUCCESS,
      SlowLocalOrderOnTheWayTypes.FAILURE,
    ],
  },
});

export const SlowLocalOrderDeliveredTypes = createAction('Slow Local', 'Delivered');

export const slowLocalOrderDeliveredAction = (id, data) => ({
  [RSAA]: {
    endpoint: `slow-local-orders/${id}/delivered`,
    method: 'PATCH',
    body: data,
    types: [
      SlowLocalOrderDeliveredTypes.REQUEST,
      SlowLocalOrderDeliveredTypes.SUCCESS,
      SlowLocalOrderDeliveredTypes.FAILURE,
    ],
  },
});

export const UploadSlowLocalOrderPictureTypes = createAction('Slow Local', 'Upload Picture');

export const uploadSlowLocalOrderPictureAction = (id, data) => ({
  [RSAA]: {
    endpoint: `slow-local-orders/${id}/upload`,
    method: 'POST',
    body: data,
    headers: { upload: true },
    types: [
      SlowLocalOrderDeliveredTypes.REQUEST,
      SlowLocalOrderDeliveredTypes.SUCCESS,
      SlowLocalOrderDeliveredTypes.FAILURE,
    ],
  },
});

export const OverrideSlowLocalDateTypes = createAction('Slow Local', 'Override date');

export const overrideSlowLocalDateAction = (id, data) => ({
  [RSAA]: {
    endpoint: `slow-local-orders/${id}/override-date`,
    method: 'POST',
    body: data,
    types: [OverrideSlowLocalDateTypes.REQUEST, OverrideSlowLocalDateTypes.SUCCESS, OverrideSlowLocalDateTypes.FAILURE],
  },
});

export const FinalizeSlowLocalOrderTypes = createAction('Slow Local', 'Override date');

export const finalizeSlowLocalOrderAction = id => ({
  [RSAA]: {
    endpoint: `slow-local-orders/${id}/finalize`,
    method: 'POST',
    types: [
      FinalizeSlowLocalOrderTypes.REQUEST,
      FinalizeSlowLocalOrderTypes.SUCCESS,
      FinalizeSlowLocalOrderTypes.FAILURE,
    ],
  },
});
