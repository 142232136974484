import { Field as FormikField } from 'formik';
import get from 'lodash-es/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { forwardRef, Suspense, useMemo, useState } from 'react';
import { Loader } from 'react-feather';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { datesService } from '../../utils';
import { tagMap } from '../../utils/tagMap';
const DatePicker = React.lazy(() => import('react-datepicker'));
const Editor = React.lazy(() => import('react-medium-editor'));
const Select = React.lazy(() => import('react-select'));
const CreatableSelect = React.lazy(() => import('react-select/creatable'));
import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';
import './Field.scss';
import { useCallback } from 'react';

export const Field = props => <FormikField {...props} component={FieldComponent} />;

Field.propTypes = {
  excludeWeekends: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  multiple: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number]),
  showTime: PropTypes.bool,
  format: PropTypes.string,
};

Field.defaultProps = {
  type: 'text',
  showTime: false,
};

const isWeekday = date => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

const FieldComponent = ({
  field,
  form,
  type,
  label,
  min,
  max,
  options,
  multiple,
  onChange,
  excludeWeekends,
  excludeDates,
  showTime,
  format,
  isTag,
  isMulti,
  shouldDelete,
  zeroed,
  idNounce,
  ...props
}) => {
  const [focused, setFocused] = useState(
    Boolean(field.value) || field.value === 0 || type === 'date' || type === 'select' || type === 'phone'
  );

  const invalid = Boolean(get(form.errors, field.name) && (get(form.touched, field.name) || form.submitCount));

  const id = `input_${field.name}${idNounce || ''}_${new Date().getTime()}`;

  if ((invalid || field.value) && !focused) {
    setFocused(true);
  }

  const handleOnWheel = useCallback(e => {
    if (e.target?.type === 'number') {
      e.target.blur();
    }
  }, []);

  const selectChange = event => {
    if (isMulti) {
      const ids = [];
      if (event) {
        if (isTag) {
          for (const prop in tagMap) {
            for (const tag in form.values.tags) {
              if (form.values.tags[tag] === tagMap[prop]) {
                ids.push(tagMap[prop]);
              }
            }
          }
        }
        event.map(event => {
          ids.push(event.value);
        });
      }
      form.setFieldValue(field.name, ids);
    } else {
      let id = '';
      if (event) {
        id = event.value;
        form.setFieldValue(field.name, id);
      } else {
        shouldDelete ? delete form.values[field.name] : form.setFieldValue(field.name, id);
      }
    }
  };

  const getClasses = () =>
    [
      `form-group--${type}`,
      ...(type === 'select' ? ['form-group-select', 'select-pointer'] : []),
      ...(type === 'hidden' ? ['mb-0'] : []),
      ...(label ? ['form-group--with-label'] : []),
      ...(props.disabled ? ['form-group-disabled'] : []),
      ...(type === 'date' && invalid ? ['form-group--date-invalid'] : []),
    ].join(' ');

  const { isValidDate, dateValue } = useMemo(() => {
    if (type !== 'date') {
      return { isValidDate: false, dateValue: null };
    }

    const date = field.value && moment(field.value).utc(true);
    const isValidDate = !!date?.isValid();
    const dateValue = isValidDate ? date.format(format) : field.value;

    return { isValidDate, dateValue };
  }, [type, field.value, format]);

  return (
    <FormGroup className={getClasses()}>
      {label && !['hidden', 'select-t'].includes(type) && <Label for={id}>{label}</Label>}
      {type === 'date' && (
        <Suspense fallback={<Loader size="13" className="spin ml-2" />}>
          <DatePicker
            onChange={value => {
              const transformedValue = zeroed ? datesService.getLocalDateInUTC(value) : value;
              form.setFieldValue(field.name, transformedValue);

              if (onChange) {
                onChange(value);
              }
            }}
            wrapperClassName={classNames('d-block', { 'is-invalid': invalid })}
            calendarClassName={classNames({ 'datepicker-inline': props.inline })}
            selected={isValidDate ? new Date(dateValue) : undefined}
            minDate={min}
            maxDate={max}
            disabled={props.disabled}
            excludeDates={excludeDates}
            filterDate={excludeWeekends ? isWeekday : undefined}
            showTimeInput={showTime}
            placeholder={label}
            inline={props.inline}
            customInput={
              <DateInput
                id={id}
                {...field}
                {...props}
                format={format}
                type="text"
                invalid={invalid}
                onFocus={() => setFocused(true)}
                date={dateValue}
              />
            }
          />
        </Suspense>
      )}

      {type === 'select-t' && (
        <>
          <div>
            <span> {label} </span>
          </div>
          <Select
            defaultValue={
              isMulti
                ? options.filter(({ value }) => (field.value || []).includes(value))
                : options.find(({ value }) => value === field.value)
            }
            className="basic-multi-select"
            classNamePrefix="select"
            isMulti={isMulti}
            isClearable={true}
            options={options}
            onChange={selectChange}
          />
        </>
      )}

      {type === 'select-creatable' && (
        <CreatableSelect
          defaultValue={(field.value || []).map(value => ({ value, label: value }))}
          isMulti={true}
          isClearable={true}
          placeholder={null}
          onChange={data => {
            form.setFieldValue(
              field.name,
              (data || []).map(({ value }) => value)
            );
          }}
          onFocus={() => setFocused(true)}
        />
      )}

      {type === 'phone' && (
        <InputMask
          mask="+1 (999) 999-9999"
          alwaysShowMask={true}
          {...props}
          {...field}
          onChange={rawEvent => {
            const event =
              rawEvent.target.value === '+1 (___) ___-____'
                ? { target: { name: rawEvent.target.name, value: '' } }
                : rawEvent;

            field.onChange(event);

            if (onChange) {
              onChange(event);
            }
          }}
        >
          {inputProps => <Input id={id} {...inputProps} invalid={invalid} type="tel" disabled={props.disabled} />}
        </InputMask>
      )}

      {type === 'editor' && (
        <div className="border bg-white pt-3 pb-2 px-2 mb-2 mh-200 d-flex align-items-stretch">
          <Editor
            text={field.value}
            onChange={value => {
              form.setFieldValue(field.name, value);

              if (onChange) {
                onChange(value);
              }
            }}
            options={{
              toolbar: {
                buttons: [
                  'bold',
                  'italic',
                  'underline',
                  'strikethrough',
                  'anchor',
                  'image',
                  'justifyLeft',
                  'justifyCenter',
                  'justifyRight',
                  'justifyFull',
                  'orderedlist',
                  'unorderedlist',
                  'removeFormat',
                  'h2',
                  'h3',
                  'h4',
                ],
              },
              buttonLabels: 'fontawesome',
              anchor: { targetCheckbox: true },
            }}
          />
        </div>
      )}

      {!['phone', 'date', 'select-t', 'editor', 'select-creatable'].includes(type) && (
        <Input
          id={id}
          {...field}
          {...props}
          type={type}
          invalid={invalid}
          min={min}
          max={max}
          multiple={multiple}
          onWheel={handleOnWheel}
          onChange={event => {
            if (multiple) {
              const selectedValue = [].slice.call(event.target.selectedOptions).map(option => option.value);
              return form.setFieldValue(field.name, selectedValue);
            }

            field.onChange(event);

            if (onChange) {
              onChange(event);
            }
          }}
          onFocus={(...args) => {
            setFocused(true);

            if (props.onFocus) {
              props.onFocus(...args);
            }
          }}
        >
          {options && (
            <>
              {Array.isArray(options) &&
                options.map(({ name, items }) => (
                  <optgroup key={name} label={name}>
                    {Object.entries(items).map(([value, label]) => (
                      <option value={value} key={value}>
                        {label}
                      </option>
                    ))}
                  </optgroup>
                ))}
              {!Array.isArray(options) &&
                Object.entries(options).map(([value, label]) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
            </>
          )}
        </Input>
      )}
      <FormFeedback className="text-left">{get(form.errors, field.name)}</FormFeedback>
    </FormGroup>
  );
};

FieldComponent.defaultProps = {
  excludeDates: [],
  format: 'MM/DD/YYYY',
};

FieldComponent.propTypes = {
  excludeWeekends: PropTypes.bool,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number]),
  excludeDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  multiple: PropTypes.bool,
  showTime: PropTypes.bool,
  format: PropTypes.string,
  isTag: PropTypes.bool,
  isMulti: PropTypes.bool,
  shouldDelete: PropTypes.bool,
  zeroed: PropTypes.bool,
  inline: PropTypes.bool,
  idNounce: PropTypes.string,
};

const DateInput = forwardRef(({ date, ...props }, ref) => <Input innerRef={ref} {...props} value={date || ''} />);

DateInput.displayName = 'DateInput';

DateInput.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};
