import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './StepsProgress.scss';

export const StepsProgress = ({ steps, activeStep = 0, className }) => (
  <div className={classNames('d-flex steps-progress', className)}>
    {new Array(steps).fill(true).map((_, index) => (
      <div
        key={index}
        className={classNames('steps-progress-step font-weight-bold text-center', {
          'steps-progress-step-active': Number(activeStep) === index,
        })}
      >
        <span className="steps-progress-step-label">Step {index + 1}</span>
      </div>
    ))}
  </div>
);

StepsProgress.propTypes = {
  steps: PropTypes.number.isRequired,
  activeStep: PropTypes.number,
  className: PropTypes.string,
};
