import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { ArrowRight } from 'react-feather';
import { useToggle } from '@bottomless/common/hooks';
import { capitalize } from '../../../utils/capitalize';

export const ProductVendorComponent = ({ product, me }) => {
  const [descClosed, toggleDescClosed] = useToggle();

  return (
    <>
      <hr />
      <h2 className="text-center mb-5 mt-5">About the Roaster</h2>
      <Row className="mt-5 mb-5 align-items-center">
        <Col xs="12" md="6" className="vendor-img-column text-center">
          <img src={product.vendor_id.img_url} width="300" alt="vendor logo" className="mb-3" />
        </Col>
        <Col xs="12" md="6" className="vendor-details">
          <div className="mb-3">
            <div className="font-weight-bolder">Roaster</div>
            <div className="desc mb-2">{product.vendor_id.name}</div>
            <div className="sub-desc">
              {!descClosed && product.vendor_id?.description?.length > 150 ? (
                <>
                  {product.vendor_id.description.substring(0, 150)}...
                  <Button color="link" className="read-more-btn" onClick={toggleDescClosed}>
                    Read More
                  </Button>
                </>
              ) : (
                <>{product.vendor_id?.description}</>
              )}
              {product.vendor_id?.description?.length > 150 && descClosed && (
                <Button color="link" className="read-more-btn" onClick={toggleDescClosed}>
                  Read Less
                </Button>
              )}
            </div>
          </div>
          {product.vendor_id?.verifiedAddress && (
            <div className="mb-3">
              <div className="font-weight-bolder">Location</div>
              <div className="desc">
                {capitalize(product.vendor_id?.verifiedAddress?.city)}, {product.vendor_id?.verifiedAddress?.state}
              </div>
            </div>
          )}
          {product.vendor_id?.fulfillment_timelines && (
            <div className="roasting-schedule mb-3">
              <div className="font-weight-bold">Roasting Schedule</div>
              <div className="desc text-secondary">
                <span className={`mr-3 ${product.vendor_id.fulfillment_timelines.sunday ? 'text-primary' : ''}`}>
                  Sun
                </span>
                <span className={`mr-3 ${product.vendor_id.fulfillment_timelines.monday ? 'text-primary' : ''}`}>
                  Mon
                </span>
                <span className={`mr-3 ${product.vendor_id.fulfillment_timelines.tuesday ? 'text-primary' : ''}`}>
                  Tues
                </span>
                <span className={`mr-3 ${product.vendor_id.fulfillment_timelines.wednesday ? 'text-primary' : ''}`}>
                  Wed
                </span>
                <span className={`mr-3 ${product.vendor_id.fulfillment_timelines.thursday ? 'text-primary' : ''}`}>
                  Thurs
                </span>
                <span className={`mr-3 ${product.vendor_id.fulfillment_timelines.friday ? 'text-primary' : ''}`}>
                  Fri
                </span>
                <span className={product.vendor_id.fulfillment_timelines.saturday ? 'text-primary' : ''}>Sat</span>
              </div>
            </div>
          )}
          <div className="mt-4">
            <div className="shop-more-title">
              <a
                href={!me ? product.vendor_id.landing_page : `/shop?vendor_id=${product.vendor_id._id}`}
                className="btn btn-sm btn-outline-primary"
              >
                <div className="d-flex align-items-center">
                  <span>Shop {product.vendor_id.name}</span>
                  <ArrowRight size="14" className="ml-2" role="img" aria-label="Arrow right" />
                </div>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

ProductVendorComponent.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    tasting_notes: PropTypes.array,
    tags: PropTypes.array,
    altitude_max: PropTypes.number,
    altitude_min: PropTypes.number,
    roast: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    origin: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    process: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    vendor_id: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      img_url: PropTypes.string,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      landing_page: PropTypes.string.isRequired,
      fulfillment_timelines: PropTypes.object.isRequired,
      verifiedAddress: PropTypes.object.isRequired,
    }),
  }),
  me: PropTypes.object,
};

export const ProductVendor = memo(ProductVendorComponent);
