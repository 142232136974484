import { LogoutTypes, ChangeScaleTypes } from '@bottomless/common/store';
import { SendReviewTypes } from '../review';
import {
  GetProductsTypes,
  GetPopularProductsTypes,
  GetProductTypes,
  GetPersonalizedProduct,
  GetSimpleDynamicPrices,
  GetGroceryProductsTypes,
  GetCustomListTypes,
  MOVE_CUSTOM_LIST_ITEM,
  DELETE_CUSTOM_LIST_ITEM,
  SAVE_PRODUCT_SELECTION,
  GetProductAttributesTypes,
  UpdateCustomListElementTypes,
  GetFourSigmaticProductsTypes,
  AddCustomListElementTypes,
} from './product.actions';

const initialState = {
  data: [],
  grocery: [],
  grocery_categories: {},
  popular: [],
  personalized: null,
  isPersonalizedLoading: null,
  isLoading: false,
  groceryIsLoading: false,
  simpleDynamicPricing: [],
  wishlist: [],
  productSelection: {},
  attributes: null,
};

export const productReducer = (state = initialState, action) => {
  const cats = {};
  switch (action.type) {
    case GetProductsTypes.REQUEST:
    case GetFourSigmaticProductsTypes.REQUEST:
    case GetGroceryProductsTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetProductTypes.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GetPersonalizedProduct.REQUEST:
      return {
        ...state,
        isPersonalizedLoading: true,
      };

    case GetProductsTypes.SUCCESS:
    case GetFourSigmaticProductsTypes.SUCCESS:
      return {
        ...state,
        data: Array.isArray(action.payload?.data)
          ? action.payload.data
              .map(({ variants, ...product }) => ({
                ...product,
                variants: variants.filter(variant => variant.available !== false),
              }))
              .filter(({ variants }) => variants.length)
          : [],
        isLoading: false,
      };

    case GetGroceryProductsTypes.SUCCESS:
      for (const prod of action.payload.data) {
        if (!(prod.category in cats)) {
          cats[prod.category] = new Set();
        }
        cats[prod.category].add(prod.sub_category);
      }
      for (const cat in cats) {
        cats[cat] = [...cats[cat]];
      }
      return {
        ...state,
        grocery: action.payload.data,
        grocery_categories: cats,
        isLoading: false,
      };

    case GetProductTypes.SUCCESS: {
      const index = state.data.findIndex(v => v._id === action.payload._id);

      return {
        ...state,
        data:
          index !== -1
            ? [...state.data.slice(0, index), action.payload, ...state.data.slice(index + 1)]
            : [...state.data, action.payload],
        isLoading: false,
      };
    }

    case GetPopularProductsTypes.SUCCESS:
      return {
        ...state,
        popular: action.payload.data,
        groceryIsLoading: false,
      };

    case GetPersonalizedProduct.SUCCESS:
      return {
        ...state,
        personalized: action.payload,
        isPersonalizedLoading: false,
      };

    case GetCustomListTypes.SUCCESS:
      return {
        ...state,
        wishlist: action.payload?.items,
      };

    case UpdateCustomListElementTypes.SUCCESS:
      return {
        ...state,
        wishlist: action.payload,
      };

    case AddCustomListElementTypes.SUCCESS:
      return {
        ...state,
        wishlist: action.payload.products,
      };

    case GetSimpleDynamicPrices.SUCCESS:
      return {
        ...state,
        simpleDynamicPricing: action.payload,
      };

    case GetProductAttributesTypes.SUCCESS:
      return {
        ...state,
        attributes: action.payload,
      };

    case GetPersonalizedProduct.FAILURE:
    case GetProductsTypes.FAILURE:
    case GetFourSigmaticProductsTypes.FAILURE:
    case GetProductTypes.FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case GetGroceryProductsTypes.FAILURE:
      return {
        ...state,
        isPersonalizedLoading: false,
      };

    case SendReviewTypes.SUCCESS:
      return {
        ...state,
        data: state.data.map(product =>
          product._id === action.payload.product_id
            ? { ...product, feedback: [...product.feedback, action.payload] }
            : product
        ),
      };

    case MOVE_CUSTOM_LIST_ITEM: {
      const newOrderedWishList = [...state.wishlist];
      const movableItem = newOrderedWishList.splice(action.payload.sourceIndex, 1);
      newOrderedWishList.splice(action.payload.destinationIndex, 0, ...movableItem);

      return {
        ...state,
        wishlist: newOrderedWishList,
      };
    }

    case DELETE_CUSTOM_LIST_ITEM:
      return {
        ...state,
        wishlist: state.wishlist.filter((_, i) => i !== action.payload.index),
      };

    case SAVE_PRODUCT_SELECTION:
      return {
        ...state,
        productSelection: action.payload,
      };

    case LogoutTypes.SUCCESS:
    case ChangeScaleTypes.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
