import PropTypes from 'prop-types';
import React from 'react';
import { DateFormat } from '@bottomless/common/components';

export const UserProductInfo = ({ productId, flatOrders }) => {
  return (
    <>
      {flatOrders[productId] && (
        <div className="small mt-2">
          <span className="text-secondary">Last ordered by</span> <span>{flatOrders[productId]?.fulfilled_by}</span>{' '}
          <span className="text-secondary">on </span>
          <span>
            <DateFormat date={flatOrders[productId]?.date_ordered} />
          </span>{' '}
          {flatOrders[productId]?.productFeedback && (
            <>
              <div className="br" />
              <span>
                Feedback: {flatOrders[productId]?.productFeedback === 'like' && <i className="fa fa-heart coral" />}
                {flatOrders[productId]?.productFeedback === 'dislike' && <i className="fa fa-ban coral" />}
              </span>
            </>
          )}
        </div>
      )}
    </>
  );
};

UserProductInfo.propTypes = {
  productId: PropTypes.string.isRequired,
  flatOrders: PropTypes.object.isRequired,
};
