import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { CheckboxBox } from '@bottomless/common/src/components';
import { BuildCard } from './BuildCard';
import { AdvanceBuilderHeading } from './AdvanceBuilderHeading';
import { useProductsCount } from '../hooks/use-products-count.hook';

export const Tags = ({
  onChange,
  onEspressoChange,
  disabled,
  products,
  isVendorLockedUser,
  hideOptionWithoutProducts,
  optionFilter,
}) => {
  const decafPersonalized = useMemo(() => ({ ...optionFilter, tag: ['decaf'] }), [optionFilter]);
  const decafCount = useProductsCount({
    products,
    personalized: decafPersonalized,
    optional: true,
  });

  const organicPersonalized = useMemo(() => ({ ...optionFilter, tag: ['organic'] }), [optionFilter]);
  const organicCount = useProductsCount({
    products,
    personalized: organicPersonalized,
    optional: true,
  });

  const espressoPersonalized = useMemo(() => ({ ...optionFilter, tag: ['espresso'] }), [optionFilter]);
  const espressoCount = useProductsCount({
    products,
    personalized: espressoPersonalized,
    optional: true,
  });

  if (hideOptionWithoutProducts && !decafCount && !organicCount && !espressoCount) {
    return <></>;
  }

  return (
    <>
      <AdvanceBuilderHeading title="Tags" subtitle="Optional" />
      <Row className="pb-4 pt-2">
        {!(hideOptionWithoutProducts && !decafCount) && (
          <Col lg="3" md="3" xs="6" className="answer-card-container">
            <CheckboxBox
              name="tag.decaf"
              className="answer-card"
              label={<BuildCard name="decaf" />}
              value
              onChange={onChange}
            />
          </Col>
        )}
        {!(hideOptionWithoutProducts && !organicCount) && (
          <Col lg="3" md="3" xs="6" className="answer-card-container">
            <CheckboxBox
              name="tag.organic"
              className="answer-card"
              label={<BuildCard name="organic" />}
              value
              onChange={onChange}
            />
          </Col>
        )}
        {!isVendorLockedUser && (
          <Col lg="3" md="3" xs="6" className="answer-card-container">
            <CheckboxBox
              name="tag.cold_brew"
              className="answer-card"
              label={<BuildCard name="cold brew" />}
              value
              onChange={onChange}
            />
          </Col>
        )}
        {!(hideOptionWithoutProducts && !espressoCount) && (
          <Col lg="3" md="3" xs="6" className="answer-card-container">
            <CheckboxBox
              className="answer-card"
              name="tag.espresso"
              label={<BuildCard name="espresso" />}
              value
              disabled={disabled}
              onChange={onEspressoChange}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

Tags.propTypes = {
  onChange: PropTypes.func.isRequired,
  onEspressoChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isVendorLockedUser: PropTypes.bool,
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  optionFilter: PropTypes.bool,
};

Tags.defaultProps = {
  isVendorLockedUser: false,
};
