import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FrontendPagination } from '@bottomless/common/src/components/FrontendPagination';
import { ReviewForm } from '../../../../components/ReviewForm';
import { DisplayReviews } from './DisplayReviews';
import { Heart, Slash } from 'react-feather';
import { Review } from './Review';

export const Reviews = ({
  product,
  me,
  lastOrder,
  onSuccess,
  onSubmit,
  onPictureUpload,
  reviewsPerPage = 6,
  paginationDisplayNumberRange,
  numberOfRichReviews,
  toggleLikesDislikesModal,
  highlightedReviewId,
}) => {
  const [currentReviews, setCurrentReviews] = useState(null);

  const [reviews] = useMemo(() => {
    const reviews = product.feedback
      .filter(feedback => !!feedback.brewing_method)
      .map(feedback => ({ ...feedback, timestamp: new Date(feedback.timestamp) }))
      .sort((a, b) => b.timestamp - a.timestamp);

    return [reviews];
  }, [product]);

  const canMakeAReview = useMemo(
    () => me && lastOrder && !product.feedback.find(feedback => feedback.user_id._id === me._id),
    [product, lastOrder, me]
  );

  const highlighedReview = useMemo(
    () => highlightedReviewId && reviews.find(review => review._id === highlightedReviewId),
    [reviews, highlightedReviewId]
  );

  if (!reviews.length) {
    return <div className="text-secondary">No reviews yet, you can be the first!</div>;
  }

  if (highlighedReview) {
    return (
      <div className="highlighted-review">
        <h2 className="text-center">{highlighedReview.user_id.first_name}&apos;s review</h2>
        <Review feedback={highlighedReview} fullWidth />
      </div>
    );
  }

  return (
    <>
      <h2 className="text-center">{numberOfRichReviews} reviews</h2>
      <div className="d-flex justify-content-center mb-5" onClick={toggleLikesDislikesModal}>
        <div className="mr-2">
          <span className="text-danger">
            <Heart size="20" fill="currentColor" role="img" aria-label="Heart" />
          </span>{' '}
          {product.likes} likes
        </div>
        <div className="ml-2">
          <span className="text-danger">
            <Slash size="20" role="img" aria-label="Slash" />
          </span>{' '}
          {product.dislikes} dislikes
        </div>
      </div>
      {canMakeAReview && (
        <div className="mb-3">
          <h5 className="mb-3">Submit your review:</h5>
          <ReviewForm
            order={lastOrder}
            onSuccess={onSuccess}
            product={product}
            onPictureUpload={onPictureUpload}
            onSubmit={onSubmit}
          />
        </div>
      )}
      <DisplayReviews currentReviews={currentReviews} />
      <FrontendPagination
        reviews={reviews}
        reviewsPerPage={reviewsPerPage}
        setCurrentReviews={setCurrentReviews}
        paginationDisplayNumberRange={paginationDisplayNumberRange}
      />
    </>
  );
};

Reviews.propTypes = {
  product: PropTypes.shape({
    feedback: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      })
    ).isRequired,
    likes: PropTypes.number,
    dislikes: PropTypes.number,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPictureUpload: PropTypes.func.isRequired,
  me: PropTypes.object,
  lastOrder: PropTypes.object,
  reviewsPerPage: PropTypes.number,
  paginationDisplayNumberRange: PropTypes.number.isRequired,
  numberOfRichReviews: PropTypes.number,
  toggleLikesDislikesModal: PropTypes.func.isRequired,
  highlightedReviewId: PropTypes.string,
};
