import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import { Loader } from 'react-feather';
import { Link } from 'react-router-dom';
import { DataLoadingWrapper } from './DataLoadingWrapper';
import './DataHandler.scss';

export const DataHandler = ({
  children = 'No data',
  isLoading,
  data,
  error,
  loadingText = 'Loading data',
  skipLoading = false,
  withMarginTop = true,
  withProfileLink = true,
  className,
  withPlaceholder,
  onProfileRedirect,
}) => {
  const noData = useMemo(() => !data || (Array.isArray(data) && !data.length), [data]);
  const LoadingWrapper = useMemo(() => (withPlaceholder ? DataLoadingWrapper : Fragment), [withPlaceholder]);

  if (error) {
    return (
      <div className={classNames('text-center', className, { 'mt-5': withMarginTop, className })}>
        <div className="text-danger text-large">
          {error?.response?.message || error?.message || 'Something went wrong'}
        </div>
        {withProfileLink && !onProfileRedirect && (
          <div>
            <Link to="/profile">Go to Profile</Link>
          </div>
        )}
        {withProfileLink && onProfileRedirect && (
          <div>
            <a className="profile-link" onClick={onProfileRedirect}>
              Go to Profile
            </a>
          </div>
        )}
      </div>
    );
  }

  if (noData && isLoading && !skipLoading) {
    return (
      <LoadingWrapper>
        <div
          className={classNames('d-flex align-items-center justify-content-center placeholder-text', className, {
            'mt-5': withMarginTop,
          })}
        >
          <Loader size="20" className="spin mr-2" />
          <span>{loadingText}</span>
        </div>
      </LoadingWrapper>
    );
  }

  if (noData) {
    return children;
  }

  return null;
};

DataHandler.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  children: PropTypes.node,
  withMarginTop: PropTypes.bool,
  error: PropTypes.object,
  withProfileLink: PropTypes.bool,
  className: PropTypes.string,
  skipLoading: PropTypes.bool,
  withPlaceholder: PropTypes.bool,
  onProfileRedirect: PropTypes.func,
};
