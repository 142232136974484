import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { RadioBox } from '@bottomless/common/src/components';
import { BuildCard } from '../BuildCard';
import { useProductsCount } from '../../hooks/use-products-count.hook';

export const SizeComponent = ({ disabled, onChange, size, products, hideOptionWithoutProducts, optionFilter }) => {
  const sizePersonalized = useMemo(() => ({ ...optionFilter, size }), [optionFilter, size]);
  const productCount = useProductsCount({
    products,
    personalized: sizePersonalized,
    optional: true,
  });

  if (hideOptionWithoutProducts && !productCount) {
    return <></>;
  }

  return (
    <Col lg="3" md="3" xs="4" className="answer-card-container">
      <RadioBox
        name="size"
        type="radio"
        className="answer-card"
        value={String(size)}
        label={<BuildCard name={`${size} oz`} className={`card-${size}`} textClassName="size" />}
        disabled={disabled}
        onChange={onChange}
      />
    </Col>
  );
};

SizeComponent.propTypes = {
  disabled: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  hideOptionWithoutProducts: PropTypes.bool,
  size: PropTypes.number.isRequired,
  optionFilter: PropTypes.object,
};

export const Size = React.memo(SizeComponent);
