import Cookies from 'universal-cookie';
const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;

export const DeelLandingRedirect = () => {
  const cookies = new Cookies();
  cookies.set('last_landing_rule', '/deel', { domain: cookieDomain });
  cookies.set('landing_page', '/deel', { domain: cookieDomain });
  window.location = '/';
  return null;
};
