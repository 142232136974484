import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Simple } from '../Simple/Simple';
import './SimplePage.scss';
import classNames from 'classnames';

export const SimplePage = ({
  children,
  title,
  description,
  className,
  wrapperClassName,
  withFooter,
  desktopColumns,
  stretch,
}) => {
  return (
    <Simple withFooter={withFooter} stretch={stretch} className={classNames('page-simple-layout', className)}>
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" sm={desktopColumns || '8'}>
            {(title || description) && (
              <article className="text-large mb-4">
                {title && <h1 className="text-center">{title}</h1>}
                {description && <p className="text-center text-secondary">{description}</p>}
              </article>
            )}
            <div className={classNames('d-flex flex-column', wrapperClassName)}>{children}</div>
          </Col>
        </Row>
      </Container>
    </Simple>
  );
};

SimplePage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  withFooter: PropTypes.bool,
  children: PropTypes.node,
  stretch: PropTypes.bool,
  desktopColumns: PropTypes.string,
  wrapperClassName: PropTypes.string,
};
