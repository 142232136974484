import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { useCookies } from 'react-cookie';
import { connect, useStore } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { Container } from 'reactstrap';
import { DataLoading, ErrorBoundary } from '@bottomless/common/components';
import { useConditionalDataEffect, useDataEffect } from '@bottomless/common/hooks';
import { addToastAction } from '@bottomless/common/store';
import { withMetaTags } from '../../components/MetaTags/MetaTags';
import { IsLoggedIn } from '../../guards/is-logged.guard';
import { Simple } from '../../layouts/Simple/Simple';
import {
  getCheckoutAction,
  getPersonalizedProductAction,
  getProductAttributesAction,
  getProductOriginsAction,
  getProductVendorsAction,
  getStartedAction,
  makeBottomlessProductAction,
  setPersonalizedAction,
  getPricingRuleAction,
  getSimpleDynamicPricesAction,
  getDynamicPricesAction,
  setShipmentAction,
  getStartedGiftAction,
  finishCheckoutAction,
  getStartedCompleteAction,
  getShopFiltersAction,
} from '../../store';
import { getVendorByIdAction } from '../../store/vendor';
import {
  clearCustomRotationAction,
  saveCustomRotationAnswerAction,
  sendPersonalizedAnswersAction,
} from '../../store/quiz';
import { getNextBusinessDay, getNextBusinessDayOrToday } from '../../utils/dates';
import { EVENT_FILTER_CHECKOUT_STEP, trackEvent } from '../../utils/tracker';
import { Question1 } from './components/Question1';
import { Question2, WHOLE_BEANS_ANSWER } from './components/Question2';
import { Question3 } from './components/Question3';
import { Question4 } from './components/Question4';
import { Question5 } from './components/Question5';
import { Question6 } from './components/Question6';
import { Summary } from './components/Summary';
import { ErrorAction } from './components/ErrorAction';
import { getPersonalized } from './util/get-personalized';
import './FilterCheckout.scss';

const initialQuestions = [Question1, Question2, Question4, Question5, Question3, Question6];

const PERSONALIZED_REQUIRED_FIELDS = ['grind', 'origin', 'price_type', 'roast', 'single_origins', 'size', 'tag'];

const FilterCheckoutPageComponent = ({
  history,
  location,
  checkoutId,
  saveAnswer,
  answers,
  sendAnswers,
  getDynamicPrices,
  getStarted,
  getStartedGift,
  makeBottomless,
  addToast,
  personalizedProduct,
  getPersonalizedProduct,
  isLoading,
  me,
  clearCustomRotation,
  getProductAttributes,
  getPricingRule,
  only32,
  quizPersonalized,
  getSimpleDynamicPricing,
  simpleDynamicPricing,
  quizFirstProduct,
  getCheckout,
  setShipment,
  getVendorById,
  overrideQuestions,
  finishCheckout,
  finishRegistration,
  persistButton,
  getShopFilters,
  attributes,
}) => {
  const store = useStore();
  const [{ checkout: checkoutCookie }] = useCookies(['checkout']);
  const [pricingRule, setPricingRule] = useState(null);
  const isMobile = useMediaQuery({ maxDeviceWidth: 767 });

  const isLoggedIn = useMemo(() => !!me, [me]);
  const skipSizeRelated = useMemo(() => !isLoggedIn && !!pricingRule?.pay_shipment_when_credit, [
    isLoggedIn,
    pricingRule,
  ]);

  const questions = useMemo(
    () =>
      overrideQuestions(initialQuestions)
        .filter(question => {
          if (question.sizeRelated && skipSizeRelated) {
            return false;
          }

          if (question.scheduleRelated && isLoggedIn) {
            return false;
          }

          return true;
        })
        .map(
          Question =>
            // eslint-disable-next-line react/prop-types
            function Comp({ style, ...props }) {
              return (
                <animated.div style={style}>
                  <Question {...props} />
                </animated.div>
              );
            }
        ),
    [skipSizeRelated, isLoggedIn, overrideQuestions]
  );

  const query = qs.parse(location.search);
  const [index, setIndex] = useState((query.question || 1) - 1);
  const [checkout, setCheckout] = useState(null);
  const { dateOverride, ...personalized } = useMemo(
    () => (quizPersonalized ? quizPersonalized : getPersonalized(answers)),
    [answers, quizPersonalized]
  );
  const [finished, setFinished] = useState(
    query.finished && PERSONALIZED_REQUIRED_FIELDS.reduce((all, field) => all && !!personalized[field], true)
  );

  const onPaymentSuccess = async () => {
    const {
      payload: { token },
    } = await finishRegistration();

    history.push(token ? `/setup_password/${token}` : '/setup_text');
  };

  const onGetStarted = useCallback(
    async (...data) => {
      const result = await getStarted(...data);

      if (dateOverride === '0') {
        await setShipment(result.payload.checkoutId, { shipment_date: undefined, dateOverride });
      }

      if (dateOverride && dateOverride !== '0') {
        const nextBusinessDayOrToday = getNextBusinessDayOrToday();

        const shipmentDate =
          dateOverride === 'nextBusiness'
            ? getNextBusinessDay()
            : new Date(nextBusinessDayOrToday.setDate(nextBusinessDayOrToday.getDate() + 7 * Number(dateOverride)));

        await setShipment(result.payload.checkoutId, {
          shipment_date: moment(shipmentDate).format('YYYY-MM-DD'),
          dateOverride,
        });
      }

      return result;
    },
    [getStarted, setShipment, dateOverride]
  );

  const getCheckoutById = useCallback(() => getCheckout(checkoutId || checkoutCookie), [
    getCheckout,
    checkoutId,
    checkoutCookie,
  ]);

  useConditionalDataEffect(checkoutId || checkoutCookie, getCheckoutById, setCheckout);
  useDataEffect(getProductAttributes);
  useDataEffect(getPricingRule, setPricingRule);
  useDataEffect(getSimpleDynamicPricing);

  const computedPricingRule = useMemo(() => (checkout ? checkout.pricing_rule : me ? me.pricing_rule : pricingRule), [
    checkout,
    pricingRule,
    me,
  ]);

  const summaryAnswers = useMemo(
    () =>
      Object.entries(answers)
        .filter(([, answer]) => answer !== '' && !answer.includes('dateOverride'))
        .reduce((all, [key, value]) => ({ ...all, [key]: value }), {}),
    [answers]
  );

  const onSignUp = ({ checkoutId }) => {
    history.push(`/get_started/${checkoutId}`);
  };

  const transitions = useTransition(index, p => p, {
    from: { opacity: 0, ...(index ? { transform: 'translate3d(0, 0, 0)', width: '100%' } : {}) },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)', width: '100%' },
    leave: { opacity: 0, position: 'absolute', transform: 'translate3d(0, 0, 0)', width: '100%' },
  });

  useEffect(() => {
    getPersonalizedProduct();
  }, [getPersonalizedProduct]);

  const [done, setDone] = useState(false);

  useEffect(() => {
    if (query.finished) {
      setDone(true);
    }
    if (!query.question && !query.finished && !done && Object.keys(answers || []).length) {
      clearCustomRotation();
    }
  }, [query, answers, done, setDone, clearCustomRotation, location.search]);

  useEffect(() => {
    setIndex((query.question || 1) - 1);
    if (query.finished) {
      if (PERSONALIZED_REQUIRED_FIELDS.reduce((all, field) => all && !!personalized[field], true)) {
        setFinished(true);
      } else {
        history.push(location.pathname);
      }
    } else {
      setFinished(false);
    }
  }, [history, location.pathname, location.search, personalized, query.finished, query.question]);

  useEffect(() => {
    if (index > 0 && !answers[1] && answers[1] !== '') {
      history.push({
        pathname: location.pathname,
      });
    }
  }, [history, answers, location, index]);

  const onAnswer = index => answer => {
    const currentAnswers = store.getState().quiz.customRotation;

    for (const otherAnswer of answer.otherAnswers || []) {
      const otherIndex = Object.entries(currentAnswers)
        .find(([, value]) => value.includes(otherAnswer.key))
        ?.shift();

      if (otherIndex) {
        saveAnswer(index, otherAnswer.value);
      }
    }

    saveAnswer(index + 1, answer.value);

    const isFinished =
      index === questions.length - 1 || (answer.value === WHOLE_BEANS_ANSWER && index === questions.length - 2);

    const nextIndex = 1 + index + (answer.value === WHOLE_BEANS_ANSWER ? 2 : 1);

    trackEvent(`COMPLETED_STEP_${index}`, answer.event);
    const answers = store.getState().quiz.customRotation;
    const personalized = getPersonalized(answers);
    trackEvent(EVENT_FILTER_CHECKOUT_STEP, {
      step: index + 1,
      answer: answer.value,
      personalized,
    });

    history.push({
      pathname: location.pathname,
      search: qs.stringify(isFinished ? { finished: true } : { question: nextIndex }),
    });
  };

  const onMakeBottomlessSuccess = () => {
    addToast('Product has been successfully saved');
    history.push('/profile');
  };

  return (
    <IsLoggedIn>
      <ErrorBoundary action={<ErrorAction />}>
        <Simple className="page-quiz" withCheckoutButton={false}>
          <DataLoading
            count={!finished || (personalizedProduct && computedPricingRule) ? 1 : 0}
            isLoading={isLoading}
          />
          {!finished && (
            <Container>
              <div className="question-container">
                {transitions.map(({ item, props, key }) => {
                  const Page = questions[item];

                  if (!Page) {
                    return null;
                  }

                  return (
                    <Page
                      answers={answers}
                      key={key}
                      product={personalizedProduct}
                      style={props}
                      only32={only32}
                      index={item + 1}
                      total={questions.length}
                      onAnswer={onAnswer(item)}
                      personalized={personalized}
                      pricingRule={computedPricingRule}
                      simpleDynamicPricing={simpleDynamicPricing}
                      isMobile={isMobile}
                    />
                  );
                })}
              </div>
            </Container>
          )}
          {finished && attributes && computedPricingRule && pricingRule && personalizedProduct && (
            <Summary
              answers={summaryAnswers}
              sendAnswers={sendAnswers}
              getDynamicPrices={getDynamicPrices}
              onSignUp={onSignUp}
              getStarted={onGetStarted}
              getStartedGift={getStartedGift}
              makeBottomless={makeBottomless}
              onMakeBottomlessSuccess={onMakeBottomlessSuccess}
              personalizedProduct={personalizedProduct}
              me={me}
              checkout={checkout?._id}
              grinds={attributes.grinds}
              pricingRule={computedPricingRule}
              quizFirstProduct={quizFirstProduct}
              dateOverride={dateOverride}
              getVendorById={getVendorById}
              finishCheckout={finishCheckout}
              onPaymentSuccess={onPaymentSuccess}
              persistButton={persistButton}
              getShopFilters={getShopFilters}
            />
          )}
        </Simple>
      </ErrorBoundary>
    </IsLoggedIn>
  );
};

FilterCheckoutPageComponent.propTypes = {
  answers: PropTypes.object,
  me: PropTypes.object,
  saveAnswer: PropTypes.func.isRequired,
  clearCustomRotation: PropTypes.func.isRequired,
  sendAnswers: PropTypes.func.isRequired,
  getStarted: PropTypes.func.isRequired,
  getStartedGift: PropTypes.func.isRequired,
  makeBottomless: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  getPersonalizedProduct: PropTypes.func.isRequired,
  personalizedProduct: PropTypes.object,
  isLoading: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  getProductAttributes: PropTypes.func.isRequired,
  getPricingRule: PropTypes.func.isRequired,
  only32: PropTypes.bool,
  quizPersonalized: PropTypes.object,
  getSimpleDynamicPricing: PropTypes.func.isRequired,
  simpleDynamicPricing: PropTypes.array.isRequired,
  quizFirstProduct: PropTypes.object,
  getCheckout: PropTypes.func.isRequired,
  getVendorById: PropTypes.func,
  checkoutId: PropTypes.string,
  overrideQuestions: PropTypes.func.isRequired,
  setShipment: PropTypes.func.isRequired,
  getDynamicPrices: PropTypes.func.isRequired,
  finishCheckout: PropTypes.func.isRequired,
  finishRegistration: PropTypes.func.isRequired,
  persistButton: PropTypes.bool,
  getShopFilters: PropTypes.func.isRequired,
  attributes: PropTypes.shape({ grinds: PropTypes.array.isRequired }),
};

FilterCheckoutPageComponent.defaultProps = {
  setShipment: PropTypes.func.isRequired,
  overrideQuestions: questions => questions,
};

export const FilterCheckoutPage = connect(
  (
    { product: { personalized, isLoading }, user: { me }, quiz, product },
    {
      match: {
        params: { id },
      },
    }
  ) => ({
    personalizedProduct: personalized,
    isLoading,
    me,
    answers: quiz.customRotation,
    quizFirstProduct: quiz.firstProduct,
    quizPersonalized: quiz.personalized,
    simpleDynamicPricing: product.simpleDynamicPricing,
    checkoutId: id,
    attributes: product.attributes,
  }),
  dispatch => ({
    saveAnswer: (question, answer) => dispatch(saveCustomRotationAnswerAction(question, answer)),
    clearCustomRotation: () => dispatch(clearCustomRotationAction()),
    sendAnswers: answers => dispatch(sendPersonalizedAnswersAction(answers)),
    getDynamicPrices: data => dispatch(getDynamicPricesAction(data)),
    getStarted: data => dispatch(getStartedAction(data)),
    getStartedGift: data => dispatch(getStartedGiftAction(data)),
    makeBottomless: (id, data) => dispatch(makeBottomlessProductAction(id, data)),
    addToast: message => dispatch(addToastAction(message)),
    getOrigins: () => dispatch(getProductOriginsAction()),
    getPersonalizedProduct: () => dispatch(getPersonalizedProductAction()),
    setPersonalizedAction: (id, data) => dispatch(setPersonalizedAction(id, data)),
    getCheckout: id => dispatch(getCheckoutAction(id)),
    getVendors: () => dispatch(getProductVendorsAction()),
    getVendorById: id => dispatch(getVendorByIdAction(id)),
    getProductAttributes: () => dispatch(getProductAttributesAction()),
    getPricingRule: () => dispatch(getPricingRuleAction()),
    getSimpleDynamicPricing: () => dispatch(getSimpleDynamicPricesAction()),
    setShipment: (id, data) => dispatch(setShipmentAction(id, data)),
    finishCheckout: (id, data) => dispatch(finishCheckoutAction(id, data)),
    finishRegistration: () => dispatch(getStartedCompleteAction()),
    getShopFilters: data => dispatch(getShopFiltersAction(data)),
  })
)(
  withMetaTags({
    title: 'Bottomless.com: Coffee Quiz',
  })(FilterCheckoutPageComponent)
);
