import { createStore as createBottomlessStore } from '@bottomless/common/store';

import { authReducer } from './auth';
import { openShopReducer } from './open-shop';
import { orderReducer } from './order';
import { productReducer } from './product';
import { quizReducer } from './quiz';
import { rotationReducer } from './rotation';
import { statsReducer } from './stats';
import { userReducer } from './user/user.reducer';
import { vendorReducer } from './vendor/vendor.reducer';
import { shipmentReducer } from './shipment/shipment.reducer';
import { petFoodReducer } from './pet-food';
import { categoryReducer } from './category';
import { staticData } from './data';
import { scaleReducer } from './scale/scale.reducer';
import { pricingRuleReducer } from './pricing_rules/pricing_rules.reducers';

export const createStore = () =>
  createBottomlessStore(
    {
      auth: authReducer,
      user: userReducer,
      order: orderReducer,
      stats: statsReducer,
      product: productReducer,
      rotation: rotationReducer,
      vendor: vendorReducer,
      quiz: quizReducer,
      openShop: openShopReducer,
      shipment: shipmentReducer,
      petFood: petFoodReducer,
      category: categoryReducer,
      scale: scaleReducer,
      pricingRule: pricingRuleReducer,
    },
    undefined,
    { staticData }
  );
