import { RSAA } from 'redux-api-middleware';
import { createAction } from '@bottomless/common/store';

export const AssignScaleTypes = createAction('Scales', 'Assign');

export const assignScaleAction = (id, data) => ({
  [RSAA]: {
    endpoint: `scales/assign/${id}`,
    method: 'POST',
    body: data,
    types: [AssignScaleTypes.REQUEST, AssignScaleTypes.SUCCESS, AssignScaleTypes.FAILURE],
  },
});

export const GetScaleAccountsTypes = createAction('Scales', 'Get accounts');

export const getScaleAccountsAction = () => ({
  [RSAA]: {
    endpoint: `scales/accounts`,
    method: 'GET',
    types: [GetScaleAccountsTypes.REQUEST, GetScaleAccountsTypes.SUCCESS, GetScaleAccountsTypes.FAILURE],
  },
});

export const GetScaleStatusTypes = createAction('Scales', 'Get status');

export const getScaleStatusAction = () => ({
  [RSAA]: {
    endpoint: `scales/status`,
    method: 'GET',
    types: [GetScaleStatusTypes.REQUEST, GetScaleStatusTypes.SUCCESS, GetScaleStatusTypes.FAILURE],
  },
});

export const ActivateScaleTypes = createAction('Scales', 'Activate');

export const activateScaleAction = data => ({
  [RSAA]: {
    endpoint: `scales/activate`,
    method: 'POST',
    body: data,
    types: [ActivateScaleTypes.REQUEST, ActivateScaleTypes.SUCCESS, ActivateScaleTypes.FAILURE],
  },
});

export const SetupScaleTypes = createAction('Scales', 'Setup');

export const setupScaleAction = () => ({
  [RSAA]: {
    endpoint: 'scales/setup',
    method: 'POST',
    types: [SetupScaleTypes.REQUEST, SetupScaleTypes.SUCCESS, SetupScaleTypes.FAILURE],
  },
});

export const SetupScaleUTypes = createAction('Scales', 'Setup U');

export const setupScaleUAction = data => ({
  [RSAA]: {
    endpoint: 'scales/setup_u',
    method: 'POST',
    body: data,
    types: [SetupScaleUTypes.REQUEST, SetupScaleUTypes.SUCCESS, SetupScaleUTypes.FAILURE],
  },
});
