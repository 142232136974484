import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import { Link2, Activity } from 'react-feather';
import { ScaleStatus } from '../../../constants';
import { Box } from '../../Box';
import { Weight } from '../../Weight';
import { Battery } from '../../Battery';
import { MiniGraph } from './MiniGraph';
import { Image } from '../../Image';
import { DateFormat } from '../../DateFormat';
import { isWholeNumber } from '../../../utils';

const STATUS_COLOR = {
  [ScaleStatus.Pending]: 'bg-secondary',
  [ScaleStatus.Connected]: 'bg-success',
  [ScaleStatus.Disconnected]: 'bg-danger',
};

export const ScaleConnected = ({ status, stats, link, borderColor, backgroundColor, noScaleImage }) => {
  const bgClass = useMemo(() => STATUS_COLOR[status?.scale_status] || 'bg-warning', [status]);
  const isConnected = useMemo(() => status?.scale_status === ScaleStatus.Connected, [status]);
  const isPending = useMemo(() => status?.scale_status === ScaleStatus.Pending, [status]);
  const wasScaleLastConnected = useMemo(
    () => Boolean(status.scale_last_connected && isWholeNumber(status.scale_last_weight)),
    [status]
  );

  return (
    <Box to={link}>
      <Row className="justify-content-between">
        <Col xs={6} md={7} lg={6} className="d-flex">
          <div className="d-flex align-items-center">
            {!noScaleImage && (
              <Image src="scale-sketch-transparent-2.png" alt="Scale" height="75" className="mr-3 image-scale" />
            )}
            <div className="small">
              {status?.scale_status && (
                <div className="d-flex align-items-center">
                  <div className="scale-icon">
                    <i className={classNames('scale-status', bgClass)}></i>
                  </div>
                  <span className={classNames('scale-status-text font-weight-bold', { 'text-capitalize': !isPending })}>
                    {isPending ? 'Waiting for data...' : status.scale_status}
                  </span>
                </div>
              )}
              {isConnected && isWholeNumber(status?.scale_last_weight) && (
                <div className="d-flex align-items-center">
                  <div className="scale-icon">
                    <Activity width="14" height="14" />
                  </div>
                  <Weight value={status.scale_last_weight} toFixed={1} />
                </div>
              )}
              {isConnected && isWholeNumber(status?.scale_battery_level) && (
                <div className="d-flex align-items-center">
                  <Battery className="scale-icon" batteryLevel={status.scale_battery_level} />{' '}
                  {Math.round(status.scale_battery_level)}%
                </div>
              )}
              {!isConnected && !isPending && (
                <a
                  href="https://support.bottomless.com/en/articles/909568"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="small d-flex align-items-center"
                  onClick={e => e.stopPropagation()}
                >
                  <div className="scale-icon">
                    <Link2 width="12" height="12" />
                  </div>
                  <div>Set-Up Guide</div>
                </a>
              )}
            </div>
          </div>
        </Col>
        <Col xs={5} md={4} lg={5} className="d-flex align-items-center justify-content-end">
          {stats.length > 0 && <MiniGraph stats={stats} borderColor={borderColor} backgroundColor={backgroundColor} />}
          {!stats.length && wasScaleLastConnected && (
            <div className="small text-right text-secondary">
              <div>
                <span>Last reading: </span>
                <span className="font-weight-bold">
                  <Weight value={status.scale_last_weight} toFixed={1} />
                </span>
                <span> on </span>
                <span className="font-weight-bold">
                  <DateFormat date={status.scale_last_connected} />
                </span>
              </div>
            </div>
          )}
          {!stats.length && !wasScaleLastConnected && (
            <div className="small text-right text-secondary">
              <div className="font-weight-bold">No data yet</div>
              <div>It will be available soon</div>
            </div>
          )}
        </Col>
      </Row>
    </Box>
  );
};

ScaleConnected.propTypes = {
  link: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  status: PropTypes.shape({
    scale_status: PropTypes.string,
    scale_last_weight: PropTypes.number,
    scale_battery_level: PropTypes.number,
    scale_last_connected: PropTypes.string,
  }),
  stats: PropTypes.array.isRequired,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  noScaleImage: PropTypes.bool,
};
