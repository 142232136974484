import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { IsAdminAuth } from '../guards/admin.guard';

const RedirectToAdmin = () => {
  const { pathname, search } = useLocation();

  window.location.replace(`${process.env.REACT_APP_ADMIN_APP_URL}${pathname}${search}`);

  return null;
};

export const AdminRoutes = props => (
  <IsAdminAuth {...props}>
    <RedirectToAdmin />
  </IsAdminAuth>
);

AdminRoutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
};
