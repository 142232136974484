import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Form, Field, SubmitButton, Radio, Box } from '@bottomless/common/components';
import { addToastAction } from '@bottomless/common/store';
import { checkoutHowYouHeardAboutUsAction } from '../../../store';
import { PanelSection } from '../../../components/PanelSection';

const Schema = Yup.object().shape({
  heard: Yup.string().required('This field is required'),
  other: Yup.string(),
});

const options = {
  word_of_mouth: 'Word of mouth',
  friend_family: 'Friend/Family',
  facebook: 'Facebook',
  instagram: 'Instagram',
  twitter: 'Twitter',
  reddit: 'Reddit',
  google: 'Google Search',
  roaster: 'Roaster',
  other: 'Other',
};

const HeardAboutUsComponent = ({ checkout_id, submitHowYouHeardAboutUs, howDidYouHear }) => {
  const [isFormDisabled, setIsFormDisabled] = useState(howDidYouHear ? true : false);

  const onSubmit = useCallback(
    async data => {
      await submitHowYouHeardAboutUs(checkout_id, data);
      setIsFormDisabled(true);
    },
    [checkout_id, submitHowYouHeardAboutUs]
  );

  const isHowDidYouHearOther = howDidYouHear && !Object.keys(options).includes(howDidYouHear);

  return (
    <PanelSection title="How did you hear about us?">
      <Box>
        {isFormDisabled ? (
          <div className="thank-you-text">Thank you for the feedback!</div>
        ) : (
          <div>
            <Form
              initialValues={{
                heard: isHowDidYouHearOther ? 'other' : howDidYouHear,
                other: isHowDidYouHearOther ? howDidYouHear : '',
              }}
              validationSchema={Schema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values }) => (
                <>
                  <div>
                    {Object.entries(options).map(([key, value]) => (
                      <div key={key}>
                        <div
                          className={`d-flex radio-option ${howDidYouHear && howDidYouHear !== key ? ' disabled' : ''}`}
                        >
                          <span>
                            <Radio
                              name="heard"
                              type="radio"
                              value={key}
                              label={value}
                              disabled={howDidYouHear && howDidYouHear !== key}
                            />{' '}
                          </span>
                        </div>
                        {(key === 'other' && values.heard === 'other') ||
                          (isHowDidYouHearOther && key === 'other' && (
                            <Field
                              name="other"
                              label="Other"
                              className="other-form"
                              value={isHowDidYouHearOther && howDidYouHear}
                            />
                          ))}
                      </div>
                    ))}
                  </div>
                  <SubmitButton
                    color="primary"
                    outline
                    isSubmitting={isSubmitting}
                    loadingText="Submitting"
                    className={`mt-5 ${!howDidYouHear ? '' : ' disabled'}`}
                    disabled={!!howDidYouHear}
                  >
                    Submit
                  </SubmitButton>
                </>
              )}
            </Form>
          </div>
        )}
      </Box>
    </PanelSection>
  );
};

HeardAboutUsComponent.propTypes = {
  checkout_id: PropTypes.string.isRequired,
  submitHowYouHeardAboutUs: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  howDidYouHear: PropTypes.string,
};

HeardAboutUsComponent.defaultProps = {
  howDidYouHear: '',
};

export const HeardAboutUs = connect(null, dispatch => ({
  submitHowYouHeardAboutUs: (id, data) => dispatch(checkoutHowYouHeardAboutUsAction(id, data)),
  addToast: message => dispatch(addToastAction(message)),
}))(HeardAboutUsComponent);
