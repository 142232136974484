import React from 'react';
import PropTypes from 'prop-types';

export const QuestionText = ({ index, title, subtitle }) => {
  return (
    <div className="question d-flex flex-column align-items-center">
      <div className="question-number text-dark font-weight-bold">0{index}</div>
      <div className="question-text d-flex flex-column align-items-center justify-content-center w-100 p-2">
        <h1 className="title font-italic">{title}</h1>
        <p className="mb-0 text-center">{subtitle}</p>
      </div>
    </div>
  );
};

QuestionText.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};
