import { intersection } from 'lodash-es';
import { useMemo } from 'react';
import get from 'lodash-es/get';
import { stripTags } from '@bottomless/common/utils';

export const FILTER_PRIORITIES = {
  NameOrVendor: 1,
  ImportantParameters: 2,
  LessImportantParameters: 3,
};

export const useProductsFilter = (products, filters) => {
  return useMemo(() => products.filter(p => isVisible(p, filters)), [products, filters]);
};

const isVisible = (product, filters) => {
  if (!Object.keys(filters).length) {
    return true;
  }

  return Object.entries(filters).reduce((res, [field, values]) => {
    if (!values.length) {
      return res;
    }

    return res && (methods[field] || methods.generic)(product, values, field);
  }, true);
};

const methods = {
  text: (product, data) => {
    if (Array.isArray(data)) {
      [data] = data;
    }
    const lowered = data?.toLowerCase();

    if (product.name?.toLowerCase().includes(lowered) || product.vendor_name?.toLowerCase().includes(lowered)) {
      product.sortPriority = FILTER_PRIORITIES.NameOrVendor;
      return true;
    }

    if (
      product.origin?.name?.toLowerCase().includes(lowered) ||
      (product.tags && product.tags.findIndex(tag => tag.name?.toLowerCase().includes(lowered)) !== -1) ||
      product.roast?.name?.toLowerCase().includes(lowered)
    ) {
      product.sortPriority = FILTER_PRIORITIES.ImportantParameters;
      return true;
    }

    if (
      (product.process && product.process.name?.toLowerCase().includes(lowered)) ||
      (product.tasting_notes &&
        product.tasting_notes.findIndex(
          tastingNote =>
            tastingNote.name?.toLowerCase().includes(lowered) ||
            tastingNote.category_id.name?.toLowerCase().includes(lowered)
        ) !== -1) ||
      (stripTags(product.description) || '').toLowerCase().includes(lowered)
    ) {
      product.sortPriority = FILTER_PRIORITIES.LessImportantParameters;
      return true;
    }

    product.sortPriority = +Infinity;
    return false;
  },
  roast: (product, data) => data.indexOf(product.roast?._id) !== -1,
  size: (product, data) =>
    intersection(
      data,
      product.variants.map(({ size }) => String(size))
    ).length > 0,
  origin: (product, data) => data.indexOf(product.origin?._id) !== -1,
  vendor_id: (product, data) => data.indexOf(product.vendor_id._id) !== -1,
  state: (product, data) => data.indexOf(product.vendor_id.verifiedAddress.state) !== -1,
  tag: (product, data) =>
    product.tags &&
    intersection(
      data,
      product.tags.map(tag => tag._id)
    ).length === data.length,
  type: (product, data) =>
    product.tags &&
    intersection(
      data,
      product.tags.map(tag => tag._id)
    ).length === data.length,
  process: (product, data) => product.process && data.indexOf(product.process._id) !== -1,
  tasting_notes: (product, data) =>
    product.tasting_notes &&
    intersection(
      data,
      product.tasting_notes.map(note => note.category_id._id)
    ).length > 0,
  grind: () => true,
  generic: (product, data, field) => {
    const productField = get(product, field);

    return Array.isArray(productField)
      ? !productField.length || intersection(data, productField).length > 0
      : data.indexOf(productField) !== -1;
  },
};
