import React, { memo, useCallback } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { SubmitButton } from '@bottomless/common/components';

const ProductFormActionsComponent = ({
  isSubmitting,
  isUpdatingFirstProduct,
  values,
  getPricingTier,
  isRoast,
  submitForm,
  onSelectClick,
  vendorRotation,
  isMobile,
  back,
  me,
}) => {
  const history = useHistory();

  const cancelBuilder = useCallback(() => {
    history.push(back);
  }, [history, back]);

  return (
    <div className="d-flex flex-lg-column container align-items-center pt-1 p-lg-0 pb-3 builder-actions">
      <SubmitButton
        isSubmitting={isSubmitting}
        block
        disabled={
          isSubmitting ||
          isUpdatingFirstProduct ||
          (!getPricingTier(values.price_type) && !vendorRotation) ||
          isRoast(values.roast) ||
          getPricingTier(values.price_type).productAmount === 0
        }
        loadingText="Processing..."
        onClick={onSelectClick(submitForm, false)}
      >
        {me ? 'Done Building' : 'Go to Checkout'}
      </SubmitButton>
      <Button
        type="button"
        className="btn-sm ml-2 mt-lg-2 mt-0 ml-lg-0"
        color="primary"
        block={!isMobile}
        outline
        onClick={cancelBuilder}
      >
        Cancel
      </Button>
    </div>
  );
};

ProductFormActionsComponent.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isUpdatingFirstProduct: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  getPricingTier: PropTypes.func.isRequired,
  isRoast: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  vendorRotation: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  back: PropTypes.string,
  me: PropTypes.object,
};

export const ProductFormActions = memo(ProductFormActionsComponent);
