import React from 'react';
import PropTypes from 'prop-types';
import { ProductCategoryManifest } from '../../../components/CategoryManifest';
import {
  PetFoodChangeProduct,
  CoffeeChangeProduct,
  AthleticGreensChangeProduct,
  GenericChangeProduct,
} from '../manifest';

const COMPONENTS = {
  CoffeeCheckoutChangeProduct: CoffeeChangeProduct,
  PetFoodCheckoutChangeProduct: PetFoodChangeProduct,
  AthleticGreensCheckoutChangeProduct: AthleticGreensChangeProduct,
  GenericCheckoutChangeProduct: GenericChangeProduct,
};

export const ChangeProduct = props => (
  <ProductCategoryManifest
    slugOrId={props.checkout.product.product._id}
    manifestKey="checkoutChangeProduct"
    components={COMPONENTS}
    fallbackComponent={GenericChangeProduct}
    withPlaceholder
    {...props}
  />
);

ChangeProduct.propTypes = {
  checkout: PropTypes.shape({
    product: PropTypes.shape({
      product: PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }),
    }),
  }),
};
