import React from 'react';
import PropTypes from 'prop-types';
import { Default } from '@bottomless/common/components';
import { Col, Row } from 'reactstrap';

export const ProductParameters = ({ product }) => {
  if (!product.origin?.name && !product.roast?.name && !product.process?.name) {
    return null;
  }

  return (
    <div className="my-4">
      <Row className="align-items-center justify-content-between text-center">
        <Col xs="4" className="product-parameter">
          <div className="extra-small text-secondary">Origin</div>
          <div className="parameter-value">
            <Default>{product.origin?.name}</Default>
          </div>
        </Col>
        <Col xs="4" className="product-parameter">
          <div className="extra-small text-secondary">Roast</div>
          <div className="parameter-value">
            <Default>{product.roast?.name}</Default>
          </div>
        </Col>
        <Col xs="4" className="product-parameter">
          <div className="extra-small text-secondary">Process</div>
          <div className="parameter-value">
            <Default>{product.process?.name}</Default>
          </div>
        </Col>
      </Row>
    </div>
  );
};

ProductParameters.propTypes = {
  product: PropTypes.shape({
    roast: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    origin: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    process: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }),
};
