import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getMeAction } from '../store';

const IsLoggedInComponent = ({ children, me, isAuth, getMe }) => {
  const [checkAuthState, setCheckAuthState] = useState(true);

  useEffect(() => {
    if (!isAuth && checkAuthState) {
      getMe();
      setCheckAuthState(false);
    }
  }, [isAuth, checkAuthState, getMe]);

  if (me) {
    Sentry.configureScope(scope => {
      scope.setUser({ id: me._id, email: me.local.email, username: `${me.first_name} ${me.last_name}` });
    });
  }

  return children;
};

IsLoggedInComponent.propTypes = {
  children: PropTypes.node.isRequired,
  me: PropTypes.object,
  getMe: PropTypes.func.isRequired,
  isAuth: PropTypes.bool.isRequired,
};

export const IsLoggedIn = connect(
  ({ user: { me }, auth: { isAuth } }) => ({ me, isAuth }),
  dispatch => ({ getMe: () => dispatch(getMeAction()) })
)(IsLoggedInComponent);
