import { useToggle } from '@bottomless/common/src/hooks';
import React, { useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Input, Modal, ModalBody, ModalHeader, CustomInput } from 'reactstrap';
import { Link2 } from 'react-feather';
import { addToastAction } from '@bottomless/common/store';
import { shareDataAction } from '../../store';

const ShareCoffeeJourneyComponent = ({ me, shareData, addToast }) => {
  const [open, toggle] = useToggle();
  const [copied, setCopied] = useState(false);
  const [allowSharing, setAllowSharing] = useState(me.share_data);
  const [isAllowing, setAllowing] = useState(false);
  const historyLink = useMemo(() => `${window.location.origin}/history/${me.referral_id}`, [me]);
  const sinceSignup = useMemo(() => moment.duration(moment(new Date()).diff(me.created_at)).humanize(), [me]);

  const onSharingChange = useCallback(() => {
    const share = !allowSharing;
    setAllowSharing(share);

    (async () => {
      try {
        setAllowing(true);
        await shareData({ share });
        setAllowing(false);
        addToast(`Sharing is turned ${share ? 'on' : 'off'}`, 'success');
      } catch (e) {
        setAllowing(false);
        addToast("Sorry, we couldn't share your data", 'danger');
      }
    })();
  }, [setAllowSharing, allowSharing, setAllowing, shareData, addToast]);

  return (
    <>
      <Link2 size="18" className="ml-2 cursor-pointer" onClick={toggle} />
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Share Your Coffee Journey</ModalHeader>
        <ModalBody>
          <div className="d-flex align-items-center mb-3">
            <div>
              It&apos;s been {sinceSignup} since you joined Bottomless.
              <br />
              We are so happy to have you here.
            </div>
          </div>
          <p className="text-secondary mb-2">Check out your Journey&apos;s Timeline and share it with friends!</p>
          <div className="d-flex align-items-center mb-2">
            <CustomInput
              className="switch"
              id="share-data"
              type="switch"
              name="share-data"
              onChange={onSharingChange}
              checked={allowSharing}
            />
            {isAllowing ? 'Saving...' : 'Enable sharing'}
          </div>
          <div className="d-flex invite-link-copy">
            <Input className="invite-link border border-dark px-2" type="text" readOnly value={historyLink} />
            <CopyToClipboard text={historyLink} onCopy={() => setCopied(true)}>
              <Button color={allowSharing ? 'primary' : 'secondary'}>{copied ? 'Copied!' : 'Copy'}</Button>
            </CopyToClipboard>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

ShareCoffeeJourneyComponent.propTypes = {
  me: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    referral_id: PropTypes.string.isRequired,
    share_data: PropTypes.bool,
  }).isRequired,
  shareData: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
};

export const ShareCoffeeJourney = connect(null, dispatch => ({
  shareData: data => dispatch(shareDataAction(data)),
  addToast: (message, type) => dispatch(addToastAction(message, type)),
}))(ShareCoffeeJourneyComponent);
